import { EligibleProductsConfig, UnderwritingType } from '@api';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import EditableSectionCard from '../../../components/EditableSectionCard';

import styles from './CustomerProductsCard.module.scss';
import { ProductRow } from './components/ProductRow';
import { ProductTypeRow } from './components/ProductTypeRow';


const { DELEGATED, NON_DELEGATED } = UnderwritingType;

interface CustomerProductsCardProps {
    productConfiguration: EligibleProductsConfig | null;
}

export default function CustomerProductsCard({ productConfiguration }: CustomerProductsCardProps) {
    const delegatedProducts = productConfiguration?.productTypeConfigs.filter(
        ({ underwritingType }) => underwritingType === DELEGATED
    );
    const nonDelegatedProducts = productConfiguration?.productTypeConfigs.filter(
        ({ underwritingType }) => underwritingType === NON_DELEGATED
    );

    return (
        <EditableSectionCard
            header="Eligible products"
            editTo="products"
        >
            {!productConfiguration ? (
                <>
                    <Typography color="textSecondary">
                        Eligible products have not been configured for this customer.
                    </Typography>

                    <Button
                        className={styles.productsButton}
                        component={Link}
                        to="products"
                    >
                        Configure products
                    </Button>
                </>
            ) : (
                <div className={styles.limitsContainer}>
                    <ProductRow
                        name="All products"
                        limit={productConfiguration.allProductsLimit}
                    />

                    <ProductRow
                        name="Delegated"
                        limit={productConfiguration.delegatedLimit}
                    />

                    {delegatedProducts?.map(product => (
                        <ProductTypeRow
                            key={product.productType}
                            productTypeConfig={product}
                        />
                    ))}

                    <ProductRow
                        name="Non-delegated"
                        limit={productConfiguration.nonDelegatedLimit}
                    />

                    {nonDelegatedProducts?.map(product => (
                        <ProductTypeRow
                            key={product.productType}
                            productTypeConfig={product}
                        />
                    ))}
                </div>
            )}
        </EditableSectionCard>
    );
}
