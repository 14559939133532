export enum RoundingMethod {
    DEFAULT = 'DEFAULT',
    ROUND_DOWN = 'ROUND_DOWN',
    ROUND_UP = 'ROUND_UP'
}

export const roundingMethodDisplay = {
    [RoundingMethod.DEFAULT]: 'Default',
    [RoundingMethod.ROUND_DOWN]: 'Round down',
    [RoundingMethod.ROUND_UP]: 'Round up'
};
