import { EligibilityGuideline } from '@api';
import { TextField, TextFieldProps } from '@tsp-ui/core/components';
import { ICellEditor, ICellEditorParams } from 'ag-grid-community';
import { Component } from 'react';
import { FieldPath } from 'react-hook-form';

import { LoanProgramFormValues } from '../LoanProgramDetailPage/components/LoanProgramForm';

import styles from './EligibilityCellEditor.module.scss';


export type EligibilityTextFieldProps = TextFieldProps<
LoanProgramFormValues, FieldPath<LoanProgramFormValues>>;

type EligibilityCellEditorProps = ICellEditorParams & {
    fieldName: keyof EligibilityGuideline;
    textFieldProps?: EligibilityTextFieldProps;
};

/**
 * Needs to be a class component to implement getValue, and because ag-grid will pass it a ref
 */
export class EligibilityCellEditor extends Component implements ICellEditor { // eslint-disable-line react/prefer-stateless-function, max-len
    props: EligibilityCellEditorProps = this.props;
    value = this.props.value;
    fieldName = `eligibilityGuidelineSet.guidelines.${this.props.rowIndex}.${this.props.fieldName}` as const;

    getValue = () => this.value;
    setValue = (value: string) => {
        this.value = value;
    };

    render() {
        return (
            <TextField<LoanProgramFormValues>
                {...this.props.textFieldProps}
                className={styles.fillCell}
                {...(this.props.textFieldProps?.select
                    ? {
                        SelectProps: {
                            classes: { select: styles.smallField },
                            defaultOpen: true
                        }
                    }
                    : {
                        InputProps: {
                            classes: {
                                input: styles.smallField,
                                root: styles.fullHeight
                            }
                        }
                    })
                }
                autoFocus
                name={this.fieldName}
                onChange={event => {
                    this.setValue(event.target.value);
                }}
            />
        );
    }
}
