export enum CustomerStatus {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    INACTIVE = 'INACTIVE',
    SUSPENDED = 'SUSPENDED'
}

export const customerStatusDisplay = {
    [CustomerStatus.ACTIVE]: 'Active',
    [CustomerStatus.PENDING]: 'Pending',
    [CustomerStatus.INACTIVE]: 'Inactive',
    [CustomerStatus.SUSPENDED]: 'Suspended'
};
