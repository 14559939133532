export enum PropertyType {
    SINGLE_FAMILY_RESIDENCE = 'SINGLE_FAMILY_RESIDENCE',
    CONDO = 'CONDO',
    PUD = 'PUD',
    MANUFACTURED = 'MANUFACTURED'
}

export const propertyTypeDisplay = {
    [PropertyType.SINGLE_FAMILY_RESIDENCE]: 'Single Family Residence',
    [PropertyType.CONDO]: 'Condo',
    [PropertyType.PUD]: 'PUD',
    [PropertyType.MANUFACTURED]: 'Manufactured'
};
