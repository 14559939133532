import api, { LockAvailability } from '@api';
import { TimeField } from '@tsp-ui/core/components';
import { usePageMessage, useParams } from '@tsp-ui/core/utils';
import { Dispatch, SetStateAction, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './LockAvailabilityForm.module.scss';
import { Day, LockAvailabilityContext } from './LockAvailabilityPage';


interface LockAvailabilityFormProps {
    setLoading: Dispatch<SetStateAction<boolean>>;
}

export default function LockAvailabilityForm({ setLoading }: LockAvailabilityFormProps) {
    const { day, customerID } = useParams<{day: Day}>();

    const { lockAvailability, setLockAvailability } = useContext(LockAvailabilityContext);

    const location = useLocation();
    const navigate = useNavigate();
    const pageMessage = usePageMessage();

    const formMethods = useForm<LockAvailability>({
        defaultValues: lockAvailability
    });

    const handleSubmit = formMethods.handleSubmit(async formData => {
        setLoading(true);

        try {
            setLockAvailability(await api.availability.updateLockAvailability(formData, customerID));

            navigate(location.pathname.replace(/lock-availability.*/, 'lock-availability'));

            pageMessage.success('Lock availability saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving lock availability', error);
        }

        setLoading(false);
    });

    return (
        <form
            noValidate
            id={LockAvailabilityForm.formID}
            onSubmit={handleSubmit}
            className={styles.root}
        >
            <FormProvider {...formMethods}>
                <TimeField<LockAvailability>
                    name={`${day}Start`}
                    label="Open"
                    required
                />

                <TimeField<LockAvailability>
                    name={`${day}End`}
                    label="Close"
                    required
                />
            </FormProvider>
        </form>
    );
}

LockAvailabilityForm.formID = 'lock-availability-form';
