import api from '@api';
import { AddCircleOutline } from '@mui/icons-material';
import {
    Alert, AlertTitle, Button, IconButton, Tooltip
} from '@mui/material';
import { FilledSection } from '@tsp-ui/core/components';
import { capitalize, usePageMessage } from '@tsp-ui/core/utils';
import { useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import styles from './LockAvailabilityContent.module.scss';
import { Day, LockAvailabilityContext, LockAvailabilityDay } from './LockAvailabilityPage';
import { LockHolidayAvailabilityCard } from './LockHolidayAvailabilityCard';
import { LockWeekdayAvailabilityCard } from './LockWeekdayAvailabilityCard';


export function LockAvailabilityContent() {
    const { customerID } = useParams();
    const pageMessage = usePageMessage();
    const { lockAvailability, setLockAvailability, lockAvailabilityHolidays } = useContext(LockAvailabilityContext);

    const [ showWeekends, setShowWeekends ] = useState(false);

    const days: LockAvailabilityDay[] = ([
        'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'
    ] as Day[]).filter(day => showWeekends || (day !== 'saturday' && day !== 'sunday')).map(day => ({
        day,
        available: !!lockAvailability?.[`is${capitalize(day)}Available`],
        startTime: lockAvailability?.[`${day}Start`],
        endTime: lockAvailability?.[`${day}End`]
    }));

    return  (
        <>
            {(lockAvailability?.isSuspended || lockAvailability?.isSuspendedUntilReprice) && (
                <Alert
                    severity="warning"
                    className={styles.suspendedAlert}
                    action={(
                        <Button
                            color="inherit"
                            onClick={async () => {
                                try {
                                    setLockAvailability(await api.availability.updateLockAvailability({
                                        ...lockAvailability,
                                        isSuspended: false,
                                        isSuspendedUntilReprice: false,
                                        suspendedComments: null
                                    }, customerID));

                                    pageMessage.success('Locks activated');
                                } catch (error) {
                                    pageMessage.handleApiError('An error occurred while reactivating locks', error);
                                }
                            }}
                        >
                            Reactivate locks
                        </Button>
                    )}
                >
                    <AlertTitle>
                        {lockAvailability?.isSuspended ? 'All locks suspended' : 'Locks suspended until reprice'}
                    </AlertTitle>

                    {lockAvailability.suspendedComments}
                </Alert>
            )}

            <div className={styles.sections}>
                <FilledSection
                    className={styles.section}
                    header={(
                        <>
                            Standard availability

                            <Button onClick={() => setShowWeekends(!showWeekends)}>
                                {showWeekends ? 'Hide' : 'Show'} weekends
                            </Button>
                        </>
                    )}
                >
                    {lockAvailability && days.map((lockAvailabilityDay) => (
                        <LockWeekdayAvailabilityCard
                            key={lockAvailabilityDay.day}
                            lockAvailabilityDay={lockAvailabilityDay}
                        />
                    ))}
                </FilledSection>

                <FilledSection
                    className={styles.section}
                    header={(
                        <>
                            Holiday availability

                            <Tooltip title="Add holiday">
                                <IconButton
                                    component={Link}
                                    to="add"
                                >
                                    <AddCircleOutline color="secondary" />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                >
                    {lockAvailabilityHolidays.map((holiday) => (
                        <LockHolidayAvailabilityCard
                            key={holiday.lockAvailabilityDate}
                            holiday={holiday}
                        />
                    ))}
                </FilledSection>
            </div>
        </>
    );
}
