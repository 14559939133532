export enum AutomatedUwRecommendation {
    APPROVE_ELIGIBLE = 'APPROVE_ELIGIBLE',
    APPROVE_INELIGIBLE = 'APPROVE_INELIGIBLE',
    REFER_WITH_CAUTION = 'REFER_WITH_CAUTION',
    OUT_OF_SCOPE = 'OUT_OF_SCOPE',
    ACCEPT = 'ACCEPT',
    CAUTION = 'CAUTION'
}

export const automatedUwRecommendationDisplay = {
    [AutomatedUwRecommendation.APPROVE_ELIGIBLE]: 'Approve/Eligible',
    [AutomatedUwRecommendation.APPROVE_INELIGIBLE]: 'Approve/Ineligible',
    [AutomatedUwRecommendation.REFER_WITH_CAUTION]: 'Refer With Caution',
    [AutomatedUwRecommendation.OUT_OF_SCOPE]: 'Out of Scope',
    [AutomatedUwRecommendation.ACCEPT]: 'Accept',
    [AutomatedUwRecommendation.CAUTION]: 'Caution'
};
