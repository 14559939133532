import { LoanDocument } from '@api';
import {
    Paper, Typography
} from '@mui/material';
import { LoanDocumentItem } from '@views/loans/components/LoanDocumentsDialog';
import { UnderwritingMenuListItem } from '@views/loans/underwriting/components/UnderwritingSidebar';
import { ConditionsDisplay } from '@views/loans/underwriting/components/UnderwritingStepCard';
import { ReactNode } from 'react';

import styles from './UnderwritingPageTemplate.module.scss';


interface UnderwritingPageTemplateProps {
    children: ReactNode;
    relevantDocs: string[] | {
        name: string;
        url: string;
    }[];
    stepCards: ReactNode;
    conditionsShown: boolean;
    onConditionsClick: () => void;
    numConditions?: number;
}

export default function UnderwritingPageTemplate({
    children, relevantDocs, stepCards, onConditionsClick, conditionsShown, numConditions
}: UnderwritingPageTemplateProps) {
    return (
        <>
            <div className={styles.leftPanel}>
                <Paper
                    elevation={0}
                    className={styles.relevantDocs}
                >
                    <Typography color="textSecondary">
                        Relevant documents
                    </Typography>

                    <ul className={styles.files}>
                        {relevantDocs.map((doc) => (
                            <LoanDocumentItem
                                key={typeof doc === 'string' ? doc : doc.name}
                                documentId="1"
                                documents={[
                                    {
                                        id: '1',
                                        instanceCount: 1,
                                        name: typeof doc === 'string' ? doc : doc.name,
                                        url: typeof doc === 'string' ? undefined : doc.url
                                    } as LoanDocument
                                ]} // TODO post-demo make this better
                            />
                        ))}
                    </ul>
                </Paper>

                <div>
                    <UnderwritingMenuListItem
                        className={styles.allConditionsStep}
                        selected={conditionsShown}
                        disableExtraWidth
                        onClick={(event) => {
                            event.preventDefault();
                            onConditionsClick();
                        }}
                    >
                        Conditions

                        <ConditionsDisplay numConditions={numConditions !== undefined ? numConditions : 0} />
                    </UnderwritingMenuListItem>

                    <Typography color="textSecondary">
                        Underwriting steps
                    </Typography>

                    {stepCards}
                </div>
            </div>

            {children}
        </>
    );
}
