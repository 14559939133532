import api, { Branch } from '@api';
import { Button, DialogContent } from '@mui/material';
import { AddressFieldset, DialogActions, TextField } from '@tsp-ui/core/components';
import { usePageMessage, useParams } from '@tsp-ui/core/utils';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './AddEditBranchDialogContent.module.scss';


export interface AddEditBranchDialogProps {
    defaultValues: Branch | undefined;
    onEdit: (branch: Branch) => void;
    onCreate: (branch: Branch) => void;
    onDone: () => void;
}

export default function AddEditBranchDialogContent({
    defaultValues, onEdit, onCreate, onDone
}: AddEditBranchDialogProps) {
    const [ loading, setLoading ] = useState(false);
    const { customerID } = useParams<AdminRouteParams<'customer'>>();

    const formMethods = useForm<Branch>({ defaultValues });
    const pageMessage = usePageMessage();

    const handleSubmit = formMethods.handleSubmit(async (formValues: Branch) => {
        setLoading(true);

        try {
            if (defaultValues) {
                onEdit(await api.customer.branches.updateBranch(customerID, formValues));
            } else {
                onCreate(await api.customer.branches.createBranch(customerID, formValues));
            }

            onDone();
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the branch', error);
        }

        setLoading(false);
    });

    return (
        <>
            <DialogContent className={styles.container}>
                <form
                    id="add-edit-branch-dialog"
                    className={styles.root}
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <FormProvider {...formMethods}>
                        <TextField<Branch>
                            name="dba"
                            label="D/B/A"
                            required
                            hideRequiredIndicator
                        />

                        <TextField<Branch>
                            name="nmlsId"
                            label="nmls ID"
                            required
                            hideRequiredIndicator
                        />

                        <AddressFieldset<Branch>
                            className={styles.fullWidth}
                            fieldNames={{
                                street: 'address.street',
                                line2: 'address.line2',
                                city: 'address.city',
                                state: 'address.state',
                                zip: 'address.zip'
                            }}
                            required
                        />
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button onClick={onDone}>
                    Cancel
                </Button>

                <Button
                    form="add-edit-branch-dialog"
                    variant="contained"
                    type="submit"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
}
