import { EligibilityExclusion, EligibilityExclusions, LoanProperty } from '@api';
import { Override, booleanToEnum, enumToBoolean } from '@tsp-ui/core/utils';
import {
    EligibilityExclusionFormValues, EligibilityExclusionGroupFormValues, EligibilityExclusionsFormValues
} from '@views/admin/investors/InvestorDetailPage/components/ExclusionGroupRow/ExclusionGroupRow';

/**
 * Sequential form ID for exclusion groups and rules
 */
let exclusionFormID = -1;

/**
 * Generates a sequential ID for use in the exclusion form. This is needed because we need unique
 * keys for react-hook-form's useFieldArray that is used in the exclusion form.
 */
export const getNextExclusionFormID = () => String(exclusionFormID--);

/**
 * Creates an empty rule with an id attached
 */
export function getEmptyExclusionRule(): EligibilityExclusionFormValues {
    return {
        id: `${getNextExclusionFormID()}`,
        loanProperty: '',
        equalityType: '',
        value: ''
    };
}

/**
 * Creates an empty exclusion group with an id and one blank rule attached
 */
export function getEmptyEligibilityExclusions(omitGroups?: boolean): EligibilityExclusionsFormValues {
    return {
        operator: 'or',
        exclusions: [ getEmptyExclusionRule() ],
        ...(!omitGroups && { groups: [] })
    };
}

export function convertToExclusionFormValues(
    exclusions: EligibilityExclusions | undefined
): EligibilityExclusionsFormValues | undefined {
    return exclusions ? {
        ...exclusions,
        exclusions: exclusions.exclusions?.map(
            exclusion => booleanToEnum<EligibilityExclusionFormValues>(exclusion)
        ),
        groups: exclusions.groups?.map(
            ({ exclusions }) => ({
                exclusions: exclusions.map(
                    exclusion => booleanToEnum<EligibilityExclusionFormValues>(exclusion)
                )
            })
        )
    } : undefined;
}

const booleanLoanProperties: LoanProperty[] = [ LoanProperty.HIGH_BALANCE ];
export function formValuesToExclusions(
    formValues: EligibilityExclusionsFormValues | EligibilityExclusionGroupFormValues
): EligibilityExclusions {
    const operator = isFormValues(formValues) && formValues.operator;
    const groups = isFormValues(formValues) && formValues.groups.map(
        group => formValuesToExclusions(group)
    );

    return {
        ...(operator && { operator }),
        ...(groups && { groups }),
        exclusions: formValues.exclusions?.filter(exclusionHasLoanProperty).map(
            exclusion => (booleanLoanProperties.includes(exclusion.loanProperty) ? enumToBoolean<EligibilityExclusion>(exclusion, [ 'value' ]) : exclusion as EligibilityExclusion)
        )
    } as EligibilityExclusions;
}

export function exclusionsToFormValues(exclusions: EligibilityExclusions): EligibilityExclusionsFormValues {
    return {
        ...exclusions,
        exclusions: exclusions.exclusions?.map(exclusion => booleanToEnum(exclusion)) || [],
        groups: exclusions.groups?.map(
            ({ exclusions }) => ({ exclusions: exclusions.map(exclusion => booleanToEnum(exclusion)) })
        )
    };
}

function exclusionHasLoanProperty(
    exclusion: EligibilityExclusionFormValues
): exclusion is Override<EligibilityExclusionFormValues, { loanProperty: LoanProperty }> {
    return exclusion.loanProperty !== '';
}

function isFormValues(
    formValues: EligibilityExclusionsFormValues | EligibilityExclusionGroupFormValues
): formValues is Required<EligibilityExclusionsFormValues> {
    return formValues.hasOwnProperty('groups');
}
