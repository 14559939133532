import { Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import { AssetsUnderwritingTabs } from '../../AssetsUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard  from '../UnderwritingYesNoQuestionCard';

import styles from './NetEquitySection.module.scss';


interface NetEquitySectionProps {
    setTab: (tab: AssetsUnderwritingTabs) => void;
    onTabComplete: (tab: AssetsUnderwritingTabs, nextTab: AssetsUnderwritingTabs) => void;
}

export default function NetEquitySection({ onTabComplete }: NetEquitySectionProps) {
    const [ hasNetEquity, setHasNetEquity ] = useState<boolean>();
    const [ showSettlementQuestion, setShowSettlementQuestion ] = useState<boolean>();
    const [ hasSettlement, setHasSettlement ] = useState<boolean>();
    const [ fundsCleared, setFundsCleared ] = useState<boolean>();

    useEffect(() => {
        if (hasNetEquity === false || fundsCleared !== undefined) {
            onTabComplete('net-equity', 'assets');
        }
    }, [
        hasNetEquity, fundsCleared, onTabComplete
    ]);

    return (
        <UnderwritingStepSection title="Net equity">
            <UnderwritingYesNoQuestionCard
                question="Is the borrower gaining net equity from sale of real estate?"
                onAnswered={setHasNetEquity}
                answer={hasNetEquity}
            >
                <TextField
                    label="How much net equity?"
                    className={styles.textField}
                />

                {!showSettlementQuestion && (
                    <Button
                        variant="contained"
                        className={styles.nextButton}
                        onClick={() => setShowSettlementQuestion(true)}
                    >
                        Next
                    </Button>
                )}
            </UnderwritingYesNoQuestionCard>

            {showSettlementQuestion && (
                <UnderwritingYesNoQuestionCard
                    question="Is the settlement statement present?"
                    onAnswered={setHasSettlement}
                    answer={hasSettlement}
                />
            )}

            {hasSettlement !== undefined && (
                <UnderwritingYesNoQuestionCard
                    question="Are the funds present in one of the borrower's accounts?"
                    onAnswered={setFundsCleared}
                    answer={fundsCleared}
                />
            )}
        </UnderwritingStepSection>
    );
}
