import { FeeType, RoundingMethod } from '..';
import { apiUtils } from '../api-utils';


export function getPurchaseAdviceConfig(): Promise<PurchaseAdviceConfig> {
    return apiUtils.get('/client/purchaseAdvice');
}

export function addPurchaseAdviceConfig(purchaseAdviceConfig: Omit<PurchaseAdviceConfig, 'id'>): Promise<PurchaseAdviceConfig> {
    return apiUtils.post('/client/purchaseAdvice', purchaseAdviceConfig);
}

export function updatePurchaseAdviceConfig(purchaseAdviceConfig: PurchaseAdviceConfig): Promise<PurchaseAdviceConfig> {
    return apiUtils.put('/client/purchaseAdvice', purchaseAdviceConfig);
}

export function getPurchaseAdviceFees(): Promise<PurchaseAdviceFee[]> {
    return apiUtils.get('/client/purchaseAdviceFee');
}

export function addPurchaseAdviceFee(purchaseAdviceFee: Omit<PurchaseAdviceFee, 'feeId'>): Promise<PurchaseAdviceFee> {
    return apiUtils.post('/client/purchaseAdviceFee', purchaseAdviceFee);
}

export function updatePurchaseAdviceFee(purchaseAdviceFee: PurchaseAdviceFee): Promise<PurchaseAdviceFee> {
    return apiUtils.put(`/client/purchaseAdviceFee/${purchaseAdviceFee.id}`, purchaseAdviceFee);
}

export function deletePurchaseAdviceFee(feeID: number): Promise<void> {
    return apiUtils.delete(`/client/purchaseAdviceFee/${feeID}`);
}

export interface PurchaseAdviceConfig {
    id: number;
    interestRatePrecision: number;
    roundingMethod: RoundingMethod;
    cutoffDay: number;
}

export interface PurchaseAdviceFee {
    id: number;
    feeType: FeeType;
    feeAmount: number;
}
