import api, {
    AvailableProduct,
    ClientProductConfig,
    CommitmentType,
    CustomerPendingSteps,
    EligibleProductsConfig,
    LimitConfig,
    ProductConfig,
    ProductType,
    ProductTypeConfig,
    ServicingType,
    UnderwritingType,
    productTypeDisplay
} from '@api';
import { HelpOutline } from '@mui/icons-material';
import { Button, DialogContent, Tooltip } from '@mui/material';
import { DialogActions, RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import {
    formatCurrency, useAsyncEffect, useConfirm, usePageMessage
} from '@tsp-ui/core/utils';
import {
    useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { CustomerDetailContext, CustomerDetailContextValue } from '../../CustomerDetailPage';

import styles from './EligibleProductsDialog.module.scss';
import { LimitFields } from './components/LimitFields';
import { UnderwritingTypeCard } from './components/UnderwritingTypeCard';


export interface ProductFormValue {
    id: string;
    active: boolean;
    name: string;
    limit: LimitConfigFormValue;
}

export interface ProductTypeFormValue {
    id: string;
    active: boolean;
    servicingType: ServicingType;
    commitmentTypes: CommitmentType[];
    lockPeriods: number[];
    limit: LimitConfigFormValue;
    products: ProductFormValue[];
}

export type ProductTypesFormValue = {
    [key in ProductType]?: ProductTypeFormValue;
}

export interface UnderwritingTypeFormValue {
    active: boolean;
    limit: LimitConfigFormValue;
    productTypes: ProductTypesFormValue;
}

export interface EligibleProductsFormValues {
    id: string;
    allProductsLimit: LimitConfigFormValue;
    delegated: UnderwritingTypeFormValue;
    nonDelegated: UnderwritingTypeFormValue
}

export interface LimitConfigFormValue {
    id: string;
    currentAmount: string;
    limitAmount: string;
    expirationDate: string;
}

const { DELEGATED, NON_DELEGATED } = UnderwritingType;

const {
    CONVENTIONAL, FHA, NON_AGENCY, USDA, VA
} = ProductType;

export default function EligibleProductsDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const [ clientConfig, setClientConfig ] = useState<ClientProductConfig>({
        availableLockPeriods: [],
        availableProducts: []
    });
    const { availableLockPeriods, availableProducts: defaultAvailableProducts } = clientConfig;

    const [ loading, setLoading ] = useState(false);
    const [ saveLoading, setSaveLoading ] = useState(false);

    const navigate = useNavigate();
    const pageMessage = usePageMessage();
    const confirm = useConfirm();

    const {
        customer, updateCustomer, updatePendingSteps
    } = useContext(CustomerDetailContext) as Required<CustomerDetailContextValue>;
    const { productConfiguration } = customer;

    const formMethods = useForm<EligibleProductsFormValues>({
        defaultValues: generateDefaultValues(productConfiguration, defaultAvailableProducts)
    });
    const { reset } = formMethods;

    const availableProducts = useMemo(() => ({
        [CONVENTIONAL]: filterAvailableProducts(defaultAvailableProducts, CONVENTIONAL),
        [FHA]: filterAvailableProducts(defaultAvailableProducts, FHA),
        [NON_AGENCY]: filterAvailableProducts(defaultAvailableProducts, NON_AGENCY),
        [USDA]: filterAvailableProducts(defaultAvailableProducts, USDA),
        [VA]: filterAvailableProducts(defaultAvailableProducts, VA)
    }), [ defaultAvailableProducts ]);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setClientConfig(await api.client.getProductConfig());
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the product config', error);
        }

        setLoading(false);
    }, [ pageMessage ]));

    useEffect(() => {
        reset(generateDefaultValues(productConfiguration, defaultAvailableProducts));
    }, [
        reset, productConfiguration, defaultAvailableProducts
    ]);

    const handleSubmit = formMethods.handleSubmit(async (formData) => {
        setSaveLoading(true);

        const productConfiguration = generateEligibleProductsConfig(formData);

        const itemsThatNeedConfirmation = getItemsThatNeedConfirmation(productConfiguration);

        for (let i = 0; i < itemsThatNeedConfirmation.length; i++) {
            const { name, limit } = itemsThatNeedConfirmation[i];
            const confirmationMessage = `
                The current value of ${name} is ${formatCurrency(limit?.currentAmount)}.
                You have entered a limit value of ${formatCurrency(limit?.limitAmount)}.
                Setting a limit below the current value will prevent new originations,
                would you like to continue?
            `;

            if (!(await confirm(confirmationMessage))) {
                setSaveLoading(false);

                return;
            }
        }

        try {
            await api.customer.eligibleProducts.updateEligibleProducts(customer.id, productConfiguration);

            updateCustomer({
                ...customer,
                productConfiguration
            });

            await updatePendingSteps(CustomerPendingSteps.ELIGIBLE_PRODUCTS);

            navigate(props.closeTo);

            pageMessage.success('Eligible products updated');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating the eligible products', error);
        }

        setSaveLoading(false);
    });

    return (
        <RoutedDialog
            {...props}
            title="Eligible products"
            maxWidth={false}
            loading={loading}
            onClose={() => reset(generateDefaultValues(productConfiguration, defaultAvailableProducts))}
        >
            <DialogContent>
                <form
                    noValidate
                    id="eligible-products-form"
                    onSubmit={handleSubmit}
                    className={styles.form}
                >
                    <FormProvider {...formMethods}>
                        <LimitFields
                            className={styles.allLimits}
                            label="All products"
                            baseFieldPath="allProductsLimit"
                        />

                        <Tooltip title="This limit is a total limit for all products">
                            <HelpOutline
                                color="primary"
                                className={styles.helpIcon}
                            />
                        </Tooltip>

                        <UnderwritingTypeCard
                            label="Delegated"
                            baseFieldPath="delegated"
                            availableLockPeriods={availableLockPeriods}
                            availableProducts={availableProducts}
                        />

                        <UnderwritingTypeCard
                            label="Non-delegated"
                            baseFieldPath="nonDelegated"
                            availableLockPeriods={availableLockPeriods}
                            availableProducts={availableProducts}
                        />
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={saveLoading}>
                <Button
                    variant="contained"
                    form="eligible-products-form"
                    type="submit"
                    disabled={saveLoading}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

function filterAvailableProducts(availableProducts: AvailableProduct[], productType: ProductType) {
    return availableProducts.filter((product) => product.productType === productType);
}

function formatLimitAsLocale(limit: LimitConfig | null): LimitConfigFormValue {
    return {
        id: limit?.id || '',
        currentAmount: limit ? `${limit.currentAmount}` : '',
        limitAmount: limit ? `${limit.limitAmount}` : '',
        expirationDate: limit?.expirationDate ? new Date(limit.expirationDate).toLocaleDateString() : ''
    };
}

function formatLimitAsISO(limit: LimitConfigFormValue | null): LimitConfig | null {
    return limit?.limitAmount
        ? {
            id: limit?.id || '',
            currentAmount: Number(limit.currentAmount),
            limitAmount: Number(limit.limitAmount),
            expirationDate: !!limit.expirationDate ? new Date(limit.expirationDate).toISOString() : ''
        }
        : null;
}

function generateDefaultValues(
    eligibleProductsConfig: EligibleProductsConfig | null, availableProducts: AvailableProduct[]
): EligibleProductsFormValues | undefined {
    if (eligibleProductsConfig) {
        const {
            productTypeConfigs, allProductsLimit, delegatedLimit, nonDelegatedLimit
        } = eligibleProductsConfig;

        const delConfigs = productTypeConfigs.filter(({ underwritingType }) => underwritingType === DELEGATED);
        const nonDelConfigs = productTypeConfigs.filter(({ underwritingType }) => underwritingType === NON_DELEGATED);

        return {
            id: eligibleProductsConfig.id || '',
            allProductsLimit: formatLimitAsLocale(allProductsLimit),
            delegated: {
                active: delConfigs.length > 0,
                limit: formatLimitAsLocale(delegatedLimit),
                productTypes: getProductTypesDefaultValues(availableProducts, productTypeConfigs, DELEGATED)
            },
            nonDelegated: {
                active: nonDelConfigs.length > 0,
                limit: formatLimitAsLocale(nonDelegatedLimit),
                productTypes: getProductTypesDefaultValues(availableProducts, productTypeConfigs, NON_DELEGATED)
            }
        };
    }
}

function getProductTypesDefaultValues(
    availableProducts: AvailableProduct[], existingConfigs: ProductTypeConfig[], uwType: UnderwritingType
): ProductTypesFormValue {
    const availableProductTypes: ProductType[] = [];
    availableProducts.forEach(({ productType }) => {
        if (availableProductTypes.indexOf(productType) < 0) {
            availableProductTypes.push(productType);
        }
    });

    return Object.fromEntries(availableProductTypes.map(productType => {
        const existingConfig = existingConfigs.find(product => product.productType === productType);

        const active = existingConfig?.underwritingType === uwType;

        return [
            productType, existingConfig ? {
                ...existingConfig,
                active,
                limit: formatLimitAsLocale(existingConfig.limit),
                products: getProductDefaultValues(
                    filterAvailableProducts(availableProducts, productType),
                    existingConfig.productConfigs,
                    active
                )
            } : {
                active: false,
                servicingType: ServicingType.RELEASED,
                commitmentTypes: [],
                lockPeriods: [],
                limit: formatLimitAsLocale(null),
                products: getProductDefaultValues(
                    filterAvailableProducts(availableProducts, productType),
                    [],
                    false
                )
            }
        ];
    }));
}

function getProductDefaultValues(
    availableProducts: AvailableProduct[], productConfigs: ProductConfig[], parentIsActive: boolean
): ProductFormValue[] {
    return availableProducts.map((availableProduct) => {
        const productConfig = productConfigs.find(({ name }) => name === availableProduct.name);

        return {
            id: productConfig?.id || '',
            active: parentIsActive ? !!productConfig : false,
            name: availableProduct.name,
            limit: formatLimitAsLocale(productConfig?.limit || null)
        };
    });
}

function generateEligibleProductsConfig(formData: EligibleProductsFormValues): EligibleProductsConfig {
    const { allProductsLimit, delegated, nonDelegated } = formData;
    return {
        id: formData.id,
        allProductsLimit: formatLimitAsISO(allProductsLimit),
        delegatedLimit: delegated.active ? formatLimitAsISO(delegated.limit) : null,
        nonDelegatedLimit: nonDelegated.active ? formatLimitAsISO(nonDelegated.limit) : null,
        productTypeConfigs: [
            ...delegated.active ? getProductTypeConfigs(DELEGATED, delegated.productTypes) : [],
            ...nonDelegated.active ? getProductTypeConfigs(NON_DELEGATED, nonDelegated.productTypes) : []
        ]
    };
}

function getProductTypeConfigs(underwritingType: UnderwritingType, productTypes: ProductTypesFormValue) {
    const productTypeConfigs: ProductTypeConfig[] = [];

    Object.entries(productTypes).forEach(([ productType, formValue ]) => {
        const {
            id, active, servicingType, commitmentTypes, lockPeriods, limit, products
        } = formValue;

        if (active) {
            productTypeConfigs.push({
                id: id || '',
                productType: productType as ProductType,
                underwritingType,
                servicingType,
                commitmentTypes,
                lockPeriods,
                limit: formatLimitAsISO(limit),
                productConfigs: getProductConfigs(products)
            });
        }
    });

    return productTypeConfigs;
}

function getProductConfigs(products: ProductFormValue[]) {
    const productConfigs: ProductConfig[] = [];

    products.forEach(({
        active, limit, name, id
    }) => {
        if (active) {
            productConfigs.push({
                id,
                name,
                limit: formatLimitAsISO(limit)
            });
        }
    });

    return productConfigs;
}

function currentAmountExceedsLimit(limit: LimitConfig | null) {
    return limit ? limit.currentAmount > limit.limitAmount : false;
}

function getItemsThatNeedConfirmation({
    allProductsLimit, delegatedLimit, nonDelegatedLimit, productTypeConfigs
}: EligibleProductsConfig) {
    const items = [] as ProductConfig[];

    checkLimitConfirmation('All products', allProductsLimit, items);
    checkLimitConfirmation('Delegated products', delegatedLimit, items);
    checkLimitConfirmation('Non-delegated products', nonDelegatedLimit, items);

    productTypeConfigs.forEach(({ limit, productType, productConfigs }) => {
        checkLimitConfirmation(`${productTypeDisplay[productType]} loans`, limit, items);

        productConfigs.forEach(({ name, limit }) => {
            checkLimitConfirmation(name, limit, items);
        });
    });

    return items;
}

function checkLimitConfirmation(name: string, limit: LimitConfig | null, items: ProductConfig[]) {
    if (currentAmountExceedsLimit(limit)) {
        items.push({
            id: limit?.id || '',
            name,
            limit
        });
    }
}
