import {
    BooleanEnum, EligibilityExclusion, EligibilityExclusions, NumericExclusionValue
} from '@api';
import clsx from 'clsx';
import React from 'react';
import { FieldValues, UseFieldArrayReturn, useFieldArray } from 'react-hook-form';

import ExclusionGroupControls from './ExclusionGroupControls/ExclusionGroupControls';
import styles from './ExclusionGroupRow.module.scss';
import ExclusionRuleRow from './ExclusionRuleRow';
import { getEmptyExclusionRule } from './exclusion-group-form-utils';


export type EligibilityExclusionFormValues = Omit<EligibilityExclusion, 'value'> & {
    value: string[] | NumericExclusionValue | BooleanEnum | '';
}

export type EligibilityExclusionsFormValues = Omit<EligibilityExclusions, 'exclusions' | 'groups'> & {
    exclusions?: EligibilityExclusionFormValues[];
    groups?: EligibilityExclusionGroupFormValues[];
};

export interface EligibilityExclusionGroupFormValues {
    exclusions?: EligibilityExclusionFormValues[];
}

interface ExclusionGroupRowProps {
    nameBase?: string;
    removeLastRow?: () => void;
    disableMargin?: boolean;
    rootNameBase?: string;
}

/**
 * Renders a row for an exclusion group. Renders the exclusion group controls for this group,
 * any rule rows within the group, and recursively renders itself to render nested exclusion group rows.
 *
 * @param nameBase      - The base name for the group that is currently being rendered. This is built
 *                        recursively to render the child groups and their rows with the correct field name
 * @param removeLastRow - A function to remove the last row. Undefined for the root group.
 * @param disableMargin - Whether margins should be disabled on the group row
 * @param rootNameBase  - The name base of the root group
 * @constructor
 */
export function ExclusionGroupRow({
    nameBase: nameBaseProp, removeLastRow, disableMargin, rootNameBase
}: ExclusionGroupRowProps) {
    const isRootGroup = !removeLastRow;
    const nameBase = nameBaseProp ? `${nameBaseProp}.` : '';

    const ruleArray = useFieldArray({
        name: `${nameBase}exclusions`
    });

    const groupsArray = useFieldArray({ // This always represents the single groups array in the form
        name: `${nameBase.includes('groups') ? nameBase.split('groups')[0] : nameBase}groups`
    });

    removeLastRow = removeLastRow || (() => {
        // If we don't have a removeLastRow function defined, this is the root group.
        // Removing the last row from the root group should replace the single rule field
        ruleArray.append(getEmptyExclusionRule());
        groupsArray.remove(0);
    });

    rootNameBase = rootNameBase || nameBase;

    return (
        <div
            className={clsx(styles.root, {
                [styles.disableMargin]: disableMargin
            })}
        >
            <ExclusionGroupControls
                nameBase={nameBase}
                isRootGroup={isRootGroup}
                ruleArray={ruleArray as UseFieldArrayReturn<FieldValues, string>}
                groupsArray={groupsArray as UseFieldArrayReturn<FieldValues, string>}
                removeLastRow={removeLastRow}
                rootNameBase={rootNameBase}
            />

            <div className={styles.rulesContainer}>
                {ruleArray.fields.map(({ id }, index) => (
                    <ExclusionRuleRow
                        key={id}
                        nameBase={`${nameBase}exclusions.${index}`}
                    />
                ))}

                {isRootGroup && groupsArray.fields.map(({ id }, index) => (
                    <ExclusionGroupRow
                        key={id}
                        nameBase={`${nameBase}groups.${index}`}
                        disableMargin={ruleArray.fields.length === 0}
                        rootNameBase={nameBase}
                        removeLastRow={() => {
                            if (ruleArray.fields.length === 0 && groupsArray.fields.length === 1) {
                                removeLastRow?.();
                            } else {
                                groupsArray.remove(index);
                            }
                        }}
                    />
                ))}
            </div>
        </div>
    );
}
