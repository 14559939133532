import api, { ClientInvestor, CommonLoanProgram } from '@api';
import { useAsyncEffect, useParams, useRouteMatch } from '@tsp-ui/core';
import { SentryRoutes, usePageMessage } from '@tsp-ui/core/components';
import { useActingClientID } from '@utils/hooks';
import CreateLoanProgramPage from '@views/admin/investors/InvestorDetailPage/CreateLoanProgramPage';
import {
    Dispatch, SetStateAction, createContext, useCallback, useMemo, useState
} from 'react';
import { Route, useLocation } from 'react-router-dom';

import { LoanProgramsContext } from '../investors/InternalInvestorDetailRoutes';

import { ClientAddInvestorPage } from './ClientAddInvestorPage';
import ClientInvestorDetailPage from './ClientInvestorDetailPage';
import { ClientLoanProgramDetailPage } from './ClientLoanProgramDetailPage';
import InvestorEligibilityManagementPage from './InvestorEligibilityManagementPage';


export interface InvestorEligibilityContextValue {
    investors?: ClientInvestor[];
    setInvestors: Dispatch<SetStateAction<ClientInvestor[] | undefined>>;
}

export const InvestorEligibilityContext = createContext<InvestorEligibilityContextValue>({
    investors: undefined,
    setInvestors: () => {}
});

export default function InvestorEligibilityRoutes() {
    const { investorID } = useRouteMatch(`${useLocation().pathname.replace(useParams()['*'] || '', '')}investors/:investorID/*`)?.params || {};
    const pageMessage = usePageMessage();
    const clientID = useActingClientID();

    const [ investors, setInvestors ] = useState<ClientInvestor[]>();
    const investorEligibilityContextValue = useMemo(() => ({
        investors,
        setInvestors
    }), [ investors, setInvestors ]);

    /**
     * TODO Need to fix potential bug where loan programs can be set in context for one investor while
     * they are loading for another investor.This would show the wrong loan programs while the new ones
     * are loading, and would show incorrect data if the new call fails. We could fix this by moving
     * loading state into this component and retrigger loading when this effect is fired (when investorID changes)
     */
    const [ loanPrograms, setLoanPrograms ] = useState<CommonLoanProgram[]>();
    const loanProgramsContextValue = useMemo(() => ({
        loanPrograms,
        setLoanPrograms
    }), [ loanPrograms, setLoanPrograms ]);

    useAsyncEffect(useCallback(async () => {
        try {
            if (investorID && investorID !== 'add') {
                setLoanPrograms(await api.client.investors.getLoanPrograms(investorID));
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loan programs', error);
        }
    }, [
        investorID, pageMessage, setLoanPrograms
    ]));

    useAsyncEffect(useCallback(async () => {
        if (clientID) {
            try {
                setInvestors(await api.client.investors.getInvestors());
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching investors', error);
            }
        }
    }, [
        clientID, pageMessage, setInvestors
    ]));

    return (
        <InvestorEligibilityContext.Provider value={investorEligibilityContextValue}>
            <LoanProgramsContext.Provider value={loanProgramsContextValue}>
                <SentryRoutes>
                    <Route
                        path="*"
                        element={<InvestorEligibilityManagementPage />}
                    />

                    <Route
                        path="investors/add"
                        element={<ClientAddInvestorPage />}
                    />

                    <Route
                        path="investors/:investorID/*"
                        element={(
                            <SentryRoutes>
                                <Route
                                    path="*"
                                    element={<ClientInvestorDetailPage />}
                                />

                                <Route
                                    path="loan-programs/new"
                                    element={<CreateLoanProgramPage />}
                                />

                                <Route
                                    path="loan-programs/:loanProgramID/*"
                                    element={<ClientLoanProgramDetailPage />}
                                />
                            </SentryRoutes>
                        )}
                    />
                </SentryRoutes>
            </LoanProgramsContext.Provider>
        </InvestorEligibilityContext.Provider>
    );
}
