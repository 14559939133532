import { Check, Close, WarningAmber } from '@mui/icons-material';
import {
    Button, Paper, Tooltip, Typography
} from '@mui/material';
import { LabelGroup, LabeledValue } from '@tsp-ui/core/components';

import { AssetsUnderwritingTabs } from '../../AssetsUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';
import { SummaryLabeledValue } from '../credit/LiabilitiesSection';

import AssetAccountCard from './AssetAccountCard';
import styles from './AssetAccountsSection.module.scss';


interface AssetAccountsSectionProps {
    setTab: (tab: AssetsUnderwritingTabs) => void;
    onTabComplete: (tab: AssetsUnderwritingTabs, nextTab: AssetsUnderwritingTabs) => void;
}

export default function AssetAccountsSection({ onTabComplete }: AssetAccountsSectionProps) {
    const sufficientLiquidAssets = true;
    const sufficientAssets = true;

    return (
        <UnderwritingStepSection
            title="Asset accounts"
            addButton={(
                <Button>
                    Add account
                </Button>
            )}
        >
            <div className={styles.root}>
                <div className={styles.accounts}>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        className={styles.sectionLabel}
                    >
                        Needs review
                    </Typography>

                    <AssetAccountCard accountType="" />

                    <Typography
                        variant="caption"
                        color="textSecondary"
                        className={styles.sectionLabel}
                    >
                        Liquid assets
                    </Typography>

                    <AssetAccountCard accountType="Checking" />

                    <AssetAccountCard accountType="Savings" />

                    <Typography
                        variant="caption"
                        color="textSecondary"
                        className={styles.sectionLabel}
                    >
                        Stocks and retirement
                    </Typography>

                    <AssetAccountCard accountType="Stocks" />
                </div>

                <div className={styles.summaryContainer}>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        Asset summary
                    </Typography>

                    <Paper
                        variant="outlined"
                        className={styles.summary}
                    >
                        <LabelGroup>
                            <SummaryLabeledValue
                                label="Original cash to close"
                                value="$40,000"
                                plus
                            />

                            <SummaryLabeledValue
                                label="Gifts received at closing"
                                value="$5,000"
                                minus
                            />

                            <SummaryLabeledValue
                                label="Required reserves"
                                value="$10,000"
                                plus
                            />

                            <SummaryLabeledValue
                                label="Total assets required"
                                value="$45,000"
                                bold
                            />

                            <SummaryLabeledValue
                                label="Liquid assets"
                                value="$20,000"
                                plus
                                className={styles.summaryExtraMargin}
                            />

                            <SummaryLabeledValue
                                label="Gifts not yet deposited"
                                value="$10,000"
                                plus
                            />

                            <SummaryLabeledValue
                                label="Net equity from sale"
                                value="--"
                                plus
                            />

                            <SummaryLabeledValue
                                label="Liquid assets available"
                                value="$30,000"
                                bold
                            />

                            <SummaryLabeledValue
                                label="Other assets available"
                                value={sufficientAssets ? '$100,000' : '--'}
                                bold
                                className={styles.summaryExtraMargin}
                            />
                        </LabelGroup>

                        <div className={styles.summaryButtonContainer}>
                            <LabeledValue
                                {...labeledValueProps}
                                label="Asset expiration"
                                value="03/01/2021"
                            />

                            <LabeledValue
                                {...labeledValueProps}
                                label="Sufficient assets"
                                value={sufficientLiquidAssets ? (
                                    <Check color="success" />
                                ) : sufficientAssets ? (
                                    <Tooltip
                                        title={`Insufficient liquid assets. Liquidation of other assets
                                         required to meet asset requirements.`}
                                    >
                                        <WarningAmber color="warning" />
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Insufficient assets. File will be suspended.">
                                        <Close color="error" />
                                    </Tooltip>
                                )}
                                className={styles.sufficientCash}
                            />

                            {!sufficientLiquidAssets && (
                                <LabeledValue
                                    {...labeledValueProps}
                                    label="Shortage"
                                    value="$15,000"
                                />
                            )}

                            <Button
                                variant="contained"
                                className={styles.doneButton}
                                onClick={() => onTabComplete('assets', 'summary')}
                            >
                                Done
                            </Button>
                        </div>
                    </Paper>
                </div>
            </div>
        </UnderwritingStepSection>
    );
}

const labeledValueProps = {
    classNames: {
        label: styles.centerAlign,
        value: styles.centerAlign
    },
    variant: 'vertical'
} as const;
