import { PricingResultProduct, RegistrationType } from '@api';
import { AddTask, Lock } from '@mui/icons-material';
import {
    IconButton, Link as MuiLink, Tooltip, Typography
} from '@mui/material';
import { ExpandableCardTableRow } from '@tsp-ui/core/components';

import styles from './PricingResultProductRow.module.scss';
import ProductDetailsTable from './ProductDetailsTable';


interface PricingResultProductRowProps {
    isExpired: boolean;
    onRegister(productID: string, registrationType: RegistrationType): Promise<void>;
    product: PricingResultProduct;
}

export default function PricingResultProductRow({
    onRegister, isExpired, product: {
        productId, adjustments, basePrice, description, docUrl, finalPrice, investor, llpas, margin
    }
}: PricingResultProductRowProps) {
    return (
        <ExpandableCardTableRow
            expandedContent={(
                <ProductDetailsTable adjustments={adjustments} />
            )}
        >
            <td width="100%">
                <Typography
                    component={MuiLink}
                    href={docUrl}
                    rel="noreferrer noopener"
                    target="_blank"
                    variant="body2"
                    className={styles.productLink}
                >
                    {description}
                </Typography>
            </td>

            <Typography
                component="td"
                variant="body2"
            >
                {investor}
            </Typography>

            <Typography
                component="td"
                variant="body2"
            >
                {margin.toFixed(3)}
            </Typography>

            <Typography
                component="td"
                variant="body2"
            >
                {basePrice.toFixed(3)}
            </Typography>

            <Typography
                component="td"
                variant="body2"
                color={
                    llpas === 0
                        ? 'textPrimary' // TODO make a component for this
                        : llpas > 0
                            ? 'green'
                            : 'error'
                }
            >
                {llpas.toFixed(3)}
            </Typography>

            <Typography
                component="td"
                variant="body2"
            >
                {finalPrice.toFixed(3)}
            </Typography>

            <td className={styles.buttonsCell}>
                <Tooltip title={isExpired ? expiredMessage('lock') : 'Lock loan'}>
                    <span>
                        <IconButton
                            size="small"
                            disabled={isExpired}
                            onClick={() => onRegister(productId, RegistrationType.LOCK)}
                        >
                            <Lock
                                color={isExpired ? 'disabled' : 'secondary'}
                                fontSize="small"
                            />
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip title={isExpired ? expiredMessage('float') : 'Float loan'}>
                    <span>
                        <IconButton
                            size="small"
                            disabled={isExpired}
                            onClick={() => onRegister(productId, RegistrationType.FLOAT)}
                        >
                            <AddTask
                                color={isExpired ? 'disabled' : 'secondary'}
                                fontSize="small"
                            />
                        </IconButton>
                    </span>
                </Tooltip>
            </td>
        </ExpandableCardTableRow>
    );
}

const expiredMessage = (action: string) => `Pricing expired. Reprice the loan to ${action}.`;
