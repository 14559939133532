import api, { CustomerFeesComp } from '@api';
import { Button, DialogContent } from '@mui/material';
import { DialogActions, RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage, useParams } from '@tsp-ui/core/utils';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { useCallback, useState } from 'react';

import styles from './CompensationDialog.module.scss';
import CompensationDialogForm, { COMPENSATION_FORM_ID } from './CompensationDialogForm';


export type CompensationDialogProps = Omit<RoutedDialogProps, 'title' | 'children'>;

export default function CompensationDialog(props: CompensationDialogProps) {
    const [ loading, setLoading ] = useState(false);
    const [ saveLoading, setSaveLoading ] = useState(false);

    const [ compensationDetails, setCompensationDetails ] = useState<CustomerFeesComp>();
    const { customerID } = useParams<AdminRouteParams<'customer'>>();

    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setCompensationDetails(await api.customer.compensation.getFeesComp(customerID));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the compensation details', error);
        }

        setLoading(false);
    }, [ customerID, pageMessage ]));

    return (
        <RoutedDialog
            title="Compensation & fees"
            loading={loading}
            {...props}
        >
            <DialogContent className={styles.container}>
                {compensationDetails && (
                    <CompensationDialogForm
                        defaultValues={compensationDetails}
                        setLoading={setSaveLoading}
                        {...props}
                    />
                )}
            </DialogContent>

            <DialogActions loading={saveLoading}>
                <Button
                    form={COMPENSATION_FORM_ID}
                    variant="contained"
                    type="submit"
                    disabled={saveLoading}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
