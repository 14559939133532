import api, { BulkCommitment } from '@api';
import { SentryRoutes } from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { CommitmentsContext } from '@views/admin/bulk-commitment/BulkCommitmentManagementPage';
import BulkCommitmentTrackingDetailPage from '@views/tracking/bulk-commitment/BulkCommitmentTrackingDetailPage';
import { useCallback, useMemo, useState } from 'react';
import { Navigate, Route } from 'react-router-dom';

import BulkCommitmentTrackingPage from './bulk-commitment/BulkCommitmentTrackingPage';


// TODO post-demo withAuthentication
export default function TrackingRouteSwitch() {
    return (
        <SentryRoutes>
            <Route
                path="/"
                element={(
                    <Navigate to="bulk-commitments" />
                )}
            />

            <Route
                path="/bulk-commitments/*"
                element={<BulkCommitmentTrackingRoutes />}
            />
        </SentryRoutes>
    );
}

function BulkCommitmentTrackingRoutes() {
    const pageMessage = usePageMessage();
    const [ loading, setLoading ] = useState(true);
    const [ commitments, setCommitments ] = useState<BulkCommitment[]>();
    const { id: clientId } = useGetCurrentAccount();

    useAsyncEffect(useCallback(async () => {
        try {
            setLoading(true);
            setCommitments(await api.commitment.getCommitments(clientId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching bulk commitments', error);
        } finally {
            setLoading(false);
        }
    }, [ pageMessage, clientId ]));

    const contextValue = useMemo(() => ({
        loading,
        commitments,
        setCommitments
    }), [
        loading, commitments, setCommitments
    ]);

    return (
        <CommitmentsContext.Provider value={contextValue}>
            <SentryRoutes>
                <Route
                    path="/"
                    element={<BulkCommitmentTrackingPage />}
                />

                <Route
                    path="/:commitmentID/*"
                    element={<BulkCommitmentTrackingDetailPage />}
                />
            </SentryRoutes>
        </CommitmentsContext.Provider>
    );
}
