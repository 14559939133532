import { Typography } from '@mui/material';
import { useState } from 'react';

import { AppraisalUnderwritingTabs } from '../../AppraisalUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';

import styles from './AppraisalReviewSection.module.scss';


interface AppraisalReviewSectionProps {
    setTab: (tab: AppraisalUnderwritingTabs) => void;
    onTabComplete: (tab: AppraisalUnderwritingTabs, nextTab: AppraisalUnderwritingTabs) => void;
}

export default function AppraisalReviewSection({ onTabComplete }: AppraisalReviewSectionProps) {
    const [ has1004D, setHas1004D ] = useState<boolean>();
    const [ hasInteriorPhotos, setHasInteriorPhotos ] = useState<boolean>();
    const [ isRuralOrOver10Acres, setIsRuralOrOver10Acres ] = useState<boolean>();

    return (
        <UnderwritingStepSection title="Appraisal review">
            <UnderwritingYesNoQuestionCard
                question='Is there a 1004D (completion report) for the "subject to" appraisal?'
                onAnswered={setHas1004D}
                answer={has1004D}
            />

            <div className={styles.questionRow}>
                {has1004D !== undefined && (/* TODO only show if non-exterior-only report */
                    <UnderwritingYesNoQuestionCard
                        question="Does the report contain interior photos?"
                        onAnswered={setHasInteriorPhotos}
                        answer={hasInteriorPhotos}
                    />
                )}

                {hasInteriorPhotos !== undefined && (
                    <UnderwritingYesNoQuestionCard
                        question="Is the property rural or over 10 acres?"
                        onAnswered={setIsRuralOrOver10Acres}
                        answer={isRuralOrOver10Acres}
                    />
                )}
            </div>

            {isRuralOrOver10Acres !== undefined && (
                <UnderwritingYesNoQuestionCard
                    question={(
                        <Typography fontWeight={500}>
                            Is the appraised value of $415,000 supported?
                        </Typography>
                    )}
                    onAnswered={() => onTabComplete('review', 'appraiser')}
                    answer={undefined}
                />
            )}
        </UnderwritingStepSection>
    );
}
