import { Contact } from '@api';

import EditableSectionCard from '../../../components/EditableSectionCard';

import styles from './CustomerPrimaryContactCard.module.scss';
import ContactDisplay from './dialogs/ContactDisplay';


interface CustomerPrimaryContactCardProps {
    primaryContact: Contact;
}

export default function CustomerPrimaryContactCard({ primaryContact }: CustomerPrimaryContactCardProps) {
    return (
        <EditableSectionCard
            header="Primary contact"
            editTo="edit-primary-contact"
        >
            <ContactDisplay
                className={styles.root}
                contact={primaryContact}
            />
        </EditableSectionCard>
    );
}
