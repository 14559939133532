import api, { Role, UserType, userTypeDisplay } from '@api';
import { useGetCurrentAccount } from '@utils/hooks';
import { createContext, useCallback } from 'react';

import AdminPageTemplate, {
    AdminEntityGroup, AdminEntityGroupProps, AdminPageContextValues, defaultAdminPageContextValues
} from '../components/AdminPageTemplate';

import RoleDetailPage from './RoleDetailPage';
import { RoleCard } from './components/RoleCard';
import RoleForm from './components/RoleForm';


export const RoleManagementPageContext = createContext<AdminPageContextValues<Role>>(defaultAdminPageContextValues);

export default function RoleManagementPage() {
    const { accountUserType, customerId } = useGetCurrentAccount();
    const fetchRoles = useCallback(() => (
        api.roles.getRoles(accountUserType, customerId)
    ), [ accountUserType, customerId ]);

    return (
        <AdminPageTemplate
            Context={RoleManagementPageContext}
            CreateEditForm={RoleForm}
            DetailPage={RoleDetailPage}
            entityName="role"
            EntityGroupComponent={RoleGroup}
            fetchEntities={fetchRoles}
            filterByLabel="name or description"
            filterEntity={({ name, description }, filterInputValue) => (
                name.toLocaleLowerCase().includes(filterInputValue)
                || description.toLocaleLowerCase().includes(filterInputValue)
            )}
            visibleGroups={roleTypeMap[accountUserType]}
        />
    );
}

function RoleGroup({
    entities: customers,
    group: userTypeProp
}: AdminEntityGroupProps<Role, UserType>) {
    return (
        <AdminEntityGroup header={`${userTypeDisplay[userTypeProp]} roles`}>
            {customers
                .filter(({ userType }) => userType === userTypeProp)
                .map((role) => (
                    <RoleCard
                        key={role.id}
                        role={role}
                    />
                ))}
        </AdminEntityGroup>
    );
}

const { INTERNAL, CLIENT, CUSTOMER } = UserType;

const roleTypeMap = {
    [INTERNAL]: [ INTERNAL ],
    [CLIENT]: [ CLIENT, CUSTOMER ],
    [CUSTOMER]: [ CUSTOMER ]
};
