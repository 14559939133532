import api, { Role } from '@api';
import { ContentCopy, RemoveCircleOutline, Star } from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { ProgressIndicator, SubtleLink } from '@tsp-ui/core/components';
import { useConfirm, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { MouseEventHandler, useContext, useState } from 'react';

import { RoleManagementPageContext } from '../RoleManagementPage';

import styles from './RoleCard.module.scss';


interface RoleCardProps {
    role: Role;
}

export function RoleCard({
    role: {
        id, name, description, isCustom, userType: roleUserType
    }
}: RoleCardProps) {
    const [ isCloning, setIsCloning ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);

    const { entities: roles, setEntities: setRoles } = useContext(RoleManagementPageContext);
    const pageMessage = usePageMessage();
    const confirm = useConfirm();

    const { accountUserType, customerId } = useGetCurrentAccount();

    const cloneRole: MouseEventHandler<HTMLButtonElement> = async event => {
        event.preventDefault();

        setIsCloning(true);

        try {
            setRoles(roles.concat([
                await api.roles.addRole(accountUserType, {
                    name: `${name} copy`,
                    description,
                    isCustom: true,
                    userType: roleUserType
                }, customerId)
            ]));

            pageMessage.success('Role cloned');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while cloning the role', error);
        }

        setIsCloning(false);
    };

    const deleteRole: MouseEventHandler<HTMLButtonElement> = async event => {
        event.preventDefault();

        setIsDeleting(true);

        try {
            if (await confirm('Are you sure you want to delete this role?')) {
                await api.roles.deleteRole(accountUserType, id);

                setRoles(roles.filter(role => role.id !== id));

                pageMessage.success('Role deleted');
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while deleting the role', error);
        }

        setIsDeleting(false);
    };

    return (
        <Paper
            className={styles.root}
            elevation={0}
        >
            <div>
                <span>
                    <SubtleLink to={`${id}`}>
                        {name}
                    </SubtleLink>

                    {isCustom && (
                        <Tooltip title="Custom role">
                            <Star
                                fontSize="small"
                                color="primary"
                            />
                        </Tooltip>
                    )}
                </span>

                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {description}
                </Typography>
            </div>

            <div className={styles.rightContainer}>
                <div className={styles.buttonContainer}>
                    {isCloning ? (
                        <ProgressIndicator className={styles.progress} />
                    ) : (
                        <Tooltip title="Clone">
                            <IconButton onClick={cloneRole}>
                                <ContentCopy color="secondary" />
                            </IconButton>
                        </Tooltip>
                    )}

                    {isDeleting ? (
                        <ProgressIndicator className={styles.progress} />
                    ) : (
                        <Tooltip title={isCustom ? 'Delete' : 'Non-custom roles can not be deleted'}>
                            <span>
                                <IconButton
                                    onClick={deleteRole}
                                    disabled={!isCustom}
                                >
                                    <RemoveCircleOutline color={isCustom ? 'error' : 'disabled'} />
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}
                </div>
            </div>
        </Paper>
    );
}
