export enum SpecialtyProgram {
    FANNIE_MAE_HOMEREADY = 'FANNIE_MAE_HOMEREADY',
    FREDDIE_MAC_HOMEPOSSIBLE = 'FREDDIE_MAC_HOMEPOSSIBLE',
    FANNIE_MAE_HOMESTYLE = 'FANNIE_MAE_HOMESTYLE',
    FREDDIE_MAC_HOMEONE = 'FREDDIE_MAC_HOMEONE',
    VA_IRRRL = 'VA_IRRRL',
    CONSTRUCTION_TO_PERMANENT = 'CONSTRUCTION_TO_PERMANENT'
}

export const specialtyProgramDisplay = {
    [SpecialtyProgram.FANNIE_MAE_HOMEREADY]: 'Fannie Mae HomeReady',
    [SpecialtyProgram.FREDDIE_MAC_HOMEPOSSIBLE]: 'Freddie Mac HomePossible',
    [SpecialtyProgram.FANNIE_MAE_HOMESTYLE]: 'Fannie Mae HomeStyle',
    [SpecialtyProgram.FREDDIE_MAC_HOMEONE]: 'Freddie Mac HomeOne',
    [SpecialtyProgram.VA_IRRRL]: 'VA IRRRL',
    [SpecialtyProgram.CONSTRUCTION_TO_PERMANENT]: 'Construction-to-Permanent'
};
