import { Link as MuiLink } from '@mui/material';
import { LabelGroup, LabeledValue } from '@tsp-ui/core/components';
import { useGetInvestor, useGetLoanProgram } from '@utils/hooks';
import EditableSectionCard from '@views/admin/components/EditableSectionCard';
import { Link } from 'react-router-dom';


export default function LoanProgramDetailsCard() {
    const [ { name: investorName } ] = useGetInvestor();
    const [ { name, description } ] = useGetLoanProgram();

    return (
        <EditableSectionCard
            header={name}
            editTo="edit"
        >
            <LabelGroup>
                <LabeledValue
                    label="Investor:"
                    value={(
                        <MuiLink
                            component={Link}
                            to=".."
                        >
                            {investorName}
                        </MuiLink>
                    )}
                    variants={{ value: 'body1' }}
                />

                <LabeledValue
                    label="Description:"
                    value={description}
                    variants={{ value: 'body1' }}
                />
            </LabelGroup>
        </EditableSectionCard>
    );
}
