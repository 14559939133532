import api, { ReferenceGuideSection } from '@api';
import { RemoveCircleOutline } from '@mui/icons-material';
import {
    Button, DialogContent, IconButton, Tooltip, Typography
} from '@mui/material';
import {
    DragTarget,
    ProgressIndicator, RoutedDialog, RoutedDialogManager, RoutedDialogProps
} from '@tsp-ui/core/components';
import { removeItemById, useConfirm, usePageMessage } from '@tsp-ui/core/utils';
import {
    DragEvent, createContext, useContext, useState
} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import AdminPageTemplate, {
    AdminEntityGroup, AdminEntityGroupProps, AdminPageContextValues, defaultAdminPageContextValues
} from '../components/AdminPageTemplate';

import styles from './ClientReferenceGuideManagementPage.module.scss';
import CustomerReferenceGuideManagementPage from './CustomerReferenceGuideManagementPage';
import { ClientReferenceGuideCard } from './components/ClientReferenceGuideCard';
import { ReferenceGuideDialog } from './components/ReferenceGuideDialog';
import { ReferenceGuideSectionDialog } from './components/ReferenceGuideSectionDialog';


export const ReferenceGuideManagementPageContext = createContext<AdminPageContextValues<ReferenceGuideSection>>(
    defaultAdminPageContextValues
);

export default function ClientReferenceGuideManagementPage() {
    return (
        <AdminPageTemplate
            entityName="reference guide"
            Context={ReferenceGuideManagementPageContext}
            labelOverrides={{
                addButton: 'Add section',
                filterPlaceholder: 'Filter files',
                header: 'Reference guide management'
            }}
            headerActions={(
                <Button
                    component={Link}
                    to="customer-preview"
                >
                    Show customer preview
                </Button>
            )}
            EntityGroupComponent={ReferenceGuideGroup}
            fetchEntities={api.referenceGuides.getReferenceGuideSections}
            filterByLabel="file name"
            filterEntities={(entities, filterInputValue) => (
                entities.map(section => ({
                    ...section,
                    files: section.files.filter(({ title }) => (
                        title.toLocaleLowerCase().includes(filterInputValue)
                    ))
                }))
            )}
            autoFocusFilter={false}
            getVisibleGroups={(referenceGuides) => referenceGuides.map(referenceGuide => referenceGuide.id)}
        >
            <RoutedDialogManager routes={routes} />
        </AdminPageTemplate>
    );
}

const routes = {
    new: ReferenceGuideSectionDialog,
    ':referenceGuideSectionID/:referenceGuideID/edit': ReferenceGuideDialog,
    ':referenceGuideSectionID/new': ReferenceGuideDialog,
    'customer-preview': (props: Omit<RoutedDialogProps, 'title'>) => (
        <RoutedDialog
            {...props}
            maxWidth="lg"
            title="Reference guides - customer view"
        >
            <DialogContent>
                <CustomerReferenceGuideManagementPage className={styles.dialogPage} />
            </DialogContent>
        </RoutedDialog>
    )
};

function ReferenceGuideGroup({
    group: referenceGuideSectionID,
    entities: referenceGuideSections,
    filterText
}: AdminEntityGroupProps<ReferenceGuideSection, string>) {
    const [ isDeleting, setIsDeleting ] = useState(false);

    const { files, title } = referenceGuideSections.find(({ id }) => id === referenceGuideSectionID) || {};

    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const confirm = useConfirm();

    const { setEntities: setReferenceGuideSections } = useContext(ReferenceGuideManagementPageContext);

    function addFile(fileList: FileList | null) {
        if (!fileList || fileList.length === 0) {
            return;
        } else if (fileList.length > 1) {
            pageMessage.error('Please add only one file at a time');
            return;
        }

        navigate(`${referenceGuideSectionID}/new`, {
            state: {
                referenceGuideUpload: {
                    file: fileList[0],
                    sectionID: referenceGuideSectionID,
                    title: fileList[0].name
                }
            }
        });
    }

    async function onDelete() {
        try {
            if (await confirm(
                'This action will delete all reference guides that belong to this section.'
                + ' Are you sure you want to continue?'
            )) {
                setIsDeleting(true);

                await api.referenceGuides.deleteReferenceGuideSection(
                    referenceGuideSectionID
                );

                setReferenceGuideSections(referenceGuideSections => removeItemById(
                    referenceGuideSections, referenceGuideSectionID
                ));

                pageMessage.success('Section deleted');
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while deleting the reference guide section', error);
        } finally {
            setIsDeleting(false);
        }
    }

    return (
        <DragTarget
            onDrop={(event) => addFile(event.dataTransfer.files)}
            validateDragEvent={(event) => !shouldIgnoreEvent(event)}
        >
            <AdminEntityGroup
                classes={{ header: styles.groupHeader }}
                noResultsMessage={filterText ? 'No reference guides match your filter text' : (
                    <Typography>
                        No reference guides uploaded yet.

                        <Button size="small">
                            browse files
                        </Button> to get started.
                    </Typography>
                )}
                header={(
                    <>
                        {title}

                        <label
                            htmlFor={`file-upload-${referenceGuideSectionID}`}
                            className={styles.label}
                        >
                            <input
                                id={`file-upload-${referenceGuideSectionID}`}
                                className={styles.hidden}
                                type="file"
                                onChange={e => addFile(e.target.files)}
                            />

                            <Typography
                                variant="caption"
                                color="textSecondary"
                                whiteSpace="nowrap"
                            >
                                Drag files here or
                            </Typography>

                            <Button
                                component="span"
                                size="small"
                            >
                                browse files
                            </Button>
                        </label>

                        {isDeleting ? <ProgressIndicator className={styles.progress} /> : (
                            <Tooltip title="Remove section">
                                <IconButton
                                    edge="start"
                                    className={styles.removeGroupButton}
                                    onClick={onDelete}
                                >
                                    <RemoveCircleOutline color="error" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                )}
            >
                {!!files?.length && files.map(file => (
                    <ClientReferenceGuideCard
                        key={file.id}
                        referenceGuideFile={file}
                    />
                ))}
            </AdminEntityGroup>
        </DragTarget>
    );
}

function isElement(target: EventTarget | null): target is Element {
    return !!target && 'className' in target && 'contains' in target;
}

function isParent(parent: Element, child: Element) {
    return parent.className.includes?.('DragTarget_dropZone') && parent.contains(child);
}

function shouldIgnoreEvent({ target, relatedTarget, type }: DragEvent<HTMLDivElement>) {
    if (isElement(target) && isElement(relatedTarget)) {
        const eventIsFromChild = isParent(target, relatedTarget) || isParent(relatedTarget, target);
        return type === 'dragleave' && eventIsFromChild;
    }
}
