import { ProductHistoryEvent, productEventTypeDisplay } from '@api';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { TimelineEventCard } from '@tsp-ui/core/components';
import { getFullName } from '@tsp-ui/core/utils';
import { parseISO } from 'date-fns';
import { useState } from 'react';

import { ProductDetailsCard } from './ProductDetailsCard';
import styles from './ProductHistoryEventCard.module.scss';


interface HistoryEventCardProps {
    event: ProductHistoryEvent;
}

export default function ProductHistoryEventCard({ event } : HistoryEventCardProps) {
    const [ expanded, setExpanded ] = useState(false);
    const {
        eventType, executedBy, date, before, after
    } = event;

    return (
        <TimelineEventCard
            header={productEventTypeDisplay[eventType]}
            className={styles.root}
            actions={(
                <div className={styles.buttonContainer}>
                    <Tooltip title={`Show ${expanded ? 'less' : 'more'}`}>
                        <IconButton
                            edge="end"
                            className={styles.expandButton}
                            onClick={() => setExpanded(!expanded)}
                            size="small"
                        >
                            {expanded ? (
                                <ExpandLess
                                    color="secondary"
                                    fontSize="small"
                                />
                            ) : (
                                <ExpandMore
                                    color="secondary"
                                    fontSize="small"
                                />
                            )}
                        </IconButton>
                    </Tooltip>
                </div>
            )}
            secondaryText={`By ${getFullName(executedBy)}`}
            date={parseISO(date)}
        >
            {expanded && (
                <ProductDetailsCard
                    product={before}
                    updatedProduct={after}
                />
            )}
        </TimelineEventCard>
    );
}
