import { Button, Paper } from '@mui/material';
import { PaperSaveLoader } from '@tsp-ui/core/components';
import {
    ComponentType, Dispatch, SetStateAction, useState
} from 'react';
import { Link } from 'react-router-dom';

import Page from '../../components/Page';

import styles from './AdminAddPageTemplate.module.scss';
import { AdminEntityName } from './AdminPageTemplate';


export interface AdminAddEditFormProps<TEntityType> {
    setLoading: Dispatch<SetStateAction<boolean>>;
    entityToEdit?: TEntityType;
    onSubmit: (updatedEntity: TEntityType) => void;
}

export type AdminAddEditForm<TEntityType> = ComponentType<AdminAddEditFormProps<TEntityType>> & {
    formID: string;
};

export interface AdminAddPageTemplatePropsBase<TEntityType> {
    entityName: AdminEntityName;
    Form: AdminAddEditForm<TEntityType>;
    onFormSubmit: AdminAddEditFormProps<TEntityType>['onSubmit'];
}

export type AdminAddPageTemplateProps<TEntityType> = Object & AdminAddPageTemplatePropsBase<TEntityType>;

export default function AdminAddPageTemplate<TEntityType>({
    entityName,
    Form,
    onFormSubmit
}: AdminAddPageTemplateProps<TEntityType>) {
    const [ loading, setLoading ] = useState(false);

    return (
        <Page
            variant="centered"
            header={`Add ${entityName}`}
        >
            <Paper className={styles.root}>
                <Form
                    setLoading={setLoading}
                    onSubmit={onFormSubmit}
                />

                <div className={styles.buttons}>
                    <Button
                        component={Link}
                        replace
                        to=".."
                    >
                        Cancel
                    </Button>

                    <Button
                        form={Form.formID}
                        variant="contained"
                        type="submit"
                    >
                        Save
                    </Button>
                </div>

                <PaperSaveLoader loading={loading} />
            </Paper>
        </Page>
    );
}
