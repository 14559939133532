import { NotificationType, PaginatedGetParams } from '@api';
import { PaginatedResponse } from '@tsp-ui/core';

import { apiUtils } from '../api-utils';


export function getUnreadNotifications(): Promise<PaginatedResponse<Notification>> {
    return apiUtils.get<GetNotificationsParams>('/notification', {
        isRead: false,
        pageSize: 25,
        pageNumber: 1
    });
}

export function getAllNotifications(params: GetNotificationsParams): Promise<PaginatedResponse<Notification>> {
    return apiUtils.get<GetNotificationsParams>('/notification', {
        ...params,
        pageSize: 25
    });
}

export function updateNotification(
    notificationID: string, notificiationUpdates: Partial<Notification>
): Promise<Notification> {
    return apiUtils.patch(`/notification/${notificationID}`, notificiationUpdates);
}

export interface Notification {
    id: string;
    type: NotificationType;
    description: string;
    loanNumber: string;
    createdDate: string;
    isRead: boolean; // represents if user has clicked on or dismissed the notification
    isViewed: boolean; // represents if user has viewed the noticiation in dropdown
}

interface GetNotificationsParams extends PaginatedGetParams {
    isRead?: boolean;
    date?: string | null;
    type?: NotificationType;
    loanNumber?: string;
}
