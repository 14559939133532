import {
    CommitmentType, ProductType, ServicingType, UnderwritingType
} from '../..';
import { apiUtils } from '../../api-utils';


export function updateEligibleProducts(
    customerID: string, productConfig: EligibleProductsConfig
): Promise<EligibleProductsConfig> {
    return apiUtils.put(`/customer/${customerID}/product/${productConfig.id}`, productConfig);
}

export interface EligibleProductsConfig {
    id: string;
    allProductsLimit: LimitConfig | null;
    delegatedLimit: LimitConfig | null;
    nonDelegatedLimit: LimitConfig | null;
    productTypeConfigs: ProductTypeConfig[];
}

export interface LimitConfig {
    id: string;
    currentAmount: number;
    limitAmount: number;
    expirationDate: string;
}

export interface ProductTypeConfig {
    id: string;
    productType: ProductType;
    underwritingType: UnderwritingType;

    servicingType: ServicingType; // TODO post-demo this should be an array of supported types
    commitmentTypes: CommitmentType[];
    lockPeriods: number[];

    limit: LimitConfig | null;
    productConfigs: ProductConfig[];
}

export interface ProductConfig {
    id: string;
    name: string;
    limit: LimitConfig | null;
}
