import {
    Address, ClientInvestor, InvestorStatus, ManagedEligibilityStatus, State
} from '@api';
import { getRandomItemFromArray } from '@tsp-ui/core';

/**
 * Actual msw mocks for clientInvestor endpoints are in investor-mocks.ts,
 * because the endpoints for client and internal investors are shared
 */

const { ACTIVE, PENDING, INACTIVE } = InvestorStatus;
const { MANAGED, UNMANAGED } = ManagedEligibilityStatus;

const addresses: Address[] = [
    {
        street: '1234 Mystery Lane',
        city: 'Somewhere',
        state: State.SC,
        zip: '54321'
    }
];

export let clientInvestorID = 30;
export function incrementClientInvestorID() {
    clientInvestorID++;
}
export const agencyInvestors: ClientInvestor[] = [
    {
        id: `${clientInvestorID++}`,
        code: 'FNMA',
        name: 'Fannie Mae',
        legalName: 'Federal National Mortgage Association',
        isAgency: true,
        managedEligibilityStatus: MANAGED,
        activeClientIDs: [],
        status: ACTIVE,
        addresses,
        managedInvestorIsActive: true,
        sellerServicerNumber: '12345'
    },
    {
        id: `${clientInvestorID++}`,
        code: 'FHLMC',
        name: 'Freddie Mac',
        legalName: 'Federal Home Loan Mortgage Corporation',
        isAgency: true,
        managedEligibilityStatus: MANAGED,
        activeClientIDs: [],
        status: INACTIVE,
        addresses,
        managedInvestorIsActive: false,
        sellerServicerNumber: '98765'
    },
    {
        id: `${clientInvestorID++}`,
        code: 'GNMA',
        name: 'Ginnie Mae',
        legalName: 'Government National Mortgage Association',
        isAgency: true,
        managedEligibilityStatus: MANAGED,
        activeClientIDs: [ '1' ],
        status: INACTIVE,
        addresses,
        managedInvestorIsActive: false,
        sellerServicerNumber: '01010101'
    }
];

export const privateInvestors: ClientInvestor[] = [
    {
        id: `${clientInvestorID++}`,
        code: 'QQQ',
        name: 'Private Fund',
        legalName: 'Private Fund Inc',
        isAgency: false,
        managedEligibilityStatus: UNMANAGED,
        managedInvestorIsActive: false,
        activeClientIDs: [],
        status: ACTIVE,
        addresses,
        eligibilityBasedOnInvestorId: getRandomItemFromArray(agencyInvestors).id
    },
    {
        id: `${clientInvestorID++}`,
        code: 'XFH',
        name: 'Private Bank',
        legalName: 'Private Bank Corp',
        isAgency: false,
        managedEligibilityStatus: UNMANAGED,
        managedInvestorIsActive: false,
        activeClientIDs: [],
        status: ACTIVE,
        addresses
    },
    {
        id: `${clientInvestorID++}`,
        code: 'FFR',
        name: 'Private Investor',
        legalName: 'Private Investment Co',
        isAgency: false,
        managedEligibilityStatus: UNMANAGED,
        managedInvestorIsActive: false,
        activeClientIDs: [],
        status: INACTIVE,
        addresses,
        eligibilityBasedOnInvestorId: getRandomItemFromArray(agencyInvestors).id
    },
    {
        id: `${clientInvestorID++}`,
        code: 'KXQ',
        name: 'Private Institution',
        legalName: 'Private Inst Corp',
        isAgency: false,
        managedEligibilityStatus: UNMANAGED,
        managedInvestorIsActive: false,
        activeClientIDs: [],
        status: INACTIVE,
        addresses
    },
    {
        id: `${clientInvestorID++}`,
        code: 'NEWI',
        name: 'New Institution',
        legalName: 'New Inst Corp',
        isAgency: false,
        managedEligibilityStatus: UNMANAGED,
        managedInvestorIsActive: false,
        activeClientIDs: [],
        status: PENDING,
        addresses
    }
];

export const clientInvestors = agencyInvestors.concat(privateInvestors);
