import api from '@api';
import { capitalize, usePageMessage, useParams } from '@tsp-ui/core/utils';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { LockAvailabilityCard } from './LockAvailabilityCard';
import { LockAvailabilityContext, LockAvailabilityDay } from './LockAvailabilityPage';


interface LockWeekdayAvailabilityCardProps {
    lockAvailabilityDay: LockAvailabilityDay;
}

export function LockWeekdayAvailabilityCard({
    lockAvailabilityDay: {
        day, available, startTime, endTime
    }
}: LockWeekdayAvailabilityCardProps) {
    const { customerID } = useParams();
    const { lockAvailability, setLockAvailability } = useContext(LockAvailabilityContext);

    const navigate = useNavigate();
    const pageMessage = usePageMessage();

    return (
        <LockAvailabilityCard
            key={day}
            isAvailable={available}
            lockStart={startTime}
            lockEnd={endTime}
            title={capitalize(day)}
            onEdit={() => navigate(`${day}/edit`)}
            onToggle={async () => {
                try {
                    lockAvailability && setLockAvailability(await api.availability.updateLockAvailability({
                        ...lockAvailability,
                        [`is${capitalize(day)}Available`]: !available
                    }, customerID));
                } catch (error) {
                    pageMessage.handleApiError('An error occurred while toggling lock availability', error);
                }
            }}
        />
    );
}
