import api, { LoanDocument, LoanStatus, User } from '@api';
import {
    Close, Description, ExpandLess, ExpandMore, History, UnfoldLess, UnfoldMore
} from '@mui/icons-material';
import { Timeline } from '@mui/lab';
import {
    DialogContent, Divider, IconButton, Link, Popover, Tooltip, Typography
} from '@mui/material';
import {
    Dialog, DialogProps, FilterTextField, IconTypography
} from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage, useParams } from '@tsp-ui/core/utils';
import {
    Fragment, ReactNode, useCallback, useEffect, useRef, useState
} from 'react';
import { useDebounce } from 'use-debounce';

import styles from './LoanDocumentsDialog.module.scss';
import { LoanTimelineContactTimeDisplay, LoanTimelineItem } from './LoanTimeline';


interface LoanDocumentsDialogProps extends Omit<DialogProps, 'title'> {
    loanID?: string;
    loanNumber: string;
    loanStatus: LoanStatus;
}

export default function LoanDocumentsDialog({
    loanID: loanIdProp, loanNumber, loanStatus, ...props
}: LoanDocumentsDialogProps) {
    const [ documents, setDocuments ] = useState<LoanDocument[]>();
    const [ expandAll, setExpandAll ] = useState(0);
    const [ collapseAll, setCollapseAll ] = useState(0);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ debouncedTerm ] = useDebounce(searchTerm, 300);

    const params = useParams<{ loanID: string }>();
    const loanID = loanIdProp || params.loanID;

    const pageMessage = usePageMessage();

    const filteredDocuments = !debouncedTerm ? documents : documents?.filter(
        ({ name, containerName }) => name.toLowerCase().includes(
            debouncedTerm.toLowerCase()
        ) || containerName.toLowerCase().includes(debouncedTerm.toLowerCase())
    );

    const containers = [ ...new Set(filteredDocuments?.map(doc => doc.containerName)) ];

    useAsyncEffect(useCallback(async () => {
        try {
            setDocuments(await api.document.getLoanDocuments(loanID, loanStatus));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loan documents', error);
        }
    }, [
        pageMessage, loanID, loanStatus
    ]));

    return (
        <Dialog
            {...props}
            title="Loan documents"
            maxWidth={false}
            loading={!documents}
        >
            <DialogContent className={styles.headerContent}>
                <Typography>
                    Loan #

                    <Typography
                        component="span"
                        fontWeight={500}
                    >
                        {loanNumber}
                    </Typography>
                </Typography>

                <FilterTextField
                    placeholder="Find a loan document"
                    helperText="Search by document or container name"
                    className={styles.filterField}
                    onChange={(event) => setSearchTerm(event.target.value.toLocaleLowerCase())}
                />

                <div className={styles.expandCollapseAllButtons}>
                    <Tooltip title="Expand all">
                        <IconButton
                            size="small"
                            onClick={() => setExpandAll(expandAll + 1)}
                        >
                            <UnfoldMore color="secondary" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Collapse all">
                        <IconButton
                            size="small"
                            onClick={() => setCollapseAll(collapseAll + 1)}
                        >
                            <UnfoldLess color="secondary" />
                        </IconButton>
                    </Tooltip>
                </div>
            </DialogContent>

            <Divider className={styles.headerDivider} />

            <DialogContent className={styles.mainContent}>
                <div className={styles.borderedList}>
                    {containers.map((containerName, index) => (
                        <Fragment key={containerName}>
                            <DocumentContainerCard
                                title={containerName}
                                documents={filteredDocuments || []}
                                expandAll={expandAll}
                                collapseAll={collapseAll}
                            />

                            {index < containers.length - 1 && <Divider className={styles.divider} /> }
                        </Fragment>
                    ))}

                    {!containers.length && (
                        <Typography>
                            No documents {documents?.length ? 'match your search prompt' : 'exist for this loan'}
                        </Typography>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
}

interface DocumentContainerCardProps {
    title: ReactNode;
    documents: LoanDocument[];
    expandAll: number; // using numbers for expand & collapse all allows us to track when an expand/collapse all event
    collapseAll: number; // takes place while keeping the expanded state local to this component
}

function DocumentContainerCard({
    title, documents, expandAll, collapseAll
}: DocumentContainerCardProps) {
    const [ expanded, setExpanded ] = useState(false);

    const containerDocuments = documents.filter(
        document => document.containerName === title
    );

    useEffect(() => setExpanded(true), [ expandAll ]);
    useEffect(() => setExpanded(false), [ collapseAll ]);

    return (
        <>
            <BorderedListItem
                title={(
                    <div className={styles.itemHeader}>
                        <IconButton
                            size="small"
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? (
                                <ExpandLess color="secondary" />
                            ) : (
                                <ExpandMore color="secondary" />
                            )}
                        </IconButton>

                        {title}
                    </div>
                )}
                icons={(
                    <IconTypography
                        compact
                        icon={(
                            <Tooltip title={`${containerDocuments.length} document${containerDocuments.length > 1 ? 's' : ''} in this container`}>
                                <Description
                                    color="primary"
                                    fontSize="small"
                                />
                            </Tooltip>
                        )}
                    >
                        {containerDocuments.length}
                    </IconTypography>
                )}
            />

            {expanded && (
                <ul className={styles.fileList}>
                    {containerDocuments.map(document => (
                        <LoanDocumentItem
                            key={document.id}
                            documentId={document.id}
                            documents={documents}
                        />
                    ))}
                </ul>
            )}
        </>
    );
}

interface LoanDocumentItemProps {
    documents: LoanDocument[];
    documentId: string;
}

export function LoanDocumentItem({ documents, documentId }: LoanDocumentItemProps) {
    const { name, url } = documents.find(document => document.id === documentId)!;
    const liRef = useRef<HTMLLIElement | null>(null);
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null);
    const pageMessage = usePageMessage();

    const [ documentUrl, setDocumentUrl ] = useState('');

    // eslint-disable-next-line require-await
    useAsyncEffect(useCallback(async () => {
        try {
            setDocumentUrl(await api.document.getLoanDocumentURL(url));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching a document', error);
        }
    }, [ url, pageMessage ]));

    return (
        <li ref={liRef}>
            <div className={styles.fileContainer}>
                <Link
                    href={documentUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    {name}
                </Link>

                <Tooltip title="Show file history"/* TODO post-demo implement file history*/>
                    <IconButton
                        size="small"
                        onClick={(event) => {
                            event.preventDefault();
                            setAnchorEl(liRef.current);
                        }}
                    >
                        <History
                            color="secondary"
                            fontSize="small"
                        />
                    </IconButton>
                </Tooltip>
            </div>

            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top'
                }}
                PaperProps={{
                    className: styles.fileDetailsPopoverPaper
                }}
            >
                <div className={styles.fileDetailsHeader}>
                    <Typography fontWeight={500}>
                        File history
                    </Typography>

                    <IconButton
                        size="small"
                        className={styles.closeButton}
                        onClick={() => setAnchorEl(null)}
                    >
                        <Close fontSize="small" />
                    </IconButton>
                </div>

                <Timeline
                    position="left"
                    className={styles.fileTimeline}
                    nonce={undefined}
                    onResize={undefined}
                    onResizeCapture={undefined}
                >
                    {documents.filter(
                        ({ id }) => id === documentId
                    ).sort((a, b) => b.instanceCount - a.instanceCount).map((document, index, array) => (
                        <LoanTimelineItem
                            key={`${document.id}-v${array.length - index}`}
                            hideTopConnector={index === 0}
                            hideBottomConnector={index === array.length - 1}
                            icon={(
                                <div className={styles.timelineItem}>
                                    {array.length - index}
                                </div>
                            )}
                        >
                            <LoanTimelineContactTimeDisplay
                                text={(
                                    <Link onClick={async () => window.open(await api.document.getLoanDocumentURL(document.url), '_blank')}>
                                        {/* eslint-disable-next-line react/jsx-newline */}
                                        {document.name} - v{array.length - index}
                                    </Link>
                                )}
                                user={{
                                    firstName: 'Premicorr',
                                    lastName: 'System',
                                    email: 'contact@premicorr.com',
                                    phone: '123-456-7890'
                                } as User} // TODO
                                time="just now"
                            />
                        </LoanTimelineItem>
                    ))}
                </Timeline>
            </Popover>
        </li>
    );
}

interface BorderedListItemProps {
    title: ReactNode;
    icons: ReactNode;
}

function BorderedListItem({ title, icons }: BorderedListItemProps) {
    return (
        <div>
            <div className={styles.itemHeader}>
                {title}

                <div className={styles.icons}>
                    {icons}
                </div>
            </div>
        </div>
    );
}
