import {
    Button, Paper, TextField, Typography
} from '@mui/material';
import { useEffect, useState } from 'react';

import { CreditUnderwritingTabs } from '../../CreditUnderwritingPage';
import UnderwritingAnswerButtons from '../UnderwritingAnswerButtons';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';

import styles from './OtherLiabilitiesSection.module.scss';


enum DivorceDecreeAnswer {
    YES,
    NO,
    NOT_REQUIRED
}
const { YES, NO, NOT_REQUIRED } = DivorceDecreeAnswer;

interface OtherLiabilitiesSectionProps {
    setTab: (tab: CreditUnderwritingTabs) => void;
    onTabComplete: (tab: CreditUnderwritingTabs, nextTab: CreditUnderwritingTabs) => void;
}

export default function OtherLiabilitiesSection({ onTabComplete }: OtherLiabilitiesSectionProps) {
    const [ hasOtherLiabilities, setHasOtherLiabilities ] = useState<boolean>();
    const [ divorceDecreeAnswer, setDivorceDecreeAnswer ] = useState<DivorceDecreeAnswer>();

    useEffect(() => {
        if (hasOtherLiabilities === false && (divorceDecreeAnswer === NOT_REQUIRED || divorceDecreeAnswer === YES)) {
            onTabComplete('other-liabilities', 'liabilities');
        }
    }, [
        hasOtherLiabilities, divorceDecreeAnswer, onTabComplete
    ]);

    return (
        <UnderwritingStepSection title="Other liabilities">
            <UnderwritingYesNoQuestionCard
                question="Is the borrower required to pay Child support or Alimony?"
                onAnswered={setHasOtherLiabilities}
                answer={hasOtherLiabilities}
            />

            {hasOtherLiabilities !== undefined && (
                <Paper
                    variant="outlined"
                    className={styles.questionCard} /* TODO make UnderwritingQuestionCard customizable & use here?*/
                >
                    <Typography>
                        Is there a divorce decree on file?
                    </Typography>

                    <UnderwritingAnswerButtons
                        buttons={divorceDecreeButtons}
                        value={divorceDecreeAnswer}
                        setValue={setDivorceDecreeAnswer}
                    />
                </Paper>
            )}

            {hasOtherLiabilities === true && divorceDecreeAnswer !== undefined && (
                <Paper
                    variant="outlined"
                    className={styles.questionCard}
                >
                    <Typography>
                        How much is the borrower required to pay per month?
                    </Typography>

                    <div className={styles.amountFields}>
                        <TextField
                            label="Child support"
                            size="small"
                        />

                        <TextField
                            label="Alimony"
                            size="small"
                        />
                    </div>

                    <div className={styles.buttons}>
                        <Button
                            onClick={() => onTabComplete('other-liabilities', 'liabilities')}
                            variant="contained"
                        >
                            Next
                        </Button>
                    </div>
                </Paper>
            )}
        </UnderwritingStepSection>
    );
}

const divorceDecreeButtons = [
    {
        label: 'Not required',
        value: NOT_REQUIRED
    },
    {
        label: 'No',
        value: NO
    },
    {
        label: 'Yes',
        value: YES
    }
];
