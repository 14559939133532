import '@aws-amplify/ui-react/styles.css';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Typography } from '@mui/material';
import { AuthType, authenticateSession } from '@redux/auth';
import { useDispatch } from '@redux/store';
import { Loader } from '@tsp-ui/core/components';
import { useAsyncEffect, useRouteState } from '@tsp-ui/core/utils';
import { ComponentProps, useCallback, useState } from 'react';
import { Navigate } from 'react-router-dom';

import '../scss/amplify-theme.scss';

import GlobalErrorMessage from './GlobalErrorMessage';
import styles from './LoginPage.module.scss';
import UnauthedHeader from './components/UnauthedHeader';


const components: ComponentProps<typeof Authenticator>['components'] = {
    SignIn: {
        Header() {
            return (
                <Typography
                    variant="h6"
                    className="custom-sign-in-header"
                >
                    Sign in to your account
                </Typography>
            );
        }
    }
};

export default function LogInPage() {
    return (
        <>
            <UnauthedHeader />

            <Authenticator
                className={styles.root}
                components={components}
                loginMechanisms={[ 'email' ]}
                hideSignUp
            >
                {(props) => (
                    <AuthenticationRedirect signOut={props?.signOut} />
                )}
            </Authenticator>
        </>
    );
}

interface AuthenticationRedirectProps {
    signOut?: ReturnType<typeof useAuthenticator>['signOut']
}

interface LoginPageState {
    redirectTo?: string
}

function AuthenticationRedirect({ signOut }: AuthenticationRedirectProps) {
    const dispatch = useDispatch();

    const [ redirectTo, setRedirectTo ] = useState<string>();
    const [ showError, setShowError ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const { redirectTo: customRedirect } = useRouteState<LoginPageState>();

    useAsyncEffect(useCallback(async () => {
        setLoading(true);
        const authResult = await dispatch(authenticateSession(AuthType.LOGIN));
        setLoading(false);

        if (authenticateSession.fulfilled.match(authResult)) {
            setRedirectTo(customRedirect || '/accounts');
        } else if (authenticateSession.rejected.match(authResult)) {
            setShowError(true);
        }
    }, [ dispatch, customRedirect ]));

    return redirectTo ? (
        <Navigate to={redirectTo} />
    ) : showError ? (
        <GlobalErrorMessage onLogout={signOut} />
    ) : loading ? (
        <Loader loading />
    ) : null;
}
