import { Typography } from '@mui/material';

import Page from './components/Page';


export default function NotFoundPage() {
    return (
        <Page header="Page not found">
            <Typography>
                Click an item on the menu to find what you're looking for.
            </Typography>
        </Page>
    );
}
