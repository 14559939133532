import api, {
    Contact, CustomerPendingSteps, Role, User, UserType
} from '@api';
import { RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import { replaceItemById, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { RolesContext } from '@views/admin/users/UserManagementPage';
import { useCallback, useContext, useState } from 'react';

import { CustomerDetailContext, CustomerDetailContextValue } from '../../CustomerDetailPage';

import { AddEditContactDialogContent } from './components/AddEditContactDialogContent';
import { AddEditUserDialogContent } from './components/AddEditUserDialogContent';
import { UsersAndContactsListDialogContent } from './components/UsersAndContactsListDialogContent';


enum DialogType {
    ADD_CONTACT,
    EDIT_CONTACT,
    ADD_USER,
    EDIT_USER,
    LIST
}

const {
    ADD_CONTACT, EDIT_CONTACT, ADD_USER, EDIT_USER, LIST
} = DialogType;

const dialogTitles = {
    [ADD_CONTACT]: 'Add contact',
    [EDIT_CONTACT]: 'Edit contact',
    [ADD_USER]: 'Add user',
    [EDIT_USER]: 'Edit user',
    [LIST]: 'Users & contacts'
};

export type UserOrContact = 'user' | 'contact';

function isUser(item: Contact | User): item is User {
    return item.hasOwnProperty('roleIds');
}

function sortByPrimaryContact(contacts: Contact[]) {
    return contacts.sort((baseContact, comparedContact) => +comparedContact.isPrimary - +baseContact.isPrimary);
}

export default function UsersAndContactsDialog(props: Omit<RoutedDialogProps, 'title' | 'children' | 'onSubmit'>) {
    const [ dialogType, setDialogType ] = useState(LIST);
    const [ users, setUsers ] = useState<User[]>([]);
    const [ contacts, setContacts ] = useState<Contact[]>([]);
    const [ defaultUser, setDefaultUser ] = useState<User>();
    const [ defaultContact, setDefaultContact ] = useState<Contact>();
    const [ roles, setRoles ] = useState<Role[]>([]);
    const [ loading, setLoading ] = useState(false);
    const [ saveLoading, setSaveLoading ] = useState(false);

    const pageMessage = usePageMessage();
    const {
        customer, updatePendingSteps, updateCustomer
    } = useContext(CustomerDetailContext) as Required<CustomerDetailContextValue>;
    const { id } = customer!;

    const rolesContextValue = {
        entities: roles,
        setEntities: setRoles
    };

    useAsyncEffect(useCallback(async () => {
        try {
            setRoles(await api.roles.getRoles(UserType.CUSTOMER, id));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching user roles', error);
        }
    }, [ id, pageMessage ]));

    useAsyncEffect(useCallback(async () => {
        if (id) {
            setLoading(true);

            try {
                setUsers(await api.users.getUsers(UserType.CUSTOMER, id));
                setContacts(
                    sortByPrimaryContact(await api.customer.contacts.getContacts(id))
                );
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching users and contacts', error);
            }

            setLoading(false);
        }
    }, [ id, pageMessage ]));

    async function handleDelete(userID: string, type: UserOrContact) {
        setSaveLoading(true);

        if (type === 'user') {
            try {
                await api.users.deleteUser(UserType.CUSTOMER, userID, id);

                setUsers(users.filter(user => user.id !== userID));

                pageMessage.success('User deleted');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deleting the user', error);
            }
        } else {
            try {
                await api.customer.contacts.deleteContact(id, userID);

                setContacts(contacts.filter(contact => contact.id !== userID));

                pageMessage.success('Contact deleted');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deleting the contact', error);
            }
        }

        setSaveLoading(false);
    }

    function handleAddUser(newUser: User) { // TODO post-demo make this redirect to list view
        setUsers([ ...users, newUser ]);
    }

    function handleEditUser(editedUser: User) { // TODO post-demo make this redirect to list view
        setUsers(replaceItemById(users, editedUser));
    }

    async function handleAddContact(formValues: Contact) { // TODO post-demo make this redirect to list view
        setContacts([
            ...contacts,
            await api.customer.contacts.addContact(customer.id, formValues)
        ]);

        if (formValues.isPrimary) {
            await updatePendingSteps(CustomerPendingSteps.USERS_CONTACTS);
        }
    }

    async function handleEditContact(formValues: Contact) { // TODO post-demo make this redirect to list view
        const editedContact = await api.customer.contacts.editContact(customer.id, formValues);

        setContacts(
            sortByPrimaryContact(contacts.map(contact => (
                contact.id === editedContact.id ? editedContact : ({
                    ...contact,
                    ...(editedContact.isPrimary && contact.isPrimary && {
                        isPrimary: false
                    })
                })
            )))
        );

        if (editedContact.isPrimary) {
            updateCustomer({
                ...customer,
                primaryContact: editedContact
            });
        }
    }

    function handleAddClick(type: UserOrContact) {
        if (type === 'user') {
            setDialogType(ADD_USER);
            setDefaultUser(undefined);
        } else {
            setDialogType(ADD_CONTACT);
            setDefaultContact(undefined);
        }
    }

    function handleEditClick(item: Contact | User) {
        if (isUser(item)) {
            setDialogType(EDIT_USER);
            setDefaultUser(item);
        } else {
            setDialogType(EDIT_CONTACT);
            setDefaultContact(item);
        }
    }

    function handleClose() {
        setDialogType(LIST);
        setDefaultUser(undefined);
        setDefaultContact(undefined);
    }

    return (
        <RolesContext.Provider value={rolesContextValue}>
            <RoutedDialog
                {...props}
                title={dialogTitles[dialogType]}
                onClose={handleClose}
                maxWidth={false}
                loading={loading}
            >
                {dialogType === LIST && (
                    <UsersAndContactsListDialogContent
                        users={users}
                        contacts={contacts}
                        onDelete={handleDelete}
                        onAddClick={handleAddClick}
                        onEditClick={handleEditClick}
                        loading={saveLoading}
                    />
                )}

                {(dialogType === ADD_USER || dialogType === EDIT_USER) && (
                    <AddEditUserDialogContent
                        user={defaultUser}
                        onAdd={handleAddUser}
                        onEdit={handleEditUser}
                        onDone={() => setDialogType(LIST)}
                        {...props}
                    />
                )}

                {(dialogType === ADD_CONTACT || dialogType === EDIT_CONTACT) && (
                    <AddEditContactDialogContent
                        defaultValues={defaultContact}
                        onAdd={handleAddContact}
                        onEdit={handleEditContact}
                        onDone={() => setDialogType(LIST)}
                        {...props}
                    />
                )}
            </RoutedDialog>
        </RolesContext.Provider>
    );
}
