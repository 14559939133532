import { LimitConfig } from '@api';
import { LinearProgress, Tooltip, Typography } from '@mui/material';
import { formatCurrency, formatCurrencyAbbreviation } from '@tsp-ui/core/utils';

import styles from './LimitDisplay.module.scss';


export interface LimitDisplayProps {
    isCompactRow?: boolean;
    limit: LimitConfig | null;
    name: string;
}

export function LimitDisplay({ isCompactRow, limit, name }: LimitDisplayProps) {
    const tooltipLimitAmounts = limit ? `${formatCurrency(limit.currentAmount)} / ${formatCurrency(limit.limitAmount)}` : 'No limit';
    const displayLimitAmounts = limit ? `${formatCurrencyAbbreviation(limit.currentAmount)} / ${formatCurrencyAbbreviation(limit.limitAmount)}` : 'No limit';

    return (
        <div className={styles.limitDisplay}>
            <Typography
                color="textPrimary"
                variant={isCompactRow ? 'caption' : 'body1'}
            >
                {name}
            </Typography>

            <Tooltip title={tooltipLimitAmounts}>
                <Typography
                    variant="caption"
                    component="p"
                    color="textSecondary"
                    align="right"
                    className={styles.limitAmounts}
                >
                    {displayLimitAmounts}
                </Typography>
            </Tooltip>

            <LinearProgress
                variant="determinate"
                color="inherit"
                value={limit ? (limit.currentAmount / limit.limitAmount) * 100 : 0}
                className={styles.progress}
            />
        </div>
    );
}
