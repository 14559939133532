import { Investor } from '@api';
import { ErrorOutline } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { AddressTypography, LabeledValue } from '@tsp-ui/core/components';

import EditableSectionCard from '../../../components/EditableSectionCard';

import styles from './InvestorDetailsSummaryCard.module.scss';


interface InvestorDetailSummaryCardProps {
    investor: Investor;
}

export default function InvestorDetailSummaryCard({
    investor: { code, name, addresses }
}: InvestorDetailSummaryCardProps) {
    return (
        <EditableSectionCard
            header={name}
            editTo="edit"
            className={styles.root}
        >
            <div className={styles.content}>
                <LabeledValue
                    label="Code"
                    value={code || (
                        <Tooltip title="Code is required for approval">
                            <ErrorOutline color="error" />
                        </Tooltip>
                    )}
                    variants={{ value: 'body1' }}
                    variant="vertical"
                />

                <div className={styles.addresses}>
                    {addresses.map((address, index) => (
                        <LabeledValue
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${JSON.stringify(address)}-${index}`}
                            label={`Address ${index + 1}`}
                            value={<AddressTypography address={address} />}
                            variants={{ value: 'body1' }}
                            variant="vertical"
                        />
                    ))}
                </div>
            </div>
        </EditableSectionCard>
    );
}
