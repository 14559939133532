import {
    Button, Paper, TextField, Typography
} from '@mui/material';
import { LabeledValue } from '@tsp-ui/core/components';

import { CreditUnderwritingTabs } from '../../CreditUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';

import styles from './CreditDetailsSection.module.scss';


interface CreditDetailsSectionProps {
    setTab: (tab: CreditUnderwritingTabs) => void;
    onTabComplete: (tab: CreditUnderwritingTabs, nextTab: CreditUnderwritingTabs) => void;
}

export default function CreditDetailsSection({ onTabComplete }: CreditDetailsSectionProps) {
    return (
        <UnderwritingStepSection title="Report details">
            <div className={styles.root}>
                <CreditDetailsCard name="William J Clinton" />
            </div>

            <Paper
                variant="outlined"
                className={styles.nextCard}
            >
                <Typography>
                    Verify credit report data and enter missing information
                </Typography>

                <div className={styles.buttons}>
                    <Button
                        variant="contained"
                        disableElevation
                        onClick={() => onTabComplete('details', 'inquiries')}
                    >
                        Next
                    </Button>
                </div>
            </Paper>
        </UnderwritingStepSection>
    );
}

function CreditDetailsCard({ name }: { name: string; }) {
    return (
        <Paper
            variant="outlined"
            className={styles.card}
        >
            <div className={styles.creditDetailsScoreRow}>
                <LabeledValue
                    label="Borrower name"
                    value={name}
                    variant="vertical"
                    classNames={{
                        value: styles.creditDetailsBorrowerName
                    }}
                />

                <LabeledValue
                    label="Report date"
                    value="09/19/2022"
                    variant="vertical"
                />

                <LabeledValue
                    label="Report #"
                    value="722254132677186"
                    variant="vertical"
                />

                <LabeledValue
                    label="Transunion"
                    value="720"
                    variant="vertical"
                />

                <LabeledValue
                    label="Equifax"
                    value={(
                        <TextField
                            variant="standard"
                            size="small"
                            className={styles.creditDetailsInput}
                        />
                    )}
                    variant="vertical"
                />

                <LabeledValue
                    label="Experian"
                    value="678"
                    variant="vertical"
                />
            </div>
        </Paper>
    );
}
