import { Contact } from '../..';
import { apiUtils } from '../../api-utils';


export function getContacts(customerID: string): Promise<Contact[]> {
    return apiUtils.get(`/customer/${customerID}/contact`);
}

export function deleteContact(customerID: string, contactID: string): Promise<void> {
    return apiUtils.delete(`/customer/${customerID}/contact/${contactID}`);
}

export function addContact(customerID: string, contact: Omit<Contact, 'id'>): Promise<Contact> {
    return apiUtils.post(`/customer/${customerID}/contact`, contact);
}

export function editContact(customerID: string, contact: Contact): Promise<Contact> {
    return apiUtils.put(`/customer/${customerID}/contact/${contact.id}`, contact);
}
