import { rest } from 'msw';

import { State } from '../..';
import { getMockUrl } from '../../../mocks/getMockUrl';

import { Branch } from './branches-api';


let newBranchID = 100;

export const mocks = [
    rest.get(getMockUrl('/customer/:customerID/branch'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(branches)
    ))),
    rest.post(getMockUrl('/customer/:customerID/branch'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as Branch,
            id: `${newBranchID++}`
        })
    ))),
    rest.put(getMockUrl('/customer/:customerID/branch/:branchID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    )))
];

export const branches: Branch[] = [
    {
        id: '1',
        dba: 'Branch 1',
        nmlsId: '123456',
        address: {
            street: '123 E St',
            line2: '#301',
            city: 'Phoenix',
            state: State.AZ,
            zip: '85250'
        }
    },
    {
        id: '2',
        dba: 'Branch 2',
        nmlsId: '234567',
        address: {
            street: '456 E St',
            city: 'Phoenix',
            state: State.AZ,
            zip: '85250'
        }
    },
    {
        id: '3',
        nmlsId: '345678',
        address: {
            street: '456 W St',
            line2: '#301',
            city: 'Phoenix',
            state: State.AZ,
            zip: '85250'
        }
    },
    {
        id: '4',
        nmlsId: '456789',
        address: {
            street: '123 W St',
            city: 'Phoenix',
            state: State.AZ,
            zip: '85250'
        }
    }
];
