export enum CustomerAction {
    // Branches
    BRANCH_ADDED = 'BRANCH_ADDED',
    BRANCH_UPDATED = 'BRANCH_UPDATED',

    // Compensation details
    COMPENSATION_DETAILS_UPDATED = 'COMPENSATION_DETAILS_UPDATED',

    // Contacts
    CONTACT_ADDED = 'CONTACT_ADDED',
    CONTACT_DELETED = 'CONTACT_DELETED',
    CONTACT_UPDATED = 'CONTACT_UPDATED',

    // Customer details
    CUSTOMER_DETAILS_UPDATED = 'CUSTOMER_DETAILS_UPDATED',

    // Due diligence
    DUE_DILIGENCE_ITEM_COMPLETED = 'DUE_DILIGENCE_ITEM_COMPLETED',
    DUE_DILIGENCE_ITEM_UPDATED = 'DUE_DILIGENCE_ITEM_UPDATED',
    DUE_DILIGENCE_DOCUMENT_UPLOADED = 'DUE_DILIGENCE_DOCUMENT_UPLOADED',
    DUE_DILIGENCE_DOCUMENT_DELETED = 'DUE_DILIGENCE_DOCUMENT_DELETED',

    // Eligible products
    ELIGIBLE_PRODUCTS_ALL_PRODUCTS_UPDATED = 'ELIGIBLE_PRODUCTS_ALL_PRODUCTS_UPDATED',

    ELIGIBLE_PRODUCTS_UW_CATEGORY_UPDATED = 'ELIGIBLE_PRODUCTS_UW_CATEGORY_UPDATED',
    ELIGIBLE_PRODUCTS_UW_CATEGORY_ENABLED = 'ELIGIBLE_PRODUCTS_UW_CATEGORY_ENABLED',
    ELIGIBLE_PRODUCTS_UW_CATEGORY_DISABLED = 'ELIGIBLE_PRODUCTS_UW_CATEGORY_DISABLED',

    ELIGIBLE_PRODUCTS_TYPE_UPDATED = 'ELIGIBLE_PRODUCTS_TYPE_UPDATED',
    ELIGIBLE_PRODUCTS_TYPE_ENABLED = 'ELIGIBLE_PRODUCTS_TYPE_ENABLED',
    ELIGIBLE_PRODUCTS_TYPE_DISABLED = 'ELIGIBLE_PRODUCTS_TYPE_DISABLED',

    ELIGIBLE_PRODUCTS_PRODUCT_UPDATED = 'ELIGIBLE_PRODUCTS_PRODUCT_UPDATED',
    ELIGIBLE_PRODUCTS_PRODUCT_ENABLED = 'ELIGIBLE_PRODUCTS_PRODUCT_ENABLED',
    ELIGIBLE_PRODUCTS_PRODUCT_DISABLED = 'ELIGIBLE_PRODUCTS_PRODUCT_DISABLED',

    // Fees
    FEE_ADJUSTED = 'FEE_ADJUSTED',
    FEE_DISABLED = 'FEE_DISABLED',
    FEE_ENABLED = 'FEE_ENABLED',

    // Licenses
    LICENSE_ADDED = 'LICENSE_ADDED',
    LICENSE_DELETED = 'LICENSE_DELETED',
    LICENSE_RENEWED = 'LICENSE_RENEWED',
    LICENSE_UPDATED = 'LICENSE_UPDATED',

    // Managers
    MANAGERS_UPDATED = 'MANAGERS_UPDATED',

    // Users
    USER_ADDED = 'USER_ADDED',
    USER_DELETED = 'USER_DELETED',
    USER_UPDATED = 'USER_UPDATED',

    // Wire instructions
    WIRE_INSTRUCTIONS_ACTIVATED = 'WIRE_INSTRUCTIONS_ACTIVATED',
    WIRE_INSTRUCTIONS_APPROVED = 'WIRE_INSTRUCTIONS_APPROVED',
    WIRE_INSTRUCTIONS_DEACTIVATED = 'WIRE_INSTRUCTIONS_DEACTIVATED',
    WIRE_INSTRUCTIONS_UPDATED = 'WIRE_INSTRUCTIONS_UPDATED'
}

export enum ActionCardsType {
    BRANCH,
    COMPENSATION_DETAILS,
    CONTACT,
    CUSTOMER_DETAILS,
    ELIGIBLE_PRODUCTS_LIMIT,
    ELIGIBLE_PRODUCTS_LIMIT_WITH_CONFIG,
    ELIGIBLE_PRODUCTS_ENABLED_DISABLED_WITH_PRODUCTS,
    ELIGIBLE_PRODUCTS_PRODUCT_ENABLED_DISABLED,
    DUE_DILIGENCE,
    FEE,
    LICENSE,
    MANAGERS,
    USER,
    WIRE_INSTRUCTIONS
}

export const customerActionDisplay = {
    [CustomerAction.BRANCH_ADDED]: 'Branch added',
    [CustomerAction.BRANCH_UPDATED]: 'Branch updated',
    [CustomerAction.COMPENSATION_DETAILS_UPDATED]: 'Compensation details updated',
    [CustomerAction.CONTACT_ADDED]: 'Contact added',
    [CustomerAction.CONTACT_DELETED]: 'Contact deleted',
    [CustomerAction.CONTACT_UPDATED]: 'Contact updated',
    [CustomerAction.CUSTOMER_DETAILS_UPDATED]: 'Customer details updated',
    [CustomerAction.ELIGIBLE_PRODUCTS_ALL_PRODUCTS_UPDATED]: 'All products limit updated',
    [CustomerAction.ELIGIBLE_PRODUCTS_UW_CATEGORY_UPDATED]: 'Underwriting category limit updated',
    [CustomerAction.ELIGIBLE_PRODUCTS_UW_CATEGORY_ENABLED]: 'Underwriting category enabled',
    [CustomerAction.ELIGIBLE_PRODUCTS_UW_CATEGORY_DISABLED]: 'Underwriting category disabled',
    [CustomerAction.ELIGIBLE_PRODUCTS_TYPE_UPDATED]: 'Product type updated',
    [CustomerAction.ELIGIBLE_PRODUCTS_TYPE_ENABLED]: 'Product type enabled',
    [CustomerAction.ELIGIBLE_PRODUCTS_TYPE_DISABLED]: 'Product type disabled',
    [CustomerAction.ELIGIBLE_PRODUCTS_PRODUCT_UPDATED]: 'Product limit updated',
    [CustomerAction.ELIGIBLE_PRODUCTS_PRODUCT_ENABLED]: 'Product enabled',
    [CustomerAction.ELIGIBLE_PRODUCTS_PRODUCT_DISABLED]: 'Product disabled',
    [CustomerAction.DUE_DILIGENCE_ITEM_COMPLETED]: 'Due diligence item completed',
    [CustomerAction.DUE_DILIGENCE_ITEM_UPDATED]: 'Due diligence item updated',
    [CustomerAction.DUE_DILIGENCE_DOCUMENT_DELETED]: 'Due diligence document deleted',
    [CustomerAction.DUE_DILIGENCE_DOCUMENT_UPLOADED]: 'Due diligence document uploaded',
    [CustomerAction.FEE_ADJUSTED]: 'Fee adjusted',
    [CustomerAction.FEE_DISABLED]: 'Fee disabled',
    [CustomerAction.FEE_ENABLED]: 'Fee enabled',
    [CustomerAction.LICENSE_ADDED]: 'License added',
    [CustomerAction.LICENSE_DELETED]: 'License deleted',
    [CustomerAction.LICENSE_RENEWED]: 'License renewed',
    [CustomerAction.LICENSE_UPDATED]: 'License updated',
    [CustomerAction.MANAGERS_UPDATED]: 'Managers updated',
    [CustomerAction.USER_ADDED]: 'User added',
    [CustomerAction.USER_DELETED]: 'User deleted',
    [CustomerAction.USER_UPDATED]: 'User updated',
    [CustomerAction.WIRE_INSTRUCTIONS_ACTIVATED]: 'Wire instructions activated',
    [CustomerAction.WIRE_INSTRUCTIONS_APPROVED]: 'Wire instructions approved',
    [CustomerAction.WIRE_INSTRUCTIONS_DEACTIVATED]: 'Wire instructions deactivated',
    [CustomerAction.WIRE_INSTRUCTIONS_UPDATED]: 'Wire instructions updated'
};
