import { Paper, Typography } from '@mui/material';

import styles from './EligibleProductsLimitCard.module.scss';


interface EligibleProductsProductToggledCardProps {
    product: string;
}

export default function EligibleProductsProductToggledCard({ product }: EligibleProductsProductToggledCardProps) {
    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            <Typography fontWeight="500">
                {product}
            </Typography>
        </Paper>
    );
}
