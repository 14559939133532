import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import styles from './LandingPage.module.scss';
import UnauthedHeader from './components/UnauthedHeader';


export default function LandingPage() {
    return (
        <>
            <UnauthedHeader />

            <Button
                component={Link}
                to="/login"
                variant="contained"
                className={styles.button}
            >
                Login
            </Button>
        </>
    );
}
