export enum ContactTitle {
    ACCOUNTANT = 'ACCOUNTANT',
    CAPITAL_MARKETS_MANAGER = 'CAPITAL_MARKETS_MANAGER',
    CEO = 'CEO',
    CFO = 'CFO',
    COLLATERAL_SHIPMENT = 'COLLATERAL_SHIPMENT',
    COMPLIANCE_MANAGER = 'COMPLIANCE_MANAGER',
    COMPLIANCE_OFFICER = 'COMPLIANCE_OFFICER',
    COO = 'COO',
    DIRECTOR_OF_POST_FUNDING = 'DIRECTOR_OF_POST_FUNDING',
    DIRECTOR_OF_SECONDARY = 'DIRECTOR_OF_SECONDARY',
    EVP_HOME_LENDING = 'EVP_HOME_LENDING',
    FINAL_DOCUMENT_SPECIALIST = 'FINAL_DOCUMENT_SPECIALIST',
    FUNDING = 'FUNDING',
    HEDGING = 'HEDGING',
    HEDGING_LOAN_SALES_MANAGER = 'HEDGING_LOAN_SALES_MANAGER',
    INTERNAL_WAREHOUSE_CONTACT = 'INTERNAL_WAREHOUSE_CONTACT',
    INVESTOR_PURCHASE_ANALYST = 'INVESTOR_PURCHASE_ANALYST',
    INVESTOR_PURCHASE_SUPERVISOR = 'INVESTOR_PURCHASE_SUPERVISOR',
    INVESTOR_SUSPENSE_COORDINATOR = 'INVESTOR_SUSPENSE_COORDINATOR',
    JR_PROCESSOR = 'JR_PROCESSOR',
    LOAN_OFFICER = 'LOAN_OFFICER',
    LOAN_SALE_COORDINATOR = 'LOAN_SALE_COORDINATOR',
    LOAN_SERVICING_MANAGER = 'LOAN_SERVICING_MANAGER',
    LOCK_DESK_MANAGER = 'LOCK_DESK_MANAGER',
    MANDATORY_DESK = 'MANDATORY_DESK',
    MERS_TRANSFERS = 'MERS_TRANSFERS',
    OPERATIONS_MANAGER = 'OPERATIONS_MANAGER',
    OPERATIONS_UNDERWRITING_MANAGER = 'OPERATIONS_UNDERWRITING_MANAGER',
    PAY_HISTORIES = 'PAY_HISTORIES',
    PAY_HISTORIES_TRAILING_CORRECTED_DOCS = 'PAY_HISTORIES_TRAILING_CORRECTED_DOCS',
    POST_CLOSING_ASSOCIATE = 'POST_CLOSING_ASSOCIATE',
    POST_CLOSING_MANAGER = 'POST_CLOSING_MANAGER',
    POST_CLOSING_PROCESSOR = 'POST_CLOSING_PROCESSOR',
    POST_CLOSING_SPECIALIST = 'POST_CLOSING_SPECIALIST',
    POST_FUNDER_MERS = 'POST_FUNDER_MERS',
    POST_FUNDING_SPECIALIST = 'POST_FUNDING_SPECIALIST',
    PRESIDENT = 'PRESIDENT',
    PRE_PURCHASE_REVIEW = 'PRE_PURCHASE_REVIEW',
    PROCESSING = 'PROCESSING',
    PRODUCTION_MANAGER = 'PRODUCTION_MANAGER',
    PURCHASE_ADVICE = 'PURCHASE_ADVICE',
    QUALITY_CONTROL_MANAGER = 'QUALITY_CONTROL_MANAGER',
    RATE_SHEET_DISTRIBUTION = 'RATE_SHEET_DISTRIBUTION',
    SECONDARY_ANALYST = 'SECONDARY_ANALYST',
    SECONDARY_MARKETING_BA = 'SECONDARY_MARKETING_BA',
    SECONDARY_MARKETING_MANAGER = 'SECONDARY_MARKETING_MANAGER',
    SENIOR_MANAGER_FOR_PRODUCT_AND_CREDIT_POLICY = 'SENIOR_MANAGER_FOR_PRODUCT_AND_CREDIT_POLICY',
    SENIOR_POST_CLOSER_PRIMARY_CONTACT_ON_FILES = 'SENIOR_POST_CLOSER_PRIMARY_CONTACT_ON_FILES',
    SERVICING_MANAGER = 'SERVICING_MANAGER',
    SERVICING_MANAGER_MERS_PAY_HISTORIES = 'SERVICING_MANAGER_MERS_PAY_HISTORIES',
    SHIPPER = 'SHIPPER',
    SHIPPING_MANAGER = 'SHIPPING_MANAGER',
    SVP = 'SVP',
    TEAM_LEAD_UPLOADING_CLOSED_LOAN_FILES = 'TEAM_LEAD_UPLOADING_CLOSED_LOAN_FILES',
    TRADER_HEDGE_DESK = 'TRADER_HEDGE_DESK',
    TRADES_PAIR_OFFS = 'TRADES_PAIR_OFFS',
    TRAILING_DOCS = 'TRAILING_DOCS',
    UNDERWRITING_MANAGER = 'UNDERWRITING_MANAGER',
    UNDERWRITING_TEAM_LEAD = 'UNDERWRITING_TEAM_LEAD',
    VP = 'VP',
    WEBSITE_ADMIN = 'WEBSITE_ADMIN'
}

export const contactTitleDisplay = {
    [ContactTitle.ACCOUNTANT]: 'Accountant',
    [ContactTitle.CAPITAL_MARKETS_MANAGER]: 'Capital Markets Manager',
    [ContactTitle.CEO]: 'CEO',
    [ContactTitle.CFO]: 'CFO',
    [ContactTitle.COLLATERAL_SHIPMENT]: 'Collateral Shipment',
    [ContactTitle.COMPLIANCE_MANAGER]: 'Compliance Manager',
    [ContactTitle.COMPLIANCE_OFFICER]: 'Compliance Officer',
    [ContactTitle.COO]: 'COO',
    [ContactTitle.DIRECTOR_OF_POST_FUNDING]: 'Director of Post Funding',
    [ContactTitle.DIRECTOR_OF_SECONDARY]: 'Director of Secondary',
    [ContactTitle.EVP_HOME_LENDING]: 'EVP Home Lending',
    [ContactTitle.FINAL_DOCUMENT_SPECIALIST]: 'Final Document Specialist',
    [ContactTitle.FUNDING]: 'Funding',
    [ContactTitle.HEDGING]: 'Hedging',
    [ContactTitle.HEDGING_LOAN_SALES_MANAGER]: 'Hedging/Loan Sales Manager',
    [ContactTitle.INTERNAL_WAREHOUSE_CONTACT]: 'Internal Warehouse Contact',
    [ContactTitle.INVESTOR_PURCHASE_ANALYST]: 'Investor Purchase Analyst',
    [ContactTitle.INVESTOR_PURCHASE_SUPERVISOR]: 'Investor Purchase Supervisor',
    [ContactTitle.INVESTOR_SUSPENSE_COORDINATOR]: 'Investor Suspense Coordinator',
    [ContactTitle.JR_PROCESSOR]: 'Jr. Processor',
    [ContactTitle.LOAN_OFFICER]: 'Loan Officer',
    [ContactTitle.LOAN_SALE_COORDINATOR]: 'Loan Sale Coordinator',
    [ContactTitle.LOAN_SERVICING_MANAGER]: 'Loan Servicing Manager',
    [ContactTitle.LOCK_DESK_MANAGER]: 'Lock Desk Manager',
    [ContactTitle.MANDATORY_DESK]: 'Mandatory Desk',
    [ContactTitle.MERS_TRANSFERS]: 'MERS Transfers',
    [ContactTitle.OPERATIONS_MANAGER]: 'Operations Manager',
    [ContactTitle.OPERATIONS_UNDERWRITING_MANAGER]: 'Operations/Underwriting Manager',
    [ContactTitle.PAY_HISTORIES]: 'Pay Histories',
    [ContactTitle.PAY_HISTORIES_TRAILING_CORRECTED_DOCS]: 'Pay Histories/Trailing-Corrected Docs',
    [ContactTitle.POST_CLOSING_ASSOCIATE]: 'Post Closing Associate',
    [ContactTitle.POST_CLOSING_MANAGER]: 'Post Closing Manager',
    [ContactTitle.POST_CLOSING_PROCESSOR]: 'Post-Closing Processor',
    [ContactTitle.POST_CLOSING_SPECIALIST]: 'Post Closing Specialist',
    [ContactTitle.POST_FUNDER_MERS]: 'Post Funder/Mers',
    [ContactTitle.POST_FUNDING_SPECIALIST]: 'Post Funding Specialist',
    [ContactTitle.PRESIDENT]: 'President',
    [ContactTitle.PRE_PURCHASE_REVIEW]: 'Pre-Purchase Review',
    [ContactTitle.PROCESSING]: 'Processing',
    [ContactTitle.PRODUCTION_MANAGER]: 'Production Manager',
    [ContactTitle.PURCHASE_ADVICE]: 'Purchase Advice',
    [ContactTitle.QUALITY_CONTROL_MANAGER]: 'Quality Control Manager',
    [ContactTitle.RATE_SHEET_DISTRIBUTION]: 'Rate Sheet Distribution',
    [ContactTitle.SECONDARY_ANALYST]: 'Secondary Analyst',
    [ContactTitle.SECONDARY_MARKETING_BA]: 'Secondary Marketing BA',
    [ContactTitle.SECONDARY_MARKETING_MANAGER]: 'Secondary Marketing Manager',
    [ContactTitle.SENIOR_MANAGER_FOR_PRODUCT_AND_CREDIT_POLICY]: 'Senior Manager for Product and Credit Policy',
    [ContactTitle.SENIOR_POST_CLOSER_PRIMARY_CONTACT_ON_FILES]: 'Senior Post Closer - Primary Contact on files',
    [ContactTitle.SERVICING_MANAGER]: 'Servicing Manager',
    [ContactTitle.SERVICING_MANAGER_MERS_PAY_HISTORIES]: 'Servicing Manager/MERS/Pay Histories',
    [ContactTitle.SHIPPER]: 'Shipper',
    [ContactTitle.SHIPPING_MANAGER]: 'Shipping Manager',
    [ContactTitle.SVP]: 'SVP',
    [ContactTitle.TEAM_LEAD_UPLOADING_CLOSED_LOAN_FILES]: 'Team Lead, Uploading Closed Loan Files',
    [ContactTitle.TRADER_HEDGE_DESK]: 'Trader-Hedge Desk',
    [ContactTitle.TRADES_PAIR_OFFS]: 'Trades/Pair Offs',
    [ContactTitle.TRAILING_DOCS]: 'Trailing Docs',
    [ContactTitle.UNDERWRITING_MANAGER]: 'Underwriting Manager',
    [ContactTitle.UNDERWRITING_TEAM_LEAD]: 'Underwriting Team Lead',
    [ContactTitle.VP]: 'VP',
    [ContactTitle.WEBSITE_ADMIN]: 'Website Admin'
};
