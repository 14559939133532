import { ErrorOutline } from '@mui/icons-material';
import {
    Button, IconButton, Popover, Tooltip
} from '@mui/material';
import { DateTypography, LabeledValue } from '@tsp-ui/core/components';
import { useState } from 'react';

import styles from './ExpiredButton.module.scss';


interface ExpiredButtonProps {
    onReprice(): Promise<void>;
    expirationDate: string;
}

export default function ExpiredButton({
    expirationDate,
    onReprice
}: ExpiredButtonProps) {
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();

    return (
        <>
            <Tooltip title="Pricing has expired. Click for options.">
                <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
                    <ErrorOutline color="error" />
                </IconButton>
            </Tooltip>

            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
                PaperProps={{
                    className: styles.expirationOptions
                }}
            >
                <LabeledValue
                    label="Pricing expired on"
                    value={(
                        <DateTypography
                            date={expirationDate}
                            component="span"
                            variant="body2"
                            time
                        />
                    )}
                    variant="vertical"
                />

                <Button
                    onClick={onReprice}
                    variant="contained"
                >
                    Reprice
                </Button>
            </Popover>
        </>
    );
}
