import api, { BulkCommitment } from '@api';
import { RemoveCircleOutline } from '@mui/icons-material';
import {
    Button, DialogContent, MenuItem, Tooltip, Typography
} from '@mui/material';
import {
    CurrencyField, DateField,
    DialogActions, IconButton,
    PercentField,
    RoutedDialog,
    RoutedDialogProps,
    TextField
} from '@tsp-ui/core/components';
import utilStyles from '@tsp-ui/core/sass/style-utils.module.scss';
import { replaceItemById, usePageMessage, useParams } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { CommitmentsContext } from '@views/admin/bulk-commitment/BulkCommitmentManagementPage';
import clsx from 'clsx';
import { useContext } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import styles from './BulkCommitmentDialog.module.scss';


interface BulkCommitmentDialogParams {
    commitmentID: string;
}

const formID = 'bulk-commitment-form';

export default function BulkCommitmentDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const { commitmentID } = useParams<BulkCommitmentDialogParams>();
    const { commitments, loading } = useContext(CommitmentsContext);

    const commitment = commitments?.find(({ id }) => id === commitmentID);

    return (
        <RoutedDialog
            {...props}
            title={`${!commitmentID ? 'Add' : 'Edit'} bulk commitment`}
            maxWidth="md"
            loading={loading}
        >
            <DialogContent>
                {(commitment || !loading) && (
                    <BulkCommitmentForm commitmentToEdit={commitment} />
                )}
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    type="submit"
                    variant="contained"
                    form={formID}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

interface BulkCommitmentFormProps {
    commitmentToEdit: BulkCommitment | undefined;
}

function BulkCommitmentForm({ commitmentToEdit }: BulkCommitmentFormProps) {
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const { id: clientId } = useGetCurrentAccount();

    const { setCommitments } = useContext(CommitmentsContext);

    const formMethods = useForm<BulkCommitment>({
        defaultValues: commitmentToEdit || {
            pricingTiers: [ {} ]
        }
    });

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        try {
            // TODO post-demo figure out why the number field is producing a string...
            formValues = {
                ...formValues,
                tradeIncentive: typeof formValues.tradeIncentive === 'string'
                    ? parseFloat(formValues.tradeIncentive)
                    : formValues.tradeIncentive
            };

            const updatedCommitment = commitmentToEdit
                ? await api.commitment.updateCommitment(clientId, formValues)
                : await api.commitment.createCommitment(clientId, formValues);

            setCommitments(commitments => (
                commitments
                    ? replaceItemById(commitments, updatedCommitment)
                    : [ updatedCommitment ]
            ));

            navigate('..');

            pageMessage.success(`Bulk commitment ${commitmentToEdit ? 'updated' : 'saved'}`);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the bulk commitment', error);
        }
    });

    const pricingTiersArray = useFieldArray<BulkCommitment>({
        name: 'pricingTiers',
        control: formMethods.control
    });

    return (
        <form
            noValidate
            id={formID}
            onSubmit={handleSubmit}
            className={styles.form}
        >
            <FormProvider {...formMethods}>
                <TextField<BulkCommitment>
                    name="customerId"
                    label="Customer"
                    select
                    required
                >
                    <MenuItem value={1000}>
                        ABC Mortgage
                    </MenuItem>

                    <MenuItem value={1001}>
                        Pending Bank
                    </MenuItem>
                </TextField>

                <Typography className={clsx(styles.fieldsHeader, utilStyles.fullWidth)}>
                    Trade details
                </Typography>

                <CurrencyField<BulkCommitment>
                    name="tradeAmount"
                    label="Amount"
                    required
                />

                <TextField<BulkCommitment>
                    name="tradeIncentive"
                    label="Incentive"
                    type="number"
                    required
                    maskProps={{
                        decimalScale: 5,
                        fixedDecimalScale: true,
                        decimalSeparator: '.',
                        displayType: 'input'
                    }}
                />

                <PercentField<BulkCommitment>
                    name="tradeVariance"
                    label="Variance"
                    required
                    decimalScale={2}
                    maskProps={{
                        fixedDecimalScale: true
                    }}
                />

                <Typography className={clsx(styles.fieldsHeader, utilStyles.fullWidth)}>
                    Dates
                </Typography>

                <DateField<BulkCommitment>
                    name="lockDate"
                    label="Trade lock date"
                    required
                />

                <TextField<BulkCommitment>
                    name="lockWindow"
                    label="Lock window (days)"
                    type="number"
                    required
                />

                <DateField<BulkCommitment>
                    name="deliveryExpiration"
                    label="Delivery expiration"
                    required
                />

                <Typography className={clsx(styles.fieldsHeader, utilStyles.fullWidth)}>
                    Pricing Tiers

                    <Button
                        onClick={() => pricingTiersArray.append({
                            productId: 1,
                            noteRate: 0,
                            price: 0
                        })}
                    >
                        Add tier
                    </Button>
                </Typography>

                <div className={clsx(styles.tierFieldRow, utilStyles.fullWidth)}>
                    {pricingTiersArray.fields.map((_, index) => (
                        <>
                            <TextField<BulkCommitment>
                                name={`pricingTiers.${index}.productId`}
                                label="Product"
                                select
                                size="small"
                            >
                                <MenuItem value={1}>
                                    Product One
                                </MenuItem>

                                <MenuItem value={2}>
                                    Product Two
                                </MenuItem>

                                <MenuItem value={3}>
                                    Product Three
                                </MenuItem>
                            </TextField>

                            <PercentField<BulkCommitment>
                                name={`pricingTiers.${index}.noteRate`}
                                label="Note rate"
                                size="small"
                                required={pricingTiersArray.fields.length > 1}
                                maskProps={{
                                    decimalScale: 3,
                                    fixedDecimalScale: true
                                }}
                            />

                            <TextField<BulkCommitment>
                                name={`pricingTiers.${index}.price`}
                                label="Price"
                                type="number"
                                size="small"
                                required
                                maskProps={{
                                    decimalScale: 5,
                                    fixedDecimalScale: true
                                }}
                            />

                            <CurrencyField<BulkCommitment>
                                name={`pricingTiers.${index}.subLimit`}
                                label="Sub-limit"
                                size="small"
                            />

                            <Tooltip title="Remove tier">
                                <IconButton
                                    className={styles.removeButton}
                                    onClick={() => pricingTiersArray.remove(index)}
                                >
                                    <RemoveCircleOutline color="error" />
                                </IconButton>
                            </Tooltip>
                        </>
                    ))}
                </div>
            </FormProvider>
        </form>
    );
}
