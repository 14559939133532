import { rest } from 'msw';

import { getMockUrl } from '../../../mocks/getMockUrl';

import { CustomerWireInfo } from './wireInfo-api';


let wireInstructionsID = 100;

export const mocks = [
    rest.get(getMockUrl('/customer/:customerID/wireInfo'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(wireInstructionsSet)
    ))),
    rest.put(getMockUrl('/customer/:customerID/wireInfo/:wireInfoID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.post(getMockUrl('/customer/:customerID/wireInfo'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as CustomerWireInfo,
            id: `${wireInstructionsID++}`,
            isActive: true,
            isApproved: false
        })
    )))
];

export const wireInstructionsSet: CustomerWireInfo[] = [
    {
        id: '1',
        customerId: 1,
        isActive: true,
        isApproved: true,
        bankName: 'Merchants Bank of Indiana',
        bankCity: 'Indianapolis',
        bankState: 'IL',
        bankAccountNum: '32452135',
        bankAba: '742',
        wireDestination: 'sample wire destination',
        furtherCreditToAcctName: 'some name',
        furtherCreditToAcctNum: '123'
    }, {
        id: '2',
        customerId: 1,
        isActive: true,
        isApproved: true,
        bankName: 'Merchants Bank of Kentucky',
        bankCity: 'louisville',
        bankState: 'KY',
        bankAccountNum: '32452135',
        bankAba: '742',
        wireDestination: 'sample wire destination',
        furtherCreditToAcctName: 'some name',
        furtherCreditToAcctNum: '123'
    },
    {
        id: '3',
        customerId: 1,
        isActive: true,
        isApproved: true,
        bankName: 'Merchants Bank of New York',
        bankCity: 'New York',
        bankState: 'NY',
        bankAccountNum: '32452135',
        bankAba: '742',
        wireDestination: 'sample wire destination',
        furtherCreditToAcctName: 'some name',
        furtherCreditToAcctNum: '123'
    }, {
        id: '4',
        customerId: 1,
        isActive: false,
        isApproved: false,
        bankName: 'Merchants Bank of Vermont',
        bankCity: 'Burlington',
        bankState: 'VT',
        bankAccountNum: '32452135',
        bankAba: '742',
        wireDestination: 'sample wire destination',
        furtherCreditToAcctName: 'some name',
        furtherCreditToAcctNum: '123'
    },
    {
        id: '5',
        customerId: 1,
        isActive: false,
        isApproved: false,
        bankName: 'Merchants Bank of Texas',
        bankCity: 'Austin',
        bankState: 'TX',
        bankAccountNum: '32452135',
        bankAba: '742',
        wireDestination: 'sample wire destination',
        furtherCreditToAcctName: 'some name',
        furtherCreditToAcctNum: '123'
    }, {
        id: '6',
        customerId: 1,
        isActive: false,
        isApproved: false,
        bankName: 'Merchants Bank of Alabama',
        bankCity: 'Newsie',
        bankState: 'AL',
        bankAccountNum: '32452135',
        bankAba: '742',
        wireDestination: 'sample wire destination',
        furtherCreditToAcctName: 'some name',
        furtherCreditToAcctNum: '123'
    }, {
        id: '7',
        customerId: 1,
        isActive: true,
        isApproved: false,
        bankName: 'Bank of North Carolina',
        bankCity: 'Manchester',
        bankState: 'NC',
        bankAccountNum: '32452135',
        bankAba: '742',
        wireDestination: 'sample wire destination',
        furtherCreditToAcctName: 'some name',
        furtherCreditToAcctNum: '123'
    },
    {
        id: '8',
        customerId: 1,
        isActive: true,
        isApproved: false,
        bankName: 'Bank of Houston',
        bankCity: 'Austin',
        bankState: 'TX',
        bankAccountNum: '32452135',
        bankAba: '742',
        wireDestination: 'sample wire destination',
        furtherCreditToAcctName: 'some name',
        furtherCreditToAcctNum: '123'
    }, {
        id: '9',
        customerId: 1,
        isActive: true,
        isApproved: false,
        bankName: 'Bank of the Ozarks',
        bankCity: 'Ozark',
        bankState: 'AL',
        bankAccountNum: '32452135',
        bankAba: '742',
        wireDestination: 'sample wire destination',
        furtherCreditToAcctName: 'some name',
        furtherCreditToAcctNum: '123'
    }
];
