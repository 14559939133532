import api, { CustomerDetails } from '@api';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { AddressFieldset, Switch, TextField } from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { useCreateAccountUrl } from '@utils/hooks';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AdminAddEditFormProps } from '../components/AdminAddPageTemplate';

import styles from './CustomerForm.module.scss';


export interface CustomerDetailsFormValues extends Omit<CustomerDetails, 'dbaNames' | 'branches' | 'pendingSteps'> {
    useOtherDba: boolean;
    dbaNames: { value: string }[];
}

/**
 * Accepts a paramter of type customer and returns customer form values
 */
function createDefaultValues(customer?: CustomerDetails): CustomerDetailsFormValues | undefined {
    return customer
        ? {
            ...customer,
            dbaNames: customer.dbaNames?.map(dbaName => ({ value: dbaName })) || [ { value: '' } ],
            useOtherDba: (customer.dbaNames?.length || 0) > 0
        } : undefined;
}

export default function CustomerForm({
    entityToEdit: customerToEdit,
    onSubmit,
    setLoading
} : AdminAddEditFormProps<CustomerDetails>) {
    const navigate = useNavigate();
    const createAccountUrl = useCreateAccountUrl();

    const pageMessage = usePageMessage();
    const formMethods = useForm<CustomerDetailsFormValues>({
        defaultValues: createDefaultValues(customerToEdit)
    });
    const useOtherDba = formMethods.watch('useOtherDba');

    const { fields, append, remove } = useFieldArray<CustomerDetailsFormValues>({
        name: 'dbaNames',
        control: formMethods.control
    });

    const handleSubmit = formMethods.handleSubmit(async (formValues: CustomerDetailsFormValues) => {
        setLoading(true);

        const payload: CustomerDetails = {
            ...formValues,
            dbaNames: useOtherDba
                ? formValues.dbaNames.map(dbaName => dbaName.value)
                : undefined
        };

        try {
            const { id } = customerToEdit
                ? await api.customer.updateCustomer(payload)
                : await api.customer.addCustomer(payload);

            onSubmit(payload);

            navigate(createAccountUrl(`admin/customers/${id}`));

            pageMessage.success('Customer details saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the customer details', error);
        }

        setLoading(false);
    });

    return (
        <form
            id={CustomerForm.formID}
            className={styles.root}
            onSubmit={handleSubmit}
            noValidate
        >
            <FormProvider {...formMethods}>
                <TextField<CustomerDetailsFormValues>
                    name="name"
                    label="Customer name"
                    required
                    hideRequiredIndicator
                />

                <Switch<CustomerDetailsFormValues>
                    name="useOtherDba"
                    label="Use other D/B/A name"
                    onClick={() => {
                        if (fields.length === 0) {
                            append({ value: '' });
                        }
                    }}
                />

                {useOtherDba && (
                    <div className={styles.dbaContainer}>
                        {fields.map((field, index) => (
                            <div
                                className={styles.dbaRow}
                                key={field.id}
                            >
                                <TextField
                                    name={`dbaNames.${index}.value`}
                                    label="Doing business as"
                                    size="small"
                                    required
                                    hideRequiredIndicator
                                />

                                <div className={styles.dbaButtons}>
                                    {fields.length > 1 && (
                                        <Tooltip title="Remove D/B/A name">
                                            <IconButton
                                                onClick={() => {
                                                    remove(index);
                                                }}
                                            >
                                                <RemoveCircleOutline color="error" />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    {index === fields.length - 1 && (
                                        <Tooltip title="Add additional D/B/A name">
                                            <IconButton
                                                onClick={() => {
                                                    append({ value: '' });
                                                }}
                                            >
                                                <AddCircleOutline color="secondary" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                <TextField<CustomerDetailsFormValues>
                    name="nmlsId"
                    label="NMLS ID"
                    required
                    hideRequiredIndicator
                />

                <TextField<CustomerDetailsFormValues>
                    name="taxID"
                    label="Tax ID"
                    required
                    hideRequiredIndicator
                />

                <AddressFieldset<CustomerDetailsFormValues>
                    className={styles.fullWidth}
                    fieldNames={{
                        street: 'address.street',
                        line2: 'address.line2',
                        city: 'address.city',
                        state: 'address.state',
                        zip: 'address.zip'
                    }}
                    required
                />
            </FormProvider>
        </form>
    );
}

CustomerForm.formID = 'edit-customer-form';
