import { ButtonProps, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import { Dispatch, ReactNode, SetStateAction } from 'react';

import UnderwritingAnswerButtons, { ButtonConfig } from './UnderwritingAnswerButtons';
import styles from './UnderwritingQuestionCard.module.scss';


export interface UnderwritingQuestionCardProps<T> {
    question: ReactNode;
    onAnswered: Dispatch<SetStateAction<T>>;
    answer: T;
    buttons: ButtonConfig<T>[];
    buttonSize?: ButtonProps['size'];
    children?: ReactNode;
    showChildren?: (answer: T) => boolean;
    className?: string;
}

export default function UnderwritingQuestionCard<T>({
    question, onAnswered, answer, buttons, children, showChildren, className, buttonSize
}: UnderwritingQuestionCardProps<T>) {
    const shouldShowChildren = showChildren ? showChildren(answer) : answer;

    return (
        <Paper
            variant="outlined"
            className={clsx(styles.root, className)}
        >
            <Typography component="div">
                {question}
            </Typography>

            <UnderwritingAnswerButtons
                buttons={buttons}
                value={answer}
                setValue={onAnswered}
                size={buttonSize}
            />

            {shouldShowChildren && children}
        </Paper>
    );
}
