import { Button, Paper, TextField } from '@mui/material';

import { AppraisalUnderwritingTabs } from '../../AppraisalUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';

// TODO make common component for text field entry cards?

import styles from './AppraisalDetailsSection.module.scss';


interface AppraiserDetailsSectionProps {
    setTab: (tab: AppraisalUnderwritingTabs) => void;
    onTabComplete: (tab: AppraisalUnderwritingTabs, nextTab: AppraisalUnderwritingTabs) => void;
}

export default function AppraiserDetailsSection({ onTabComplete }: AppraiserDetailsSectionProps) {
    return (
        <UnderwritingStepSection title="Appraiser details">
            <Paper
                variant="outlined"
                className={styles.questionCard}
            >
                <div className={styles.feesAndTaxesFields}>
                    <TextField label="Appraiser name" />

                    <TextField label="License number" />

                    <TextField label="Appraisal company" />
                </div>

                <Button
                    variant="contained"
                    className={styles.nextButton}
                    onClick={() => onTabComplete('appraiser', 'ucdp')}
                >
                    Next
                </Button>
            </Paper>
        </UnderwritingStepSection>
    );
}

