import { HelpOutline } from '@mui/icons-material';
import {
    Button, Paper, Tooltip, Typography
} from '@mui/material';
import { FilledSection, LabelGroup, LabeledValue } from '@tsp-ui/core/components';
import clsx from 'clsx';
import { ReactNode } from 'react';

import { CreditUnderwritingTabs } from '../../CreditUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';

import LiabilityCard from './LiabilitiesCard';
import styles from './LiabilitiesSection.module.scss';


interface LiabilitiesSectionProps {
    setTab: (tab: CreditUnderwritingTabs) => void;
    onTabComplete: (tab: CreditUnderwritingTabs, nextTab: CreditUnderwritingTabs) => void;
}

export default function LiabilitiesSection({ onTabComplete }: LiabilitiesSectionProps) {
    return (
        <UnderwritingStepSection
            title="Liabilities"
            addButton={(
                <Button>
                    Add liability
                </Button>
            )}
        >
            <div className={styles.root}>
                <FilledSection
                    header={(
                        <>
                            Included

                            <Tooltip
                                title="Excludes mortgages. Review mortgages on the REO screen."
                                enterDelay={0}
                            >
                                <HelpOutline color="primary" />
                            </Tooltip>
                        </>
                    )}
                    className={clsx(styles.section, styles.activeLiabilities)}
                    variant="light"
                >
                    <LiabilityCard />

                    <LiabilityCard />

                    <LiabilityCard />

                    <LiabilityCard />

                    <LiabilityCard />

                    <LiabilityCard />
                </FilledSection>

                <FilledSection
                    header="Excluded"
                    variant="light"
                    className={styles.section}
                >
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        className={styles.sectionLabel}
                    >
                        Pay off
                    </Typography>

                    <LiabilityCard excluded />

                    <Typography
                        variant="caption"
                        color="textSecondary"
                        className={clsx(styles.sectionLabel, styles.omitLabel)}
                    >
                        Omit
                    </Typography>

                    <LiabilityCard excluded />

                    <LiabilityCard excluded />
                </FilledSection>

                <Paper
                    variant="outlined"
                    className={styles.summary}
                >
                    <LabelGroup>
                        <SummaryLabeledValue
                            label="Total monthly liabilities"
                            value="$2,880"
                            plus
                        />

                        <SummaryLabeledValue
                            label="Child support/Alimony"
                            value="$250"
                            plus
                        />

                        <SummaryLabeledValue
                            label="To be paid off"
                            value="--"
                            minus
                        />

                        <SummaryLabeledValue
                            label="Omitted"
                            value="--"
                            minus
                        />

                        <SummaryLabeledValue
                            label="Installment debts < 10 months"
                            value="--"
                            minus
                        />

                        <SummaryLabeledValue
                            label="Total liabilities considered in DTI"
                            value="$3,130"
                            bold
                        />
                    </LabelGroup>

                    <Button
                        variant="contained"
                        onClick={() => onTabComplete('liabilities', 'summary')}
                    >
                        Done
                    </Button>
                </Paper>
            </div>
        </UnderwritingStepSection>
    );
}

interface SummaryLabeledValueProps {
    label: string;
    value: ReactNode;
    className?: string;
    bold?: boolean;
    plus?: boolean;
    minus?: boolean;
}

// TODO move to separate file
export function SummaryLabeledValue({
    label, value, bold, plus, minus, className: classNameProp
}: SummaryLabeledValueProps) {
    const className = clsx(classNameProp, {
        [styles.bold]: bold
    });

    return (
        <LabeledValue
            label={(plus || minus) ? (
                <>
                    {label}&nbsp;

                    {plus ? (
                        <span className={styles.plus}>(+)</span>
                    ) : (
                        <span className={styles.minus}>
                            <span className={styles.minusLetterSpacing}>(-</span>)
                        </span>
                    )}

                    :&nbsp;
                </>
            ) : (
                <>
                    {label}:&nbsp;
                </>
            )}
            value={value}
            variants={{
                value: 'body1',
                label: 'body2'
            }}
            classNames={{
                label: className,
                value: clsx(className, styles.rightAlign)
            }}
        />
    );
}
