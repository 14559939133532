import api, { CustomerChecklistStep, CustomerPendingSteps } from '@api';
import { Search } from '@mui/icons-material';
import {
    Button, Checkbox, DialogContent, FormControlLabel, InputAdornment, TextField
} from '@mui/material';
import { FilledSection, RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import { replaceItemById, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useCallback, useContext, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { CustomerDetailContext } from '../../CustomerDetailPage';

import styles from './DueDiligenceDialog.module.scss';
import ChecklistItemCard from './components/ChecklistItemCard';


export default function DueDiligenceDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const { customer, updatePendingSteps } = useContext(CustomerDetailContext);
    const { id } = customer!;

    const [ showCompleted, setShowCompleted ] = useState(false);
    const [ showOptional, setShowOptional ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ saveLoading, setSaveLoading ] = useState(false);
    const [ steps, setSteps ] = useState<CustomerChecklistStep[]>([]);

    const [ searchTerm, setSearchTerm ] = useState('');
    const [ debouncedTerm ] = useDebounce(searchTerm, 300);

    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setSteps(await api.customer.checklist.getChecklistSteps(id));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the due diligence checklist', error);
        }

        setLoading(false);
    }, [ id, pageMessage ]));

    const completeSteps = steps.filter(step => step.isCompleted && filterByName(step.name));
    const incompleteSteps = steps.filter(step => (
        !step.isCompleted && (showOptional || step.isRequired) && filterByName(step.name)
    ));

    function filterByName(name: string) {
        return name.toLocaleLowerCase().includes(debouncedTerm);
    }

    function saveStep(savedStep: CustomerChecklistStep) {
        setSteps(replaceItemById(steps, savedStep));
    }

    useAsyncEffect(useCallback(async () => {
        try {
            if (incompleteSteps.length === 0) {
                await updatePendingSteps(CustomerPendingSteps.DUE_DILIGENCE);
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while marking due diligence as completed', error);
        }
    }, [
        incompleteSteps, updatePendingSteps, pageMessage
    ]));

    return (
        <RoutedDialog
            {...props}
            title="Due-diligence checklist"
            maxWidth={false}
            loading={loading}
            saveLoading={saveLoading}
        >
            <DialogContent>
                <div className={styles.filterContainer}>
                    <TextField
                        autoFocus
                        variant="standard"
                        placeholder="Filter checklist items"
                        onChange={e => setSearchTerm(e.target.value.toLocaleLowerCase())}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                    />

                    <Button onClick={() => setShowCompleted(!showCompleted)}>
                        {showCompleted ? 'Hide' : 'Show'} completed items
                    </Button>
                </div>

                <div className={styles.root}>
                    <FilledSection
                        className={styles.section}
                        variant="light"
                        header={(
                            <>
                                Pending items

                                <FormControlLabel
                                    control={<Checkbox />}
                                    checked={showOptional}
                                    onChange={() => setShowOptional(!showOptional)}
                                    label="Show optional"
                                    className={styles.showOptionalCheckbox}
                                />
                            </>
                        )}
                    >
                        {incompleteSteps.map(step => (
                            <ChecklistItemCard
                                key={step.id}
                                checklistStep={step}
                                onSave={saveStep}
                                setLoading={setSaveLoading}
                            />
                        ))}
                    </FilledSection>

                    {showCompleted && (
                        <FilledSection
                            className={styles.section}
                            variant="light"
                            header="Completed items"
                        >
                            {completeSteps.map(step => (
                                <ChecklistItemCard
                                    key={step.id}
                                    checklistStep={step}
                                    onSave={saveStep}
                                    setLoading={setSaveLoading}
                                />
                            ))}
                        </FilledSection>
                    )}
                </div>
            </DialogContent>
        </RoutedDialog>
    );
}
