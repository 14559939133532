export enum AutomatedUwSystem {
    DU = 'DU',
    LPA = 'LPA',
    MANUAL = 'MANUAL'
}

export const automatedUwSystemDisplay = {
    [AutomatedUwSystem.DU]: 'DU',
    [AutomatedUwSystem.LPA]: 'LPA',
    [AutomatedUwSystem.MANUAL]: 'Manual'
};
