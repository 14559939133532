import { Button, DialogActions, DialogContent } from '@mui/material';
import {  Dialog, TextField } from '@tsp-ui/core/components';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './EditConditionDialog.module.scss';


interface EditConditionDialogProps {
    open: boolean;
}

export default function EditConditionDialog({ open }: EditConditionDialogProps) {
    const formMethods = useForm();

    return (
        <Dialog
            title="Add condition"
            open={open}
            maxWidth="xs"
        >
            <DialogContent>
                <form
                    noValidate
                    className={styles.form}
                >
                    <FormProvider {...formMethods}>
                        <TextField
                            name="todo"
                            label="Category"
                            select
                            required
                        />

                        <TextField
                            name="todo"
                            label="Group"
                            select
                            required
                        />

                        <TextField
                            name="todo"
                            label="Stage"
                            select
                            required
                        />

                        <TextField
                            name="todo"
                            label="Responsible party"
                            select
                            required
                        />

                        <TextField
                            name="todo"
                            label="Condition description"
                            multiline
                            required
                            rows={3}
                            className={styles.fullWidth}
                        />
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    form="add-edit-license-form"
                    type="submit"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
