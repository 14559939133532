import { store } from '@redux/store';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { SentryErrorBoundary } from '@tsp-ui/core/components';
import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
    BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType
} from 'react-router-dom';

import App from './App';

import './index.scss';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/agGridMaterialFont.css';


const { worker } = require('./mocks/browser');


declare global {
    interface Window {
        Cypress?: any;
        mocks: {
            isRunning: () => boolean;
            start: () => void;
            stop: () => void;
        }
    }
}

let mocksRunning = false;

window.mocks = {
    isRunning() {
        return mocksRunning;
    },
    start() {
        mocksRunning = true;
        worker.start({ onUnhandledRequest: 'bypass' });
    },
    stop() {
        mocksRunning = false;
        worker.stop();
    }
};

if ((process.env.NODE_ENV === 'development' && !window.Cypress) || window.origin.includes('demo.premicorr.net')) {
    window.mocks.start();

    window.addEventListener('focus', () => {
        if (mocksRunning) {
            window.mocks.stop();
            window.mocks.start();
        }
    });
}

if (process.env.NODE_ENV === 'production') {
    const { host } = window.location;

    Sentry.init({
        dsn: 'https://6301ec40c5a64f829b419c218cae5e24@o412084.ingest.sentry.io/6582054',
        release: process.env.REACT_APP_VERSION,
        tracesSampleRate: 0.75,
        integrations: [
            new BrowserTracing({
                tracingOrigins: [
                    host.includes('premicorr')
                        ? `${host.split('.')[0]}-webapi.premicorr.net`
                        : 'localhost'
                ],
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                )
            })
        ],
        environment: host.includes('premicorr')
            ? host.split('.')[0]
            : 'local'
    });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <StrictMode>
        <SentryErrorBoundary>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </SentryErrorBoundary>
    </StrictMode>
);
