import api, {
    CustomerCompensationType,
    CustomerFeesComp,
    CustomerPendingSteps,
    FeeType,
    customerCompensationPercentageOfDisplay,
    customerTypeDisplay
} from '@api';
import {
    Button, Divider, FormLabel, InputAdornment, Typography
} from '@mui/material';
import {
    CardTable,
    ContainedTableProps,
    CurrencyField,
    Radio,
    RadioGroup
    , Switch
    , TextField, renderEnumOptions
} from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { Dispatch, SetStateAction, useContext } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { CustomerDetailContext } from '../../CustomerDetailPage';

import { CompensationDialogProps } from './CompensationDialog';
import styles from './CompensationDialogForm.module.scss';
import CustomerFeeTableCustomFeeRow from './CustomerFeeTableCustomFeeRow';
import CustomerFeeTableRow from './CustomerFeeTableRow';


interface CompensationDialogFormProps extends CompensationDialogProps {
    defaultValues: CustomerFeesComp;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

export type FeesCompFormValues = Required<CustomerFeesComp>;

export const COMPENSATION_FORM_ID = 'compensation-form';

export const feesTableHeaders: ContainedTableProps['headers'] = [
    'Fee name',
    {
        value: 'Amount',
        align: 'right'
    },
    ''
];

export default function CompensationDialogForm({
    defaultValues, closeTo, setLoading
}: CompensationDialogFormProps) {
    const navigate = useNavigate();

    const formMethods = useForm<FeesCompFormValues>({ defaultValues });
    const { watch, getValues, reset } = formMethods;

    const compensationType = watch('compensation.type');

    const { customer, updatePendingSteps } = useContext(CustomerDetailContext);
    const { id } = customer!;

    const { fields, append, remove } = useFieldArray<FeesCompFormValues>({
        name: 'fees',
        control: formMethods.control
    });
    const fieldsWithIndex = fields.map((fee, index) => ({
        ...fee,
        index
    }));
    const customFees = fieldsWithIndex.filter(fee => fee.isCustom);

    const pageMessage = usePageMessage();

    const handleSubmit = formMethods.handleSubmit(async (formValues: FeesCompFormValues) => {
        setLoading(true);

        try {
            await api.customer.compensation.updateFeesComp(id, formValues);
            await updatePendingSteps(CustomerPendingSteps.COMP_FEES);

            navigate(closeTo);

            pageMessage.success('Compensation details saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the compensation details', error);
        }

        setLoading(false);
    });

    return (
        <form
            className={styles.root}
            id={COMPENSATION_FORM_ID}
            onSubmit={handleSubmit}
            noValidate
        >
            <FormProvider {...formMethods}>
                <Switch<FeesCompFormValues>
                    name="compensation.firstLienOnly"
                    label="Compensation only paid for the 1st lien of a combo"
                />

                <div className={styles.container}>
                    <div>
                        <FormLabel>
                            Compensation type
                        </FormLabel>

                        <RadioGroup<FeesCompFormValues>
                            name="compensation.type"
                            defaultValue={CustomerCompensationType.FIXED}
                        >
                            <Radio
                                value={CustomerCompensationType.FIXED}
                                label={customerTypeDisplay[CustomerCompensationType.FIXED]}
                            />

                            <Radio
                                value={CustomerCompensationType.PERCENTAGE}
                                label={customerTypeDisplay[CustomerCompensationType.PERCENTAGE]}
                            />
                        </RadioGroup>
                    </div>

                    {compensationType === CustomerCompensationType.FIXED ? (
                        <CurrencyField<FeesCompFormValues>
                            name="compensation.amount"
                            label="Compensation amount"
                            required
                            hideRequiredIndicator
                        />
                    ) : (
                        <div className={styles.percentageContent}>
                            <div className={styles.firstRow}>
                                <TextField<FeesCompFormValues>
                                    className={styles.percentageInput}
                                    name="compensation.percentage"
                                    type="number"
                                    required
                                    hideRequiredIndicator
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                %
                                            </InputAdornment>
                                        ),
                                        className: styles.percentage
                                    }}
                                />

                                <Typography
                                    variant="body2"
                                    className={styles.separationText}
                                >
                                    of the:
                                </Typography>

                                <TextField<FeesCompFormValues>
                                    className={styles.loanAmount}
                                    name="compensation.percentageOf"
                                    label="Loan amount type"
                                    select
                                    required
                                    hideRequiredIndicator
                                >
                                    {renderEnumOptions(customerCompensationPercentageOfDisplay)}
                                </TextField>
                            </div>

                            <CurrencyField<FeesCompFormValues>
                                name="compensation.minAmount"
                                label="Min compensation"
                                required
                                hideRequiredIndicator
                            />

                            <CurrencyField<FeesCompFormValues>
                                name="compensation.maxAmount"
                                label="Max compensation"
                                required
                                hideRequiredIndicator
                            />
                        </div>
                    )}
                </div>

                <TextField<FeesCompFormValues>
                    name="compensation.notes"
                    label="Notes"
                    multiline
                    rows={3}
                />

                <div className={styles.tableHeader}>
                    <Typography variant="h6">
                        Fees
                    </Typography>

                    <Button
                        variant="text"
                        onClick={() => append({
                            id: '',
                            feeType: '' as FeeType,
                            defaultAmount: 0,
                            adjustedAmount: undefined,
                            isActive: true,
                            isCustom: true
                        })}
                    >
                        Add fee
                    </Button>
                </div>

                {defaultValues.fees ? (
                    <CardTable
                        className={styles.feesTable}
                        headers={feesTableHeaders}
                    >
                        {fieldsWithIndex.filter(fee => !fee.isCustom).map(fee => (
                            <CustomerFeeTableRow
                                fee={fee}
                                index={fee.index}
                                key={fee.feeType}
                            />
                        ))}

                        {customFees.length ? (
                            <tr>
                                <td
                                    colSpan={3}
                                    className={styles.divider}
                                >
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        className={styles.additionalFees}
                                    >
                                        Additional fees
                                    </Typography>

                                    <Divider />
                                </td>
                            </tr>
                        ) : null}

                        {customFees.map(fee => (
                            <CustomerFeeTableCustomFeeRow
                                fee={fee}
                                index={fee.index}
                                onRemove={() => {
                                    remove(fee.index);
                                    reset(getValues()); // this resets the default values of the field to undefined
                                }}
                                key={fee.feeType || fee.index}
                            />
                        ))}
                    </CardTable>
                ) : (
                    <Typography variant="body2">
                        No applicable fees for the selected customer.
                    </Typography>
                )}
            </FormProvider>
        </form>
    );
}
