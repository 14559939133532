import { Button } from '@mui/material';
import { FilterTextField, FilterableFilledSection, RoutedDialogManager } from '@tsp-ui/core/components';
import { LLPACard } from '@views/admin/llpas/components/LLPACard';
import Page from '@views/components/Page';
import { Link } from 'react-router-dom';

import styles from './LLPAManagementPage.module.scss';
import LLPADialog from './components/LLPADialog';


export default function LLPAManagementPage() {
    return (
        <Page header="LLPA Management">
            <div className={styles.root}>
                <RoutedDialogManager routes={routes} />

                <FilterableFilledSection
                    header="LLPAs"
                    addButton={(
                        <Button
                            variant="contained"
                            component={Link}
                            to="add"
                        >
                            Add LLPA
                        </Button>
                    )}
                    filterField={(
                        <FilterTextField
                            autoFocus
                            placeholder="Filter LLPAs"
                            helperText="Filter by name"
                        />
                    )}
                >
                    <LLPACard
                        title="FNMA Standard Balance Products"
                        description="Fannie mae adjustments based on loan amount"
                    />

                    <LLPACard
                        title="FNMA State/Escrow Adjustments"
                        description="Fannie mae adjustments based on state and escrow status"
                    />

                    <LLPACard
                        title="FNMA FICO/LTV 15+ Year"
                        description="Fannie mae adjustments based on FICO/LTV for terms greater than 15 years"
                    />

                    <LLPACard
                        title="FNMA Cash-out Refinances"
                        description="Fannie mae adjustments based on FICO/LTV for cash-out refinances"
                    />

                    <LLPACard
                        title="FNMA Investment Properties"
                        description="Fannie mae adjustments for investment properties"
                    />

                    <LLPACard
                        title="FNMA Loans with Subordinate Financing"
                        description="Fannie mae adjustments for loans with subordinate financing"
                    />

                    <LLPACard
                        title="FNMA Super Conforming Adjustments"
                        description="Fannie mae adjustments for super conforming"
                    />

                    <LLPACard
                        title="FNMA Other adjustments"
                        description="Fannie mae adjustments miscellaneous"
                    />
                </FilterableFilledSection>

                <FilterableFilledSection
                    header="Caps"
                    addButton={(
                        <Button
                            variant="contained"
                            component={Link}
                            to="add-cap"
                        >
                            Add Cap
                        </Button>
                    )}
                    filterField={(
                        <FilterTextField
                            autoFocus
                            placeholder="Filter caps"
                            helperText="Filter by name"
                        />
                    )}
                >
                    <LLPACard
                        title="FNMA HomeReady"
                        description="Cumulative LLPA caps for HomeReady loans"
                    />

                    <LLPACard
                        title="FNMA High LTV Refinance"
                        description="Cumulative LLPA caps for High LTV Refinance loans"
                    />
                </FilterableFilledSection>
            </div>
        </Page>
    );
}

const routes = {
    add: LLPADialog,
    edit: LLPADialog
};
