import {
    HighLevelGuideline,
    LoanProperty,
    Operator,
    booleanEnumDisplay,
    loanPropertyDisplay,
    loanPropertyEnumDisplays,
    loanPropertyEnumFieldTypes,
    operatorDisplay
} from '@api';
import { Edit, RemoveCircleOutline, Save } from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import {
    DateField, DateTypography, TextField, renderEnumOptions
} from '@tsp-ui/core/components';
import { ValueOf, booleanValueToEnum } from '@tsp-ui/core/utils';
import clsx from 'clsx';
import { ReactNode, useState } from 'react';
import { UseFieldArrayRemove, useWatch } from 'react-hook-form';

import styles from './HighLevelGuidelineCard.module.scss';


interface ViewHighLevelGuidelineCardProps {
    highLevelGuideline: HighLevelGuideline;
}

export function ViewHighLevelGuidelineCard({
    highLevelGuideline: { loanProperty, value, operator }
}: ViewHighLevelGuidelineCardProps) {
    const formattedValue = useFormattedValue(loanProperty, value);

    return (
        <Paper
            variant="outlined"
            className={styles.highLevelCard}
        >
            <Typography
                variant="body2"
                component="div"
                className={clsx(styles.highLevelProperty, styles.readOnly)}
                fontWeight={500}
            >
                {loanPropertyDisplay[loanProperty!]}
            </Typography>

            <Typography variant="caption">
                {operatorDisplay[operator!]}
            </Typography>

            <Typography variant="body2">
                {formattedValue}
            </Typography>
        </Paper>
    );
}

interface EditHighLevelGuidelineCardProps{
    index: number;
    remove: UseFieldArrayRemove;
    fieldName: string;
}

export function EditHighLevelGuidelineCard({ index, remove, fieldName }: EditHighLevelGuidelineCardProps) {
    const {
        operator, loanProperty, value
    } = useWatch({ name: fieldName }) as HighLevelGuideline || {};

    const saveDisabled = !loanProperty || !operator || ! value;

    const { valueField, formattedValue, availableOperatorsDisplay } = useGetCardData(fieldName) || {};

    const [ isEditing, setIsEditing ] = useState(!loanProperty);

    return (
        <Paper
            variant="outlined"
            className={styles.highLevelCard}
        >
            <Typography
                variant="body2"
                component="div"
                className={clsx(styles.highLevelProperty, { [styles.editProperty]: isEditing })}
                fontWeight={500}
            >
                {isEditing ? (
                    <TextField
                        name={`${fieldName}.loanProperty`}
                        className={styles.flexOne}
                        SelectProps={{
                            classes: { select: styles.dropdown },
                            defaultOpen: saveDisabled
                        }}
                        required
                        hideRequiredIndicator
                        select
                    >
                        {renderEnumOptions(loanPropertyDisplay)}
                    </TextField>
                ) : loanPropertyDisplay[loanProperty!]}

                <div>
                    {isEditing ? (
                        <Tooltip
                            title="Save"
                            key="save"
                        >
                            <span>
                                <IconButton
                                    size="small"
                                    onClick={() => setIsEditing(false)}
                                    disabled={saveDisabled}
                                >
                                    <Save
                                        color={saveDisabled ? 'disabled' : 'secondary'}
                                        fontSize="small"
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Edit">
                            <IconButton
                                size="small"
                                onClick={() => setIsEditing(true)}
                            >
                                <Edit
                                    color="secondary"
                                    fontSize="small"
                                />
                            </IconButton>
                        </Tooltip>
                    )}

                    <Tooltip title="Remove">
                        <IconButton
                            size="small"
                            onClick={() => remove?.(index)}
                        >
                            <RemoveCircleOutline
                                color="error"
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>
                </div>
            </Typography>

            {!availableOperatorsDisplay ? null : isEditing ? (
                <TextField
                    name={`${fieldName}.operator`}
                    SelectProps={{
                        classes: { select: styles.dropdown }
                    }}
                    required
                    hideRequiredIndicator
                    select
                >
                    {renderEnumOptions(availableOperatorsDisplay)}
                </TextField>
            ) : (
                <Typography variant="caption">
                    {operatorDisplay[operator!]}
                </Typography>
            )}

            {isEditing ? valueField : ( // TODO valueField throws 'changing default value of uncontrolled component' err
                <Typography
                    variant="body2"
                    className={styles.valueDisplay}
                >
                    {formattedValue}
                </Typography>
            )}
        </Paper>
    );
}

type LoanPropertyEnumValue = keyof ValueOf<typeof loanPropertyEnumDisplays>; // TODO fix this type

function getPartialEnumDisplay(display: any, values: any[]) {
    return Object.fromEntries(Object.entries(display).filter(
        ([ key ]) => values.includes(key)
    )) as { [key: string]: string };
}

const {
    DOES_NOT_EQUAL, EQUALS, IS_GREATER_THAN, IS_GREATER_THAN_OR_EQUAL_TO, IS_LESS_THAN, IS_LESS_THAN_OR_EQUAL_TO
} = Operator;

const enumOperators = [ EQUALS, DOES_NOT_EQUAL ];
const numberOperators = [
    DOES_NOT_EQUAL, EQUALS, IS_GREATER_THAN, IS_GREATER_THAN_OR_EQUAL_TO, IS_LESS_THAN, IS_LESS_THAN_OR_EQUAL_TO
];

function getAvailableOperators(loanProperty: LoanProperty | undefined) {
    if (!loanProperty) {
        return [];
    }

    const type = loanPropertyEnumFieldTypes[loanProperty];

    if (type === 'numeric') {
        return numberOperators;
    } else if (type === null) {
        return [ EQUALS ]; // TODO add some others for date; IS_BEFORE, IS_AFTER
    } else if (type === 'enum') {
        return enumOperators;
    } else {
        return Object.values(Operator);
    }
}

export function getFormattedValue(loanProperty: LoanProperty | undefined, value: HighLevelGuideline['value'] | undefined) {
    if (!loanProperty) {
        return null;
    }

    const type = loanPropertyEnumFieldTypes[loanProperty];

    let formattedValue: ReactNode;
    if (type === null) {
        formattedValue = <DateTypography date={value as string} />; // TODO post-demo
    } else if (isEnumLoanProperty(loanProperty)) {
        formattedValue = loanPropertyEnumDisplays[loanProperty]?.[value as LoanPropertyEnumValue];
    } else if (isBooleanLoanProperty(loanProperty)) {
        formattedValue = booleanEnumDisplay[booleanValueToEnum(value as boolean)];
    } else {
        formattedValue = value;
    }

    return formattedValue;
}

function useFormattedValue(loanProperty: LoanProperty | undefined, value: HighLevelGuideline['value'] | undefined) {
    return getFormattedValue(loanProperty, value);
}

function useGetCardData(fieldName: string) {
    const {
        loanProperty, value
    } = useWatch({ name: fieldName }) as Partial<HighLevelGuideline>;

    const formattedValue = useFormattedValue(loanProperty, value);
    const availableOperators = getAvailableOperators(loanProperty);

    if (!loanProperty) {
        return;
    }

    const type = loanPropertyEnumFieldTypes[loanProperty];

    const props = {
        name: `${fieldName}.value` as const,
        label: 'Value',
        required: true,
        hideRequiredIndicator: true
    };

    const valueField = type === null ? ( // TODO post-demo
        <DateField {...props} />
    ) : (
        <TextField
            {...props}
            {...(isEnumLoanProperty(loanProperty) && {
                select: true,
                children: renderEnumOptions(loanPropertyEnumDisplays[loanProperty]!)
            })}
            {...(type === 'numeric' && { type: 'number' })}
        />
    );

    return {
        valueField,
        formattedValue,
        availableOperatorsDisplay: getPartialEnumDisplay(operatorDisplay, availableOperators)
    };
}

export function isEnumLoanProperty(loanProperty: LoanProperty) {
    return loanPropertyEnumFieldTypes[loanProperty] === 'enum';
}

export function isBooleanLoanProperty(loanProperty: LoanProperty) {
    return loanPropertyEnumFieldTypes[loanProperty] === 'boolean';
}
