import { agencyDisplay } from '@api';
import { Check, Edit, RemoveCircleOutline } from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import {
    DateField, LabelGroup, LabeledValue, TextField, renderEnumOptions
} from '@tsp-ui/core/components';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import styles from './AgencyApprovalCard.module.scss';
import { ChecklistStepFormValues } from './ChecklistItemCard';
import { SpecialChecklistCardProps } from './SpecialChecklistSection';


type BaseFieldName = `agencies.${number}`

export function AgencyApprovalCard({ onRemove, index }: SpecialChecklistCardProps) {
    const [ editing, setEditing ] = useState(false);

    const baseFieldName: BaseFieldName = `agencies.${index}`;

    const formMethods = useFormContext<ChecklistStepFormValues>();

    const [
        agency, firstApprovalDate, latestApprovalDate
    ] = formMethods.watch([
        `${baseFieldName}.agency`, `${baseFieldName}.firstApprovalDate`, `${baseFieldName}.latestApprovalDate`
    ]);

    const handleSubmit = formMethods.handleSubmit(() => {
        setEditing(!editing);
    });

    useEffect(() => {
        if (!agency) {
            setEditing(true);
        }
    }, [ agency ]);

    return (
        <Paper
            className={styles.root}
            variant="outlined"
        >
            {editing ? (
                <>
                    <div className={styles.formContent}>
                        <TextField<ChecklistStepFormValues>
                            name={`${baseFieldName}.agency`}
                            label="Agency"
                            size="small"
                            variant="outlined"
                            required
                            select
                        >
                            {renderEnumOptions(agencyDisplay)}
                        </TextField>

                        <div />

                        <DateField<ChecklistStepFormValues>
                            name={`${baseFieldName}.firstApprovalDate`}
                            label="First approval date"
                            size="small"
                            variant="outlined"
                        />

                        <DateField<ChecklistStepFormValues>
                            name={`${baseFieldName}.latestApprovalDate`}
                            label="Latest approval date"
                            size="small"
                            variant="outlined"
                            required
                        />
                    </div>

                    <div className={styles.buttonContainer}>
                        <Tooltip
                            title="Done editing"
                            enterDelay={0}
                        >
                            <IconButton onClick={handleSubmit}>
                                <Check color="success" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title="Delete approval"
                            enterDelay={0}
                        >
                            <IconButton
                                onClick={onRemove}
                                edge="end"
                            >
                                <RemoveCircleOutline color="error" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.displayHeader}>
                        <Typography>
                            {agencyDisplay[agency]}
                        </Typography>

                        <div>
                            <Tooltip
                                title="Edit approval"
                                enterDelay={0}
                            >
                                <IconButton onClick={handleSubmit}>
                                    <Edit color="primary" />
                                </IconButton>
                            </Tooltip>

                            <Tooltip
                                title="Delete approval"
                                enterDelay={0}
                            >
                                <IconButton
                                    onClick={onRemove}
                                    edge="end"
                                >
                                    <RemoveCircleOutline color="error" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>

                    <LabelGroup>
                        <LabeledValue
                            label="First approval:"
                            value={firstApprovalDate || 'N/A'}
                        />

                        <LabeledValue
                            label="Latest approval:"
                            value={latestApprovalDate}
                        />
                    </LabelGroup>
                </>
            )}
        </Paper>
    );
}
