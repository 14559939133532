import api, { CustomerPendingSteps, License, stateDisplay } from '@api';
import { RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import { replaceItemById, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useCallback, useContext, useState } from 'react';

import { CustomerDetailContext } from '../../CustomerDetailPage';

import AddEditLicenseDialogContent from './components/AddEditLicenseDialogContent';
import LicenseListDialogContent from './components/LicenseListDialogContent';


const licenseCompareFn = (a: License, b: License) => (
    stateDisplay[a.state].localeCompare(stateDisplay[b.state]) || a.type.localeCompare(b.type)
);

enum DialogType {
    ADD,
    EDIT,
    LIST
}

const { ADD, EDIT, LIST } = DialogType;

const dialogTitles = {
    [ADD]: 'Add license',
    [EDIT]: 'Edit license',
    [LIST]: 'State licenses'
};

export default function LicenseDialog(props: Omit<RoutedDialogProps, 'title' | 'children' | 'onSubmit'>) {
    const [ dialogType, setDialogType ] = useState(LIST);
    const [ licenses, setLicenses ] = useState<License[]>([]);
    const [ defaultValues, setDefaultValues ] = useState<License>();
    const [ loading, setLoading ] = useState(false);
    const [ saveLoading, setSaveLoading ] = useState(false);

    const pageMessage = usePageMessage();
    const { updatePendingSteps, customer } = useContext(CustomerDetailContext);
    const { id } = customer!;

    useAsyncEffect(useCallback(async () => {
        if (id) {
            setLoading(true);

            try {
                setLicenses(await api.customer.licenses.getLicenses(id));
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching the licenses', error);
            }

            setLoading(false);
        }
    }, [ id, pageMessage ]));

    async function handleDelete(licenseID: string) {
        if (id) {
            setSaveLoading(true);

            const newLicensesArray = licenses.filter(license => license.id !== licenseID);

            try {
                await api.customer.licenses.deleteLicense(id, licenseID);

                setLicenses(newLicensesArray);

                pageMessage.success('License deleted');
            } catch (error) {
                setLicenses(licenses);

                pageMessage.handleApiError('An error occurred while deleting the licenses', error);
            }

            if (newLicensesArray.length === 0) {
                await updatePendingSteps(CustomerPendingSteps.LICENSES);
            }

            setSaveLoading(false);
        }
    }

    async function handleRenew(licenseID: string, renewedThroughYear: number) {
        if (id) {
            setSaveLoading(true);

            try {
                await api.customer.licenses.renewLicense(id, licenseID, renewedThroughYear);

                setLicenses(licenses.map(license => (license.id === licenseID ? {
                    ...license,
                    renewedThroughYear
                } : license)));

                pageMessage.success('License renewed');
            } catch (error) {
                setLicenses(licenses);

                pageMessage.handleApiError('An error occurred while renewing the license', error);
            }

            setSaveLoading(false);
        }
    }

    async function handleAdd(license: License) {
        setLicenses([ ...licenses, license ].sort(licenseCompareFn));

        await updatePendingSteps(CustomerPendingSteps.LICENSES);
    }

    function handleEdit(editedLicense: License) {
        setLicenses(replaceItemById(licenses, editedLicense).sort(licenseCompareFn));
    }

    function handleAddClick() {
        setDialogType(ADD);
        setDefaultValues(undefined);
    }

    function handleEditClick(license: License) {
        setDialogType(EDIT);
        setDefaultValues(license);
    }

    return (
        <RoutedDialog
            title={dialogTitles[dialogType]}
            {...props}
            loading={loading}
            maxWidth={false}
        >
            {dialogType === LIST ? (
                <LicenseListDialogContent
                    licenses={licenses}
                    onAddClick={handleAddClick}
                    onDelete={handleDelete}
                    onEditClick={handleEditClick}
                    onRenew={handleRenew}
                    loading={saveLoading}
                />
            ) : (
                <AddEditLicenseDialogContent
                    defaultValues={defaultValues}
                    onAdd={handleAdd}
                    onEdit={handleEdit}
                    onDone={() => setDialogType(LIST)}
                    {...props}
                />
            )}
        </RoutedDialog>
    );
}
