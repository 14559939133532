import { Notification, notificationTypeDisplay } from '@api';
import { Close, Drafts, Markunread } from '@mui/icons-material';
import {
    Divider, IconButton, Tooltip, Typography
} from '@mui/material';
import { Chip } from '@tsp-ui/core/components';
import { formatDistanceToNowStrict } from '@tsp-ui/core/utils';
import LoanNumberLink from '@views/components/MainNav/components/NotificationsButton/components/LoanNumberLink';
import clsx from 'clsx';
import { parseISO } from 'date-fns';
import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { TransitionProps } from 'react-transition-group/Transition';

import styles from './NotificationCard.module.scss';


export type NotificationCardProps = Partial<TransitionProps<HTMLDivElement>> & {
    notification: Notification;
    onLinkClick(notification: Notification): void;
    className?: string;
    formatDate?: (date: Date) => string;
    onDismiss?(notification: Notification): void;
    onMarkRead?(notification: Notification): void;
    onMarkUnread?(notification: Notification): void;
}

export function NotificationCard({
    onLinkClick,
    onDismiss,
    notification,
    className,
    onMarkRead,
    onMarkUnread,
    formatDate,
    ...transitionProps
}: NotificationCardProps) {
    const nodeRef = useRef<HTMLDivElement>(null);

    const {
        type, description, loanNumber, createdDate: createdDateStr, isRead, isViewed
    } = notification;

    const createdDate = parseISO(createdDateStr);

    return (
        <div className={clsx(styles.root, className)}>
            <CSSTransition
                nodeRef={nodeRef}
                timeout={250}
                classNames={{
                    appear: styles.card_appear,
                    appearActive: styles.card_appear_active,
                    enter: styles.card_enter,
                    enterActive: styles.card_enter_active,
                    exit: styles.card_exit,
                    exitActive: styles.card_exit_active
                }}
                {...transitionProps}
            >
                <div
                    ref={nodeRef}
                    className={styles.card}
                >
                    <div className={styles.row}>
                        <Typography fontWeight={500}>
                            {notificationTypeDisplay[type]}
                        </Typography>

                        <div className={styles.buttonContainer}>
                            {!isViewed && (
                                <Chip className={styles.newChip}>
                                    New
                                </Chip>
                            )}

                            {onDismiss && (
                                <Tooltip title="Dismiss">
                                    <IconButton
                                        size="small"
                                        edge="end"
                                        onClick={() => onDismiss(notification)}
                                    >
                                        <Close
                                            color="action"
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {onMarkRead && onMarkUnread && (
                                <Tooltip
                                    title={isRead
                                        ? 'Mark as unread'
                                        : 'Mark as read'}
                                >
                                    <IconButton
                                        size="small"
                                        edge="end"
                                        onClick={() => {
                                            if (isRead) {
                                                onMarkUnread(notification);
                                            } else {
                                                onMarkRead(notification);
                                            }
                                        }}
                                    >
                                        {isRead ? (
                                            <Markunread
                                                color="secondary"
                                                fontSize="small"
                                            />
                                        ) : (
                                            <Drafts
                                                color="secondary"
                                                fontSize="small"
                                            />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    </div>

                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                        {description}
                    </Typography>

                    <div className={styles.row}>
                        <LoanNumberLink
                            loanNumber={loanNumber}
                            onClick={() => onLinkClick(notification)}
                        />

                        <Typography
                            variant="caption"
                            color="textSecondary"
                        >
                            {formatDate?.(createdDate) || formatDistanceToNowStrict(createdDate, {
                                addSuffix: true
                            })}
                        </Typography>
                    </div>
                </div>
            </CSSTransition>

            <Divider className={styles.divider} />
        </div>
    );
}
