import { rest } from 'msw';

import { Agency, ChecklistStepType } from '../..';
import { getMockUrl } from '../../../mocks/getMockUrl';

import { CustomerChecklistStep, Document } from './checklist-api';


export const mocks = [
    rest.get(getMockUrl('/customer/:customerID/checklist'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(checklistSteps)
    ))),
    rest.put(getMockUrl('/customer/:customerID/checklist/:checklistID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.post(getMockUrl('/customer/:customerID/checklist/:checklistID/document'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(fileUploadToDocumentArray(req.body))
    ))),
    rest.delete(getMockUrl('/customer/:customerID/checklist/:checklistID/document/:docID'), (req, res, ctx) => (res(
        ctx.status(200)
    )))
];

export const SAMPLE_PDF_URL = 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf';

function fileUploadToDocumentArray(fd: any): Document[] {
    const checklistDocs: Document[] = [];

    Object.values(fd).forEach((v, i) => checklistDocs.push({
        id: `${i + 100}`,
        name: (v as File).name,
        url: SAMPLE_PDF_URL
    }));

    return checklistDocs;
}

export const checklistSteps: CustomerChecklistStep[] = [
    {
        customerId: 1,
        id: '1',
        name: 'Customer application',
        description: 'Completed, signed and dated',
        type: ChecklistStepType.DEFAULT,
        isRequired: true,
        isCompleted: false,
        completedDate: '',
        orderId: 1,
        notes: '',
        isDocRequired: true,
        documents: [
            {
                id: '1',
                name: 'Document 1.pdf',
                url: SAMPLE_PDF_URL
            },
            {
                id: '2',
                name: 'Document 2.pdf',
                url: SAMPLE_PDF_URL
            }
        ]
    },
    {
        customerId: 1,
        id: '2',
        name: 'Customer loan purchase & sale agreement',
        description: 'Completed, signed and dated',
        type: ChecklistStepType.DEFAULT,
        isRequired: true,
        isCompleted: false,
        completedDate: '',
        orderId: 2,
        notes: '',
        isDocRequired: false
    },
    {
        customerId: 1,
        id: '3',
        name: 'Officer/manager resumes',
        description: 'Resumes for all key officers/managers listed on the application',
        type: ChecklistStepType.DEFAULT,
        isRequired: true,
        isCompleted: false,
        completedDate: '',
        orderId: 3,
        notes: '',
        isDocRequired: false
    },
    {
        customerId: 1,
        id: '4',
        name: 'Optional step',
        description: 'Resumes for all key officers/managers listed on the application',
        type: ChecklistStepType.DEFAULT,
        isRequired: false,
        isCompleted: false,
        completedDate: '',
        orderId: 3,
        notes: '',
        isDocRequired: false
    },
    {
        customerId: 1,
        id: '5',
        name: 'Completed step',
        description: 'Resumes for all key officers/managers listed on the application',
        type: ChecklistStepType.DEFAULT,
        isRequired: false,
        isCompleted: true,
        completedDate: '2021-10-31',
        orderId: 3,
        notes: '',
        isDocRequired: false
    },
    {
        customerId: 1,
        id: '6',
        name: 'Agency approval',
        description: 'this is where you fill out agency approvals',
        type: ChecklistStepType.AGENCY,
        isRequired: true,
        isCompleted: false,
        completedDate: '',
        orderId: 3,
        notes: '',
        agencies: [
            {
                agency: Agency.FHA,
                id: '1',
                firstApprovalDate: '',
                latestApprovalDate: '2021-10-31'
            }
        ],
        isDocRequired: true
    },
    {
        customerId: 1,
        id: '7',
        name: 'Investor relationships',
        description: 'this is where you fill out investor relationships',
        type: ChecklistStepType.INVESTORS,
        isRequired: true,
        isCompleted: false,
        completedDate: '',
        orderId: 3,
        notes: '',
        investors: [
            {
                id: '1',
                companyName: 'Investor 1',
                contact: 'Joe Shmo',
                phone: '123-456-7890',
                emailAddress: 'joes@shmos-mortgage.com'
            }
        ],
        isDocRequired: true
    },
    {
        customerId: 1,
        id: '8',
        name: 'Warehouse relationships',
        description: 'this is where you fill out warehouse relationships',
        type: ChecklistStepType.WAREHOUSE,
        isRequired: true,
        isCompleted: false,
        completedDate: '',
        orderId: 3,
        notes: '',
        warehouses: [
            {
                id: '1',
                lenderName: 'Lender 1',
                lineAmount: 1000000,
                contact: 'Joe Shmo',
                phone: '123-456-7890',
                emailAddress: 'joes@shmos-mortgage.com'
            }
        ],
        isDocRequired: true
    },
    {
        customerId: 1,
        id: '9',
        name: 'Mortgage insurance relationships',
        description: 'this is where you fill out mortgage insurance relationships',
        type: ChecklistStepType.INSURANCE,
        isRequired: true,
        isCompleted: false,
        completedDate: '',
        orderId: 3,
        notes: '',
        insurances: [
            {
                id: '1',
                contact: 'Jordan Morganson',
                emailAddress: 'jmorganson@morgsurance.com',
                insurerName: 'Morgan & Sons Insurance Group',
                phone: '463-3351-6829',
                policyNumber: '128527'
            }
        ],
        isDocRequired: true
    }
];
