import api, { LockAvailability, LockAvailabilityHoliday, TimeString } from '@api';
import { RoutedDialogManager } from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import Page from '@views/components/Page';
import {
    Dispatch, SetStateAction, createContext, useCallback, useMemo, useState
} from 'react';
import { useParams } from 'react-router-dom';

import { EditLockAvailabilityDialog } from './EditLockAvailabilityDialog';
import LockAvailabilityButton from './LockAvailabilityButton';
import { LockAvailabilityContent } from './LockAvailabilityContent';
import { LockAvailabilityHolidayDialog } from './LockAvailabilityHolidayDialog';


export type Day =  'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export interface LockAvailabilityDay {
    day: Day;
    available: boolean;
    startTime: TimeString | null | undefined;
    endTime: TimeString | null | undefined;
}

export interface LockAvailabilityContextValue {
    lockAvailability?: LockAvailability;
    setLockAvailability: Dispatch<SetStateAction<LockAvailability | undefined>>;
    lockAvailabilityHolidays: LockAvailabilityHoliday[];
    setLockAvailabilityHolidays: Dispatch<SetStateAction<LockAvailabilityHoliday[]>>;
}

export const LockAvailabilityContext = createContext<LockAvailabilityContextValue>({
    lockAvailability: undefined,
    setLockAvailability: () => {},
    lockAvailabilityHolidays: [],
    setLockAvailabilityHolidays: () => {}
});

export function useLockAvailabilityContextValue(): [ LockAvailabilityContextValue, boolean ] {
    const [ lockAvailability, setLockAvailability ] = useState<LockAvailability>();
    const [ lockAvailabilityHolidays, setLockAvailabilityHolidays ] = useState<LockAvailabilityHoliday[]>([]);
    const [ loading, setLoading ] = useState(true);

    const { customerID } = useParams();
    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            const [ [ availability ], holidays ] = await Promise.all([
                api.availability.getLockAvailabilities(customerID),
                api.availability.getLockAvailabilityHolidays(customerID)
            ]);

            setLockAvailability(availability);
            setLockAvailabilityHolidays(holidays);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching lock availability', error);
        }

        setLoading(false);
    }, [
        customerID, pageMessage, setLoading
    ]));

    const lockAvailabilityContextValue = useMemo(() => ({
        lockAvailability,
        setLockAvailability,
        lockAvailabilityHolidays,
        setLockAvailabilityHolidays
    }), [
        lockAvailability, setLockAvailability, lockAvailabilityHolidays, setLockAvailabilityHolidays
    ]);

    return [ lockAvailabilityContextValue, loading ];
}

export default function LockAvailabilityPage() {
    const [ lockAvailabilityContextValue, loading ] = useLockAvailabilityContextValue();

    return (
        <LockAvailabilityContext.Provider value={lockAvailabilityContextValue}>
            <Page
                header="Lock availability"
                headerActions={(
                    <LockAvailabilityButton />
                )}
                loading={loading}
            >
                <LockAvailabilityContent />

                <RoutedDialogManager routes={routes} />
            </Page>
        </LockAvailabilityContext.Provider>
    );
}

const routes = {
    ':day/edit': EditLockAvailabilityDialog,
    ':id/edit-holiday': LockAvailabilityHolidayDialog,
    add: LockAvailabilityHolidayDialog
};
