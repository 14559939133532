import { rest } from 'msw';

import { Contact, ContactResponsibility } from '../..';
import { getMockUrl } from '../../../mocks/getMockUrl';
import { ContactTitle } from '../../enums/contactTitleEnum';


let contactID = 100;

export const mocks = [
    rest.get(getMockUrl('/client/:clientID/contact'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(contacts)
    ))),
    rest.delete(getMockUrl('/client/:clientID/contact/:contactID'), (req, res, ctx) => (res(
        ctx.status(200)
    ))),
    rest.post(getMockUrl('/client/:clientID/contact'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as Contact,
            id: `${contactID++}`
        })
    ))),
    rest.put(getMockUrl('/client/:clientID/contact/:contactID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(contacts[0])
    )))
];

export const contacts: Contact[] = [
    {
        id: '1',
        name: 'Manager Shmo',
        responsibilities: [ ContactResponsibility.SENIOR_MANAGER ],
        email: 'manager@shmos-mortgage.com',
        phone: '2222222222',
        title: ContactTitle.INVESTOR_SUSPENSE_COORDINATOR,
        isPrimary: true
    },
    {
        id: '2',
        name: 'Joe Shmo',
        responsibilities: [
            ContactResponsibility.ACCOUNTING,
            ContactResponsibility.INTERNAL_WAREHOUSE,
            ContactResponsibility.POST_CLOSING,
            ContactResponsibility.MERS_TRANSFERS
        ],
        email: 'joe@shmos-mortgage.com',
        phone: '5555555555',
        title: ContactTitle.COLLATERAL_SHIPMENT,
        isPrimary: false
    }
];
