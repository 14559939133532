/**
 * An enum listing every US state abbreviation (and Washington D.C.)
 */
export enum State {
    AL = 'AL',
    AK = 'AK',
    AZ = 'AZ',
    AR = 'AR',
    CA = 'CA',
    CO = 'CO',
    CT = 'CT',
    DE = 'DE',
    DC = 'DC',
    FL = 'FL',
    GA = 'GA',
    HI = 'HI',
    ID = 'ID',
    IL = 'IL',
    IN = 'IN',
    IA = 'IA',
    KS = 'KS',
    KY = 'KY',
    LA = 'LA',
    ME = 'ME',
    MD = 'MD',
    MA = 'MA',
    MI = 'MI',
    MN = 'MN',
    MS = 'MS',
    MO = 'MO',
    MT = 'MT',
    NE = 'NE',
    NV = 'NV',
    NH = 'NH',
    NJ = 'NJ',
    NM = 'NM',
    NY = 'NY',
    NC = 'NC',
    ND = 'ND',
    OH = 'OH',
    OK = 'OK',
    OR = 'OR',
    PA = 'PA',
    RI = 'RI',
    SC = 'SC',
    SD = 'SD',
    TN = 'TN',
    TX = 'TX',
    UT = 'UT',
    VT = 'VT',
    VA = 'VA',
    WA = 'WA',
    WV = 'WV',
    WI = 'WI',
    WY = 'WY'
};

export const stateDisplay = {
    [State.AL]: 'Alabama',
    [State.AK]: 'Alaska',
    [State.AZ]: 'Arizona',
    [State.AR]: 'Arkansas',
    [State.CA]: 'California',
    [State.CO]: 'Colorado',
    [State.CT]: 'Connecticut',
    [State.DE]: 'Delaware',
    [State.DC]: 'District Of Columbia',
    [State.FL]: 'Florida',
    [State.GA]: 'Georgia',
    [State.HI]: 'Hawaii',
    [State.ID]: 'Idaho',
    [State.IL]: 'Illinois',
    [State.IN]: 'Indiana',
    [State.IA]: 'Iowa',
    [State.KS]: 'Kansas',
    [State.KY]: 'Kentucky',
    [State.LA]: 'Louisiana',
    [State.ME]: 'Maine',
    [State.MD]: 'Maryland',
    [State.MA]: 'Massachusetts',
    [State.MI]: 'Michigan',
    [State.MN]: 'Minnesota',
    [State.MS]: 'Mississippi',
    [State.MO]: 'Missouri',
    [State.MT]: 'Montana',
    [State.NE]: 'Nebraska',
    [State.NV]: 'Nevada',
    [State.NH]: 'New Hampshire',
    [State.NJ]: 'New Jersey',
    [State.NM]: 'New Mexico',
    [State.NY]: 'New York',
    [State.NC]: 'North Carolina',
    [State.ND]: 'North Dakota',
    [State.OH]: 'Ohio',
    [State.OK]: 'Oklahoma',
    [State.OR]: 'Oregon',
    [State.PA]: 'Pennsylvania',
    [State.RI]: 'Rhode Island',
    [State.SC]: 'South Carolina',
    [State.SD]: 'South Dakota',
    [State.TN]: 'Tennessee',
    [State.TX]: 'Texas',
    [State.UT]: 'Utah',
    [State.VT]: 'Vermont',
    [State.VA]: 'Virginia',
    [State.WA]: 'Washington',
    [State.WV]: 'West Virginia',
    [State.WI]: 'Wisconsin',
    [State.WY]: 'Wyoming'
};
