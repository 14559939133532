import {
    Address, Contact, ProductType, User
} from '..';
import { apiUtils } from '../api-utils';
import { ClientStatus } from '../enums/clientSatusEnum';


export type { ClientInvestor, ClientLoanProgram } from './investor/clientInvestor-api';

export * as contacts from './contacts/contacts-api';
export * as investors from './investor/clientInvestor-api';

export function getClients(): Promise<ClientDetail[]> {
    return apiUtils.get('/client');
}

export function getClient(clientID: string): Promise<ClientDetail> {
    return apiUtils.get(`/client/${clientID}`);
}

export function createClient(client: ClientDetail): Promise<ClientDetail> {
    return apiUtils.post('/client', client);
}

export function updateClient(client: ClientDetail): Promise<ClientDetail> {
    return apiUtils.put(`/client/${client.id}`, client);
}

export function getProductConfig(): Promise<ClientProductConfig> {
    return apiUtils.get('/client/productConfig');
}

export function getAuthorizedPremicorrUsers(clientID: string): Promise<User[]> {
    return apiUtils.get(`/client/${clientID}/authorizedUsers`);
}

export function addAuthorizedPremicorrUser(clientID: string, user: User): Promise<User> {
    return apiUtils.post(`/client/${clientID}/authorizedUsers`, user);
}

export function deleteAuthorizedPremicorrUser(clientID: string, userID: string): Promise<void> {
    return apiUtils.delete(`/client/${clientID}/authorizedUsers/${userID}`);
}

export interface ClientDetail {
    id: string;
    nmlsId: string | null;
    name: string;
    shortName: string;
    code: string;
    isInternal: boolean;
    status: ClientStatus;
    address: Address;
    primaryContact?: Contact;
    allowInternalEdits?: boolean;
}

export interface ClientProductConfig {
    availableLockPeriods: number[];
    availableProducts: AvailableProduct[];
}

export interface AvailableProduct {
    id: string;
    name: string;
    productType: ProductType;
}
