import { usePageMessage } from '@tsp-ui/core/utils';
import { useState } from 'react';

import ConditionsSummary from './components/ConditionsSummary';
import UnderwritingPageTemplate from './components/UnderwritingPageTemplate';
import UnderwritingStepCard from './components/UnderwritingStepCard';
import AKAsSection from './components/credit/AKAsSection';
import CreditDetailsSection from './components/credit/CreditDetailsSection';
import CreditInquiriesSection from './components/credit/CreditInquiriesSection';
import DerogatoriesSection from './components/credit/DerogatoriesSection';
import FraudSection from './components/credit/FraudSection';
import LiabilitiesSection from './components/credit/LiabilitiesSection';
import MortgageHistorySection from './components/credit/MortgageHistorySection';
import OtherLiabilitiesSection from './components/credit/OtherLiabilitiesSection';

// TODO use react-router for this
export type CreditUnderwritingTabs = 'summary' | 'details' | 'inquiries' | 'history' | 'fraud' | 'derogatories' | 'akas'
    | 'other-liabilities' | 'liabilities';

export default function CreditUnderwritingPage() {
    const [ tab, setTab ] = useState<CreditUnderwritingTabs>('summary');
    const [ finishedTabs, setFinishedTabs ] = useState<CreditUnderwritingTabs[]>([]);
    const pageMessage = usePageMessage();

    function tabComplete(tab: CreditUnderwritingTabs, nextTab: CreditUnderwritingTabs) {
        setFinishedTabs(finishedTabs.concat(tab));
        setTab(nextTab);

        if (tab === 'inquiries') {
            pageMessage.info('A condition was added to this loan',
                [ 'Provide letter of explanation for 1 credit inquiry' ]);
        } else if (tab === 'history') {
            pageMessage.info('A condition was added to this loan',
                [ 'Obtain updated mortgage history for 1 property' ]);
        }
    }

    const numConditions = finishedTabs.includes('history')
        ? 2
        : finishedTabs.includes('inquiries')
            ? 1 : 0;

    return (
        <UnderwritingPageTemplate
            relevantDocs={[
                {
                    name: 'Credit report single',
                    url: 'https://demo-api.premicorr.net/api/document/loan/322F0641-CABE-4E0E-B6A1-B6F48EE533E5/CREDIT_REPORT/Credit%20Report%20Single.pdf'
                }
            ]}
            conditionsShown={tab === 'summary'}
            onConditionsClick={() => setTab('summary')}
            numConditions={numConditions}
            stepCards={(
                <>
                    <UnderwritingStepCard
                        active={tab === 'details'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('details')}
                        label="Report details"
                        onClick={() => setTab('details')}
                    />

                    <UnderwritingStepCard
                        active={tab === 'inquiries'}
                        conditionIDs={[ '' ]}
                        done={finishedTabs.includes('inquiries')}
                        label="Credit inquiries"
                        onClick={() => setTab('inquiries')}
                    />

                    <UnderwritingStepCard
                        active={tab === 'history'}
                        conditionIDs={[ '' ]}
                        done={finishedTabs.includes('history')}
                        label="Mortgage history"
                        onClick={() => setTab('history')}
                    />

                    <UnderwritingStepCard
                        active={tab === 'fraud'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('fraud')}
                        label="Fraud check"
                        onClick={() => setTab('fraud')}
                    />

                    <UnderwritingStepCard
                        active={tab === 'derogatories'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('derogatories')}
                        label="Derogatories"
                        onClick={() => setTab('derogatories')}
                    />

                    <UnderwritingStepCard
                        active={tab === 'akas'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('akas')}
                        label="AKAs & Addresses"
                        onClick={() => setTab('akas')}
                    />

                    <UnderwritingStepCard
                        active={tab === 'other-liabilities'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('other-liabilities')}
                        label="Other liabilities"
                        onClick={() => setTab('other-liabilities')}
                    />

                    <UnderwritingStepCard
                        active={tab === 'liabilities'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('liabilities')}
                        label="Liabilities"
                        onClick={() => setTab('liabilities')}
                    />
                </>
            )}
        >
            {tab === 'summary' ? (
                <ConditionsSummary
                    header="Credit & Liabilities conditions"
                    noConditions={numConditions === 0}
                    isCredit
                    onStartClick={() => setTab('details')}
                />
            ) : tab === 'details' ? (
                <CreditDetailsSection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : tab === 'inquiries' ? (
                <CreditInquiriesSection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : tab === 'history' ? (
                <MortgageHistorySection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : tab === 'fraud' ? (
                <FraudSection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : tab === 'derogatories' ? (
                <DerogatoriesSection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : tab === 'akas' ? (
                <AKAsSection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : tab === 'other-liabilities' ? (
                <OtherLiabilitiesSection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : tab === 'liabilities' ? (
                <LiabilitiesSection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : null}
        </UnderwritingPageTemplate>
    );
}
