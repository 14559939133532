import api, { PurchaseAdviceConfig, RoundingMethod, roundingMethodDisplay } from '@api';
import {
    Button, DialogActions, DialogContent, Typography
} from '@mui/material';
import {
    RoutedDialog, TextField, isAtLeast, isAtMost
    , renderEnumOptions
} from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import styles from './PurchaseAdviceDialog.module.scss';
import { PurchaseAdviceContext } from './PurchaseAdviceManagementPage';


export function PurchaseAdviceDialog() {
    const [ loading, setLoading ] = useState(false);

    const { purchaseAdviceConfig: defaultValues, setPurchaseAdviceConfig } = useContext(PurchaseAdviceContext);
    const formMethods = useForm<PurchaseAdviceConfig>({ defaultValues });
    const navigate = useNavigate();
    const pageMessage = usePageMessage();

    const handleSubmit = formMethods.handleSubmit(async (formData) => {
        setLoading(true);

        try {
            setPurchaseAdviceConfig(defaultValues?.id
                ? await api.purchaseAdvice.updatePurchaseAdviceConfig(formData)
                : await api.purchaseAdvice.addPurchaseAdviceConfig(formData));

            navigate('..');

            pageMessage.success('Purchase advice config saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the purchase advice config', error);
        }

        setLoading(false);
    });

    const roundingMethod = formMethods.watch('roundingMethod');
    const precisionFormValue = formMethods.watch('interestRatePrecision');
    const interestRatePrecision = precisionFormValue < 0 ? 0 : precisionFormValue > 4 ? 4 : +precisionFormValue;

    const sampleNumber = '5.5555'.slice(0, interestRatePrecision + 2);
    const roundedDown = interestRatePrecision ? sampleNumber : sampleNumber.slice(0, -1);
    const roundedUp = round(+sampleNumber + 1 / 10 ** interestRatePrecision, interestRatePrecision);

    return (
        <RoutedDialog
            closeTo=".."
            title="Interest settings"
            loading={loading}
        >
            <DialogContent className={styles.root}>
                <form
                    id={PurchaseAdviceDialog.formID}
                    onSubmit={handleSubmit}
                    className={styles.form}
                >
                    <FormProvider {...formMethods}>
                        <TextField<PurchaseAdviceConfig>
                            name="interestRatePrecision"
                            label="Interest rate precision"
                            type="number"
                            rules={{
                                ...isAtLeast(0),
                                ...isAtMost(4)
                            }}
                            helperText={(
                                <>
                                    The maximum number of decimal points of precision on the interest rate.
                                    Example:&nbsp;

                                    <Typography
                                        fontSize="inherit"
                                        color="inherit"
                                        fontWeight={500}
                                        component="span"
                                    >
                                        {interestRatePrecision ? `5.${'0'.repeat(interestRatePrecision - 1)}1` : '5'}
                                    </Typography>.
                                </>
                            )}
                        />

                        <TextField<PurchaseAdviceConfig>
                            name="roundingMethod"
                            label="Rounding method"
                            helperText={(
                                <>
                                    Determines how the interest rate is rounded when necessary. Example:&nbsp;

                                    <Typography
                                        fontSize="inherit"
                                        color="inherit"
                                        fontWeight={500}
                                        component="span"
                                    >
                                        {(roundingMethod === RoundingMethod.ROUND_UP) && `${sampleNumber}2 rounds up to ${roundedUp}`}

                                        {(roundingMethod === RoundingMethod.ROUND_DOWN) && `${sampleNumber}7 rounds down to ${roundedDown}`}

                                        {(roundingMethod === RoundingMethod.DEFAULT)
                                        && `${sampleNumber}5 rounds up to ${roundedUp}. ${sampleNumber}4 rounds down to ${roundedDown}` }
                                    </Typography>.
                                </>
                            )}
                            select
                        >
                            {renderEnumOptions(roundingMethodDisplay)}
                        </TextField>

                        <TextField<PurchaseAdviceConfig>
                            name="cutoffDay"
                            type="number"
                            label="Cutoff day"
                            helperText="The day of the month by which a loan must be purchased for the first payment date to start the following month."
                        />
                    </FormProvider>
                </form>

            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    type="submit"
                    form={PurchaseAdviceDialog.formID}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

PurchaseAdviceDialog.formID = 'add-edit-purchase-advice-form';

/**
 * Corrects js floating point precision errors
 */
function round(number: number, precision: number) {
    return Math.round(number * 10 ** precision) / 10 ** precision;
}
