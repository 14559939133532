import { amortizationTypeDisplay } from './amorTypeEnum';
import { automatedUwSystemDisplay } from './automatedUwSystemEnum';
import { booleanEnumDisplay } from './booleanEnum';
import { loanPurposeDisplay } from './loanPurposeEnum';
import { loanTypeDisplay } from './loanTypeEnum';
import { numUnitsDisplay } from './numUnitsEnum';
import { occupancyTypeDisplay } from './occupancyTypeEnum';
import { propertyTypeDisplay } from './propertyTypeEnum';
import { specialtyProductDisplay } from './specialtyProductEnum';
import { stateDisplay } from './stateEnum';


export enum LoanProperty {
    LOAN_TYPE = 'LOAN_TYPE',
    OCCUPANCY = 'OCCUPANCY',
    PURPOSE = 'PURPOSE',
    AMORT_TYPE = 'AMORT_TYPE',
    PROPERTY_TYPE = 'PROPERTY_TYPE',
    UNITS = 'UNITS',
    FICO = 'FICO',
    LTV = 'LTV',
    CLTV = 'CLTV',
    DTI = 'DTI',
    TERM = 'TERM',
    AUS = 'AUS',
    MIN_RESERVES_MONTHS = 'MIN_RESERVES_MONTHS',
    HIGH_BALANCE = 'HIGH_BALANCE',
    SPECIALTY_PRODUCT = 'SPECIALTY_PRODUCT',
    EFFECTIVE_DATE = 'EFFECTIVE_DATE',
    EXPIRATION_DATE = 'EXPIRATION_DATE',
    STATE = 'STATE',
    LOAN_AMOUNT = 'LOAN_AMOUNT',
    INVESTOR = 'INVESTOR'
}

export const loanPropertyDisplay = {
    [LoanProperty.LOAN_TYPE]: 'Loan Type',
    [LoanProperty.OCCUPANCY]: 'Occupancy',
    [LoanProperty.PURPOSE]: 'Purpose',
    [LoanProperty.AMORT_TYPE]: 'Amortization Type',
    [LoanProperty.PROPERTY_TYPE]: 'Property Type',
    [LoanProperty.UNITS]: 'Units',
    [LoanProperty.FICO]: 'FICO',
    [LoanProperty.LTV]: 'LTV',
    [LoanProperty.CLTV]: 'CLTV',
    [LoanProperty.DTI]: 'DTI',
    [LoanProperty.TERM]: 'Term',
    [LoanProperty.AUS]: 'AUS',
    [LoanProperty.MIN_RESERVES_MONTHS]: 'Min Reserves Months',
    [LoanProperty.HIGH_BALANCE]: 'High Balance',
    [LoanProperty.SPECIALTY_PRODUCT]: 'Specialty Product',
    [LoanProperty.EFFECTIVE_DATE]: 'Effective Date',
    [LoanProperty.EXPIRATION_DATE]: 'Expiration Date',
    [LoanProperty.STATE]: 'State',
    [LoanProperty.LOAN_AMOUNT]: 'Loan Amount',
    [LoanProperty.INVESTOR]: 'Investor'
};

export const loanPropertyEnumFieldTypes = {
    [LoanProperty.LOAN_TYPE]: 'enum',
    [LoanProperty.OCCUPANCY]: 'enum',
    [LoanProperty.PURPOSE]: 'enum',
    [LoanProperty.AMORT_TYPE]: 'enum',
    [LoanProperty.PROPERTY_TYPE]: 'enum',
    [LoanProperty.UNITS]: 'enum',
    [LoanProperty.FICO]: 'numeric',
    [LoanProperty.LTV]: 'numeric',
    [LoanProperty.CLTV]: 'numeric',
    [LoanProperty.DTI]: 'numeric',
    [LoanProperty.TERM]: 'numeric',
    [LoanProperty.AUS]: 'enum',
    [LoanProperty.MIN_RESERVES_MONTHS]: 'numeric',
    [LoanProperty.HIGH_BALANCE]: 'boolean',
    [LoanProperty.SPECIALTY_PRODUCT]: 'enum',
    [LoanProperty.EFFECTIVE_DATE]: null,
    [LoanProperty.EXPIRATION_DATE]: null,
    [LoanProperty.STATE]: 'enum',
    [LoanProperty.LOAN_AMOUNT]: 'numeric',
    [LoanProperty.INVESTOR]: 'enum'
} as const;

// TODO post-demo get rid of this
export enum InvestorEnum {
    FNMA = 'FNMA',
    GNMA = 'GNMA',
    FHLMC = 'FHLMC'
}

export const loanPropertyEnumDisplays = {
    [LoanProperty.LOAN_TYPE]: loanTypeDisplay,
    [LoanProperty.OCCUPANCY]: occupancyTypeDisplay,
    [LoanProperty.PURPOSE]: loanPurposeDisplay,
    [LoanProperty.AMORT_TYPE]: amortizationTypeDisplay,
    [LoanProperty.PROPERTY_TYPE]: propertyTypeDisplay,
    [LoanProperty.UNITS]: numUnitsDisplay,
    [LoanProperty.FICO]: null,
    [LoanProperty.LTV]: null,
    [LoanProperty.CLTV]: null,
    [LoanProperty.DTI]: null,
    [LoanProperty.TERM]: null,
    [LoanProperty.AUS]: automatedUwSystemDisplay,
    [LoanProperty.MIN_RESERVES_MONTHS]: null,
    [LoanProperty.HIGH_BALANCE]: booleanEnumDisplay,
    [LoanProperty.SPECIALTY_PRODUCT]: specialtyProductDisplay,
    [LoanProperty.EFFECTIVE_DATE]: null,
    [LoanProperty.EXPIRATION_DATE]: null,
    [LoanProperty.STATE]: stateDisplay,
    [LoanProperty.LOAN_AMOUNT]: null,
    [LoanProperty.INVESTOR]: {
        FNMA: 'Fannie Mae',
        GNMA: 'Ginnie Mac',
        FHLMC: 'Freddie Mac'
    }
} as const;
