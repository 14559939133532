export enum LoanRoleAssignment {
    LOAN_OFFICER = 'LOAN_OFFICER',
    LOAN_SETUP = 'LOAN_SETUP',
    UNDERWRITER = 'UNDERWRITER'
}

export const loanRoleAssignmentDisplay = {
    [LoanRoleAssignment.LOAN_OFFICER]: 'Loan officer',
    [LoanRoleAssignment.LOAN_SETUP]: 'Loan setup',
    [LoanRoleAssignment.UNDERWRITER]: 'Underwriter'
};
