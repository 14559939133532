import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { SubtleLink } from '@tsp-ui/core/components';
import { Link } from 'react-router-dom';

import styles from './LLPACard.module.scss';


interface LoanProgramCardProps {
    title: string;
    description: string;
}

export function LLPACard({ title, description }: LoanProgramCardProps) {
    return (
        <Paper
            elevation={0}
            className={styles.root}
        >
            <div>
                <SubtleLink
                    to="llpas/1"
                >
                    {title}
                </SubtleLink>

                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {description}
                </Typography>
            </div>

            <div className={styles.actions}>
                <Tooltip title="Edit LLPA">
                    <IconButton
                        component={Link}
                        to="edit"
                    >
                        <Edit color="secondary" />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Delete LLPA">
                    <IconButton>
                        <RemoveCircleOutline color="error" />
                    </IconButton>
                </Tooltip>
            </div>
        </Paper>
    );
}
