import {
    AttachMoney, Edit, MoneyOff, RemoveCircleOutline
} from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { LabelGroup, LabeledValue } from '@tsp-ui/core/components';

import styles from './LiabilitiesCard.module.scss';


interface LiabilityCardProps {
    excluded?: boolean;
}

export default function LiabilityCard({ excluded }: LiabilityCardProps) {
    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            <div className={styles.accountInfo}>
                <Typography className={styles.creditorName}>
                    NSTAR/COOPER
                </Typography>

                <Typography variant="body2">
                    96644701121E00220150
                </Typography>

                <Typography
                    variant="body2"
                    className={styles.liabilityType}
                >
                    Installment
                </Typography>
            </div>

            <div>
                <div className={styles.iconButtons}>
                    <Tooltip title="Delete">
                        <IconButton size="small">
                            <RemoveCircleOutline color="error" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={excluded ? 'Include' : 'Exclude'}>
                        <IconButton size="small">
                            {excluded ? (
                                <AttachMoney color="action" />
                            ) : (
                                <MoneyOff color="action" />
                            )}
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Edit">
                        <IconButton size="small">
                            <Edit color="secondary" />
                        </IconButton>
                    </Tooltip>
                </div>

                <LabelGroup>
                    <LabeledValue
                        label="Account balance:"
                        value="$100,000"
                        classNames={{ value: styles.rightAlign }}
                    />

                    <LabeledValue
                        label="Monthly payment:"
                        value="$1,000"
                        classNames={{ value: styles.rightAlign }}
                    />
                </LabelGroup>
            </div>
        </Paper>
    );
}
