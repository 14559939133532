import {
    LLPAMatrixEntry,
    LoanProperty,
    NumericMatrixEntry,
    NumericMatrixEntryType,
    loanPropertyEnumDisplays,
    numericFieldOperatorDisplay
} from '@api';
import { AddCircleOutline, Edit, MoreVert } from '@mui/icons-material';
import {
    IconButton, Menu, MenuItem, Tooltip
} from '@mui/material';
import { formatCurrency } from '@tsp-ui/core/utils';
import { LLPAMatrixContext } from '@views/admin/llpas/components/LLPAMatrix/LLPAMatrix';
import matrixStyles from '@views/admin/llpas/components/LLPAMatrix/LLPAMatrix.module.scss';
import updateMatrixEntry from '@views/admin/llpas/components/LLPAMatrix/utils/updateMatrixEntry';
import clsx from 'clsx';
import deepEqual from 'fast-deep-equal';
import {
    Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState
} from 'react';

import ActionsCell from './ActionsCell';


export interface LoanPropertyValueCellProps {
    entry: LLPAMatrixEntry;
    setEntries: Dispatch<SetStateAction<LLPAMatrixEntry[]>>;
    variant: 'row' | 'column';
    rowSpan?: number;
    hideBottomBorder?: boolean;
    property: LoanProperty;
    rowProperty: LoanProperty | null;
    colProperties: LoanProperty[];
    numRows: number;
}

function formatNumericMatrixEntry(
    entry: NumericMatrixEntry,
    formatValue: (value: number | undefined) => string | null = (value) => value?.toString() || null
) {
    return entry.type === NumericMatrixEntryType.RANGE
        ? `${formatValue(entry.min)} - ${formatValue(entry.max)}`
        : `${numericFieldOperatorDisplay[entry.operator]} ${formatValue(entry.value)}`;
}

function formatPercent(value: number | undefined) {
    return !value ? '' : `${value.toFixed(2)}%`;
}

const loanPropertyEnumNumericFormatters = {
    [LoanProperty.LTV]: formatPercent,
    [LoanProperty.CLTV]: formatPercent,
    [LoanProperty.DTI]: formatPercent,
    [LoanProperty.LOAN_AMOUNT]: formatCurrency
} as const;

type FormattedLoanProperty = keyof typeof loanPropertyEnumNumericFormatters;

function isFormattedProperty(loanProperty: LoanProperty): loanProperty is FormattedLoanProperty {
    return Object.keys(loanPropertyEnumNumericFormatters).includes(loanProperty);
}

export function formatMatrixEntry(property: LoanProperty, entry: LLPAMatrixEntry) {
    const value = entry[property];
    const displayEnum = loanPropertyEnumDisplays[property];

    return !value ? '--' : (displayEnum !== null
        ? displayEnum[(value as any)[0] as keyof typeof displayEnum]
        : formatNumericMatrixEntry(
            value as NumericMatrixEntry,
            isFormattedProperty(property)
                ? loanPropertyEnumNumericFormatters[property]
                : undefined
        )
    );
}

export default function LoanPropertyValueCell({
    entry, setEntries, rowSpan, hideBottomBorder, variant, property, rowProperty, colProperties, numRows
}: LoanPropertyValueCellProps) {
    const cellRef = useRef<HTMLTableHeaderCellElement>(null);
    const [ moreActionsAnchorEl, setMoreActionsAnchorEl ] = useState<HTMLElement>();

    const value = entry[property];

    const updateValue = useCallback((newValue: typeof value) => {
        if (!rowProperty) {
            updateMatrixEntry(entry, setEntries, newValue, property);
        } else {
            // TODO post-demo fix editing a cell to match an adjacent cell's value
            setEntries(entries => entries.map(entryToTest => {
                const shouldUpdate = variant === 'row'
                    ? entryToTest[rowProperty] === entry[rowProperty]
                    : colProperties.slice(0, colProperties.indexOf(property) + 1).every(colProperty => (
                        deepEqual(entryToTest[colProperty], entry[colProperty])
                    ));

                return !shouldUpdate ? entryToTest : {
                    ...entryToTest,
                    [property]: newValue
                };
            }));
        }
    }, [
        colProperties, entry, property, rowProperty, setEntries, variant
    ]);

    function insertCell(direction: 'before' | 'after') {
        if (variant === 'row') {
            setEntries((entries) => {
                const startIndex = direction === 'before'
                    ? entries.indexOf(entry)
                    : entries.indexOf(entry) + numRows;

                entries.splice(startIndex, 0, ...entries.slice(0, numRows).map(entry => ({
                    ...entry,
                    [property]: undefined,
                    adjustment: ''
                })));

                return [ ...entries ];
            });
        } else {
            setEntries((entries) => {
                const insertionIndex = direction === 'before'
                    ? entries.indexOf(entry)
                    : entries.indexOf(entry) + (rowSpan || 1);

                const matrixEntries: LLPAMatrixEntry[][] = [];
                for (let i = 0; i < entries.length; i += numRows) {
                    matrixEntries.push(entries.slice(i, i + numRows));
                }

                const emptyProperties = colProperties.slice(colProperties.indexOf(property));
                const emptyOverrides = Object.fromEntries(emptyProperties.map((colProperty) => (
                    [ colProperty, undefined ]
                ))) as Partial<LLPAMatrixEntry>;

                for (const entriesForRowValue of matrixEntries) {
                    const baseEntry = entriesForRowValue[direction === 'before'
                        ? insertionIndex
                        : insertionIndex - 1
                    ];

                    entriesForRowValue.splice(insertionIndex, 0, {
                        ...baseEntry,
                        ...emptyOverrides,
                        adjustment: ''
                    });
                }

                return matrixEntries.flat();
            });
        }
    }

    const { handleEditClick, popoverProps, queueEdit } = useContext(LLPAMatrixContext);

    const isEditing = entry === popoverProps.entry && property === popoverProps.property;
    const argsRef = useRef([
        property, entry, updateValue, cellRef
    ] as const);

    useEffect(() => {
        if (!value && !isEditing) {
            queueEdit?.(...argsRef.current);
        }
    }, [
        value, queueEdit, isEditing
    ]);

    return (
        <ActionsCell
            component="th"
            ref={cellRef}
            rowSpan={rowSpan}
            showActions={Boolean(moreActionsAnchorEl || isEditing)}
            className={clsx(matrixStyles.loanPropertyValueCell, {
                [matrixStyles.hideBottomBorder]: hideBottomBorder
            })}
            actions={(
                <>
                    <Tooltip title="Edit cell">
                        <IconButton
                            size="small"
                            onClick={() => {
                                handleEditClick?.(property, entry, updateValue, cellRef);
                            }}
                        >
                            <Edit
                                color="secondary"
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={`Insert cell ${variant === 'column' ? 'below' : 'after'}`}>
                        <IconButton
                            size="small"
                            onClick={() => insertCell('after')}
                        >
                            <AddCircleOutline
                                color="success"
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="More actions">
                        <IconButton
                            size="small"
                            onClick={event => setMoreActionsAnchorEl(event.currentTarget)}
                        >
                            <MoreVert
                                color="action"
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        >
            {formatMatrixEntry(property, entry)}

            <Menu
                open={!!moreActionsAnchorEl}
                onClose={() => setMoreActionsAnchorEl(undefined)}
                anchorEl={moreActionsAnchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
            >
                <MenuItem>
                    Remove cell
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        insertCell('before');
                        setMoreActionsAnchorEl(undefined);
                    }}
                >
                    Insert cell {variant === 'column' ? 'above' : 'before'}
                </MenuItem>

                <MenuItem>
                    Move cell {variant === 'column' ? 'up' : 'left'}
                </MenuItem>

                <MenuItem>
                    Move cell {variant === 'column' ? 'down' : 'right'}
                </MenuItem>
            </Menu>
        </ActionsCell>
    );
}
