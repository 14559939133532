import api, { CustomerDetails, User, UserType } from '@api';
import { LabeledValue } from '@tsp-ui/core/components';
import { getFullName, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useCallback, useState } from 'react';

import EditableSectionCard from '../../../components/EditableSectionCard';

import styles from './CustomerManagersCard.module.scss';


interface CustomerManagersCardProps{
    customer: CustomerDetails;
    readOnly?: boolean;
}

export default function CustomerManagersCard({ customer, readOnly }: CustomerManagersCardProps) {
    const {
        counterpartyAnalystId, primaryAccountExecId, secondaryAccountExecId, accountManagerId
    } = customer;

    const pageMessage = usePageMessage();

    const [ clientUsers, setClientUsers ] = useState<User[]>([]);

    useAsyncEffect(useCallback(async () => {
        try {
            setClientUsers(await api.users.getUsers(UserType.CLIENT));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching client users', error);
        }
    }, [ pageMessage ]));

    return (
        <EditableSectionCard
            header="Managers"
            editTo="managers"
            readOnly={readOnly}
        >
            <div className={styles.root}>
                <LabeledValue
                    label="Counterparty Analyst"
                    value={getFullName(clientUsers.find(user => user.id === counterpartyAnalystId))}
                    variant="vertical"
                />

                <LabeledValue
                    label="Primary account executive"
                    value={getFullName(clientUsers.find(user => user.id === primaryAccountExecId))}
                    variant="vertical"
                />

                <LabeledValue
                    label="Secondary account executive"
                    value={getFullName(clientUsers.find(user => user.id === secondaryAccountExecId))}
                    variant="vertical"
                />

                <LabeledValue
                    label="Account Manager"
                    value={getFullName(clientUsers.find(user => user.id === accountManagerId))}
                    variant="vertical"
                />
            </div>
        </EditableSectionCard>
    );
}
