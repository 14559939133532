export enum Operator {
    EQUALS = 'EQUALS',
    DOES_NOT_EQUAL = 'DOES_NOT_EQUAL',
    IS_GREATER_THAN = 'IS_GREATER_THAN',
    IS_LESS_THAN = 'IS_LESS_THAN',
    IS_GREATER_THAN_OR_EQUAL_TO = 'IS_GREATER_THAN_OR_EQUAL_TO',
    IS_LESS_THAN_OR_EQUAL_TO = 'IS_LESS_THAN_OR_EQUAL_TO',
    BEGINS_WITH = 'BEGINS_WITH',
    ENDS_WITH = 'ENDS_WITH',
    CONTAINS = 'CONTAINS',
    IS_NOT_LIKE = 'IS_NOT_LIKE',
}

export const operatorDisplay = {
    [Operator.EQUALS]: 'equals',
    [Operator.DOES_NOT_EQUAL]: 'does not equal',
    [Operator.IS_GREATER_THAN]: 'is greater than',
    [Operator.IS_LESS_THAN]: 'is less than',
    [Operator.IS_GREATER_THAN_OR_EQUAL_TO]: 'is greater than or equal to',
    [Operator.IS_LESS_THAN_OR_EQUAL_TO]: 'is less than or equal to',
    [Operator.BEGINS_WITH]: 'begins with',
    [Operator.ENDS_WITH]: 'ends with',
    [Operator.CONTAINS]: 'contains',
    [Operator.IS_NOT_LIKE]: 'is not like'
};
