import api, { User } from '@api';
import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import {
    IconButton, Link as MuiLink, Paper, Tooltip, Typography
} from '@mui/material';
import { LabelGroup, LabeledValue, PhoneTypography } from '@tsp-ui/core/components';
import { getFullName, useConfirm, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import styles from './UserCard.module.scss';
import { RolesContext, UserManagementPageContext } from './UserManagementPage';


interface UserCardProps {
    user: User;
}

export function UserCard({ user }: UserCardProps) {
    const { email, id } = user;

    const { accountUserType, customerId } = useGetCurrentAccount();

    const confirm = useConfirm();
    const pageMessage = usePageMessage();

    const { entities: users, setEntities: setUsers } = useContext(UserManagementPageContext);
    const { entities: roles } = useContext(RolesContext);

    return (
        <Paper
            className={styles.card}
            elevation={0}
        >
            <div>
                <Typography>
                    {getFullName(user)}
                </Typography>

                <MuiLink
                    variant="body2"
                    href={`mailto:${email}`}
                >
                    {email}
                </MuiLink>

                <PhoneTypography variant="body2">
                    {user.phone || ''}
                </PhoneTypography>

                <LabelGroup className={styles.roles}>
                    <LabeledValue
                        label="Roles:"
                        value={user.roleIds.map(roleID => (
                            roles.find(role => role.id === roleID)?.name
                        )).join(', ')}
                    />
                </LabelGroup>
            </div>

            <div className={styles.buttons}>
                <Tooltip title="Edit user">
                    <IconButton
                        component={Link}
                        to={`${id}/edit`}
                    >
                        <Edit color="secondary" />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Delete user">
                    <IconButton
                        edge="end"
                        onClick={async () => {
                            if (await confirm('Are you sure you want to delete this user?')) {
                                try {
                                    await api.users.deleteUser(accountUserType, id, customerId?.toString());

                                    setUsers(users.filter(user => user.id !== id));

                                    pageMessage.success('User deleted');
                                } catch (error) {
                                    pageMessage.handleApiError('An error occurred while deleting the user', error);
                                }
                            }
                        }}
                    >
                        <RemoveCircleOutline color="error" />
                    </IconButton>
                </Tooltip>
            </div>
        </Paper>
    );
}
