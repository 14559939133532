import { ProductTypeConfig, productTypeDisplay } from '@api';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useState } from 'react';

import { LimitDisplay } from './LimitDisplay';
import { ProductRow } from './ProductRow';
import styles from './ProductRow.module.scss';


interface ProductTypeRowProps {
    productTypeConfig: ProductTypeConfig;
}

export function ProductTypeRow({ productTypeConfig }: ProductTypeRowProps) {
    const { limit, productType, productConfigs } = productTypeConfig;

    const [ expanded, setExpanded ] = useState(false);

    return (
        <>
            <div className={styles.productRow}>
                <IconButton
                    size="small"
                    onClick={() => setExpanded(!expanded)}
                    disabled={!productConfigs.length}
                >
                    {expanded ? (
                        <ExpandLess color="secondary" />
                    ) : (
                        <ExpandMore color={productConfigs.length ? 'secondary' : 'disabled'} />
                    )}
                </IconButton>

                <LimitDisplay
                    limit={limit}
                    name={productTypeDisplay[productType]}
                />
            </div>

            {expanded && productConfigs.map(({ limit, name }) => (
                <ProductRow
                    key={name}
                    className={styles.compactRow}
                    isCompactRow
                    limit={limit}
                    name={name}
                />
            ))}
        </>
    );
}
