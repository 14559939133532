import { rest } from 'msw';

import { CustomerCompensationPercentageOf, CustomerCompensationType, FeeType } from '../..';
import { getMockUrl } from '../../../mocks/getMockUrl';

import { CustomerCompensation, CustomerFeesComp } from './compensation-api';


let feeID = 0;
export const mocks = [
    rest.get(getMockUrl('/customer/:customerID/compensation'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.url.pathname.split('/')[2] === '1' ? customerFeesComp : {})
    ))),
    rest.put(getMockUrl('/customer/:customerID/compensation'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    )))
];

const compensationDetails: CustomerCompensation = {
    id: '1',
    isActive: true,
    firstLienOnly: true,
    type: CustomerCompensationType.PERCENTAGE,
    percentage: 25,
    percentageOf: CustomerCompensationPercentageOf.BASE_LOAN_AMOUNT,
    minAmount: 12000,
    maxAmount: 64000,
    notes: 'Lorem ipsum\n• Dolor sit amet, consectetur adipiscing elit\n• Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua\n• Ut enim ad minim veniam, quis nostrud exercitation ullamco'
};

export const customerFeesComp: CustomerFeesComp = {
    customerId: 5234,
    compensation: compensationDetails,
    fees: [
        {
            id: `${feeID++}`,
            feeType: FeeType['203K_SUPPLEMENTAL_FEE'],
            defaultAmount: 3400,
            adjustedAmount: 3400,
            isActive: true
        }, {
            id: `${feeID++}`,
            feeType: FeeType.ADMINISTRATION_FEE,
            defaultAmount: 8500,
            adjustedAmount: undefined,
            isActive: false
        }, {
            id: `${feeID++}`,
            feeType: FeeType.CDFI_CREDIT,
            defaultAmount: 7800,
            adjustedAmount: undefined,
            isActive: true,
            isCustom: true
        }, {
            id: `${feeID++}`,
            feeType: FeeType.COMPLIANCE_FEE,
            defaultAmount: 345,
            adjustedAmount: 95,
            isActive: true
        }
    ]
};
