export enum SpecialtyProduct {
    HOME_POSSIBLE = 'HOME_POSSIBLE',
    HOME_READY = 'HOME_READY',
    REFI_NOW = 'REFI_NOW',
    REFI_POSSIBLE = 'REFI_POSSIBLE'
}

export const specialtyProductDisplay = {
    [SpecialtyProduct.HOME_POSSIBLE]: 'Home possible',
    [SpecialtyProduct.HOME_READY]: 'Home ready',
    [SpecialtyProduct.REFI_NOW]: 'Refi now',
    [SpecialtyProduct.REFI_POSSIBLE]: 'Refi possible'
};
