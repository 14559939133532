import { UserType } from '@api';
import { useParams } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';

/**
 * Returns the clientID for the current user. If the user is an internal Prem admin on a client's config page,
 * the clientID will be pulled from the route params. Otherwise, the current account ID is returned.
 */
export function useActingClientID() {
    const params = useParams();
    const { accountUserType, id } = useGetCurrentAccount();

    const clientID = accountUserType === UserType.CLIENT ? id.toString() : params.clientID;

    if (!clientID) {
        throw new Error('Expected a clientID param on the current route and did not find one');
    }

    return clientID;
}
