import {
    CommitmentType, EligibleProductsConfig, ProductConfig,
    ProductType, ProductTypeConfig, ServicingType, UnderwritingType
} from '@api';
import { rest } from 'msw';

import { getMockUrl } from '../../../mocks/getMockUrl';


export const mocks = [
    rest.put(getMockUrl('/customer/:customerID/product'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    )))
];

let limitID = 0;
let productConfigID = 0;

const productConfigs: ProductConfig[] = [
    {
        id: `${productConfigID++}`,
        name: '10 year conventional',
        limit: {
            id: `${limitID++}`,
            currentAmount: 200000,
            limitAmount: 250000,
            expirationDate: '2022-10-31'
        }
    },
    {
        id: `${productConfigID++}`,
        name: '20 year conventional',
        limit: {
            id: `${limitID++}`,
            currentAmount: 200000,
            limitAmount: 250000,
            expirationDate: '2022-10-31'
        }
    },
    {
        id: `${productConfigID++}`,
        name: '10 year jumbo',
        limit: {
            id: `${limitID++}`,
            currentAmount: 100000,
            limitAmount: 250000,
            expirationDate: '2022-10-31'
        }
    },
    {
        id: `${productConfigID++}`,
        name: '10 year FHA',
        limit: null
    },
    {
        id: `${productConfigID++}`,
        name: '10 year USDA',
        limit: {
            id: `${limitID++}`,
            currentAmount: 100000,
            limitAmount: 250000,
            expirationDate: '2022-10-31'
        }
    },
    {
        id: `${productConfigID++}`,
        name: '10 year VA',
        limit: null
    }
];

let productTypeConfig = 0;

const productTypeConfigs: ProductTypeConfig[] = [
    {
        id: `${productTypeConfig++}`,
        productType: ProductType.CONVENTIONAL,
        underwritingType: UnderwritingType.DELEGATED,
        servicingType: ServicingType.RELEASED,
        commitmentTypes: [ CommitmentType.BEST_EFFORT ],
        lockPeriods: [
            15, 30, 60
        ],
        limit: {
            id: `${limitID++}`,
            currentAmount: 500000,
            limitAmount: 2000000,
            expirationDate: '2022-10-31'
        },
        productConfigs: productConfigs.slice(0, 2)
    },
    {
        id: `${productTypeConfig++}`,
        productType: ProductType.VA,
        underwritingType: UnderwritingType.DELEGATED,
        servicingType: ServicingType.RETAINED,
        commitmentTypes: [ CommitmentType.MANDATORY ],
        lockPeriods: [ 60 ],
        limit: {
            id: `${limitID++}`,
            currentAmount: 500000,
            limitAmount: 1000000,
            expirationDate: '2022-10-31'
        },
        productConfigs: productConfigs.slice(-1)
    },
    {
        id: `${productTypeConfig++}`,
        productType: ProductType.NON_AGENCY,
        underwritingType: UnderwritingType.DELEGATED,
        servicingType: ServicingType.RETAINED,
        commitmentTypes: [ CommitmentType.MANDATORY ],
        lockPeriods: [ 60 ],
        limit: {
            id: `${limitID++}`,
            currentAmount: 500000,
            limitAmount: 1000000,
            expirationDate: '2022-10-31'
        },
        productConfigs: productConfigs.slice(2, 3)
    },
    {
        id: `${productTypeConfig++}`,
        productType: ProductType.FHA,
        underwritingType: UnderwritingType.NON_DELEGATED,
        servicingType: ServicingType.RETAINED,
        commitmentTypes: [ CommitmentType.MANDATORY ],
        lockPeriods: [
            15, 30, 60
        ],
        limit: {
            id: `${limitID++}`,
            currentAmount: 500000,
            limitAmount: 1000000,
            expirationDate: '2022-10-31'
        },
        productConfigs: productConfigs.slice(3, 4)
    },
    {
        id: `${productTypeConfig++}`,
        productType: ProductType.USDA,
        underwritingType: UnderwritingType.NON_DELEGATED,
        servicingType: ServicingType.RETAINED,
        commitmentTypes: [ CommitmentType.MANDATORY ],
        lockPeriods: [ 60 ],
        limit: {
            id: `${limitID++}`,
            currentAmount: 500000,
            limitAmount: 1000000,
            expirationDate: '2022-10-31'
        },
        productConfigs: productConfigs.slice(4, 5)
    }
];

export const productConfiguration: EligibleProductsConfig = {
    id: '1',
    allProductsLimit: {
        id: `${limitID++}`,
        currentAmount: 5000000,
        limitAmount: 10000000,
        expirationDate: '2022-7-9'
    },
    delegatedLimit: {
        id: `${limitID++}`,
        currentAmount: 2500000,
        limitAmount: 5000000,
        expirationDate: '2022-10-31'
    },
    nonDelegatedLimit: {
        id: `${limitID++}`,
        currentAmount: 2000000,
        limitAmount: 5000000,
        expirationDate: '2022-10-31'
    },
    productTypeConfigs
};
