import {
    EligibleProductsToggleDetails, isUnderwritingType, productTypeDisplay, underwritingTypeDisplay
} from '@api';
import { Paper } from '@mui/material';
import { LabelGroup, LabeledValue } from '@tsp-ui/core/components';
import { Fragment } from 'react';

import styles from './EligibleProductsLimitCard.module.scss';


type EligibleProductsEnabledDisabledCardProps = {
    details: EligibleProductsToggleDetails;
}

export default function EligibleProductsEnabledDisabledCard({ details }: EligibleProductsEnabledDisabledCardProps) {
    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            <LabelGroup
                title={
                    isUnderwritingType(details.type)
                        ? underwritingTypeDisplay[details.type]
                        : productTypeDisplay[details.type]
                }
            >
                <LabeledValue
                    label="Affected products:"
                    value={details.products.map(product => (
                        <Fragment key={product}>
                            {product}<br />
                        </Fragment>
                    ))}
                />
            </LabelGroup>
        </Paper>
    );
}
