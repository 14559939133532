import api, {
    Loan, LoanActivity, LoanAlert, PipelineGroup
} from '@api';
import { Typography } from '@mui/material';
import { ExpandableHeader } from '@tsp-ui/core/components';
import { replaceItemById, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import LoanDocumentsDialog from '@views/loans/components/LoanDocumentsDialog';
import LoanPipelineResultCard from '@views/loans/components/LoanPipelineResultCard';
import { ReactNode, useCallback, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Page from '../components/Page';

import styles from './LoansPage.module.scss';
import { CustomAlerts } from './components/CustomAlerts';
import LoanDataDialog from './components/LoanDataDialog';
import { RecentLoans } from './components/RecentLoans';


/**
 * Renders the loan pipeline page
 *
 * @constructor
 */
export default function LoansPage() {
    const [ loans, setLoans ] = useState<Loan[]>();
    const [ loanAlerts, setLoanAlerts ] = useState<LoanAlert[]>([]);
    const [ pipelineGroups, setPipelineGroups ] = useState<PipelineGroup[]>([]);
    const [ recentLoans, setRecentLoans ] = useState<LoanActivity[]>([]);
    const [ loading, setLoading ] = useState(true);

    // TODO post-demo use a routed dialog to get rid of this
    const [ loanDetailID, setLoanDetailID ] = useState<string | null>(null); // set to null to close dialog
    const openLoanDetails = async (loanID?: string) => {
        setLoanDetailID(loanID || null);

        if (loanID) {
            setRecentLoans(await api.loans.demoLoanViewed(loanID));
        }
    };

    const [ loanDocsID, setLoanDocsID ] = useState<string | null>(null);
    const openLoanDocs = (loanID?: string) => setLoanDocsID(loanID || null);

    const openLoanForDocs = loans?.find(({ id }) => id === loanDocsID);

    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        try {
            // TODO post-demo don't fail to load the whole page if alerts/recent fail
            const [
                loans, loanAlerts, pipelineGroups, recentLoans
            ] = await Promise.all([
                api.loans.getLoans(),
                api.loans.getLoanAlerts(),
                api.loans.getPipelineGroups(),
                api.loans.getRecentLoans()
            ]);

            setLoans(loans);
            setLoanAlerts(loanAlerts);
            setPipelineGroups(pipelineGroups);
            setRecentLoans(recentLoans);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loans', error);
        }

        setLoading(false);
    }, [ pageMessage ]));

    function updateLoan(loanToUpdate: Loan) {
        setLoans((loans) => loans && replaceItemById(loans, loanToUpdate));
    }

    return (
        <Page
            header="Loan Pipeline"
            loading={loading}
            className={styles.main}
        >
            <div className={styles.root}>
                <div className={styles.sections}>
                    {pipelineGroups.map(pipelineGroup => (
                        <LoansSection
                            key={pipelineGroup.id}
                            title={pipelineGroup.title}
                            loans={loans?.filter(loan => loan.pipelineGroupID === pipelineGroup.id) || []}
                            onOpenLoanDetails={openLoanDetails}
                            onOpenLoanDocs={openLoanDocs}
                            onUpdateLoan={updateLoan}
                        />
                    ))}
                </div>

                <div className={styles.sidebar}>
                    <CustomAlerts loanAlerts={loanAlerts} />

                    <RecentLoans recentLoans={recentLoans} />
                </div>
            </div>

            {loanDetailID && (
                <LoanDataDialog
                    open
                    onClose={() => setLoanDetailID(null)}
                    loanID={loanDetailID}
                />
            )}

            {openLoanForDocs && (
                <LoanDocumentsDialog
                    open
                    onClose={() => setLoanDocsID(null)}
                    loanID={openLoanForDocs.id}
                    loanNumber={openLoanForDocs.loanNumber}
                    loanStatus={openLoanForDocs.loanStatus}
                />
            )}
        </Page>
    );
}

interface LoansSectionProps {
    title: ReactNode;
    loans: Loan[];
    onOpenLoanDetails: (loanID?: string) => void;
    onOpenLoanDocs: (loanID?: string) => void;
    onUpdateLoan: (loan: Loan) => void;
}

function LoansSection({
    title, loans, onOpenLoanDetails, onOpenLoanDocs, onUpdateLoan
}: LoansSectionProps) {
    return (
        <div>
            <ExpandableHeader
                disableExpand={!loans.length}
                defaultExpand={false}
                title={title}
                secondaryText={`${loans.length} loan${loans.length !== 1 ? 's' : ''}`}
                expandedContent={(
                    <TransitionGroup className={styles.loans}>
                        {loans.map((loan, index) => (
                            <CSSTransition
                                key={loan.id /* TODO see below */}
                                exit={false}
                                timeout={250 + (index * 50)}
                                classNames={{
                                    enter: styles.pricingResultCardTransition_enter,
                                    enterActive: styles.pricingResultCardTransition_enter_active
                                }}
                            >
                                <LoanPipelineResultCard
                                    loan={loan}
                                    updateLoan={onUpdateLoan}
                                    onOpenLoanDetails={() => onOpenLoanDetails(loan.id)}
                                    onOpenLoanDocs={() => onOpenLoanDocs(loan.id)}
                                />
                            </CSSTransition>
                        )) || (
                            <Typography>
                                There are no loans to display at this time.
                            </Typography>
                        )}
                    </TransitionGroup>
                )}
            />
        </div>
    );
}
