import {
    EligibilityExclusion,
    EligibilityExclusionGroup,
    NumericExclusionValue,
    exclusionEqualityTypeDisplay,
    loanPropertyDisplay,
    numericFieldOperatorDisplayLong
} from '@api';
import { AutoFixNormal, Layers } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { Fragment, ReactNode } from 'react';

import { getFormattedValue } from '../LoanProgramDetailPage/components/HighLevelGuidelineCard';

import styles from './EligibilityExclusionRow.module.scss';


interface EligibilityExclusionRowProps {
    exclusionGroup: EligibilityExclusionGroup;
    isManaged?: boolean;
    hideIcon?: boolean;
}

export function EligibilityExclusionRow({ exclusionGroup, isManaged, hideIcon }: EligibilityExclusionRowProps) {
    return (
        <div className={styles.root}>
            {hideIcon ? (
                <div />
            ) : isManaged ? (
                <Tooltip title="This exclusion is managed by Premicorr">
                    <AutoFixNormal
                        color="primary"
                        fontSize="small"
                        className={styles.info}
                    />
                </Tooltip>
            ) : (
                <Tooltip title="This exclusion is an overlay">
                    <Layers
                        color="primary"
                        fontSize="small"
                        className={styles.info}
                    />
                </Tooltip>
            )}

            {exclusionGroup.exclusions.map((exclusion, index) => (
                <Fragment key={exclusion.id}>
                    {index > 0 && (
                        <Typography>
                            <u>AND</u>
                        </Typography>
                    )}

                    <ExclusionContent exclusion={exclusion} />
                </Fragment>
            ))}
        </div>
    );
}

interface ExclusionContentProps {
    exclusion: EligibilityExclusion;
}

function ExclusionContent({ exclusion: { loanProperty, equalityType, value } }: ExclusionContentProps) {
    if (!loanProperty) {
        return null;
    }

    let formattedValue: ReactNode;
    if (!Array.isArray(value)) {
        formattedValue = isNumericExclusionValue(value)
            ? formatNumericExclusion(value)
            : getFormattedValue(loanProperty, value);
    } else {
        formattedValue = value.map((val, index) => (
            <Fragment key={val}>
                {getFormattedValue(loanProperty, val)}

                {index < value.length - 1 && (
                    <Typography
                        component="span"
                        variant="inherit"
                        fontWeight={400}
                    >
                        {' or '}
                    </Typography>
                )}
            </Fragment>
        ));
    }

    const equalityTypeDisplay = equalityType
        ? exclusionEqualityTypeDisplay[equalityType].toLowerCase()
        : isNumericExclusionValue(value)
            ? `is ${numericFieldOperatorDisplayLong[value.operator]}`
            : '';

    return (
        <Typography>
            <b>{loanPropertyDisplay[loanProperty]}</b>{' '}

            {equalityTypeDisplay}{' '}

            <b>{formattedValue}</b>{' '}
        </Typography>
    );
}

function isNumericExclusionValue(value: EligibilityExclusion['value']): value is NumericExclusionValue {
    return value.hasOwnProperty('operator');
}

function formatNumericExclusion(value: NumericExclusionValue) {
    return `${value.min || value.max}${value.min && value.max ? ` and ${value.max}` : ''}`;
}
