import { rest } from 'msw';

import {
    FeeType, PurchaseAdviceConfig, PurchaseAdviceFee, RoundingMethod
} from '..';
import { getMockUrl } from '../../mocks/getMockUrl';


let purchaseAdviceConfigID = 2;
let purchaseAdviceFeeID = 4;

export const mocks = [
    rest.get(getMockUrl('/client/purchaseAdvice'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(purchaseAdviceConfig)
    ))),
    rest.post(getMockUrl('/client/purchaseAdvice'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as PurchaseAdviceConfig,
            id: purchaseAdviceConfigID++
        })
    ))),
    rest.put(getMockUrl('/client/purchaseAdvice'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.get(getMockUrl('/client/purchaseAdviceFee'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(purchaseAdviceFees)
    ))),
    rest.post(getMockUrl('/client/purchaseAdviceFee'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as PurchaseAdviceFee,
            id: purchaseAdviceFeeID++
        })
    ))),
    rest.put(getMockUrl('/client/purchaseAdviceFee/:feeID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.delete(getMockUrl('/client/purchaseAdviceFee/:feeID'), (req, res, ctx) => (res(
        ctx.status(200)
    )))
];

const purchaseAdviceConfig: PurchaseAdviceConfig = {
    id: 1,
    interestRatePrecision: 3,
    roundingMethod: RoundingMethod.ROUND_DOWN,
    cutoffDay: 20
};

const purchaseAdviceFees: PurchaseAdviceFee[] = [
    {
        id: 1,
        feeType: FeeType.ADMINISTRATION_FEE,
        feeAmount: 50
    },
    {
        id: 2,
        feeType: FeeType.CODE_COMPLIANCE_FEE,
        feeAmount: 250
    },
    {
        id: 3,
        feeType: FeeType.DOCUMENT_FEE,
        feeAmount: 123.45
    }
];
