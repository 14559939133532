export enum ClientStatus {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    INACTIVE = 'INACTIVE'
}

export const clientStatusDisplay = {
    [ClientStatus.ACTIVE]: 'Active',
    [ClientStatus.PENDING]: 'Pending',
    [ClientStatus.INACTIVE]: 'Inactive'
};
