import { InvestorEligibilityContext } from '@views/admin/investor-eligibility/InvestorEligibilityRoutes';
import Page from '@views/components/Page';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './CreateLoanProgramPage.module.scss';
import { LoanProgramForm } from './LoanProgramDetailPage/components/LoanProgramForm';


export default function CreateLoanProgramPage() {
    const [ activeStep, setActiveStep ] = useState(0);

    const { pathname } = useLocation();
    const isClient = pathname.includes('investor-eligibility');
    const { investors } = useContext(InvestorEligibilityContext);

    return (
        <Page
            header="Add Loan Program"
            variant="centered"
            className={clsx(styles.main, {
                [styles.unsetMaxWidth]: activeStep === 1
            })}
            loading={isClient && !investors}
        >
            <LoanProgramForm
                activeStep={activeStep}
                setActiveStep={setActiveStep}
            />
        </Page>
    );
}
