import { RemoveCircleOutline } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import {
    EligibilityExclusionsFormValues
} from '@views/admin/investors/InvestorDetailPage/components/ExclusionGroupRow';
import React from 'react';

import styles from './DeleteExclusionRowButtons.module.scss';
import { getYCoordinates } from './ExclusionGroupGraphic';


interface DeleteExclusionGroupButtonsProps {
    group: EligibilityExclusionsFormValues;
    onTooltipOpen: (index: number) => void;
    onTooltipClose: () => void;
    onClick: (index: number) => void;
}

/**
 * Renders the delete buttons for all the rows in the given group
 *
 * @param group          - The group to render delete buttons for
 * @param onTooltipClose - A function to execute when the tooltip on the button is closed
 * @param onTooltipOpen  - A function to execute when the tooltip on the button is opened
 * @param onClick        - A function to execute when the delete button is clicked
 * @constructor
 */
export default function DeleteExclusionRowButtons({
    group, onTooltipClose, onTooltipOpen, onClick
}: DeleteExclusionGroupButtonsProps) {
    const yCoordinates = getYCoordinates(group);

    // The keys for the buttons should correspond to the ids of the rule/nested group that they delete
    const keys = group.exclusions?.map(({ id }) => id).concat(
        group.groups?.map((group => (
            (group.exclusions || []).flatMap(({ id }) => id).join())
        )) || []
    );

    return (
        <>
            {yCoordinates.map((yCoordinate, index) => (
                <Tooltip
                    key={keys?.[index]}
                    enterDelay={0}
                    title={index < (group.exclusions?.length || 0)
                        ? 'Remove rule'
                        : 'Remove group'}
                    onOpen={() => onTooltipOpen(index)}
                    onClose={onTooltipClose}
                >
                    <IconButton
                        className={styles.button}
                        size="small"
                        style={{
                            top: yCoordinate - 3
                        }}
                        onClick={() => onClick(index)}
                    >
                        <RemoveCircleOutline
                            color="error"
                            fontSize="small"
                        />
                    </IconButton>
                </Tooltip>
            ))}
        </>
    );
}
