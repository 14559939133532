export enum Agency {
    FNMA = 'FNMA',
    FHLMC = 'FHLMC',
    FHA = 'FHA',
    VA = 'VA',
    USDA = 'USDA'
}

export const agencyDisplay = {
    [Agency.FNMA]: 'FNMA',
    [Agency.FHLMC]: 'FHLMC',
    [Agency.FHA]: 'FHA',
    [Agency.VA]: 'VA',
    [Agency.USDA]: 'USDA'
};
