import api, { ReferenceGuideFile } from '@api';
import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import {
    IconButton, Link as MuiLink, Paper, Tooltip, Typography
} from '@mui/material';
import { DateTypography, ProgressIndicator, useConfirm } from '@tsp-ui/core/components';
import { removeItemById, replaceItemById, usePageMessage } from '@tsp-ui/core/utils';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReferenceGuideManagementPageContext } from '../ClientReferenceGuideManagementPage';

import styles from './ClientReferenceGuideCard.module.scss';


interface ClientReferenceGuideCardProps {
    referenceGuideFile: ReferenceGuideFile;
}

export function ClientReferenceGuideCard({ referenceGuideFile }: ClientReferenceGuideCardProps) {
    const {
        title, url, id, sectionId, description, createdAt
    } = referenceGuideFile;

    const [ isDeleting, setIsDeleting ] = useState(false);
    const { setEntities: setReferenceGuideSections } = useContext(ReferenceGuideManagementPageContext);

    const confirm = useConfirm();
    const pageMessage = usePageMessage();

    return (
        <Paper
            elevation={0}
            className={styles.card}
        >
            <div>
                <MuiLink
                    href={url}
                    rel="noreferrer noopener"
                    target="_blank"
                >
                    {title}
                </MuiLink>

                <br />

                <Typography variant="caption">
                    {description}
                </Typography>
            </div>

            <div>
                <DateTypography
                    variant="caption"
                    date={createdAt}
                />

                <div className={styles.buttons}>
                    <Tooltip title="Edit file">
                        <IconButton
                            component={Link}
                            to={`${sectionId}/${id}/edit`}
                        >
                            <Edit color="secondary" />
                        </IconButton>
                    </Tooltip>

                    {isDeleting ? <ProgressIndicator className={styles.progress} /> : (
                        <Tooltip title="Remove file">
                            <IconButton
                                onClick={async () => {
                                    try {
                                        if (await confirm('Are you sure you want to delete this reference guide?')) {
                                            setIsDeleting(true);

                                            await api.referenceGuides.deleteReferenceGuide(sectionId, id);

                                            setReferenceGuideSections(referenceGuideSections => {
                                                const referenceGuideSection = referenceGuideSections.find(
                                                    ({ id }) => id === sectionId
                                                )!;

                                                return replaceItemById(referenceGuideSections, {
                                                    ...referenceGuideSection,
                                                    files: removeItemById(
                                                        referenceGuideSection.files, id
                                                    )
                                                });
                                            });

                                            pageMessage.success('Reference guide deleted');
                                        }
                                    } catch (error) {
                                        pageMessage.handleApiError(
                                            'An error occurred while deleting the reference guide item',
                                            error
                                        );
                                    } finally {
                                        setIsDeleting(false);
                                    }
                                }}
                            >
                                <RemoveCircleOutline color="error" />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </div>
        </Paper>
    );
}
