import { useState } from 'react';

import ConditionsSummary from './components/ConditionsSummary';
import UnderwritingPageTemplate from './components/UnderwritingPageTemplate';
import UnderwritingStepCard from './components/UnderwritingStepCard';
import FloodInsuranceSection from './components/flood-hazard/FloodInsuranceSection';
import HazardInsuranceSection from './components/flood-hazard/HazardInsuranceSection';

// TODO use react-router for this
export type FloodAndHazardUnderwritingTabs = 'summary' | 'flood' | 'hazard';

export default function FloodAndHazardUnderwritingPage() {
    const [ tab, setTab ] = useState<FloodAndHazardUnderwritingTabs>('summary');
    const [ finishedTabs, setFinishedTabs ] = useState<FloodAndHazardUnderwritingTabs[]>([]);

    function tabComplete(tab: FloodAndHazardUnderwritingTabs, nextTab: FloodAndHazardUnderwritingTabs) {
        setFinishedTabs(finishedTabs.concat(tab));
        setTab(nextTab);
    }

    return (
        <UnderwritingPageTemplate
            conditionsShown={tab === 'summary'}
            onConditionsClick={() => setTab('summary')}
            relevantDocs={[ 'Flood cert.pdf', 'Hazard insurance.pdf' ]}
            stepCards={(
                <>
                    <UnderwritingStepCard
                        active={tab === 'flood'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('flood')}
                        label="Flood insurance"
                        onClick={() => setTab('flood')}
                    />

                    <UnderwritingStepCard
                        active={tab === 'hazard'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('hazard')}
                        label="Hazard Insurance"
                        onClick={() => setTab('hazard')}
                    />
                </>
            )}
        >
            {tab === 'summary' ? (
                <ConditionsSummary header="Flood & Hazard conditions" />
            ) : tab === 'flood' ? (
                <FloodInsuranceSection
                    onTabComplete={tabComplete}
                    setTab={setTab}
                />
            ) : tab === 'hazard' ? (
                <HazardInsuranceSection
                    onTabComplete={tabComplete}
                    setTab={setTab}
                />
            ) : null}
        </UnderwritingPageTemplate>
    );
}
