import { Auth } from 'aws-amplify';

import { AppConfig } from './configureApp';

/**
 * Configures AWS amplify for authentication
 */
export function configureAmplify(config: AppConfig) {
    Auth.configure({
        ...config.awsAuth,
        mandatorySignIn: true
    });
}
