import { LLPAMatrixEntry, LoanProperty } from '@api';
import AdjustmentCell from '@views/admin/llpas/components/LLPAMatrix/components/AdjustmentCell';
import deepEqual from 'fast-deep-equal';
import { Dispatch, SetStateAction } from 'react';

import { getKeyForEntry } from '../LLPAMatrix';

import LoanPropertyValueCell from './LoanPropertyValueCell';


interface MatrixTableRowProps {
    rowIndex: number;
    entries: LLPAMatrixEntry[];
    setEntries: Dispatch<SetStateAction<LLPAMatrixEntry[]>>;
    rowProperty: LoanProperty | null;
    colProperties: LoanProperty[];
    numRows: number;
}

export default function MatrixTableRow({
    rowIndex,
    setEntries,
    entries,
    rowProperty,
    colProperties,
    numRows
}: MatrixTableRowProps) {
    const firstEntry = entries[rowIndex];

    const adjustmentEntries = entries.filter((entry) => (
        colProperties.every(property => deepEqual(entry[property], entries[rowIndex][property]))
    ));

    const remainingColEntries = entries.slice(rowIndex, numRows);

    function getRowSpanForColumn(property: LoanProperty) {
        let colSpan = 1;

        for (let i = 0; i < remainingColEntries.length; i++) {
            const nextEntry = remainingColEntries[i + 1];
            // TODO post-demo fix '--' next to each other

            if (!deepEqual(nextEntry?.[property], firstEntry[property])) {
                break;
            }

            if (deepEqual(remainingColEntries[i][property], firstEntry[property])) {
                colSpan++;
            }
        }

        return colSpan;
    }

    return (
        <tr>
            {colProperties.filter((property) => (
                !deepEqual(entries[rowIndex - 1]?.[property], firstEntry[property])
            )).map((property) => (
                <LoanPropertyValueCell
                    key={`${getKeyForEntry(firstEntry)}_${property}`}
                    property={property}
                    rowProperty={rowProperty}
                    colProperties={colProperties}
                    variant="column"
                    entry={firstEntry}
                    setEntries={setEntries}
                    rowSpan={getRowSpanForColumn(property)}
                    hideBottomBorder={remainingColEntries.every((llpaEntry) => (
                        deepEqual(llpaEntry[property], firstEntry[property])
                    ))}
                    numRows={numRows}
                />
            ))}

            {adjustmentEntries.map((entry) => (
                <AdjustmentCell
                    key={`${getKeyForEntry(entry)}_adjustment`}
                    entry={entry}
                    setEntries={setEntries}
                />
            ))}
        </tr>
    );
}
