import { Button, Paper } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';

import styles from './ManualLoanEntryStep.module.scss';


export interface ManualLoanEntryStepProps {
    children: ReactNode;
    className?: string;
    onPreviousButtonClick?: () => void;
    secondaryButton?: ReactNode;
    submitButton?: ReactNode;
}

export default function ManualLoanEntryStep({
    children, className, onPreviousButtonClick, secondaryButton, submitButton = (
        <Button
            variant="contained"
            type="submit"
        >
            Next
        </Button>
    )
}: ManualLoanEntryStepProps) {
    return (
        <Paper className={clsx(styles.root, className)}>
            {children}

            <div className={styles.buttonContainer}>
                {onPreviousButtonClick && (
                    <Button
                        color="inherit"
                        onClick={onPreviousButtonClick}
                    >
                        Previous
                    </Button>
                )}

                {secondaryButton}

                {submitButton}
            </div>
        </Paper>
    );
}
