import api, {
    Role, User, UserType, userTypeDisplay
} from '@api';
import { FilterAlt } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { ButtonBar, ButtonBarProps } from '@tsp-ui/core/components';
import { getFullName, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import {
    createContext, useCallback, useMemo, useState
} from 'react';

import AdminPageTemplate, {
    AdminEntityGroup, AdminEntityGroupProps, AdminPageContextValues, defaultAdminPageContextValues
} from '../components/AdminPageTemplate';

import { UserCard } from './UserCard';
import { UserForm } from './UserForm';


export const UserManagementPageContext = createContext<AdminPageContextValues<User>>(defaultAdminPageContextValues);
export const RolesContext = createContext<AdminPageContextValues<Role>>(defaultAdminPageContextValues);

export default function UserManagementPage() {
    const { accountUserType, customerId } = useGetCurrentAccount();
    const pageMessage = usePageMessage();
    const fetchUsers = useCallback(() => (
        api.users.getUsers(accountUserType, customerId?.toString())
    ), [ accountUserType, customerId ]);

    const [ roles, setRoles ] = useState<Role[]>([]);
    const [ roleFilter, setRoleFilter ] = useState('');

    const rolesContextValue = {
        entities: roles,
        setEntities: setRoles
    };

    const EntityGroupComponent = useMemo(() => (
        createUserGroup(roleFilter)
    ), [ roleFilter ]);

    useAsyncEffect(useCallback(async () => {
        try {
            setRoles(await api.roles.getRoles(accountUserType, customerId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching user roles', error);
        }
    }, [
        customerId, accountUserType, pageMessage
    ]));

    return (
        <RolesContext.Provider value={rolesContextValue}>
            <AdminPageTemplate
                Context={UserManagementPageContext}
                CreateEditForm={UserForm}
                entityName="user"
                EntityGroupComponent={EntityGroupComponent}
                fetchEntities={fetchUsers}
                filterByLabel="name or email"
                filterEntity={(
                    (entities, filterInputValue) => filterUser(entities, filterInputValue, roles, roleFilter)
                )}
                justifyFilters="flex-start"
                otherFilters={(
                    <ButtonBar
                        label="Filter by role"
                        onValueChange={newValue => setRoleFilter(newValue)}
                        optional
                        options={roles.reduce<ButtonBarProps['options']>((prev, { name }) => ({
                            ...prev,
                            [name]: name
                        }), {})}
                    />
                )}
                visibleGroups={[ accountUserType ]}
            />
        </RolesContext.Provider>
    );
}

function createUserGroup(roleFilter: string) {
    return function UserGroup({
        entities,
        group: userType
    }: AdminEntityGroupProps<User, UserType>) {
        const users = entities.filter((entity) => entity.type === userType);

        return (
            <AdminEntityGroup
                header={(
                    <>
                        {`${userTypeDisplay[userType]} users`}

                        {roleFilter && (
                            <Tooltip title="Filters active">
                                <FilterAlt color="primary" />
                            </Tooltip>
                        )}
                    </>
                )}
            >
                {users.map((user) => (
                    <UserCard
                        key={user.id}
                        user={user}
                    />
                ))}

                {users.length === 0 && (
                    <Typography>
                        No {userType.toLowerCase()} users found
                    </Typography>
                )}
            </AdminEntityGroup>
        );
    };
}

function filterUser(user: User, debouncedTerm: string, roles: Role[], roleNameFilter?: string) {
    const { email, roleIds } = user;

    const filteredRoleID = roles.find(role => role.name === roleNameFilter)?.id;

    const roleIncluded = !roleNameFilter || (!!filteredRoleID && roleIds.includes(filteredRoleID));

    return roleIncluded && (
        getFullName(user).toLocaleLowerCase().includes(debouncedTerm)
        || email.toLocaleLowerCase().includes(debouncedTerm)
    );
}
