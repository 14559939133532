import {
    HighLevelGuideline,
    LoanProperty,
    amortizationTypeDisplay,
    automatedUwSystemDisplay,
    booleanEnumDisplay,
    loanPurposeDisplay,
    numUnitsDisplay,
    occupancyTypeDisplay,
    propertyTypeDisplay,
    specialtyProductDisplay
} from '@api';
import { Grid, renderEnumOptions } from '@tsp-ui/core/components';
import { AllowPartialArrayItems, booleanToEnum } from '@tsp-ui/core/utils';
import { ColDef, ColGroupDef, GetRowIdParams } from 'ag-grid-community';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { useCallback, useContext, useMemo } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';

import { EligibilityGuidelinesContext } from '../LoanProgramDetailPage/components/EditEligibilityGuidelinesDialog';
import { EligibilityGuidelineFormValues, LoanProgramFormValues } from '../LoanProgramDetailPage/components/LoanProgramForm';

import { EligibilityCellEditor, EligibilityTextFieldProps } from './EligibilityCellEditor';


export function EligibilityGuidelineGrid() {
    const { guidelines, highLevelGuidelines } = useWatch<LoanProgramFormValues, 'eligibilityGuidelineSet'>({ name: 'eligibilityGuidelineSet' });

    const { remove, insert } = useFieldArray<AllowPartialArrayItems<LoanProgramFormValues, true>>({ name: 'eligibilityGuidelineSet.guidelines' });

    const handleDrop = useCallback(async () => {
        await new Promise<void>((resolve) => setTimeout(resolve, 2000)); // TODO
    }, []);

    const onRemoveRow = useCallback((rowIndex: number) => {
        remove(rowIndex);
    }, [ remove ]);

    const onCreateRow = useCallback((rowIndex: number, newItem?: Partial<EligibilityGuidelineFormValues>) => {
        insert(rowIndex, newItem || {}, { shouldFocus: false });
    }, [ insert ]);

    // Flattened into string to prevent unnecessary rerendering
    const highLevelLoanProperties = getHighLevelProperties(highLevelGuidelines);

    const columnDefs =  useMemo(() => getFilteredColumnDefs(highLevelLoanProperties), [ highLevelLoanProperties ]);

    return (
        <Grid
            createNewItem={createNewItem}
            columnDefs={columnDefs}
            rowData={guidelines}
            getRowId={getRowID}
            onDrop={handleDrop}
            showActions
            showRowNumbers
            autoSizeColumns
            onRemoveRow={onRemoveRow}
            onCreateRow={onCreateRow}
        />
    );
}

function getHighLevelProperties(highLevelGuidelines: HighLevelGuideline[] | undefined) {
    return highLevelGuidelines?.map(
        ({ loanProperty }) => loanProperty
    ).filter(loanProperty => loanProperty).join(',');
}

export function ViewEligibilityGuidelineGrid() {
    const { eligibilityGuidelineSet } = useContext(EligibilityGuidelinesContext);

    return (
        <Grid
            columnDefs={getFilteredColumnDefs(getHighLevelProperties(eligibilityGuidelineSet?.highLevelGuidelines))}
            rowData={eligibilityGuidelineSet?.guidelines.map(guideline => booleanToEnum(guideline))}
            getRowId={getRowID}
            showRowNumbers
            suppressClickEdit
        />
    );
}

let newInvestorGuidelineID = -1;
function createNewItem() {
    return { id: `${newInvestorGuidelineID--}` };
}

function getRowID(params: GetRowIdParams<EligibilityGuidelineFormValues>) {
    return params.data.id;
}

type EligibilityColumnData = {
    field?: keyof EligibilityGuidelineFormValues;
    textFieldProps?: Partial<EligibilityTextFieldProps>;
    display?: Record<string, string>;
} & (ColDef<EligibilityGuidelineFormValues> | ColGroupDef<EligibilityGuidelineFormValues>);

function createColumnDefs(
    eligibilityFields: EligibilityColumnData[]
): (ColDef<EligibilityGuidelineFormValues> | ColGroupDef<EligibilityGuidelineFormValues>)[] {
    return eligibilityFields.map(({ display, textFieldProps, ...props }) => (!props.field ? props : {
        ...props,
        valueFormatter: ({ value }: ValueFormatterParams<EligibilityGuidelineFormValues>) => display?.[value] || value,
        cellEditor: EligibilityCellEditor,
        cellEditorParams: {
            fieldName: props.field,
            textFieldProps: {
                ...textFieldProps,
                ...(display ? {
                    select: true,
                    children: renderEnumOptions(display)
                } : null)
            }
        }
    }));
}

function toCamelCase(str: string) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

function getFilteredColumnDefs(loanPropertiesStr?: string) {
    const loanProperties = loanPropertiesStr?.split(',') as LoanProperty[];

    return columnDefsBase.filter(columnDef => {
        if (isColDef(columnDef)) {
            return !loanProperties?.some(loanProperty => toCamelCase(loanProperty) === columnDef.field);
        } else if (isColGroupDef(columnDef)) {
            return !loanProperties?.some(loanProperty => fieldHeaderMappings[loanProperty] === columnDef.headerName);
        } else {
            return false;
        }
    });
}

function isColDef(columnDef: ColDef| ColGroupDef): columnDef is ColDef<EligibilityGuidelineFormValues> {
    return columnDef.hasOwnProperty('field');
}

function isColGroupDef(columnDef: ColDef| ColGroupDef): columnDef is ColGroupDef<EligibilityGuidelineFormValues> {
    return columnDef.hasOwnProperty('children');
}

const fieldHeaderMappings: Partial<Record<LoanProperty, string>> = {
    [LoanProperty.FICO]: 'Credit Score',
    [LoanProperty.LTV]: 'LTV',
    [LoanProperty.DTI]: 'DTI',
    [LoanProperty.TERM]: 'Term'
};

const columnDefsBase = createColumnDefs([
    {
        field: 'occupancy',
        display: occupancyTypeDisplay
    },
    {
        field: 'purpose',
        display: loanPurposeDisplay
    },
    {
        field: 'propertyType',
        display: propertyTypeDisplay
    },
    {
        field: 'units',
        display: numUnitsDisplay
    },
    {
        headerName: 'Credit Score',
        marryChildren: true,
        children: createColumnDefs([
            {
                field: 'minFICO',
                headerName: 'Min',
                type: 'rightAligned',
                textFieldProps: { type: 'number' }
            },
            {
                field: 'maxFICO',
                headerName: 'Max',
                type: 'rightAligned',
                textFieldProps: { type: 'number' }
            }
        ])
    },
    {
        headerName: 'LTV',
        marryChildren: true,
        children: createColumnDefs([
            {
                field: 'minLTV',
                headerName: 'Min',
                type: 'rightAligned',
                textFieldProps: { type: 'number' }
            },
            {
                field: 'maxLTV',
                headerName: 'Max',
                type: 'rightAligned',
                textFieldProps: { type: 'number' }
            }
        ])
    },
    {
        headerName: 'DTI',
        marryChildren: true,
        children: createColumnDefs([
            {
                field: 'minDTI',
                headerName: 'Min',
                type: 'rightAligned',
                textFieldProps: { type: 'number' }
            },
            {
                field: 'maxDTI',
                headerName: 'Max',
                type: 'rightAligned',
                textFieldProps: { type: 'number' }
            }
        ])
    },
    {
        headerName: 'Term',
        marryChildren: true,
        children: createColumnDefs([
            {
                field: 'minTerm',
                headerName: 'Min',
                type: 'rightAligned',
                textFieldProps: { type: 'number' }
            },
            {
                field: 'maxTerm',
                headerName: 'Max',
                type: 'rightAligned',
                textFieldProps: { type: 'number' }
            }
        ])
    },
    {
        field: 'amortType',
        headerName: 'Amortization Type',
        display: amortizationTypeDisplay
    },
    {
        field: 'aus',
        display: automatedUwSystemDisplay
    },
    {
        field: 'minReservesMonths',
        textFieldProps: { type: 'number' },
        type: 'rightAligned'
    },
    {
        field: 'highBalance',
        display: booleanEnumDisplay
    },
    {
        field: 'specialtyProduct',
        display: specialtyProductDisplay
    }
]);
