import { apiUtils } from '../../api-utils';


export function getWireInfo(customerID: string): Promise<CustomerWireInfo[]> {
    return apiUtils.get(`/customer/${customerID}/wireInfo`);
}

export function createWireInfo(
    customerID: string, wireInfo: Omit<CustomerWireInfo, 'customerId' & 'id'>
): Promise<CustomerWireInfo> {
    return apiUtils.post(`/customer/${customerID}/wireInfo`, wireInfo);
}

export function updateWireInfo(wireInfo: CustomerWireInfo): Promise<CustomerWireInfo> {
    return apiUtils.put(`/customer/${wireInfo.customerId}/wireInfo/${wireInfo.id}`, wireInfo);
}

export interface CustomerWireInfo {
    id: string;
    customerId: number;
    isActive: boolean;
    isApproved: boolean;
    bankName: string;
    bankCity: string;
    bankState: string;
    bankAba: string;
    bankAccountNum: string;
    wireDestination: string;
    furtherCreditToAcctName?: string;
    furtherCreditToAcctNum?: string;
}
