import { License, licenseTypeDisplay } from '@api';
import { Cached, Edit, RemoveCircleOutline } from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { useConfirm } from '@tsp-ui/core/utils';

import styles from './LicenseCard.module.scss';
import LicenseDetails from './LicenseDetails';


interface LicenseCardProps {
    license: License;
    onDelete: (id: string) => void;
    onEditClick: (license: License) => void;
    onRenew: (id: string, renewedThroughYear: number) => void;
    readOnly?: boolean;
}

export default function LicenseCard({
    license, onDelete, onEditClick, onRenew, readOnly
}: LicenseCardProps) {
    const {
        id, state, type, licenseNumber, renewedThroughYear
    } = license;

    // Date.getMonth() is zero indexed for some reason so November = 10 for month comparison below
    const currentDate = new Date();
    const expired = renewedThroughYear < currentDate.getFullYear();
    const canRenew = expired || (currentDate.getMonth() >= 10 && renewedThroughYear === currentDate.getFullYear());

    const confirm = useConfirm();

    function getNewRenewedThroughYear() {
        return currentDate.getMonth() < 10 ? currentDate.getFullYear() : currentDate.getFullYear() + 1;
    }

    async function handleDelete() {
        if (await confirm('Are you sure you want to delete this license?')) {
            onDelete(id);
        }
    }

    return (
        <Paper
            variant="outlined"
            className={styles.card}
        >
            <Typography className={styles.title}>
                {licenseTypeDisplay[type]}
            </Typography>

            <div className={styles.cardButtons}>
                {!readOnly && (
                    <Tooltip
                        title="Delete license"
                        enterDelay={0}
                    >
                        <IconButton onClick={handleDelete}>
                            <RemoveCircleOutline color="error" />
                        </IconButton>
                    </Tooltip>
                )}

                {!expired && !readOnly && (
                    <Tooltip
                        title="Edit license"
                        enterDelay={0}
                    >
                        <IconButton onClick={() => onEditClick(license)}>
                            <Edit color="primary" />
                        </IconButton>
                    </Tooltip>
                )}

                {!readOnly && (
                    <Tooltip
                        title={canRenew ? 'Renew license' : 'You can renew this license after Nov 1'}
                        enterDelay={0}
                    >
                        <span>
                            <IconButton
                                onClick={() => onRenew(id, getNewRenewedThroughYear())}
                                disabled={!canRenew}
                                edge="end"
                            >
                                <Cached color={canRenew ? 'primary' : 'disabled'} />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
            </div>

            <LicenseDetails
                licenseNumber={licenseNumber}
                state={state}
            />

            <Tooltip
                title={expired ? 'License is expired' : ''}
                enterDelay={0}
            >
                <Typography
                    className={styles.expirationDate}
                    variant="caption"
                    color={expired ? 'error' : 'textSecondary'}
                >
                    {expired ? `expired in ${renewedThroughYear}` : `renewed through ${renewedThroughYear}`}
                </Typography>
            </Tooltip>
        </Paper>
    );
}
