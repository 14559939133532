import { Button, Paper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import { TitleUnderwritingTabs } from '../../TitleUnderwritingPage';
import UnderwritingQuestionGroup from '../../UnderwritingQuestionGroup';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';

import styles from './TitleCommitmentSection.module.scss';


interface TitleDetailsSectionProps {
    setTab: (tab: TitleUnderwritingTabs) => void;
    onTabComplete: (tab: TitleUnderwritingTabs, nextTab: TitleUnderwritingTabs) => void;
}

export default function TitleCommitmentSection({ onTabComplete }: TitleDetailsSectionProps) {
    const [ titleInFile, setTitleInFile ] = useState<boolean>();
    const [ showNext, setShowNext ] = useState(false);

    const [ lenderNameCorrect, setLenderNameCorrect ] = useState<boolean>();
    const [ vestingCorrect, setVestingCorrect ] = useState<boolean>();
    const [ clearOfDefects, setClearOfDefects ] = useState<boolean>();
    const [ holdHarmless, setHoldHarmless ] = useState<boolean>();
    const [ delinquentTaxes, setDelinquentTaxes ] = useState<boolean>();
    const [ adequateChain, setAdequateChain ] = useState<boolean>();
    const [ feeSimple, setFeeSimple ] = useState<boolean>();
    const [ insuredClosingLetter, setInsuredClosingLetter ] = useState<boolean>();

    const [ oneBorrowerVested, setOneBorrowerVested ] = useState<boolean>();
    const [ borrowerOnTitleLast6Mo, setBorrowerOnTitleLast6Mo ] = useState<boolean>();
    const [ vestedInTrust, setVestedInTrust ] = useState<boolean>();

    useEffect(() => {
        if (vestedInTrust !== undefined) {
            onTabComplete('commitment', vestedInTrust ? 'trust-review' : 'taxes');
        }
    }, [ vestedInTrust, onTabComplete ]);

    return (
        <UnderwritingStepSection title="Title commitment">
            <UnderwritingYesNoQuestionCard
                question="Is the title on file?"
                onAnswered={setTitleInFile}
                answer={titleInFile}
            />

            {titleInFile && (
                <>
                    <Paper
                        variant="outlined"
                        className={styles.paper}
                    >
                        <div className={styles.textFields}>
                            <TextField
                                label="Commitment date"
                                helperText="expires 01/31/2023"
                                required
                            />

                            <TextField
                                label="Loan amount"
                                required
                            />
                        </div>

                        {!showNext && (
                            <Button
                                variant="contained"
                                onClick={() => setShowNext(true)}
                            >
                                Next
                            </Button>
                        )}
                    </Paper>

                    {showNext && (
                        <UnderwritingQuestionGroup>
                            <UnderwritingYesNoQuestionCard
                                question="Is the lender's name correct?"
                                answer={lenderNameCorrect}
                                onAnswered={setLenderNameCorrect}
                            />

                            {lenderNameCorrect !== undefined && (
                                <UnderwritingYesNoQuestionCard
                                    question="Is the vesting correct?"
                                    answer={vestingCorrect}
                                    onAnswered={setVestingCorrect}
                                />
                            )}

                            {vestingCorrect !== undefined && (
                                <UnderwritingYesNoQuestionCard
                                    question="Is the title clear of defects?"
                                    answer={clearOfDefects}
                                    onAnswered={setClearOfDefects}
                                />
                            )}

                            {clearOfDefects !== undefined && (
                                <UnderwritingYesNoQuestionCard
                                    question={'Covid 19 "Hold Harmless" language?'}
                                    answer={holdHarmless}
                                    onAnswered={setHoldHarmless}
                                />
                            )}

                            {holdHarmless !== undefined && (
                                <UnderwritingYesNoQuestionCard
                                    question="Are there any delinquent taxes?"
                                    answer={delinquentTaxes}
                                    onAnswered={setDelinquentTaxes}
                                />
                            )}

                            {delinquentTaxes !== undefined && (
                                <UnderwritingYesNoQuestionCard
                                    question="Is a 24 month chain of title shown?"
                                    answer={adequateChain}
                                    onAnswered={setAdequateChain}
                                />
                            )}

                            {adequateChain !== undefined && (
                                <UnderwritingYesNoQuestionCard
                                    question="Is the title fee simple or leasehold?"
                                    answer={feeSimple}
                                    onAnswered={setFeeSimple}
                                />
                            )}

                            {feeSimple !== undefined && (
                                <UnderwritingYesNoQuestionCard
                                    question="Is an insured closing letter on file?"
                                    answer={insuredClosingLetter}
                                    onAnswered={setInsuredClosingLetter}
                                />
                            )}

                            {insuredClosingLetter !== undefined && ( // TODO refinance only
                                <UnderwritingYesNoQuestionCard
                                    question="Is at least one borrower currently vested on the title?"
                                    answer={oneBorrowerVested}
                                    onAnswered={setOneBorrowerVested}
                                />
                            )}

                            {oneBorrowerVested !== undefined && ( // TODO cash-out only
                                <UnderwritingYesNoQuestionCard
                                    question="Has any borrower been on the title for at least 6 months?"
                                    answer={borrowerOnTitleLast6Mo}
                                    onAnswered={setBorrowerOnTitleLast6Mo}
                                />
                            )}

                            {borrowerOnTitleLast6Mo !== undefined && (
                                <UnderwritingYesNoQuestionCard
                                    question="Is the title currently vested in a trust, or will it be at closing?"
                                    answer={vestedInTrust}
                                    onAnswered={setVestedInTrust}
                                />
                            )}
                        </UnderwritingQuestionGroup>
                    )}
                </>
            )}
        </UnderwritingStepSection>
    );
}
