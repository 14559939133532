import { CustomerPendingStep, customerPendingStepsDisplay } from '@api';
import { Tooltip, TooltipProps } from '@mui/material';
import clsx from 'clsx';

import StatusChecklistItem from './StatusChecklistItem';
import styles from './StatusTooltip.module.scss';


interface StatusTooltipProps {
    pendingSteps: CustomerPendingStep[];
    children: TooltipProps['children'];
}

export default function StatusTooltip({ pendingSteps, children }: StatusTooltipProps) {
    return (
        <Tooltip
            classes={{ tooltip: clsx(styles.paperTooltip, styles.checklistTooltip) }}
            enterDelay={0}
            title={
                pendingSteps.map(step => (
                    <StatusChecklistItem
                        key={step.id}
                        label={customerPendingStepsDisplay[step.name]}
                    />
                ))
            }
        >
            {children}
        </Tooltip>
    );
}
