import { CommonLoanProgram } from '@api';
import { Paper, Typography } from '@mui/material';
import { SubtleLink } from '@tsp-ui/core/components';
import { ReactNode } from 'react';

import styles from './LoanProgramCard.module.scss';


interface LoanProgramCardProps {
    loanProgram: CommonLoanProgram;
    action?: ReactNode;
}

export function LoanProgramCard({ loanProgram: { name, description, id }, action }: LoanProgramCardProps) {
    return (
        <Paper
            elevation={0}
            className={styles.root}
            variant={action ? 'outlined' : undefined}
        >
            <div className={styles.title}>
                <SubtleLink to={`loan-programs/${id}`}>
                    {name}
                </SubtleLink>

                {action}

                {/* TODO add these icons for client view */}

                {/* {action ? (
                    action
                ) : name === 'HomeStyle Renovation DU' ? (
                    <Tooltip
                        title="This loan program's eligibility is an overlay based on eligibility that is managed by
                            Premicorr. You will be notified if Premicorr makes changes to the base eligibility but this
                            loan program's eligibility will not automatically update."
                    >
                        <AutoFixNormal color="action" />
                    </Tooltip>
                ) : name === 'A Custom Loan Program' ? (
                    <Tooltip title="This loan program's eligibility is not managed by Premicorr.">
                        <AutoFixOff color="disabled" />
                    </Tooltip>
                ) : (
                    <Tooltip
                        title="This loan program's eligibility is managed by Premicorr. Eligibility
                            will automatically be updated if Premicorr makes changes to the base eligibility."
                    >
                        <AutoFixNormal color="primary" />
                    </Tooltip>
                )} */}
            </div>

            <Typography
                variant="body2"
                color="textSecondary"
            >
                {description}
            </Typography>
        </Paper>
    );
}
