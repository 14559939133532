import {
    Button, Paper, TextField, Typography
} from '@mui/material';
import { CardTable } from '@tsp-ui/core/components';
import { useEffect, useState } from 'react';

import loanDetailsStyles from '../../../LoanDetailPage.module.scss';
import { CreditUnderwritingTabs } from '../../CreditUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';

import styles from './CreditInquiriesSection.module.scss';


interface CreditInquiriesSectionProps {
    setTab: (tab: CreditUnderwritingTabs) => void;
    onTabComplete: (tab: CreditUnderwritingTabs, nextTab: CreditUnderwritingTabs) => void;
}

export default function CreditInquiriesSection({ onTabComplete }: CreditInquiriesSectionProps) {
    const [ hasInquiries, setHasInquiries ] = useState<boolean>();
    const [ hasLetter, setHasLetter ] = useState<boolean>();

    useEffect(() => {
        if (hasInquiries && hasLetter) {
            onTabComplete('inquiries', 'history');
        }
    }, [
        hasInquiries, hasLetter, onTabComplete
    ]);

    const showInquiriesTable = hasInquiries && hasLetter === false;

    return (
        <UnderwritingStepSection title="Credit inquiries">
            <div className={styles.root}>
                <div className={styles.questionsContainer}>
                    <UnderwritingYesNoQuestionCard
                        question="Does the credit report show other inquiries in the last 90 days?"
                        onAnswered={setHasInquiries}
                        answer={hasInquiries}
                    />

                    {hasInquiries && (
                        <UnderwritingYesNoQuestionCard
                            question="Is there a letter of explanation for each of those inquiries?"
                            onAnswered={setHasLetter}
                            answer={hasLetter}
                        />
                    )}
                </div>

                {showInquiriesTable && (
                    <Paper
                        variant="outlined"
                        className={loanDetailsStyles.card}
                    >
                        <Typography color="textSecondary">
                            Inquiries requiring explanation
                        </Typography>

                        <CardTable
                            className={loanDetailsStyles.editableCardTable}
                            headers={[ 'Creditor name', 'Date' ]}
                        >
                            <tr>
                                <td>
                                    <TextField
                                        variant="standard"
                                        size="small"
                                        autoFocus
                                    />
                                </td>

                                <td>
                                    <TextField
                                        variant="standard"
                                        size="small"
                                        className={styles.dateField}
                                    />
                                </td>
                            </tr>
                        </CardTable>

                        <div className={loanDetailsStyles.buttons}>
                            <Button>
                                Add inquiry
                            </Button>

                            <Button
                                variant="contained"
                                onClick={() => onTabComplete('inquiries', 'history')}
                            >
                                Next
                            </Button>
                        </div>
                    </Paper>
                )}
            </div>
        </UnderwritingStepSection>
    );
}
