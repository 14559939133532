import { Button, Paper } from '@mui/material';
import { ReactNode } from 'react';

import styles from './UnderwritingNextStepSummary.module.scss';


interface UnderwritingNextStepSummaryProps {
    children: ReactNode;
    onNextClick?: () => void;
}

export default function UnderwritingNextStepSummary({ children, onNextClick }: UnderwritingNextStepSummaryProps) {
    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            {children}

            {onNextClick && (
                <Button
                    variant="contained"
                    onClick={onNextClick}
                >
                    Next
                </Button>
            )}
        </Paper>
    );
}
