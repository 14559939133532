import { Notification, notificationTypeDisplay } from '@api';
import { Close } from '@mui/icons-material';
import {
    IconButton, Slide, Tooltip, Typography
} from '@mui/material';
import LoanNumberLink from '@views/components/MainNav/components/NotificationsButton/components/LoanNumberLink';
import {
    useCallback, useEffect, useRef, useState
} from 'react';

import styles from './NewNotificationDisplay.module.scss';


interface NewNotificationDisplayProps {
    notification: Notification | undefined;
    onDismiss: (notification: Notification) => void;
}

const NEW_NOTIFICATION_SHOW_TIMEOUT = 5000;

export default function NewNotificationDisplay({ notification, onDismiss }: NewNotificationDisplayProps) {
    const [ shown, setShown ] = useState(false);
    const timeoutRef = useRef<number>();

    const clearTimeout = useCallback(() => {
        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
        }
    }, []);

    const setTimeout = useCallback(() => {
        clearTimeout();

        timeoutRef.current = window.setTimeout(() => {
            setShown(false);
        }, NEW_NOTIFICATION_SHOW_TIMEOUT);
    }, [ clearTimeout ]);

    useEffect(() => {
        setShown(!!notification);

        if (notification) {
            setTimeout();
        } else {
            clearTimeout();
        }
    }, [
        notification, setTimeout, clearTimeout
    ]);

    function dismissNotification() {
        clearTimeout();
        setShown(false);

        onDismiss(notification!);
    }

    return !notification ? null : (
        <div className={styles.root}>
            <Slide
                direction={shown ? 'down' : 'left'}
                in={shown}
            >
                <div
                    className={styles.newNotification}
                    onMouseEnter={clearTimeout}
                    onMouseLeave={setTimeout}
                >
                    <Typography fontWeight={500}>
                        {notificationTypeDisplay[notification.type]}
                    </Typography>

                    <LoanNumberLink
                        loanNumber={notification.loanNumber}
                        onClick={dismissNotification}
                    />

                    <Tooltip title="Dismiss">
                        <IconButton
                            size="small"
                            edge="end"
                            className={styles.dismissButton}
                            onClick={dismissNotification}
                        >
                            <Close
                                color="action"
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>
                </div>
            </Slide>
        </div>
    );
}
