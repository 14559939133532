import {
    ProductTypeConfig, commitmentTypeDisplay, productTypeDisplay, servicingTypeDisplay
} from '@api';
import { Paper } from '@mui/material';
import { LabelGroup, LabeledValue } from '@tsp-ui/core/components';
import { formatCurrency } from '@tsp-ui/core/utils';
import { Fragment } from 'react';

import styles from './EligibleProductsLimitCard.module.scss';


interface EligibleProductsLimitCardProps {
    config: ProductTypeConfig;
}

export default function EligibleProductTypeChangeCard({ config }: EligibleProductsLimitCardProps) {
    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            <LabelGroup title={productTypeDisplay[config.productType]}>
                <LabeledValue
                    label="Servicing type:"
                    value={servicingTypeDisplay[config.servicingType]}
                />

                <LabeledValue
                    label="Commitment types:"
                    value={config.commitmentTypes?.map(commitmentType => (
                        <Fragment key={commitmentType}>
                            {commitmentTypeDisplay[commitmentType]}<br />
                        </Fragment>
                    )) || 'None'}
                />

                <LabeledValue
                    label="Lock periods:"
                    value={config.lockPeriods?.map(lockPeriod => (
                        <Fragment key={lockPeriod}>
                            {lockPeriod}<br />
                        </Fragment>
                    )) || 'None'}
                />

                <LabeledValue
                    label="Limit amount:"
                    value={formatCurrency(config.limit?.limitAmount)}
                />

                <LabeledValue
                    label="Expiration date:"
                    value={config.limit?.expirationDate}
                />
            </LabelGroup>
        </Paper>
    );
}
