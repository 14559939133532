import api from '@api';

import { apiUtils } from '../api/api-utils';

import { configureAmplify } from './configureAmplify';


export interface AppConfig {
    apiUrl: string;
    websocketUrl: string;
    awsAuth: {
        region: string;
        userPoolId: string;
        userPoolWebClientId: string;
    };
}

export const localConfig: AppConfig = {
    apiUrl: 'https://demo-api.premicorr.net/api',
    websocketUrl: 'wss://0puy3y27hh.execute-api.us-east-1.amazonaws.com/dev',
    awsAuth: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_IYUH4q1EX',
        userPoolWebClientId: '76f67tc3hcn31vd4l69i556d8n'
    }
};


export const demoConfig: AppConfig = {
    apiUrl: 'https://api.demo.premicorr.net/api',
    websocketUrl: 'wss://4cezf035d5.execute-api.us-east-1.amazonaws.com/production/',
    awsAuth: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_hKhWK3JLP',
        userPoolWebClientId: '6t8ksalc3gnf52p7vlc5h5ffeb'
    }
};

/**
 * Loads config variables for the current environment and uses them to configure services that depend on them.
 * If the app is running in production mode, fetches the config json from the server, else falls back to the
 * local config defined above.
 */
export default async function configureApp() {
    let config = localConfig;

    if (process.env.NODE_ENV === 'production') {
        const configResponse = await fetch('/config.json');
        config = await configResponse.json();
    }

    configureAmplify(config);
    apiUtils.configure(config);

    if (!api.webSocket.url) {
        api.webSocket.url = config.websocketUrl;
    }
}
