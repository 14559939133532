import { InvestorRelationship } from '@api';
import { Link, Typography } from '@mui/material';
import { PhoneTypography } from '@tsp-ui/core/components';


export default function InvestorsTableRow({ values }: { values: InvestorRelationship }) {
    return (
        <tr>
            <Typography
                component="td"
                padding="4px"
                variant="body2"
                width="100%"
                noWrap
            >
                {values.companyName}
            </Typography>

            <Typography
                component="td"
                variant="body2"
                padding="4px"
                noWrap
            >
                {values.contact}
            </Typography>

            <Typography
                component="td"
                variant="body2"
                padding="4px"
                noWrap
            >
                <Link href={`mailto:${values.emailAddress}`}>
                    {values.emailAddress}
                </Link>
            </Typography>

            <PhoneTypography
                component="td"
                variant="body2"
                padding="4px"
                noWrap
            >
                {values.phone}
            </PhoneTypography>
        </tr>
    );
}
