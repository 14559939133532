import { ClientInvestor, InvestorStatus } from '@api';
import {
    AutoFixNormal, AutoFixOff, CorporateFare, Info
} from '@mui/icons-material';
import { Paper, Tooltip } from '@mui/material';
import { IconTypography, SubtleLink } from '@tsp-ui/core/components';
import clsx from 'clsx';
import { useContext } from 'react';

import { InvestorEligibilityContext } from '../InvestorEligibilityRoutes';

import styles from './ClientInvestorCard.module.scss';


interface ClientInvestorCardProps {
    investor: ClientInvestor;
}

export function ClientInvestorCard({ investor }: ClientInvestorCardProps) {
    const { name, id, eligibilityBasedOnInvestorId } = investor;

    const { investors } = useContext(InvestorEligibilityContext);

    const eligibilityBaseInvestor = investors?.find(({ id }) => id === eligibilityBasedOnInvestorId);

    const icon = investor.isAgency || investor.eligibilityBasedOnInvestorId ? (
        <Tooltip title="This investor's eligibility is based on eligibility managed by Premicorr">
            <AutoFixNormal color="primary" />
        </Tooltip>
    ) : (
        <Tooltip title="This investor's eligibility is not based on eligibility managed by Premicorr">
            <AutoFixOff color="disabled" />
        </Tooltip>
    );
    return (
        <Paper
            className={styles.root}
            elevation={0}
        >
            <span className={styles.investorName}>
                {investor.isAgency && (
                    <Tooltip title="This is an agency investor">
                        <CorporateFare
                            fontSize="small"
                            color="primary"
                        />
                    </Tooltip>
                )}

                <SubtleLink
                    to={`investors/${id}`}
                    className={clsx({
                        [styles.lineThrough]: investor.status === InvestorStatus.INACTIVE
                    })}
                >
                    {name}
                </SubtleLink>

                {investor.status === InvestorStatus.PENDING && (
                    <Tooltip title="This investor is pending approval">
                        <Info
                            fontSize="small"
                            color="disabled"
                        />
                    </Tooltip>
                )}
            </span>

            <IconTypography
                variant="caption"
                color="textSecondary"
                fontWeight={400}
                iconPosition="after"
                icon={icon}
            >
                {eligibilityBaseInvestor && `Eligibility based on ${eligibilityBaseInvestor.name}`}
            </IconTypography>
        </Paper>
    );
}
