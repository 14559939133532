import {
    LLPAMatrixEntry,
    LoanProperty,
    NumericFieldOperator,
    NumericMatrixEntry,
    NumericMatrixEntryType,
    loanPropertyDisplay,
    loanPropertyEnumDisplays,
    numericFieldOperatorDisplay,
    numericFieldOperatorTooltips
} from '@api';
import { HelpOutline } from '@mui/icons-material';
import {
    MenuItem, TextField as MuiTextField, Paper, Popover, Tooltip, Typography
} from '@mui/material';
import { IconTypography, renderEnumOptions } from '@tsp-ui/core/components';
import LoanPropertyValueCell, {
    formatMatrixEntry
} from '@views/admin/llpas/components/LLPAMatrix/components/LoanPropertyValueCell';
import MatrixTableRow from '@views/admin/llpas/components/LLPAMatrix/components/MatrixTableRow';
import {
    RefObject,
    createContext,
    createRef,
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';
import { useWatch } from 'react-hook-form';

import { MatrixStepFormValues } from '../LLPADialog';

import styles from './LLPAMatrix.module.scss';


interface LLPAMatrixContextValues {
    popoverProps: NumericCellEditPopoverProps;
    handleEditPopoverClose?: () => void;
    handleEditClick?: (
        property: LoanProperty,
        entry: LLPAMatrixEntry,
        updateValue: (newValue: LLPAMatrixEntry[LoanProperty]) => void,
        anchorElRef: RefObject<HTMLTableHeaderCellElement | null>
    ) => void;
    queueEdit?: (
        property: LoanProperty,
        entry: LLPAMatrixEntry,
        updateValue: (newValue: LLPAMatrixEntry[LoanProperty]) => void,
        anchorElRef: RefObject<HTMLTableHeaderCellElement | null>
    ) => void;
}

const defaultPopoverProps: NumericCellEditPopoverProps = {
    anchorElRef: createRef(),
    updateValue: () => {},
    property: undefined,
    entry: undefined
};

export const LLPAMatrixContext = createContext<LLPAMatrixContextValues>({
    popoverProps: { ...defaultPopoverProps }
});

const {
    FICO, LTV
} = LoanProperty;

export function LLPAMatrix() {
    const matrixColumns = useWatch<MatrixStepFormValues, 'matrixColumns'>({
        name: 'matrixColumns'
    });

    const [ entries, setEntries ] = useState<LLPAMatrixEntry[]>(ficoLtvEntries || [
        Object.fromEntries([
            [ 'adjustment', '' ],
            ...matrixColumns.map((col) => [ col, undefined ])
        ]) as LLPAMatrixEntry
    ]);

    const colProperties = useMemo(() => (
        matrixColumns.length === 1 ? matrixColumns : matrixColumns.slice(0, matrixColumns.length - 1)
    ), [ matrixColumns ]);

    const rowProperty = matrixColumns.length === 1 ? null : matrixColumns.slice(-1)[0];

    const numRows = new Set(entries.map((llpaEntry) => (
        Object.keys(llpaEntry)
            .filter(loanProperty => (
                loanProperty !== rowProperty && loanProperty !== 'adjustment'
            ))
            .map((key) => `${key}:${formatMatrixEntry(key as LoanProperty, llpaEntry)}`)
            .join()
    ))).size;

    const rowHeaderEntries: LLPAMatrixEntry[] = [];
    for (let i = 0; i < entries.length; i += numRows) {
        rowHeaderEntries.push(entries[i]);
    }

    const [ editQueue, setEditQueue ] = useState<NumericCellEditPopoverProps[]>([]);

    const [ contextValues, setContextValues ] = useState<LLPAMatrixContextValues>({
        handleEditPopoverClose: () => {
            setContextValues((contextValues) => ({
                ...contextValues,
                popoverProps: { ...defaultPopoverProps }
            }));
        },
        handleEditClick: (property, entry, updateValue, anchorElRef) => {
            setEditQueue([]);
            setContextValues((contextValues) => ({
                ...contextValues,
                popoverProps: {
                    anchorElRef,
                    updateValue,
                    property,
                    entry
                }
            }));
        },
        queueEdit: (property, entry, updateValue, anchorElRef) => {
            setEditQueue((editQueue) => {
                const existingEntry = editQueue.find((queueEntry) => (
                    queueEntry.property === property
                ));

                return editQueue.filter(editEntry => editEntry !== existingEntry).concat({
                    anchorElRef,
                    updateValue,
                    property,
                    entry
                });
            });
        },
        popoverProps: { ...defaultPopoverProps }
    });

    const { popoverProps } = contextValues;
    const { property } = popoverProps;

    useEffect(() => {
        if (editQueue.length) {
            const newQueue = [ ...editQueue ];
            const popoverProps = newQueue.shift() || {
                ...defaultPopoverProps
            };

            if (!property || popoverProps.property === property) {
                setContextValues((contextValues) => ({
                    ...contextValues,
                    popoverProps
                }));

                setEditQueue(newQueue);
            }
        }
    }, [ editQueue, property ]);

    return (
        <LLPAMatrixContext.Provider value={contextValues}>
            <Paper
                variant="outlined"
                className={styles.tableContainer}
            >
                {colProperties.length === 0 ? (
                    <div className={styles.noContent}>
                        <Paper className={styles.noContentPaper}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                align="center"
                            >
                                This matrix has no content yet. Add a matrix column above to start building your matrix.
                            </Typography>
                        </Paper>
                    </div>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                {colProperties.map((property) => (
                                    <th
                                        key={property}
                                        rowSpan={rowProperty ? 2 : undefined}
                                    >
                                        {loanPropertyDisplay[property]}
                                    </th>
                                ))}

                                {!rowProperty ? (
                                    <th>Price Adjustment</th>
                                ) : (
                                    <th colSpan={rowHeaderEntries?.length}>
                                        {loanPropertyDisplay[rowProperty]}
                                    </th>
                                )}
                            </tr>

                            {rowProperty && (
                                <tr>
                                    {rowHeaderEntries?.map((entry) => (
                                        <LoanPropertyValueCell
                                            key={getKeyForEntry(entry)}
                                            property={rowProperty}
                                            rowProperty={rowProperty}
                                            colProperties={colProperties}
                                            variant="row"
                                            entry={entry}
                                            setEntries={setEntries}
                                            numRows={numRows}
                                        />
                                    ))}
                                </tr>
                            )}
                        </thead>

                        <tbody>
                            {Array(numRows).fill(true).map((_, index) => (
                                <MatrixTableRow
                                    key={getKeyForEntry(entries[index])}
                                    rowIndex={index}
                                    entries={entries}
                                    setEntries={setEntries}
                                    rowProperty={rowProperty}
                                    colProperties={colProperties}
                                    numRows={numRows}
                                />
                            ))}
                        </tbody>
                    </table>
                )}
            </Paper>

            {colProperties.length > 0 && (
                <IconTypography
                    fontWeight={400}
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    className={styles.tableHelperText}
                    icon={(
                        <HelpOutline
                            color="primary"
                            fontSize="small"
                        />
                    )}
                >
                    Hover over cells to reveal additional actions
                </IconTypography>
            )}

            <LoanPropertyCellEditPopover
                key={popoverProps?.entry
                    ? formatMatrixEntry(popoverProps.property!, popoverProps.entry)
                    : popoverProps?.property}
                {...popoverProps}
            />
        </LLPAMatrixContext.Provider>
    );
}

export function parseNumericExclusion(value: string): NumericMatrixEntry {
    return value.includes(' - ') ? {
        type: NumericMatrixEntryType.RANGE,
        min: parseFloat(value.split(' - ')[0]),
        max: parseFloat(value.split(' - ')[1])
    } : {
        type: NumericMatrixEntryType.COMPARISON,
        operator: value.includes('>')
            ? NumericFieldOperator.GREATER_THAN : value.includes('≥')
                ? NumericFieldOperator.GREATER_THAN_EQUAL : value.includes('<')
                    ? NumericFieldOperator.LESS_THAN : value.includes('≤')
                        ? NumericFieldOperator.LESS_THAN_EQUAL
                        : NumericFieldOperator.BETWEEN,
        value: parseFloat(value.replace(/[>≥<≤] /, ''))
    };
}

export function getKeyForEntry(entry: LLPAMatrixEntry) {
    return Object.keys(entry)
        .filter((key) => key !== 'adjustment')
        .map((key) => `${key}:${formatMatrixEntry(key as LoanProperty, entry)}`)
        .join();
}

interface NumericCellEditPopoverProps {
    anchorElRef: RefObject<HTMLElement | null>;
    property: LoanProperty | undefined;
    entry: LLPAMatrixEntry | undefined;
    updateValue: (newValue: LLPAMatrixEntry[LoanProperty]) => void;
}

function LoanPropertyCellEditPopover({
    anchorElRef, property, entry, updateValue
}: NumericCellEditPopoverProps) {
    const value = entry?.[property!] as NumericMatrixEntry | undefined;
    const inputRef = useRef<HTMLInputElement>(null);
    const anchorEl = anchorElRef.current;

    useEffect(() => {
        if (anchorEl) {
            inputRef.current?.focus();
        }
    }, [ anchorEl ]);

    const { handleEditPopoverClose } = useContext(LLPAMatrixContext);

    const popoverContentsRef = useRef<PopoverContentsRef>(null);
    const PopoverContentsComponent = loanPropertyCellEditors[property!];

    return (
        <Popover
            open={!!anchorEl}
            onClose={() => {
                handleEditPopoverClose?.();

                const updatedValue = popoverContentsRef.current!.getUpdatedMatrixValue();
                if (updatedValue) {
                    updateValue(updatedValue);
                }
            }}
            anchorEl={anchorEl}
            classes={{ paper: styles.rangeEditCellPopover }}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom'
            }}
            transformOrigin={{
                horizontal: 'center',
                vertical: 'top'
            }}
        >
            {property && PopoverContentsComponent && (
                <PopoverContentsComponent
                    ref={popoverContentsRef}
                    property={property}
                    value={value}
                    inputRef={inputRef}
                />
            )}
        </Popover>
    );
}

interface PopoverContentsRef {
    getUpdatedMatrixValue(): LLPAMatrixEntry[LoanProperty];
}

interface PopoverContentsProps {
    property: LoanProperty;
    value: LLPAMatrixEntry[LoanProperty] | undefined;
    inputRef: RefObject<HTMLInputElement | null>;
}

const NumericCellEditPopoverContents = forwardRef<PopoverContentsRef, PopoverContentsProps>(({
    value: valueProp,
    inputRef
}, ref) =>  {
    const value = valueProp as NumericMatrixEntry | undefined;

    const [ operator, setOperator ] = useState(value?.type === NumericMatrixEntryType.COMPARISON
        ? value.operator
        : NumericFieldOperator.BETWEEN.toString());

    const [ min, setMin ] = useState(value?.type === NumericMatrixEntryType.RANGE
        ? value.min.toString()
        : '');

    const [ max, setMax ] = useState(value?.type === NumericMatrixEntryType.RANGE
        ? value.max.toString()
        : '');

    const [ inputValue, setInputValue ] = useState(value?.type === NumericMatrixEntryType.COMPARISON
        ? value.value.toString()
        : '');

    useImperativeHandle(ref, () => ({
        getUpdatedMatrixValue: () => (
            operator === NumericFieldOperator.BETWEEN ? {
                type: NumericMatrixEntryType.RANGE,
                max: parseInt(max) || 0,
                min: parseInt(min) || 0
            } : {
                type: NumericMatrixEntryType.COMPARISON,
                operator: operator as NumericFieldOperator,
                value: parseInt(inputValue) || 0
            }
        )
    }), [
        operator, min, max, inputValue
    ]);

    return (
        <>
            <MuiTextField
                name="operator"
                label="Operator"
                size="small"
                select
                value={operator}
                onChange={(event) => setOperator(event.target.value)}
                inputRef={inputRef}
                autoFocus
                InputLabelProps={{
                    shrink: true
                }}
            >
                {Object.entries(numericFieldOperatorDisplay).map(([ value, display ]) => (
                    <MenuItem
                        value={value}
                        key={value}
                        className={styles.operatorMenuItem}
                    >
                        <Tooltip title={numericFieldOperatorTooltips[value as NumericFieldOperator]}>
                            <div>{display}</div>
                        </Tooltip>
                    </MenuItem>
                ))}
            </MuiTextField>

            {operator === NumericFieldOperator.BETWEEN ? (
                <>
                    <MuiTextField
                        name="min"
                        label="Min"
                        size="small"
                        value={min}
                        onChange={(event) => setMin(event.target.value)}
                    />

                    <MuiTextField
                        name="max"
                        label="Max"
                        size="small"
                        value={max}
                        onChange={(event) => setMax(event.target.value)}
                    />
                </>
            ) : (
                <MuiTextField
                    name="value"
                    label="Value"
                    size="small"
                    className={styles.fullWidth}
                    value={inputValue}
                    onChange={(event) => setInputValue(event.target.value)}
                />
            )}
        </>
    );
});

const EnumCellEditPopoverContents = forwardRef<PopoverContentsRef, PopoverContentsProps>(({
    property,
    value: valueProp,
    inputRef
}, ref) => {
    const [ value, setValue ] = useState(valueProp as string[] || []);
    const enumDisplay = loanPropertyEnumDisplays[property];

    useImperativeHandle(ref, () => ({
        getUpdatedMatrixValue: () => (value.length ? value as typeof valueProp : undefined)
    }), [ value ]);

    return !enumDisplay ? null : (
        <MuiTextField
            name="value"
            label={loanPropertyDisplay[property]}
            size="small"
            select
            value={value}
            onChange={(event) => setValue(event.target.value as unknown as string[])}
            inputRef={inputRef}
            autoFocus
            InputLabelProps={{
                shrink: true
            }}
            SelectProps={{
                multiple: true
            }}
        >
            {renderEnumOptions(enumDisplay)}
        </MuiTextField>
    );
});

const loanPropertyCellEditors = {
    [LoanProperty.LOAN_TYPE]: EnumCellEditPopoverContents,
    [LoanProperty.OCCUPANCY]: EnumCellEditPopoverContents,
    [LoanProperty.PURPOSE]: EnumCellEditPopoverContents,
    [LoanProperty.AMORT_TYPE]: EnumCellEditPopoverContents,
    [LoanProperty.PROPERTY_TYPE]: EnumCellEditPopoverContents,
    [LoanProperty.UNITS]: EnumCellEditPopoverContents,
    [LoanProperty.FICO]: NumericCellEditPopoverContents,
    [LoanProperty.LTV]: NumericCellEditPopoverContents,
    [LoanProperty.CLTV]: NumericCellEditPopoverContents,
    [LoanProperty.DTI]: NumericCellEditPopoverContents,
    [LoanProperty.TERM]: NumericCellEditPopoverContents,
    [LoanProperty.AUS]: EnumCellEditPopoverContents,
    [LoanProperty.MIN_RESERVES_MONTHS]: NumericCellEditPopoverContents,
    [LoanProperty.HIGH_BALANCE]: EnumCellEditPopoverContents,
    [LoanProperty.SPECIALTY_PRODUCT]: EnumCellEditPopoverContents,
    [LoanProperty.EFFECTIVE_DATE]: null,
    [LoanProperty.EXPIRATION_DATE]: null,
    [LoanProperty.STATE]: EnumCellEditPopoverContents,
    [LoanProperty.LOAN_AMOUNT]: NumericCellEditPopoverContents,
    [LoanProperty.INVESTOR]: EnumCellEditPopoverContents
};

const ficoLtvEntries: LLPAMatrixEntry[] = [
    {
        [FICO]: parseNumericExclusion('≥ 740'),
        [LTV]: parseNumericExclusion('80.01 - 85.00%'),
        adjustment: '0.250'
    },
    {
        [FICO]: parseNumericExclusion('720 - 739'),
        [LTV]: parseNumericExclusion('80.01 - 85.00%'),
        adjustment: '0.500'
    },
    {
        [FICO]: parseNumericExclusion('700 - 719'),
        [LTV]: parseNumericExclusion('80.01 - 85.00%'),
        adjustment: '1.000'
    },
    {
        [FICO]: parseNumericExclusion('680 - 699'),
        [LTV]: parseNumericExclusion('80.01 - 85.00%'),
        adjustment: '1.500'
    },
    {
        [FICO]: parseNumericExclusion('660 - 679'),
        [LTV]: parseNumericExclusion('80.01 - 85.00%'),
        adjustment: '2.750'
    },
    {
        [FICO]: parseNumericExclusion('640 - 659'),
        [LTV]: parseNumericExclusion('80.01 - 85.00%'),
        adjustment: '3.250'
    },
    {
        [FICO]: parseNumericExclusion('620 - 639'),
        [LTV]: parseNumericExclusion('80.01 - 85.00%'),
        adjustment: '3.250'
    },
    {
        [FICO]: parseNumericExclusion('< 620'),
        [LTV]: parseNumericExclusion('80.01 - 85.00%'),
        adjustment: '3.250'
    },

    {
        [FICO]: parseNumericExclusion('≥ 740'),
        [LTV]: parseNumericExclusion('85.01 - 90.00%'),
        adjustment: '0.250'
    },
    {
        [FICO]: parseNumericExclusion('720 - 739'),
        [LTV]: parseNumericExclusion('85.01 - 90.00%'),
        adjustment: '0.500'
    },
    {
        [FICO]: parseNumericExclusion('700 - 719'),
        [LTV]: parseNumericExclusion('85.01 - 90.00%'),
        adjustment: '1.000'
    },
    {
        [FICO]: parseNumericExclusion('680 - 699'),
        [LTV]: parseNumericExclusion('85.01 - 90.00%'),
        adjustment: '1.250'
    },
    {
        [FICO]: parseNumericExclusion('660 - 679'),
        [LTV]: parseNumericExclusion('85.01 - 90.00%'),
        adjustment: '2.250'
    },
    {
        [FICO]: parseNumericExclusion('640 - 659'),
        [LTV]: parseNumericExclusion('85.01 - 90.00%'),
        adjustment: '2.750'
    },
    {
        [FICO]: parseNumericExclusion('620 - 639'),
        [LTV]: parseNumericExclusion('85.01 - 90.00%'),
        adjustment: '3.250'
    },
    {
        [FICO]: parseNumericExclusion('< 620'),
        [LTV]: parseNumericExclusion('85.01 - 90.00%'),
        adjustment: '3.250'
    },

    {
        [FICO]: parseNumericExclusion('≥ 740'),
        [LTV]: parseNumericExclusion('90.01 - 95.00%'),
        adjustment: '0.250'
    },
    {
        [FICO]: parseNumericExclusion('720 - 739'),
        [LTV]: parseNumericExclusion('90.01 - 95.00%'),
        adjustment: '0.500'
    },
    {
        [FICO]: parseNumericExclusion('700 - 719'),
        [LTV]: parseNumericExclusion('90.01 - 95.00%'),
        adjustment: '1.000'
    },
    {
        [FICO]: parseNumericExclusion('680 - 699'),
        [LTV]: parseNumericExclusion('90.01 - 95.00%'),
        adjustment: '1.250'
    },
    {
        [FICO]: parseNumericExclusion('660 - 679'),
        [LTV]: parseNumericExclusion('90.01 - 95.00%'),
        adjustment: '2.250'
    },
    {
        [FICO]: parseNumericExclusion('640 - 659'),
        [LTV]: parseNumericExclusion('90.01 - 95.00%'),
        adjustment: '2.750'
    },
    {
        [FICO]: parseNumericExclusion('620 - 639'),
        [LTV]: parseNumericExclusion('90.01 - 95.00%'),
        adjustment: '3.250'
    },
    {
        [FICO]: parseNumericExclusion('< 620'),
        [LTV]: parseNumericExclusion('90.01 - 95.00%'),
        adjustment: '3.250'
    },

    {
        [FICO]: parseNumericExclusion('≥ 740'),
        [LTV]: parseNumericExclusion('95.01 - 97.00%'),
        adjustment: '0.750'
    },
    {
        [FICO]: parseNumericExclusion('720 - 739'),
        [LTV]: parseNumericExclusion('95.01 - 97.00%'),
        adjustment: '1.000'
    },
    {
        [FICO]: parseNumericExclusion('700 - 719'),
        [LTV]: parseNumericExclusion('95.01 - 97.00%'),
        adjustment: '1.500'
    },
    {
        [FICO]: parseNumericExclusion('680 - 699'),
        [LTV]: parseNumericExclusion('95.01 - 97.00%'),
        adjustment: '1.500'
    },
    {
        [FICO]: parseNumericExclusion('660 - 679'),
        [LTV]: parseNumericExclusion('95.01 - 97.00%'),
        adjustment: '2.250'
    },
    {
        [FICO]: parseNumericExclusion('640 - 659'),
        [LTV]: parseNumericExclusion('95.01 - 97.00%'),
        adjustment: '2.750'
    },
    {
        [FICO]: parseNumericExclusion('620 - 639'),
        [LTV]: parseNumericExclusion('95.01 - 97.00%'),
        adjustment: '3.500'
    },
    {
        [FICO]: parseNumericExclusion('< 620'),
        [LTV]: parseNumericExclusion('95.01 - 97.00%'),
        adjustment: '3.750'
    },

    {
        [FICO]: parseNumericExclusion('≥ 740'),
        [LTV]: parseNumericExclusion('> 97.00%'),
        adjustment: '0.750'
    },
    {
        [FICO]: parseNumericExclusion('720 - 739'),
        [LTV]: parseNumericExclusion('> 97.00%'),
        adjustment: '1.000'
    },
    {
        [FICO]: parseNumericExclusion('700 - 719'),
        [LTV]: parseNumericExclusion('> 97.00%'),
        adjustment: '1.500'
    },
    {
        [FICO]: parseNumericExclusion('680 - 699'),
        [LTV]: parseNumericExclusion('> 97.00%'),
        adjustment: '1.500'
    },
    {
        [FICO]: parseNumericExclusion('660 - 679'),
        [LTV]: parseNumericExclusion('> 97.00%'),
        adjustment: '2.250'
    },
    {
        [FICO]: parseNumericExclusion('640 - 659'),
        [LTV]: parseNumericExclusion('> 97.00%'),
        adjustment: '2.750'
    },
    {
        [FICO]: parseNumericExclusion('620 - 639'),
        [LTV]: parseNumericExclusion('> 97.00%'),
        adjustment: '3.500'
    },
    {
        [FICO]: parseNumericExclusion('< 620'),
        [LTV]: parseNumericExclusion('> 97.00%'),
        adjustment: '3.750'
    }
];

// const defaultEntries: LLPAMatrixEntry[] = [
//     {
//         [STATE]: [ State.SC ],
//         [FICO]: parseNumericExclusion('620 - 639'),
//         [LTV]: parseNumericExclusion('≤ 60%'),
//         [LOAN_AMOUNT]: parseNumericExclusion('< 300000'),
//         adjustment: ''
//     },
//     {
//         [STATE]: [ State.SC ],
//         [FICO]: parseNumericExclusion('640 - 659'),
//         [LTV]: parseNumericExclusion('65.01% - 70%'),
//         [LOAN_AMOUNT]: parseNumericExclusion('< 300000'),
//         adjustment: ''
//     },
//     {
//         [STATE]: [ State.SC ],
//         [FICO]: parseNumericExclusion('640 - 659'),
//         [LTV]: parseNumericExclusion('70.01% - 75%'),
//         [LOAN_AMOUNT]: parseNumericExclusion('< 300000'),
//         adjustment: ''
//     },
//     {
//         [STATE]: [ State.SC ],
//         [FICO]: parseNumericExclusion('≥ 680'),
//         [LTV]: parseNumericExclusion('> 75%'),
//         [LOAN_AMOUNT]: parseNumericExclusion('< 300000'),
//         adjustment: ''
//     },
//     {
//         [STATE]: [ State.MI ],
//         [FICO]: parseNumericExclusion('620 - 639'),
//         [LTV]: parseNumericExclusion('≤ 60%'),
//         [LOAN_AMOUNT]: parseNumericExclusion('< 300000'),
//         adjustment: ''
//     },
//     {
//         [STATE]: [ State.MI ],
//         [FICO]: parseNumericExclusion('640 - 659'),
//         [LTV]: parseNumericExclusion('65.01% - 70%'),
//         [LOAN_AMOUNT]: parseNumericExclusion('< 300000'),
//         adjustment: ''
//     },
//     {
//         [STATE]: [ State.MI ],
//         [FICO]: parseNumericExclusion('660 - 679'),
//         [LTV]: parseNumericExclusion('70.01% - 75%'),
//         [LOAN_AMOUNT]: parseNumericExclusion('< 300000'),
//         adjustment: ''
//     },
//     {
//         [STATE]: [ State.MI ],
//         [FICO]: parseNumericExclusion('≥ 680'),
//         [LTV]: parseNumericExclusion('> 75%'),
//         [LOAN_AMOUNT]: parseNumericExclusion('< 300000'),
//         adjustment: ''
//     },
//     {
//         [STATE]: [ State.SC ],
//         [FICO]: parseNumericExclusion('620 - 639'),
//         [LTV]: parseNumericExclusion('≤ 60%'),
//         [LOAN_AMOUNT]: parseNumericExclusion('≥ 300000'),
//         adjustment: ''
//     },
//     {
//         [STATE]: [ State.SC ],
//         [FICO]: parseNumericExclusion('640 - 659'),
//         [LTV]: parseNumericExclusion('65.01% - 70%'),
//         [LOAN_AMOUNT]: parseNumericExclusion('≥ 300000'),
//         adjustment: ''
//     },
//     {
//         [STATE]: [ State.SC ],
//         [FICO]: parseNumericExclusion('640 - 659'),
//         [LTV]: parseNumericExclusion('70.01% - 75%'),
//         [LOAN_AMOUNT]: parseNumericExclusion('≥ 300000'),
//         adjustment: ''
//     },
//     {
//         [STATE]: [ State.SC ],
//         [FICO]: parseNumericExclusion('≥ 680'),
//         [LTV]: parseNumericExclusion('> 75%'),
//         [LOAN_AMOUNT]: parseNumericExclusion('≥ 300000'),
//         adjustment: ''
//     },
//     {
//         [STATE]: [ State.MI ],
//         [FICO]: parseNumericExclusion('620 - 639'),
//         [LTV]: parseNumericExclusion('≤ 60%'),
//         [LOAN_AMOUNT]: parseNumericExclusion('≥ 300000'),
//         adjustment: ''
//     },
//     {
//         [STATE]: [ State.MI ],
//         [FICO]: parseNumericExclusion('640 - 659'),
//         [LTV]: parseNumericExclusion('65.01% - 70%'),
//         [LOAN_AMOUNT]: parseNumericExclusion('≥ 300000'),
//         adjustment: '1.000'
//     },
//     {
//         [STATE]: [ State.MI ],
//         [FICO]: parseNumericExclusion('660 - 679'),
//         [LTV]: parseNumericExclusion('70.01% - 75%'),
//         [LOAN_AMOUNT]: parseNumericExclusion('≥ 300000'),
//         adjustment: ''
//     },
//     {
//         [STATE]: [ State.MI ],
//         [FICO]: parseNumericExclusion('≥ 680'),
//         [LTV]: parseNumericExclusion('> 75%'),
//         [LOAN_AMOUNT]: parseNumericExclusion('≥ 300000'),
//         adjustment: ''
//     }
// ];
