import { LLPAMatrixEntry } from '@api';
import { Edit } from '@mui/icons-material';
import {
    IconButton, TextField as MuiTextField, Popover, Tooltip
} from '@mui/material';
import ActionsCell from '@views/admin/llpas/components/LLPAMatrix/components/ActionsCell';
import {
    Dispatch, SetStateAction, useRef, useState
} from 'react';

import updateMatrixEntry from '../utils/updateMatrixEntry';

import styles from './AdjustmentCell.module.scss';


interface AdjustmentCellProps {
    entry: LLPAMatrixEntry;
    setEntries: Dispatch<SetStateAction<LLPAMatrixEntry[]>>;
}

export default function AdjustmentCell({ entry, setEntries }: AdjustmentCellProps) {
    const [ anchorEl, setAnchorEl ] = useState<HTMLTableHeaderCellElement | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const cellRef = useRef<HTMLTableHeaderCellElement>(null);

    const { adjustment } = entry;
    const [ value, setValue ] = useState(adjustment);

    return (
        <ActionsCell
            component="td"
            align="center"
            showActions={!!anchorEl}
            ref={cellRef}
            actions={(
                <Tooltip title="Edit cell">
                    <IconButton
                        size="small"
                        onClick={() => {
                            setAnchorEl(cellRef.current);
                            setTimeout(() => {
                                inputRef.current?.focus();
                                inputRef.current?.select();
                            }, 0);
                        }}
                    >
                        <Edit
                            color="secondary"
                            fontSize="small"
                        />
                    </IconButton>
                </Tooltip>
            )}
        >
            {adjustment}{adjustment ? '%' : '--'}

            <Popover
                open={!!anchorEl}
                onClose={() => {
                    setAnchorEl(null);

                    const asFloat = parseFloat(value);

                    updateMatrixEntry(entry, setEntries, isNaN(asFloat)
                        ? value !== '' ? 'ERR' : ''
                        : asFloat.toFixed(3));
                }}
                anchorEl={anchorEl}
                keepMounted
                classes={{
                    paper: styles.editCellPopover
                }}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'center',
                    vertical: 'top'
                }}
            >
                <MuiTextField
                    name="priceAdjustment"
                    label="Price adjustment"
                    size="small"
                    inputRef={inputRef}
                    value={value}
                    onChange={(event) => setValue(event.target.value)}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Popover>
        </ActionsCell>
    );
}
