import api, {
    AgencyApproval,
    ChecklistStepType,
    CustomerChecklistStep,
    Document,
    InvestorRelationship,
    MICompanyRelationship,
    WarehouseRelationship
} from '@api';
import {
    AssignmentTurnedIn, ExpandLess, ExpandMore, NoteAdd, Save
} from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { FileInput, LinkedFile, TextField } from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { Dispatch, SetStateAction, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { AgencyApprovalCard } from './AgencyApprovalCard';
import styles from './ChecklistItemCard.module.scss';
import { InsuranceRelationshipsCard } from './InsuranceRelationshipsCard';
import { InvestorRelationshipsCard } from './InvestorRelationshipsCard';
import { SpecialChecklistSection } from './SpecialChecklistSection';
import { WarehouseRelationshipsCard } from './WarehouseRelationshipsCard';


const acceptedFileTypes = [ 'pdf' ];

export interface ChecklistStepFormValues extends CustomerChecklistStep {
    agencies: AgencyApproval[];
    investors: InvestorRelationship[];
    warehouses: WarehouseRelationship[];
    insurances: MICompanyRelationship[];
    documents: Document[];
}

interface ChecklistItemCardProps {
    checklistStep: CustomerChecklistStep;
    onSave: (step: CustomerChecklistStep) => void;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

const {
    AGENCY, INVESTORS, WAREHOUSE, INSURANCE
} = ChecklistStepType;

export default function ChecklistItemCard({ checklistStep, onSave, setLoading }: ChecklistItemCardProps) {
    const {
        id, name, description, isDocRequired, documents: initialDocs, isRequired, customerId, isCompleted, type
    } = checklistStep;
    const [ expanded, setExpanded ] = useState(false);
    const [ documents, setDocuments ] = useState<Document[]>(initialDocs ?? []);

    const [ showDocuments, setShowDocuments ] = useState(isDocRequired);

    const formMethods = useForm({ defaultValues: generateDefaultValues(checklistStep) });
    const pageMessage = usePageMessage();

    const handleSave = formMethods.handleSubmit(async formData => {
        setLoading(true);

        try {
            onSave(await api.customer.checklist.updateChecklistStep(`${customerId}`, formData));

            pageMessage.success('Checklist item saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the checklist item', error);
        }

        setLoading(false);
    });

    const handleComplete = formMethods.handleSubmit(async formData => {
        setLoading(true);

        try {
            onSave(await api.customer.checklist.updateChecklistStep(`${customerId}`, {
                ...formData,
                isCompleted: true
            }));

            pageMessage.success('Checklist item completed');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while marking the checklist item as completed', error);
        }

        setLoading(false);
    });

    async function uploadFiles(newFiles: File[]) {
        setLoading(true);

        const fd = new FormData();
        newFiles.forEach((file, index) => fd.append(`file-upload-${index}`, file));

        try {
            const newDocs = await api.customer.checklist.uploadChecklistStepDocument(`${customerId}`, id, fd);

            setDocuments(documents.concat(newDocs));

            pageMessage.success('Files uploaded');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while uploading documents', error);
        }

        setLoading(false);
    }

    async function removeFile(removedFile: File) {
        const docID = documents.find(doc => doc.name === removedFile.name)?.id;

        if (docID) {
            setLoading(false);

            try {
                await api.customer.checklist.removeChecklistStepDocument(`${customerId}`, id, docID);

                setDocuments(documents.filter(doc => doc.id !== docID));

                pageMessage.success('File deleted');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deleting the file', error);
            }

            setLoading(false);
        }
    }

    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            <div className={styles.mainRow}>
                <div>
                    <Typography>
                        {`${name}${isRequired ? ' *' : ''}`}
                    </Typography>

                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                        {description}
                    </Typography>
                </div>

                <Tooltip title={`${expanded ? 'Hide' : 'Show'} details`}>
                    <IconButton
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? (
                            <ExpandLess color="secondary" />
                        ) : (
                            <ExpandMore color="secondary" />
                        )}
                    </IconButton>
                </Tooltip>
            </div>

            {expanded && (
                <form
                    noValidate
                    id="checklist-item-form"
                    onSubmit={handleSave}
                    className={styles.expandedRow}
                >
                    <FormProvider {...formMethods}>
                        {type === AGENCY && (
                            <SpecialChecklistSection
                                arrayName="agencies"
                                title="Agency approvals"
                                addButtonLabel="Add approval"
                                SpecialChecklistCard={AgencyApprovalCard}
                            />
                        )}

                        {type === INVESTORS && (
                            <SpecialChecklistSection
                                arrayName="investors"
                                title="Investor relationships"
                                addButtonLabel="Add relationship"
                                SpecialChecklistCard={InvestorRelationshipsCard}
                            />
                        )}

                        {type === WAREHOUSE && (
                            <SpecialChecklistSection
                                arrayName="warehouses"
                                title="Warehouse relationships"
                                addButtonLabel="Add relationship"
                                SpecialChecklistCard={WarehouseRelationshipsCard}
                            />
                        )}

                        {type === INSURANCE && (
                            <SpecialChecklistSection
                                arrayName="insurances"
                                title="Insurance relationships"
                                addButtonLabel="Add relationship"
                                SpecialChecklistCard={InsuranceRelationshipsCard}
                            />
                        )}

                        {showDocuments && (
                            <>
                                <Typography color="textSecondary">
                                    Documents {isDocRequired && '*'}
                                </Typography>

                                <FileInput
                                    className={styles.fileInput}
                                    title="document"
                                    files={documents.map(
                                        doc => new LinkedFile([], doc.name, undefined, doc.url)
                                    ) ?? []}
                                    acceptedFileTypes={acceptedFileTypes}
                                    onAddFiles={uploadFiles}
                                    onRemoveFile={removeFile}
                                    compact
                                />
                            </>
                        )}

                        <div className={styles.notesContainer}>
                            <TextField<ChecklistStepFormValues>
                                name="notes"
                                label="Notes"
                                multiline
                            />

                            <div className={styles.buttonContainer}>
                                {!isDocRequired && !showDocuments && (
                                    <Tooltip
                                        title="Attach documents (optional)"
                                        onClick={() => setShowDocuments(true)}
                                        enterDelay={0}
                                    >
                                        <IconButton>
                                            <NoteAdd />
                                        </IconButton>
                                    </Tooltip>
                                )}

                                <Tooltip
                                    title="Save"
                                    enterDelay={0}
                                >
                                    <IconButton
                                        form="checklist-item-form"
                                        type="submit"
                                    >
                                        <Save color="secondary" />
                                    </IconButton>
                                </Tooltip>

                                {!isCompleted && (
                                    <Tooltip
                                        title="Mark as completed"
                                        enterDelay={0}
                                    >
                                        <IconButton onClick={handleComplete}>
                                            <AssignmentTurnedIn color="success" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    </FormProvider>
                </form>
            )}
        </Paper>
    );
}

function generateDefaultValues(checklistStep: CustomerChecklistStep): ChecklistStepFormValues {
    return {
        ...checklistStep,
        completedDate: new Date(checklistStep.completedDate).toLocaleDateString(),
        agencies: getAgenciesDefaultValues(checklistStep.agencies),
        investors: checklistStep.investors ?? [],
        warehouses: checklistStep.warehouses ?? [],
        insurances: checklistStep.insurances ?? [],
        documents: checklistStep.documents ?? []
    };
}

function getAgenciesDefaultValues(agencies: AgencyApproval[] | undefined): AgencyApproval[] {
    return agencies?.map(agency => ({
        ...agency,
        firstApprovalDate: agency.firstApprovalDate ? new Date(agency.firstApprovalDate).toLocaleDateString() : '',
        latestApprovalDate: new Date(agency.latestApprovalDate).toLocaleDateString()
    })) ?? [];
}
