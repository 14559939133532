import { HighLevelGuideline, Operator } from '@api';
import { HelpOutline } from '@mui/icons-material';
import { Button, Tooltip, Typography } from '@mui/material';
import { ConditionalProps } from '@tsp-ui/core/utils';
import { useContext } from 'react';
import {
    FieldValues, UseFieldArrayAppend, UseFieldArrayRemove, useFieldArray
} from 'react-hook-form';

import { EligibilityGuidelinesContext } from './EditEligibilityGuidelinesDialog';
import { EditHighLevelGuidelineCard, ViewHighLevelGuidelineCard } from './HighLevelGuidelineCard';
import styles from './HighLevelGuidelines.module.scss';


type HighLevelGuidelinesBaseProps = {
    highLevelGuidelines: HighLevelGuideline[];
} & ConditionalProps<
    { readOnly: true },
    {
        readOnly?: false;
        append: UseFieldArrayAppend<FieldValues>;
        remove: UseFieldArrayRemove;
        nameBase: string;
    }
>;

function HighLevelGuidelinesBase({
    readOnly, append, highLevelGuidelines, remove, nameBase
}: HighLevelGuidelinesBaseProps) {
    return (
        <>
            <Typography
                color="textSecondary"
                variant="caption"
                className={styles.highLevelLabel}
            >
                High level guidelines

                <Tooltip
                    title="High level guidelines apply to all rows in the matrix. The columns for high
                                        level guidelines will not be visible in the matrix unless they are deleted."
                >
                    <HelpOutline
                        color="primary"
                        fontSize="small"
                    />
                </Tooltip>

                {!readOnly && (
                    <Button
                        className={styles.addButton}
                        onClick={() => append?.({
                            operator: Operator.EQUALS
                        } as HighLevelGuideline)}
                    >
                        Add high level guideline
                    </Button>
                )}
            </Typography>

            {highLevelGuidelines.length ? (
                <div className={styles.highLevelContainer}>
                    {highLevelGuidelines.map((highLevelGuideline, index) => (readOnly ? (
                        <ViewHighLevelGuidelineCard
                            key={highLevelGuideline.id}
                            highLevelGuideline={highLevelGuideline}
                        />
                    ) : (
                        <EditHighLevelGuidelineCard
                            index={index}
                            fieldName={`${nameBase}.${index}`}
                            key={highLevelGuideline.id}
                            remove={remove!}
                        />
                    )))}
                </div>
            ) : (
                <Typography variant="body2">
                    There are no high level guidelines for this matrix
                </Typography>
            )}
        </>
    );
}

interface EditHighLevelGuidelinesProps {
    name: string;
}

export function EditHighLevelGuidelines({ name }: EditHighLevelGuidelinesProps) {
    const { fields, append, remove } = useFieldArray({ name });

    return (
        <HighLevelGuidelinesBase
            highLevelGuidelines={fields as HighLevelGuideline[]}
            append={append}
            remove={remove}
            nameBase={name}
        />
    );
}

export function ViewHighLevelGuidelines() {
    const { highLevelGuidelines } = useContext(EligibilityGuidelinesContext)?.eligibilityGuidelineSet || {};

    return (
        <HighLevelGuidelinesBase
            highLevelGuidelines={highLevelGuidelines || []}
            readOnly
        />
    );
}
