import { apiUtils } from '../api-utils';


export function getLockAvailabilities(customerID: string | undefined): Promise<LockAvailability[]> {
    return apiUtils.get(getAvailabilityURL(customerID));
}

export function updateLockAvailability(
    lockAvailability: LockAvailability, customerID: string | undefined
): Promise<LockAvailability> {
    return apiUtils.put(`${getAvailabilityURL(customerID)}/${lockAvailability.lockAvailabilityId}`, lockAvailability);
}

export function getLockAvailabilityHolidays(customerID: string | undefined): Promise<LockAvailabilityHoliday[]> {
    return apiUtils.get(getAvailabilityURL(customerID, true));
}

export function createLockAvailabilityHoliday(
    lockAvailabilityHoliday: Omit<LockAvailabilityHoliday, 'id'>, customerID: string | undefined
): Promise<LockAvailabilityHoliday> {
    return apiUtils.post(getAvailabilityURL(customerID, true), lockAvailabilityHoliday);
}

export function updateLockAvailabilityHoliday(
    lockAvailabilityHoliday: LockAvailabilityHoliday, customerID: string | undefined
): Promise<LockAvailabilityHoliday> {
    return apiUtils.put(`${getAvailabilityURL(customerID, true)}/${lockAvailabilityHoliday.lockAvailabilityHolidayId}`, lockAvailabilityHoliday);
}

export function deleteLockAvailabilityHoliday(
    lockAvailabilityHolidayID: number, customerID: string | undefined
): Promise<LockAvailabilityHoliday> {
    return apiUtils.delete(`${getAvailabilityURL(customerID, true)}/${lockAvailabilityHolidayID}`);
}

function getAvailabilityURL(customerID: string | undefined, holiday?: true) {
    return `${customerID ? `/customer/${customerID}/` : '/client/'}lockAvailability${holiday ? 'Holiday' : ''}`;
}

export type Range<TMax extends number> = _Range<TMax>;

type _Range<TMax extends number, TMinArray extends unknown[] = []> = TMinArray['length'] extends TMax
    ? TMinArray[number]
    : _Range<TMax, [TMinArray['length'], ...TMinArray]>;

export type TimeString = `${Range<24> | `0${Range<10>}`}:${Range<60> | `0${Range<10>}`}`;

export interface LockAvailability {
    lockAvailabilityId: number;
    isSuspended: boolean;
    isSuspendedUntilReprice: boolean;
    suspendedComments: string | null;
    isMondayAvailable: boolean;
    mondayStart: TimeString | null;
    mondayEnd: TimeString | null;
    isTuesdayAvailable: boolean;
    tuesdayStart: TimeString | null;
    tuesdayEnd: TimeString | null;
    isWednesdayAvailable: boolean;
    wednesdayStart: TimeString | null;
    wednesdayEnd: TimeString | null;
    isThursdayAvailable: boolean;
    thursdayStart: TimeString | null;
    thursdayEnd: TimeString | null;
    isFridayAvailable: boolean;
    fridayStart: TimeString | null;
    fridayEnd: TimeString | null;
    isSaturdayAvailable: boolean;
    saturdayStart: TimeString | null;
    saturdayEnd: TimeString | null;
    isSundayAvailable: boolean;
    sundayStart: TimeString | null;
    sundayEnd: TimeString | null;
}

export interface LockAvailabilityHoliday {
    lockAvailabilityHolidayId: number;
    lockAvailabilityDate: string;
    isAvailable: boolean;
    lockStart: TimeString | null;
    lockEnd: TimeString | null;
    description: string;
}
