import { PermissionType, apiUtils } from '../api-utils';
import { UserType } from '../enums/userTypeEnum';


export function fetchAuthUser(): Promise<AuthUser> {
    return apiUtils.get('/auth/me');
}

export function fetchClientToken(clientID: string): Promise<AuthResult> {
    return apiUtils.get(`/auth/client/${clientID}`);
}

export interface AuthUser {
    firstName: string;
    lastName: string;
    email: string;
    isInternal: boolean;
    internalPermissions?: PermissionType[];
    clientAccounts?: ClientAccount[];
    customerAccounts?: CustomerAccount[];
}

export interface ClientAccount {
    id: string;
    name: string;
    logoUrl: string;
}

interface CustomerAccount extends ClientAccount {
    customerId: string;
    customerName: string;
}

export type Account = ClientAccount & Partial<CustomerAccount> & {
    accountUserType: UserType;
};

export interface AuthResult {
    accessToken: string;
    tokenType?: 'Bearer'; // TODO add the other options here, or remove if unnecessary
}
