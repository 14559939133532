import { Button, DialogContent } from '@mui/material';
import { DialogActions, RoutedDialog } from '@tsp-ui/core/components';
import { capitalize, useParams } from '@tsp-ui/core/utils';
import { useState } from 'react';

import LockAvailabilityForm from './LockAvailabilityForm';
import { Day } from './LockAvailabilityPage';


export function EditLockAvailabilityDialog() {
    const { day } = useParams<{day: Day}>();
    const [ loading, setLoading ] = useState(false);

    return (
        <RoutedDialog
            title={`${capitalize(day)} lock availability`}
            maxWidth={false}
            closeTo="../"
            loading={loading}
        >
            <DialogContent>
                <LockAvailabilityForm setLoading={setLoading} />
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    type="submit"
                    form={LockAvailabilityForm.formID}
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
