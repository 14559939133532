import { Check, Edit, RemoveCircleOutline } from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import {
    LabelGroup, LabeledValue, PhoneField, PhoneTypography, TextField, isEmail
} from '@tsp-ui/core/components';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { ChecklistStepFormValues } from './ChecklistItemCard';
import styles from './InvestorRelationshipsCard.module.scss';
import { SpecialChecklistCardProps } from './SpecialChecklistSection';


type BaseFieldName = `insurances.${number}`

export function InsuranceRelationshipsCard({ onRemove, index }: SpecialChecklistCardProps) {
    const [ editing, setEditing ] = useState(false);

    const baseFieldName: BaseFieldName = `insurances.${index}`;

    const formMethods = useFormContext<ChecklistStepFormValues>();

    const [
        insurerName, policyNumber, contact, phone, emailAddress
    ] = formMethods.watch([
        `${baseFieldName}.insurerName`, `${baseFieldName}.policyNumber`, `${baseFieldName}.contact`, `${baseFieldName}.phone`, `${baseFieldName}.emailAddress`
    ]);

    const handleSubmit = formMethods.handleSubmit(() => {
        setEditing(!editing);
    });

    useEffect(() => {
        if (!insurerName) {
            setEditing(true);
        }
    }, [ insurerName ]);

    return (
        <Paper
            className={styles.root}
            variant="outlined"
        >
            {editing ? (
                <>
                    <div className={styles.formContent}>
                        <TextField<ChecklistStepFormValues>
                            name={`${baseFieldName}.insurerName`}
                            label="Insurer name"
                            size="small"
                            variant="outlined"
                            required
                        />

                        <TextField<ChecklistStepFormValues>
                            name={`${baseFieldName}.policyNumber`}
                            label="Policy number"
                            size="small"
                            variant="outlined"
                            required
                        />

                        <TextField<ChecklistStepFormValues>
                            name={`${baseFieldName}.contact`}
                            label="Contact name"
                            size="small"
                            variant="outlined"
                            required
                        />

                        <PhoneField<ChecklistStepFormValues>
                            name={`${baseFieldName}.phone`}
                            label="Phone #"
                            size="small"
                            variant="outlined"
                            required
                        />

                        <TextField<ChecklistStepFormValues>
                            name={`${baseFieldName}.emailAddress`}
                            label="Email address"
                            size="small"
                            variant="outlined"
                            required
                            rules={isEmail}
                        />
                    </div>

                    <div className={styles.buttonContainer}>
                        <Tooltip
                            title="Done editing"
                            enterDelay={0}
                        >
                            <IconButton onClick={handleSubmit}>
                                <Check color="success" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title="Delete relationship"
                            enterDelay={0}
                        >
                            <IconButton
                                onClick={onRemove}
                                edge="end"
                            >
                                <RemoveCircleOutline color="error" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.displayHeader}>
                        <Typography>
                            {insurerName}
                        </Typography>

                        <div>
                            <Tooltip
                                title="Edit relationship"
                                enterDelay={0}
                            >
                                <IconButton onClick={handleSubmit}>
                                    <Edit color="primary" />
                                </IconButton>
                            </Tooltip>

                            <Tooltip
                                title="Delete relationship"
                                enterDelay={0}
                            >
                                <IconButton
                                    onClick={onRemove}
                                    edge="end"
                                >
                                    <RemoveCircleOutline color="error" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>

                    <LabelGroup>
                        <LabeledValue
                            label="Policy number:"
                            value={policyNumber}
                        />

                        <LabeledValue
                            label="Contact name:"
                            value={contact}
                        />

                        <LabeledValue
                            label="Phone #:"
                            value={(
                                <PhoneTypography variant="body2">
                                    {phone}
                                </PhoneTypography>
                            )}
                        />

                        <LabeledValue
                            label="Email:"
                            value={emailAddress}
                        />
                    </LabelGroup>
                </>
            )}
        </Paper>
    );
}
