import { ReferenceGuideFile } from '@api';
import { Download } from '@mui/icons-material';
import {
    IconButton, Link as MuiLink, Paper, Tooltip, Typography
} from '@mui/material';
import { DateTypography } from '@tsp-ui/core/components';
import { Link } from 'react-router-dom';

import styles from './CustomerReferenceGuideCard.module.scss';


interface CustomerReferenceGuideCardProps {
    referenceGuideFile: ReferenceGuideFile;
}

export function CustomerReferenceGuideCard({
    referenceGuideFile: {
        url, title, description, createdAt
    }
}: CustomerReferenceGuideCardProps) {
    return (
        <Paper
            variant="outlined"
            className={styles.card}
        >
            <div>
                <MuiLink
                    href={url}
                    rel="noreferrer noopener"
                    target="_blank"
                >
                    {title}
                </MuiLink>

                <br />

                <Typography variant="caption">
                    {description}
                </Typography>
            </div>

            <div>
                <DateTypography
                    variant="caption"
                    date={createdAt}
                />

                <div className={styles.buttons}>
                    <Tooltip title="Download file">
                        <IconButton // TODO fix direct download
                            component={Link}
                            target="_blank"
                            to={url}
                            download
                        >
                            <Download color="secondary" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </Paper>
    );
}
