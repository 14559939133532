import { AppBar, Toolbar, Typography } from '@mui/material';

import styles from './UnauthedHeader.module.scss';


export default function UnauthedHeader() {
    return (
        <AppBar
            position="static"
            className={styles.root}
        >
            <Toolbar>
                <Typography
                    variant="h4"
                    component="h1"
                >
                    Premicorr
                </Typography>
            </Toolbar>
        </AppBar>
    );
}
