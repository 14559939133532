import { useAsyncEffect, useConfirm } from '@tsp-ui/core/utils';
import { useCallback, useEffect, useState } from 'react';

import { AppraisalUnderwritingTabs } from '../../AppraisalUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';


interface AppraisalWaiverSectionProps {
    setTab: (tab: AppraisalUnderwritingTabs) => void;
    onTabComplete: (tab: AppraisalUnderwritingTabs, nextTab: AppraisalUnderwritingTabs) => void;
}

export default function AppraisalWaiverSection({ onTabComplete }: AppraisalWaiverSectionProps) {
    const [ hasWaiver, setHasWaiver ] = useState<boolean>();
    const [ hasAppraisal, setHasAppraisal ] = useState<boolean>();

    // When onTabComplete is called, the reference to it is updated, which causes the effect that uses onTabComplete
    // as a dependency to fire again, which shows the confirm modal again. This flag prevents the duplicate confirm.
    const [ disableConfirmEffect, setDisableConfirmEffect ] = useState<boolean>();
    const confirm = useConfirm();

    useAsyncEffect(useCallback(async () => {
        if (hasAppraisal === false && !disableConfirmEffect) {
            setDisableConfirmEffect(true);

            // TODO figure out consistent verbiage...custom modal?
            if (await confirm('Would you like to suspend the file?')) {
                onTabComplete('waiver', 'summary');
            } else {
                setHasAppraisal(undefined);
            }

            setDisableConfirmEffect(false);
        }
    }, [
        hasAppraisal, confirm, onTabComplete, disableConfirmEffect
    ]));

    useEffect(() => {
        if (hasAppraisal) {
            onTabComplete('waiver', 'property');
        }

        if (hasWaiver) {
            onTabComplete('waiver', 'summary');
            alert('TODO mark the remaining appraisal items as "waived"');
        }
    }, [
        hasAppraisal, hasWaiver, onTabComplete
    ]);

    return (
        <UnderwritingStepSection title="Appraisal waiver">
            <UnderwritingYesNoQuestionCard
                question="Is the appraisal waived for this file?"
                onAnswered={setHasWaiver}
                answer={hasWaiver}
            />

            {hasWaiver === false && (
                <UnderwritingYesNoQuestionCard
                    question="Is the appraisal present in this file?"
                    onAnswered={setHasAppraisal}
                    answer={hasAppraisal}
                />
            )}
        </UnderwritingStepSection>
    );
}
