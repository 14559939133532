import { Button, Paper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import { SalesContractUnderwritingTabs } from '../../SalesContractUnderwritingPage';
import UnderwritingQuestionGroup from '../../UnderwritingQuestionGroup';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';

import styles from './SalesContractSpecialSaleSection.module.scss';


interface SalesContractSellerDetailsSectionProps {
    setTab: (tab: SalesContractUnderwritingTabs) => void;
    onTabComplete: (tab: SalesContractUnderwritingTabs, nextTab: SalesContractUnderwritingTabs) => void;
}

export default function SalesContractSellerDetailsSection({ onTabComplete }: SalesContractSellerDetailsSectionProps) {
    const [ numSellers, setNumSellers ] = useState(1);
    const [ showSellerQuestions, setShowSellerQuestions ] = useState(false);

    const [ sellerCompany, setSellerCompany ] = useState<boolean>();
    const [ govOrHomeBuilder, setGovOrHomeBuilder ] = useState<boolean>();
    const [ hasProof, setHasProof ] = useState<boolean>();
    const [ armsLength, setArmsLength ] = useState<boolean>();
    const [ hasGifts, setHasGifts ] = useState<boolean>();

    useEffect(() => {
        if (hasGifts !== undefined) {
            onTabComplete('seller-details', 'summary');
        }
    }, [ hasGifts, onTabComplete ]);

    return (
        <UnderwritingStepSection title="Seller details">
            <Paper
                variant="outlined"
                className={styles.paper}
            >
                <div className={styles.textFields}>
                    <TextField label="Selling agent" />

                    <TextField label="Listing agent" />

                    {Array(numSellers).fill(undefined).map((_, index) => (
                        <TextField label={`Property seller ${index + 1}`} />
                    ))}

                    <Button
                        className={styles.addSeller}
                        onClick={() => setNumSellers(numSellers + 1)}
                    >
                        Add seller
                    </Button>
                </div>

                <Button
                    variant="contained"
                    className={styles.button}
                    onClick={() => setShowSellerQuestions(true)}
                >
                    Next
                </Button>
            </Paper>

            {showSellerQuestions && (
                <UnderwritingQuestionGroup>
                    <UnderwritingYesNoQuestionCard
                        question="Is the seller a company?"
                        onAnswered={setSellerCompany}
                        answer={sellerCompany}
                    />

                    {sellerCompany !== undefined && (
                        <UnderwritingYesNoQuestionCard
                            question="Is the seller a government agency or home builder?"
                            onAnswered={setGovOrHomeBuilder}
                            answer={govOrHomeBuilder}
                        />
                    )}

                    {govOrHomeBuilder !== undefined && (
                        <UnderwritingYesNoQuestionCard
                            question="Does the file contain proof of the individual corporate owners?"
                            onAnswered={setHasProof}
                            answer={hasProof}
                        />
                    )}

                    {hasProof !== undefined && (
                        <UnderwritingYesNoQuestionCard
                            question="Is this an arms length transaction?"
                            onAnswered={setArmsLength}
                            answer={armsLength}
                        />
                    )}

                    {armsLength !== undefined && (
                        <UnderwritingYesNoQuestionCard
                            question="Does contract show any gifts from seller to buyer?"
                            onAnswered={setHasGifts}
                            answer={hasGifts}
                        />
                    )}
                </UnderwritingQuestionGroup>
            )}
        </UnderwritingStepSection>
    );
}
