import api, { AuthResult } from '@api';
import { EXPIRATION_DATE_OFFSET, createApiUtils, getCognitoToken } from '@tsp-ui/core/utils';
import { isAfter } from 'date-fns';
import decodeJWT from 'jwt-decode';


let clientTokenPromise: Promise<AuthResult> | null = null;
let clientToken: string;

export const apiUtils = {
    ...createApiUtils({
        getAuthToken,
        getRequestUrl: (url, options, config) => config?.apiUrl + url
    }),

    setClientToken(newClientToken: string) {
        clientToken = newClientToken;
    },

    getClientToken() {
        return clientToken;
    },

    async decodeClientToken() {
        return decodeClientToken(await getAuthToken());
    }
};

async function getAuthToken(url?: string): Promise<string> {
    if (!clientToken || url?.includes('/auth')) {
        return await getCognitoToken();
    } else {
        const { exp, client_id: clientID } = decodeClientToken(clientToken);
        const expiration = new Date(exp * 1000);

        return isAfter(new Date(Date.now() + EXPIRATION_DATE_OFFSET), expiration)
            ? await refreshClientToken(clientID)
            : clientToken;
    }
}

export async function refreshClientToken(clientID: string) {
    if (!clientTokenPromise) {
        console.log('Refreshing client auth token');
        clientTokenPromise = api.auth.fetchClientToken(clientID);
    }

    try {
        const { tokenType = 'Bearer', accessToken } = await clientTokenPromise;
        clientToken = `${tokenType} ${accessToken}`;
    } finally {
        clientTokenPromise = null;
    }

    return clientToken;
}

export interface ClientTokenPayload {
    sub: string;
    user_id: string;
    client_id: string;
    email: string;
    exp: number;
    iss: string;
    aud: string;
    permission: PermissionType[];
    given_name: string;
    family_name: string;
}

export enum PermissionType {
    CREATE_LOAN = 'CREATE_LOAN',
    CREATE_PREMPACK = 'CREATE_PREMPACK',
    CREATE_USER = 'CREATE_USER',
    CREATE_ROLE = 'CREATE_ROLE',
    CREATE_LOAN_TEAM = 'CREATE_LOAN_TEAM',
    VIEW_LOAN = 'VIEW_LOAN',
    VIEW_ACTIVITY_HISTORY = 'VIEW_ACTIVITY_HISTORY',
    VIEW_DOCS = 'VIEW_DOCS',
    VIEW_USER = 'VIEW_USER',
    VIEW_ROLE = 'VIEW_ROLE',
    VIEW_RPT_DASH = 'VIEW_RPT_DASH',
    VIEW_PRICING = 'VIEW_PRICING',
    VIEW__RATES = 'VIEW_RATES',
}

function decodeClientToken(token: string): ClientTokenPayload {
    if (token === 'Bearer mockToken') { // TODO post-demo add development check
        /* eslint-disable camelcase */
        return {
            sub: '1',
            user_id: '',
            client_id: '1',
            email: 'email@email.com',
            exp: 9999999999,
            iss: '',
            aud: '',
            permission: Object.values(PermissionType),
            given_name: 'First',
            family_name: 'Last'
        };
        /* eslint-enable camelcase */
    }

    return decodeJWT(token.split(' ')[1]);
}
