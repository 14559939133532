import { CustomerFee } from '@api';
import { CardTable } from '@tsp-ui/core/components';

import { feesTableHeaders } from '../CompensationDialogForm';
import CustomerFeeTableRow from '../CustomerFeeTableRow';

import styles from './FeeCard.module.scss';


interface FeeCardProps {
    fee: CustomerFee;
}

export default function FeeCard({ fee }: FeeCardProps) {
    return (
        <CardTable
            headers={feesTableHeaders}
            className={styles.noBorder}
        >
            <CustomerFeeTableRow
                fee={fee}
                index={0}
                readOnly
            />
        </CardTable>
    );
}
