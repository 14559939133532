import { TypographyVariant } from '@mui/material';
import { LabelGroup, LabeledValue } from '@tsp-ui/core/components';


interface CustomerDetailsProps {
    dba?: string;
    nmlsID: string;
    taxID?: string;
    variant?: TypographyVariant;
}

export default function CustomerDetails({
    variant, dba, nmlsID, taxID
}: CustomerDetailsProps) {
    return (
        <LabelGroup>
            {dba && (
                <LabeledValue
                    label="D/B/A:"
                    value={dba}
                    variants={{ value: variant }}
                />
            )}

            <LabeledValue
                label="NMLS ID:"
                value={nmlsID}
                variants={{ value: variant }}
            />

            {taxID && (
                <LabeledValue
                    label="Tax ID:"
                    value={taxID}
                    variants={{ value: variant }}
                />
            )}
        </LabelGroup>
    );
}

CustomerDetails.defaultProps = {
    variant: 'caption'
};
