import { Investor } from '@api';
import { Button, Tooltip, Typography } from '@mui/material';
import { useCreateAccountUrl } from '@utils/hooks';
import { Link } from 'react-router-dom';

import styles from './InvestorChip.module.scss';


interface InvestorChipProps {
    investor: Investor;
}

export function InvestorChip({ investor: { code, id, name } }: InvestorChipProps) {
    const createAccountUrl = useCreateAccountUrl();

    return (
        <Tooltip title={name}>
            <Button
                component={Link}
                to={createAccountUrl(`admin/investor-eligibility/investors/${id}`)}
                variant="outlined"
                className={styles.root}
            >
                <Typography
                    variant="body2"
                    color="inherit"
                >
                    {code}
                </Typography>
            </Button>
        </Tooltip>
    );
}
