import { CurrencyField, DateField, isAtLeast } from '@tsp-ui/core/components';
import clsx from 'clsx';
import { useWatch } from 'react-hook-form';

import { EligibleProductsFormValues } from '../EligibleProductsDialog';

import styles from './LimitFields.module.scss';
import { ProductCardFieldPath } from './ProductCard';
import { ProductTypeCardFieldPath } from './ProductTypeCard';
import { GenerateMaxFunction, UnderwritingCardFieldPath } from './UnderwritingTypeCard';


type BaseFieldPath = `${UnderwritingCardFieldPath | ProductTypeCardFieldPath | ProductCardFieldPath}.limit` | 'allProductsLimit';

interface LimitFieldsProps {
    label: string;
    baseFieldPath: BaseFieldPath;
    className?: string;
    compact?: boolean;
    generateMax?: GenerateMaxFunction;
}

export function LimitFields({
    label, baseFieldPath, className, compact, generateMax
}: LimitFieldsProps) {
    const expiration = !!useWatch<EligibleProductsFormValues>({ name: `${baseFieldPath}.expirationDate` });
    const individualLimit = parseInt(useWatch<EligibleProductsFormValues>({ name: `${baseFieldPath}.limitAmount` }) as string);

    return (
        <div className={clsx(styles.limitFields, className)}>
            <CurrencyField<EligibleProductsFormValues>
                name={`${baseFieldPath}.limitAmount`}
                label={`${label} limit`}
                size={compact ? 'small' : undefined}
                variant={compact ? 'outlined' : undefined}
                required={expiration}
                hideHelperTextOnBlur
                rules={{
                    ...isAtLeast(0),
                    max: generateMax?.(individualLimit),
                    required: {
                        value: expiration,
                        message: 'Enter limit or remove expiration'
                    }
                }}
            />

            <DateField<EligibleProductsFormValues>
                name={`${baseFieldPath}.expirationDate`}
                label="Expiration"
                size={compact ? 'small' : undefined}
                variant={compact ? 'outlined' : undefined}
            />
        </div>
    );
}
