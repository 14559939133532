import api, { BulkCommitment } from '@api';
import { Edit, Lock, Schedule } from '@mui/icons-material';
import {
    Button, Checkbox, FormControlLabel, MenuItem, Link as MuiLink, Paper, Popover, TextField, Tooltip, Typography
} from '@mui/material';
import {
    CardTable, ExpandableHeader, FilterTextField, IconButton, IconTypography, RoutedDialogManager
} from '@tsp-ui/core/components';
import {
    formatCurrency,
    formatCurrencyAbbreviation,
    useAsyncEffect,
    usePageMessage
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import BulkCommitmentDialog from '@views/admin/bulk-commitment/components/BulkCommitmentDialog';
import Page from '@views/components/Page';
import clsx from 'clsx';
import {
    formatDistanceToNowStrict, isAfter, isBefore, parseISO
} from 'date-fns';
import {
    Dispatch, SetStateAction, createContext, useCallback, useMemo, useState
} from 'react';
import { Link } from 'react-router-dom';

import styles from './BulkCommitmentManagementPage.module.scss';


interface CommitmentsContextValue {
    commitments: BulkCommitment[] | undefined,
    setCommitments: Dispatch<SetStateAction<BulkCommitment[] | undefined>>
    loading: boolean;
}

export const CommitmentsContext = createContext<CommitmentsContextValue>({
    commitments: undefined,
    setCommitments: () => {},
    loading: false
});

export default function BulkCommitmentManagementPage() {
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(true);
    const [ commitments, setCommitments ] = useState<BulkCommitment[]>();

    useAsyncEffect(useCallback(async () => {
        try {
            setLoading(true);
            setCommitments(await api.commitment.getCommitments(clientId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching bulk commitments', error);
        } finally {
            setLoading(false);
        }
    }, [ pageMessage, clientId ]));

    const [ customerId, setCustomerId ] = useState(0);
    const [ filterText, setFilterText ] = useState('');
    const [ showPastDeliveries, setShowPastDeliveries ] = useState(false);

    const commitmentsByCustomerId = commitments
        ?.filter(({ id, deliveryExpiration }) => (
            id.toString().includes(filterText) && (
                showPastDeliveries || isAfter(parseISO(deliveryExpiration), new Date())
            )
        ))
        .reduce((value, commitment) => {
            if (!customerId || commitment.customerId === customerId) {
                value[commitment.customerId] = [
                    ...(value[commitment.customerId] || []),
                    commitment
                ];
            }

            return value;
        }, {} as { [commitmentId: number]: BulkCommitment[]});

    const contextValue = useMemo(() => ({
        loading,
        commitments,
        setCommitments
    }), [
        loading, commitments, setCommitments
    ]);

    return (
        <Page
            header="Bulk Commitment Management"
            loading={loading}
            headerActions={(
                <>
                    <Button
                        component={Link}
                        to="../../tracking/bulk-commitments"
                    >
                        View bulk commitment tracking
                    </Button>

                    <Button
                        component={Link}
                        to="add"
                        variant="contained"
                    >
                        Add commitment
                    </Button>
                </>
            )}
        >
            <div className={styles.filters}>
                <TextField
                    select
                    variant="standard"
                    label="Customer"
                    value={customerId}
                    onChange={(event) => setCustomerId(parseInt(event.target.value))}
                >
                    <MenuItem value={0}>
                        -- All --
                    </MenuItem>

                    <MenuItem value={1000}>
                        ABC Mortgage
                    </MenuItem>

                    <MenuItem value={1001}>
                        Pending Bank
                    </MenuItem>
                </TextField>

                <FilterTextField
                    autoFocus
                    placeholder="Filter commitments"
                    helperText="Filter by commitment id"
                    value={filterText}
                    onChange={(event) => setFilterText(event.target.value)}
                />

                <FormControlLabel
                    className={styles.showInactive}
                    control={<Checkbox onChange={() => setShowPastDeliveries(!showPastDeliveries)} />}
                    label="Show past deliveries"
                />
            </div>

            {commitmentsByCustomerId && (
                <div className={styles.sections}>
                    {Object.entries(commitmentsByCustomerId).map(([ thisCustomerId, commitments ]) => (
                        <ExpandableHeader
                            key={customerId + thisCustomerId}
                            defaultExpand={customerId.toString() === thisCustomerId}
                            disableExpand={false}
                            title={customerNameMap[thisCustomerId]}
                            secondaryText={`${commitments.length} commitment${commitments.length === 1 ? '' : 's'}`}
                            expandedContent={(
                                <div className={styles.commitmentContainer}>
                                    {commitments.map((commitment) => (
                                        <CommitmentCard commitment={commitment} />
                                    ))}
                                </div>
                            )}
                        />
                    ))}
                </div>
            )}

            <CommitmentsContext.Provider value={contextValue}>
                <RoutedDialogManager routes={dialogRoutes} />
            </CommitmentsContext.Provider>
        </Page>
    );
}

const dialogRoutes = {
    add: BulkCommitmentDialog,
    ':commitmentID': BulkCommitmentDialog
};

const customerNameMap: { [key: string]: string } = {
    1000: 'ABC Mortgage',
    1001: 'Pending Bank'
};

const productNameMap: { [key: string]: string } = {
    1: 'Product one',
    2: 'Product two'
};

interface CommitmentCardProps {
    commitment: BulkCommitment;
}

function CommitmentCard({ commitment }: CommitmentCardProps) {
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();

    return (
        <Paper
            variant="outlined"
            className={clsx(styles.card, {
                [styles.expired]: isBefore(parseISO(commitment.deliveryExpiration), new Date())
            })}
        >
            <div className={styles.header}>
                <MuiLink
                    component={Link}
                    to={`../../tracking/bulk-commitments/${commitment.id}`}
                >
                    {commitment.id}
                </MuiLink>

                <div className={styles.chips}>
                    <Tooltip
                        title={(
                            <>
                                <div>Trade amount</div>

                                <div>{formatCurrency(commitment.tradeAmount)}</div>
                            </>
                        )}
                    >
                        <Typography
                            variant="body2"
                            className={styles.chip}
                        >
                            {formatCurrencyAbbreviation(commitment.tradeAmount)}
                        </Typography>
                    </Tooltip>

                    <Tooltip title="Trade incentive">
                        <Typography
                            variant="body2"
                            className={styles.chip}
                        >
                            {commitment.tradeIncentive.toFixed(5)}
                        </Typography>
                    </Tooltip>

                    <Tooltip title="Trade variance">
                        <Typography
                            variant="body2"
                            className={styles.chip}
                        >
                            {commitment.tradeVariance.toFixed(2)}%
                        </Typography>
                    </Tooltip>
                </div>

                <IconButton
                    component={Link}
                    to={`${commitment.id}`}
                    className={styles.iconButton}
                >
                    <Edit color="secondary" />
                </IconButton>
            </div>

            <div className={styles.datesRow}>
                <IconTypography
                    fontWeight={400}
                    variant="body2"
                    icon={(
                        <Tooltip title="Trade lock date">
                            <Lock
                                color="primary"
                                fontSize="small"
                            />
                        </Tooltip>
                    )}
                >
                    {formatDistanceToNowStrict(parseISO(commitment.lockDate), {
                        addSuffix: true
                    })}
                </IconTypography>

                <IconTypography
                    fontWeight={400}
                    variant="body2"
                    icon={(
                        <Tooltip title="Delivery expiration">
                            <Schedule
                                color="primary"
                                fontSize="small"
                            />
                        </Tooltip>
                    )}
                >
                    {formatDistanceToNowStrict(parseISO(commitment.deliveryExpiration), {
                        addSuffix: true
                    })}
                </IconTypography>

                <Button
                    size="small"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                    View tiers ({commitment.pricingTiers.length})
                </Button>
            </div>

            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top'
                }}
            >
                <CardTable headers={tableHeaders}>
                    {commitment.pricingTiers.map((tier) => (
                        <tr>
                            <Typography
                                component="td"
                                variant="body2"
                            >
                                {tier.productId ? productNameMap[tier.productId] : 'All products'}
                            </Typography>

                            <Typography
                                component="td"
                                variant="body2"
                            >
                                {tier.noteRate ? `${tier.noteRate.toFixed(3)}%` : 'All note rates'}
                            </Typography>

                            <Typography
                                component="td"
                                variant="body2"
                            >
                                {tier.price.toFixed(5)}
                            </Typography>

                            <Typography
                                component="td"
                                variant="body2"
                            >
                                {tier.subLimit === undefined ? '--' : formatCurrencyAbbreviation(tier.subLimit)}
                            </Typography>
                        </tr>
                    ))}
                </CardTable>
            </Popover>
        </Paper>
    );
}

const tableHeaders = [
    'Product',
    'Note Rate',
    'Price',
    'Limit'
];
