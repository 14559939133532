import { Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';

import { FloodAndHazardUnderwritingTabs } from '../../FloodAndHazardUnderwritingPage';
import UnderwritingQuestionGroup from '../../UnderwritingQuestionGroup';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';

import styles from './FloodInsuranceSection.module.scss';


interface HazardInsuranceSectionProps {
    setTab: (tab: FloodAndHazardUnderwritingTabs) => void;
    onTabComplete: (tab: FloodAndHazardUnderwritingTabs, nextTab: FloodAndHazardUnderwritingTabs) => void;
}

export default function HazardInsuranceSection({ onTabComplete }: HazardInsuranceSectionProps) {
    const [ hasHazardInsurance, setHasHazardInsurance ] = useState<boolean>();
    const [ mortgageeCorrect, setMortgageeCorrect ] = useState<boolean>();
    const [ loanNumberCorrect, setLoanNumberCorrect ] = useState<boolean>();

    const [ showMasterInsurance, setShowMasterInsurance ] = useState(false);
    const [ hasMasterInsurance, setHasMasterInsurance ] = useState<boolean>();
    const [ masterMortgageeCorrect, setMasterMortgageeCorrect ] = useState<boolean>();
    const [ masterLoanNumberCorrect, setMasterLoanNumberCorrect ] = useState<boolean>();

    const [ hasWallsInInsurance, setHasWallsInInsurance ] = useState<boolean>();
    const [ wallsInMortgageeCorrect, setWallsInMortgageeCorrect ] = useState<boolean>();
    const [ wallsInLoanNumberCorrect, setWallsInLoanNumberCorrect ] = useState<boolean>();

    return (
        <UnderwritingStepSection title="Hazard insurance">
            <UnderwritingQuestionGroup
                otherChildren={loanNumberCorrect !== undefined && (
                    <>
                        <TextField
                            label="What is the annual premium?"
                            className={styles.textField}
                            required
                        />

                        <TextField
                            label="What is the coverage amount?"
                            className={styles.textField}
                            required
                        />

                        <Button
                            variant="contained"
                            className={styles.nextButton}
                            onClick={() => setShowMasterInsurance(true)}
                        >
                            Next
                        </Button>
                    </>
                )}
            >
                <UnderwritingYesNoQuestionCard
                    question="Is hazard insurance on file?"
                    onAnswered={setHasHazardInsurance}
                    answer={hasHazardInsurance}
                />

                {hasHazardInsurance !== undefined && (
                    <UnderwritingYesNoQuestionCard
                        question="Is the mortgagee correct?"
                        onAnswered={setMortgageeCorrect}
                        answer={mortgageeCorrect}
                    />
                )}

                {mortgageeCorrect !== undefined && (
                    <UnderwritingYesNoQuestionCard
                        question="Is the loan number correct?"
                        onAnswered={setLoanNumberCorrect}
                        answer={loanNumberCorrect}
                    />
                )}
            </UnderwritingQuestionGroup>

            {showMasterInsurance && (
                <>
                    <Typography variant="h6">
                        Master insurance
                    </Typography>

                    <UnderwritingQuestionGroup>
                        <UnderwritingYesNoQuestionCard
                            question="Is master insurance on file?"
                            onAnswered={setHasMasterInsurance}
                            answer={hasMasterInsurance}
                        />

                        {hasMasterInsurance !== undefined && (
                            <UnderwritingYesNoQuestionCard
                                question="Is the mortgagee correct?"
                                onAnswered={setMasterMortgageeCorrect}
                                answer={masterMortgageeCorrect}
                            />
                        )}

                        {masterMortgageeCorrect !== undefined && (
                            <UnderwritingYesNoQuestionCard
                                question="Is the loan number correct?"
                                onAnswered={setMasterLoanNumberCorrect}
                                answer={masterLoanNumberCorrect}
                            />
                        )}
                    </UnderwritingQuestionGroup>
                </>
            )}

            {masterLoanNumberCorrect !== undefined && (
                <>
                    <Typography variant="h6">
                        HO-6 (Walls in) insurance
                    </Typography>

                    <UnderwritingQuestionGroup
                        otherChildren={wallsInLoanNumberCorrect !== undefined && (
                            <>
                                <TextField
                                    label="What is the annual premium?"
                                    className={styles.textField}
                                    required
                                />

                                <Button
                                    variant="contained"
                                    className={styles.nextButton}
                                    onClick={() => onTabComplete('hazard', 'summary')}
                                >
                                    Next
                                </Button>
                            </>
                        )}
                    >
                        <UnderwritingYesNoQuestionCard
                            question="Is walls in insurance on file?"
                            onAnswered={setHasWallsInInsurance}
                            answer={hasWallsInInsurance}
                        />

                        {hasWallsInInsurance !== undefined && (
                            <UnderwritingYesNoQuestionCard
                                question="Is the mortgagee correct?"
                                onAnswered={setWallsInMortgageeCorrect}
                                answer={wallsInMortgageeCorrect}
                            />
                        )}

                        {wallsInMortgageeCorrect !== undefined && (
                            <UnderwritingYesNoQuestionCard
                                question="Is the loan number correct?"
                                onAnswered={setWallsInLoanNumberCorrect}
                                answer={wallsInLoanNumberCorrect}
                            />
                        )}
                    </UnderwritingQuestionGroup>
                </>
            )}
        </UnderwritingStepSection>
    );
}
