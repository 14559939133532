import {
    Permission, PermissionCategory, PermissionLevel, Role, UserType, permissionCategoryDisplay
} from '@api';
import {
    getRandomEnumValue, getRandomItemFromArray, randomBoolean, randomNum
} from '@tsp-ui/core/utils';
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';


export const mocks = [
    rest.get(getMockUrl('/auth/role'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(roles)
    ))),
    rest.get(getMockUrl('/client/role'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(roles)
    ))),
    rest.get(getMockUrl('/customer/:customerID/role'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(roles)
    ))),
    rest.get(getMockUrl('/auth/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(roles[parseInt(req.params.roleID.toString()) - 1])
    ))),
    rest.get(getMockUrl('/client/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(roles[parseInt(req.params.roleID.toString()) - 1])
    ))),
    rest.get(getMockUrl('/customer/:customerID/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(roles[parseInt(req.params.roleID.toString()) - 1])
    ))),
    rest.put(getMockUrl('/auth/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.put(getMockUrl('/client/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.put(getMockUrl('/customer/:customerID/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.delete(getMockUrl('/auth/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500)
    ))),
    rest.delete(getMockUrl('/client/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500)
    ))),
    rest.delete(getMockUrl('/customer/:customerID/role/:roleID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500)
    ))),
    rest.post(getMockUrl('/auth/role'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json({
            ...req.body as Role,
            id: roleID++,
            isCustom: true
        })
    ))),
    rest.post(getMockUrl('/client/role'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json({
            ...req.body as Role,
            id: roleID++,
            isCustom: true
        })
    ))),
    rest.post(getMockUrl('/customer/:customerID/role'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json({
            ...req.body as Role,
            id: roleID++,
            isCustom: true
        })
    ))),
    rest.get(getMockUrl('/auth/role/:roleID/permissions'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(getPermissions())
    ))),
    rest.get(getMockUrl('/client/role/:roleID/permissions'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(getPermissions())
    ))),
    rest.get(getMockUrl('/customer/:customerID/role/:roleID/permissions'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(getPermissions())
    ))),
    rest.put(getMockUrl('/auth/role/:roleID/permissions'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.put(getMockUrl('/client/role/:roleID/permissions'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.put(getMockUrl('/customer/:customerID/role/:roleID/permissions'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    )))
];

let roleID = 1;
const roles: Role[] = [
    {
        id: roleID++,
        userType: UserType.INTERNAL,
        name: 'Loan setup',
        description: 'role description',
        isCustom: false
    },
    {
        id: roleID++,
        userType: UserType.INTERNAL,
        name: 'Underwriter',
        description: 'role description',
        isCustom: false
    },
    {
        id: roleID++,
        userType: UserType.INTERNAL,
        name: 'Compliance',
        description: 'role description',
        isCustom: false
    },
    {
        id: roleID++,
        userType: UserType.CUSTOMER,
        name: 'Closing',
        description: 'role description',
        isCustom: false
    },
    {
        id: roleID++,
        userType: UserType.CUSTOMER,
        name: 'Admin',
        description: 'role description',
        isCustom: false
    },
    {
        id: roleID++,
        userType: UserType.CLIENT,
        name: 'Client user',
        description: 'role description',
        isCustom: false
    },
    {
        id: roleID++,
        userType: UserType.CLIENT,
        name: 'Client admin',
        description: 'role description',
        isCustom: true
    },
    {
        id: roleID++,
        userType: UserType.CUSTOMER,
        name: 'Customer user',
        description: 'role description',
        isCustom: false
    },
    {
        id: roleID++,
        userType: UserType.CUSTOMER,
        name: 'Customer admin',
        description: 'role description',
        isCustom: true
    }
];

let permissionID = 0;
const getPermissions: () => Permission[] = () => [ ...Array(randomNum(8, 16)) ].map(() => {
    const category = getRandomEnumValue(PermissionCategory);

    return {
        id: permissionID++,
        category,
        description: `${permissionCategoryDisplay[category]} ${getRandomItemFromArray(nouns)}`,
        allowAssigned: randomBoolean(),
        level: getRandomEnumValue(PermissionLevel)
    };
});

const nouns = [
    'loans',
    'doc packages (Slimpack)',
    'users',
    'roles',
    'loan teams',
    'activity history',
    'documents',
    'reporting dashboard',
    'pricing',
    'rates'
];
