import api, { Product } from '@api';
import { CheckCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { ProgressIndicator, SubtleLink } from '@tsp-ui/core/components';
import { replaceItemById } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import clsx from 'clsx';
import { MouseEventHandler, useContext, useState } from 'react';

import { ProductManagementPageContext } from '../ProductManagementPage';

import styles from './ProductCard.module.scss';


interface ProductCardProps {
    product: Product;
}

export default function ProductCard({ product }: ProductCardProps) {
    // TODO post-demo add a link with investors instead of chips
    const { entities, setEntities } = useContext(ProductManagementPageContext);
    const [ isDeleting, setIsDeleting ] = useState(false);
    const { id: clientId } = useGetCurrentAccount();

    const toggleActive: MouseEventHandler<HTMLButtonElement> = async event => {
        event.preventDefault();
        setIsDeleting(true);

        setEntities(replaceItemById(entities, await api.products.updateProduct(clientId, {
            ...product,
            isActive: !product.isActive
        })));

        setIsDeleting(false);
    };

    return (
        <Paper
            className={styles.root}
            elevation={0}
        >
            <Typography className={clsx(({ [styles.inactive]: !product.isActive }))}>
                <SubtleLink to={product.id}>
                    {product.productDescription}
                </SubtleLink>
            </Typography>

            <div className={styles.buttons}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    ({product.productCode})
                </Typography>

                {isDeleting ? <ProgressIndicator className={styles.progress} /> : (
                    <Tooltip title={product.isActive ? 'Deactivate' : 'Activate'}>
                        <IconButton
                            onClick={toggleActive}
                        >
                            {product.isActive ? (
                                <RemoveCircleOutline color="error" />
                            ) : (
                                <CheckCircleOutline color="success" />
                            )}
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        </Paper>
    );
}
