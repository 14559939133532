import {
    CommonLoanProgram, InternalLoanProgram, Investor, InvestorStatus
} from '@api';

import { apiUtils } from '../../api-utils';


export function getInvestors(): Promise<ClientInvestor[]> {
    return apiUtils.get('/investor');
}

export function getAgencyInvestors(): Promise<ClientInvestor[]> {
    return apiUtils.get('/investor?showAgencyOnly=true');
}

export function updateInvestor(investor: ClientInvestor): Promise<ClientInvestor> {
    return apiUtils.put(`/investor/${investor.id}`, investor);
}

export function activateManagedInvestor(payload: ActivateManagedInvestorPayload): Promise<ClientInvestor> {
    return apiUtils.post('/investor/managed', payload);
}

export function updateStatus(
    investorID: string, status: InvestorStatus
): Promise<ClientInvestor> {
    return apiUtils.patch(`/investor/${investorID}`, { status });
}

export function addInvestor(investor: Omit<ClientInvestor, 'id'>): Promise<ClientInvestor> {
    return apiUtils.post('/investor', investor);
}

// TODO this function is not used anywhere
export function updateStatusPremManaged(
    investorID: number, params: { status: InvestorStatus, sellerServicerNumber?: number }
): Promise<ClientInvestor> {
    return apiUtils.patch(`/investor/managed/${investorID}`, params);
}

export function getLoanPrograms(investorID: string): Promise<ClientLoanProgram[]> {
    return apiUtils.get(`/investor/${investorID}/loan-program`);
}

export type ClientInvestor = Omit<Investor, 'activeClientIds'> & {
    sellerServicerNumber?: string;
    eligibilityBasedOnInvestorId?: string;
    managedInvestorIsActive?: boolean;
}

export interface ActivateManagedInvestorPayload {
    investorId: string;
    sellerServicerNumber: string;
}

export interface ClientLoanProgram extends InternalLoanProgram {
    baseLoanProgramId: string | undefined;
    hasMatrixOverlay: boolean;
}

export function isClientLoanProgram(commonLoanProgram: CommonLoanProgram): commonLoanProgram is ClientLoanProgram {
    return commonLoanProgram.hasOwnProperty('baseLoanProgramId') && commonLoanProgram.hasOwnProperty('hasMatrixOverlay');
}
