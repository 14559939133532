import { Agency, ChecklistStepType } from '../..';
import { apiUtils } from '../../api-utils';


export function getChecklistSteps(customerID: string): Promise<CustomerChecklistStep[]> {
    return apiUtils.get(`/customer/${customerID}/checklist`);
}

export function updateChecklistStep(
    customerID: string, checklist: CustomerChecklistStep
): Promise<CustomerChecklistStep> {
    return apiUtils.put(`/customer/${customerID}/checklist/${checklist.id}`, checklist);
}

export function uploadChecklistStepDocument( // TODO this endpoint is temp. Mike hasn't finalized it yet
    customerID: string, checklistID: string, formData: FormData
): Promise<Document[]> {
    return apiUtils.post(`/customer/${customerID}/checklist/${checklistID}/document`, formData, { useAutoContentType: true });
}

export function removeChecklistStepDocument( // TODO this endpoint is temp. Mike hasn't finalized it yet
    customerID: string, checklistID: string, docID: string
): Promise<void> {
    return apiUtils.delete(`/customer/${customerID}/checklist/${checklistID}/document/${docID}`);
}

export interface CustomerChecklistStep {
    customerId: number;
    id: string;
    name: string;
    description: string;
    type: ChecklistStepType;
    isRequired: boolean;
    isCompleted: boolean;
    completedDate: string;
    orderId: number;
    notes: string;
    isDocRequired: boolean;
    agencies?: AgencyApproval[];
    investors?: InvestorRelationship[];
    warehouses?: WarehouseRelationship[];
    insurances?: MICompanyRelationship[];
    documents?: Document[];
}

export interface AgencyApproval {
    id: string;
    agency: Agency;
    firstApprovalDate: string;
    latestApprovalDate: string;
}

export interface InvestorRelationship {
    id: string;
    companyName: string;
    contact: string;
    phone: string;
    emailAddress: string;
}

export interface WarehouseRelationship {
    id: string;
    lenderName: string;
    lineAmount: number;
    contact: string;
    phone: string;
    emailAddress: string;
}

export interface MICompanyRelationship {
    id: string;
    insurerName: string;
    policyNumber: string;
    contact: string;
    phone: string;
    emailAddress: string;
}

export interface Document {
    id: string;
    name: string;
    url: string;
}
