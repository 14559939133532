import { PricingResultAdjustment } from '@api';
import { Check, Login } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { ContainedTable } from '@tsp-ui/core/components';

import styles from './ProductDetailsTable.module.scss';


interface ProductDetailsTableProps {
    adjustments: PricingResultAdjustment[];
}

export default function ProductDetailsTable({ adjustments }: ProductDetailsTableProps) {
    const adjustmentsTotal = sumAmount(adjustments);
    const srpTotal = sumAmount(adjustments.filter(adjustment => adjustment.srp));
    const gosTotal = sumAmount(adjustments.filter(adjustment => adjustment.gos));

    return adjustments.length ? (
        <ContainedTable
            tableClassName={styles.root}
            headers={[
                'Adjustment reason',
                'Adjustment',
                'SRP', // TODO only show SRP and GOS if current user isn't customer user
                'GOS'
            ]}
        >
            {adjustments.map(adjustment => (
                <tr key={adjustment.id}>
                    <Typography
                        component="td"
                        variant="body2"
                        width="100%"
                    >
                        {adjustment.description}
                    </Typography>

                    <td className={styles.iconCell}>
                        <div className={styles.capContainer}>
                            {adjustment.isCap && (
                                <Tooltip title="Adds into cap">
                                    <Login
                                        fontSize="small"
                                        color="primary"
                                        className={styles.capIcon}
                                    />
                                </Tooltip>
                            )}

                            <Typography
                                variant="body2"
                                color={adjustment.amount > 0 ? 'green' : 'error'}
                            >
                                {adjustment.amount.toFixed(3)}
                            </Typography>
                        </div>
                    </td>

                    <Typography
                        component="td"
                        variant="body2"
                        align="center"
                        className={styles.iconCell}
                    >
                        {adjustment.srp && (
                            <Check
                                fontSize="small"
                                color="success"
                            />
                        )}
                    </Typography>

                    <Typography
                        component="td"
                        variant="body2"
                        align="center"
                        className={styles.iconCell}
                    >
                        {adjustment.gos && (
                            <Check
                                fontSize="small"
                                color="success"
                            />
                        )}
                    </Typography>
                </tr>
            ))}

            <tr className={styles.totalsRow}>
                <Typography
                    component="td"
                    variant="body2"
                    width="100%"
                    align="right"
                    className={styles.bold}
                >
                    Total
                </Typography>

                <td>
                    <Typography
                        variant="body2"
                        className={styles.capContainer}
                        color={adjustmentsTotal > 0 ? 'green' : 'error'}
                    >
                        {adjustmentsTotal.toFixed(3)}
                    </Typography>
                </td>

                <Typography
                    component="td"
                    variant="body2"
                    align="right"
                    color={srpTotal === 0 ? 'textPrimary'
                        : srpTotal > 0
                            ? 'green'
                            : 'error'}
                >
                    {srpTotal.toFixed(3)}
                </Typography>

                <Typography
                    component="td"
                    variant="body2"
                    align="right"
                    color={gosTotal === 0 ? 'textPrimary'
                        : gosTotal > 0
                            ? 'green'
                            : 'error'}
                >
                    {gosTotal.toFixed(3)}
                </Typography>
            </tr>
        </ContainedTable>
    ) : (
        <Typography
            className={styles.noAdjustments}
            variant="body2"
        >
            No adjustments were made to this product.
        </Typography>
    );
}

const sumAmount = (adjustments: PricingResultAdjustment[]) => adjustments.reduce(
    (sum, adjustment) => sum + adjustment.amount, 0
);
