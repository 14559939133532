import api, { LockAvailabilityHoliday } from '@api';
import { DateField, TextField, TimeField } from '@tsp-ui/core/components';
import { replaceItemByKey, usePageMessage, useParams } from '@tsp-ui/core/utils';
import { Dispatch, SetStateAction, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './LockAvailabilityHolidayForm.module.scss';
import { LockAvailabilityContext } from './LockAvailabilityPage';


interface LockAvailabilityHolidayFormProps {
    setLoading: Dispatch<SetStateAction<boolean>>;
}

export default function LockAvailabilityHolidayForm({ setLoading }: LockAvailabilityHolidayFormProps) {
    const { id, customerID } = useParams();

    const { lockAvailabilityHolidays, setLockAvailabilityHolidays } = useContext(LockAvailabilityContext);
    const defaultValues = lockAvailabilityHolidays.find(
        ({ lockAvailabilityHolidayId }) => lockAvailabilityHolidayId === parseInt(id || '')
    );

    const navigate = useNavigate();
    const location = useLocation();
    const pageMessage = usePageMessage();

    const formMethods = useForm<LockAvailabilityHoliday>({ defaultValues });

    const handleSubmit = formMethods.handleSubmit(async formData => {
        setLoading(true);

        const updatedAvailabilities = {
            ...formData,
            lockAvailabilityDate: new Date(formData.lockAvailabilityDate).toISOString()
        };

        try {
            const updatedLockAvailabilityHoliday = defaultValues
                ? await api.availability.updateLockAvailabilityHoliday(updatedAvailabilities, customerID)
                : await api.availability.createLockAvailabilityHoliday({
                    ...updatedAvailabilities,
                    isAvailable: true
                }, customerID);

            setLockAvailabilityHolidays(
                defaultValues
                    ? replaceItemByKey<LockAvailabilityHoliday>(
                        lockAvailabilityHolidays, updatedLockAvailabilityHoliday, 'lockAvailabilityHolidayId'
                    )!
                    : lockAvailabilityHolidays.concat([ updatedLockAvailabilityHoliday ])
            );

            navigate(`${location.pathname.split('lock-availability')[0]}lock-availability`);

            pageMessage.success('Lock availability saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the lock availability', error);
        }

        setLoading(false);
    });

    return (
        <form
            noValidate
            id={LockAvailabilityHolidayForm.formID}
            onSubmit={handleSubmit}
            className={styles.root}
        >
            <FormProvider {...formMethods}>
                <DateField<LockAvailabilityHoliday>
                    name="lockAvailabilityDate"
                    label="Date"
                    required
                />

                <TextField<LockAvailabilityHoliday>
                    name="description"
                    label="Description"
                    required
                />

                <TimeField<LockAvailabilityHoliday>
                    name="lockStart"
                    label="Lock start"
                    required
                />

                <TimeField<LockAvailabilityHoliday>
                    name="lockEnd"
                    label="Lock end"
                    required
                />
            </FormProvider>
        </form>
    );
}

LockAvailabilityHolidayForm.formID = 'lock-availability-holiday-form';
