import { CustomerWireInfo } from '@api';
import { Button, DialogContent, Typography } from '@mui/material';
import { DialogActions, FilledSection } from '@tsp-ui/core/components';
import { Dispatch, SetStateAction, useState } from 'react';

import styles from './WireInstructionsListContent.module.scss';
import WireInstructionsCard from './components/WireInstructionsCard';


export interface WireInstructionsListContentProps {
    wireInstructionsArray: CustomerWireInfo[];
    onApprove: (id: string) => void;
    onToggleActive: (id: string) => void;
    setWireInstructionsToEdit: Dispatch<SetStateAction<{} | CustomerWireInfo | undefined>>;
}

export default function WireInstructionsListContent({
    wireInstructionsArray, onApprove, onToggleActive, setWireInstructionsToEdit
}: WireInstructionsListContentProps) {
    const [ showInactive, setShowInactive ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const needsApproval = wireInstructionsArray?.filter(({ isApproved, isActive }) => !isApproved && isActive);
    const active = wireInstructionsArray?.filter(({ isApproved, isActive }) => isActive && isApproved);
    const inactive = wireInstructionsArray?.filter(({ isActive }) => !isActive);

    const cardProps = (wireInstructions: CustomerWireInfo) => ({
        key: wireInstructions.id,
        wireInstructions,
        wireInstructionsArray,
        onApprove,
        onToggleActive,
        setWireInstructionsToEdit,
        setLoading
    });

    return (
        <>
            <DialogContent className={styles.content}>
                {!!needsApproval?.length && (
                    <FilledSection
                        header="Needs approval"
                        variant="light"
                        className={styles.section}
                    >
                        {needsApproval.map(wireInstructions => (
                            <WireInstructionsCard {...cardProps(wireInstructions)} />
                        ))}
                    </FilledSection>
                )}

                {!!active?.length && (
                    <FilledSection
                        header="Active"
                        variant="light"
                        className={styles.section}
                    >
                        {active.map(wireInstructions => (
                            <WireInstructionsCard {...cardProps(wireInstructions)} />
                        ))}
                    </FilledSection>
                )}

                {showInactive && !!inactive?.length && (
                    <FilledSection
                        header="Inactive"
                        variant="light"
                        className={styles.section}
                    >
                        {inactive.map(wireInstructions => (
                            <WireInstructionsCard {...cardProps(wireInstructions)} />
                        ))}
                    </FilledSection>
                )}

                {(!showInactive || !inactive?.length) && !active?.length && !needsApproval?.length && (
                    <Typography variant="body2">
                        No active wire instructions were found.
                    </Typography>
                )}
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    variant="text"
                    onClick={() => setShowInactive(!showInactive)}
                    disabled={!inactive.length || loading}
                >
                    {showInactive ? 'Hide inactive' : 'Show inactive'}
                </Button>

                <Button
                    variant="contained"
                    onClick={() => setWireInstructionsToEdit({})}
                    disabled={loading}
                >
                    Add new
                </Button>
            </DialogActions>
        </>
    );
}
