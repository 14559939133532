export enum LicenseType {
    FEDERAL_LICENSE = 'FEDERAL_LICENSE',
    MORTGAGE_LENDER_LICENSE = 'MORTGAGE_LENDER_LICENSE',
    MORTGAGE_BANKER_LICENSE = 'MORTGAGE_BANKER_LICENSE',
    MORTGAGE_BROKER_LICENSE = 'MORTGAGE_BROKER_LICENSE'
}

export const licenseTypeDisplay = {
    [LicenseType.FEDERAL_LICENSE]: 'Federal License',
    [LicenseType.MORTGAGE_LENDER_LICENSE]: 'Mortgage Lender License',
    [LicenseType.MORTGAGE_BANKER_LICENSE]: 'Mortgage Banker License',
    [LicenseType.MORTGAGE_BROKER_LICENSE]: 'Mortgage Broker License'
};
