/* eslint-disable object-property-newline, object-curly-newline */
import {
    AmortizationType,
    OccupancyType,
    Product,
    ProductEventType,
    ProductHistoryEvent,
    ProductType,
    SpecialtyProduct
} from '@api';
import { PaginatedResponse } from '@tsp-ui/core';
import {
    getRandomEnumValue, getRandomItemFromArray, randomBoolean, randomNum
    , stripUndefinedProperties
} from '@tsp-ui/core/utils';
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';
import { generateSampleProductName, loanTerms } from '../pricing/pricing-mocks';
import { internalUsers } from '../user/user-mocks';


let productID = 0;

export const mocks = [
    rest.get(getMockUrl('/client/:clientID/product'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(products)
    ))),
    rest.post(getMockUrl('/client/:clientID/product'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as Product,
            id: `${productID++}`,
            isActive: true
        })
    ))),
    rest.put(getMockUrl('/client/:clientID/product/:productID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(1000),
        ctx.json(req.body)
    ))),
    rest.get(getMockUrl('/client/:clientID/product/:productID/history'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(getProductHistoryItems(parseInt(req.url.searchParams.get('pageNumber') || '')))
    )))
];

// const products: Product[] = [ ...Array(randomNum(16, 28)) ].map(() => generateProduct());

const products: Product[] = [
    generateProduct({ productType: ProductType.CONVENTIONAL, productCode: 'CON30',   term: 30, isActive: true, amortizationType: AmortizationType.FIXED }),
    generateProduct({ productType: ProductType.CONVENTIONAL, productCode: 'CON20',   term: 20, isActive: true, amortizationType: AmortizationType.FIXED }),
    generateProduct({ productType: ProductType.CONVENTIONAL, productCode: 'CON15',   term: 15, isActive: true, amortizationType: AmortizationType.ADJUSTABLE }),
    generateProduct({ productType: ProductType.FHA,          productCode: 'FHA30',   term: 30, isActive: true, amortizationType: AmortizationType.FIXED }),
    generateProduct({ productType: ProductType.FHA,          productCode: 'FHA20',   term: 20, isActive: false, amortizationType: AmortizationType.FIXED }),
    generateProduct({ productType: ProductType.FHA,          productCode: 'FHA15',   term: 15, isActive: true, amortizationType: AmortizationType.FIXED }),
    generateProduct({ productType: ProductType.VA,           productCode: 'VA30',    term: 30, isActive: true, amortizationType: AmortizationType.ADJUSTABLE }),
    generateProduct({ productType: ProductType.VA,           productCode: 'VA20',    term: 20, isActive: false, amortizationType: AmortizationType.FIXED }),
    generateProduct({ productType: ProductType.VA,           productCode: 'VA15',    term: 15, isActive: true, amortizationType: AmortizationType.FIXED }),
    generateProduct({ productType: ProductType.USDA,         productCode: 'USDA30',  term: 30, isActive: true, amortizationType: AmortizationType.ADJUSTABLE }),
    generateProduct({ productType: ProductType.USDA,         productCode: 'USDA20',  term: 20, isActive: false, amortizationType: AmortizationType.ADJUSTABLE }),
    generateProduct({ productType: ProductType.USDA,         productCode: 'USDA15',  term: 15, isActive: true, amortizationType: AmortizationType.FIXED }),
    generateProduct({ productType: ProductType.NON_AGENCY,   productCode: 'NAGCY30', term: 30, isActive: true, amortizationType: AmortizationType.FIXED }),
    generateProduct({ productType: ProductType.NON_AGENCY,   productCode: 'NAGCY20', term: 20, isActive: true, amortizationType: AmortizationType.FIXED }),
    generateProduct({ productType: ProductType.NON_AGENCY,   productCode: 'NAGCY15', term: 15, isActive: true, amortizationType: AmortizationType.FIXED })
];

function generateProduct(fields?: Partial<Product>, forDemo = true): Product {
    const productType = fields?.productType || getRandomEnumValue(ProductType);
    const amortizationType = fields?.amortizationType || getRandomEnumValue(AmortizationType);

    const term = fields?.term || getRandomItemFromArray(loanTerms);

    const investorIDs = [
        '30', '31', '32'
    ];

    return ({
        id: `${productID++}`,
        productCode: getRandomItemFromArray([
            'J20', 'AINS15', 'FH30', 'FN30', 'HBARM51', 'VA30'
        ]),
        productDescription: generateSampleProductName(productType, amortizationType, term),
        productType,
        amortizationType,
        term: randomNum(180, 240),
        ...(!forDemo && {
            minTerm: 10,
            maxTerm: 30,
            minLoanAmount: randomNum(30, 300) * 1000,
            maxLoanAmount: randomNum(30, 300) * 1000
        }),
        highBalance: randomBoolean(),
        jumbo: randomBoolean(),
        specialtyProduct: getRandomEnumValue(SpecialtyProduct),
        nonAgency: randomBoolean(),
        servicingReleased: randomBoolean(),
        streamline: randomBoolean(),
        ...(amortizationType === AmortizationType.ADJUSTABLE && {
            armInitialPeriod: randomNum(6, 12),
            armSubPeriod: randomNum(6, 12),
            armInitialRateCap: randomNum(3, 7, 3),
            armSubRateCap: randomNum(3, 7, 3),
            armLifeRateCap: randomNum(3, 7, 3),
            armLookBackDays: 90
        }),
        isActive: randomBoolean(),
        investorIDs: [
            ...new Set([
                getRandomItemFromArray(investorIDs),
                getRandomItemFromArray(investorIDs)
            ])
        ],
        occupancyTypes: [
            ...new Set([
                getRandomEnumValue(OccupancyType),
                getRandomEnumValue(OccupancyType),
                getRandomEnumValue(OccupancyType)
            ])
        ],
        ...fields
    });
}

// const products: Product[] = [ ...Array(randomNum(16, 28)) ].map(() => generateProduct());

let productHistoryEventID = 1;
function getProductHistoryItems(pageNumber: number): PaginatedResponse<ProductHistoryEvent> {
    const totalResults = 88;
    const pageSize = 15;

    return {
        totalPages: Math.floor(totalResults / pageSize),
        totalRecords: totalResults,
        pageNumber,
        pageSize,
        data: [ ...Array(15) ].map(() => {
            const item = getRandomItemFromArray(products);

            return {
                id: `${productHistoryEventID++}`,
                executedBy: internalUsers[0],
                eventType: getRandomEnumValue(ProductEventType),
                before: item,
                after: {
                    ...item,
                    ...stripUndefinedProperties<Partial<Product>>(Object.fromEntries(
                        Object.entries(getRandomItemFromArray(products)).map(
                            ([ key, value ]) => [ key, randomNum(1, 10) >= 7 ? value : undefined ]
                        )
                    )) || {}
                },
                date: new Date(Date.now() - randomNum(20000000, 500000000)).toISOString()
            };
        })
    };
}
