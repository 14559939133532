import { State } from '@api';
import {
    Button, Checkbox, Divider, FormControlLabel, Paper, Typography
} from '@mui/material';
import { AddressTypography } from '@tsp-ui/core/components';
import { ReactNode, useState } from 'react';

import { AppraisalUnderwritingTabs } from '../../AppraisalUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';

import styles from './QualifyingAppraisalSection.module.scss';


interface QualifyingAppraisalSectionProps {
    setTab: (tab: AppraisalUnderwritingTabs) => void;
    onTabComplete: (tab: AppraisalUnderwritingTabs, nextTab: AppraisalUnderwritingTabs) => void;
}

export default function QualifyingAppraisalSection({ onTabComplete }: QualifyingAppraisalSectionProps) {
    const [ onlyShowDiff, setOnlyShowDiff ] = useState(true);

    const address1 = {
        street: '120 Lake Dr',
        city: 'West Greenwich',
        state: State.RI,
        zip: '02817'
    };

    const address2 = {
        street: '120 Lake Dr',
        city: 'West Greenwich',
        state: State.RI,
        zip: '02817'
    };

    return (
        <UnderwritingStepSection
            title="Qualifying appraisal"
            addButton={(
                <Button>
                    Add condition
                </Button>
            )}
        >
            <Paper
                variant="outlined"
                className={styles.root}
            >
                <Typography>
                    Select a qualifying appraisal
                </Typography>

                <FormControlLabel
                    className={styles.diffCheckbox}
                    label="Only show differences"
                    control={(
                        <Checkbox
                            checked={onlyShowDiff}
                            onChange={(event) => setOnlyShowDiff(event.target.checked)}
                        />
                    )}
                />

                <Divider className={styles.divider} />

                <div className={styles.table}>
                    <Typography
                        component="th"
                        color="textSecondary"
                        fontWeight={500}
                        className={styles.gridCol2}
                    >
                        Appraisal 1
                    </Typography>

                    <Typography
                        component="th"
                        color="textSecondary"
                        fontWeight={500}
                    >
                        Appraisal 2
                    </Typography>

                    <ComparisonRow
                        label="Appraisal type"
                        v1="1004"
                        v2="1004"
                        onlyShowDiff={onlyShowDiff}
                    />

                    <ComparisonRow
                        label="Property address"
                        onlyShowDiff={onlyShowDiff}
                        v1={<AddressTypography address={address1} />}
                        v2={<AddressTypography address={address2} />}
                        valuesEqualOverride={address1.street === address2.street
                            && address1.city === address2.city
                            && address1.state === address2.state
                            && address1.zip === address2.zip}
                    />

                    <ComparisonRow
                        label="Property type"
                        v1="Single family (attached)"
                        v2="Single family (attached)"
                        onlyShowDiff={onlyShowDiff}
                    />

                    <ComparisonRow
                        label="Annual taxes"
                        v1="$3,000"
                        v2="$3,150"
                        onlyShowDiff={onlyShowDiff}
                    />

                    <ComparisonRow
                        label="Special assessments"
                        v1="--"
                        v2="--"
                        onlyShowDiff={onlyShowDiff}
                    />

                    <ComparisonRow
                        label="HOA Fees"
                        v1="$100/mo"
                        v2="$120/mo"
                        onlyShowDiff={onlyShowDiff}
                    />

                    <ComparisonRow
                        label="Declining market"
                        v1="No"
                        v2="No"
                        onlyShowDiff={onlyShowDiff}
                    />

                    <ComparisonRow
                        label="Condition rating"
                        v1="C1"
                        v2="C2"
                        onlyShowDiff={onlyShowDiff}
                    />

                    <ComparisonRow
                        label="Appraisal made"
                        v1="as is"
                        v2="as is"
                        onlyShowDiff={onlyShowDiff}
                    />

                    <ComparisonRow
                        label="Appraisal date"
                        v1="01/13/2022"
                        v2="01/13/2022"
                        onlyShowDiff={onlyShowDiff}
                    />

                    <ComparisonRow
                        label="Appraised value"
                        v1="$300,000"
                        v2="$320,000"
                        onlyShowDiff={onlyShowDiff}
                    />

                    <ComparisonRow
                        label="Has 1004D"
                        v1="Yes"
                        v2="Yes"
                        onlyShowDiff={onlyShowDiff}
                    />

                    <ComparisonRow
                        label="Has interior photos"
                        v1="Yes"
                        v2="Yes"
                        onlyShowDiff={onlyShowDiff}
                    />

                    <ComparisonRow
                        label="Rural or >10 acres"
                        v1="No"
                        v2="No"
                        onlyShowDiff={onlyShowDiff}
                    />

                    <ComparisonRow
                        label="Value supported"
                        v1="Yes"
                        v2="Yes"
                        onlyShowDiff={onlyShowDiff}
                    />

                    <div className={styles.buttons}>
                        <Button
                            className={styles.gridCol2}
                            onClick={() => onTabComplete('qualifying', 'summary')}
                        >
                            Select
                        </Button>

                        <Button>
                            Select
                        </Button>
                    </div>
                </div>
            </Paper>
        </UnderwritingStepSection>
    );
}

interface ComparisonRowProps {
    label: string;
    v1: ReactNode;
    v2: ReactNode;
    onlyShowDiff: boolean;
    valuesEqualOverride?: boolean;
}

function ComparisonRow({
    label, v1, v2, onlyShowDiff, valuesEqualOverride
}: ComparisonRowProps) {
    const valuesDiffer = valuesEqualOverride !== undefined
        ? !valuesEqualOverride
        : v1 !== v2;

    return (!onlyShowDiff || valuesDiffer) ? (
        <div className={styles.row}>
            <Typography
                align="right"
                color={valuesDiffer ? 'textPrimary' : 'textSecondary'}
                fontWeight={500}
            >
                {label}
            </Typography>

            <Typography align="center">
                {v1}
            </Typography>

            <Typography align="center">
                {v2}
            </Typography>
        </div>
    ) : null;
}
