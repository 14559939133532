import {
    ConditionEvent,
    ConditionExceptionStatus,
    LoanRoleAssignment,
    LoanStatusDelegated,
    LoanStatusNonDelegated,
    NotificationFrequency,
    NotificationMethod,
    NotificationType,
    UserType,
    getRoleURL
} from '../..';
import { apiUtils } from '../../api-utils';


export function getNotificationConfigs(
    userType: UserType, roleID: number, customerID?: string
): Promise<NotificationConfig[]> {
    return apiUtils.get(getNotificationsUrl(userType, roleID, customerID));
}

export function editNotification(
    userType: UserType,
    roleID: number,
    notification: NotificationConfig,
    customerID?: string
): Promise<NotificationConfig> {
    return apiUtils.put(getNotificationsUrl(userType, roleID, customerID, notification.id), notification);
}

export function addNotification(
    userType: UserType, roleID: number, notification: Omit<NotificationConfig, 'id'>, customerID?: string
): Promise<NotificationConfig> {
    return apiUtils.post(getNotificationsUrl(userType, roleID, customerID), notification);
}

export function deleteNotification(
    userType: UserType,
    roleID: number,
    notificationID: number,
    customerID?: string
): Promise<NotificationConfig> {
    return apiUtils.delete(getNotificationsUrl(userType, roleID, customerID, notificationID));
}

export interface NotificationConfig {
    id: number;
    roleId: number;
    type: NotificationType;
    parameters: NotificationParam[];
    frequency: NotificationFrequency;
    methods: NotificationMethod[];
    includeUnassigned: boolean;
}

export type NotificationParam =
    | ConditionEvent
    | ConditionExceptionStatus
    | LoanRoleAssignment
    | LoanStatusDelegated
    | LoanStatusNonDelegated;

function getNotificationsUrl(
    userType: UserType, roleID: number, customerID: string | undefined, notificationID?: number
) {
    return `${getRoleURL(userType, customerID, roleID)}/notifications${notificationID ? `/${notificationID}` : ''}`;
}
