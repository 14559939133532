import { Button, Link as MuiLink, Typography } from '@mui/material';
import { FilterTextField, FilterableFilledSection } from '@tsp-ui/core/components';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { LoanProgramsContext } from '../../InternalInvestorDetailRoutes';

import { LoanProgramCard } from './LoanProgramCard';


export function LoanProgramSection() {
    const { loanPrograms } = useContext(LoanProgramsContext);

    return (
        <FilterableFilledSection
            header="Loan programs"
            addButton={(
                <Button
                    variant="contained"
                    component={Link}
                    to="loan-programs/new"
                >
                    Add loan program
                </Button>
            )}
            filterField={(
                <FilterTextField
                    placeholder="Filter loan programs"
                    helperText="Filter by name"
                />
            )}
        >
            {!loanPrograms?.length ? (
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    No loan programs are currently configured.{' '}

                    <MuiLink
                        component={Link}
                        to="loan-programs/new"
                    >
                        Add a new loan program
                    </MuiLink>.
                </Typography>
            ) : loanPrograms.map(loanProgram => (
                <LoanProgramCard
                    key={loanProgram.id}
                    loanProgram={loanProgram}
                />
            ))}
        </FilterableFilledSection>
    );
}
