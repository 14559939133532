import api, { Contact } from '@api';
import { RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomerDetailContext, CustomerDetailContextValue } from '../../CustomerDetailPage';

import { AddEditContactDialogContent } from './components/AddEditContactDialogContent';


export default function EditCustomerPrimaryContactDialog(props: Omit<RoutedDialogProps, 'title' | 'children' | 'onSubmit'>) {
    const navigate = useNavigate();

    const {
        customer, updateCustomer
    } = useContext(CustomerDetailContext) as Required<CustomerDetailContextValue>;

    async function handleEdit(formValues: Contact) {
        updateCustomer({
            ...customer,
            primaryContact: await api.customer.contacts.editContact(customer.id, formValues)
        });
    }

    return (
        <RoutedDialog
            {...props}
            title="Edit primary contact"
            maxWidth={false}
        >
            <AddEditContactDialogContent
                defaultValues={customer.primaryContact}
                onEdit={handleEdit}
                onDone={() => navigate(props.closeTo, { replace: true })}
                {...props}
            />
        </RoutedDialog>
    );
}
