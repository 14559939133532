import api, { EligibilityGuidelineSetSummary } from '@api';
import {
    ContentCopy, Edit, RemoveCircleOutline, Visibility
} from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import {
    formatDate, isFutureDate, isPastDate, removeItemById, replaceItemById, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { AdminSubRouteParams } from '@views/admin/components/AdminPageTemplate';
import clsx from 'clsx';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ProgramEligibilityContext } from '../InternalLoanProgramDetailPage';

import styles from './GuidelineCard.module.scss';


interface GuidelineCardProps {
    guideline: EligibilityGuidelineSetSummary;
}

export function GuidelineCard({
    guideline: { expirationDate, effectiveDate, id }
}: GuidelineCardProps) {
    const { investorID, loanProgramID } = useParams<AdminSubRouteParams<'loanProgram'>>();
    const { eligibilityGuidelineSets = [], setEligibilityGuidelineSets } = useContext(ProgramEligibilityContext);
    const pageMessage = usePageMessage();

    const isActive = isPastDate(effectiveDate) && (
        expirationDate ? isFutureDate(expirationDate) : true
    );
    const isPending = isFutureDate(effectiveDate);
    const isExpired = isPastDate(expirationDate);

    return (
        <Paper
            elevation={0}
            className={clsx(styles.root, {
                [styles.active]: isActive,
                [styles.pending]: isPending,
                [styles.expired]: isExpired
            })}
        >
            <Typography variant="body2">
                {isExpired ? (
                    `Expired ${formatDate(expirationDate)}`
                ) : isActive && expirationDate ? (
                    `Effective until ${formatDate(expirationDate)}`
                ) : (
                    `Effective ${formatDate(effectiveDate)}`
                )}
            </Typography>

            <div>
                <Tooltip title="View details">
                    <IconButton
                        component={Link}
                        size="small"
                        to={`guidelines/${id}`}
                    >
                        <Visibility
                            color="action"
                            fontSize="small"
                        />
                    </IconButton>
                </Tooltip>

                {!isExpired && (
                    <Tooltip title="Edit guidelines">
                        <IconButton
                            component={Link}
                            size="small"
                            to={`guidelines/${id}/edit`}
                        >
                            <Edit
                                color="action"
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>
                )}

                {isExpired ? (
                    <Tooltip title="Clone guidelines">
                        <IconButton
                            size="small"
                            component={Link}
                            to={`guidelines/${id}/edit`}
                        >
                            <ContentCopy
                                color="action"
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip
                        title={isActive
                            ? 'Expire active guidelines'
                            : 'Delete pending guidelines'}
                    >
                        <IconButton
                            size="small"
                            onClick={async () => {
                                try {
                                    if (!isActive) {
                                        await api.investors.deleteEligibilityGuidelineSet(
                                            investorID, loanProgramID, id
                                        );

                                        setEligibilityGuidelineSets(removeItemById(eligibilityGuidelineSets, id));
                                    } else {
                                        const updatedGuidelineSet = await api.investors.expireEligibilityGuidelineSet(
                                            investorID, loanProgramID, id
                                        );

                                        setEligibilityGuidelineSets(replaceItemById(
                                            eligibilityGuidelineSets, updatedGuidelineSet
                                        ));
                                    }

                                    pageMessage.success(`Eligibility guidelines ${isActive ? 'expired' : 'deleted'}`);
                                } catch (error) {
                                    pageMessage.handleApiError(`An error occurred while ${isActive ? 'expiring' : 'deleting'} eligibility guidelines`, error);
                                }
                            }}
                        >
                            <RemoveCircleOutline
                                color="error"
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        </Paper>
    );
}
