import { CheckCircleOutline, ErrorOutline, InfoOutlined } from '@mui/icons-material';
import { IconTypography } from '@tsp-ui/core/components';
import { useState } from 'react';

import { AppraisalUnderwritingTabs } from '../../AppraisalUnderwritingPage';
import UnderwritingNextStepSummary from '../UnderwritingNextStepSummary';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';

import styles from './CondoInfoSection.module.scss';


interface CondoInfoSectionProps {
    setTab: (tab: AppraisalUnderwritingTabs) => void;
    onTabComplete: (tab: AppraisalUnderwritingTabs, nextTab: AppraisalUnderwritingTabs) => void;
}

export default function CondoInfoSection({ onTabComplete }: CondoInfoSectionProps) {
    const [ isNew, setIsNew ] = useState<boolean>();
    const [ isLessThan5Units, setIsLessThan5Units ] = useState<boolean>();
    const [ docsIncluded, setDocsIncluded ] = useState<boolean>();
    const [ limitedReviewSufficient, setLimitedReviewSufficient ] = useState<boolean>();

    return (
        <UnderwritingStepSection title="Condo info">
            <UnderwritingYesNoQuestionCard
                question="Is the condo project new?"
                onAnswered={setIsNew}
                answer={isNew}
            />

            {isNew && (
                <CondoInfoSummary
                    variant="full"
                    onTabComplete={onTabComplete}
                />
            )}

            {isNew === false && (
                <>
                    <UnderwritingYesNoQuestionCard
                        question="Does the entire project contain less than 5 total units?"
                        onAnswered={setIsLessThan5Units}
                        answer={isLessThan5Units}
                    />

                    {isLessThan5Units !== undefined && (
                        <CondoInfoSummary
                            variant={isLessThan5Units ? 'none' : 'limited'}
                            onTabComplete={onTabComplete}
                        />
                    )}
                </>
            )}

            {(isNew || isLessThan5Units === false) && (
                <UnderwritingYesNoQuestionCard
                    question={(
                        <>
                            Are the following warranty documents included?

                            <ul className={styles.documentList}>
                                {isNew ? (
                                    <>
                                        <li>Full condo questionnaire</li>

                                        <li>HOA Budget</li>

                                        <li>HOA Balance sheet</li>
                                    </>
                                ) : (
                                    <li>Limited condo questionnaire</li>
                                )}
                            </ul>
                        </>
                    )}
                    onAnswered={setDocsIncluded}
                    answer={docsIncluded}
                />
            )}

            {!isNew && isLessThan5Units === false && docsIncluded && (
                <UnderwritingYesNoQuestionCard
                    question="Is the limited review sufficient?"
                    onAnswered={setLimitedReviewSufficient}
                    answer={limitedReviewSufficient}
                />
            )}
        </UnderwritingStepSection>
    );
}

interface CondoInfoSummaryProps {
    variant: 'full' | 'limited' | 'none';
    onTabComplete: CondoInfoSectionProps['onTabComplete'];
}

const summaryPrefixes = {
    full: 'Full',
    limited: 'Limited',
    none: 'No'
};

const summaryIcons = {
    full: <ErrorOutline color="error" />,
    limited: <InfoOutlined color="primary" />,
    none: <CheckCircleOutline color="success" />
};

function CondoInfoSummary({ variant, onTabComplete }: CondoInfoSummaryProps) {
    return (
        <UnderwritingNextStepSummary
            onNextClick={variant === 'none'
                ? undefined
                : () => onTabComplete('condo-info', 'summary')}
        >
            <IconTypography icon={summaryIcons[variant]}>
                {summaryPrefixes[variant]} condo review required
            </IconTypography>
        </UnderwritingNextStepSummary>
    );
}
