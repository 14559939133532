import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import {
    Button, IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { LabelGroup, LabeledValue } from '@tsp-ui/core/components';
import { useEffect, useState } from 'react';

import { AssetsUnderwritingTabs } from '../../AssetsUnderwritingPage';
import UnderwritingAnswerButtons from '../UnderwritingAnswerButtons';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard, { yesNoButtonConfigs } from '../UnderwritingYesNoQuestionCard';

import styles from './GiftFundsSection.module.scss';


interface GiftFundsSectionProps {
    setTab: (tab: AssetsUnderwritingTabs) => void;
    onTabComplete: (tab: AssetsUnderwritingTabs, nextTab: AssetsUnderwritingTabs) => void;
}

export default function GiftFundsSection({ onTabComplete }: GiftFundsSectionProps) {
    const [ hasGiftFunds, setHasGiftFunds ] = useState<boolean>();

    useEffect(() => {
        if (hasGiftFunds === false) {
            onTabComplete('gifts', 'earnest-money');
        }
    }, [ hasGiftFunds, onTabComplete ]);

    const giftFunds = [
        {
            amount: '$5,000',
            from: 'Some Giver'
        },
        {
            amount: '$10,000',
            from: 'Better Giver'
        }
    ];

    return (
        <UnderwritingStepSection
            title="Gift funds"
            addButton={(
                <Button>
                    Add gift
                </Button>
            )}
        >
            <UnderwritingYesNoQuestionCard
                question="Are there any gift funds for this transaction?"
                onAnswered={setHasGiftFunds}
                answer={hasGiftFunds}
            />

            {hasGiftFunds && (
                <div className={styles.cardContainer}>
                    {giftFunds.map((gift) => (
                        <GiftFundCard gift={gift} />
                    ))}
                </div>
            )}
        </UnderwritingStepSection>
    );
}

interface GiftFundCardProps {
    gift: Gift;
}

interface Gift {
    amount: string;
    from: string;
}

function GiftFundCard({ gift: { amount, from } }: GiftFundCardProps) {
    const [ hasGiftLetter, setHasGiftLetter ] = useState<boolean>();
    const [ fundRecipient, setFundRecipient ] = useState<GiftFundRecipient>();
    const [ hasBorrowerReceived, setHasBorrowerReceived ] = useState<boolean>();
    const [ donorFundsSourced, setDonorFundsSourced ] = useState<YesNoNotRequired>();

    return (
        <Paper
            variant="outlined"
            className={styles.card}
        >
            <div className={styles.header}>
                <Typography>
                    <span className={styles.bold}>{amount}{' '}</span>

                    from{' '}

                    <span className={styles.bold}>{from}</span>
                </Typography>

                <div className={styles.iconButtons}>
                    <Tooltip title="Delete">
                        <IconButton size="small">
                            <RemoveCircleOutline color="error" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Edit">
                        <IconButton size="small">
                            <Edit color="secondary" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            {hasGiftLetter !== undefined && (
                <LabelGroup>
                    <LabeledValue
                        label="Gift letter on file?"
                        value={hasGiftLetter ? 'Yes' : 'No'}
                    />

                    {fundRecipient !== undefined && (
                        <LabeledValue
                            label="Gift fund recipient?"
                            value={giftFundRecipientDisplay[fundRecipient]}
                        />
                    )}

                    {hasBorrowerReceived !== undefined && (
                        <LabeledValue
                            label="Borrower received funds?"
                            value={hasBorrowerReceived ? 'Yes' : 'No'}
                        />
                    )}

                    {donorFundsSourced !== undefined && (
                        <LabeledValue
                            label="Donor funds sourced?"
                            value={yesNoNotRequiredDisplay[donorFundsSourced]}
                        />
                    )}
                </LabelGroup>
            )}

            {hasGiftLetter === undefined ? (
                <div className={styles.question}>
                    <Typography>
                        Is there a gift letter for this gift?
                    </Typography>

                    <UnderwritingAnswerButtons
                        buttons={yesNoButtonConfigs}
                        value={hasGiftLetter}
                        setValue={setHasGiftLetter}
                    />
                </div>
            ) : fundRecipient === undefined ? (
                <div className={styles.question}>
                    <Typography>
                        Who will receive these gift funds?
                    </Typography>

                    <UnderwritingAnswerButtons
                        buttons={recipientButtons}
                        value={fundRecipient}
                        setValue={setFundRecipient}
                    />
                </div>
            ) : fundRecipient === BORROWER && hasBorrowerReceived === undefined ? (
                <div className={styles.question}>
                    <Typography>
                        Have these funds been deposited in the borrower's account?
                    </Typography>

                    <UnderwritingAnswerButtons
                        buttons={yesNoButtonConfigs}
                        value={hasBorrowerReceived}
                        setValue={setHasBorrowerReceived}
                    />
                </div>
            ) : fundRecipient === CLOSING_AGENT && donorFundsSourced === undefined ? (
                <div className={styles.question}>
                    <Typography>
                        Have these funds been sourced in the donor's account?
                    </Typography>

                    <UnderwritingAnswerButtons
                        buttons={yesNoNotRequiredButtons}
                        value={donorFundsSourced}
                        setValue={setDonorFundsSourced}
                    />
                </div>
            ) : null}
        </Paper>
    );
}

enum GiftFundRecipient {
    BORROWER = 'BORROWER',
    CLOSING_AGENT = 'CLOSING_AGENT',
    GIFT_OF_EQUITY = 'GIFT_OF_EQUITY'
}

const { BORROWER, CLOSING_AGENT, GIFT_OF_EQUITY } = GiftFundRecipient;

const giftFundRecipientDisplay = {
    [BORROWER]: 'Borrower',
    [CLOSING_AGENT]: 'Closing agent',
    [GIFT_OF_EQUITY]: 'Gift of equity'
};

const recipientButtons = [
    {
        label: giftFundRecipientDisplay[BORROWER],
        value: BORROWER
    },
    {
        label: giftFundRecipientDisplay[CLOSING_AGENT],
        value: CLOSING_AGENT
    },
    {
        label: giftFundRecipientDisplay[GIFT_OF_EQUITY],
        value: GIFT_OF_EQUITY
    }
];

enum YesNoNotRequired {
    NOT_REQUIRED = 'NOT_REQUIRED',
    YES = 'YES',
    NO = 'NO'
}

const { NOT_REQUIRED, YES, NO } = YesNoNotRequired;

const yesNoNotRequiredDisplay = {
    [NOT_REQUIRED]: 'Not required',
    [YES]: 'Yes',
    [NO]: 'No'
};

const yesNoNotRequiredButtons = [
    {
        label: yesNoNotRequiredDisplay[NOT_REQUIRED],
        value: NOT_REQUIRED
    },
    {
        label: yesNoNotRequiredDisplay[NO],
        value: NO
    },
    {
        label: yesNoNotRequiredDisplay[YES],
        value: YES
    }
];
