import api, {
    CustomerDetails, CustomerPendingSteps, User, UserType
} from '@api';
import { Button, DialogContent, MenuItem } from '@mui/material';
import {
    DialogActions, RoutedDialog, RoutedDialogProps, TextField
} from '@tsp-ui/core/components';
import { getFullName, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import {
    useCallback, useContext, useMemo, useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { CustomerDetailContext } from '../../CustomerDetailPage';

import styles from './ManagersDialog.module.scss';


export default function ManagersDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const {
        customer: defaultValues, updateCustomer, updatePendingSteps
    } = useContext(CustomerDetailContext); // TODO post-demo add client users to context

    const pageMessage = usePageMessage();
    const [ saveLoading, setSaveLoading ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ clientUsers, setClientUsers ] = useState<User[]>([]);

    useAsyncEffect(useCallback(async () => {
        try {
            setClientUsers(await api.users.getUsers(UserType.CLIENT));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching client users', error);
        }

        setLoading(false);
    }, [ pageMessage ]));

    const navigate = useNavigate();
    const formMethods = useForm({ defaultValues });

    const userOptions = useMemo(() => clientUsers.map((user) => (
        <MenuItem
            value={user.id}
            key={user.id}
        >
            {getFullName(user)}
        </MenuItem>
    ))
    , [ clientUsers ]);

    const handleSubmit = formMethods.handleSubmit(async (formData) => {
        setSaveLoading(true);

        try {
            updateCustomer(await api.customer.updateCustomer(formData));

            await updatePendingSteps(CustomerPendingSteps.MANAGERS);

            navigate(props.closeTo);

            pageMessage.success('Managers saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving customer managers', error);
        }

        setSaveLoading(false);
    });

    return (
        <RoutedDialog
            title="Edit managers"
            {...props}
            maxWidth={false}
            loading={loading}
        >
            <DialogContent>
                <form
                    className={styles.form}
                    noValidate
                    id="edit-managers-form"
                    onSubmit={handleSubmit}
                >
                    <FormProvider {...formMethods}>
                        <TextField<CustomerDetails>
                            name="counterpartyAnalystId"
                            label="Counterparty Analyst"
                            select
                            required
                        >
                            {userOptions}
                        </TextField>

                        <TextField<CustomerDetails>
                            name="primaryAccountExecId"
                            label="Primary account executive"
                            select
                            required
                        >
                            {userOptions}
                        </TextField>

                        <TextField<CustomerDetails>
                            name="secondaryAccountExecId"
                            label="Secondary account executive"
                            select
                            required
                        >
                            {userOptions}
                        </TextField>

                        <TextField<CustomerDetails>
                            name="accountManagerId"
                            label="Account manager"
                            select
                            required
                        >
                            {userOptions}
                        </TextField>
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={saveLoading}>
                <Button
                    variant="contained"
                    form="edit-managers-form"
                    type="submit"
                    disabled={saveLoading}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
