export enum LoanStatusDelegated {
    APPROVED_FOR_FUNDING = 'APPROVED_FOR_FUNDING',
    FILE_RECEIVED = 'FILE_RECEIVED',
    FUNDING_IN_PROCESS = 'FUNDING_IN_PROCESS',
    IN_QA_REVIEW = 'IN_QA_REVIEW',
    LOAN_SETUP = 'LOAN_SETUP',
    POST_PURCHASE_SUSPENDED = 'POST_PURCHASE_SUSPENDED',
    PURCHASED = 'PURCHASED',
    QA_COMPLETE = 'QA_COMPLETE',
    QA_SUSPENDED_CONDITIONS_ISSUED = 'QA_SUSPENDED_CONDITIONS_ISSUED',
    WITHDRAWN_DENIED_CANCELLED = 'WITHDRAWN_DENIED_CANCELLED'
}

export const loanStatusDelegatedDisplay = {
    [LoanStatusDelegated.APPROVED_FOR_FUNDING]: 'Approved for Funding',
    [LoanStatusDelegated.FILE_RECEIVED]: 'File Received',
    [LoanStatusDelegated.FUNDING_IN_PROCESS]: 'Funding in Process',
    [LoanStatusDelegated.IN_QA_REVIEW]: 'In QA Review',
    [LoanStatusDelegated.LOAN_SETUP]: 'Loan Setup',
    [LoanStatusDelegated.POST_PURCHASE_SUSPENDED]: 'Post Purchase Suspended',
    [LoanStatusDelegated.PURCHASED]: 'Purchased',
    [LoanStatusDelegated.QA_COMPLETE]: 'QA Complete',
    [LoanStatusDelegated.QA_SUSPENDED_CONDITIONS_ISSUED]: 'QA Suspended/Conditions Issued',
    [LoanStatusDelegated.WITHDRAWN_DENIED_CANCELLED]: 'Withdrawn/Denied/Cancelled'
};
