import { CustomerFee, feeTypeDisplay } from '@api';
import { CheckCircleOutline, Edit, RemoveCircleOutline } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';
import {
    CurrencyField, CurrencyTypography, TextField, renderEnumOptions
} from '@tsp-ui/core/components';
import clsx from 'clsx';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FeesCompFormValues } from './CompensationDialogForm';
import styles from './CustomerFeeTableCustomFeeRow.module.scss';


interface CustomerFeeTableCustomFeeRowProps {
    fee: CustomerFee;
    index: number;
    onRemove: () => void;
}

export default function CustomerFeeTableCustomFeeRow({
    fee, index, onRemove
}: CustomerFeeTableCustomFeeRowProps) {
    const nameBase: `fees.${number}` = `fees.${index}`;

    const { watch } = useFormContext<FeesCompFormValues>();

    const feeType = watch(`${nameBase}.feeType`);
    const amount = watch(`${nameBase}.defaultAmount`);

    const isFilledOut = feeType && amount;

    const [ isEditing, setIsEditing ] = useState(!isFilledOut);

    const handleSave = () => isFilledOut && setIsEditing(false);

    return (
        <tr
            key={fee.feeType}
            className={clsx(styles.tableRow, {
                [styles.tableRowIsEditing]: isEditing
            })}
        >
            {isEditing ? (
                <>
                    <td>
                        <TextField<FeesCompFormValues>
                            name={`${nameBase}.feeType`}
                            SelectProps={{
                                classes: { select: styles.feeTypeDropdown }
                            }}
                            required
                            hideRequiredIndicator
                            select
                        >
                            {renderEnumOptions(feeTypeDisplay)}
                        </TextField>
                    </td>

                    <td>
                        <CurrencyField<FeesCompFormValues>
                            name={`${nameBase}.defaultAmount`}
                            className={styles.amountField}
                            variant="standard"
                            autoFocus
                            required
                            hideRequiredIndicator
                            onKeyDown={event => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                    handleSave();
                                }
                            }}
                        />
                    </td>
                </>
            ) : (
                <>
                    <td width="100%">
                        <Typography
                            variant="body2"
                        >
                            {feeTypeDisplay[feeType]}
                        </Typography>
                    </td>

                    <td align="right">
                        <CurrencyTypography
                            variant="body2"
                            value={amount}
                        />
                    </td>
                </>
            )}

            <td className={styles.actionButtons}>
                {isEditing ? (
                    <Tooltip title="Done">
                        <IconButton
                            size="small"
                            onClick={handleSave}
                        >
                            <CheckCircleOutline
                                color="secondary"
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Edit fee">
                        <IconButton
                            size="small"
                            onClick={() => setIsEditing(true)}
                        >
                            <Edit
                                color="secondary"
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>
                )}

                <Tooltip title="Delete fee">
                    <IconButton
                        size="small"
                        onClick={onRemove}
                    >
                        <RemoveCircleOutline
                            fontSize="small"
                            color="error"
                        />
                    </IconButton>
                </Tooltip>
            </td>
        </tr>
    );
}
