import { EligibleProductsLimitUpdated } from '@api';
import { Paper } from '@mui/material';
import { LabelGroup, LabeledValue } from '@tsp-ui/core/components';
import { formatCurrency } from '@tsp-ui/core/utils';

import styles from './EligibleProductsLimitCard.module.scss';


interface EligibleProductsLimitCardProps {
    config: EligibleProductsLimitUpdated;
}

export default function EligibleProductsLimitCard({ config }: EligibleProductsLimitCardProps) {
    const { name, limit } = config;

    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            <LabelGroup title={name}>
                <LabeledValue
                    label="Limit amount:"
                    value={formatCurrency(limit.limitAmount)}
                />

                <LabeledValue
                    label="Expiration date:"
                    value={limit.expirationDate}
                />
            </LabelGroup>
        </Paper>
    );
}
