import {
    CustomerCompensation,
    CustomerCompensationType,
    customerCompensationPercentageOfDisplay,
    customerTypeDisplay
} from '@api';
import { Paper, Typography } from '@mui/material';
import { LabelGroup, LabeledValue } from '@tsp-ui/core/components';
import { formatCurrency } from '@tsp-ui/core/utils';

import styles from './CompensationCard.module.scss';


interface CompensationCardProps {
    compensation: CustomerCompensation;
}

export default function CompensationCard({ compensation }: CompensationCardProps) {
    const {
        firstLienOnly, type, amount, percentage, percentageOf, minAmount, maxAmount, notes
    } = compensation;

    return (
        <Paper
            variant="outlined"
        >
            <LabelGroup
                title="Compensation details"
                className={styles.group}
            >
                <LabeledValue
                    label="First lien only:"
                    value={firstLienOnly ? 'Yes' : 'No'}
                />

                <LabeledValue
                    label="Compensation type:"
                    value={customerTypeDisplay[type]}
                />

                {type === CustomerCompensationType.FIXED ? (
                    <LabeledValue
                        label="Compensation amount:"
                        value={formatCurrency(amount)}
                    />
                ) : (
                    <>
                        <LabeledValue
                            label="Details:"
                            value={(percentage && percentageOf) ? `${percentage}% of the ${customerCompensationPercentageOfDisplay[percentageOf!].toLowerCase()}` : undefined}
                        />

                        <LabeledValue
                            label="Min compensation:"
                            value={formatCurrency(minAmount)}
                        />

                        <LabeledValue
                            label="Max compensation:"
                            value={formatCurrency(maxAmount)}
                        />
                    </>
                )}

                <Typography
                    variant="caption"
                    color="textSecondary"
                >
                    Notes:
                </Typography>

                <Typography
                    variant="body2"
                    className={styles.notes}
                >
                    {notes}
                </Typography>
            </LabelGroup>
        </Paper>
    );
}
