import { AddCircleOutline } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { EligibilityExclusionFormValues, EligibilityExclusionGroupFormValues } from '@views/admin/investors/InvestorDetailPage/components/ExclusionGroupRow';
import { useState } from 'react';

import { getEmptyEligibilityExclusions, getEmptyExclusionRule } from '../../exclusion-group-form-utils';


interface AddExclusionRowButtonProps {
    appendRule: (rule: EligibilityExclusionFormValues) => void;
    appendGroup: (group: EligibilityExclusionGroupFormValues) => void;
    disableGroupAdd?: boolean;
    rootNameBase?: string;
}

/**
 * Renders the add exclusion row button. Allows a user to select whether to add a rule or a nested group.
 *
 * @param appendRule      - A function to append a new rule to the group
 * @param appendGroup     - A function to append a new nested group to the group
 * @param disableGroupAdd - Whether to disable adding a group
 * @constructor
 */
export default function AddExclusionRowButton({
    appendRule, appendGroup, disableGroupAdd
}: AddExclusionRowButtonProps) {
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();

    return (
        <>
            <IconButton
                onClick={(event) => {
                    if (disableGroupAdd) {
                        appendRule(getEmptyExclusionRule());
                    } else {
                        setAnchorEl(event.currentTarget);
                    }
                }}
            >
                <AddCircleOutline color="secondary" />
            </IconButton>

            <Menu
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <MenuItem
                    onClick={() => {
                        appendRule(getEmptyExclusionRule());
                        setAnchorEl(undefined);
                    }}
                >
                    Add rule
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        const { operator, ...group } = getEmptyEligibilityExclusions(true);

                        group.exclusions?.push(getEmptyExclusionRule());

                        appendGroup(group);
                        setAnchorEl(undefined);
                    }}
                >
                    Add group
                </MenuItem>
            </Menu>
        </>
    );
}
