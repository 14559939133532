import { User } from '@api';
import {
    AddBox, Assignment, AssignmentReturn, CheckCircleOutline, Close, Comment, Description, Edit, ErrorOutline
} from '@mui/icons-material';
import {
    Button, IconButton, Paper, SvgIcon, SvgIconProps, TextField, Tooltip, Typography
} from '@mui/material';
import { Chip, ExpandableCard, FileInput } from '@tsp-ui/core/components';
import clsx from 'clsx';
import { useState } from 'react';

import EditConditionDialog from './EditConditionDialog';
import styles from './credit/CreditConditionsSummary.module.scss';


interface ConditionsSummaryProps {
    header: string;
    noConditions?: boolean;
    isCredit?: boolean;
    onStartClick?: () => void;
}

export default function ConditionsSummary({
    header, noConditions, isCredit, onStartClick
}: ConditionsSummaryProps) {
    const showDialog = false;

    return (
        <div className={styles.root}>
            <Typography
                variant="h6"
                className={styles.header}
            >
                {header}

                <Button>
                    Add condition
                </Button>
            </Typography>

            {noConditions ? (
                <Paper
                    variant="outlined"
                    className={styles.noConditions}
                >
                    {isCredit ? (
                        <>
                            <Typography>
                                This loan does not have any credit & liabilities conditions yet
                            </Typography>

                            <Button
                                variant="contained"
                                onClick={onStartClick}
                            >
                                Start credit & liabilities
                            </Button>
                        </>
                    ) : (
                        <>
                            <Typography>
                                This loan does not have any conditions yet
                            </Typography>

                            <Typography
                                variant="body2"
                                color="textSecondary"
                            >
                                Use the sidebar to navigate or click below to
                            </Typography>

                            <Button variant="contained">
                                Start underwriting
                            </Button>
                        </>
                    )}
                </Paper>
            ) : (
                <ConditionsGroup
                    label="Prior to close"
                    conditions={[
                        {
                            id: '1',
                            description: 'Provide signed letter of explanation along with documentation of the terms and current balance of any new debt for the following credit inquiry:',
                            detail: 'Some Creditor on 01/01/2023'
                        },
                        {
                            id: '2',
                            description: 'Obtain updated mortgage history for the following property:',
                            detail: '555 Fifth Ave Philadelphia, PA 19111'
                        }
                    ]}
                />
            )}

            <EditConditionDialog open={showDialog} />
        </div>
    );
}

interface ConditionsGroupProps {
    label: string;
    conditions: Partial<Condition>[];
}

function ConditionsGroup({ label, conditions }: ConditionsGroupProps) {
    return (
        <div>
            <Typography
                variant="body2"
                color="textSecondary"
            >
                {label}
            </Typography>

            <div className={styles.conditions}>
                {conditions.map(condition => (
                    <ConditionCard
                        key={condition.id}
                        condition={condition}
                        status="Pending"
                    />
                ))}
            </div>
        </div>
    );
}

interface ConditionCardProps {
    condition: Partial<Condition>;
    status: StatusChipProps['status'];
}

const acceptedFileTypes = [ 'pdf' ];

function ConditionCard({ condition: { description, detail }, status }: ConditionCardProps) {
    const [ files, setFiles ] = useState<File[]>([]);

    const [ hideDropZone, setHideDropZone ] = useState(true);
    const [ showAddNote, setShowAddNote ] = useState(false);
    const [ notes, setNotes ] = useState<string[]>([]);

    return (
        <ExpandableCard
            expandedContent={(
                <div className={styles.expandedContent}>
                    <div className={styles.leftColumn}>
                        <FileInput
                            compact
                            className={styles.fileInput}
                            title="document"
                            files={files}
                            acceptedFileTypes={acceptedFileTypes}
                            hideDropZone={hideDropZone}
                            onAddFiles={(newFiles) => setFiles([ ...files, ...newFiles ])}
                            onRemoveFile={(removedFile) => (
                                setFiles(files?.filter(({ name }) => name === removedFile.name))
                            )}
                        />

                        <Paper
                            variant="outlined"
                            className={styles.filledCard}
                        >
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                {notes.length || showAddNote ? 'Notes' : 'No notes'}
                            </Typography>

                            {notes.map((note) => (
                                <ActionCard
                                    description={note}
                                    date="30 minutes ago by you"
                                />
                            ))}

                            {showAddNote && (
                                <Paper
                                    variant="outlined"
                                    className={styles.addNoteCard}
                                >
                                    <Typography>
                                        Add new note
                                    </Typography>

                                    <TextField
                                        multiline
                                        label="Note"
                                    />

                                    <div className={styles.addNoteButtons}>
                                        <Tooltip title="Cancel">
                                            <IconButton onClick={() => setShowAddNote(false)}>
                                                <Close color="action" />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="done">
                                            <IconButton
                                                onClick={() => {
                                                    setNotes([ 'This file needs additional information' ]);
                                                    setShowAddNote(false);
                                                }}
                                            >
                                                <CheckCircleOutline color="success" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </Paper>
                            )}
                        </Paper>
                    </div>

                    <div>
                        <Paper
                            variant="outlined"
                            className={styles.filledCard}
                        >
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                Timeline
                            </Typography>

                            <ActionCard
                                Icon={Comment}
                                description="Note added"
                                date="30 minutes ago by you"
                            />

                            <Typography
                                variant="caption"
                                color="textSecondary"
                                className={styles.timelineSeparator}
                            >
                                2 hours
                            </Typography>

                            <ActionCard
                                Icon={Assignment}
                                description="Condition ready for review"
                                date="2.5 hours ago by Jim Corr"
                            />

                            <Typography
                                variant="caption"
                                color="textSecondary"
                                className={styles.timelineSeparator}
                            >
                                30 minutes
                            </Typography>

                            <ActionCard
                                Icon={Description}
                                iconColor="primary"
                                description="Documents added"
                                date="3 hours ago by Jim Corr"
                            />

                            <Typography
                                variant="caption"
                                color="textSecondary"
                                className={styles.timelineSeparator}
                            >
                                1 hour
                            </Typography>

                            <ActionCard
                                Icon={AssignmentReturn /* TODO rotateY(180deg) */}
                                description="Conditional approval submitted"
                                date="4 hours ago by you"
                            />

                            <Typography
                                variant="caption"
                                color="textSecondary"
                                className={styles.timelineSeparator}
                            >
                                2 hours
                            </Typography>

                            <ActionCard
                                Icon={AddBox}
                                description="Condition created"
                                date="6 hours ago by you"
                            />
                        </Paper>
                    </div>

                    <div className={styles.buttons}>
                        <Button
                            onClick={() => setShowAddNote(true)}
                            disabled={showAddNote}
                        >
                            Add note
                        </Button>

                        {hideDropZone && (
                            <Button onClick={() => setHideDropZone(false)}>
                                Add documents
                            </Button>
                        )}

                        <Button
                            variant="contained"
                            className={styles.clearButton}
                        >
                            Clear condition
                        </Button>
                    </div>
                </div>
            )}
            indentContent
        >
            <div className={styles.mainRow}>
                <div className={styles.description}>
                    <Typography variant="body2">
                        {description}
                    </Typography>

                    <Typography
                        variant="body2"
                        fontWeight={500}
                        className={styles.conditionDetail}
                    >
                        {detail}
                    </Typography>

                    {description?.startsWith('The following debts') && (
                        <Button
                            color="error"
                            size="small"
                            startIcon={<ErrorOutline color="error" />}
                            className={styles.exceptionButton}
                        >
                            Review exception request
                        </Button>
                    )}
                </div>

                <div className={styles.info}>
                    <div className={styles.statusContainer}>
                        <StatusChip status={status} />

                        <Tooltip
                            title="No documents"
                            enterDelay={0}
                        >
                            <Description
                                color="disabled"
                                fontSize="small"
                            />
                        </Tooltip>

                        <Tooltip
                            title="No notes"
                            enterDelay={0}
                        >
                            <Comment
                                color="disabled"
                                fontSize="small"
                            />
                        </Tooltip>

                        <IconButton className={styles.editButton}>
                            <Edit color="secondary" />
                        </IconButton>
                    </div>

                    <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                        whiteSpace="nowrap"
                        align="right"
                        className={styles.dateText}
                    >
                        {status === 'Pending' ? 'created' : 'submitted'} just now by&nbsp;

                        {status === 'Pending' ? 'you' : 'John Doe'}
                    </Typography>
                </div>
            </div>
        </ExpandableCard>
    );
}

interface ActionCardProps {
    Icon?: typeof SvgIcon;
    iconColor?: SvgIconProps['color'];
    description: string;
    date: string; // TODO make two props, one for date, one for actor
}

function ActionCard({
    Icon, iconColor = 'secondary', description, date
}: ActionCardProps) {
    return (
        <Paper
            variant="outlined"
            className={styles.card}
        >
            <Typography variant="body2">
                {Icon && (
                    <Icon
                        color={iconColor}
                        fontSize="small"
                        className={styles.actionCardIcon}
                    />
                )}

                {description}

                <Typography // TODO show tooltip of actual date
                    variant="caption"
                    align="right"
                    color="textSecondary"
                    className={styles.actionCardDate}
                >
                    {date}
                </Typography>
            </Typography>
        </Paper>
    );
}

interface StatusChipProps {
    status: 'Pending' | 'Needs review';
}

function StatusChip({ status }: StatusChipProps) {
    return (
        <Chip
            className={clsx(styles.statusChip, {
                [styles.needsReview]: status === 'Needs review'
            })}
        >
            {status}
        </Chip>
    );
}

enum ConditionStatus {
    // TODO
}

interface Condition {
    id: string;
    description: string;
    detail: string;
    category: ConditionCategory;
    group: ConditionGroup;
    stage: ConditionStage;
    source: ConditionSource;
    status: ConditionStatus;
    responsibility: ConditionResponsibility;

    exception?: ConditionException;
    documents?: Document[];
    events?: ConditionEvent[];
    notes?: Note[];
    includeOnApproval: boolean; // may not need - can this be based on responsibility?
}

interface ConditionEvent {
    type: ConditionEventType;
    date: Date;
    by: User;
}

interface ConditionException {
    date: Date;
    by: User;
    status: ConditionExceptionStatus;
}

interface Note {
    text: string;
    date: Date;
    by: User;
    internal: boolean;
}

enum ConditionCategory { // Byte Pro 'Class'
    LOAN_SET_UP = 'LOAN_SET_UP',
    NON_DELEGATED = 'NON_DELEGATED',
    DELEGATED = 'DELEGATED' // Post-closing
}

enum ConditionGroup { // UW menu items, Byte Pro 'Type'
    AUS = 'AUS',
    CREDIT = 'CREDIT',
    INCOME = 'INCOME',
    ASSETS = 'ASSETS',
    APPRAISAL = 'APPRAISAL',
    APPLICATION = 'APPLICATION',
    DISCLOSURE = 'DISCLOSURE',
    TITLE_FLOOD = 'TITLE_FLOOD',
    INSURANCE = 'INSURANCE',
    REO = 'REO'
}

enum ConditionStage {
    PRIOR_TO_DOCS = 'PRIOR_TO_DOCS',
    PRIOR_TO_CLOSING = 'PRIOR_TO_CLOSING',
    AT_CLOSING = 'AT_CLOSING',
    PRIOR_TO_PURCHASE = 'PRIOR_TO_PURCHASE',
    SUSPENSE = 'SUSPENSE'
}

enum ConditionSource {
    SYSTEM_ADDED = 'SYSTEM_ADDED',
    INVESTOR_OVERLAY = 'INVESTOR_OVERLAY',
    UNDERWRITER_ADDED = 'UNDERWRITER_ADDED'
}

enum ConditionResponsibility {
    CUSTOMER = 'CUSTOMER',
    UNDERWRITER = 'UNDERWRITER',
    CLOSER = 'CLOSER',
    ESCROW_COMPANY = 'ESCROW_COMPANY',
    LOAN_OFFICER = 'LOAN_OFFICER',
    LOAN_PROCESSOR = 'PROCESSOR',
    SHIPPER = 'SHIPPER',
    TITLE_COMPANY = 'TITLE_COMPANY'
}

enum ConditionEventType {
    CREATED = 'CREATED',
    REQUESTED = 'REQUESTED', // when loan goes to Conditional Approval status
    RECEIVED = 'RECEIVED', // optionally entered by responsibile party
    SUBMITTED = 'SUBMITTED', // when loan goes to Conditions Submitted status
    CLEARED = 'CLEARED'
}

enum ConditionExceptionStatus {
    APPROVED_BY_LENDER = 'APPROVED_BY_LENDER',
    APPROVED_BY_INVESTOR = 'APPROVED_BY_INVESTOR',
    DENIED_BY_LENDER = 'DENIED_BY_LENDER',
    DENIED_BY_INVESTOR = 'DENIED_BY_INVESTOR',
    PENDING_WITH_LENDER = 'PENDING_WITH_LENDER',
    PENDING_WITH_INVESTOR = 'PENDING_WITH_INVESTOR',
    WAIVED_NOT_REQUIRED = 'WAIVED_NOT_REQUIRED'
}
