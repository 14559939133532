import { EligibilityExclusions } from '@api';

import { apiUtils } from '../api-utils';


export function getExclusionOverlays(
    queryParams?: { loanProgramId?: string, investorId?: string }
): Promise<ExclusionOverlay[]> {
    return apiUtils.get('/exclusion-overlay', queryParams);
}

export function createExclusionOverlay(exclusionOverlay: Omit<ExclusionOverlay, 'id'>): Promise<ExclusionOverlay> {
    return apiUtils.post('/exclusion-overlay', exclusionOverlay);
}

export function updateExclusionOverlay(exclusionOverlay: ExclusionOverlay): Promise<ExclusionOverlay> {
    return apiUtils.put(`/exclusion-overlay/${exclusionOverlay.id}`, exclusionOverlay);
}

export enum ExclusionOverlayType {
    CLIENT = 'CLIENT',
    INVESTOR = 'INVESTOR',
    LOAN_PROGRAM = 'LOAN_PROGRAM'
}

export interface ExclusionOverlay {
    id: string;
    type: ExclusionOverlayType;
    investorIds?: ['ALL'] | string[];
    loanProgramIds?: ['ALL'] | string[];
    name: string;
    description: string;
    effectiveDate: string;
    expirationDate?: string;
    exclusions: EligibilityExclusions;
}
