import api from '@api';
import { Button, DialogContent, Paper } from '@mui/material';
import {
    DialogActions, RoutedDialog, RoutedDialogImplProps, usePageMessage
} from '@tsp-ui/core/components';
import { useParams } from '@tsp-ui/core/utils';
import { exclusionsToFormValues, formValuesToExclusions } from '@views/admin/investors/InvestorDetailPage/components/ExclusionGroupRow';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ExclusionsContext } from '../InternalInvestorDetailPage';

import styles from './EligibilityExclusionsDialog.module.scss';
import { EligibilityExclusionsFormValues, ExclusionGroupRow, getEmptyEligibilityExclusions } from './ExclusionGroupRow';


export default function EligibilityExclusionsDialog({ closeTo }: RoutedDialogImplProps) {
    const { investorID } = useParams<{ investorID: string }>();
    const navigate = useNavigate();
    const pageMessage = usePageMessage();

    const [ saveLoading, setSaveLoading ] = useState(false);

    const { exclusions, setExclusions } = useContext(ExclusionsContext);
    const defaultValues = exclusions ? exclusionsToFormValues(exclusions) : getEmptyEligibilityExclusions();
    const formMethods = useForm<EligibilityExclusionsFormValues>({ defaultValues });

    const handleSubmit = formMethods.handleSubmit(async formValues => {
        setSaveLoading(true);

        try {
            const newExclusions = exclusions
                ? await api.investors.updateExclusions(investorID, formValuesToExclusions(formValues))
                : await api.investors.createExclusions(investorID, formValuesToExclusions(formValues));

            setExclusions(newExclusions);

            navigate('..');

            pageMessage.success('Eligibility exclusions saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving eligibility exclusions', error);
        }

        setSaveLoading(false);
    });

    return (
        <RoutedDialog
            closeTo={closeTo}
            title="Edit eligibility exclusions"
            maxWidth={false}
            saveLoading={saveLoading}
        >
            <DialogContent>
                <Paper
                    variant="outlined"
                    className={styles.filterContainer}
                >
                    <form
                        id={EligibilityExclusionsDialog.formID}
                        onSubmit={handleSubmit}
                        noValidate
                    >
                        <FormProvider {...formMethods}>
                            <ExclusionGroupRow />
                        </FormProvider>
                    </form>
                </Paper>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    type="submit"
                    form={EligibilityExclusionsDialog.formID}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

EligibilityExclusionsDialog.formID = 'exclusions-form';
