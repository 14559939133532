import { apiUtils } from '../api-utils';


export function getReferenceGuideSections(): Promise<ReferenceGuideSection[]> {
    return apiUtils.get('/document/client/referenceGuide');
}

export function createReferenceGuideSection(referenceGuideSection: Omit<ReferenceGuideSection, 'id' | 'files'>): Promise<ReferenceGuideSection> {
    return apiUtils.post('/document/client/referenceGuide', referenceGuideSection);
}

export function deleteReferenceGuideSection(referenceGuideSectionID: string): Promise<void> {
    return apiUtils.delete(`/document/client/referenceGuide/${referenceGuideSectionID}`);
}

export function createReferenceGuide(referenceGuide: ReferenceGuideUpload): Promise<ReferenceGuideFile> {
    return apiUtils.post(`/document/client/referenceGuide/${referenceGuide.sectionId}`, referenceGuide);
}

export function updateReferenceGuide(referenceGuide: ReferenceGuideFile): Promise<ReferenceGuideFile> {
    return apiUtils.put(`/document/client/referenceGuide/${referenceGuide.sectionId}/${referenceGuide.id}`, referenceGuide);
}

export function deleteReferenceGuide(
    referenceGuideSectionID: string, referenceGuideID: string
): Promise<void> {
    return apiUtils.delete(`/document/client/referenceGuide/${referenceGuideSectionID}/${referenceGuideID}`);
}

export interface ReferenceGuideSection {
    id: string;
    title: string;
    files: ReferenceGuideFile[];
}

export interface ReferenceGuideFile {
    id: string;
    sectionId: string;
    title: string;
    description?: string;
    createdAt: string;
    url: string;
}

export interface ReferenceGuideUpload {
    title: string;
    description?: string;
    file: File;
    sectionId: string;
}

export function isReferenceGuide(object: Partial<ReferenceGuideFile> | undefined): object is ReferenceGuideFile {
    return object instanceof Object && object.hasOwnProperty('id');
}
