import { State } from '@api';
import { Edit } from '@mui/icons-material';
import {
    Button, Divider, IconButton, Link, Paper, Tooltip, Typography
} from '@mui/material';
import { AddressTypography, LabelGroup, LabeledValue } from '@tsp-ui/core/components';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { AUSUnderwritingTabs } from '../../AUSUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';

import styles from './AUSReviewSection.module.scss';


interface AUSReviewSectionProps {
    setTab: (tab: AUSUnderwritingTabs) => void;
    onTabComplete: (tab: AUSUnderwritingTabs, nextTab: AUSUnderwritingTabs) => void;
}

export default function AUSReviewSection({ onTabComplete }: AUSReviewSectionProps) {
    const [ infoMatches, setInfoMatches ] = useState<boolean>();
    const [ conditionsAdded, setConditionsAdded ] = useState<boolean>();

    useEffect(() => {
        if (conditionsAdded !==  undefined) {
            onTabComplete('review', 'summary');
        }
    }, [ conditionsAdded, onTabComplete ]);

    return (
        <UnderwritingStepSection
            title="AUS Review"
            addButton={(
                <Button>
                    Add condition
                </Button>
            )}
        >
            <Paper
                variant="outlined"
                className={styles.paper}
            >
                <div className={clsx(styles.fullWidth, styles.ausDetailsHeader)}>
                    <Typography
                        className={styles.ausTypeChip}
                        color="textSecondary"
                        fontWeight={500}
                    >
                        Fannie Mae DU
                    </Typography>

                    <Typography fontWeight={500}>
                        2002566947{' '}

                        <Typography
                            component="span"
                            color="textSecondary"
                        >
                            — submission{' '}

                            <Tooltip title="Submission date: 11/11/2021 04:30PM">
                                <Link underline="always">#1</Link>
                            </Tooltip>
                        </Typography>
                    </Typography>

                    <Typography
                        color="green"
                        fontWeight={500}
                        className={styles.statusText}
                    >
                        Approve/Eligible
                    </Typography>

                    <IconButton edge="end">
                        <Edit color="secondary" />
                    </IconButton>
                </div>

                <div className={clsx(styles.section, styles.loanDetailsSection)}>
                    <Typography className={styles.header}>
                        Loan details
                    </Typography>

                    <div>
                        <Typography fontWeight={500}>
                            $400,000
                        </Typography>

                        <Typography>
                            <span className={styles.hidden}>$</span>4.00%
                        </Typography>

                        <Typography>
                            <span className={styles.hidden}>$</span>360 mo
                        </Typography>
                    </div>

                    <LabelGroup>
                        <LabeledValue
                            {...labeledValueProps}
                            label="LTV:"
                            value="70.00%"
                        />

                        <LabeledValue
                            {...labeledValueProps}
                            label="CLTV:"
                            value="70.00%"
                        />

                        <LabeledValue
                            {...labeledValueProps}
                            label="HCLTV:"
                            value="70.00%"
                        />
                    </LabelGroup>

                    <LabelGroup>
                        <LabeledValue
                            {...labeledValueProps}
                            label="Front DTI:"
                            value="15.26%"
                        />

                        <LabeledValue
                            {...labeledValueProps}
                            label="Back DTI:"
                            value="39.79%"
                        />
                    </LabelGroup>

                    <Divider className={styles.divider} />

                    <LabeledValue
                        {...verticalLabeledValueProps}
                        label="Loan purpose"
                        value="Purchase"
                    />

                    <LabeledValue
                        {...verticalLabeledValueProps}
                        label="Occupancy"
                        value="Primary"
                    />

                    <LabeledValue
                        {...verticalLabeledValueProps}
                        label="Community lending"
                        value="Home ready"
                    />

                    <Typography className={styles.header}>
                        Property
                    </Typography>

                    <LabeledValue
                        {...verticalLabeledValueProps}
                        label="Purchase Price"
                        value="$500,000"
                    />

                    <LabeledValue
                        {...verticalLabeledValueProps}
                        className={styles.address}
                        label="Property address"
                        value={(
                            <AddressTypography
                                variant="inherit"
                                address={{
                                    street: '1234 Mystery Ln',
                                    city: 'Nowhere',
                                    state: State.MI,
                                    zip: '12345'
                                }}
                            />
                        )}
                    />
                </div>

                <div className={styles.section}>
                    <Typography className={styles.header}>
                        Appraisal
                    </Typography>

                    <LabeledValue
                        {...verticalLabeledValueProps}
                        label="Required appraisal type"
                        value="Full appraisal"
                    />

                    <LabeledValue
                        {...verticalLabeledValueProps}
                        label="Appraised value"
                        value="$400,000"
                    />

                    <LabeledValue
                        {...verticalLabeledValueProps}
                        label="Property type"
                        value="Single family (attached)"
                    />

                    <Typography className={styles.header}>
                        Asset requirements
                    </Typography>

                    <LabeledValue
                        {...verticalLabeledValueProps}
                        label="Required reserves"
                        value="$30,000"
                    />

                    <LabeledValue
                        {...verticalLabeledValueProps}
                        label="Statements required"
                        value="2"
                    />

                    <Typography className={styles.header}>
                        Credit report numbers (last 4 digits)
                    </Typography>

                    <LabelGroup>
                        <LabeledValue
                            {...labeledValueProps}
                            label="Jane Borrower:"
                            value="1234"
                        />
                    </LabelGroup>

                    <LabelGroup>
                        <LabeledValue
                            {...labeledValueProps}
                            label="John Borrower:"
                            value="4321"
                        />
                    </LabelGroup>
                </div>
            </Paper>

            <UnderwritingYesNoQuestionCard
                question="Does the above information match the AUS results?"
                onAnswered={setInfoMatches}
                answer={infoMatches}
            />

            {infoMatches && (
                <UnderwritingYesNoQuestionCard
                    question="Have conditions been added to address all AUS findings?"
                    onAnswered={setConditionsAdded}
                    answer={conditionsAdded}
                />
            )}
        </UnderwritingStepSection>
    );
}

const labeledValueProps = {
    variants: {
        label: 'body2',
        value: 'body1'
    }
} as const;

const verticalLabeledValueProps = {
    ...labeledValueProps,
    variant: 'vertical'
} as const;
