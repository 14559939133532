export enum ProductEventType {
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED',
    UPDATED = 'UPDATED'
};

export const productEventTypeDisplay = {
    [ProductEventType.ACTIVATED]: 'Product activated',
    [ProductEventType.DEACTIVATED]: 'Product deactivated',
    [ProductEventType.UPDATED]: 'Product updated'
};
