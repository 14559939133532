import { Account, AuthUser, UserType } from '@api';
import { rest } from 'msw';

import logoUrl from '../../images/logo-customer.png';
import { getMockUrl } from '../../mocks/getMockUrl';


export const mocks = [
    rest.get(getMockUrl('/auth/me'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            firstName: 'John',
            lastName: 'Smith',
            email: 'johnsmith@premicorr.com',
            isInternal: true,
            clientAccounts: [ accounts[0] ],
            customerAccounts: [ accounts[1] ],
            internalPermissions: [] // TODO permissions
        } as AuthUser)
    ))),
    rest.get(getMockUrl('/auth/client/:clientID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({ accessToken: 'mockToken' })
    )))
];

export const accounts: Account[] = [
    {
        id: '100',
        name: 'Lendalot',
        logoUrl,
        accountUserType: UserType.CLIENT
    },
    {
        id: '100',
        name: 'Lendalot',
        logoUrl,
        accountUserType: UserType.CUSTOMER,
        customerId: '1000',
        customerName: 'ABC Mortgage'
    }
];
