import api, { PurchaseAdviceFee, feeTypeDisplay } from '@api';
import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import { IconButton, Paper, Typography } from '@mui/material';
import {
    formatCurrency, removeItemById, useConfirm, usePageMessage
} from '@tsp-ui/core/utils';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import styles from './PurchaseAdviceFeeCard.module.scss';
import { PurchaseAdviceContext } from './PurchaseAdviceManagementPage';


interface PurchaseAdviceFeeCardProps {
    fee: PurchaseAdviceFee;
}

export function PurchaseAdviceFeeCard({ fee }: PurchaseAdviceFeeCardProps) {
    const { purchaseAdviceFees, setPurchaseAdviceFees } = useContext(PurchaseAdviceContext);

    const confirm = useConfirm();
    const pageMessage = usePageMessage();

    return (
        <Paper
            elevation={0}
            className={styles.feeCard}
        >
            <div>
                <Typography>
                    {feeTypeDisplay[fee.feeType]}
                </Typography>

                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {formatCurrency(fee.feeAmount)}
                </Typography>
            </div>

            <div>
                <IconButton
                    component={Link}
                    to={`fees/${fee.id}`}
                >
                    <Edit color="secondary" />
                </IconButton>

                <IconButton
                    edge="end"
                    onClick={async () => {
                        if (await confirm('Are you sure you want to delete this fee?')) {
                            try {
                                await api.purchaseAdvice.deletePurchaseAdviceFee(fee.id);

                                setPurchaseAdviceFees(removeItemById(purchaseAdviceFees, fee.id));
                            } catch (error) {
                                pageMessage.handleApiError('An error occurred while deleting the fee', error);
                            }
                        }
                    }}
                >
                    <RemoveCircleOutline color="error" />
                </IconButton>
            </div>
        </Paper>
    );
}
