import {
    Address, Contact, CustomerPendingSteps, CustomerStatus
} from '..';
import { apiUtils } from '../api-utils';

import { Branch } from './branches/branches-api';
import { EligibleProductsConfig } from './eligibleProducts/eligibleProducts-api';


export * as branches from './branches/branches-api';
export * as checklist from './checklist/checklist-api';
export * as compensation from './compensation/compensation-api';
export * as contacts from './contacts/contacts-api';
export * as eventHistory from './eventHistory/eventHistory-api';
export * as licenses from './licenses/licenses-api';
export * as eligibleProducts from './eligibleProducts/eligibleProducts-api';
export * as wireInfo from './wireInfo/wireInfo-api';

export function getCustomers(): Promise<CustomerSummary[]> {
    return apiUtils.get('/customer');
}

export function addCustomer(customer: CustomerDetails): Promise<CustomerDetails> {
    return apiUtils.post('/customer', customer);
}

export function getCustomer(customerID: string): Promise<CustomerDetails> {
    return apiUtils.get(`/customer/${customerID}`);
}

export function updateCustomer(customer: Partial<CustomerDetails>): Promise<CustomerDetails> {
    return apiUtils.put(`/customer/${customer.id}`, customer);
}

export interface CustomerSummary {
    id: string;
    name: string;
    status: CustomerStatus;

    dbaNames?: string[];
    nmlsId: string;

    branches?: Branch[];
    pendingSteps?: CustomerPendingStep[];
}

export interface CustomerDetails extends CustomerSummary {
    taxID: string;
    address: Address;

    productConfiguration: EligibleProductsConfig | null;
    primaryContact: Contact;
    counterpartyAnalystId: string;
    primaryAccountExecId: string;
    secondaryAccountExecId: string;
    accountManagerId: string;
}

export interface CustomerPendingStep {
    id: string;
    name: CustomerPendingSteps;
    isRequired: boolean;
}
