import api, {
    Permission, PermissionCategory, PermissionLevel, permissionCategoryDisplay
} from '@api';
import { HelpOutline } from '@mui/icons-material';
import {
    Button, DialogContent, Tooltip, Typography
} from '@mui/material';
import {
    DialogActions, FilterTextField,
    IconTypography,
    Radio, RadioGroup, RoutedDialog, RoutedDialogProps
} from '@tsp-ui/core/components';
import { usePageMessage, useParams } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { AdminRouteParams } from '../../components/AdminPageTemplate';
import { RoleDetailPageContext } from '../RoleDetailPage';

import styles from './EditPermissionsDialog.module.scss';


const EDIT_PERMISSIONS_FORM = 'edit-permissions-form';

interface PermissionsFormValues {
    permissions: Permission[];
}

export default function EditPermissionsDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const { roleID } = useParams<AdminRouteParams<'role'>>();

    const { accountUserType, customerId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(false);

    const navigate = useNavigate();
    const { permissions, updatePermissions } = useContext(RoleDetailPageContext);

    const [ searchTerm, setSearchTerm ] = useState('');
    const [ debouncedTerm ] = useDebounce(searchTerm, 300);

    const filteredPermissions = permissions.filter(({ description }) => (
        description.toLocaleLowerCase().includes(debouncedTerm)
    ));

    const pageMessage = usePageMessage();
    const formMethods = useForm<PermissionsFormValues>({ defaultValues: { permissions } });

    const handleSubmit = formMethods.handleSubmit(async ({ permissions }) => {
        setLoading(true);

        try {
            updatePermissions(
                await api.roles.editPermissions(accountUserType, parseInt(roleID), permissions, customerId)
            );

            navigate(props.closeTo);

            pageMessage.success('Role permissions saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the role permissions', error);
        }

        setLoading(false);
    });

    return (
        <RoutedDialog
            {...props}
            title="Edit Permissions"
        >
            <DialogContent>
                <div className={styles.buttonContainer}>
                    <FilterTextField
                        autoFocus
                        placeholder="Filter permissions"
                        helperText="Filter by description"
                        onChange={e => setSearchTerm(e.target.value.toLocaleLowerCase())}
                    />
                </div>

                <form
                    noValidate
                    id={EDIT_PERMISSIONS_FORM}
                    onSubmit={handleSubmit}
                >
                    <FormProvider {...formMethods}>
                        <div className={styles.permissions}>
                            <div className={clsx(styles.row, styles.headerRow)}>
                                <div />

                                <Typography fontWeight={500}>
                                    Always
                                </Typography>

                                <IconTypography
                                    iconPosition="after"
                                    icon={(
                                        <Tooltip title="Gives permission when user is assigned to a loan">
                                            <HelpOutline color="primary" />
                                        </Tooltip>
                                    )}
                                >
                                    When assigned
                                </IconTypography>

                                <Typography fontWeight={500}>
                                    Never
                                </Typography>
                            </div>

                            {Object.keys(PermissionCategory).map((category) => (
                                <CategoryPermissions
                                    key={category}
                                    category={category as PermissionCategory}
                                    permissions={filteredPermissions}
                                />
                            ))}
                        </div>
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button onClick={() => navigate(props.closeTo)}>
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    form={EDIT_PERMISSIONS_FORM}
                    type="submit"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

interface CategoryPermissionsProps {
    category: PermissionCategory;
    permissions: Permission[];
}

function CategoryPermissions({ category, permissions }: CategoryPermissionsProps) {
    const categoryPermissions = permissions.filter(
        perm => perm.category === category
    );

    return categoryPermissions.length ? (
        <>
            <Typography className={styles.sectionHeader}>
                {permissionCategoryDisplay[category]}
            </Typography>

            {categoryPermissions.map(({
                allowAssigned, description, id
            }) => (
                <div
                    className={styles.row}
                    key={id}
                >
                    <Typography className={styles.permissionTypography}>
                        {description}
                    </Typography>

                    <RadioGroup<PermissionsFormValues>
                        name={`permissions.${permissions.findIndex(permission => id === permission.id)}.level`}
                        className={styles.radioGroup}
                    >
                        <Radio
                            value={PermissionLevel.ALWAYS}
                            label=""
                        />

                        {allowAssigned ? (
                            <Radio
                                value={PermissionLevel.WHEN_ASSIGNED}
                                label=""
                            />
                        ) : <div />}

                        <Radio
                            value={PermissionLevel.NEVER}
                            label=""
                        />
                    </RadioGroup>
                </div>
            ))}
        </>
    ) : null;
}
