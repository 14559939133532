import { Paper } from '@mui/material';
import {
    Children, ReactElement, ReactNode, cloneElement
} from 'react';

import styles from './UnderwritingQuestionGroup.module.scss';
import { UnderwritingQuestionCardProps } from './components/UnderwritingQuestionCard';


interface UnderwritingQuestionGroupProps<T> {
    children: false | undefined | ReactElement<UnderwritingQuestionCardProps<T>>
        | (ReactElement<UnderwritingQuestionCardProps<T>> | false | undefined)[];
    otherChildren?: ReactNode;
}

export default function UnderwritingQuestionGroup<T>({ children, otherChildren }: UnderwritingQuestionGroupProps<T>) {
    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            {Children.map(children, (child) => child && cloneElement(child, {
                buttonSize: 'small',
                className: styles.question
            }))}

            {otherChildren}
        </Paper>
    );
}
