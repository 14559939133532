import { Contact, contactResponsibilityDisplay } from '@api';
import { AdminPanelSettingsOutlined, EmailOutlined, PersonOutlined } from '@mui/icons-material';
import { Link as MuiLink, Tooltip, Typography } from '@mui/material';
import { PhoneTypography } from '@tsp-ui/core/components';
import clsx from 'clsx';

import styles from './ContactDisplay.module.scss';


interface ContactDisplayProps {
    className?: string;
    contact: undefined | (Pick<Contact, 'name' | 'phone' | 'email'> & Partial<Pick<Contact, 'responsibilities'>>);
}

export default function ContactDisplay({ className, contact }: ContactDisplayProps) {
    const { responsibilities } = contact || {};

    return !contact ? (
        <span>None</span>
    ) : (
        <div className={clsx(styles.root, className)}>
            <Typography variant="body2">
                <PersonOutlined
                    color="primary"
                    fontSize="small"
                />

                {contact.name}
            </Typography>

            <PhoneTypography
                showIcon
                variant="caption"
            >
                {contact.phone}
            </PhoneTypography>

            <Typography variant="caption">
                <EmailOutlined
                    color="primary"
                    fontSize="small"
                />

                <MuiLink href={`mailto:${contact.email}`}>{contact.email}</MuiLink>
            </Typography>

            {!!responsibilities?.length && (
                <Typography
                    variant="caption"
                >
                    <Tooltip title="Responsibilities">
                        <AdminPanelSettingsOutlined
                            color="primary"
                            fontSize="small"
                        />
                    </Tooltip>

                    {responsibilities.map(
                        (responsibility, index) => (
                            `${contactResponsibilityDisplay[responsibility]}${
                                index < responsibilities.length - 1 ? ', ' : ''
                            }`)
                    )}
                </Typography>
            )}
        </div>
    );
}
