import api, { ClientInvestor, ExclusionOverlay, InvestorStatus } from '@api';
import { RoutedDialogManager, usePageMessage } from '@tsp-ui/core/components';
import {
    getItemById, replaceItemById, useAsyncEffect, useParams
} from '@tsp-ui/core/utils';
import { InvestorDetailPageBase } from '@views/admin/investors/InvestorDetailPage/InvestorDetailPageBase';
import { LoanProgramSection } from '@views/admin/investors/InvestorDetailPage/components/LoanProgramSection';
import { useCallback, useContext, useState } from 'react';

import { LoanProgramsContext } from '../investors/InternalInvestorDetailRoutes';

import { ExclusionOverlayContext } from './InvestorEligibilityManagementPage';
import { InvestorEligibilityContext } from './InvestorEligibilityRoutes';
import { EditClientInvestorDialog } from './components/EditClientInvestorDialog';
import ExclusionOverlaySection from './components/ExclusionOverlaySection';
import { overlayDialogRoutes } from './overlayDialogRoutes';


const { ACTIVE, INACTIVE } = InvestorStatus;

export default function ClientInvestorDetailPage() {
    const { investorID } = useParams<{ investorID: string }>();

    const pageMessage = usePageMessage();

    const [ overlaysLoading, setOverlaysLoading ] = useState(true);
    const { loanPrograms } = useContext(LoanProgramsContext);

    const { investorID: investorId } = useParams();
    const [ overlays, setOverlays ] = useState<ExclusionOverlay[]>([]);
    useAsyncEffect(useCallback(async () => {
        try {
            setOverlays(await api.overlay.getExclusionOverlays({ investorId }));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching exclusion overlays.', error);
        }

        setOverlaysLoading(false);
    }, [
        investorId, pageMessage, setOverlaysLoading
    ]));

    const { investors, setInvestors } = useContext(InvestorEligibilityContext);
    const [ investor, setInvestor ] = [
        getItemById(investors || [], investorID),
        (updatedInvestor: ClientInvestor) => setInvestors(replaceItemById(investors || [], updatedInvestor))
    ];

    return (
        <ExclusionOverlayContext.Provider
            value={{
                overlays,
                setOverlays
            }}
        >
            <InvestorDetailPageBase
                firstBreadcrumb="Investor Eligibility"
                loading={overlaysLoading || !loanPrograms}
                investor={investor}
                setInvestor={setInvestor}
                onUpdateStatus={investor && (() => api.client.investors.updateStatus(
                    investor.id, investor.status === ACTIVE
                        ? INACTIVE
                        : ACTIVE
                ))}
            >
                <RoutedDialogManager
                    routes={{
                        edit: EditClientInvestorDialog,
                        ...overlayDialogRoutes
                    }}
                />

                <LoanProgramSection />

                <ExclusionOverlaySection overlays={overlays} />
            </InvestorDetailPageBase>
        </ExclusionOverlayContext.Provider>
    );
}
