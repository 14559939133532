import { WarehouseRelationship } from '@api';
import { Link, Typography } from '@mui/material';
import { PhoneTypography } from '@tsp-ui/core/components';


export default function WarehousesTableRow({ values }: { values: WarehouseRelationship }) {
    return (
        <tr>
            <Typography
                component="td"
                padding="4px"
                variant="body2"
                width="100%"
                noWrap
            >
                {values.contact}
            </Typography>

            <Typography
                component="td"
                padding="4px"
                variant="body2"
                noWrap
            >
                {values.lenderName}
            </Typography>

            <Typography
                component="td"
                padding="4px"
                variant="body2"
                noWrap
            >
                {values.lineAmount}
            </Typography>

            <Typography
                component="td"
                padding="4px"
                variant="body2"
                noWrap
            >
                <Link href={`mailto:${values.emailAddress}`}>
                    {values.emailAddress}
                </Link>
            </Typography>

            <PhoneTypography
                component="td"
                padding="4px"
                variant="body2"
                noWrap
            >
                {values.phone}
            </PhoneTypography>
        </tr>
    );
}
