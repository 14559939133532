import { Paper, Typography } from '@mui/material';
import { LabelGroup, LabeledValue } from '@tsp-ui/core/components';
import { ReactNode, useEffect, useState } from 'react';

import { FraudReportUnderwritingTabs } from '../../FraudReportUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';

import styles from './FraudPartiesSection.module.scss';


interface FraudPartiesSectionProps {
    setTab: (tab: FraudReportUnderwritingTabs) => void;
    onTabComplete: (tab: FraudReportUnderwritingTabs, nextTab: FraudReportUnderwritingTabs) => void;
}

export default function FraudPartiesSection({ onTabComplete }: FraudPartiesSectionProps) {
    const [ appraiserValidated, setAppraiserValidated ] = useState<boolean>();
    const [ appraisalCompanyValidated, setAppraisalCompanyValidated ] = useState<boolean>();
    const [ loanOfficerValidated, setLoanOfficerValidated ] = useState<boolean>();
    const [ originatingCompanyValidated, setOriginatingCompanyValidated ] = useState<boolean>();
    const [ realtor1Validated, setRealtor1Validated ] = useState<boolean>();
    const [ realtor2Validated, setRealtor2Validated ] = useState<boolean>();
    const [ seller1Validated, setSeller1Validated ] = useState<boolean>();
    const [ seller2Validated, setSeller2Validated ] = useState<boolean>();
    const [ buyer1Validated, setBuyer1Validated ] = useState<boolean>();
    const [ buyer2Validated, setBuyer2Validated ] = useState<boolean>();

    useEffect(() => {
        if (buyer2Validated !== undefined) {
            onTabComplete('parties', 'summary');
        }
    }, [ buyer2Validated, onTabComplete ]);

    return (
        <UnderwritingStepSection title="Parties review">
            <Paper
                variant="outlined"
                className={styles.paper}
            >
                <Typography className={styles.partiesQuestion}>
                    Have the following parties been validated on the fraud report?
                </Typography>

                <LabeledPaper label="Appraiser">
                    <UnderwritingYesNoQuestionCard
                        question={(
                            <>
                                <Typography>
                                    Joe Appraiser
                                </Typography>

                                <LabelGroup className={styles.labelGroup}>
                                    <LabeledValue
                                        label="License #:"
                                        value="3452363"
                                    />
                                </LabelGroup>
                            </>
                        )}
                        onAnswered={setAppraiserValidated}
                        answer={appraiserValidated}
                        className={styles.card}
                        buttonSize="small"
                    />
                </LabeledPaper>

                {appraiserValidated !== undefined && (
                    <LabeledPaper label="Appraisal company">
                        <UnderwritingYesNoQuestionCard
                            question="Appraisals R Us"
                            onAnswered={setAppraisalCompanyValidated}
                            answer={appraisalCompanyValidated}
                            className={styles.card}
                            buttonSize="small"
                        />
                    </LabeledPaper>
                )}

                {appraisalCompanyValidated !== undefined && (
                    <LabeledPaper label="Loan officer">
                        <UnderwritingYesNoQuestionCard
                            question={(
                                <>
                                    <Typography>
                                        John Officer
                                    </Typography>

                                    <LabelGroup className={styles.labelGroup}>
                                        <LabeledValue
                                            label="NMLS ID:"
                                            value="567865"
                                        />
                                    </LabelGroup>
                                </>
                            )}
                            onAnswered={setLoanOfficerValidated}
                            answer={loanOfficerValidated}
                            className={styles.card}
                            buttonSize="small"
                        />
                    </LabeledPaper>
                )}

                {loanOfficerValidated !== undefined && (
                    <LabeledPaper label="Originating company">
                        <UnderwritingYesNoQuestionCard
                            question={(
                                <>
                                    <Typography>
                                        Mortgages R Us
                                    </Typography>

                                    <LabelGroup className={styles.labelGroup}>
                                        <LabeledValue
                                            label="NMLS ID:"
                                            value="689454"
                                        />
                                    </LabelGroup>
                                </>
                            )}
                            onAnswered={setOriginatingCompanyValidated}
                            answer={originatingCompanyValidated}
                            className={styles.card}
                            buttonSize="small"
                        />
                    </LabeledPaper>
                )}

                {originatingCompanyValidated !== undefined && (
                    <LabeledPaper label="Realtors">
                        <UnderwritingYesNoQuestionCard
                            question={(
                                <>
                                    Jack Realtor{' '}

                                    <Typography
                                        variant="body2"
                                        component="span"
                                        color="textSecondary"
                                    >
                                        (buyer's agent)
                                    </Typography>
                                </>
                            )}
                            onAnswered={setRealtor1Validated}
                            answer={realtor1Validated}
                            className={styles.card}
                            buttonSize="small"
                        />

                        {realtor1Validated !== undefined && (
                            <UnderwritingYesNoQuestionCard
                                question={(
                                    <>
                                        Jill Realtor{' '}

                                        <Typography
                                            variant="body2"
                                            component="span"
                                            color="textSecondary"
                                        >
                                            (seller's agent)
                                        </Typography>
                                    </>
                                )}
                                onAnswered={setRealtor2Validated}
                                answer={realtor2Validated}
                                className={styles.card}
                                buttonSize="small"
                            />
                        )}
                    </LabeledPaper>
                )}

                {realtor2Validated !== undefined && (
                    <LabeledPaper label="Sellers">
                        <UnderwritingYesNoQuestionCard
                            question="Billy Seller"
                            onAnswered={setSeller1Validated}
                            answer={seller1Validated}
                            className={styles.card}
                            buttonSize="small"
                        />

                        {seller1Validated !== undefined && (
                            <UnderwritingYesNoQuestionCard
                                question="Bessie Seller"
                                onAnswered={setSeller2Validated}
                                answer={seller2Validated}
                                className={styles.card}
                                buttonSize="small"
                            />
                        )}
                    </LabeledPaper>
                )}

                {seller2Validated !== undefined && (
                    <LabeledPaper label="Buyers">
                        <UnderwritingYesNoQuestionCard
                            question="George Buyer"
                            onAnswered={setBuyer1Validated}
                            answer={buyer1Validated}
                            className={styles.card}
                            buttonSize="small"
                        />

                        {buyer1Validated !== undefined && (
                            <UnderwritingYesNoQuestionCard
                                question="Jackie Buyer"
                                onAnswered={setBuyer2Validated}
                                answer={buyer2Validated}
                                className={styles.card}
                                buttonSize="small"
                            />
                        )}
                    </LabeledPaper>
                )}
            </Paper>
        </UnderwritingStepSection>
    );
}

interface LabeledPaperProps {
    children: ReactNode;
    label: string;
}

function LabeledPaper({ children, label }: LabeledPaperProps) {
    return (
        <Paper
            variant="outlined"
            className={styles.labeledPaper}
        >
            <Typography
                variant="body2"
                color="textSecondary"
                className={styles.floatingLabel}
            >
                {label}
            </Typography>

            {children}
        </Paper>
    );
}
