import { List, ListItemButtonProps } from '@mui/material';
import { ListItemLink } from '@tsp-ui/core/components';
import { useParams } from '@tsp-ui/core/utils';
import clsx from 'clsx';

import { UnderwritingTab } from '../../LoanDetailPage';

import styles from './UnderwritingSidebar.module.scss';


export default function UnderwritingSidebar() {
    // Destructure needs to happen within render here to prevent accessing UnderwritingTab from happening prior
    // to initialization (it's defined in LoanDetailPage, which imports this file before it's defined)
    const {
        AUS, CREDIT_LIABILITIES, INCOME_ASSETS, APPRAISAL, TITLE, SALES_CONTRACT, FLOOD_HAZARD, FRAUD
    } = UnderwritingTab;

    const { underwritingTab } = useParams();

    return (
        <List
            disablePadding
            className={styles.root}
        >
            <UnderwritingMenuListItem
                selected={!underwritingTab}
                disableExtraWidth
            >
                All conditions
            </UnderwritingMenuListItem>

            <UnderwritingMenuListItem tab={AUS}>
                AUS
            </UnderwritingMenuListItem>

            <UnderwritingMenuListItem tab={SALES_CONTRACT}>
                Sales contract
            </UnderwritingMenuListItem>

            <UnderwritingMenuListItem tab={CREDIT_LIABILITIES}>
                Credit & Liabilities
            </UnderwritingMenuListItem>

            <UnderwritingMenuListItem tab={INCOME_ASSETS}>
                Income & Assets
            </UnderwritingMenuListItem>

            <UnderwritingMenuListItem tab={APPRAISAL}>
                Appraisal
            </UnderwritingMenuListItem>

            <UnderwritingMenuListItem>
                REO Analysis
            </UnderwritingMenuListItem>

            <UnderwritingMenuListItem tab={TITLE}>
                Title & MI
            </UnderwritingMenuListItem>

            <UnderwritingMenuListItem tab={FLOOD_HAZARD}>
                Flood & Hazard
            </UnderwritingMenuListItem>

            <UnderwritingMenuListItem tab={FRAUD}>
                Fraud report
            </UnderwritingMenuListItem>

            <UnderwritingMenuListItem>
                Secondary financing
            </UnderwritingMenuListItem>

            <UnderwritingMenuListItem>
                Specialty issues
            </UnderwritingMenuListItem>
        </List>
    );
}

interface ListItemProps extends ListItemButtonProps {
    // TODO make required when all list items are completed
    tab?: UnderwritingTab;
    selected?: boolean;
    disableExtraWidth?: boolean;
}

export function UnderwritingMenuListItem({
    children, tab, selected, disableExtraWidth, ...otherProps
}: ListItemProps) {
    const { underwritingTab } = useParams();

    return (
        <ListItemLink
            to={`${underwritingTab ? './../' : ''}${tab || ''}`}
            selected={selected !== undefined ? selected : !!underwritingTab && underwritingTab === tab}
            classes={{
                selected: clsx(styles.selected, {
                    [styles.extraWidth]: !disableExtraWidth
                })
            }}
            {...otherProps}
        >
            {children}
        </ListItemLink>
    );
}
