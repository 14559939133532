import { State } from '@api';
import { Button, Typography } from '@mui/material';
import { AddressFieldset, AddressTypography } from '@tsp-ui/core/components';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { AppraisalUnderwritingTabs } from '../../AppraisalUnderwritingPage';
import UnderwritingAnswerButtons from '../UnderwritingAnswerButtons';
import UnderwritingQuestionCard from '../UnderwritingQuestionCard';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';

import styles from './AppraisalPropertySection.module.scss';


interface AppraisalPropertySectionProps {
    setTab: (tab: AppraisalUnderwritingTabs) => void;
    onTabComplete: (tab: AppraisalUnderwritingTabs, nextTab: AppraisalUnderwritingTabs) => void;
}

export default function AppraisalPropertySection({ onTabComplete }: AppraisalPropertySectionProps) {
    const [ appraisalType, setAppraisalType ] = useState<string>();
    const [ propertyAddressMatches, setPropertyAddressMatches ] = useState<boolean>();

    const [ showPropertyType, setShowPropertyType ] = useState<boolean>();
    const [ propertyType, setPropertyType ] = useState<string>();
    const [ attached, setAttached ] = useState<boolean>();

    useEffect(() => {
        if (propertyType === 'CO-OP' || propertyType === 'Manufactured home' || attached !== undefined) {
            onTabComplete('property', 'detail');
        }
    }, [
        propertyType, attached, onTabComplete
    ]);

    useEffect(() => {
        if (propertyAddressMatches) {
            setShowPropertyType(true);
        }
    }, [ propertyAddressMatches ]);

    const formMethods = useForm(); // TODO

    return (
        <UnderwritingStepSection title="Appraisal property">
            <UnderwritingQuestionCard
                question="What type of appraisal has been performed?"
                onAnswered={setAppraisalType}
                answer={appraisalType}
                buttons={appraisalTypeButtons}
            />

            {appraisalType && (
                <UnderwritingYesNoQuestionCard
                    question={(
                        <>
                            <Typography className={styles.addressTypography}>
                                Does the appraisal property address match the following address from AUS?
                            </Typography>

                            <AddressTypography
                                align="center"
                                address={{
                                    street: '120 Lake Dr',
                                    city: 'West Greenwich',
                                    state: State.RI,
                                    zip: '02817'
                                }}
                            />
                        </>
                    )}
                    onAnswered={setPropertyAddressMatches}
                    answer={propertyAddressMatches}
                    showChildren={(answer) => answer === false}
                >
                    <FormProvider {...formMethods}>
                        <AddressFieldset
                            className={styles.addressFields}
                            fieldNames={{
                                street: 'street',
                                line2: 'line2',
                                city: 'city',
                                state: 'state',
                                zip: 'zip'
                            }}
                        />
                    </FormProvider>

                    <Button
                        variant="contained"
                        className={styles.nextButton}
                        onClick={() => setShowPropertyType(true)}
                    >
                        Next
                    </Button>
                </UnderwritingYesNoQuestionCard>
            )}

            {showPropertyType && (
                <UnderwritingQuestionCard
                    question="What type of property is the appraisal for?"
                    onAnswered={setPropertyType}
                    answer={propertyType}
                    buttons={propertyTypeButtons}
                    showChildren={(answer) => answer === 'Single family' || answer === 'Condo' || answer === 'PUD'}
                >
                    <Typography className={styles.attachedQuestion}>
                        Is the property attached or detached?
                    </Typography>

                    <UnderwritingAnswerButtons
                        buttons={[
                            {
                                label: 'Attached',
                                value: true
                            },
                            {
                                label: 'Detached',
                                value: false
                            }
                        ]}
                        value={attached}
                        setValue={setAttached}
                    />
                </UnderwritingQuestionCard>
            )}
        </UnderwritingStepSection>
    );
}

const appraisalTypeButtons = [
    {
        label: '1004',
        value: '1004',
        tooltip: 'Full appraisal'
    },
    {
        label: '2055',
        value: '2055',
        tooltip: 'Exterior only'
    },
    {
        label: '1073',
        value: '1073',
        tooltip: 'Condo - Full appraisal'
    },
    {
        label: '1075',
        value: '1075',
        tooltip: 'Condo - Exterior only'
    },
    {
        label: '1025',
        value: '1025`',
        tooltip: '2-4 Unit full appraisal'
    },
    {
        label: '1025 exterior',
        value: '1025e`',
        tooltip: '2-4 Unit exterior only'
    },
    {
        label: '2090',
        value: '2090`',
        tooltip: 'Co-Op appraisal'
    }
];

export const propertyTypeButtons = [ // TODO enum, update other usages
    {
        label: 'Single family',
        value: 'Single family'
    },
    {
        label: 'Condo',
        value: 'Condo'
    },
    {
        label: 'PUD',
        value: 'PUD'
    },
    {
        label: 'CO-OP',
        value: 'CO-OP'
    },
    {
        label: 'Manufactured home',
        value: 'Manufactured home'
    }
];
