import { Toolbar, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useAsyncEffect } from '@tsp-ui/core';
import { Loader, SentryRoutes } from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { useTryGetCurrentAccount, useTryGetCurrentUser } from '@utils/hooks';
import { withAuthentication } from '@utils/withAuthentication';
import {
    useCallback, useEffect, useState
} from 'react';
import { Route } from 'react-router-dom';

import { apiUtils, refreshClientToken } from '../api/api-utils';

import styles from './AuthenticatedRouteSwitch.module.scss';
import { default as NotFoundPageBase } from './NotFoundPage';
import { default as AccountDashboardPageBase } from './accounts/AccountDashboardPage';
import { default as SwitchAccountsPageBase } from './accounts/SwitchAccountsPage';
import { default as AdminRouteSwitchBase } from './admin/AdminRouteSwitch';
import MainNav from './components/MainNav/MainNav';
import AccountButton from './components/MainNav/components/AccountButton';
import NotificationsButton from './components/MainNav/components/NotificationsButton/NotificationsButton';
import { default as LoanDetailPageBase } from './loans/LoanDetailPage';
import { default as LoansPageBase } from './loans/LoansPage';
import { default as ManualLoanEntryPageBase } from './product-pricing/ManualLoanEntryPage';
import { default as ProductAndPricingPageBase } from './product-pricing/ProductAndPricingPage';
import { default as TrackingRouteSwitchBase } from './tracking/TrackingRouteSwitch';


const AdminRouteSwitch = withAuthentication(AdminRouteSwitchBase);
const TrackingRouteSwitch = withAuthentication(TrackingRouteSwitchBase);
const AccountDashboardPage = withAuthentication(AccountDashboardPageBase);
const LoansPage = withAuthentication(LoansPageBase);
const LoanDetailPage = withAuthentication(LoanDetailPageBase);
const ManualLoanEntryPage = withAuthentication(ManualLoanEntryPageBase);
const ProductAndPricingPage = withAuthentication(ProductAndPricingPageBase);
const SwitchAccountsPage = withAuthentication(SwitchAccountsPageBase);
const NotFoundPage = withAuthentication(NotFoundPageBase);

export default function AuthenticatedRouteSwitch() {
    const pageMessage = usePageMessage();
    const user = useTryGetCurrentUser();
    const account = useTryGetCurrentAccount();
    const { id: clientId, customerId, customerName } = account || {};

    const [ tokenLoading, setTokenLoading ] = useState(true);
    const [ accessToken, setAccessToken ] = useState<string | undefined>(apiUtils.getClientToken());

    useAsyncEffect(useCallback(async () => {
        if (clientId && clientId !== 'internal') {
            try {
                setTokenLoading(true);
                const accessToken = await refreshClientToken(clientId);
                setAccessToken(accessToken);
            } catch (error) {
                pageMessage.handleApiError('An error occurred while authenticating with client', error);
            } finally {
                setTokenLoading(false);
            }
        } else {
            apiUtils.setClientToken('');
            setAccessToken(undefined);
            setTokenLoading(false);
        }
    }, [ clientId, pageMessage ]));

    useEffect(() => {
        if (account) {
            Sentry.setContext('account', account);
        }
    }, [ account ]);

    const [ showLoader, setShowLoader ] = useState(false);
    useEffect(() => {
        setTimeout(() => setShowLoader(true), 300);
    }, []);

    return (
        <div className={styles.root}>
            {user && (
                <>
                    <MainNav account={account} />

                    <header className={styles.headerContainer}>
                        <Toolbar className={styles.toolbar}>
                            {account && (
                                <>
                                    <div className={styles.logoContainer}>
                                        <img
                                            alt="account logo"
                                            src={account.logoUrl}
                                            className={styles.logo}
                                        />

                                        {!!customerId && (
                                            <Typography
                                                variant="body2"
                                                className={styles.customerName}
                                            >
                                                {customerName}
                                            </Typography>
                                        )}
                                    </div>

                                    {!tokenLoading && <NotificationsButton />}
                                </>
                            )}

                            <AccountButton account={account} />
                        </Toolbar>
                    </header>
                </>
            )}

            {tokenLoading && showLoader && <Loader loading />}

            {(!tokenLoading || !!accessToken) && (
                <SentryRoutes>
                    <Route
                        path="/accounts"
                        element={<SwitchAccountsPage />}
                    />

                    <Route
                        path={customerId
                            ? '/accounts/:accountID/:customerId/*'
                            : '/accounts/:accountID/*'}
                        element={<AccountRoutes />}
                    />

                    <Route
                        path="*"
                        element={<NotFoundPage />}
                    />
                </SentryRoutes>
            )}
        </div>
    );
}

function AccountRoutes() {
    return (
        <SentryRoutes>
            <Route
                path="/"
                element={<AccountDashboardPage />}
            />

            <Route
                path="admin/*"
                element={<AdminRouteSwitch />}
            />

            <Route
                path="loans/*"
                element={<LoansRoutes />}
            />

            <Route
                path="product-pricing/*"
                element={<ProductAndPricingPage />}
            />

            <Route
                path="product-pricing/new/manual"
                element={<ManualLoanEntryPage />}
            />

            <Route
                path="tracking/*"
                element={<TrackingRouteSwitch />}
            />
        </SentryRoutes>
    );
}

function LoansRoutes() {
    return (
        <SentryRoutes>
            <Route
                path="/"
                element={<LoansPage />}
            />

            <Route
                path=":loanID"
                element={<LoanDetailPage />}
            />

            <Route
                path=":loanID/:underwritingTab"
                element={<LoanDetailPage />}
            />
        </SentryRoutes>
    );
}
