import api, { ExclusionOverlay, ExclusionOverlayType } from '@api';
import { Paper, Typography } from '@mui/material';
import {
    Autocomplete, DateField, Switch, TextField
} from '@tsp-ui/core/components';
import {
    Override, replaceItemById, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { LoanProgramsContext } from '@views/admin/investors/InternalInvestorDetailRoutes';
import {
    EligibilityExclusionsFormValues,
    ExclusionGroupRow,
    exclusionsToFormValues,
    formValuesToExclusions,
    getEmptyEligibilityExclusions
} from '@views/admin/investors/InvestorDetailPage/components/ExclusionGroupRow';
import clsx from 'clsx';
import { Dispatch, SetStateAction, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { ExclusionOverlayContext } from '../InvestorEligibilityManagementPage';
import { InvestorEligibilityContext } from '../InvestorEligibilityRoutes';

import styles from './ExclusionOverlayForm.module.scss';


interface ExclusionOverlayFormProps {
    activeStep: number;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

export type ExclusionOverlayFormValues = Override<ExclusionOverlay, { exclusions: EligibilityExclusionsFormValues }>;

export default function ExclusionOverlayForm({ activeStep, setLoading } : ExclusionOverlayFormProps) {
    const { overlayID } = useParams();
    const pageMessage = usePageMessage();
    const navigate = useNavigate();

    const { overlays, setOverlays } = useContext(ExclusionOverlayContext);
    const { investors } = useContext(InvestorEligibilityContext);
    const { loanPrograms } = useContext(LoanProgramsContext);

    const type = useOverlayType();

    const defaultValues = exclusionOverlayToFormValues(overlays.find(overlay => overlay.id === overlayID)) || {
        type,
        investorIds: [],
        loanProgramIds: [],
        exclusions: getEmptyEligibilityExclusions()
    };

    const formMethods = useForm<ExclusionOverlayFormValues>({ defaultValues });

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        setLoading(true);

        if (type === ExclusionOverlayType.CLIENT) {
            formValues.loanProgramIds = [ 'ALL' ];
        }

        try {
            setOverlays(overlayID
                ? replaceItemById(
                    overlays, await api.overlay.updateExclusionOverlay(formValuesToExclusionOverlay(formValues))
                )
                : [ ...overlays, await api.overlay.createExclusionOverlay(formValuesToExclusionOverlay(formValues)) ]);

            setLoading(false);

            navigate('..');

            pageMessage.success('Exclusion overlay added');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the exclusion overlay', error);
            setLoading(false);
        }
    });

    const applyToAllInvestors = formMethods.watch('investorIds')?.includes('ALL');
    const applyToAllPrograms = formMethods.watch('loanProgramIds')?.includes('ALL');

    const exclusionElements = (
        <>
            <Typography
                align="center"
            >
                What is your exclusion criteria?

                {type === ExclusionOverlayType.LOAN_PROGRAM && (
                    <Typography
                        align="center"
                        variant="caption"
                        component="p"
                        color="textSecondary"
                    >
                        This overlay will apply only to this loan program
                    </Typography>
                )}
            </Typography>

            <Paper
                className={styles.exclusions}
                variant="outlined"
            >
                <ExclusionGroupRow nameBase="exclusions" />
            </Paper>
        </>
    );

    return (
        <form
            id={ExclusionOverlayForm.formID}
            className={clsx(styles.root, {
                [styles.targetStep]: type !== ExclusionOverlayType.LOAN_PROGRAM && activeStep === 1,
                [styles.exclusionStep]: activeStep === 2 || (
                    type === ExclusionOverlayType.LOAN_PROGRAM && activeStep === 1
                )
            })}
            onSubmit={handleSubmit}
            noValidate
        >
            <FormProvider {...formMethods}>
                {activeStep === 0 ? (
                    <>
                        <TextField
                            name="name"
                            label="Overlay name"
                            required
                        />

                        <TextField
                            name="description"
                            label="Description"
                            required
                            multiline
                            rows={2}
                        />

                        <div className={styles.dateFields}>
                            <DateField
                                name="effectiveDate"
                                label="Effective date"
                                required
                            />

                            <DateField
                                name="expirationDate"
                                label="Expiration date"
                            />
                        </div>
                    </>
                ) : activeStep === 1 ? (
                    type === ExclusionOverlayType.CLIENT ? (
                        <>
                            <Typography
                                align="center"
                            >
                                Which investors should this overlay should apply to?

                                <Typography
                                    align="center"
                                    variant="caption"
                                    component="p"
                                    color="textSecondary"
                                >
                                    This overlay will apply to all loan programs from the selected investors
                                </Typography>
                            </Typography>

                            <Autocomplete
                                key="investors"
                                name="investorIds"
                                label="Investors"
                                multiple
                                required
                                options={investors!.map(investor => investor.id)}
                                getOptionLabel={option => investors!.find(investor => investor.id === option)?.name || 'None'}
                                className={styles.autocomplete}
                                disabled={applyToAllInvestors}
                            />

                            <Switch
                                label="Apply to all investors"
                                name="all-investors"
                                onChange={(_, checked) => {
                                    formMethods.setValue('investorIds', checked ? [ 'ALL' ] : []);
                                }}
                            />
                        </>
                    ) : type === ExclusionOverlayType.INVESTOR ? (
                        <>
                            <Typography
                                align="center"
                            >
                                Which loan programs should this overlay should apply to?
                            </Typography>

                            <Autocomplete
                                key="programs"
                                name="programs"
                                label="Loan Programs"
                                multiple
                                required
                                options={loanPrograms!.map(loanProgram => loanProgram.id)}
                                getOptionLabel={option => loanPrograms!.find(loanProgram => loanProgram.id === option)?.name || 'None'}
                                className={styles.autocomplete}
                                disabled={applyToAllPrograms}
                            />

                            <Switch
                                label="Apply to all loan programs"
                                name="all-programs"
                                onChange={(_, checked) => {
                                    formMethods.setValue('loanProgramIds', checked ? [ 'ALL' ] : []);
                                }}
                            />
                        </>
                    ) : exclusionElements
                ) : exclusionElements}
            </FormProvider>
        </form>
    );
}

ExclusionOverlayForm.formID = 'edit-overlay-form';

export function useOverlayType(): ExclusionOverlayType {
    const { overlayID } = useParams();
    const { pathname } = useLocation();

    const { overlays } = useContext(ExclusionOverlayContext);
    const overlay = overlays.find(overlay => overlay.id === overlayID);

    let type: ExclusionOverlayType;

    if (!overlay) {
        type = pathname.includes('loan-programs')
            ? ExclusionOverlayType.LOAN_PROGRAM
            : pathname.includes('investors')
                ? ExclusionOverlayType.INVESTOR
                : ExclusionOverlayType.CLIENT;
    } else {
        type = overlay.type; // eslint-disable-line prefer-destructuring
    }

    return type;
}

function exclusionOverlayToFormValues(overlay?: ExclusionOverlay): ExclusionOverlayFormValues | undefined {
    return overlay ? {
        ...overlay,
        exclusions: exclusionsToFormValues(overlay.exclusions)
    } : undefined;
}

function formValuesToExclusionOverlay(formValues: ExclusionOverlayFormValues): ExclusionOverlay {
    return {
        ...formValues,
        exclusions: formValuesToExclusions(formValues.exclusions)
    } as ExclusionOverlay;
}
