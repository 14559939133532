import {
    LoanEvent, LoanEventType, User, loanEventTypeDisplay
} from '@api';
import {
    Check, Description, Done, Lock, MoreHoriz, NoteAdd, PendingActions, PriceCheck
} from '@mui/icons-material';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineDotProps,
    TimelineItem,
    TimelineSeparator
} from '@mui/lab';
import {
    Button, CircularProgress, Link as MuiLink, Popover, Typography
} from '@mui/material';
import { getFullName } from '@tsp-ui/core/utils';
import ContactDisplay from '@views/admin/customers/CustomerDetailPage/components/dialogs/ContactDisplay';
import clsx from 'clsx';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import { ReactNode, useState } from 'react';

import styles from './LoanTimeline.module.scss';


interface LoanTimelinePopoverProps {
    isAwaitingDocs: boolean;
    isIndexing?: boolean;
    isInProgress?: boolean;
    disableAnimation?: boolean;
    loanEvents: LoanEvent[];
}

export function LoanTimeline({
    isAwaitingDocs, isIndexing, isInProgress, loanEvents, disableAnimation
}: LoanTimelinePopoverProps) {
    const [ showAllEvents, setShowAllEvents ] = useState(loanEvents.length <= 3);

    const eventsToDisplay = loanEvents?.slice(showAllEvents ? 0 : -2, loanEvents.length);

    return (
        <div>
            <Typography color="textSecondary">
                Loan Timeline
            </Typography>

            <Timeline
                position="left"
                className={styles.progressTimeline}
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
            >
                {!showAllEvents && (
                    <LoanTimelineItem
                        icon={<MoreHoriz color="primary" />}
                        hideTopConnector
                        TimelineDotProps={{
                            variant: 'outlined'
                        }}
                    >
                        <Button
                            className={styles.timelineButton}
                            onClick={() => setShowAllEvents(true)}
                        >
                            Show {(loanEvents?.length || 0) - 2} more events
                        </Button>
                    </LoanTimelineItem>
                )}

                {eventsToDisplay?.map((loanEvent, index, { length }) => (
                    <LoanTimelineItem
                        hideTopConnector={showAllEvents && index === 0}
                        key={loanEvent.id}
                        classNames={{
                            content: clsx({
                                [styles.done]: !disableAnimation
                                    && loanEvent.eventType === LoanEventType.INITIAL_DOC_PACKAGE_UPLOADED
                                    && index === length - 1
                            })
                        }}
                        hideBottomConnector={index === length - 1 && !isInProgress && !isAwaitingDocs && !isIndexing}
                        icon={loanEventIconMappings[loanEvent.eventType]}
                    >
                        <LoanTimelineContactTimeDisplay
                            text={loanEventTypeDisplay[loanEvent.eventType]}
                            user={loanEvent.triggeredBy}
                            time={`${formatDistanceToNowStrict(parseISO(loanEvent.triggeredAt!))} ago`}
                        />
                    </LoanTimelineItem>
                ))}

                {isAwaitingDocs ? (
                    <LoanTimelineItem
                        hideTopConnector={!eventsToDisplay.length}
                        icon={<PendingActions color="disabled" />}
                        hideBottomConnector
                        TimelineDotProps={{
                            color: 'grey',
                            variant: 'outlined'
                        }}
                    >
                        <LoanTimelineDisplay
                            text="Awaiting documents"
                            secondaryText="Upload initial doc package"
                        />
                    </LoanTimelineItem>
                ) : isIndexing && (
                    <LoanTimelineItem
                        hideTopConnector={!eventsToDisplay.length}
                        classNames={{ content: styles.loading }}
                        hideBottomConnector
                        TimelineDotProps={{
                            color: 'secondary',
                            variant: 'outlined'
                        }}
                        icon={(
                            <CircularProgress
                                color="secondary"
                                size={24}
                            />
                        )}
                    >
                        <LoanTimelineDisplay
                            text="Indexing in progress"
                            secondaryText="Loading..."
                        />
                    </LoanTimelineItem>
                )}

                {isInProgress && (
                    <LoanTimelineItem
                        icon={<PendingActions color="disabled" />}
                        hideBottomConnector
                        className={styles.timelineEnterTransitionContainer}
                        classNames={{ content: styles.timelineEnterTransition }}
                        TimelineDotProps={{
                            color: 'grey',
                            variant: 'outlined',
                            className: styles.timelineEnterTransition
                        }}
                    >
                        <LoanTimelineDisplay
                            text="Loan setup in progress"
                            secondaryText="You'll be notified when it's done"
                        />
                    </LoanTimelineItem>
                )}
            </Timeline>
        </div>
    );
}

interface LoanTimelineItemProps {
    children: ReactNode;
    className?: string;
    classNames?: {
        content?: string;
    };
    TimelineDotProps?: TimelineDotProps;
    icon: ReactNode;
    hideTopConnector?: boolean;
    hideBottomConnector?: boolean;
}

export function LoanTimelineItem({
    children, className, classNames, TimelineDotProps, icon, hideTopConnector, hideBottomConnector
}: LoanTimelineItemProps) {
    return (
        <TimelineItem className={clsx(styles.timelineRoot, className)}>
            <TimelineContent className={clsx(styles.timelineContent, classNames?.content)}>
                {children}
            </TimelineContent>

            <TimelineSeparator>
                <TimelineConnector
                    sx={{ bgcolor: 'primary.main' }}
                    className={clsx({
                        [styles.hiddenConnector]: hideTopConnector
                    })}
                />

                <TimelineDot
                    {...({
                        color: 'primary',
                        ...TimelineDotProps
                    })}
                >
                    {icon}
                </TimelineDot>

                <TimelineConnector
                    sx={{ bgcolor: 'primary.main' }}
                    className={clsx({
                        [styles.hiddenConnector]: hideBottomConnector
                    })}
                />
            </TimelineSeparator>
        </TimelineItem>
    );
}

interface LoanTimelineDisplayProps {
    text: ReactNode;
    secondaryText: ReactNode;
}

export function LoanTimelineDisplay({ text, secondaryText }: LoanTimelineDisplayProps) {
    return (
        <Typography component="span">
            {text}

            <Typography
                variant="body2"
                color="textSecondary"
            >
                {secondaryText}
            </Typography>
        </Typography>
    );
}

interface LoanTimelineContactTimeDisplayProps {
    text: LoanTimelineDisplayProps['text'];
    user: User;
    time: ReactNode;
}

export function LoanTimelineContactTimeDisplay({ text, user, time }: LoanTimelineContactTimeDisplayProps) {
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();

    return (
        <>
            <LoanTimelineDisplay
                text={text}
                secondaryText={(
                    <>
                        by{' '}

                        {/**
                         * TODO post-demo create a component called ContactLink to
                         * encapsulate the Link and the associated Popover
                         */}

                        <MuiLink onClick={(event) => setAnchorEl(event.currentTarget)}>
                            {getFullName(user)}
                        </MuiLink>

                        {' '}{time}
                    </>
                )}
            />

            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top'
                }}
                PaperProps={{
                    className: styles.popover
                }}
            >
                <ContactDisplay
                    contact={{
                        name: getFullName(user),
                        phone: `${user.phone}`,
                        email: `${user.email}`
                    }}
                />
            </Popover>
        </>
    );
}

const loanEventIconMappings = {
    [LoanEventType.CREATED]: <NoteAdd />,
    [LoanEventType.PRICED]: <PriceCheck />,
    [LoanEventType.REPRICED]: <PriceCheck />,
    [LoanEventType.LOCKED]: <Lock />,
    [LoanEventType.INITIAL_DOC_PACKAGE_UPLOADED]: <Description />,
    [LoanEventType.DOCUMENT_UPLOADED]: <Description />,
    [LoanEventType.SETUP_COMPLETE]: <Check />,
    [LoanEventType.CLOSED]: <Done />
};
