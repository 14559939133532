import { RoutedDialogProps } from '@tsp-ui/core/components';
import AdminAddEditDialogTemplate from '@views/admin/components/AdminAddEditDialogTemplate';
import { useContext } from 'react';

import CustomerForm  from '../../../CustomerForm';
import { CustomerDetailContext } from '../../CustomerDetailPage';


type EditDetailsDialogProps = Omit<RoutedDialogProps, 'title' | 'children'>;

export default function EditCustomerDetailsDialog(props: EditDetailsDialogProps) {
    const { customer, updateCustomer } = useContext(CustomerDetailContext);

    return (
        <AdminAddEditDialogTemplate
            entityName="customer"
            entityToEdit={customer}
            Form={CustomerForm}
            onFormSubmit={updateCustomer}
            {...props}
        />
    );
}
