import { CheckCircleOutline, InfoOutlined } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';
import { IconTypography, renderEnumOptions } from '@tsp-ui/core/components';
import { useState } from 'react';

import { FloodAndHazardUnderwritingTabs } from '../../FloodAndHazardUnderwritingPage';
import UnderwritingQuestionGroup from '../../UnderwritingQuestionGroup';
import UnderwritingNextStepSummary from '../UnderwritingNextStepSummary';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';

import styles from './FloodInsuranceSection.module.scss';


interface FloodInsuranceSectionProps {
    setTab: (tab: FloodAndHazardUnderwritingTabs) => void;
    onTabComplete: (tab: FloodAndHazardUnderwritingTabs, nextTab: FloodAndHazardUnderwritingTabs) => void;
}

export default function FloodInsuranceSection({ onTabComplete }: FloodInsuranceSectionProps) {
    const [ hasFloodCert, setHasFloodCert ] = useState<boolean>();
    const [ isLifeOfLoan, setIsLifeOfLoan ] = useState<boolean>();
    const [ zone, setZone ] = useState<string>();

    const [ hasFloodInsurance, setHasFloodInsurance ] = useState<boolean>();
    const [ mortgageeCorrect, setMortgageeCorrect ] = useState<boolean>();
    const [ loanNumberCorrect, setLoanNumberCorrect ] = useState<boolean>();

    const floodInsuranceRequired = zone?.startsWith('A') || zone?.startsWith('V');

    return (
        <UnderwritingStepSection title="Flood insurance">
            <UnderwritingQuestionGroup
                otherChildren={isLifeOfLoan !== undefined && (
                    <TextField
                        label="Which flood zone is listed on the certificate?"
                        className={styles.floodZoneInput}
                        onChange={(event) => setZone(event.target.value)}
                        select
                        required
                    >
                        {renderEnumOptions(options)}
                    </TextField>
                )}
            >
                <UnderwritingYesNoQuestionCard
                    question="Is the flood certificate on file?"
                    onAnswered={setHasFloodCert}
                    answer={hasFloodCert}
                />

                {hasFloodCert && (
                    <UnderwritingYesNoQuestionCard
                        question="Is the flood certificate valid for the life of the loan?"
                        onAnswered={setIsLifeOfLoan}
                        answer={isLifeOfLoan}
                    />
                )}
            </UnderwritingQuestionGroup>

            {zone && (
                <UnderwritingNextStepSummary
                    onNextClick={floodInsuranceRequired
                        ? undefined
                        : () => onTabComplete('flood', 'hazard')}
                >
                    <IconTypography
                        icon={floodInsuranceRequired ? (
                            <CheckCircleOutline color="success" />
                        ) : (
                            <InfoOutlined color="primary" />
                        )}
                    >
                        {floodInsuranceRequired ? 'Flood insurance required' : 'No flood insurance required'}
                    </IconTypography>
                </UnderwritingNextStepSummary>
            )}

            {floodInsuranceRequired && (
                <UnderwritingQuestionGroup
                    otherChildren={loanNumberCorrect !== undefined && (
                        <>
                            <TextField
                                label="What is the annual premium?"
                                className={styles.textField}
                                required
                            />

                            <TextField
                                label="What is the coverage amount?"
                                className={styles.textField}
                                required
                            />

                            <Button
                                variant="contained"
                                className={styles.nextButton}
                                onClick={() => onTabComplete('flood', 'hazard')}
                            >
                                Next
                            </Button>
                        </>
                    )}
                >
                    <UnderwritingYesNoQuestionCard
                        question="Is flood insurance on file?"
                        onAnswered={setHasFloodInsurance}
                        answer={hasFloodInsurance}
                    />

                    {hasFloodInsurance !== undefined && (
                        <UnderwritingYesNoQuestionCard
                            question="Is the mortgagee correct?"
                            onAnswered={setMortgageeCorrect}
                            answer={mortgageeCorrect}
                        />
                    )}

                    {mortgageeCorrect !== undefined && (
                        <UnderwritingYesNoQuestionCard
                            question="Is the loan number correct?"
                            onAnswered={setLoanNumberCorrect}
                            answer={loanNumberCorrect}
                        />
                    )}
                </UnderwritingQuestionGroup>
            )}
        </UnderwritingStepSection>
    );
}

// TODO enum
const options = {
    A: 'A',
    AE: 'AE',
    A1_30: 'A1-30',
    AH: 'AH',
    AO: 'AO',
    AR: 'AR',
    A99: 'A99',
    V: 'V',
    VE: 'VE',
    V1_30: 'V1-30',
    X: 'X',
    B: 'B',
    C: 'C'
};
