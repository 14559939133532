import { Button, TextField } from '@mui/material';
import { CardTable } from '@tsp-ui/core/components';
import { useEffect, useState } from 'react';

import loanDetailsStyles from '../../../LoanDetailPage.module.scss';
import { CreditUnderwritingTabs } from '../../CreditUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';


interface DerogatoriesSectionProps {
    setTab: (tab: CreditUnderwritingTabs) => void;
    onTabComplete: (tab: CreditUnderwritingTabs, nextTab: CreditUnderwritingTabs) => void;
}

export default function DerogatoriesSection({ onTabComplete }: DerogatoriesSectionProps) {
    const [ hasBankruptcies, setHasBankruptcies ] = useState<boolean>();
    const [ bankruptciesComplete, setBankruptciesComplete ] = useState(false);

    const [ hasJudgements, setHasJudgements ] = useState<boolean>();
    const [ judgementsComplete, setJudgementsComplete ] = useState(false);

    const [ hasCollections, setHasCollections ] = useState<boolean>();

    useEffect(() => {
        if (hasBankruptcies === false && hasJudgements === false && hasCollections === false) {
            onTabComplete('derogatories', 'akas');
        } else {
            if (hasBankruptcies === false) {
                setBankruptciesComplete(true);
            }

            if (hasJudgements === false) {
                setJudgementsComplete(true);
            }
        }
    }, [
        hasBankruptcies, hasJudgements, hasCollections, onTabComplete
    ]);

    return (
        <UnderwritingStepSection title="Derogatories">
            <UnderwritingYesNoQuestionCard
                question="Are there any bankruptcies, foreclosures, or short sales?"
                onAnswered={setHasBankruptcies}
                answer={hasBankruptcies}
            >
                <CardTable
                    className={loanDetailsStyles.editableCardTable}
                    headers={[
                        'Borrower', 'Type', 'Disposition', 'Date', 'Extenuating'
                    ]}
                >
                    <tr>
                        <td>
                            <TextField
                                variant="standard"
                                size="small"
                                autoFocus
                            />
                        </td>

                        <td>
                            <TextField
                                variant="standard"
                                size="small"
                            />
                        </td>

                        <td>
                            <TextField
                                variant="standard"
                                size="small"
                            />
                        </td>

                        <td>
                            <TextField
                                variant="standard"
                                size="small"
                            />
                        </td>

                        <td>
                            <TextField
                                variant="standard"
                                size="small"
                            />
                        </td>
                    </tr>
                </CardTable>

                <div className={loanDetailsStyles.buttons}>
                    <Button>
                        Add row
                    </Button>

                    {!bankruptciesComplete && (
                        <Button
                            variant="contained"
                            onClick={() => setBankruptciesComplete(true)}
                        >
                            Next
                        </Button>
                    )}
                </div>
            </UnderwritingYesNoQuestionCard>

            {bankruptciesComplete && (
                <UnderwritingYesNoQuestionCard
                    question="Are there any outstanding judgements or liens?"
                    onAnswered={setHasJudgements}
                    answer={hasJudgements}
                >
                    <CardTable
                        className={loanDetailsStyles.editableCardTable}
                        headers={[
                            'Borrower', 'Plaintiff', 'Item type', 'Amount', 'Date'
                        ]}
                    >
                        <tr>
                            <td>
                                <TextField
                                    variant="standard"
                                    size="small"
                                    autoFocus
                                />
                            </td>

                            <td>
                                <TextField
                                    variant="standard"
                                    size="small"
                                />
                            </td>

                            <td>
                                <TextField
                                    variant="standard"
                                    size="small"
                                />
                            </td>

                            <td>
                                <TextField
                                    variant="standard"
                                    size="small"
                                />
                            </td>

                            <td>
                                <TextField
                                    variant="standard"
                                    size="small"
                                />
                            </td>
                        </tr>
                    </CardTable>

                    <div className={loanDetailsStyles.buttons}>
                        <Button>
                            Add row
                        </Button>

                        {!judgementsComplete && (
                            <Button
                                variant="contained"
                                onClick={() => setJudgementsComplete(true)}
                            >
                                Next
                            </Button>
                        )}
                    </div>
                </UnderwritingYesNoQuestionCard>
            )}

            {judgementsComplete && (
                <UnderwritingYesNoQuestionCard
                    question="Are there any open collections or charge offs?"
                    onAnswered={setHasCollections}
                    answer={hasCollections}
                >
                    <CardTable
                        className={loanDetailsStyles.editableCardTable}
                        headers={[
                            'Borrower', 'Company', 'Account #', 'Balance', 'Paying off?'
                        ]}
                    >
                        <tr>
                            <td>
                                <TextField
                                    variant="standard"
                                    size="small"
                                    autoFocus
                                />
                            </td>

                            <td>
                                <TextField
                                    variant="standard"
                                    size="small"
                                />
                            </td>

                            <td>
                                <TextField
                                    variant="standard"
                                    size="small"
                                />
                            </td>

                            <td>
                                <TextField
                                    variant="standard"
                                    size="small"
                                />
                            </td>

                            <td>
                                <TextField
                                    variant="standard"
                                    size="small"
                                />
                            </td>
                        </tr>
                    </CardTable>

                    <div className={loanDetailsStyles.buttons}>
                        <Button>
                            Add row
                        </Button>

                        <Button
                            variant="contained"
                            onClick={() => onTabComplete('derogatories', 'akas')}
                        >
                            Done
                        </Button>
                    </div>
                </UnderwritingYesNoQuestionCard>
            )}
        </UnderwritingStepSection>
    );
}
