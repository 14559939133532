import { Button, DialogContent } from '@mui/material';
import { DialogActions, RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import { useState } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { AdminAddPageTemplatePropsBase } from './AdminAddPageTemplate';


export interface AdminAddEditDialogTemplateProps<TEntityType>
    extends Omit<RoutedDialogProps, 'title' | 'children'>, AdminAddPageTemplatePropsBase<TEntityType>
{
    entityToEdit?: TEntityType;
}

export default function AdminAddEditDialogTemplate<TEntityType>({
    entityName, entityToEdit, Form, onFormSubmit, ...props
}: AdminAddEditDialogTemplateProps<TEntityType>) {
    const entityIDParam: `${typeof entityName}ID` = `${entityName}ID`;
    const match = useMatch(`/accounts/:accountID/admin/${entityName}s/:${entityIDParam}/*`);

    const entityID = match?.params[entityIDParam];

    const [ loading, setLoading ] = useState(false);
    const [ debouncedKey ] = useDebounce(entityID, 200);

    const isEdit = entityID !== 'new';

    return (
        <RoutedDialog
            {...props}
            title={isEdit ? `Edit ${entityName}` : `Add ${entityName}`}
            debounceTitle={isEdit}
        >
            <DialogContent>
                <Form
                    key={debouncedKey}
                    setLoading={setLoading}
                    onSubmit={onFormSubmit}
                    entityToEdit={entityToEdit}
                />
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    component={Link}
                    to={props.closeTo}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    form={Form.formID}
                    type="submit"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
