import { CheckCircle, CircleOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';

import styles from './StatusChecklistItem.module.scss';


interface StatusChecklistItemProps {
    className?: string;
    selected?: boolean;
    done?: boolean;
    label: ReactNode;
}

export default function StatusChecklistItem({
    className, selected, done, label
}: StatusChecklistItemProps) {
    return (
        <Typography
            className={clsx(styles.root, className, {
                [styles.selected]: selected
            })}
        >
            {done ? (
                <CheckCircle
                    fontSize="inherit"
                    color="success"
                />
            ) : (
                <CircleOutlined fontSize="inherit" />
            )}

            {label}
        </Typography>
    );
}
