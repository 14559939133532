import api, { Investor } from '@api';
import { createContext } from 'react';

import AdminPageTemplate, {
    AdminEntityGroup, AdminEntityGroupProps, AdminPageContextValues, defaultAdminPageContextValues
} from '../components/AdminPageTemplate';

import InternalInvestorDetailRoutes from './InternalInvestorDetailRoutes';
import InternalInvestorForm from './InvestorDetailPage/components/InternalInvestorForm';
import { InternalInvestorCard } from './components/InternalInvestorCard';


export const InvestorManagementPageContext = createContext<AdminPageContextValues<Investor>>(
    defaultAdminPageContextValues
);

export default function InvestorManagementPage() {
    return (
        <AdminPageTemplate
            entityName="investor"
            CreateEditForm={InternalInvestorForm}
            Context={InvestorManagementPageContext}
            DetailPage={InternalInvestorDetailRoutes}
            EntityGroupComponent={InvestorGroup}
            fetchEntities={api.investors.getInvestors}
            filterByLabel="name"
            filterEntity={({ name }, filterInputValue) => name.toLocaleLowerCase().includes(filterInputValue)}
            visibleGroups={[ 'Investors' ]}
            editDetails
        />
    );
}

type InvestorGroupName = 'Investors';

function InvestorGroup({
    group, entities: investors
}: AdminEntityGroupProps<Investor, InvestorGroupName>) {
    return (
        <AdminEntityGroup
            header={group}
            noResultsMessage="No investors exist."
        >
            {investors.map(investor => (
                <InternalInvestorCard
                    key={investor.id}
                    investor={investor}
                />
            ))}
        </AdminEntityGroup>
    );
}
