import { Account } from '@api';
import { AccountCircleOutlined, Settings, SwapHoriz } from '@mui/icons-material';
import {
    Avatar, Button, FormControlLabel, IconButton, Popover, Switch, Tooltip, Typography
} from '@mui/material';
import { logout } from '@redux/auth';
import { useDispatch } from '@redux/store';
import { getFullName } from '@tsp-ui/core/utils';
import { useTryGetCurrentUser } from '@utils/hooks';
import clsx from 'clsx';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import styles from './AccountButton.module.scss';


interface AccountButtonProps {
    account: Account | undefined;
}

export default function AccountButton({ account }: AccountButtonProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentUser = useTryGetCurrentUser();

    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();
    const [ mocksRunning, setMocksRunning ] = useState(window.mocks.isRunning());

    return (
        <>
            <Tooltip title="Account details">
                {account ? (
                    <Button
                        variant="outlined"
                        className={styles.button}
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        startIcon={
                            <AccountCircleOutlined color="secondary" />
                        }
                    >
                        {account.name}
                    </Button>
                ) : (
                    <IconButton
                        edge="end"
                        className={styles.iconButton}
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                    >
                        <AccountCircleOutlined color="secondary" />
                    </IconButton>
                )}
            </Tooltip>

            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
                PaperProps={{
                    className: clsx(styles.accountMenu, {
                        [styles.noAccount]: !account
                    })
                }}
            >
                {account && (
                    <div className={styles.accountContainer}>
                        <Typography variant="h6">
                            {account.name}
                        </Typography>

                        <Tooltip title="Switch accounts">
                            <IconButton
                                component={Link}
                                to="/accounts"
                                edge="end"
                                onClick={() => setAnchorEl(undefined)}
                                className={styles.switchAccountButton}
                                color="inherit"
                            >
                                <SwapHoriz color="inherit" />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}

                {currentUser && (
                    <div className={styles.nameContainer}>
                        <Avatar className={styles.avatar}>
                            {`${currentUser.firstName[0]}${currentUser.lastName[0]}`}
                        </Avatar>

                        <div>
                            <Typography>
                                {getFullName(currentUser)}
                            </Typography>

                            <Typography variant="body2">
                                {currentUser.email}
                            </Typography>
                        </div>

                        <Tooltip title="Manage account">
                            <IconButton edge="end">
                                <Settings color="secondary" />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}

                {(window.location.origin === 'https://dev.premicorr.net' || process.env.NODE_ENV === 'development') && (
                    <FormControlLabel
                        label="Enable mocked data"
                        control={(
                            <Switch
                                checked={mocksRunning}
                                onClick={() => {
                                    if (mocksRunning) {
                                        setMocksRunning(false);
                                        window.mocks.stop();
                                    } else {
                                        setMocksRunning(true);
                                        window.mocks.start();
                                    }
                                }}
                            />
                        )}
                    />
                )}

                <Button
                    className={styles.logOut}
                    onClick={async () => {
                        await dispatch(logout());
                        navigate('/login');
                    }}
                >
                    Log out
                </Button>
            </Popover>
        </>
    );
}
