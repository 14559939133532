import { useTheme } from '@mui/material';
import { formatCurrencyAbbreviation, formatDate } from '@tsp-ui/core/utils';
import { transparentize } from '@utils/color-utils';
// @ts-ignore
import { Chart, ChartConfiguration, ChartTypeRegistry } from 'chart.js';
import { enUS } from 'date-fns/locale';
import { useMemo } from 'react';
// @ts-ignore
import { Line } from 'react-chartjs-2';
// @ts-ignore
import 'chartjs-adapter-date-fns';

import styles from './AllocationHistoryChart.module.scss';


export interface AllocationHistoryChartProps {
    data: AllocationHistoryChartData[];
    updateData: (newData: AllocationHistoryChartData) => void;
    max: number;
    colors: {
        undeliveredColor: string;
        deliveredColor: string;
        purchasedColor: string;
        soldColor: string;
    };
}

const commonDatasetProps = {
    tension: 0.1,
    pointRadius: 0,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 1
};

export function AllocationHistoryChart({
    data, max, updateData, colors: {
        undeliveredColor, deliveredColor, purchasedColor, soldColor
    }
}: AllocationHistoryChartProps) {
    const verticalLineColor = useTheme().palette.secondary.main;

    const chartData = useMemo<ChartConfiguration<'line', { x: Date; y: number }[]>['data']>(() => {
        const undeliveredData = data.map(item => ({
            x: item.lockDate,
            y: item.undelivered
        }));

        const deliveredData = data.map(item => ({
            x: item.lockDate,
            y: item.delivered
        }));

        const purchasedData = data.map(item => ({
            x: item.lockDate,
            y: item.purchased
        }));

        const soldData = data.map(item => ({
            x: item.lockDate,
            y: item.sold
        }));

        return {
            datasets: [
                {
                    ...commonDatasetProps,
                    label: 'Undelivered',
                    data: undeliveredData,
                    borderColor: undeliveredColor,
                    pointHoverBorderColor: undeliveredColor,
                    pointHoverBackgroundColor: transparentize(undeliveredColor, 0.5)
                },
                {
                    ...commonDatasetProps,
                    label: 'Delivered',
                    data: deliveredData,
                    borderColor: deliveredColor,
                    pointHoverBorderColor: deliveredColor,
                    pointHoverBackgroundColor: transparentize(deliveredColor, 0.5)
                },
                {
                    ...commonDatasetProps,
                    label: 'Purchased',
                    data: purchasedData,
                    borderColor: purchasedColor,
                    pointHoverBorderColor: purchasedColor,
                    pointHoverBackgroundColor: transparentize(purchasedColor, 0.5)
                },
                {
                    ...commonDatasetProps,
                    label: 'Sold',
                    data: soldData,
                    borderColor: soldColor,
                    pointHoverBorderColor: soldColor,
                    pointHoverBackgroundColor: transparentize(soldColor, 0.5)
                }
            ]
        };
    }, [
        data, undeliveredColor, deliveredColor, purchasedColor, soldColor
    ]);

    const options = useMemo<ChartConfiguration<'line', { x: Date; y: number }[]>['options']>(() => ({
        interaction: {
            intersect: false,
            mode: 'index'
        },
        scales: {
            x: {
                type: 'time',
                adapters: {
                    date: {
                        locale: enUS
                    }
                },
                time: {
                    unit: 'day'
                },
                ticks: {
                    maxTicksLimit: 4,
                    padding: 0,
                    color: 'rgba(0,0,0,0.54)'
                }
            },
            y: {
                bounds: 'ticks',
                position: 'left',
                max,
                ticks: {
                    maxTicksLimit: 4,
                    padding: 0,
                    color: 'rgba(0,0,0,0.54)',
                    callback(value) {
                        return formatCurrencyAbbreviation(parseInt(value.toString(), 10));
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false,
                external: (context) => {
                    const { chart, tooltip } = context;
                    const tooltipEl = getOrCreateTooltip(chart);
                    const h6 = tooltipEl.querySelector('h6');
                    const xAxisPosition = chart.scales.y.bottom;

                    // Hide if no tooltip
                    if (tooltip.opacity === 0) {
                        tooltipEl.style.opacity = '0';
                        return;
                    }

                    // Set Text
                    if (tooltip.body) {
                        const [ monthDay, year ] = tooltip.title[0].split(',');
                        const date = new Date(monthDay + year);
                        const tooltipText = formatDate(date.toISOString());

                        const text = document.createTextNode(tooltipText);

                        // Remove old children
                        while (h6?.firstChild) {
                            h6?.firstChild.remove();
                        }

                        h6?.appendChild(text);
                    }

                    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

                    tooltipEl.style.opacity = '1';
                    tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
                    tooltipEl.style.top = `${positionY + xAxisPosition}px`;
                    tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;
                }
            }
        }
    }), [ max ]);

    const { datasets } = chartData;
    const plugins = useMemo<ChartConfiguration<'line', { x: Date; y: number }[]>['plugins']>(() => ([
        {
            id: '1',
            afterDraw: (chart: AfterDrawChart) => {
                if (chart.tooltip?._active?.length) {
                    const [
                        undelivered, delivered, purchased, sold
                    ] = chart.tooltip._active;

                    updateData({
                        lockDate: datasets[purchased.datasetIndex].data[purchased.index].x,
                        undelivered: datasets[undelivered.datasetIndex].data[undelivered.index].y,
                        purchased: datasets[purchased.datasetIndex].data[purchased.index].y,
                        delivered: datasets[delivered.datasetIndex].data[delivered.index].y,
                        sold: datasets[sold.datasetIndex].data[sold.index].y
                    });

                    const xCord = purchased.element?.x || 0;
                    const yAxis = chart.scales.y;
                    const { ctx } = chart;
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(xCord, yAxis.top);
                    ctx.lineTo(xCord, yAxis.bottom);
                    ctx.lineWidth = 1;
                    ctx.strokeStyle = verticalLineColor;
                    ctx.stroke();
                    ctx.restore();
                }
            }
        }
    ]), [
        updateData, verticalLineColor, datasets
    ]);

    return (
        <div className={styles.chart}>
            <Line
                data={chartData}
                options={options}
                plugins={plugins}
            />
        </div>
    );
}

type AfterDrawChart = Chart<'line'> & {
    tooltip: {
        _active?: Array<{
            index: number;
            datasetIndex: number;
            element: {
                x: number;
                y: number;
            };
        }>;
    };
}

export type AllocationHistoryChartData = {
    lockDate: Date;
    undelivered: number;
    delivered: number;
    purchased: number;
    sold: number;
};

function getOrCreateTooltip(chart: Chart<keyof ChartTypeRegistry>) {
    let tooltipEl = chart.canvas.parentNode?.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = '1';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';

        const h6 = document.createElement('h6');
        h6.style.margin = '0px';

        tooltipEl.appendChild(h6);
        chart.canvas.parentNode?.appendChild(tooltipEl);
    }

    return tooltipEl;
}
