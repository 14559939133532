import { OverrideProps } from '@mui/material/OverridableComponent';
import clsx from 'clsx';
import { ElementType, ReactNode, forwardRef } from 'react';

import styles from './ActionsCell.module.scss';


interface ActionsCellTypeMap<P = {}, D extends ElementType = 'th'> {
    props: P & {
        actions: ReactNode;
        children: ReactNode;
        className?: string;
        component: 'th' | 'td';
        showActions?: boolean;
    };
    defaultComponent: D;
}

type ActionsCellProps<
    D extends ElementType = ActionsCellTypeMap['defaultComponent'],
    P = {},
> = OverrideProps<ActionsCellTypeMap<P, D>, D>;

const ActionsCell = forwardRef<HTMLTableCellElement | HTMLTableHeaderCellElement, ActionsCellProps>(({
    actions, component: Component, children, className, showActions, ...props
}, ref) => (
    <Component
        className={clsx(styles.root, className)}
        ref={ref}
        {...props}
    >
        {children}

        <div
            className={clsx(styles.actions, {
                [styles.show]: showActions
            })}
        >
            {actions}
        </div>
    </Component>
));

export default ActionsCell;
