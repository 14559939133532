import { AvailableProduct, ProductType, productTypeDisplay } from '@api';
import { FilledSection, Switch } from '@tsp-ui/core/components';
import { useConfirm } from '@tsp-ui/core/utils';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { EligibleProductsFormValues, ProductTypesFormValue } from '../EligibleProductsDialog';

import { LimitFields } from './LimitFields';
import { ProductTypeCard } from './ProductTypeCard';
import styles from './UnderwritingTypeCard.module.scss';


export type UnderwritingCardFieldPath = 'delegated' | 'nonDelegated';

const {
    CONVENTIONAL, FHA, NON_AGENCY, USDA, VA
} = ProductType;

interface UnderwritingTypeCardProps {
    label: string;
    baseFieldPath: UnderwritingCardFieldPath;
    availableLockPeriods: number[];
    availableProducts: {[key in ProductType]: AvailableProduct[]};
}

export function UnderwritingTypeCard({
    label, baseFieldPath, availableLockPeriods, availableProducts
}: UnderwritingTypeCardProps) {
    const otherUnderwritingType = baseFieldPath === 'delegated' ? 'nonDelegated' : 'delegated';

    const expanded = useWatch<EligibleProductsFormValues>({ name: `${baseFieldPath}.active` });
    const otherUnderwritingTypeActive = useWatch<EligibleProductsFormValues>({ name: `${otherUnderwritingType}.active` });

    const allProductsLimit = parseInt(useWatch<EligibleProductsFormValues>({ name: 'allProductsLimit.limitAmount' }) as string);

    const uwCatLimit = parseInt(useWatch<EligibleProductsFormValues>({ name: `${baseFieldPath}.limit.limitAmount` }) as string);
    const otherUwCatLimit = parseInt(useWatch<EligibleProductsFormValues>({ name: `${otherUnderwritingType}.limit.limitAmount` }) as string);

    const productTypes = useWatch<EligibleProductsFormValues>({ name: `${baseFieldPath}.productTypes` }) as ProductTypesFormValue;
    const otherProductTypes = useWatch<EligibleProductsFormValues>({ name: `${otherUnderwritingType}.productTypes` }) as ProductTypesFormValue;

    const totalOfProductTypeLimits = Object.values(productTypes).reduce(
        (previousValue, currentValue) => previousValue + (
            currentValue.active ? parseInt(currentValue.limit?.limitAmount) : 0
        ), 0
    );

    const generateUWTypeMax = useMemo(() => (
        createGenerateMax('underwriting category', allProductsLimit, uwCatLimit + (otherUnderwritingTypeActive ? otherUwCatLimit : 0))
    ), [
        allProductsLimit, uwCatLimit, otherUwCatLimit, otherUnderwritingTypeActive
    ]);

    const generateProductTypeMax = useMemo(() => (
        createGenerateMax('product type', uwCatLimit, totalOfProductTypeLimits)
    ), [ uwCatLimit, totalOfProductTypeLimits ]);

    const confirm = useConfirm();

    return (
        <FilledSection
            variant="light"
            header={(
                <>
                    <Switch<EligibleProductsFormValues>
                        name={`${baseFieldPath}.active`}
                        label={label}
                        disableTypography
                        labelClassName={styles.delNonDelSwitch}
                        confirm={expanded ? () => confirm(`This action will delete all ${label.toLowerCase()} products. Are you sure?`) : undefined}
                    />

                    {expanded && (
                        <LimitFields
                            label={label}
                            className={styles.delNonDelLimitFields}
                            baseFieldPath={`${baseFieldPath}.limit`}
                            generateMax={generateUWTypeMax}
                            compact
                        />
                    )}
                </>
            )}
        >
            <div className={styles.productTypeCardContainer}>
                {expanded && (
                    <>
                        {availableProducts[CONVENTIONAL] && !otherProductTypes.CONVENTIONAL?.active && (
                            <ProductTypeCard
                                availableLockPeriods={availableLockPeriods}
                                availableProducts={availableProducts[CONVENTIONAL]}
                                baseFieldPath={`${baseFieldPath}.productTypes.${CONVENTIONAL}`}
                                label={productTypeDisplay[CONVENTIONAL]}
                                generateProductTypeMax={generateProductTypeMax}
                            />
                        )}

                        {availableProducts[FHA] && !otherProductTypes.FHA?.active && (
                            <ProductTypeCard
                                availableLockPeriods={availableLockPeriods}
                                availableProducts={availableProducts[FHA]}
                                baseFieldPath={`${baseFieldPath}.productTypes.${FHA}`}
                                label={productTypeDisplay[FHA]}
                                generateProductTypeMax={generateProductTypeMax}
                            />
                        )}

                        {availableProducts[NON_AGENCY] && !otherProductTypes.NON_AGENCY?.active && (
                            <ProductTypeCard
                                availableLockPeriods={availableLockPeriods}
                                availableProducts={availableProducts[NON_AGENCY]}
                                baseFieldPath={`${baseFieldPath}.productTypes.${NON_AGENCY}`}
                                label={productTypeDisplay[NON_AGENCY]}
                                generateProductTypeMax={generateProductTypeMax}
                            />
                        )}

                        {availableProducts[USDA] && !otherProductTypes.USDA?.active && (
                            <ProductTypeCard
                                availableLockPeriods={availableLockPeriods}
                                availableProducts={availableProducts[USDA]}
                                baseFieldPath={`${baseFieldPath}.productTypes.${USDA}`}
                                label={productTypeDisplay[USDA]}
                                generateProductTypeMax={generateProductTypeMax}
                            />
                        )}

                        {availableProducts[VA] && !otherProductTypes.VA?.active && (
                            <ProductTypeCard
                                availableLockPeriods={availableLockPeriods}
                                availableProducts={availableProducts[VA]}
                                baseFieldPath={`${baseFieldPath}.productTypes.${VA}`}
                                label={productTypeDisplay[VA]}
                                generateProductTypeMax={generateProductTypeMax}
                            />
                        )}
                    </>
                )}
            </div>
        </FilledSection>
    );
}

export function createGenerateMax(
    limitType: string,
    limitOfParent: number,
    totalOfChildLimits: number
) {
    return (individualChildLimit: number) => {
        const individualChildMax = (limitOfParent - totalOfChildLimits) + individualChildLimit;

        return {
            value: individualChildMax,
            message: `Sum of ${limitType} limits cannot exceed ${limitOfParent}`
        };
    };
}

export type GenerateMaxFunction = ReturnType<typeof createGenerateMax>;
