import {
    AmortizationType, Product, amortizationTypeDisplay, occupancyTypeDisplay, productTypeDisplay,
    specialtyProductDisplay
} from '@api';
import { Typography } from '@mui/material';
import { DiffLabeledValue, LabelGroup } from '@tsp-ui/core/components';
import { formatCurrency, getItemById } from '@tsp-ui/core/utils';
import EditableSectionCard from '@views/admin/components/EditableSectionCard';
import clsx from 'clsx';
import { useContext } from 'react';

import { InvestorsContext } from '../ProductManagementPage';

import { InvestorChip } from './InvestorChip';
import styles from './ProductDetailsCard.module.scss';


interface ProductDetailsCardProps {
    product: Product;
    updatedProduct?: Product;
}

export function ProductDetailsCard({ product, updatedProduct }: ProductDetailsCardProps) {
    const { investors } = useContext(InvestorsContext);

    const diffProps = {
        original: product,
        updated: updatedProduct,
        ignoreDelete: true,
        variants: {
            value: 'body1',
            label: 'body2'
        }
    } as const;

    const booleanDiffProps = {
        ...diffProps,
        classNames: { value: styles.valueContainer },
        renderValue: renderBoolean
    };

    return (
        <EditableSectionCard
            header={product.productDescription}
            readOnly={!!updatedProduct}
            editTo="edit"
            className={updatedProduct ? styles.background : undefined}
        >
            <div className={styles.root}>
                <div>
                    <LabelGroup>
                        <DiffLabeledValue
                            {...diffProps}
                            property="productType"
                            label="Product type:"
                            renderValue={productType => productTypeDisplay[productType]}
                        />

                        <DiffLabeledValue
                            {...diffProps}
                            property="productCode"
                            label="Code:"
                        />

                        <DiffLabeledValue
                            {...diffProps}
                            property="amortizationType"
                            label="Amortization type:"
                            renderValue={amorType => amortizationTypeDisplay[amorType]}
                        />

                        <DiffLabeledValue
                            {...diffProps}
                            label="Term:"
                            property={[
                                'term', 'minTerm', 'maxTerm'
                            ]}
                            renderValue={({ term, minTerm, maxTerm }) => (
                                <>
                                    {term}

                                    {(minTerm || maxTerm) && (
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                        >
                                            &nbsp;({`${minTerm || '0'} - ${maxTerm || 'unlimited'}`})
                                        </Typography>
                                    )}
                                </>
                            )}
                            fieldSet
                        />

                        {(product.minLoanAmount || product.maxLoanAmount) && (
                            <DiffLabeledValue
                                {...diffProps}
                                label="Loan amount:"
                                property={[ 'minLoanAmount', 'maxLoanAmount' ]}
                                renderValue={({ minLoanAmount, maxLoanAmount }) => `${formatCurrency(minLoanAmount || 0)} - ${formatCurrency(maxLoanAmount) || 'unlimited'}`}
                                fieldSet
                            />
                        )}

                        {product.specialtyProduct && (
                            <DiffLabeledValue
                                {...diffProps}
                                label="Specialty product:"
                                property="specialtyProduct"
                                renderValue={specialtyProduct => specialtyProductDisplay[specialtyProduct!]}
                            />
                        )}

                        <DiffLabeledValue
                            {...diffProps}
                            label="Occupancy types:"
                            property="occupancyTypes"
                            renderValue={occupancyType => occupancyTypeDisplay[occupancyType]}
                        />
                    </LabelGroup>

                    {product.amortizationType === AmortizationType.ADJUSTABLE && (
                        <LabelGroup>
                            <DiffLabeledValue
                                {...diffProps}
                                label="ARM initial period:"
                                property="armInitialPeriod"
                            />

                            <DiffLabeledValue
                                {...diffProps}
                                label="ARM sub period:"
                                property="armSubPeriod"
                            />

                            <DiffLabeledValue
                                {...diffProps}
                                label="ARM initial rate cap:"
                                property="armInitialRateCap"
                            />

                            <DiffLabeledValue
                                {...diffProps}
                                label="ARM sub rate cap:"
                                property="armSubRateCap"
                            />

                            <DiffLabeledValue
                                {...diffProps}
                                label="ARM life rate cap:"
                                property="armLifeRateCap"
                            />

                            <DiffLabeledValue
                                {...diffProps}
                                label="ARM lookback days:"
                                property="armLookBackDays"
                            />
                        </LabelGroup>
                    )}
                </div>

                <LabelGroup className={styles.detailsRows}>
                    <DiffLabeledValue
                        {...diffProps}
                        property="investorIDs"
                        label="Investors:"
                        classNames={{ value: styles.chips }}
                        renderValue={investorID => (
                            <InvestorChip
                                investor={getItemById(investors, investorID)}
                                key={investorID}
                            />
                        )}
                    />

                    <DiffLabeledValue
                        {...booleanDiffProps}
                        label="Non agency:"
                        property="nonAgency"
                    />

                    <DiffLabeledValue
                        {...booleanDiffProps}
                        label="High balance:"
                        property="highBalance"
                    />

                    <DiffLabeledValue
                        {...booleanDiffProps}
                        label="Jumbo:"
                        property="jumbo"
                    />

                    <DiffLabeledValue
                        {...booleanDiffProps}
                        label="Servicing released:"
                        property="servicingReleased"
                    />

                    <DiffLabeledValue
                        {...booleanDiffProps}
                        label="Streamline:"
                        property="streamline"
                    />
                </LabelGroup>
            </div>
        </EditableSectionCard>
    );
}

// TODO post-demo move this to common utility file
export function renderBoolean(value: boolean) {
    return (
        <span
            className={clsx({
                [styles.colorSuccess]: value,
                [styles.colorAction]: !value
            })}
        >
            {value ? '✓' : '✕'}
        </span>
    );
}
