import { useState } from 'react';

import ConditionsSummary from './components/ConditionsSummary';
import UnderwritingPageTemplate from './components/UnderwritingPageTemplate';
import UnderwritingStepCard from './components/UnderwritingStepCard';
import AUSReviewSection from './components/aus/AUSReviewSection';

// TODO use react-router for this
export type AUSUnderwritingTabs = 'summary' | 'review';

export default function AUSUnderwritingPage() {
    const [ tab, setTab ] = useState<AUSUnderwritingTabs>('summary');
    const [ finishedTabs, setFinishedTabs ] = useState<AUSUnderwritingTabs[]>([]);

    function tabComplete(tab: AUSUnderwritingTabs, nextTab: AUSUnderwritingTabs) {
        setFinishedTabs(finishedTabs.concat(tab));
        setTab(nextTab);
    }

    return (
        <UnderwritingPageTemplate
            conditionsShown={tab === 'summary'}
            onConditionsClick={() => setTab('summary')}
            relevantDocs={[ 'AUS Report.pdf' ]}
            stepCards={(
                <UnderwritingStepCard
                    active={tab === 'review'}
                    conditionIDs={[]}
                    done={finishedTabs.includes('review')}
                    label="AUS Review"
                    onClick={() => setTab('review')}
                />
            )}
        >
            {tab === 'summary' ? (
                <ConditionsSummary header="AUS conditions" />
            ) : tab === 'review' ? (
                <AUSReviewSection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : null}
        </UnderwritingPageTemplate>
    );
}
