import { apiUtils } from '../../api-utils';
import { Contact } from '../../common';


export function getContacts(clientID: string): Promise<Contact[]> {
    return apiUtils.get(`/client/${clientID}/contact`);
}

export function deleteContact(clientID: string, contactID: string): Promise<void> {
    return apiUtils.delete(`/client/${clientID}/contact/${contactID}`);
}

export function addContact(clientID: string, contact: Omit<Contact, 'id'>): Promise<Contact> {
    return apiUtils.post(`/client/${clientID}/contact`, contact);
}

export function editContact(clientID: string, contact: Contact): Promise<Contact> {
    return apiUtils.put(`/client/${clientID}/contact/${contact.id}`, contact);
}
