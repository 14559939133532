import { useEffect, useState } from 'react';

import { CreditUnderwritingTabs } from '../../CreditUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';


interface FraudSectionProps {
    setTab: (tab: CreditUnderwritingTabs) => void;
    onTabComplete: (tab: CreditUnderwritingTabs, nextTab: CreditUnderwritingTabs) => void;
}

export default function FraudSection({ onTabComplete }: FraudSectionProps) {
    const [ hasFraudAlerts, setHasFraudAlerts ] = useState<boolean>();
    const [ hasProcessorCert, setHasProcessorCert ] = useState<boolean>();

    useEffect(() => {
        if (hasFraudAlerts === false || (hasFraudAlerts && hasProcessorCert !== undefined)) {
            onTabComplete('fraud', 'derogatories');
        }
    }, [
        hasFraudAlerts, hasProcessorCert, onTabComplete
    ]);

    return (
        <UnderwritingStepSection title="Fraud alerts">
            <UnderwritingYesNoQuestionCard
                question="Are there any fraud alerts shown on the credit report?"
                onAnswered={setHasFraudAlerts}
                answer={hasFraudAlerts}
            />

            {hasFraudAlerts && (
                <UnderwritingYesNoQuestionCard
                    question="Is there a processor certification to satisfy the alert(s)?"
                    onAnswered={setHasProcessorCert}
                    answer={hasProcessorCert}
                />
            )}
        </UnderwritingStepSection>
    );
}
