import { Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import { AppraisalUnderwritingTabs } from '../../AppraisalUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';
// TODO move into util?

import styles from './AppraisalDetailsSection.module.scss';


interface AppraisalUCDPSectionProps {
    setTab: (tab: AppraisalUnderwritingTabs) => void;
    onTabComplete: (tab: AppraisalUnderwritingTabs, nextTab: AppraisalUnderwritingTabs) => void;
}

export default function AppraisalUCDPSection({ onTabComplete }: AppraisalUCDPSectionProps) {
    const [ fannie, setFannie ] = useState<boolean>();
    const [ freddie, setFreddie ] = useState<boolean>();

    useEffect(() => {
        if (fannie === false && freddie === false) {
            onTabComplete('ucdp', 'summary'); // TODO send to qualifying if multi-appraisal
        }
    }, [
        fannie, freddie, onTabComplete
    ]);

    const nextButton = (
        <Button
            variant="contained"
            className={styles.nextButton}
            onClick={() => onTabComplete('ucdp', 'summary')}
        >
            Next
        </Button>
    );

    return (
        <UnderwritingStepSection title="SSR/UCDP Submissions">
            <div className={styles.questionRow}>
                <UnderwritingYesNoQuestionCard
                    question="Is the Fannie Mae UCDP in file?"
                    onAnswered={setFannie}
                    answer={fannie}
                >
                    <TextField label="Fannie UCDP score" />

                    {!freddie && nextButton}
                </UnderwritingYesNoQuestionCard>

                <UnderwritingYesNoQuestionCard
                    question="Is the Freddie Mac UCDP in file?"
                    onAnswered={setFreddie}
                    answer={freddie}
                >
                    <TextField label="Freddie UCDP score" />

                    {nextButton}
                </UnderwritingYesNoQuestionCard>
            </div>
        </UnderwritingStepSection>
    );
}

