import { loanPurposeDisplay, occupancyTypeDisplay, propertyTypeDisplay } from '@api';
import { FormLabel } from '@mui/material';
import {
    AddressFieldset, CurrencyField, TextField, isAWholeNumber, isAtLeast, renderEnumOptions
} from '@tsp-ui/core/components';

import { ManualLoanEntryFormValues } from '../ManualLoanEntryPage';

import ManualLoanEntryStep, { ManualLoanEntryStepProps } from './ManualLoanEntryStep';
import styles from './PropertyStep.module.scss';


export default function PropertyStep(props: Required<Pick<ManualLoanEntryStepProps, 'onPreviousButtonClick'>>) {
    return (
        <ManualLoanEntryStep {...props}>
            <div>
                <FormLabel required>
                    Property details
                </FormLabel>

                <div className={styles.loanInfoContainer}>
                    <TextField<ManualLoanEntryFormValues>
                        name="propertyType"
                        label="Property type"
                        select
                        required
                        hideRequiredIndicator
                    >
                        {renderEnumOptions(propertyTypeDisplay)}
                    </TextField>

                    <TextField<ManualLoanEntryFormValues>
                        name="propertyUnits"
                        label="Number of units"
                        required
                        hideRequiredIndicator
                        type="number"
                        rules={{
                            ...isAtLeast(1),
                            ...isAWholeNumber
                        }}
                    />

                    <TextField<ManualLoanEntryFormValues>
                        name="loanPurpose"
                        label="Purpose"
                        select
                        required
                        hideRequiredIndicator
                    >
                        {renderEnumOptions(loanPurposeDisplay)}
                    </TextField>

                    <TextField<ManualLoanEntryFormValues>
                        name="occupancyType"
                        label="Occupancy"
                        select
                        required
                        hideRequiredIndicator
                    >
                        {renderEnumOptions(occupancyTypeDisplay)}
                    </TextField>

                    <CurrencyField<ManualLoanEntryFormValues>
                        name="appraisedValue"
                        label="Appraised value"
                        required
                        hideRequiredIndicator
                        rules={isAtLeast(0)}
                    />

                    <CurrencyField<ManualLoanEntryFormValues>
                        name="salesPrice"
                        label="Sales price (optional)"
                        rules={isAtLeast(0)}
                    />
                </div>
            </div>

            <AddressFieldset<ManualLoanEntryFormValues>
                required
                fieldNames={{
                    street: 'propertyStreetAddress1',
                    line2: 'propertyStreetAddress2',
                    city: 'propertyCity',
                    state: 'propertyState',
                    zip: 'propertyZipCode',
                    county: 'propertyCounty'
                }}
            />
        </ManualLoanEntryStep>
    );
}
