import {
    Agency,
    ChecklistStepType,
    CommitmentType,
    ContactResponsibility,
    ContactTitle,
    CustomerAction,
    CustomerCompensationType,
    CustomerStatus,
    FeeType,
    ProductType,
    ServicingType,
    State,
    UnderwritingType,
    User,
    UserType
} from '@api';
import { rest } from 'msw';

import { getMockUrl } from '../../../mocks/getMockUrl';
import { branches } from '../branches/branches-mocks';
import { SAMPLE_PDF_URL, checklistSteps } from '../checklist/checklist-mocks';
import { customerFeesComp } from '../compensation/compensation-mocks';
import { CustomerDetails } from '../customer-api';
import { productConfiguration } from '../eligibleProducts/eligibleProducts-mocks';
import { licenses } from '../licenses/licenses-mocks';
import { wireInstructionsSet } from '../wireInfo/wireInfo-mocks';

import { CustomerHistoryEvent, setMockHistoryEvents } from './eventHistory-api';


export const mocks = [
    rest.get(getMockUrl('/customer/:id/eventHistory'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(historyEvents)
    )))
];

const historyCustomer: CustomerDetails = {
    id: '1',
    name: 'American Bank',
    nmlsId: '123456',
    taxID: '1234567890',
    address: branches[0].address,
    dbaNames: [ 'AmCap Mortgage, Ltd.', 'American Bank LTD' ],
    productConfiguration,
    primaryContact: {
        id: '1',
        responsibilities: [ ContactResponsibility.ACCOUNTING ],
        name: 'P. Contact',
        phone: '123-456-7890',
        email: 'primarycontact@email.com',
        title: ContactTitle.SVP,
        isPrimary: false
    },
    counterpartyAnalystId: '1',
    primaryAccountExecId: '2',
    secondaryAccountExecId: '3',
    accountManagerId: '4',
    status: CustomerStatus.PENDING
};

const historyUser: User = {
    id: '1',
    firstName: 'Joeseph',
    lastName: 'Schmo',
    email: 'joe@shmos-mortgage.com',
    roleIds: [ 1, 2 ],
    isActive: true,
    phone: null,
    type: UserType.CUSTOMER
};

const historyEvents: CustomerHistoryEvent[] = [
    {
        id: '1',
        action: CustomerAction.CUSTOMER_DETAILS_UPDATED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            before: historyCustomer,
            after: {
                ...historyCustomer,
                dbaNames: undefined
            }
        }
    }, {
        id: '2',
        action: CustomerAction.CONTACT_UPDATED,
        executedBy: historyUser,
        date: '2021-04-05T16:18:06.526Z',
        details: {
            before: {
                id: '1',
                responsibilities: [ ContactResponsibility.COMPLIANCE ],
                name: 'Joeseph',
                phone: '094-234-9465',
                email: 'joe@shmos-mortgage.com',
                title: ContactTitle.SVP,
                isPrimary: false
            },
            after: {
                id: '1',
                responsibilities: [ ContactResponsibility.COMPLIANCE ],
                name: 'Johnathan',
                phone: '094-234-9465',
                email: 'john@doe-mortgage.com',
                title: ContactTitle.SVP,
                isPrimary: false
            }
        }
    }, {
        id: '3',
        action: CustomerAction.FEE_DISABLED,
        executedBy: historyUser,
        date: '2021-10-17T05:12:43.123Z',
        details: {
            after: {
                id: '1',
                feeType: FeeType['203K_SUPPLEMENTAL_FEE'],
                defaultAmount: 23500,
                adjustedAmount: 24500,
                isActive: true
            }
        }
    }, {
        id: '4',
        action: CustomerAction.MANAGERS_UPDATED,
        executedBy: historyUser,
        date: '2021-11-01T02:10:18.456Z',
        details: {
            before: historyCustomer,
            after: historyCustomer
        }
    }, {
        id: '5',
        action: CustomerAction.WIRE_INSTRUCTIONS_APPROVED,
        executedBy: historyUser,
        date: '2021-11-01T02:10:18.456Z',
        details: {
            after: wireInstructionsSet[0]
        }
    }, {
        id: '6',
        action: CustomerAction.LICENSE_ADDED,
        executedBy: historyUser,
        date: '2021-11-01T02:10:18.456Z',
        details: {
            after: licenses[0]
        }
    }, {
        id: '7',
        action: CustomerAction.LICENSE_UPDATED,
        executedBy: historyUser,
        date: '2021-11-01T02:10:18.456Z',
        details: {
            before: licenses[0],
            after: licenses[1]
        }
    }, {
        id: '8',
        action: CustomerAction.DUE_DILIGENCE_ITEM_COMPLETED,
        executedBy: historyUser,
        date: '2021-11-01T02:10:18.456Z',
        details: {
            after: checklistSteps[0]
        }
    }, {
        id: '9',
        action: CustomerAction.BRANCH_ADDED,
        executedBy: historyUser,
        date: '2021-11-01T02:10:18.456Z',
        details: {
            after: branches[0]
        }
    }, {
        id: '10',
        action: CustomerAction.FEE_ADJUSTED,
        executedBy: historyUser,
        date: '2021-11-01T02:10:18.456Z',
        details: {
            before: customerFeesComp.fees![0],
            after: customerFeesComp.fees![1]
        }
    }, {
        id: '11',
        action: CustomerAction.COMPENSATION_DETAILS_UPDATED,
        executedBy: historyUser,
        date: '2021-11-01T02:10:18.456Z',
        details: {
            before: customerFeesComp.compensation,
            after: {
                id: '1',
                amount: 12000,
                firstLienOnly: false,
                isActive: true,
                type: CustomerCompensationType.FIXED,
                notes: 'N/A'
            }
        }
    }, {
        id: '12',
        action: CustomerAction.BRANCH_ADDED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            after: {
                id: '45',
                nmlsId: '12345',
                address: {
                    street: '123 E St',
                    line2: '#301',
                    city: 'Phoenix',
                    state: State.AZ,
                    zip: '85250'
                }
            }
        }
    }, {
        id: '13',
        action: CustomerAction.ELIGIBLE_PRODUCTS_PRODUCT_UPDATED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            before: {
                name: '10 year conventional',
                limit: {
                    id: '1',
                    currentAmount: 200000,
                    limitAmount: 250000,
                    expirationDate: '2022-10-31'
                }
            },
            after: {
                name: '10 year conventional',
                limit: {
                    id: '1',
                    currentAmount: 200000,
                    limitAmount: 300000,
                    expirationDate: '2022-10-31'
                }
            }
        }
    }, {
        id: '14',
        action: CustomerAction.ELIGIBLE_PRODUCTS_TYPE_UPDATED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            before: {
                id: '1',
                productType: ProductType.CONVENTIONAL,
                underwritingType: UnderwritingType.DELEGATED,
                servicingType: ServicingType.RELEASED,
                commitmentTypes: [ CommitmentType.BEST_EFFORT, CommitmentType.MANDATORY ],
                lockPeriods: [
                    15, 30, 60
                ],
                limit: {
                    id: '1',
                    currentAmount: 200000,
                    limitAmount: 250000,
                    expirationDate: '2022-10-31'
                },
                productConfigs: []
            },
            after: {
                id: '1',
                productType: ProductType.CONVENTIONAL,
                underwritingType: UnderwritingType.DELEGATED,
                servicingType: ServicingType.RELEASED,
                commitmentTypes: [ CommitmentType.BEST_EFFORT, CommitmentType.MANDATORY ],
                lockPeriods: [
                    15, 30, 60
                ],
                limit: {
                    id: '1',
                    currentAmount: 200000,
                    limitAmount: 300000,
                    expirationDate: '2022-10-31'
                },
                productConfigs: []
            }
        }
    }, {
        id: '15',
        action: CustomerAction.ELIGIBLE_PRODUCTS_UW_CATEGORY_DISABLED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            after: {
                type: UnderwritingType.DELEGATED,
                products: [
                    '10 year conventional', '20 year conventional', '10 year jumbo', '10 year VA'
                ]
            }
        }
    }, {
        id: '16',
        action: CustomerAction.ELIGIBLE_PRODUCTS_TYPE_DISABLED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            after: {
                type: ProductType.CONVENTIONAL,
                products: [
                    '10 year conventional', '20 year conventional', '30 year conventional'
                ]
            }
        }
    }, {
        id: '17',
        action: CustomerAction.ELIGIBLE_PRODUCTS_PRODUCT_ENABLED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            after: {
                product: '10 year conventional'
            }
        }
    }, {
        id: '18',
        action: CustomerAction.DUE_DILIGENCE_ITEM_UPDATED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            before: {
                customerId: 1,
                id: '7',
                name: 'Investor Relationships',
                description: 'this is where you fill out investor relationships',
                type: ChecklistStepType.INVESTORS,
                isRequired: true,
                isCompleted: false,
                completedDate: '',
                orderId: 3,
                notes: '',
                documents: [
                    {
                        id: '1',
                        name: 'Document 1.pdf',
                        url: SAMPLE_PDF_URL
                    },
                    {
                        id: '2',
                        name: 'Document 2.pdf',
                        url: SAMPLE_PDF_URL
                    },
                    {
                        id: '3',
                        name: 'Document 3.pdf',
                        url: SAMPLE_PDF_URL
                    }
                ],
                isDocRequired: true
            },
            after: {
                customerId: 1,
                id: '7',
                name: 'Investor Relationships',
                description: 'this is where you fill out investor relationships',
                type: ChecklistStepType.INVESTORS,
                isRequired: true,
                isCompleted: false,
                completedDate: '',
                orderId: 3,
                notes: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                investors: [
                    {
                        id: '1',
                        companyName: 'Investor 1',
                        contact: 'Joe Shmo',
                        phone: '123-456-7890',
                        emailAddress: 'joes@shmos-mortgage.com'
                    },
                    {
                        id: '2',
                        companyName: 'Investor 2',
                        contact: 'Morgan Shmo',
                        phone: '123-456-7891',
                        emailAddress: 'morgans@shmos-mortgage.com'
                    },
                    {
                        id: '3',
                        companyName: 'Investor 3',
                        contact: 'Moe Shmo',
                        phone: '123-456-7892',
                        emailAddress: 'moes@shmos-mortgage.com'
                    }
                ],
                documents: [
                    {
                        id: '1',
                        name: 'Document 1.pdf',
                        url: SAMPLE_PDF_URL
                    },
                    {
                        id: '2',
                        name: 'Document 2.pdf',
                        url: SAMPLE_PDF_URL
                    },
                    {
                        id: '3',
                        name: 'Document 3.pdf',
                        url: SAMPLE_PDF_URL
                    }
                ],
                isDocRequired: true
            }
        }
    }, {
        id: '19',
        action: CustomerAction.DUE_DILIGENCE_DOCUMENT_UPLOADED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            before: {
                customerId: 1,
                id: '7',
                name: 'Agency Approval',
                description: 'this is where you fill out investor relationships',
                type: ChecklistStepType.AGENCY,
                isRequired: true,
                isCompleted: false,
                completedDate: '',
                orderId: 3,
                notes: '',
                isDocRequired: true,
                agencies: [
                    {
                        agency: Agency.FHA,
                        id: '1',
                        firstApprovalDate: '2020-10-31',
                        latestApprovalDate: '2021-10-31'
                    }
                ]
            },
            after: {
                customerId: 1,
                id: '7',
                name: 'Agency Approval',
                description: 'this is where you fill out investor relationships',
                type: ChecklistStepType.AGENCY,
                isRequired: true,
                isCompleted: false,
                completedDate: '',
                orderId: 3,
                notes: '',
                documents: [
                    {
                        id: '1',
                        name: 'Document 1.pdf',
                        url: SAMPLE_PDF_URL
                    }
                ],
                agencies: [
                    {
                        agency: Agency.FHA,
                        id: '1',
                        firstApprovalDate: '2020-10-31',
                        latestApprovalDate: '2021-10-31'
                    }
                ],
                isDocRequired: true
            }
        }
    }, {
        id: '20',
        action: CustomerAction.DUE_DILIGENCE_DOCUMENT_UPLOADED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            before: {
                customerId: 1,
                id: '7',
                name: 'Insurance',
                description: 'this is where you fill out investor relationships',
                type: ChecklistStepType.INSURANCE,
                isRequired: true,
                isCompleted: false,
                completedDate: '',
                orderId: 3,
                notes: '',
                isDocRequired: true,
                insurances: [
                    {
                        id: '1',
                        insurerName: 'Schmo Insurance Group',
                        policyNumber: '1234743233',
                        contact: 'Jared Schmo',
                        phone: '516-445-2385',
                        emailAddress: 'jareds@schmos-insurance-group.com'
                    },
                    {
                        id: '2',
                        insurerName: 'Lewis Insurance Group',
                        policyNumber: '1234743234',
                        contact: 'Jane Lewis',
                        phone: '516-445-2385',
                        emailAddress: 'janel@lewis-insurance-group.com'
                    }
                ]
            },
            after: {
                customerId: 1,
                id: '7',
                name: 'Insurance',
                description: 'this is where you fill out investor relationships',
                type: ChecklistStepType.INSURANCE,
                isRequired: true,
                isCompleted: false,
                completedDate: '',
                orderId: 3,
                notes: '',
                documents: [
                    {
                        id: '1',
                        name: 'Document 1.pdf',
                        url: SAMPLE_PDF_URL
                    }
                ],
                insurances: [
                    {
                        id: '3',
                        insurerName: 'Schmo Insurance Group',
                        policyNumber: '1234743235',
                        contact: 'Jared Schmo',
                        phone: '516-445-2385',
                        emailAddress: 'jareds@schmos-insurance-group.com'
                    },
                    {
                        id: '4',
                        insurerName: 'Lewis Insurance Group',
                        policyNumber: '1234743236',
                        contact: 'Jane Lewis',
                        phone: '516-445-2385',
                        emailAddress: 'janel@lewis-insurance-group.com'
                    }
                ],
                isDocRequired: true
            }
        }
    }, {
        id: '21',
        action: CustomerAction.DUE_DILIGENCE_DOCUMENT_UPLOADED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            before: {
                customerId: 1,
                id: '7',
                name: 'Warehouses',
                description: 'this is where you fill out investor relationships',
                type: ChecklistStepType.WAREHOUSE,
                isRequired: true,
                isCompleted: false,
                completedDate: '',
                orderId: 3,
                notes: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                isDocRequired: true,
                warehouses: [
                    {
                        id: '1',
                        lenderName: 'Doe\'s Lending',
                        lineAmount: 1234,
                        contact: 'John Doe',
                        phone: '424-682-5592',
                        emailAddress: 'jdoe@does-lending.com'
                    },
                    {
                        id: '2',
                        lenderName: 'Schmo\'s Lending Group Incorporated',
                        lineAmount: 1234,
                        contact: 'Joe Schmo',
                        phone: '424-682-5592',
                        emailAddress: 'jschmo@schmos-lending-group-incorporated.com'
                    }
                ]
            },
            after: {
                customerId: 1,
                id: '7',
                name: 'Warehouses',
                description: 'this is where you fill out investor relationships',
                type: ChecklistStepType.WAREHOUSE,
                isRequired: true,
                isCompleted: false,
                completedDate: '',
                orderId: 3,
                notes: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                documents: [
                    {
                        id: '1',
                        name: 'Document 1.pdf',
                        url: SAMPLE_PDF_URL
                    }
                ],
                warehouses: [
                    {
                        id: '3',
                        lenderName: 'Doe\'s Lending',
                        lineAmount: 1234,
                        contact: 'John Doe',
                        phone: '424-682-5592',
                        emailAddress: 'jdoe@does-lending.com'
                    },
                    {
                        id: '4',
                        lenderName: 'Schmo\'s Lending Group Incorporated',
                        lineAmount: 1234,
                        contact: 'Joe Schmo',
                        phone: '424-682-5592',
                        emailAddress: 'jschmo@schmos-lending-group-incorporated.com'
                    }
                ],
                isDocRequired: true
            }
        }
    }, {
        id: '22',
        action: CustomerAction.USER_UPDATED,
        executedBy: historyUser,
        date: '2020-12-12T09:08:12.496Z',
        details: {
            before: historyUser,
            after: historyUser
        }
    }
];

setMockHistoryEvents(historyEvents); // TODO post-demo remove this
