import api, { EligibilityExclusions, EligibilityGuidelineSet } from '@api';
import {
    Button, DialogContent, Paper, Tab, Tabs, Typography
} from '@mui/material';
import { RoutedDialog, RoutedDialogImplProps } from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage, useParams } from '@tsp-ui/core/utils';
import { AdminSubRouteParams } from '@views/admin/components/AdminPageTemplate';
import clsx from 'clsx';
import { useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { EligibilityExclusionRow } from '../../components/EligibilityExclusionRow';
import { ViewEligibilityGuidelineGrid } from '../../components/EligibilityGuidelineGrid';

import { EligibilityGuidelinesContext } from './EditEligibilityGuidelinesDialog';
import { ViewHighLevelGuidelines } from './HighLevelGuidelines';
import styles from './ViewEligibilityGuidelinesDialog.module.scss';


export default function ViewEligibilityGuidelinesDialog({ closeTo }: RoutedDialogImplProps) {
    const [ activeTab, setActiveTab ] = useState<'matrix' | 'exclusions'>('matrix');
    const [ eligibilityGuidelineSet, setEligibilityGuidelineSet ] = useState<EligibilityGuidelineSet>();

    const { investorID, loanProgramID, guidelineSetID } = useParams<AdminSubRouteParams<'guidelineSet'>>();

    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        try {
            setEligibilityGuidelineSet(await api.investors.getEligibilityGuidelineSetDetail(
                investorID, loanProgramID, guidelineSetID
            ));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching guideline set details', error);
        }
    }, [
        investorID, loanProgramID, guidelineSetID, pageMessage
    ]));

    return (
        <EligibilityGuidelinesContext.Provider
            value={{
                eligibilityGuidelineSet,
                setEligibilityGuidelineSet
            }}
        >
            <RoutedDialog
                title="View eligibility guidelines"
                closeTo={closeTo}
                fullWidth
                maxWidth={activeTab !== 'matrix' ? undefined : false}
                classes={{ paper: clsx({ [styles.matrixDialog]: activeTab === 'matrix' }) }}
            >
                <DialogContent className={styles.tabContent}>
                    <Tabs
                        value={activeTab}
                        onChange={(_, newTab) => setActiveTab(newTab)}
                    >
                        <Tab
                            label="Matrix"
                            value="matrix"
                        />

                        <Tab
                            label="Exclusions"
                            value="exclusions"
                        />
                    </Tabs>
                </DialogContent>

                <DialogContent>
                    {activeTab === 'matrix' ? (
                        <div className={styles.matrixContainer}>
                            <ViewHighLevelGuidelines />

                            <div className={styles.gridContainer}>
                                <ViewEligibilityGuidelineGrid />
                            </div>
                        </div>
                    ) : (
                        <ViewEligibilityExclusions />
                    )}
                </DialogContent>
            </RoutedDialog>
        </EligibilityGuidelinesContext.Provider>
    );
}

function ViewEligibilityExclusions() {
    const { exclusions } = useContext(EligibilityGuidelinesContext)?.eligibilityGuidelineSet || {};

    return (
        <Paper
            variant="outlined"
            className={styles.exclusionContainer}
        >
            <ExclusionsView exclusions={exclusions} />
        </Paper>
    );
}

export function ExclusionsView({ exclusions, editTo = 'edit' }: { exclusions: EligibilityExclusions | undefined, editTo?: string }) {
    return (
        exclusions ? (
            <>
                <Typography align="center">
                    Loans will be disqualified if:
                </Typography>

                <div className={styles.exclusionCardContainer}>
                    {[
                        ...exclusions.exclusions?.map(exclusions => ({ exclusions: [ exclusions ] })) || [],
                        ...(exclusions.groups || [])
                    ].map((exclusionGroup, index) => (
                        <div
                            className={styles.logicContainer}
                            key={`${JSON.stringify(exclusionGroup)}`}
                        >
                            {index > 0 && (
                                <Typography>
                                    <u>{exclusions.operator.toUpperCase()}</u>
                                </Typography>
                            )}

                            <Paper
                                variant="outlined"
                                className={styles.exclusionCard}
                            >
                                <EligibilityExclusionRow
                                    hideIcon
                                    exclusionGroup={exclusionGroup}
                                />
                            </Paper>
                        </div>
                    ))}
                </div>
            </>
        ) : (
            <>
                <Typography className={styles.noExclusions}>
                    This investor does not have any eligibility exclusions
                </Typography>

                <Button
                    component={Link}
                    to={editTo}
                    className={styles.button}
                >
                    Add eligibility exclusions
                </Button>
            </>
        )
    );
}
