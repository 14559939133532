import { CustomerCompensationPercentageOf, CustomerCompensationType, FeeType } from '../..';
import { apiUtils } from '../../api-utils';


export function getFeesComp(customerID: string): Promise<CustomerFeesComp> {
    return apiUtils.get(`/customer/${customerID}/compensation`);
}

export function updateFeesComp(
    customerID: string, feesComp: CustomerFeesComp
): Promise<CustomerFeesComp> {
    return apiUtils.put(`/customer/${customerID}/compensation`, feesComp);
}

export interface CustomerFeesComp {
    customerId: number;
    compensation?: CustomerCompensation;
    fees?: CustomerFee[];
}

export interface CustomerFee {
    id: string;
    feeType: FeeType;
    defaultAmount: number;
    adjustedAmount: number | undefined;
    isActive: boolean;
    isCustom?: boolean;
}

export interface CustomerCompensation {
    id: string;
    isActive: boolean;
    firstLienOnly: boolean;
    type: CustomerCompensationType;
    amount?: number;
    percentage?: number;
    percentageOf?: CustomerCompensationPercentageOf;
    minAmount?: number;
    maxAmount?: number;
    notes: string;
}
