import { apiUtils } from '../api-utils';
import { LoanStatus } from '../enums/loanStatusEnum';


let documentId = 1;

export async function getLoanDocuments(loanID: string, status: LoanStatus): Promise<LoanDocument[]> {
    return (await apiUtils.get(`/document/loan/${loanID}`))
        .filter((document: LoanDocument) => (
            status === LoanStatus.AWAITING_DOCS
                ? document.name === 'Lock In Confirmation.pdf'
                : true
        )).map((document: LoanDocument) => ({
            ...document,
            id: `${documentId++}`
        }));
}

// TODO post-demo replace uploadDocument with uploadDocuments
export function uploadDocument(loanID: string, formData: FormData): Promise<LoanDocument> {
    return apiUtils.post(`/document/loan/${loanID}`, formData, { useAutoContentType: true });
}

// TODO update this (no longer pass client-token)
export async function getLoanDocumentURL(
    endpointURL: string
): Promise<string> {
    return URL.createObjectURL(new Blob(
        [ await fetch(endpointURL, { headers: { 'client-token': apiUtils.getClientToken() } }).then((response) => response.blob()) ],
        { type: 'application/pdf' }
    ));
}

export interface LoanDocument {
    id: string;
    loanId: string;
    name: string;
    containerName: string;
    instanceCount: number;
    url: string;
}
