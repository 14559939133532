import { UserType } from '@api';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import {
    CssBaseline, StyledEngineProvider, Theme, ThemeProvider, Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AuthType, authenticateSession, isAuthenticationInitialized } from '@redux/auth';
import { useDispatch, useSelector } from '@redux/store';
import {
    ConfirmDialog, ConfirmDialogContext, PageMessageContext, SentryRoutes
} from '@tsp-ui/core/components';
import { useAsyncEffect, useConfirmDialogValue, usePageMessageValue } from '@tsp-ui/core/utils';
import { useTryGetCurrentAccount } from '@utils/hooks';
import AuthenticatedRouteSwitch from '@views/AuthenticatedRouteSwitch';
import LandingPage from '@views/LandingPage';
import LoginPage from '@views/LoginPage';
import UnauthedHeader from '@views/components/UnauthedHeader';
import {
    ReactNode, useCallback, useEffect, useState
} from 'react';
import { Route } from 'react-router-dom';

import styles from './App.module.scss';
import configureApp from './config/configureApp';
import { createPremTheme } from './config/mui-theme';


const defaultPremTheme = createPremTheme('#7092BF', '#70BABF');
const clientTheme = createPremTheme('#954171', '#419565');

export default function App() {
    const { isConfigError, isInitialized } = useConfigureApp();
    const { accountUserType } = useTryGetCurrentAccount() || {};

    const overrideTheme = accountUserType === UserType.CLIENT || accountUserType === UserType.CUSTOMER;

    useEffect(() => {
        if (overrideTheme) {
            document.body.classList.add(styles.clientTheme);

            return () => document.body.classList.remove(styles.clientTheme);
        }
    }, [ overrideTheme ]);

    return (
        <Providers themeOverride={overrideTheme ? clientTheme : undefined}>
            {isConfigError ? (
                <>
                    <UnauthedHeader />

                    <Typography>
                        We ran into a problem starting the app, please contact support.
                    </Typography>
                </>
            ) : isInitialized && (
                <SentryRoutes>
                    <Route
                        path="/"
                        element={<LandingPage />}
                    />

                    <Route
                        path="/login"
                        element={<LoginPage />}
                    />

                    <Route
                        path="*"
                        element={<AuthenticatedRouteSwitch />}
                    />
                </SentryRoutes>
            )}
        </Providers>
    );
}

export function useConfigureApp() {
    const dispatch = useDispatch();
    const isInitialized = useSelector(isAuthenticationInitialized);

    const [ isConfigError, setConfigError ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        try {
            await configureApp();

            dispatch(authenticateSession(AuthType.INIT));
        } catch (error) {
            setConfigError(true);
            console.error(error);
        }
    }, [ dispatch ]));

    return {
        isInitialized,
        isConfigError
    };
}

interface ProvidersProps {
    children?: ReactNode;
    themeOverride?: Theme;
}

export function Providers({ children, themeOverride }: ProvidersProps) {
    const pageMessageValue = usePageMessageValue();
    const confirmDialogValue = useConfirmDialogValue();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themeOverride || defaultPremTheme}>
                <AmplifyProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <PageMessageContext.Provider value={pageMessageValue}>
                            <ConfirmDialogContext.Provider value={confirmDialogValue}>
                                <CssBaseline />

                                <ConfirmDialog />

                                {children}
                            </ConfirmDialogContext.Provider>
                        </PageMessageContext.Provider>
                    </LocalizationProvider>
                </AmplifyProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
