import { Fade, Slide, SlideProps } from '@mui/material';
import React, { ReactNode, useRef } from 'react';

import styles from './NavList.module.scss';


interface NavListProps {
    children: ReactNode;
    direction: SlideProps['direction'];
    in: boolean | undefined;
}

export default function NavList({
    children, direction, in: inProp
}: NavListProps) {
    const slideContainerRef = useRef<HTMLDivElement>(null);

    return (
        <Fade
            in={inProp}
            appear={false}
        >
            <div className={styles.slideContainer}>
                <Slide
                    in={inProp}
                    direction={direction}
                    appear={false}
                    container={slideContainerRef?.current}
                >
                    <div
                        className={styles.navList}
                        ref={slideContainerRef}
                    >
                        {children}
                    </div>
                </Slide>
            </div>
        </Fade>
    );
}
