export enum PermissionCategory {
    CREATE = 'CREATE',
    DELETE = 'DELETE',
    EDIT = 'EDIT',
    VIEW = 'VIEW'
}

export const permissionCategoryDisplay = {
    [PermissionCategory.CREATE]: 'Create',
    [PermissionCategory.DELETE]: 'Delete',
    [PermissionCategory.EDIT]: 'Edit',
    [PermissionCategory.VIEW]: 'View'
};
