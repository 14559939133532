import api, {
    CustomerStatus, customerPendingStepsDisplay, customerStatusDisplay
} from '@api';
import { Button, Tooltip } from '@mui/material';
import { useContext } from 'react';

import StatusActionsCard from '../../../components/StatusActionsCard';
import StatusChecklistItem from '../../components/StatusChecklistItem';
import { CustomerDetailContext } from '../CustomerDetailPage';


export default function CustomerActionsCard() {
    const { updateCustomer, customer } = useContext(CustomerDetailContext);
    const { status, pendingSteps } = customer!;

    const requiredSteps = pendingSteps?.filter(({ isRequired }) => isRequired);
    const canApprove = !requiredSteps?.length;

    return (
        <StatusActionsCard
            actions={status === CustomerStatus.PENDING && (
                <Tooltip title={canApprove ? '' : 'Required items must be completed prior to approval'}>
                    <span>
                        <Button
                            variant="contained"
                            disabled={!canApprove}
                            fullWidth
                            onClick={async () => {
                                updateCustomer(await api.customer.updateCustomer({
                                    ...customer,
                                    status: CustomerStatus.ACTIVE
                                }));
                            }}
                        >
                            Approve
                        </Button>
                    </span>
                </Tooltip>
            )}
            checklistItems={requiredSteps?.map(({ name }) => (
                <StatusChecklistItem
                    label={customerPendingStepsDisplay[name]}
                    key={name}
                />
            ))}
            noBorder={status !== CustomerStatus.PENDING}
            status={customerStatusDisplay[status]}
            variant={status}
        />
    );
}
