import api, { CustomerStatus, CustomerSummary, customerStatusDisplay } from '@api';
import { HelpOutline } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { useState } from 'react';

import AdminPageTemplate, { AdminEntityGroup, AdminEntityGroupProps } from '../components/AdminPageTemplate';

import CustomerDetailPage from './CustomerDetailPage/CustomerDetailPage';
import CustomerForm from './CustomerForm';
import { CustomerCard } from './components/CustomerCard';


const { ACTIVE, INACTIVE, PENDING } = CustomerStatus;

export default function CustomerManagementPage() {
    const [ showInactive, setShowInactive ] = useState(false);

    return (
        <AdminPageTemplate
            CreateEditForm={CustomerForm}
            DetailPage={CustomerDetailPage}
            entityName="customer"
            EntityGroupComponent={CustomerGroup}
            fetchEntities={api.customer.getCustomers}
            filterByLabel="name or NMLS ID"
            filterEntity={({ name, nmlsId }, filterInputValue) => (
                name.toLocaleLowerCase().includes(filterInputValue) || nmlsId.includes(filterInputValue)
            )}
            otherFilters={(
                <Button onClick={() => setShowInactive(!showInactive)}>
                    {showInactive ? 'Hide' : 'Show'} inactive
                </Button>
            )}
            sortEntitiesBy="name"
            visibleGroups={[
                PENDING,
                ACTIVE,
                ...(showInactive ? [ INACTIVE ] : [])
            ]}
        />
    );
}

function CustomerGroup({
    entities: customers,
    group: statusProp
}: AdminEntityGroupProps<CustomerSummary, CustomerStatus>) {
    return (
        <AdminEntityGroup
            header={(
                <>
                    {customerStatusDisplay[statusProp]}

                    {statusProp === PENDING && (
                        <Tooltip title="Pending customers are not yet active. Complete required items to activate them.">
                            <HelpOutline color="primary" />
                        </Tooltip>
                    )}
                </>
            )}
        >
            {customers
                .filter(({ status }) => status === statusProp)
                .map((customer) => (
                    <CustomerCard
                        key={customer.id}
                        customer={customer}
                    />
                ))}
        </AdminEntityGroup>
    );
}
