import api, { LockAvailabilityHoliday } from '@api';
import {
    replaceItemByKey, useConfirm, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { LockAvailabilityCard } from './LockAvailabilityCard';
import { LockAvailabilityContext } from './LockAvailabilityPage';


interface LockHolidayAvailabilityCardProps {
    holiday: LockAvailabilityHoliday;
}

export function LockHolidayAvailabilityCard({ holiday }: LockHolidayAvailabilityCardProps) {
    const {
        lockAvailabilityHolidayId, lockAvailabilityDate, isAvailable, lockStart, lockEnd, description
    } = holiday;

    const { lockAvailabilityHolidays, setLockAvailabilityHolidays } = useContext(LockAvailabilityContext);

    const confirm = useConfirm();
    const navigate = useNavigate();
    const pageMessage = usePageMessage();
    const { customerID } = useParams();

    return (
        <LockAvailabilityCard
            key={lockAvailabilityHolidayId}
            date={lockAvailabilityDate}
            isAvailable={isAvailable}
            lockStart={lockStart}
            lockEnd={lockEnd}
            title={description}
            onEdit={() => navigate(`${lockAvailabilityHolidayId}/edit-holiday`)}
            onDelete={async () => {
                if (await confirm('Are you sure you want to delete this holiday?')) {
                    try {
                        await api.availability.deleteLockAvailabilityHoliday(
                            lockAvailabilityHolidayId, customerID
                        );

                        setLockAvailabilityHolidays(lockAvailabilityHolidays.filter(
                            ({
                                lockAvailabilityHolidayId: id
                            }) => id !== lockAvailabilityHolidayId
                        ));

                        pageMessage.success('Holiday deleted');
                    } catch (error) {
                        pageMessage.handleApiError('An error occurred while deleting the holiday', error);
                    }
                }
            }}
            onToggle={async () => {
                try {
                    setLockAvailabilityHolidays(
                        replaceItemByKey<LockAvailabilityHoliday>(
                            lockAvailabilityHolidays,
                            await api.availability.updateLockAvailabilityHoliday({
                                ...holiday,
                                isAvailable: !isAvailable
                            }, customerID),
                            'lockAvailabilityHolidayId'
                        )
                    );
                } catch (error) {
                    pageMessage.handleApiError('An error occurred while toggling holiday availability', error);
                }
            }}
        />
    );
}
