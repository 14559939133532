import * as Sentry from '@sentry/react';


const {
    CONNECTING, OPEN, CLOSING, CLOSED
} = WebSocket;

const MAX_RETRIES = 5;

/**
 * A web socket that has a built-in retry mechanism if the connection fails. It also includes a retry mechanism that
 * occurs on window focus if the socket connection is closed (to refresh it after long sessions of inactivity)
 */
export class RetryWebSocket {
    public socket: WebSocket;

    private _retryCount = 0;
    private _retryTimeout: NodeJS.Timeout | null = null;
    private _closed = false;

    private readonly _onInit: (socket: WebSocket) => void;
    private readonly _url: string | URL;
    private readonly _protocols?: string | string[];

    constructor(url: string | URL, onInit: (socket: WebSocket) => void, protocols?: string | string[]) {
        this._url = url;
        this._onInit = onInit;
        this._protocols = protocols;

        this._handleWindowFocus = this._handleWindowFocus.bind(this);
        this._handleError = this._handleError.bind(this);

        this.socket = this._initializeWebsocket();

        window.addEventListener('focus', this._handleWindowFocus);
    }

    /**
     * Closes the underlying websocket and sets the closed flag, which will stop any future retries from firing.
     * If the socket is still connecting, it will be closed as soon as it opens.
     */
    public close() {
        window.removeEventListener('focus', this._handleWindowFocus);

        if (this._retryTimeout) {
            clearTimeout(this._retryTimeout);
        }

        const { readyState } = this.socket;

        if (readyState === CONNECTING) {
            this.socket.onopen = () => this.socket.close();
        } else if (readyState === OPEN) {
            this.socket.close();
        }

        this._closed = true;
    }

    /**
     * When the window is focused, check to see if the underlying socket is closed or closing. If it is, attempt to
     * reinitialize the socket.
     */
    private _handleWindowFocus() {
        const { readyState } = this.socket;

        if (readyState === CLOSED || readyState === CLOSING) {
            this._retryCount = 0;
            this.socket = this._initializeWebsocket();
        }
    }

    /**
     * Attached to the underlying socket's onerror function to handle errors connecting to the socket. If the socket
     * isn't closed, and we haven't reached our maximum retry limit, try to reinitialize the socket.
     *
     * @param event - The error event
     */
    private _handleError(event: Event) {
        Sentry.captureException(new Error('Web socket connection failed'), {
            extra: {
                retryCount: this._retryCount,
                event
            }
        });

        if (!this._closed && this._retryCount <= MAX_RETRIES) {
            this._retryTimeout = setTimeout(() => {
                this.socket = this._initializeWebsocket();

                this._retryCount++;
            }, this._getRetryTimeout());
        }
    }

    /**
     * Initializes a new websocket and calls the init function that was passed to this RetryWebSocket in its
     * constructor.
     */
    private _initializeWebsocket(): WebSocket {
        const socket = new WebSocket(this._url, this._protocols);

        socket.onerror = this._handleError;

        this._onInit(socket);

        return socket;
    }

    /**
     * Gets the retry timeout based on the current retry count.
     */
    private _getRetryTimeout() {
        return this._retryCount < 2 ? 2000 : 5000;
    }
}
