import api, { User } from '@api';
import { MenuItem } from '@mui/material';
import {
    PhoneField, Switch, TextField, isEmail
} from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AdminAddEditFormProps } from '../components/AdminAddPageTemplate';

import styles from './UserForm.module.scss';
import { RolesContext } from './UserManagementPage';


export function UserForm({
    entityToEdit: defaultValues,
    setLoading,
    onSubmit
}: AdminAddEditFormProps<User>) {
    const { accountUserType, customerId } = useGetCurrentAccount();

    const { entities: roles } = useContext(RolesContext);

    const navigate = useNavigate();
    const pageMessage = usePageMessage();
    const formMethods = useForm<User>({
        defaultValues
    });

    const handleSubmit = formMethods.handleSubmit(async formData => {
        setLoading(true);

        formData.type = defaultValues?.type || accountUserType;

        try {
            onSubmit(defaultValues
                ? await api.users.editUser(accountUserType, formData, customerId?.toString())
                : await api.users.addUser(accountUserType, formData, customerId?.toString()));

            navigate('..');

            pageMessage.success('User saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the user', error);
        }

        setLoading(false);
    });

    return (
        <form
            noValidate
            id={UserForm.formID}
            onSubmit={handleSubmit}
            className={styles.root}
        >
            <FormProvider {...formMethods}>
                <TextField<User>
                    name="firstName"
                    label="First name"
                    required
                />

                <TextField<User>
                    name="lastName"
                    label="Last name"
                    required
                />

                <TextField<User>
                    name="email"
                    label="Email"
                    required
                    rules={isEmail}
                />

                <PhoneField<User>
                    name="phone"
                    label="Phone"
                />

                <TextField<User>
                    name="roleIds"
                    label="User roles"
                    className={styles.fullWidth}
                    select
                    required
                    SelectProps={{
                        multiple: true
                    }}
                >
                    {roles.map(role => (
                        <MenuItem
                            value={role.id}
                            key={role.id}
                        >
                            {role.name}
                        </MenuItem>
                    ))}
                </TextField>

                <Switch<User>
                    className={styles.fullWidth}
                    name="isActive"
                    label="Enabled"
                />
            </FormProvider>
        </form>
    );
}

UserForm.formID = 'add-edit-user-form';
