export enum LoanStatusNonDelegated {
    REGISTERED = 'REGISTERED',
    LOCKED = 'LOCKED',
    FLOATED = 'FLOATED',
    LOAN_SETUP = 'LOAN_SETUP',
    LOAN_SETUP_INCOMPLETE = 'LOAN_SETUP_INCOMPLETE',
    IN_UNDERWRITING = 'IN_UNDERWRITING',
    APPROVED_CLEAR_TO_CLOSE = 'APPROVED_CLEAR_TO_CLOSE',
    APPROVED_NOT_ACCEPTED = 'APPROVED_NOT_ACCEPTED',
    APPROVED_WITH_CONDITIONS = 'APPROVED_WITH_CONDITIONS',
    SUSPENDED = 'SUSPENDED',
    WITHDRAWN = 'WITHDRAWN',
    DENIED = 'DENIED',
    CLEAR_TO_CLOSE = 'CLEAR_TO_CLOSE',
    IN_CLOSING_FUNDING = 'IN_CLOSING_FUNDING',
    CLOSED_LOAN_DELIVERED = 'CLOSED_LOAN_DELIVERED',
    PURCHASED = 'PURCHASED'
}

export const loanStatusNonDelegatedDisplay = {
    [LoanStatusNonDelegated.REGISTERED]: 'Registered',
    [LoanStatusNonDelegated.LOCKED]: 'Locked',
    [LoanStatusNonDelegated.FLOATED]: 'Floated',
    [LoanStatusNonDelegated.LOAN_SETUP]: 'Loan Setup',
    [LoanStatusNonDelegated.LOAN_SETUP_INCOMPLETE]: 'Loan Setup Incomplete',
    [LoanStatusNonDelegated.IN_UNDERWRITING]: 'In Underwriting',
    [LoanStatusNonDelegated.APPROVED_CLEAR_TO_CLOSE]: 'Approved Clear to Close',
    [LoanStatusNonDelegated.APPROVED_NOT_ACCEPTED]: 'Approved/Not Accepted',
    [LoanStatusNonDelegated.APPROVED_WITH_CONDITIONS]: 'Approved with Conditions',
    [LoanStatusNonDelegated.SUSPENDED]: 'Suspended',
    [LoanStatusNonDelegated.WITHDRAWN]: 'Withdrawn',
    [LoanStatusNonDelegated.DENIED]: 'Denied',
    [LoanStatusNonDelegated.CLEAR_TO_CLOSE]: 'Clear to Close',
    [LoanStatusNonDelegated.IN_CLOSING_FUNDING]: 'In Closing/Funding',
    [LoanStatusNonDelegated.CLOSED_LOAN_DELIVERED]: 'Closed Loan Delivered',
    [LoanStatusNonDelegated.PURCHASED]: 'Purchased'
};
