import { LoanDocument } from '@api';
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';
import { SAMPLE_PDF_URL } from '../customer/checklist/checklist-mocks';


let loanDocumentID = 400;

export const mocks = [
    // rest.get(getMockUrl('/document/loan/:loanID'), (req, res, ctx) => res(
    //     ctx.status(200),
    //     ctx.delay(500),
    //     ctx.json(loanDocs.find(({ loanID }) => loanID === req.params.loanID)?.documents)
    // )),
    // rest.get(getMockUrl('/document/loan/:loanID/:container/:filename'), async (req, res, ctx) => {
    //     const buffer = await fetch(`${process.env.PUBLIC_URL}/sample.pdf`, {
    //         headers: {
    //             'Content-Type': 'application/pdf',
    //             Accept: 'application/pdf'
    //         }
    //     }).then((res) => res.arrayBuffer());
    //
    //     return res(
    //         ctx.set('Content-Length', buffer.byteLength.toString()),
    //         ctx.set('Content-Type', 'application/pdf'),
    //         ctx.body(buffer)
    //     );
    // }),
    rest.post(getMockUrl('/document/loan/:loanID'), (req: { params: Record<string, string>, body: Record<string, any> }, res, ctx) => res(
        ctx.status(200),
        ctx.json<LoanDocument>({
            id: String(loanDocumentID++),
            loanId: req.params.loanID,
            name: req.body.files.name,
            containerName: 'Unnamed Container',
            instanceCount: 1,
            url: SAMPLE_PDF_URL
        })
    ))
];

// const containers = [
//     {
//         name: 'Lock Confirmation',
//         docs: [
//             'Float Lock Agreement', 'Lock In Agreement', 'Lock Extension Approval'
//         ]
//     },
//     {
//         name: 'Purchase Advice',
//         docs: [
//             'Purchase Advice Or Confirmation', 'Asset Checklist', 'Repurchase Requests'
//         ]
//     },
//     {
//         name: 'Borrower Information',
//         docs: [
//             'Credit History Report', 'Background Check', 'Transaction Records'
//         ]
//     }
// ];

// function getLoanDocuments(loanID: string): LoanDocument[] {
//     return [
//         ...(getItemById(loans, loanID).loanStatus === LoanStatus.AWAITING_DOCS
//             ? []
//             : Array(randomNum(1, 8)))
//     ].map(() => {
//         const container = getRandomItemFromArray(containers);
//
//         return {
//             id: String(loanDocumentID++),
//             loanId: loanID,
//             name: getRandomItemFromArray(container.docs),
//             containerName: container.name,
//             instanceCount: 1,
//             url: getMockUrl('/document/loan/someLoanID/someContainer/someFilename')
//         };
//     }).reduce((documents, currentDoc) => { // increment instanceCount on nonunique docNames
//         const duplicate = documents.find(({ name }) => name === currentDoc.name);
//
//         if (duplicate) {
//             currentDoc.id = duplicate.id;
//             currentDoc.instanceCount += 1;
//         }
//
//         return documents.concat([ currentDoc ]);
//     }, [] as LoanDocument[]);
// }

// const loanDocs = loans.map(({ id }) => ({
//     loanID: id,
//     documents: getLoanDocuments(id)
// }));
