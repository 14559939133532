import { apiUtils } from '../api-utils';
import { UserType } from '../enums/userTypeEnum';


const { INTERNAL, CLIENT, CUSTOMER } = UserType;

export function getUsers(userType: UserType, customerID?: string): Promise<User[]> {
    return apiUtils.get(getUserURL(userType, customerID));
}

export function deleteUser(userType: UserType, userID: string, customerID?: string): Promise<void> {
    return apiUtils.delete(getUserURL(userType, customerID, userID));
}

export function addUser(userType: UserType, user: User, customerID?: string): Promise<User> {
    return apiUtils.post(getUserURL(userType, customerID), user);
}

export function editUser(userType: UserType, user: User, customerID?: string): Promise<User> {
    return apiUtils.put(getUserURL(userType, customerID, user.id), user);
}

function getUserURL(userType: UserType, customerID: string | undefined, userID?: string) {
    const urls = userID ? ({
        [INTERNAL]: `/auth/user/${userID}`,
        [CLIENT]: `/client/user/${userID}`,
        [CUSTOMER]: `/customer/${customerID}/user/${userID}`
    }) : ({
        [INTERNAL]: '/auth/user',
        [CLIENT]: '/client/user',
        [CUSTOMER]: `/customer/${customerID}/user`
    });

    return urls[userType];
}

export interface User {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string | null;
    type: UserType;
    isActive: boolean;
    roleIds: number[];
}
