import { Branch } from '@api';
import {
    Button, DialogActions, DialogContent, Paper
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import BranchCard from './BranchCard';
import styles from './BranchesDialogContent.module.scss';


interface BranchesDialogContentProps {
    onEditClick: (branch: Branch) => void;
    branches: Branch[];
}

export default function BranchesDialogContent({ onEditClick, branches }: BranchesDialogContentProps) {
    const { pathname } = useLocation();

    return (
        <>
            <DialogContent>
                <Paper
                    variant="outlined"
                    className={styles.container}
                >
                    {branches.map((branch) => (
                        <BranchCard
                            key={branch.id}
                            branch={branch}
                            onEditClick={onEditClick}
                        />
                    ))}
                </Paper>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    component={Link}
                    to={`${pathname}/add`}
                >
                    Add branch
                </Button>
            </DialogActions>
        </>
    );
}
