export enum UserType {
    INTERNAL = 'INTERNAL',
    CLIENT = 'CLIENT',
    CUSTOMER = 'CUSTOMER'
}

export const userTypeDisplay = {
    [UserType.INTERNAL]: 'Internal',
    [UserType.CLIENT]: 'Client',
    [UserType.CUSTOMER]: 'Customer'
};
