import api, {
    ClientInvestor,
    EligibilityExclusions, Investor, InvestorStatus, ManagedEligibilityStatus
} from '@api';
import { Button, Paper, Typography } from '@mui/material';
import { RoutedDialogManager } from '@tsp-ui/core/components';
import {
    replaceItemById, tryGetItemById, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import EditableSectionCard from '@views/admin/components/EditableSectionCard';
import { InvestorEligibilityContext } from '@views/admin/investor-eligibility/InvestorEligibilityRoutes';
import {
    Dispatch, SetStateAction, createContext, useCallback, useContext, useState
} from 'react';
import { useLocation } from 'react-router-dom';

import { LoanProgramsContext } from '../InternalInvestorDetailRoutes';
import { InvestorManagementPageContext } from '../InternalInvestorManagementPage';

import styles from './InternalInvestorDetailPage.module.scss';
import { InvestorDetailPageBase } from './InvestorDetailPageBase';
import { ExclusionsView } from './LoanProgramDetailPage/components/ViewEligibilityGuidelinesDialog';
import { ActiveClients } from './components/ActiveClients';
import EligibilityExclusionsDialog from './components/EligibilityExclusionsDialog';
import { LoanProgramSection } from './components/LoanProgramSection';


const { PENDING: ELIGIBILITY_PENDING, MANAGED } = ManagedEligibilityStatus;
const { ACTIVE, INACTIVE } = InvestorStatus;

export function useTryGetInvestor(): [Investor | ClientInvestor | undefined, (
    investor: Investor | ClientInvestor
) => void] {
    const { investorID } = useParams<AdminRouteParams<'investor'>>();
    const isClientInvestor = useLocation().pathname.includes('investor-eligibility');

    const {
        entities: internalInvestors,
        setEntities: setInternalInvestors
    } = useContext(InvestorManagementPageContext);

    const {
        investors: clientInvestors,
        setInvestors: setClientInvestors
    } = useContext(InvestorEligibilityContext);

    const investors = (isClientInvestor ? clientInvestors : internalInvestors) || [];
    const investor = tryGetItemById(investors, investorID);
    const setInvestors = isClientInvestor ? setClientInvestors : setInternalInvestors;

    function setInvestor(investor: Investor | ClientInvestor) {
        setInvestors(replaceItemById(investors, investor));
    }

    return [ investor, setInvestor ];
}

export function useGetInvestor() {
    return useTryGetInvestor() as [Investor | ClientInvestor, (investor: Investor | ClientInvestor) => void];
}

export const ExclusionsContext = createContext<{
    exclusions?: EligibilityExclusions;
    setExclusions: Dispatch<SetStateAction<EligibilityExclusions | undefined>>;
}>({
    exclusions: undefined,
    setExclusions: () => {}
});

export default function InternalInvestorDetailPage() {
    const loanProgramsLoading = !useContext(LoanProgramsContext).loanPrograms;
    const [ investor, setInvestor ] = useTryGetInvestor();
    const [ exclusions, setExclusions ] = useState<EligibilityExclusions>();
    const [ exclusionsLoading, setExclusionsLoading ] = useState(true);

    const pageMessage = usePageMessage();

    const { managedEligibilityStatus, status, activeClientIDs } = investor || {};

    const premManaged = managedEligibilityStatus === MANAGED
        || managedEligibilityStatus === ELIGIBILITY_PENDING;

    const { investorID } = useParams<{ investorID: string }>();
    useAsyncEffect(useCallback(async () => {
        try {
            if (investorID) {
                setExclusions(await api.investors.getExclusions(investorID));
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching eligibility exclusions', error);
        }

        setExclusionsLoading(false);
    }, [ investorID, pageMessage ]));

    const hasExclusions = exclusions?.exclusions?.length || exclusions?.groups?.length;

    return (
        <ExclusionsContext.Provider
            value={{
                exclusions,
                setExclusions
            }}
        >
            <InvestorDetailPageBase
                firstBreadcrumb="Investors"
                investor={investor}
                setInvestor={setInvestor}
                onUpdateStatus={() => {
                    if (investor) {
                        try {
                            return api.investors.updateStatus(
                                investor.id, status === ACTIVE
                                    ? ACTIVE
                                    : INACTIVE
                            );
                        } catch (error) {
                            pageMessage.handleApiError('An error occurred while updating the investor status', error);
                        }
                    }
                }}
                loading={loanProgramsLoading || exclusionsLoading}
                statusCardChildren={status === ACTIVE && <ActiveClients clientIDs={activeClientIDs} />}
            >
                {!premManaged ? (
                    <Paper
                        variant="outlined"
                        className={styles.premManagedCard}
                    >
                        This investor's eligibility is not managed by Premicorr

                        <Button
                            className={styles.button}
                            onClick={async () => {
                                if (investor) {
                                    try {
                                        setInvestor(await api.investors.updateEligibilityStatus(
                                            investor.id, ELIGIBILITY_PENDING
                                        ));
                                    } catch (error) {
                                        pageMessage.handleApiError('An error occurred while updating investor status', error);
                                    }
                                }
                            }}
                        >
                            Add managed eligibility
                        </Button>
                    </Paper>
                ) : (
                    <>
                        <LoanProgramSection />

                        <div className={styles.exclusions}>
                            <EditableSectionCard
                                header="Eligibility exclusions"
                                editTo="exclusions"
                                readOnly={!hasExclusions}
                            >
                                <ExclusionsView
                                    exclusions={exclusions}
                                    editTo="exclusions/edit"
                                />
                            </EditableSectionCard>

                            <Typography
                                variant="caption"
                                color="textSecondary"
                                align="center"
                                className={styles.caption}
                            >
                                These exclusions apply to all loan programs for this investor. Additional exclusions can be{' '}
                                managed on each Loan Program.
                            </Typography>
                        </div>
                    </>
                )}

                <RoutedDialogManager routes={routes} />
            </InvestorDetailPageBase>
        </ExclusionsContext.Provider>
    );
}

const routes = {
    exclusions: EligibilityExclusionsDialog
};
