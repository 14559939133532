export enum NumericFieldOperator {
    BETWEEN = 'BETWEEN',
    GREATER_THAN = 'GREATER_THAN',
    GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
    LESS_THAN = 'LESS_THAN',
    LESS_THAN_EQUAL = 'LESS_THAN_EQUAL'
}

export const numericFieldOperatorDisplay = {
    [NumericFieldOperator.BETWEEN]: 'Between',
    [NumericFieldOperator.GREATER_THAN]: '>',
    [NumericFieldOperator.GREATER_THAN_EQUAL]: '≥',
    [NumericFieldOperator.LESS_THAN]: '<',
    [NumericFieldOperator.LESS_THAN_EQUAL]: '≤'
};

export const numericFieldOperatorTooltips = {
    [NumericFieldOperator.BETWEEN]: '',
    [NumericFieldOperator.GREATER_THAN]: 'Greater than',
    [NumericFieldOperator.GREATER_THAN_EQUAL]: 'Greater than or equal to',
    [NumericFieldOperator.LESS_THAN]: 'Less than',
    [NumericFieldOperator.LESS_THAN_EQUAL]: 'Less than or equal to'
};

// TODO consolidate
export const numericFieldOperatorDisplayLong = {
    [NumericFieldOperator.BETWEEN]: 'between',
    [NumericFieldOperator.GREATER_THAN]: 'greater than',
    [NumericFieldOperator.GREATER_THAN_EQUAL]: 'greater than or equal to',
    [NumericFieldOperator.LESS_THAN]: 'less than',
    [NumericFieldOperator.LESS_THAN_EQUAL]: 'less than or equal to'
};
