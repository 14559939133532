import api, {
    PurchaseAdviceConfig, PurchaseAdviceFee, feeTypeDisplay, roundingMethodDisplay
} from '@api';
import { AddCircleOutline } from '@mui/icons-material';
import { IconButton, Link as MuiLink, Typography } from '@mui/material';
import {
    FilledSection,
    FilterTextField, LabelGroup, LabeledValue, RoutedDialogManager
} from '@tsp-ui/core/components';
import { toOrdinal, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import Page from '@views/components/Page';
import {
    Dispatch, SetStateAction, createContext, useCallback, useMemo, useState
} from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import EditableSectionCard from '../components/EditableSectionCard';

import { PurchaseAdviceDialog } from './PurchaseAdviceDialog';
import { PurchaseAdviceFeeCard } from './PurchaseAdviceFeeCard';
import { PurchaseAdviceFeeDialog } from './PurchaseAdviceFeeDialog';
import styles from './PurchaseAdviceManagementPage.module.scss';


export interface PurchaseAdviceContextValue {
    purchaseAdviceConfig?: PurchaseAdviceConfig;
    setPurchaseAdviceConfig: Dispatch<SetStateAction<PurchaseAdviceConfig | undefined>>;
    purchaseAdviceFees: PurchaseAdviceFee[];
    setPurchaseAdviceFees: Dispatch<SetStateAction<PurchaseAdviceFee[]>>;
}

export const PurchaseAdviceContext = createContext<PurchaseAdviceContextValue>({
    purchaseAdviceConfig: undefined,
    setPurchaseAdviceConfig: () => {},
    purchaseAdviceFees: [],
    setPurchaseAdviceFees: () => {}
});

export default function PurchaseAdviceManagementPage() {
    const [ purchaseAdviceConfig, setPurchaseAdviceConfig ] = useState<PurchaseAdviceConfig>();
    const [ purchaseAdviceFees, setPurchaseAdviceFees ] = useState<PurchaseAdviceFee[]>([]);
    const [ loading, setLoading ] = useState(true);

    const pageMessage = usePageMessage();

    const { interestRatePrecision, roundingMethod, cutoffDay } = purchaseAdviceConfig || {};

    const purchaseAdviceContextValue = useMemo(() => ({
        purchaseAdviceConfig,
        setPurchaseAdviceConfig,
        purchaseAdviceFees,
        setPurchaseAdviceFees
    }), [
        purchaseAdviceConfig, setPurchaseAdviceConfig, purchaseAdviceFees, setPurchaseAdviceFees
    ]);

    const [ searchTerm, setSearchTerm ] = useState('');
    const [ debouncedTerm ] = useDebounce(searchTerm, 300);

    const filteredPurchaseAdviceFees = purchaseAdviceFees.filter(({ feeType }) => (
        feeTypeDisplay[feeType].toLocaleLowerCase().includes(debouncedTerm)
    ));

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            const [ purchaseAdviceConfig, purchaseAdviceFees ] = await Promise.all(
                [ api.purchaseAdvice.getPurchaseAdviceConfig(), api.purchaseAdvice.getPurchaseAdviceFees() ]
            );

            setPurchaseAdviceConfig(purchaseAdviceConfig);
            setPurchaseAdviceFees(purchaseAdviceFees);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the purchase advice configuration', error);
        }

        setLoading(false);
    }, [ pageMessage ]));

    return (
        <Page
            header="Purchase advice management"
            headerActions={(
                <FilterTextField
                    autoFocus
                    placeholder="Filter fees"
                    helperText="Filter by fee name"
                    onChange={e => setSearchTerm(e.target.value.toLocaleLowerCase())}
                />
            )}
            loading={loading}
        >
            <div className={styles.root}>
                {purchaseAdviceConfig?.id ? (
                    <EditableSectionCard
                        header="Interest settings"
                        editTo="interest"
                    >
                        <LabelGroup>
                            <LabeledValue
                                label="Precision:"
                                value={(
                                    <>
                                        {interestRatePrecision} decimals&nbsp;

                                        <>(
                                            <Typography
                                                component="span"
                                                fontSize="inherit"
                                                fontWeight={500}
                                            >
                                                {`.${'0'.repeat(interestRatePrecision! - 1)}1`}
                                            </Typography>)
                                        </>
                                    </>
                                )}
                                variants={variants}
                            />

                            <LabeledValue
                                label="Rounding:"
                                value={roundingMethodDisplay[roundingMethod!]}
                                variants={variants}
                            />

                            <LabeledValue
                                label="Cutoff day:"
                                value={`${toOrdinal(cutoffDay!)} of the month`}
                                variants={variants}
                            />
                        </LabelGroup>
                    </EditableSectionCard>
                ) : (
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        align="center"
                        className={styles.caption}
                    >
                        You haven't configured purchase advice yet.&nbsp;

                        <MuiLink
                            component={Link}
                            to="interest/new"
                        >
                            Click here to get started
                        </MuiLink>.
                    </Typography>
                )}

                {purchaseAdviceConfig?.id && purchaseAdviceFees.length ? (
                    <div>
                        <FilledSection
                            className={styles.feesSection}
                            header={(
                                <>
                                    Fees

                                    <IconButton
                                        className={styles.addFeeButton}
                                        component={Link}
                                        to="fees/new"
                                    >
                                        <AddCircleOutline color="secondary" />
                                    </IconButton>
                                </>
                            )}
                        >
                            {filteredPurchaseAdviceFees.map(fee => (
                                <PurchaseAdviceFeeCard
                                    key={fee.id}
                                    fee={fee}
                                />
                            ))}
                        </FilledSection>

                        <Typography
                            variant="caption"
                            color="textSecondary"
                            align="center"
                            className={styles.caption}
                        >
                            These fees are the base fees that apply to all customers.
                            Customer-specific configuration is available in&nbsp;

                            <MuiLink
                                component={Link}
                                to="../customers"
                            >
                                Customer management
                            </MuiLink>.
                        </Typography>
                    </div>
                ) : (
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        align="center"
                        className={styles.caption}
                    >
                        No fees exist yet.&nbsp;

                        <MuiLink
                            component={Link}
                            to="fees/new"
                        >
                            Click here to create a new fee
                        </MuiLink>.
                    </Typography>
                )}

                <PurchaseAdviceContext.Provider value={purchaseAdviceContextValue}>
                    <RoutedDialogManager routes={dialogRoutes} />
                </PurchaseAdviceContext.Provider>
            </div>
        </Page>
    );
}

const dialogRoutes = {
    'interest/:new': PurchaseAdviceDialog,
    interest: PurchaseAdviceDialog,
    'fees/new': PurchaseAdviceFeeDialog,
    'fees/:feeID': PurchaseAdviceFeeDialog
};

const variants = {
    value: 'body1',
    label: 'body2'
} as const;
