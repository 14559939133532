import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import {
    Button, IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { LabelGroup, LabeledValue } from '@tsp-ui/core/components';

import styles from './AssetAccountCard.module.scss';


interface AssetAccountCardProps {
    accountType: string;
}

export default function AssetAccountCard({ accountType }: AssetAccountCardProps) {
    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            <div className={styles.accountInfo}>
                <Typography fontWeight="500">
                    Some institution
                </Typography>

                <Typography variant="body2">
                    2346345634
                </Typography>

                <Typography
                    variant="body2"
                    className={styles.accountType}
                >
                    {accountType}
                </Typography>

                {accountType && (
                    <Typography variant="body2">
                        12/01/2021
                    </Typography>
                )}
            </div>

            <div>
                <div className={styles.iconButtons}>
                    <Tooltip title="Delete">
                        <IconButton size="small">
                            <RemoveCircleOutline color="error" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Edit">
                        <IconButton size="small">
                            <Edit color="secondary" />
                        </IconButton>
                    </Tooltip>
                </div>

                {accountType ? (
                    <LabelGroup>
                        <LabeledValue
                            label="Account balance:"
                            value="$10,000"
                            classNames={{ value: styles.rightAlign }}
                        />

                        <LabeledValue
                            label="Qualifying balance:"
                            value="$10,000"
                            classNames={{ value: styles.rightAlign }}
                        />

                        <LabeledValue
                            label="Months of statements:"
                            value="2"
                        />
                    </LabelGroup>
                ) : (
                    <Button size="small">
                        Enter account info
                    </Button>
                )}
            </div>
        </Paper>
    );
}
