import UnderwritingQuestionCard, { UnderwritingQuestionCardProps } from './UnderwritingQuestionCard';


export default function UnderwritingYesNoQuestionCard(
    props: Omit<UnderwritingQuestionCardProps<boolean | undefined>, 'buttons'>
) {
    return (
        <UnderwritingQuestionCard
            {...props}
            buttons={yesNoButtonConfigs}
        />
    );
}

export const yesNoButtonConfigs = [
    {
        label: 'No',
        value: false
    },
    {
        label: 'Yes',
        value: true
    }
];

