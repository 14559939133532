export enum LoanStatus {
    AWAITING_DOCS = 'AWAITING_DOCS',
    IN_SETUP = 'IN_SETUP',
    UNDERWRITING = 'UNDERWRITING',
    CONDITIONED = 'CONDITIONED',
    CLEAR_TO_CLOSE = 'CLEAR_TO_CLOSE',
    CLOSED = 'CLOSED',
    DENIED_WITHDRAWN = 'DENIED_WITHDRAWN'
}

export const loanStatusDisplay = {
    [LoanStatus.AWAITING_DOCS]: 'Awaiting documents',
    [LoanStatus.IN_SETUP]: 'Loan setup in progress',
    [LoanStatus.UNDERWRITING]: 'Underwriting',
    [LoanStatus.CONDITIONED]: 'Conditioned',
    [LoanStatus.CLEAR_TO_CLOSE]: 'Clear to close',
    [LoanStatus.CLOSED]: 'Closed',
    [LoanStatus.DENIED_WITHDRAWN]: 'Denied/Withdrawn'
};
