import {
    HighLevelGuideline, LoanProperty, Operator, loanPropertyDisplay
} from '@api';
import { DialogContent, Step, StepLabel } from '@mui/material';
import {
    Autocomplete, DateField, RoutedDialogImplProps, RoutedStepperDialog
    , Switch, TextField
} from '@tsp-ui/core/components';
import {
    EditHighLevelGuidelines
} from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/HighLevelGuidelines';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './LLPADialog.module.scss';
import { LLPAMatrix } from './LLPAMatrix/LLPAMatrix';


export interface MatrixStepFormValues {
    highLevelGuidelines: HighLevelGuideline[];
    matrixColumns: LoanProperty[];
}

export default function LLPADialog(props: RoutedDialogImplProps) {
    return (
        <RoutedStepperDialog
            {...props}
            title="Edit LLPA"
            maxWidth={false}
            steps={[
                <Step key="details">
                    <StepLabel>
                        Details
                    </StepLabel>
                </Step>,

                <Step key="matrix">
                    <StepLabel>
                        Matrix
                    </StepLabel>
                </Step>
            ]}
            stepContent={[
                <DetailsStep />,
                <MatrixStep />
            ]}
        />
    );
}

function DetailsStep() {
    const formMethods = useForm({
        defaultValues: {
            name: 'FNMA FICO/LTV 15+ Year',
            description: 'Fannie mae adjustments based on FICO/LTV for terms greater than 15 years',
            effectiveDate: new Date().toISOString(),
            addsToCap: true
        }
    });

    return (
        <DialogContent className={styles.detailsStep}>
            <FormProvider {...formMethods}>
                <TextField
                    name="name"
                    label="LLPA name"
                    required
                />

                <TextField
                    name="description"
                    label="Description"
                    required
                    multiline
                    rows={2}
                />

                <div className={styles.dateFields}>
                    <DateField
                        name="effectiveDate"
                        label="Effective date"
                        required
                    />

                    <DateField
                        name="expirationDate"
                        label="Expiration date"
                    />
                </div>

                <Switch
                    label="Adds to cap"
                    name="addsToCap"
                />
            </FormProvider>
        </DialogContent>
    );
}

function MatrixStep() {
    const formMethods = useForm<MatrixStepFormValues>({
        defaultValues: {
            highLevelGuidelines: [
                {
                    id: '1',
                    loanProperty: LoanProperty.INVESTOR,
                    operator: Operator.EQUALS,
                    value: 'FNMA'
                },
                {
                    id: '2',
                    loanProperty: LoanProperty.TERM,
                    operator: Operator.IS_GREATER_THAN,
                    value: '15 years'
                }
            ],
            matrixColumns: [ LoanProperty.FICO, LoanProperty.LTV ]
        }
    });

    return (
        <DialogContent className={styles.matrixStep}>
            <FormProvider {...formMethods}>
                <EditHighLevelGuidelines name="highLevelGuidelines" />

                <Autocomplete<MatrixStepFormValues>
                    name="matrixColumns"
                    label="Matrix columns"
                    options={Object.values(LoanProperty)}
                    getOptionLabel={(value) => loanPropertyDisplay[value as LoanProperty]}
                    multiple
                    disabled
                    className={styles.columnAutocomplete}
                />

                <LLPAMatrix />
            </FormProvider>
        </DialogContent>
    );
}
