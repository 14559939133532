import { Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import { AssetsUnderwritingTabs } from '../../AssetsUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard  from '../UnderwritingYesNoQuestionCard';

import styles from './NetEquitySection.module.scss';
// TODO

interface EarnestMoneySectionProps {
    setTab: (tab: AssetsUnderwritingTabs) => void;
    onTabComplete: (tab: AssetsUnderwritingTabs, nextTab: AssetsUnderwritingTabs) => void;
}

export default function EarnestMoneySection({ onTabComplete }: EarnestMoneySectionProps) {
    const [ hasEarnestMoneyDeposit, setHasEarnestMoneyDeposit ] = useState<boolean>();
    const [ showClearedQuestion, setShowClearedQuestion ] = useState<boolean>();
    const [ hasCleared, setHasCleared ] = useState<boolean>();

    useEffect(() => {
        if (hasEarnestMoneyDeposit === false || hasCleared !== undefined) {
            onTabComplete('earnest-money', 'net-equity');
        }
    }, [
        hasEarnestMoneyDeposit, hasCleared, onTabComplete
    ]);

    return (
        <UnderwritingStepSection title="Earnest money">
            <UnderwritingYesNoQuestionCard
                question="Is an earnest money deposit required in the sales contract?"
                onAnswered={setHasEarnestMoneyDeposit}
                answer={hasEarnestMoneyDeposit}
            >
                <TextField
                    label="How much earnest money is required?"
                    className={styles.textField}
                />

                {!showClearedQuestion && (
                    <Button
                        variant="contained"
                        className={styles.nextButton}
                        onClick={() => setShowClearedQuestion(true)}
                    >
                        Next
                    </Button>
                )}
            </UnderwritingYesNoQuestionCard>

            {showClearedQuestion && (
                <UnderwritingYesNoQuestionCard
                    question="Has the deposit cleared the borrower's bank account?"
                    onAnswered={setHasCleared}
                    answer={hasCleared}
                />
            )}
        </UnderwritingStepSection>
    );
}
