import { Button, DialogContent } from '@mui/material';
import { DialogActions, RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import { capitalize } from '@tsp-ui/core/utils';
import { useLockAvailabilityContextValue } from '@utils/hooks';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import LockAvailabilityButton from './LockAvailabilityButton';
import { LockAvailabilityContent } from './LockAvailabilityContent';
import LockAvailabilityForm from './LockAvailabilityForm';
import LockAvailabilityHolidayForm from './LockAvailabilityHolidayForm';
import { LockAvailabilityContext } from './LockAvailabilityPage';


enum DialogType {
    ADD = 'ADD',
    EDIT = 'EDIT',
    EDIT_HOLIDAY = 'HOLIDAY_EDIT',
    LIST = 'LIST'
}

const {
    ADD, EDIT, EDIT_HOLIDAY, LIST
} = DialogType;

export function LockAvailabilityDialog(props: Omit<RoutedDialogProps, 'title' | 'children' | 'onSubmit'>) {
    const [ saveLoading, setSaveLoading ] = useState(false);

    const navigate = useNavigate();
    const { day, id, '*': endOfUrl } = useParams();
    const location = useLocation();

    const dialogType = endOfUrl === 'add' ? ADD : day ? EDIT : id ? EDIT_HOLIDAY : LIST;

    const dialogTitles = {
        [ADD]: 'Add holiday',
        [EDIT]: `Edit lock availability - ${capitalize(day || '')}`,
        [EDIT_HOLIDAY]: 'Edit holiday',
        [LIST]: 'Lock availability'
    };

    const [ lockAvailabilityContextValue, loading ] = useLockAvailabilityContextValue();

    return (
        <LockAvailabilityContext.Provider value={lockAvailabilityContextValue}>
            <RoutedDialog
                title={dialogTitles[dialogType]}
                loading={loading}
                maxWidth={false}
                {...props}
            >
                <DialogContent>
                    {dialogType === LIST && <LockAvailabilityContent />}

                    {dialogType === EDIT && <LockAvailabilityForm setLoading={setSaveLoading} /> }

                    {(dialogType === EDIT_HOLIDAY || dialogType === ADD) && (
                        <LockAvailabilityHolidayForm setLoading={setSaveLoading} />
                    )}
                </DialogContent>

                <DialogActions loading={saveLoading}>
                    {dialogType === LIST ? <LockAvailabilityButton /> : (
                        <>
                            <Button onClick={() => navigate(location.pathname.replace(/lock-availability.*/, 'lock-availability'))}>
                                Cancel
                            </Button>

                            <Button
                                type="submit"
                                form={
                                    dialogType === EDIT
                                        ? LockAvailabilityForm.formID
                                        : LockAvailabilityHolidayForm.formID
                                }
                                variant="contained"
                            >
                                Save
                            </Button>
                        </>
                    )}
                </DialogActions>
            </RoutedDialog>
        </LockAvailabilityContext.Provider>
    );
}
