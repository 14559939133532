import { State, stateDisplay } from '@api';
import { LabelGroup, LabeledValue } from '@tsp-ui/core/components';


interface LicenseDetailsProps {
    licenseNumber: string;
    state: State;
}

export default function LicenseDetails({ licenseNumber, state }: LicenseDetailsProps) {
    return (
        <LabelGroup>
            <LabeledValue
                label="License #:"
                value={licenseNumber}
            />

            <LabeledValue
                label="State:"
                value={stateDisplay[state]}
            />
        </LabelGroup>
    );
}
