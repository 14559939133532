import { useState } from 'react';

import ConditionsSummary from './components/ConditionsSummary';
import UnderwritingPageTemplate from './components/UnderwritingPageTemplate';
import UnderwritingStepCard from './components/UnderwritingStepCard';
import MortgageInsuranceSection from './components/title/MortgageInsuranceSection';
import TitleCommitmentSection from './components/title/TitleCommitmentSection';
import TitleTaxInformationSection from './components/title/TitleTaxInformationSection';
import TitleTrustReviewSection from './components/title/TitleTrustReviewSection';

// TODO use react-router for this
export type TitleUnderwritingTabs = 'summary' | 'commitment' | 'trust-review' | 'taxes' | 'mortgage-insurance';

export default function TitleUnderwritingPage() {
    const [ tab, setTab ] = useState<TitleUnderwritingTabs>('summary');
    const [ finishedTabs, setFinishedTabs ] = useState<TitleUnderwritingTabs[]>([]);

    function tabComplete(tab: TitleUnderwritingTabs, nextTab: TitleUnderwritingTabs) {
        setFinishedTabs(finishedTabs.concat(tab));
        setTab(nextTab);
    }

    return (
        <UnderwritingPageTemplate
            conditionsShown={tab === 'summary'}
            onConditionsClick={() => setTab('summary')}
            relevantDocs={[
                'Title.pdf', 'Tax cert.pdf', 'MI cert.pdf'
            ]}
            stepCards={(
                <>
                    <UnderwritingStepCard
                        active={tab === 'commitment'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('commitment')}
                        label="Title commitment"
                        onClick={() => setTab('commitment')}
                    />

                    <UnderwritingStepCard
                        active={tab === 'trust-review'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('trust-review')}
                        label="Living trust review"
                        onClick={() => setTab('trust-review')}
                    />

                    <UnderwritingStepCard
                        active={tab === 'taxes'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('taxes')}
                        label="Tax certification"
                        onClick={() => setTab('taxes')}
                    />

                    <UnderwritingStepCard
                        active={tab === 'mortgage-insurance'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('mortgage-insurance')}
                        label="Mortgage insurance"
                        onClick={() => setTab('mortgage-insurance')}
                    />
                </>
            )}
        >
            {tab === 'summary' ? (
                <ConditionsSummary header="Title & MI conditions" />
            ) : tab === 'trust-review' ? (
                <TitleTrustReviewSection
                    onTabComplete={tabComplete}
                    setTab={setTab}
                />
            ) : tab === 'commitment' ? (
                <TitleCommitmentSection
                    onTabComplete={tabComplete}
                    setTab={setTab}
                />
            ) : tab === 'taxes' ? (
                <TitleTaxInformationSection
                    onTabComplete={tabComplete}
                    setTab={setTab}
                />
            ) : tab === 'mortgage-insurance' ? (
                <MortgageInsuranceSection
                    onTabComplete={tabComplete}
                    setTab={setTab}
                />
            ) : null}
        </UnderwritingPageTemplate>
    );
}
