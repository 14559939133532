import { Branch } from '@api';
import { Tooltip, TooltipProps } from '@mui/material';

import BranchCard from '../CustomerDetailPage/components/dialogs/components/BranchCard';

import styles from './BranchTooltip.module.scss';


interface BranchTooltipProps {
    mainName: string;
    branches: Branch[];
    children: TooltipProps['children'];
}

export default function BranchTooltip({ mainName, branches, children }: BranchTooltipProps) {
    return (
        <Tooltip
            classes={{ tooltip: styles.branchTooltip }}
            enterDelay={0}
            title={
                branches.map(branch => (
                    <BranchCard
                        key={branch.id}
                        mainName={mainName}
                        branch={branch}
                        readOnly
                    />
                ))
            }
        >
            {children}
        </Tooltip>
    );
}
