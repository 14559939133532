import { useRouteMatch } from '@tsp-ui/core/utils';

/**
 * Custom hook to create an account url based on the current account
 *
 * @return a function to create a url
 */
export function useCreateAccountUrl(pattern?: string) {
    const accountID = useRouteMatch(pattern)?.params.accountID;

    return (urlSuffix: string) => `/accounts/${accountID}/${urlSuffix}`;
}
