export enum OccupancyType {
    OWNER_OCCUPIED = 'OWNER_OCCUPIED',
    INVESTMENT = 'INVESTMENT',
    SECOND_HOME = 'SECOND_HOME'
}

export const occupancyTypeDisplay = {
    [OccupancyType.OWNER_OCCUPIED]: 'Owner Occupied',
    [OccupancyType.INVESTMENT]: 'Investment',
    [OccupancyType.SECOND_HOME]: 'Second Home'
};
