import api, {
    CustomerAction, CustomerHistoryEvent, User, UserType
} from '@api';
import { Search } from '@mui/icons-material';
import { DialogContent, InputAdornment, TextField } from '@mui/material';
import { FilledSection, RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage, useParams } from '@tsp-ui/core/utils';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import { useCallback, useContext, useState } from 'react';

import { CustomerDetailContext } from '../../CustomerDetailPage';

import styles from './HistoryDialog.module.scss';
import HistoryEventCard from './HistoryEventCard';


export default function HistoryDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const { id } = useContext(CustomerDetailContext).customer!;
    const [ events, setEvents ] = useState<CustomerHistoryEvent[]>([]);
    const [ users, setUsers ] = useState<User[]>();
    const [ loading, setLoading ] = useState(true);
    const { customerID } = useParams<AdminRouteParams<'customer'>>();

    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        try {
            setEvents(await api.customer.eventHistory.getHistory(id));
            setUsers(await api.users.getUsers(UserType.CUSTOMER, customerID));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the customer history', error);
        }

        setLoading(false);
    }, [
        id, pageMessage, customerID
    ]));

    return (
        <RoutedDialog
            {...props}
            title="History"
            maxWidth={false}
            loading={loading}
        >
            <DialogContent className={styles.root}>
                <FilledSection
                    header={(
                        <>
                            Events

                            {/* TODO implement pagination and filtering */}

                            <TextField
                                autoFocus
                                variant="standard"
                                placeholder="Filter history events"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </>
                    )}
                    variant="light"
                    className={styles.section}
                >
                    {events.map(event => (
                        <HistoryEventCard
                            key={event.id}
                            event={event}
                            users={event.action === CustomerAction.MANAGERS_UPDATED ? users : undefined}
                        />
                    ))}
                </FilledSection>
            </DialogContent>
        </RoutedDialog>
    );
}
