import { LoanActivity } from '@api';
import { Typography } from '@mui/material';

import { AlertCard } from './AlertCard';
import styles from './RecentLoans.module.scss';


interface RecentLoansProps {
    recentLoans: LoanActivity[];
}

export function RecentLoans({ recentLoans }: RecentLoansProps) {
    return (
        <div>
            <Typography
                fontWeight={500}
                className={styles.sectionHeader}
            >
                Your recent loans
            </Typography>

            <div className={styles.alertSection}>
                {recentLoans.map(({
                    id, loanID, loanNumber, lastActiveAt
                }) => (
                    <AlertCard
                        key={id}
                        loanID={loanID}
                        loanNumber={loanNumber}
                        date={lastActiveAt}
                    />
                ))}
            </div>
        </div>
    );
}
