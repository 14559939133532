import api, { Contact } from '@api';
import { RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AddEditContactDialogContent } from '../../../customers/CustomerDetailPage/components/dialogs/components/AddEditContactDialogContent';
import { ClientDetailsContext, ClientDetailsContextValue } from '../ClientDetailPage';


export default function EditClientPrimaryContactDialog(props: Omit<RoutedDialogProps, 'title' | 'children' | 'onSubmit'>) {
    const navigate = useNavigate();

    const { client, updateClient } = useContext(ClientDetailsContext) as Required<ClientDetailsContextValue>;

    async function handleEdit(formValues: Contact) {
        updateClient({
            ...client,
            primaryContact: await api.client.contacts.editContact(client.id, formValues)
        });
    }

    return (
        <RoutedDialog
            {...props}
            title="Edit primary contact"
            maxWidth={false}
        >
            <AddEditContactDialogContent
                defaultValues={client.primaryContact}
                onEdit={handleEdit}
                onDone={() => navigate(props.closeTo, { replace: true })}
                {...props}
            />
        </RoutedDialog>
    );
}
