import { Branch } from '@api';
import { Edit } from '@mui/icons-material';
import { IconButton, Paper } from '@mui/material';
import { AddressTypography } from '@tsp-ui/core/components';
import CustomerDetails from '@views/admin/customers/components/CustomerDetails';

import styles from './BranchCard.module.scss';


interface BranchCardProps {
    branch: Branch
    mainName?: string;
    onEditClick?: (branch: Branch) => void;
    readOnly?: boolean;
}

export default function BranchCard({
    branch, mainName, onEditClick = () => {}, readOnly
}: BranchCardProps) {
    return (
        <Paper
            variant="outlined"
            className={styles.branchDetails}
        >
            <CustomerDetails
                variant="body2"
                dba={branch.dba !== mainName ? branch.dba : undefined}
                nmlsID={branch.nmlsId}
            />

            <AddressTypography
                address={branch.address}
                variant="body2"
            />

            {!readOnly && (
                <IconButton
                    className={styles.button}
                    onClick={() => onEditClick(branch)}
                    edge="end"
                >
                    <Edit color="secondary" />
                </IconButton>
            )}
        </Paper>
    );
}
