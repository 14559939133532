import api, { Role, UserType } from '@api';
import { TextField, renderEnumOptions } from '@tsp-ui/core/components';
import { usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AdminAddEditFormProps } from '../../components/AdminAddPageTemplate';

import styles from './RoleForm.module.scss';


export default function RoleForm({ setLoading, onSubmit, entityToEdit: defaultValues }: AdminAddEditFormProps<Role>) {
    const { accountUserType, customerId } = useGetCurrentAccount();

    const navigate = useNavigate();
    const pageMessage = usePageMessage();

    const formMethods = useForm<Role>({
        defaultValues
    });

    const handleSubmit = formMethods.handleSubmit(async formData => {
        setLoading(true);

        try {
            const updatedRole = defaultValues
                ? await api.roles.editRole(accountUserType, formData, customerId)
                : await api.roles.addRole(accountUserType, {
                    ...formData,
                    isCustom: true
                }, customerId);

            onSubmit(updatedRole);

            navigate(`${defaultValues ? '../' : ''}../${updatedRole.id}`);

            pageMessage.success('Role saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the role', error);
        }

        setLoading(false);
    });

    return (
        <form
            noValidate
            id={RoleForm.formID}
            onSubmit={handleSubmit}
            className={styles.root}
        >
            <FormProvider {...formMethods}>
                <TextField<Role>
                    name="name"
                    label="Name"
                    required
                />

                <TextField<Role>
                    name="description"
                    label="Description"
                    required
                />

                <TextField<Role>
                    name="userType"
                    label="Type"
                    select
                    required
                >
                    {renderEnumOptions(userTypeOptions[accountUserType])}
                </TextField>
            </FormProvider>
        </form>
    );
}

RoleForm.formID = 'add-edit-role-form';

const { INTERNAL, CUSTOMER, CLIENT } = UserType;

const userTypeOptions = {
    [INTERNAL]: { [INTERNAL]: 'Internal' },
    [CLIENT]: {
        [CLIENT]: 'Client',
        [CUSTOMER]: 'Customer'
    },
    [CUSTOMER]: { [CUSTOMER]: 'Customer' }
};
