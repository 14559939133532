import api, {
    CustomerDetails, CustomerPendingSteps, User, UserType
} from '@api';
import { Button } from '@mui/material';
import { RoutedDialogManager } from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage, useParams } from '@tsp-ui/core/utils';
import { LockAvailabilityDialog } from '@views/admin/lockAvailability/LockAvailabilityDialog';
import {
    Dispatch, SetStateAction, createContext, useCallback, useMemo, useState
} from 'react';
import { Link } from 'react-router-dom';

import Page from '../../../components/Page';
import { AdminRouteParams } from '../../components/AdminPageTemplate';
import DetailPageViewCard from '../../components/DetailPageViewCard';

import styles from './CustomerDetailPage.module.scss';
import CustomerActionsCard from './components/CustomerActionsCard';
import CustomerDetailsSummaryCard from './components/CustomerDetailsSummaryCard';
import CustomerManagersCard from './components/CustomerManagersCard';
import CustomerPrimaryContactCard from './components/CustomerPrimaryContactCard';
import CustomerProductsCard from './components/CustomerProductsCard';
import BranchDialog from './components/dialogs/BranchDialog';
import CompensationDialog from './components/dialogs/CompensationDialog';
import DueDiligenceDialog from './components/dialogs/DueDiligenceDialog';
import EditCustomerDetailsDialog from './components/dialogs/EditCustomerDetailsDialog';
import EditCustomerPrimaryContactDialog from './components/dialogs/EditCustomerPrimaryContactDialog';
import EligibleProductsDialog from './components/dialogs/EligibleProductsDialog';
import HistoryDialog from './components/dialogs/HistoryDialog';
import LicenseDialog from './components/dialogs/LicenseDialog';
import ManagersDialog from './components/dialogs/ManagersDialog';
import UsersAndContactsDialog from './components/dialogs/UsersAndContactsDialog';
import WireInstructionsDialog from './components/dialogs/WireInstructionsDialog';


export interface CustomerDetailContextValue {
    customer?: CustomerDetails;
    updateCustomer: Dispatch<SetStateAction<CustomerDetails | undefined>>;
    updatePendingSteps: (stepName: CustomerPendingSteps) => Promise<void>;
    users: User[];
}

export const CustomerDetailContext = createContext<CustomerDetailContextValue>({
    customer: undefined,
    updateCustomer: () => {},
    updatePendingSteps: async () => {},
    users: []
});

export default function CustomerDetailPage() {
    const { customerID } = useParams<AdminRouteParams<'customer'>>();
    const [ customer, setCustomer ] = useState<CustomerDetails>();
    const [ users, setUsers ] = useState<User[]>([]);
    const pageMessage = usePageMessage();

    const customerContextValue = useMemo(() => ({
        customer,
        updateCustomer: setCustomer,
        updatePendingSteps: async (stepName: CustomerPendingSteps) => {
            if (customer?.pendingSteps?.some(step => step.name === stepName)) {
                setCustomer(await api.customer.getCustomer(customer.id));
            }
        },
        users
    }), [
        customer, setCustomer, users
    ]);

    useAsyncEffect(useCallback(async () => {
        try {
            setCustomer(await api.customer.getCustomer(customerID));
            setUsers(await api.users.getUsers(UserType.CUSTOMER, customerID));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the customer details', error);
        }
    }, [ customerID, pageMessage ]));

    return (
        <Page
            header="Customer details"
            loading={!customer}
            headerActions={(
                <Button
                    component={Link}
                    to="history"
                >
                    View history
                </Button>
            )}
            breadcrumbs={[
                'Customers',
                customer?.name
            ]}
        >
            {customer && (
                <CustomerDetailContext.Provider value={customerContextValue}>
                    <div className={styles.root}>
                        <div>
                            <CustomerDetailsSummaryCard />

                            <CustomerProductsCard productConfiguration={customer.productConfiguration} />
                        </div>

                        <div>
                            <DetailPageViewCard
                                label="Compensation & fees"
                                to="compensation"
                            />

                            <DetailPageViewCard
                                label="Due-diligence"
                                to="due-diligence"
                            />

                            <DetailPageViewCard
                                label="State licenses"
                                to="licenses"
                            />

                            <DetailPageViewCard
                                label="Users & contacts"
                                to="users"
                            />

                            <DetailPageViewCard
                                label="Wire instructions"
                                to="wire-instructions"
                            />

                            <DetailPageViewCard
                                label="Lock availability"
                                to="lock-availability"
                            />
                        </div>

                        <div>
                            <CustomerActionsCard />

                            <CustomerPrimaryContactCard primaryContact={customer.primaryContact} />

                            <CustomerManagersCard customer={customer} />
                        </div>
                    </div>

                    <RoutedDialogManager routes={dialogRoutes} />
                </CustomerDetailContext.Provider>
            )}
        </Page>
    );
}

const dialogRoutes = {
    'branches/*': BranchDialog,
    edit: EditCustomerDetailsDialog,
    'due-diligence': DueDiligenceDialog,
    licenses: LicenseDialog,
    'lock-availability/:day/edit': LockAvailabilityDialog,
    'lock-availability/:id/edit-holiday': LockAvailabilityDialog,
    'lock-availability/*': LockAvailabilityDialog,
    products: EligibleProductsDialog,
    compensation: CompensationDialog,
    managers: ManagersDialog,
    users: UsersAndContactsDialog,
    'wire-instructions': WireInstructionsDialog,
    'edit-primary-contact': EditCustomerPrimaryContactDialog,
    history: HistoryDialog
};
