import { ProductType } from '@api';
import { Paper, Typography } from '@mui/material';
import { Switch } from '@tsp-ui/core/components';
import { useWatch } from 'react-hook-form';

import { EligibleProductsFormValues } from '../EligibleProductsDialog';

import { LimitFields } from './LimitFields';
import styles from './ProductCard.module.scss';
import { GenerateMaxFunction, UnderwritingCardFieldPath } from './UnderwritingTypeCard';


export type ProductCardFieldPath = `${UnderwritingCardFieldPath}.productTypes.${ProductType}.products.${number}`;

interface ProductCardProps {
    name: string;
    baseFieldPath: ProductCardFieldPath;
    generateProductMax: GenerateMaxFunction;
}

export function ProductCard({ name, baseFieldPath, generateProductMax }: ProductCardProps) {
    const expanded = useWatch<EligibleProductsFormValues>({ name: `${baseFieldPath}.active` });

    return (
        <Paper
            variant="outlined"
            className={styles.productCard}
        >
            <Typography variant="body2">
                <Switch<EligibleProductsFormValues>
                    name={`${baseFieldPath}.active`}
                    label={name}
                    size="small"
                    disableTypography
                />
            </Typography>

            {expanded && (
                <LimitFields
                    label="Product"
                    baseFieldPath={`${baseFieldPath}.limit`}
                    compact
                    generateMax={generateProductMax}
                />
            )}
        </Paper>
    );
}
