import api, {
    AmortizationType,
    Borrower,
    LoanDetail,
    NumUnits,
    amortizationTypeDisplay,
    automatedUwRecommendationDisplay,
    automatedUwSystemDisplay,
    commitmentTypeDisplay,
    documentationTypeDisplay,
    loanPurposeDisplay,
    loanTypeDisplay,
    numUnitsDisplay,
    occupancyTypeDisplay,
    propertyTypeDisplay,
    specialtyProgramDisplay
} from '@api';
import {
    AttachMoney, ExpandMore, HowToReg, LocalAtm, Place, TrendingUp, Verified
} from '@mui/icons-material';
import {
    DialogContent, Divider, IconButton, Link, Tooltip, Typography
} from '@mui/material';
import {
    AddressTypography, Dialog, DialogProps, FilterTextField, IconTypography, LabelGroup, LabeledValue, SubtleLink
} from '@tsp-ui/core/components';
import {
    Either, formatCurrency, getFullName, useAsyncEffect, usePageMessage
} from '@tsp-ui/core/utils';
import { renderBoolean } from '@views/admin/products/components/ProductDetailsCard';
import { Fragment, useCallback, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { LoanStatusButton } from '../LoanDetailPage';

import styles from './LoanDataDialog.module.scss';


type LoanDataDialogProps = Omit<DialogProps, 'title'> & Either<
    { loanDetail: LoanDetail },
    { loanID: string; }
>;

export default function LoanDataDialog({ loanDetail: detailProp, loanID, ...props }: LoanDataDialogProps) {
    const [ showArmDetails, setShowArmDetails ] = useState(false);
    const [ loading, setLoading ] = useState(!detailProp);
    const [ loanDetail, setLoanDetail ] = useState(detailProp);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ debouncedTerm ] = useDebounce(searchTerm, 300);

    const loanDisplay = Object.fromEntries(Object.entries(loanDetail || {}).map(([ key, value ]) => {
        if (value !== false && !value) {
            return [ key, '--' ];
        } else {
            return [ key, value ];
        }
    })) as LoanDetail; // TODO post-demo clean up logic for displaying these

    const pageMessage = usePageMessage();

    const { open } = props;
    useAsyncEffect(useCallback(async () => {
        if (!loanDetail && open) {
            try {
                setLoanDetail(await api.loans.getLoanDetail(loanID!));
                setLoading(false);
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching loan details', error);
            }
        }
    }, [
        loanDetail, pageMessage, open, loanID
    ]));

    // TODO post-demo clean up
    const { id, ...loanData } = loanDetail || {};
    const keysToDisplay = Object.entries(loanData || {}).map(
        ([ key, value ]) => (
            (
                String(value).toLowerCase().includes( // TODO post-demo handle some values are '[Object object]'
                    debouncedTerm.toLowerCase()
                ) || loanFieldLabels[key as keyof typeof loanFieldLabels].toLowerCase().includes(
                    debouncedTerm.toLowerCase()
                )
            ) ? key : undefined)
    ) as (keyof LoanDetail)[];

    /**
     * TODO post-demo idea for improvement: use context and some components to
     * handle some of this for us. Create SearchableFieldGroup and SearchableField
     * components where the group knows the fields that are below it in the tree
     * so we don't need to keep logic like this:
     * `(includeLoanInfo || include([ 'borrowers' ])) && render fields` in sync.
     */
    function include(keys: (keyof LoanDetail)[]) {
        return keys.some(key => keysToDisplay.includes(key));
    }

    const includeLoanInfo = include([
        'loanType', 'loanAmount', 'interestRate', 'amortizationType', 'escrowsFlag', 'interestOnlyFlag', 'loanTerm', 'lockPeriod', 'armMargin', 'armLifeCap', 'armInitialCap', 'armSubsequentCap'
    ]);

    return (
        <Dialog // TODO post-demo switch to RoutedDialog
            {...props}
            title="Loan data"
            maxWidth={false}
            loading={loading}
        >
            {loanDetail && (
                <>
                    <DialogContent className={styles.headerContent}>
                        <Typography>
                            Loan #

                            <Typography
                                component="span"
                                fontWeight={500}
                            >
                                {loanDetail.loanNumber}
                            </Typography>
                        </Typography>

                        <FilterTextField
                            placeholder="Find a loan field"
                            helperText="Search by field label or value"
                            onChange={(event) => setSearchTerm(event.target.value.toLocaleLowerCase())}
                        />

                        <LoanStatusButton
                            status={loanDetail.loanStatus}
                            loanID={loanDetail.id}
                        />
                    </DialogContent>

                    <DialogContent className={styles.content}>
                        {(includeLoanInfo || include([ 'borrowers' ])) && (
                            <div className={styles.column}>
                                {includeLoanInfo && (
                                    <div>
                                        <Typography className={styles.header}>
                                            Loan information
                                        </Typography>

                                        <div className={styles.section}>
                                            <div className={styles.infoHeader}>
                                                {include([ 'loanType' ]) && (
                                                    <Tooltip title={loanFieldLabels['loanType']}>
                                                        <Typography>
                                                            {loanTypeDisplay[loanDetail.loanType]}
                                                        </Typography>
                                                    </Tooltip>
                                                )}

                                                <div className={styles.icons}>
                                                    {include([ 'escrowsFlag' ]) && (
                                                        <Tooltip
                                                            title={`Taxes & insurance ${loanDetail.escrowsFlag ? '' : 'not'} escrowed`}
                                                            enterDelay={0}
                                                        >
                                                            <LocalAtm
                                                                color={loanDetail.escrowsFlag ? 'primary' : 'disabled'}
                                                                fontSize="small"
                                                            />
                                                        </Tooltip>
                                                    )}

                                                    {include([ 'interestOnlyFlag' ]) && (
                                                        <Tooltip
                                                            title={`${loanDetail.interestOnlyFlag ? '' : 'Not'} Interest Only`}
                                                            enterDelay={0}
                                                        >
                                                            <TrendingUp
                                                                color={loanDetail.interestOnlyFlag ? 'primary' : 'disabled'}
                                                                fontSize="small"
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </div>

                                            {include([ 'loanAmount' ]) && (
                                                <Tooltip title="Loan amount">
                                                    <Typography>
                                                        {formatCurrency(loanDetail.loanAmount)}
                                                    </Typography>
                                                </Tooltip>
                                            )}

                                            {include([ 'loanTerm' ]) && (
                                                <Tooltip title="Term">
                                                    <Typography align="right">
                                                        {loanDetail.loanTerm} months
                                                    </Typography>
                                                </Tooltip>
                                            )}

                                            {include([ 'interestRate' ]) && (
                                                <Tooltip title="Interest rate">
                                                    <Typography>
                                                        {loanDetail.interestRate.toFixed(3)}%
                                                    </Typography>
                                                </Tooltip>
                                            )}

                                            {include([ 'lockPeriod' ]) && (
                                                <Tooltip title="Lock period">
                                                    <Typography align="right">
                                                        {loanDetail.lockPeriod} days
                                                    </Typography>
                                                </Tooltip>
                                            )}

                                            {include([ 'amortizationType' ]) && (
                                                <Tooltip title="Amortization type">
                                                    <Typography>
                                                        {amortizationTypeDisplay[loanDetail.amortizationType]}
                                                    </Typography>
                                                </Tooltip>
                                            )}

                                            {loanDetail.amortizationType === AmortizationType.ADJUSTABLE && include([
                                                'armMargin', 'armInitialCap', 'armLifeCap', 'armLifeCap'
                                            ]) && (
                                                <Tooltip title="Show amortization details">
                                                    <IconButton
                                                        size="small"
                                                        className={styles.showArmButton}
                                                        onClick={() => setShowArmDetails(!showArmDetails)}
                                                    >
                                                        <ExpandMore
                                                            color="secondary"
                                                            fontSize="small"
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            )}

                                            {showArmDetails && (
                                                <LabelGroup className={styles.armDetails}>
                                                    {include([ 'armMargin' ]) && (
                                                        <LabeledValue
                                                            label="Margin:"
                                                            value={loanDetail.armMargin}
                                                        />
                                                    )}

                                                    {include([ 'armInitialCap' ]) && (
                                                        <LabeledValue
                                                            label="Initial cap:"
                                                            value="2%"
                                                        />
                                                    )}

                                                    {include([ 'armSubsequentCap' ]) && (
                                                        <LabeledValue
                                                            label="Subsequent cap:"
                                                            value="2%"
                                                        />
                                                    )}

                                                    {include([ 'armLifeCap' ]) && (
                                                        <LabeledValue
                                                            label="Life cap:"
                                                            value="2%"
                                                        />
                                                    )}
                                                </LabelGroup>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {include([ 'borrowers' ]) && (
                                    <div>
                                        <Typography className={styles.header}>
                                            Borrowers
                                        </Typography>

                                        <div className={styles.borrowers}>
                                            {loanDetail.borrowers.map((borrower, index) => (
                                                <Fragment
                                                    // TODO post-demo add id prop to borrower
                                                    key={JSON.stringify(borrower)}
                                                >
                                                    <BorrowerCard borrower={borrower} />

                                                    {index < loanDetail.borrowers.length - 1 && (
                                                        <Divider className={styles.divider} />
                                                    )}
                                                </Fragment>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        <div className={styles.column}>
                            {include([
                                'propertyType', 'purpose', 'units', 'occupancy', 'address', 'appraisedValue', 'salePrice'
                            ]) && (
                                <div>
                                    <Typography className={styles.header}>
                                        Property details
                                    </Typography>

                                    <div className={styles.section}>
                                        {include([ 'propertyType' ]) && (
                                            <Tooltip title="Property type">
                                                <Typography>
                                                    {propertyTypeDisplay[loanDetail.propertyType!]}
                                                </Typography>
                                            </Tooltip>
                                        )}

                                        {include([ 'units' ]) && (
                                            <Tooltip title="Number of units">
                                                <Typography align="right">
                                                    {`${numUnitsDisplay[loanDetail.units]} unit${loanDetail.units !== NumUnits.ONE ? 's' : ''}`}
                                                </Typography>
                                            </Tooltip>
                                        )}

                                        {include([ 'purpose' ]) && (
                                            <Tooltip title="Loan purpose">
                                                <Typography>
                                                    {loanPurposeDisplay[loanDetail.purpose]}
                                                </Typography>
                                            </Tooltip>
                                        )}

                                        {include([ 'occupancy' ]) && (
                                            <Tooltip title="Occupancy type">
                                                <Typography align="right">
                                                    {occupancyTypeDisplay[loanDetail.occupancy]}
                                                </Typography>
                                            </Tooltip>
                                        )}

                                        {include([ 'address' ]) && (
                                            <>
                                                <Divider className={styles.divider} />

                                                <Tooltip title="Property address">
                                                    <div className={styles.propertyAddress}>
                                                        <Place color="primary" />

                                                        <AddressTypography
                                                            address={loanDetail.address}
                                                        />
                                                    </div>
                                                </Tooltip>

                                                <Divider className={styles.divider} />
                                            </>
                                        )}

                                        {include([ 'appraisedValue' ]) && (
                                            <LabelGroup className={styles.justifyStart}>
                                                <LabeledValue
                                                    label="Appraisal:"
                                                    value={formatCurrency(loanDetail.appraisedValue)}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            </LabelGroup>
                                        )}

                                        {include([ 'salePrice' ]) && (
                                            <LabelGroup className={styles.justifyEnd}>
                                                <LabeledValue
                                                    label="Sale:"
                                                    value={formatCurrency(loanDetail.salePrice)}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            </LabelGroup>
                                        )}
                                    </div>
                                </div>
                            )}

                            {include([
                                'productCode', 'specialtyProgram', 'documentationType', 'subordinatedBalance', 'cashOutAmount', 'limitedLiabilityCorp', 'commitmentType', 'commitmentIdentifier', 'comments', 'mortgageInsFlag', 'mortgageInsCompany', 'mortgageInsCoverage', 'underwriteFlag', 'automatedUwSystem', 'automatedUwRecommendation'
                            ]) && (
                                <div>
                                    <Typography className={styles.header}>
                                        Additional data
                                    </Typography>

                                    <div className={styles.section}>
                                        <LabelGroup className={styles.justifyStart}>
                                            {include([ 'customerName' ]) && (
                                                <LabeledValue
                                                    label="Customer name:"
                                                    value={loanDisplay.customerName}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}

                                            {include([ 'customerId' ]) && (
                                                <LabeledValue
                                                    label="Customer ID:"
                                                    value={loanDisplay.customerId}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}

                                            {include([ 'customerLoanNumber' ]) && (
                                                <LabeledValue
                                                    label="Customer loan number:"
                                                    value={loanDisplay.customerLoanNumber}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}

                                            {include([ 'productCode' ]) && ( // TODO post-demo create component that
                                                <LabeledValue               // accepts props & optional value
                                                    label="Product code:"
                                                    value={loanDisplay.productCode}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}

                                            {include([ 'specialtyProgram' ]) && (
                                                <LabeledValue
                                                    label="Specialty program:"
                                                    value={loanDetail.specialtyProgram
                                                        ? specialtyProgramDisplay[loanDetail.specialtyProgram]
                                                        : loanDisplay.specialtyProgram}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}

                                            {include([ 'documentationType' ]) && (
                                                <LabeledValue
                                                    label="Documentation type:"
                                                    value={loanDetail.documentationType
                                                        ? documentationTypeDisplay[loanDetail.documentationType]
                                                        : loanDisplay.documentationType}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}

                                            {include([ 'subordinatedBalance' ]) && (
                                                <LabeledValue
                                                    label="Subordinated balance:"
                                                    value={formatCurrency(
                                                        loanDetail.subordinatedBalance
                                                    ) || loanDisplay.subordinatedBalance}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}

                                            {include([ 'cashOutAmount' ]) && (
                                                <LabeledValue
                                                    label="Cash out amount:"
                                                    value={formatCurrency(
                                                        loanDetail.cashOutAmount
                                                    ) || loanDisplay.cashOutAmount}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}

                                            {include([ 'limitedLiabilityCorp' ]) && (
                                                <LabeledValue
                                                    label="Limited liability corp:"
                                                    value={loanDisplay.limitedLiabilityCorp}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}

                                            {include([ 'commitmentType' ]) && (
                                                <LabeledValue
                                                    label="Commitment type:"
                                                    value={loanDetail.commitmentType
                                                        ? commitmentTypeDisplay[loanDetail.commitmentType]
                                                        : loanDisplay.commitmentType}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}

                                            {include([ 'commitmentIdentifier' ]) && (
                                                <LabeledValue
                                                    label="Commitment identifier:"
                                                    value={loanDisplay.commitmentIdentifier}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}

                                            {include([ 'comments' ]) && (
                                                <LabeledValue
                                                    label="Comments:"
                                                    value={loanDisplay.comments}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}

                                            {include([ 'mortgageInsFlag' ]) && (
                                                <LabeledValue
                                                    label="Mortgage ins flag:"
                                                    value={(
                                                        loanDetail.mortgageInsFlag
                                                        || loanDetail.mortgageInsFlag === false
                                                    ) // TODO post-demo clean up
                                                        ? renderBoolean(loanDetail.mortgageInsFlag)
                                                        : loanDisplay.mortgageInsFlag}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}

                                            {include([ 'mortgageInsCompany' ]) && (
                                                <LabeledValue
                                                    label="Mortgage ins company:"
                                                    value={loanDisplay.mortgageInsCompany}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}

                                            {include([ 'mortgageInsCoverage' ]) && (
                                                <LabeledValue
                                                    label="Mortgage ins coverage:"
                                                    value={formatCurrency(
                                                        loanDetail.mortgageInsCoverage
                                                    ) || loanDisplay.mortgageInsCoverage}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}

                                            {include([ 'underwriteFlag' ]) && (
                                                <LabeledValue
                                                    label="Underwrite flag:"
                                                    value={(
                                                        loanDetail.underwriteFlag
                                                        || loanDetail.underwriteFlag === false
                                                    ) // TODO post-demo clean up
                                                        ? renderBoolean(loanDetail.underwriteFlag)
                                                        : loanDisplay.underwriteFlag}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}

                                            {include([ 'automatedUwSystem' ]) && (
                                                <LabeledValue
                                                    label="AUS:"
                                                    value={loanDetail.automatedUwSystem
                                                        ? automatedUwSystemDisplay[loanDetail.automatedUwSystem]
                                                        : loanDisplay.automatedUwSystem}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}

                                            {include([ 'automatedUwRecommendation' ]) && (
                                                <LabeledValue
                                                    label="AUR:"
                                                    value={loanDetail.automatedUwRecommendation
                                                        ? automatedUwRecommendationDisplay[
                                                            loanDetail.automatedUwRecommendation
                                                        ] : loanDisplay.automatedUwRecommendation}
                                                    variants={{
                                                        label: 'body2',
                                                        value: 'body1'
                                                    }}
                                                />
                                            )}
                                        </LabelGroup>
                                    </div>
                                </div>
                            )}
                        </div>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
}

function BorrowerCard({ borrower }: { borrower: Borrower }) {
    const [ hideSSN, setHideSSN ] = useState(true);

    return (
        <div>
            <div className={styles.borrowerCardHeader}>
                <Link href="#TODO_show_contact_info">
                    {getFullName(borrower)}
                </Link>

                <div className={styles.icons}>
                    <Tooltip
                        title={`${borrower.primaryWageEarner ? 'P' : 'Not the p'}rimary wage earner`}
                        enterDelay={0}
                    >
                        <AttachMoney
                            color={borrower.primaryWageEarner ? 'primary' : 'disabled'}
                            fontSize="small"
                        />
                    </Tooltip>

                    <Tooltip
                        title={`${borrower.firstTimeHomeBuyer ? 'F' : 'Not a f'}irst time home buyer`}
                        enterDelay={0}
                    >
                        <HowToReg
                            color={borrower.firstTimeHomeBuyer ? 'primary' : 'disabled'}
                            fontSize="small"
                        />
                    </Tooltip>
                </div>
            </div>

            <div className={styles.borrowerDetails}>
                <LabelGroup>
                    <LabeledValue
                        label="FICO:"
                        value={(
                            <IconTypography
                                compact
                                iconPosition="after"
                                fontWeight={400}
                                variant="inherit"
                                icon={(
                                    <Verified
                                        color="primary"
                                        fontSize="inherit"
                                    />
                                )}
                            >
                                {borrower.fico}
                            </IconTypography>
                        )}
                    />
                </LabelGroup>

                <LabelGroup>
                    <LabeledValue
                        label="SSN:"
                        value={(
                            <SubtleLink // TODO post-demo replace this with styling
                                to=""
                                onClick={() => setHideSSN(!hideSSN)}
                            >
                                <Tooltip title={hideSSN ? 'Reveal' : 'Hide'}>
                                    <Typography variant="body2">
                                        {!borrower.ssn ? 'N/A' : (hideSSN ? `*-${borrower.ssn?.slice(-4, borrower.ssn.length)}` : borrower.ssn) /* TODO post-demo cleanup logic */}
                                    </Typography>
                                </Tooltip>
                            </SubtleLink>
                        )}
                    />
                </LabelGroup>
            </div>
        </div>
    );
}

// TODO post-demo use these as labels/titles in the component
const loanFieldLabels = {
    loanNumber: 'Loan number',
    assignee: 'Assignee',
    loanStatus: 'Loan status',
    loanType: 'Loan type',
    loanAmount: 'Loan amount',
    interestRate: 'Interest rate',
    amortizationType: 'Amortization type',
    armMargin: 'Arm margin',
    armInitialCap: 'Arm initial cap',
    armSubsequentCap: 'Arm subsequent cap',
    armLifeCap: 'Arm life cap',
    escrowsFlag: 'Escrows flag',
    interestOnlyFlag: 'Interest only flag',
    loanTerm: 'Loan term',
    lockPeriod: 'Lock period',
    borrowers: 'Borrowers',
    propertyType: 'Property type',
    units: 'Number of units',
    occupancy: 'Occupancy type',
    purpose: 'Purpose',
    address: 'Address',
    appraisedValue: 'Appraised value',
    salePrice: 'Sale price',
    customerName: 'Customer name',
    customerId: 'Customer ID',
    customerLoanNumber: 'Customer loan number',
    productCode: 'Product code',
    specialtyProgram: 'Specialty program',
    documentationType: 'Documentation type',
    subordinatedBalance: 'Subordinated balance',
    cashOutAmount: 'Cash out amount',
    limitedLiabilityCorp: 'Limited liability corp',
    commitmentType: 'Commitment type',
    commitmentIdentifier: 'Commitment identifier',
    comments: 'Comments',
    mortgageInsFlag: 'Mortgage ins flag',
    mortgageInsCompany: 'Mortgage ins company',
    mortgageInsCoverage: 'Mortgage ins coverage',
    underwriteFlag: 'Underwrite flag',
    automatedUwSystem: 'Automated uw system',
    automatedUwRecommendation: 'Automated uw recommendation'
} as const;
