import { Account, UserType } from '@api';
import { Paper, Typography } from '@mui/material';
import { getAccounts } from '@redux/auth';
import { useSelector } from '@redux/store';
import { HorizontalLabelGroup, HorizontalLabeledValue } from '@tsp-ui/core';
import clsx from 'clsx';
import { Link, Navigate } from 'react-router-dom';

import Page from '../components/Page';

import styles from './SwitchAccountsPage.module.scss';


export default function SwitchAccountsPage() {
    const accounts = useSelector(getAccounts);

    return !accounts ? null : (
        accounts.length === 1 ? (
            <Navigate to={`/accounts/${accounts[0].id}`} />
        ) : (
            <Page
                header="Select an account"
                variant="centered"
            >
                <div className={styles.root}>
                    {accounts.map((account) => (
                        <AccountCard
                            key={`${account.id} ${account.customerId}`}
                            account={account}
                        />
                    ))}
                </div>
            </Page>
        )
    );
}

interface AccountCardProps {
    account: Account;
}

function AccountCard({ account }: AccountCardProps) {
    return (
        <Paper
            component={Link}
            to={account.customerId
                ? `/accounts/${account.id}/${account.customerId}`
                : `/accounts/${account.id}`}
            className={clsx(styles.card, {
                [styles.customer]: account.accountUserType === UserType.CUSTOMER
            })}
        >
            <div className={styles.logoContainer}>
                <img
                    alt={`${account.name} logo`}
                    src={account.logoUrl}
                />
            </div>

            <div>
                <Typography
                    variant="h6"
                    color="textSecondary"
                >
                    {account.name}
                </Typography>

                {account.accountUserType === UserType.CUSTOMER && (
                    <HorizontalLabelGroup>
                        <HorizontalLabeledValue
                            label="Customer:"
                            value={account.customerName}
                        />
                    </HorizontalLabelGroup>
                )}
            </div>
        </Paper>
    );
}
