import { CheckCircleOutline, InfoOutlined } from '@mui/icons-material';
import { Paper, RadioGroup, Typography } from '@mui/material';
import { IconTypography, Radio } from '@tsp-ui/core/components';
import { useEffect, useState } from 'react';

import { TitleUnderwritingTabs } from '../../TitleUnderwritingPage';
import UnderwritingNextStepSummary from '../UnderwritingNextStepSummary';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';

import styles from './TitleTrustReviewSection.module.scss';


interface TitleTrustReviewSectionProps {
    setTab: (tab: TitleUnderwritingTabs) => void;
    onTabComplete: (tab: TitleUnderwritingTabs, nextTab: TitleUnderwritingTabs) => void;
}

export default function TitleTrustReviewSection({ onTabComplete }: TitleTrustReviewSectionProps) {
    const [ vestingDescription, setVestingDescription ] = useState<string>();
    const [ hasDocument, setHasDocument ] = useState<boolean>();
    const [ hasDocs, setHasDocs ] = useState<boolean>();

    useEffect(() => {
        if (hasDocs) {
            onTabComplete('trust-review', 'taxes');
        }
    }, [ hasDocs, onTabComplete ]);

    const isDeededOut = vestingDescription === 'DEEDED_OUT';

    return (
        <UnderwritingStepSection title="Living trust review">
            <Paper
                variant="outlined"
                className={styles.paper}
            >
                <Typography>
                    Which of the following best describes the titling of this loan regarding a living trust?
                </Typography>

                <RadioGroup
                    onChange={(event) => setVestingDescription(event.target.value)}
                    className={styles.radios}
                >
                    <Radio
                        name="vestingDescription"
                        value="REMAIN"
                        label={(
                            <>
                                Title is currently vested in the trust and <strong>will remain</strong> in the trust
                            </>
                        )}
                    />

                    <Radio
                        name="vestingDescription"
                        value="DEEDED_OUT"
                        label={(
                            <>
                                Title is currently vested in the trust, but

                                {' '}<strong>will be deeded out</strong> of the trust at closing
                            </>
                        )}
                    />

                    <Radio
                        name="vestingDescription"
                        value="WILL_BE"
                        label={(
                            <>
                                Title is <strong>not</strong> currently in a trust but

                                {' '}<strong>will be</strong> vested in a trust at closing
                            </>
                        )}
                    />
                </RadioGroup>
            </Paper>

            {vestingDescription && (
                <>
                    <UnderwritingNextStepSummary
                        onNextClick={!isDeededOut ? undefined : () => (
                            onTabComplete('trust-review', 'taxes')
                        )}
                    >
                        {isDeededOut ? (
                            <IconTypography icon={<CheckCircleOutline color="success" />}>
                                No living trust review required
                            </IconTypography>
                        ) : (
                            <IconTypography icon={<InfoOutlined color="primary" />}>
                                Full living trust review required
                            </IconTypography>
                        )}
                    </UnderwritingNextStepSummary>

                    {!isDeededOut && (
                        <UnderwritingYesNoQuestionCard
                            question="Is the full living trust document on file?"
                            onAnswered={setHasDocument}
                            answer={hasDocument}
                        />
                    )}

                    {hasDocument !== undefined && (
                        <UnderwritingYesNoQuestionCard
                            question="Has the trust approval been obtained from the title company?"
                            onAnswered={setHasDocs}
                            answer={hasDocs}
                        />
                    )}
                </>
            )}
        </UnderwritingStepSection>
    );
}
