import { Link as MuiLink, Paper, Typography } from '@mui/material';
import { formatDistanceToNowStrict } from '@tsp-ui/core/utils';
import { parseISO } from 'date-fns';
import { Link } from 'react-router-dom';

import styles from './AlertCard.module.scss';


interface AlertCardProps {
    loanID: string;
    loanNumber: string;
    date: string;
}

export function AlertCard({ loanID, loanNumber, date }: AlertCardProps) {
    return (
        <Paper
            variant="outlined"
            className={styles.alertCard}
        >
            <Typography>
                Loan #

                <MuiLink
                    component={Link}
                    to={loanID}
                >
                    {loanNumber}
                </MuiLink>
            </Typography>

            <Typography
                variant="body2"
                color="textSecondary"
            >
                {formatDistanceToNowStrict(parseISO(date), { addSuffix: true })}
            </Typography>
        </Paper>
    );
}
