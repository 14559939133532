export enum NotificationType {
    CONDITION_EVENT = 'CONDITION_EVENT',
    CONDITION_EXCEPTION_STATUS = 'CONDITION_EXCEPTION_STATUS',
    LOAN_ROLE_ASSIGNMENT = 'LOAN_ROLE_ASSIGNMENT',
    LOAN_STATUS_DELEGATED = 'LOAN_STATUS_DELEGATED',
    LOAN_STATUS_NON_DELEGATED = 'LOAN_STATUS_NON_DELEGATED'
}

export const notificationTypeDisplay = {
    [NotificationType.CONDITION_EVENT]: 'Condition event',
    [NotificationType.CONDITION_EXCEPTION_STATUS]: 'Condition exception status',
    [NotificationType.LOAN_ROLE_ASSIGNMENT]: 'Loan role assignment',
    [NotificationType.LOAN_STATUS_DELEGATED]: 'Loan status delegated',
    [NotificationType.LOAN_STATUS_NON_DELEGATED]: 'Loan status non delegated'
};
