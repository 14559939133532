import { LoanAlert } from '@api';
import { Settings } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { AlertCard } from './AlertCard';
import styles from './CustomAlerts.module.scss';


interface CustomAlertsProps {
    loanAlerts: LoanAlert[];
}

export function CustomAlerts({ loanAlerts }: CustomAlertsProps) {
    const alertTypes = [ ...new Set(loanAlerts.map(({ alertType }) => alertType)) ];

    return (
        <div>
            <Typography
                fontWeight={500}
                className={styles.sectionHeader}
            >
                Custom alerts

                <Tooltip title="Configure custom alerts">
                    <IconButton className={styles.headerButton}>
                        <Settings color="secondary" />
                    </IconButton>
                </Tooltip>
            </Typography>

            <div className={styles.alertSection}>
                {alertTypes.map((alertType) => (
                    <AlertGroup
                        key={alertType}
                        title={alertType}
                        alerts={loanAlerts.filter(alert => alert.alertType === alertType)}
                    />
                ))}
            </div>
        </div>
    );
}

interface AlertGroupProps {
    title: ReactNode;
    alerts: LoanAlert[];
}

function AlertGroup({ title, alerts }: AlertGroupProps) {
    return (
        <>
            <Typography
                variant="body2"
                color="textSecondary"
                className={styles.alertTitle}
            >
                {title}
            </Typography>

            {alerts.map(alert => (
                <AlertCard
                    key={alert.id}
                    loanID={alert.loanID}
                    loanNumber={alert.loanNumber}
                    date={alert.triggeredAt}
                />
            ))}
        </>
    );
}
