import { UserType } from '@api';
import { SentryRoutes } from '@tsp-ui/core/components';
import { useGetCurrentAccount } from '@utils/hooks';
import { withAuthentication } from '@utils/withAuthentication';
import { Navigate, Route, useLocation } from 'react-router-dom';

import NotFoundPage from '../NotFoundPage';

import BulkCommitmentManagementPageBase from './bulk-commitment/BulkCommitmentManagementPage';
import ClientManagementPageBase from './clients/ClientManagementPage';
import CustomerManagementPageBase from './customers/CustomerManagementPage';
import InvestorEligibilityRoutesBase from './investor-eligibility/InvestorEligibilityRoutes';
import InvestorManagementPageBase from './investors/InternalInvestorManagementPage';
import LLPAManagementPageBase from './llpas/LLPAManagementPage';
import LockAvailabilityPageBase from './lockAvailability/LockAvailabilityPage';
import ProductManagementPageBase from './products/ProductManagementPage';
import PurchaseAdviceManagementPageBase from './purchase-advice/PurchaseAdviceManagementPage';
import ClientReferenceGuideManagementPageBase from './reference-guides/ClientReferenceGuideManagementPage';
import CustomerReferenceGuideManagementPageBase from './reference-guides/CustomerReferenceGuideManagementPage';
import RoleManagementPageBase from './roles/RoleManagementPage';
import UserManagementPageBase from './users/UserManagementPage';


const { INTERNAL, CLIENT, CUSTOMER } = UserType;

const BulkCommitmentManagementPage = withAuthentication(BulkCommitmentManagementPageBase, [ CLIENT ]);
const ClientManagementPage = withAuthentication(ClientManagementPageBase, [ INTERNAL ]);
const CustomerManagementPage = withAuthentication(CustomerManagementPageBase, [ CLIENT ]);
const InvestorManagementPage = withAuthentication(InvestorManagementPageBase, [ INTERNAL ]);
const InvestorEligibilityRoutes = withAuthentication(InvestorEligibilityRoutesBase, [ CLIENT ]);
const ProductManagementPage = withAuthentication(ProductManagementPageBase, [ CLIENT, INTERNAL ]);
const PurchaseAdviceManagementPage = withAuthentication(PurchaseAdviceManagementPageBase, [ CLIENT ]);
const ClientReferenceGuideManagementPage = withAuthentication(ClientReferenceGuideManagementPageBase, [ CLIENT ]);
const CustomerReferenceGuideManagementPage = withAuthentication(CustomerReferenceGuideManagementPageBase, [ CUSTOMER ]);
const LockAvailabilityPage = withAuthentication(LockAvailabilityPageBase, [ CLIENT ]);
const RoleManagementPage = withAuthentication(RoleManagementPageBase);
const UserManagementPage = withAuthentication(UserManagementPageBase);
const LLPAManagementPage = withAuthentication(LLPAManagementPageBase, [ CLIENT ]);

export default function AdminRouteSwitch() {
    const { pathname } = useLocation();
    const { accountUserType } = useGetCurrentAccount();

    return (
        <SentryRoutes>
            <Route
                path="/"
                element={(
                    <Navigate
                        to={{
                            [INTERNAL]: `${pathname}/clients`,
                            [CLIENT]: `${pathname}/customers`,
                            [CUSTOMER]: `${pathname}/users`
                        }[accountUserType]}
                    />
                )}
            />

            <Route
                path="/clients/*"
                element={<ClientManagementPage />}
            />

            <Route
                path="/customers/*"
                element={<CustomerManagementPage />}
            />

            <Route
                path="/bulk-commitments/*"
                element={<BulkCommitmentManagementPage />}
            />

            <Route
                path="/lock-availability/*"
                element={<LockAvailabilityPage />}
            />

            <Route
                path="/investors/*"
                element={<InvestorManagementPage />}
            />

            <Route
                path="/investor-eligibility/*"
                element={<InvestorEligibilityRoutes />}
            />

            <Route
                path="/products/*"
                element={<ProductManagementPage />}
            />

            <Route
                path="/reference-guides/*"
                element={accountUserType === CUSTOMER
                    ? <CustomerReferenceGuideManagementPage />
                    : <ClientReferenceGuideManagementPage />}
            />

            <Route
                path="/purchase-advice/*"
                element={<PurchaseAdviceManagementPage />}
            />

            <Route
                path="/clients/*"
                element={<ClientManagementPage />}
            />

            <Route
                path="/roles/*"
                element={<RoleManagementPage />}
            />

            <Route
                path="/users/*"
                element={<UserManagementPage />}
            />

            <Route
                path="/llpas/*"
                element={<LLPAManagementPage />}
            />

            <Route
                path="*"
                element={<NotFoundPage />}
            />
        </SentryRoutes>
    );
}
