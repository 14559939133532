import {
    Contact, ContactResponsibility, ContactTitle, contactResponsibilityDisplay, contactTitleDisplay
} from '@api';
import { Close } from '@mui/icons-material';
import {
    Button, Chip, DialogContent, Tooltip
} from '@mui/material';
import {
    Autocomplete, DialogActions, PhoneField, RoutedDialogProps, Switch
    , TextField, isEmail
} from '@tsp-ui/core/components';
import { isEnumValue, useConfirm, usePageMessage } from '@tsp-ui/core/utils';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './AddEditContactDialogContent.module.scss';


interface AddEditContactDialogContentProps extends Omit<RoutedDialogProps, 'title' | 'children'> {
    defaultValues?: Contact;
    onAdd?: (formValues: Contact) => Promise<void>;
    onEdit: (formValues: Contact) => Promise<void>;
    onDone: () => void;
}

export function AddEditContactDialogContent({
    defaultValues, onAdd, onEdit, onDone
}: AddEditContactDialogContentProps) {
    const [ loading, setLoading ] = useState(false);

    const formMethods = useForm({ defaultValues });
    const isPrimary = formMethods.watch('isPrimary');

    const pageMessage = usePageMessage();
    const confirm = useConfirm();

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        setLoading(true);

        try {
            if (defaultValues) {
                await onEdit(formValues);
            } else {
                await onAdd?.(formValues);
            }

            setLoading(false); // set loading before component unmounts

            onDone();

            pageMessage.success('Contact saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the contact', error);

            setLoading(false);
        }
    });

    return (
        <>
            <DialogContent>
                <form
                    noValidate
                    id="add-edit-contact-form"
                    onSubmit={handleSubmit}
                >
                    <FormProvider {...formMethods}>
                        <div className={styles.formContent}>
                            <TextField<Contact>
                                name="name"
                                label="Name"
                                required
                            />

                            <PhoneField<Contact>
                                name="phone"
                                label="Phone Number"
                                required
                            />

                            <TextField<Contact>
                                name="email"
                                label="Email"
                                required
                                rules={isEmail}
                            />

                            <Autocomplete<Contact>
                                name="title"
                                label="Title"
                                freeSolo
                                options={Object.keys(ContactTitle)}
                                getOptionLabel={option => (
                                    isEnumValue(option, ContactTitle) ? contactTitleDisplay[option] : option
                                )}
                                required
                            />

                            <Autocomplete<Contact>
                                name="responsibilities"
                                label="Responsibilities"
                                className={styles.fullWidth}
                                multiple
                                options={Object.keys(ContactResponsibility)}
                                getOptionLabel={option => contactResponsibilityDisplay[option as ContactResponsibility]}
                                renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                                    <Chip
                                        variant="outlined"
                                        label={contactResponsibilityDisplay[option as ContactResponsibility]}
                                        {...getTagProps({ index })}
                                        className={styles.chip}
                                        deleteIcon={<Close />}
                                    />
                                ))}
                                required
                            />

                            <div className={styles.fullWidth}>
                                <Tooltip
                                    title={defaultValues?.isPrimary ? 'You must have a primary contact. To change the primary contact, edit the contact you would like to assign as primary.' : ''}
                                >
                                    <span>
                                        <Switch<Contact>
                                            name="isPrimary"
                                            label="Primary contact"
                                            disabled={defaultValues?.isPrimary}
                                            confirm={!isPrimary ? () => confirm('This action will override the current primary contact. Are you sure?') : undefined}
                                        />
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button onClick={onDone}>
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    form="add-edit-contact-form"
                    type="submit"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
}
