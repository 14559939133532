import { Account, AuthUser, UserType } from '@api';
import { createReducer } from '@reduxjs/toolkit';

import logoUrl from '../../images/logo-full.png';

import { authenticateSession, logout } from './';


export interface AuthenticationState {
    initNeeded: boolean;
    user: AuthUser | undefined;
    accounts: Account[] | undefined;
}

const initialState: AuthenticationState = {
    initNeeded: true,
    user: undefined,
    accounts: undefined
};

export const reducer = createReducer(initialState, (builder) => builder
    .addCase(authenticateSession.rejected, (state) => {
        state.initNeeded = false;
    })
    .addCase(authenticateSession.fulfilled, (state, action) => {
        const { user } = action.payload;

        const accounts: Account[] = [
            ...(user.clientAccounts?.map((clientAccount) => ({
                ...clientAccount,
                accountUserType: UserType.CLIENT
            })) || []),
            ...(user.customerAccounts?.map((clientAccount) => ({
                ...clientAccount,
                accountUserType: UserType.CUSTOMER
            })) || [])
        ];

        accounts.sort((accountA, accountB) => (
            accountA.name === accountB.name
                ? accountA.customerName
                    ? accountA.customerName.localeCompare(accountB.customerName || '')
                    : 0
                : accountA.name.localeCompare(accountB.name)
        ));

        return {
            initNeeded: false,
            accounts: [
                ...(user.isInternal ? [
                    {
                        id: 'internal',
                        name: 'Premicorr',
                        logoUrl,
                        accountUserType: UserType.INTERNAL
                    }
                ] : []),
                ...accounts
            ],
            user
        };
    })
    .addCase(logout.fulfilled, (state) => {
        state.user = undefined;
    }));
