import { ClientLoanProgram } from '@api';
import { AutoFixNormal } from '@mui/icons-material';
import {
    Button, Paper, Tooltip, Typography
} from '@mui/material';
import {
    getItemById, isFutureDate, isPastDate, useParams
} from '@tsp-ui/core';
import { useConfirm } from '@tsp-ui/core/components';
import { LoanProgramsContext } from '@views/admin/investors/InternalInvestorDetailRoutes';
import { ProgramEligibilityContext } from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/InternalLoanProgramDetailPage';
import { GuidelineCard } from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/GuidelineCard';
import { getActiveGuidelineSet, guidelineSetIsActive } from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/LoanProgramForm';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { InvestorEligibilityContext } from '../InvestorEligibilityRoutes';

import styles from './ManagedEligibilityGuidelinesCard.module.scss';


export default function ManagedEligibilityGuidelinesCard() {
    const confirm = useConfirm();
    const navigate = useNavigate();

    const { loanProgramID } = useParams<{ loanProgramID: string }>();
    const { loanPrograms } = useContext(LoanProgramsContext);
    const loanProgram = getItemById(loanPrograms || [], loanProgramID) as ClientLoanProgram;
    const baseLoanProgram = getItemById(loanPrograms || [], loanProgram.baseLoanProgramId!);

    const { eligibilityGuidelineSets } = useContext(ProgramEligibilityContext);
    const overlays = eligibilityGuidelineSets?.filter(({ isOverlay }) => isOverlay);
    const { investors } = useContext(InvestorEligibilityContext);

    const activeOverlay = getActiveGuidelineSet(overlays);

    const pendingOverlays = overlays?.filter(
        ({ effectiveDate }) => isFutureDate(effectiveDate)
    );

    const expiredOverlays = overlays?.filter(
        ({ expirationDate }) => isPastDate(expirationDate)
    );

    const [ showExpired, setShowExpired ] = useState(false);

    const isCurrentlyOverlayed = !!activeOverlay;

    return (
        <Paper
            variant="outlined"
            className={clsx(styles.root, {
                [styles.hasOverlay]: isCurrentlyOverlayed
            })}
        >
            <Typography
                variant="h6"
                className={styles.title}
            >
                Eligibility Guidelines

                {isCurrentlyOverlayed ? (
                    <Tooltip
                        title="This loan program's eligibility is an overlay based on eligibility that is managed by
                            Premicorr. You will be notified if Premicorr makes changes to the base eligibility but this
                            loan program's eligibility will not automatically update."
                    >
                        <AutoFixNormal color="action" />
                    </Tooltip>
                ) : (
                    <Tooltip
                        title="This loan program's eligibility is managed by Premicorr. Eligibility
                            will automatically be updated if Premicorr makes changes to the base eligibility."
                    >
                        <AutoFixNormal color="primary" />
                    </Tooltip>
                )}
            </Typography>

            <Typography className={styles.eligibilityBlurb}>
                Eligibility for this program is {isCurrentlyOverlayed ? 'an overlay based on ' : 'is inherited from '}

                <b>{getItemById(
                    investors || [], baseLoanProgram.investorId
                ).name}
                </b>'s

                {' '}

                <b>{baseLoanProgram.name}</b>{' '}

                program managed by Premicorr
            </Typography>

            <div className={styles.overlayContainer}>
                {isCurrentlyOverlayed && (
                    <>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            component="div"
                            className={styles.overlaySectionHeader}
                        >
                            Active Matrix Overlay
                        </Typography>

                        <GuidelineCard guideline={activeOverlay} />
                    </>
                )}

                {!!pendingOverlays?.length && (
                    <>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            component="div"
                            className={styles.overlaySectionHeader}
                        >
                            Pending Matrix Overlay{pendingOverlays?.length === 1 ? '' : 's'}
                        </Typography>

                        <div className={styles.overlayList}>
                            {pendingOverlays.map(pendingGuideline => (
                                <GuidelineCard
                                    key={pendingGuideline.id}
                                    guideline={pendingGuideline}
                                />
                            ))}
                        </div>
                    </>
                )}

                {!!expiredOverlays?.length && (
                    <>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            component="div"
                            className={styles.overlaySectionHeader}
                        >
                            Expired Matrix Overlays
                        </Typography>

                        {showExpired ? (
                            <div className={styles.overlayList}>
                                {expiredOverlays.map(expiredGuideline => (
                                    <GuidelineCard
                                        key={expiredGuideline.id}
                                        guideline={expiredGuideline}
                                    />
                                ))}
                            </div>
                        ) : (
                            <Button
                                size="small"
                                className={styles.button}
                                onClick={() => setShowExpired(!showExpired)}
                            >
                                View expired guidelines
                            </Button>
                        )}
                    </>
                )}
            </div>

            {!isCurrentlyOverlayed && (
                <div className={styles.buttons}>
                    <Button
                        component={Link}
                        to={`guidelines/${eligibilityGuidelineSets?.find((guidelineSet) => !guidelineSet.isOverlay && guidelineSetIsActive(guidelineSet))?.id}`}
                    >
                        View eligibility summary
                    </Button>

                    <Button
                        onClick={async () => {
                            if (await confirm('Creating an overlay for this matrix will prevent updates made'
                                + ' by Premicorr to the base matrix for this loan program from applying automatically.'
                                + ' Are you sure you want to continue?')) {
                                navigate('guidelines/new');
                            }
                        }}
                    >
                        Add matrix overlay
                    </Button>
                </div>
            )}
        </Paper>
    );
}
