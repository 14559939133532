import {
    ActionCardsType,
    Branch,
    Contact,
    CustomerAction,
    CustomerChecklistStep,
    CustomerCompensation,
    CustomerDetails,
    CustomerFee,
    CustomerInfoType,
    CustomerWireInfo,
    EligibleProductsLimitUpdated,
    EligibleProductsSingleProductToggled,
    EligibleProductsToggleDetails,
    License,
    ProductTypeConfig,
    User
} from '@api';
import clsx from 'clsx';

import CustomerDetailsSummaryCard from '../CustomerDetailsSummaryCard';
import CustomerManagersCard from '../CustomerManagersCard';

import ContactDisplay from './ContactDisplay';
import styles from './DynamicCardDisplay.module.scss';
import BranchCard from './components/BranchCard';
import CompensationCard from './components/CompensationCard';
import DueDiligenceCard from './components/DueDiligenceCard';
import EligibleProductTypeChangeCard from './components/EligibleProductTypeChangeCard';
import EligibleProductsEnabledDisabledCard from './components/EligibleProductsEnabledDisabledCard';
import EligibleProductsLimitCard from './components/EligibleProductsLimitCard';
import EligibleProductsProductToggledCard from './components/EligibleProductsProductToggledCard';
import FeeCard from './components/FeeCard';
import LicenseCard from './components/LicenseCard';
import UserCard from './components/UserCard';
import WireInstructionsCard from './components/WireInstructionsCard';


const {
    BRANCH,
    COMPENSATION_DETAILS,
    CONTACT,
    CUSTOMER_DETAILS,
    DUE_DILIGENCE,
    ELIGIBLE_PRODUCTS_LIMIT,
    ELIGIBLE_PRODUCTS_LIMIT_WITH_CONFIG,
    ELIGIBLE_PRODUCTS_ENABLED_DISABLED_WITH_PRODUCTS,
    ELIGIBLE_PRODUCTS_PRODUCT_ENABLED_DISABLED,
    FEE,
    LICENSE,
    MANAGERS,
    USER,
    WIRE_INSTRUCTIONS
} = ActionCardsType;

interface DynamicCardDisplayProps {
    action: CustomerAction;
    item: CustomerInfoType;
    users?: User[];
}

const mockRequiredProp = () => {};

export default function DynamicCardDisplay({ action, item, users }: DynamicCardDisplayProps) {
    const cardType = customerActionCards[action];

    return (
        <div
            className={clsx(styles.diff, {
                [styles.padding]: !(customerActionCards[action] === ActionCardsType.FEE)
            })}
        >
            {cardType === BRANCH ? (
                <BranchCard
                    branch={item as Branch}
                    readOnly
                />
            ) : cardType === COMPENSATION_DETAILS ? (
                <CompensationCard compensation={item as CustomerCompensation} />
            ) : cardType === CONTACT ? (
                <ContactDisplay contact={item as Contact} />
            ) : cardType === CUSTOMER_DETAILS ? (
                <CustomerDetailsSummaryCard
                    customer={item as CustomerDetails}
                    showAdditionalDBAs
                    readOnly
                />
            ) : cardType === ELIGIBLE_PRODUCTS_LIMIT ? (
                <EligibleProductsLimitCard config={item as EligibleProductsLimitUpdated} />
            ) : cardType === ELIGIBLE_PRODUCTS_LIMIT_WITH_CONFIG ? (
                <EligibleProductTypeChangeCard config={item as ProductTypeConfig} />
            ) : cardType === ELIGIBLE_PRODUCTS_ENABLED_DISABLED_WITH_PRODUCTS ? (
                <EligibleProductsEnabledDisabledCard details={item as EligibleProductsToggleDetails} />
            ) : cardType === ELIGIBLE_PRODUCTS_PRODUCT_ENABLED_DISABLED ? (
                <EligibleProductsProductToggledCard product={(item as EligibleProductsSingleProductToggled).product} />
            ) : cardType === DUE_DILIGENCE ? (
                <DueDiligenceCard item={item as CustomerChecklistStep} />
            ) : cardType === FEE ? (
                <FeeCard fee={item as CustomerFee} />
            ) : cardType === LICENSE ? (
                <LicenseCard
                    license={item as License}
                    readOnly
                    onDelete={mockRequiredProp}
                    onEditClick={mockRequiredProp}
                    onRenew={mockRequiredProp}
                />
            ) : cardType === MANAGERS ? (
                <CustomerManagersCard
                    customer={item as CustomerDetails}
                    readOnly
                />
            ) : cardType === USER ? (
                <UserCard
                    user={item as User}
                    readOnly
                    showRoles
                    onDelete={mockRequiredProp}
                    onEditClick={mockRequiredProp}
                />
            ) : cardType === WIRE_INSTRUCTIONS ? (
                <WireInstructionsCard
                    wireInstructions={item as CustomerWireInfo}
                    wireInstructionsArray={[]}
                    readOnly
                    setLoading={mockRequiredProp}
                    onApprove={mockRequiredProp}
                    onToggleActive={mockRequiredProp}
                    setWireInstructionsToEdit={mockRequiredProp}
                />
            ) : null}
        </div>
    );
}

export const customerActionCards = {
    [CustomerAction.WIRE_INSTRUCTIONS_APPROVED]: WIRE_INSTRUCTIONS,
    [CustomerAction.WIRE_INSTRUCTIONS_DEACTIVATED]: WIRE_INSTRUCTIONS,
    [CustomerAction.WIRE_INSTRUCTIONS_ACTIVATED]: WIRE_INSTRUCTIONS,
    [CustomerAction.WIRE_INSTRUCTIONS_UPDATED]: WIRE_INSTRUCTIONS,
    [CustomerAction.CUSTOMER_DETAILS_UPDATED]: CUSTOMER_DETAILS,

    [CustomerAction.COMPENSATION_DETAILS_UPDATED]: COMPENSATION_DETAILS,

    [CustomerAction.FEE_DISABLED]: FEE,
    [CustomerAction.FEE_ENABLED]: FEE,
    [CustomerAction.FEE_ADJUSTED]: FEE,

    [CustomerAction.BRANCH_UPDATED]: BRANCH,
    [CustomerAction.BRANCH_ADDED]: BRANCH,

    [CustomerAction.USER_DELETED]: USER,
    [CustomerAction.USER_UPDATED]: USER,
    [CustomerAction.USER_ADDED]: USER,

    [CustomerAction.CONTACT_DELETED]: CONTACT,
    [CustomerAction.CONTACT_UPDATED]: CONTACT,
    [CustomerAction.CONTACT_ADDED]: CONTACT,

    [CustomerAction.DUE_DILIGENCE_ITEM_UPDATED]: DUE_DILIGENCE,
    [CustomerAction.DUE_DILIGENCE_ITEM_COMPLETED]: DUE_DILIGENCE,
    [CustomerAction.DUE_DILIGENCE_DOCUMENT_DELETED]: DUE_DILIGENCE,
    [CustomerAction.DUE_DILIGENCE_DOCUMENT_UPLOADED]: DUE_DILIGENCE,

    [CustomerAction.LICENSE_RENEWED]: LICENSE,
    [CustomerAction.LICENSE_UPDATED]: LICENSE,
    [CustomerAction.LICENSE_DELETED]: LICENSE,
    [CustomerAction.LICENSE_ADDED]: LICENSE,

    [CustomerAction.ELIGIBLE_PRODUCTS_ALL_PRODUCTS_UPDATED]: ELIGIBLE_PRODUCTS_LIMIT,
    [CustomerAction.ELIGIBLE_PRODUCTS_UW_CATEGORY_UPDATED]: ELIGIBLE_PRODUCTS_LIMIT,
    [CustomerAction.ELIGIBLE_PRODUCTS_UW_CATEGORY_ENABLED]: ELIGIBLE_PRODUCTS_ENABLED_DISABLED_WITH_PRODUCTS,
    [CustomerAction.ELIGIBLE_PRODUCTS_UW_CATEGORY_DISABLED]: ELIGIBLE_PRODUCTS_ENABLED_DISABLED_WITH_PRODUCTS,
    [CustomerAction.ELIGIBLE_PRODUCTS_TYPE_UPDATED]: ELIGIBLE_PRODUCTS_LIMIT_WITH_CONFIG,
    [CustomerAction.ELIGIBLE_PRODUCTS_TYPE_ENABLED]: ELIGIBLE_PRODUCTS_ENABLED_DISABLED_WITH_PRODUCTS,
    [CustomerAction.ELIGIBLE_PRODUCTS_TYPE_DISABLED]: ELIGIBLE_PRODUCTS_ENABLED_DISABLED_WITH_PRODUCTS,
    [CustomerAction.ELIGIBLE_PRODUCTS_PRODUCT_UPDATED]: ELIGIBLE_PRODUCTS_LIMIT,
    [CustomerAction.ELIGIBLE_PRODUCTS_PRODUCT_ENABLED]: ELIGIBLE_PRODUCTS_PRODUCT_ENABLED_DISABLED,
    [CustomerAction.ELIGIBLE_PRODUCTS_PRODUCT_DISABLED]: ELIGIBLE_PRODUCTS_PRODUCT_ENABLED_DISABLED,

    [CustomerAction.MANAGERS_UPDATED]: MANAGERS
};
