import {
    EligibilityExclusion,
    ExclusionEqualityType,
    LoanProperty,
    NumericExclusionValue,
    NumericFieldOperator,
    exclusionEqualityTypeDisplay,
    loanPropertyDisplay,
    loanPropertyEnumDisplays,
    loanPropertyEnumFieldTypes,
    numericFieldOperatorDisplay
} from '@api';
import { TextField, renderEnumOptions } from '@tsp-ui/core/components';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import styles from './ExclusionRuleRow.module.scss';


interface ExclusionRuleRowProps {
    nameBase: string;
}

/**
 * Renders an exclusion rule row
 *
 * @param nameBase - The name base of the ExclusionRule that this row represents
 * @constructor
 */
export default function ExclusionRuleRow({ nameBase }: ExclusionRuleRowProps) {
    const { setValue } = useFormContext();
    const { loanProperty, value } = useWatch({ name: nameBase }) as EligibilityExclusion || {};

    const fieldType = loanProperty && loanPropertyEnumFieldTypes[loanProperty];
    const enumDisplay = !loanProperty ? null : loanPropertyEnumDisplays[loanProperty];

    let operator: NumericFieldOperator | null = null;
    if (isNumericExclusion(value)) {
        ({ operator } = value);
    }

    return (
        <div className={styles.root}>
            <TextField
                name={`${nameBase}.loanProperty`}
                variant="standard"
                size="small"
                select
                required
                SelectProps={{
                    placeholder: 'Loan property',
                    classes: {
                        nativeInput: styles.placeholderSelect
                    }
                }}
                onChange={(event) => {
                    const fieldType = loanPropertyEnumFieldTypes[event.target.value as LoanProperty];

                    setValue(`${nameBase}.value`, fieldType === 'numeric'
                        ? { operator: NumericFieldOperator.BETWEEN }
                        : fieldType === 'enum'
                            ? []
                            : '');

                    setValue(`${nameBase}.equalityType`, fieldType === 'enum' || fieldType === 'boolean'
                        ? ExclusionEqualityType.EQUALS
                        : null);
                }}
            >
                {renderEnumOptions(loanPropertyDisplay)};
            </TextField>

            {fieldType === 'enum' || fieldType === 'boolean' ? (
                <TextField
                    name={`${nameBase}.equalityType`}
                    variant="standard"
                    size="small"
                    required
                    select
                >
                    {renderEnumOptions(exclusionEqualityTypeDisplay)}
                </TextField>
            ) : (
                <TextField
                    key="value-operator"
                    name={`${nameBase}.value.operator`}
                    variant="standard"
                    size="small"
                    select
                    required
                    disabled={!loanProperty}
                >
                    {renderEnumOptions(numericFieldOperatorDisplay)}
                </TextField>
            )}

            {fieldType === 'enum' || fieldType === 'boolean' ? (
                <TextField
                    key="select"
                    name={`${nameBase}.value`}
                    variant="standard"
                    size="small"
                    select
                    required
                    SelectProps={{
                        multiple: fieldType === 'enum',
                        placeholder: 'Value',
                        classes: {
                            nativeInput: styles.placeholderSelect
                        }
                    }}
                >
                    {renderEnumOptions(enumDisplay!)}
                </TextField>
            ) : operator === NumericFieldOperator.BETWEEN ? (
                <div className={styles.minMaxFields}>
                    <TextField
                        name={`${nameBase}.value.min`}
                        variant="standard"
                        placeholder="Min"
                        size="small"
                        required
                    />

                    <TextField
                        name={`${nameBase}.value.max`}
                        variant="standard"
                        placeholder="Max"
                        size="small"
                        required
                    />
                </div>
            ) : (
                <TextField
                    name={`${nameBase}.value.${getNumberFieldName(operator)}`}
                    variant="standard"
                    placeholder="Value"
                    size="small"
                    required
                    disabled={!loanProperty}
                />
            )}
        </div>
    );
}

function getNumberFieldName(operator: NumericFieldOperator | null) {
    return operator === NumericFieldOperator.LESS_THAN_EQUAL || operator === NumericFieldOperator.LESS_THAN
        ? 'max'
        : 'min';
}

function isNumericExclusion(value: EligibilityExclusion['value']): value is NumericExclusionValue {
    return !!value && value.hasOwnProperty('operator');
}
