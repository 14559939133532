import api, {
    AmortizationType, Investor, Product, ProductType, productTypeDisplay
} from '@api';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import {
    createContext, useCallback, useMemo, useState
} from 'react';

import AdminPageTemplate, {
    AdminEntityGroup, AdminEntityGroupProps, AdminPageContextValues, defaultAdminPageContextValues
} from '../components/AdminPageTemplate';

import ProductDetailPage from './ProductDetailPage/ProductDetailPage';
import styles from './ProductManagementPage.module.scss';
import ProductCard from './components/ProductCard';
import { ProductDetailsForm } from './components/ProductDetailsForm';


export const ProductManagementPageContext = createContext<AdminPageContextValues<Product>>(
    defaultAdminPageContextValues
);

export const InvestorsContext = createContext<{ investors: Investor[] }>({ investors: [] });

export default function ProductManagementPage() {
    const pageMessage = usePageMessage();
    const { id: clientId } = useGetCurrentAccount();

    const [ investors, setInvestors ] = useState<Investor[]>([]);
    const [ showInactive, setShowInactive ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        try {
            setInvestors(await api.client.investors.getInvestors());
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching investors', error);
        }
    }, [ setInvestors, pageMessage ]));

    const investorsContextValue = useMemo(() => ({ investors }), [ investors ]);
    const fetchProducts = useCallback(() => (
        api.products.getAllProducts(clientId)
    ), [ clientId ]);

    return (
        <InvestorsContext.Provider value={investorsContextValue}>
            <AdminPageTemplate
                Context={ProductManagementPageContext}
                CreateEditForm={ProductDetailsForm}
                entityName="product"
                DetailPage={ProductDetailPage}
                EntityGroupComponent={ProductGroup}
                fetchEntities={fetchProducts}
                sortEntitiesBy="isActive"
                filterByLabel="name or code"
                filterEntity={({ productDescription, productCode, isActive }, filterInputValue) => (
                    (productDescription.toLocaleLowerCase().includes(filterInputValue)
                    || productCode.includes(filterInputValue))
                    && showInactive ? true : isActive
                )}
                otherFilters={(
                    <FormControlLabel
                        className={styles.showInactive}
                        control={<Checkbox onChange={() => setShowInactive(!showInactive)} />}
                        label="Show inactive"
                    />
                )}
                visibleGroups={Object.values(ProductType)}
            />
        </InvestorsContext.Provider>
    );
}

function ProductGroup({
    entities: products,
    group: productType
}: AdminEntityGroupProps<Product, ProductType>) {
    products = products.filter(product => product.productType === productType);

    const fixedProducts = products.filter(product => product.amortizationType === AmortizationType.FIXED);
    const adjustableProducts = products.filter(product => product.amortizationType === AmortizationType.ADJUSTABLE);

    return products.length ? (
        <AdminEntityGroup
            header={productTypeDisplay[productType]}
        >
            {!!fixedProducts.length && (
                <Typography
                    color="textSecondary"
                    className={styles.productSetHeader}
                >
                    Fixed
                </Typography>
            )}

            {fixedProducts.map(product => (
                <ProductCard
                    key={product.id}
                    product={product}
                />
            ))}

            {!!adjustableProducts.length && (
                <Typography
                    color="textSecondary"
                    className={styles.productSetHeader}
                >
                    Adjustable
                </Typography>
            )}

            {adjustableProducts.map(product => (
                <ProductCard
                    key={product.id}
                    product={product}
                />
            ))}
        </AdminEntityGroup>
    ) : null;
}
