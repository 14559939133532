import { EligibilityExclusionsFormValues } from '@views/admin/investors/InvestorDetailPage/components/ExclusionGroupRow';
import { useState } from 'react';
import { UseFieldArrayReturn, useFormContext, useWatch } from 'react-hook-form';

import styles from './ExclusionGroupControls.module.scss';
import AddExclusionRowButton from './components/AddExclusionRowButton';
import DeleteExclusionRowButtons from './components/DeleteExclusionRowButtons';
import ExclusionGroupGraphic from './components/ExclusionGroupGraphic';
import ExclusionGroupOperatorButton from './components/ExclusionGroupOperatorButton';


interface ExclusionGroupControlsProps {
    nameBase?: string;
    isRootGroup: boolean;
    ruleArray: UseFieldArrayReturn;
    groupsArray: UseFieldArrayReturn;
    removeLastRow: () => void;
    rootNameBase?: string;
}

/**
 * Renders the controls for an exclusion group
 *
 * @param nameBase         - The name base for the exclusion group that these controls belong to
 * @param isRootGroup      - Whether this group is the outermost group
 * @param ruleArray        - The return values from useFieldArray for the rules in this group
 * @param exclusionGroupArray - The return values from useFieldArray for the nested groups in this group
 * @param removeLastRow    - A function to execute when last row from this group is removed
 * @param rootNameBase      - The name base of the root group
 * @constructor
 */
export default function ExclusionGroupControls({
    nameBase, isRootGroup, ruleArray, groupsArray, removeLastRow, rootNameBase
}: ExclusionGroupControlsProps) {
    const { getValues, setValue } = useFormContext();
    let group: EligibilityExclusionsFormValues = useWatch({ name: nameBase || '' });

    if (!nameBase) {
        group = getValues() as EligibilityExclusionsFormValues;
    }

    const exclusionsLength = group.exclusions?.length || 0;

    const totalRows = exclusionsLength + (group.groups?.length || 0);

    const hideGraphic = isRootGroup && totalRows <= 1 && exclusionsLength !== 0;

    const [ indexToHide, setIndexToHide ] = useState<number>();

    return (
        <div className={styles.root}>
            {hideGraphic ? null : (
                <>
                    <ExclusionGroupGraphic
                        group={group}
                        indexToHide={indexToHide}
                    />

                    <ExclusionGroupOperatorButton
                        group={group}
                        nameBase={nameBase}
                        isRootGroup={isRootGroup}
                    />

                    <DeleteExclusionRowButtons
                        group={group}
                        onTooltipOpen={(index) => setIndexToHide(index)}
                        onTooltipClose={() => setIndexToHide(undefined)}
                        onClick={(index) => {
                            if (totalRows === 1) {
                                removeLastRow();
                            } else if (index < exclusionsLength) {
                                ruleArray.remove(index);
                            } else {
                                groupsArray.remove(index - exclusionsLength);
                            }

                            setIndexToHide(undefined);
                        }}
                    />
                </>
            )}

            <AddExclusionRowButton
                appendRule={ruleArray.append}
                appendGroup={(group) => {
                    const { operator, exclusions } = nameBase ? getValues(nameBase) : getValues();

                    if (operator === 'and') {
                        setValue(`${nameBase}.operator`, 'or');

                        exclusions.forEach(() => {
                            ruleArray.remove(0);
                        });

                        groupsArray.append([
                            {
                                operator: 'and',
                                exclusions
                            },
                            group
                        ]);
                    } else {
                        groupsArray.append(group);
                    }
                }}
                disableGroupAdd={!isRootGroup}
                rootNameBase={rootNameBase}
            />
        </div>
    );
}
