import api, { EligibilityGuidelineSet } from '@api';
import { RoutedDialog, RoutedDialogImplProps } from '@tsp-ui/core/components';
import {
    booleanToEnum, isFutureDate, isPastDate, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { AdminSubRouteParams } from '@views/admin/components/AdminPageTemplate';
import {
    convertToExclusionFormValues, getEmptyEligibilityExclusions
} from '@views/admin/investors/InvestorDetailPage/components/ExclusionGroupRow';
import clsx from 'clsx';
import {
    Dispatch, SetStateAction, createContext, useCallback, useContext, useState
} from 'react';
import { useLocation } from 'react-router-dom';

import { ProgramEligibilityContext } from '../InternalLoanProgramDetailPage';

import styles from './EditEligibilityGuidelinesDialog.module.scss';
import { LoanProgramForm, guidelineSetIsActive } from './LoanProgramForm';


export const EligibilityGuidelinesContext = createContext<{
    eligibilityGuidelineSet?: EligibilityGuidelineSet;
    setEligibilityGuidelineSet: Dispatch<SetStateAction<EligibilityGuidelineSet | undefined>>;
}>({
    eligibilityGuidelineSet: undefined,
    setEligibilityGuidelineSet: () => {}
});

export default function EditEligibilityGuidelinesDialog({ closeTo }: RoutedDialogImplProps) {
    const [ activeStep, setActiveStep ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const { investorID, loanProgramID, guidelineSetID } = useParams<AdminSubRouteParams<'guidelineSet'>>();
    const pageMessage = usePageMessage();

    const { pathname } = useLocation();
    const { eligibilityGuidelineSets } = useContext(ProgramEligibilityContext);

    const [ eligibilityGuidelineSet, setEligibilityGuidelineSet ] = useState<EligibilityGuidelineSet>();

    const isPending = isFutureDate(eligibilityGuidelineSet?.effectiveDate);
    const isExpired = isPastDate(eligibilityGuidelineSet?.expirationDate);
    const title = `${!guidelineSetID ? 'Create' : isExpired ? 'Clone' : 'Edit'} eligibility guidelines`;

    const baseGuidelineSet = pathname.includes('investor-eligibility') ? eligibilityGuidelineSets?.find(eligibilityGuidelineSet => guidelineSetIsActive(eligibilityGuidelineSet) && !eligibilityGuidelineSet.isOverlay) : undefined;

    useAsyncEffect(useCallback(async () => {
        try {
            setLoading(true);

            if (guidelineSetID || baseGuidelineSet) {
                setEligibilityGuidelineSet(
                    await api.investors.getEligibilityGuidelineSetDetail(
                        investorID, loanProgramID, guidelineSetID || baseGuidelineSet!.id
                    )
                );
            }

            setLoading(false);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching guideline set details', error);
        }
    }, [
        investorID, loanProgramID, guidelineSetID, pageMessage, baseGuidelineSet
    ]));

    const defaultValues = (isPending || isExpired) ? {
        eligibilityGuidelineSet: {
            ...eligibilityGuidelineSet,
            highLevelGuidelines: eligibilityGuidelineSet?.highLevelGuidelines?.map(
                highLevelGuideline => booleanToEnum(highLevelGuideline)
            ),
            guidelines: eligibilityGuidelineSet?.guidelines.map(
                guideline => booleanToEnum(guideline)
            ),
            exclusions: convertToExclusionFormValues(
                eligibilityGuidelineSet?.exclusions
            ) || getEmptyEligibilityExclusions()
        }
    } : undefined;

    return (
        <EligibilityGuidelinesContext.Provider
            value={{
                eligibilityGuidelineSet,
                setEligibilityGuidelineSet
            }}
        >
            <RoutedDialog
                title={title}
                closeTo={closeTo}
                loading={loading}
                fullWidth={activeStep === 1}
                maxWidth={activeStep === 0 ? undefined : false}
                classes={{ paper: clsx({ [styles.root]: activeStep === 1 }) }}
            >
                <LoanProgramForm
                    isEdit
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    defaultValues={defaultValues}
                />
            </RoutedDialog>
        </EligibilityGuidelinesContext.Provider>
    );
}
