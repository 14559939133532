import { Tooltip, Typography } from '@mui/material';
import { UnderwritingMenuListItem } from '@views/loans/underwriting/components/UnderwritingSidebar';

import StatusChecklistItem from '../../../admin/customers/components/StatusChecklistItem';

import styles from './UnderwritingStepCard.module.scss';


interface UnderwritingStepCardProps {
    active: boolean;
    conditionIDs: string[];
    done: boolean;
    label: string;
    onClick: () => void;
}

export default function UnderwritingStepCard({
    active, done, conditionIDs, label, onClick
}: UnderwritingStepCardProps) {
    return (
        <UnderwritingMenuListItem
            className={styles.root}
            selected={active}
            disableExtraWidth
            onClick={(event) => {
                event.preventDefault();
                onClick();
            }}
        >
            <StatusChecklistItem
                label={label}
                done={done}
                selected={active}
            />

            {done && (
                <ConditionsDisplay numConditions={conditionIDs.length} />
            )}
        </UnderwritingMenuListItem>
    );
}

interface ConditionsDisplayProps {
    numConditions: number;
}

export function ConditionsDisplay({ numConditions }: ConditionsDisplayProps) {
    const conditionsDisplay = !numConditions
        ? 'No conditions'
        : numConditions === 1
            ? '1 condition'
            : `${numConditions} conditions`;

    return (
        <Tooltip title={conditionsDisplay}>
            <Typography
                color={numConditions ? 'error' : 'textSecondary'}
                fontWeight={numConditions ? 500 : 400}
            >
                {numConditions}
            </Typography>
        </Tooltip>
    );
}
