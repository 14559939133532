import { Button, Paper, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import styles from './DetailPageViewCard.module.scss';


interface DetailPageViewCardProps {
    label: ReactNode;
    to: LinkProps['to'];
}

export default function DetailPageViewCard({ label, to }: DetailPageViewCardProps) {
    return (
        <Paper
            className={styles.root}
            variant="outlined"
        >
            <Typography variant="h6">
                {label}
            </Typography>

            <Button
                component={Link}
                to={to}
            >
                View
            </Button>
        </Paper>
    );
}
