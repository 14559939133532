import { AgencyApproval } from '@api';
import { Typography } from '@mui/material';


export default function AgenciesTableRow({ values }: { values: AgencyApproval }) {
    return (
        <tr>
            <Typography
                component="td"
                padding="4px"
                variant="body2"
                width="100%"
                noWrap
            >
                {values.agency}
            </Typography>

            <Typography
                component="td"
                padding="4px"
                variant="body2"
                noWrap
            >
                {values.firstApprovalDate}
            </Typography>

            <Typography
                component="td"
                padding="4px"
                variant="body2"
                noWrap
            >
                {values.latestApprovalDate}
            </Typography>
        </tr>
    );
}
