import { User } from '@api';
import { Button, DialogContent } from '@mui/material';
import { DialogActions, RoutedDialogProps } from '@tsp-ui/core/components';
import { UserForm } from '@views/admin/users/UserForm';
import { useState } from 'react';


interface AddEditUserDialogContentProps extends Omit<RoutedDialogProps, 'title' | 'children'> {
    user?: User;
    onAdd: (user: User) => void;
    onEdit: (user: User) => void;
    onDone: () => void;
}

export function AddEditUserDialogContent({
    user, onAdd, onEdit, onDone
}: AddEditUserDialogContentProps) {
    const [ loading, setLoading ] = useState(false);

    return (
        <>
            <DialogContent>
                <UserForm
                    entityToEdit={user}
                    onSubmit={(newUser) => (user ? onEdit(newUser) : onAdd(newUser))}
                    setLoading={setLoading}
                />
            </DialogContent>

            <DialogActions loading={loading}>
                <Button onClick={onDone}>
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    form="add-edit-user-form"
                    type="submit"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
}
