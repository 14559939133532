import { Button, TextField } from '@mui/material';
import { useState } from 'react';

import { TitleUnderwritingTabs } from '../../TitleUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';

import styles from './TitleTaxInformationSection.module.scss';


interface TitleTaxInformationSectionProps {
    setTab: (tab: TitleUnderwritingTabs) => void;
    onTabComplete: (tab: TitleUnderwritingTabs, nextTab: TitleUnderwritingTabs) => void;
}

export default function TitleTaxInformationSection({ onTabComplete }: TitleTaxInformationSectionProps) {
    const [ taxInFile, setTaxInFile ] = useState<boolean>();

    return (
        <UnderwritingStepSection title="Tax certification">
            <UnderwritingYesNoQuestionCard
                question="Is the tax certification on file?"
                onAnswered={setTaxInFile}
                answer={taxInFile}
            >
                <TextField
                    label="Annual property taxes"
                    className={styles.textField}
                />

                <Button
                    variant="contained"
                    className={styles.button}
                    onClick={() => onTabComplete('taxes', 'mortgage-insurance')}
                >
                    Next
                </Button>
            </UnderwritingYesNoQuestionCard>
        </UnderwritingStepSection>
    );
}
