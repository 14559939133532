export enum ContactResponsibility {
    ACCOUNTING = 'ACCOUNTING',
    CAPITAL_MARKETS = 'CAPITAL_MARKETS',
    COMPLIANCE = 'COMPLIANCE',
    FINAL_DOCS = 'FINAL_DOCS',
    INTERNAL_WAREHOUSE = 'INTERNAL_WAREHOUSE',
    MERS_TRANSFERS = 'MERS_TRANSFERS',
    OPERATIONS = 'OPERATIONS',
    PAY_HISTORIES = 'PAY_HISTORIES',
    POST_CLOSING = 'POST_CLOSING',
    PRE_PURCHASE_REVIEW = 'PRE_PURCHASE_REVIEW',
    PROCESSING = 'PROCESSING',
    PRODUCTION = 'PRODUCTION',
    QUALITY_CONTROL = 'QUALITY_CONTROL',
    SECONDARY_MARKET = 'SECONDARY_MARKET',
    SENIOR_MANAGER = 'SENIOR_MANAGER',
    SERVICING = 'SERVICING',
    SHIPPING = 'SHIPPING',
    TRAILING_DOCS = 'TRAILING_DOCS',
    UNDERWRITING = 'UNDERWRITING',
}

export const contactResponsibilityDisplay = {
    [ContactResponsibility.ACCOUNTING]: 'Accounting',
    [ContactResponsibility.CAPITAL_MARKETS]: 'Capital Markets',
    [ContactResponsibility.COMPLIANCE]: 'Compliance',
    [ContactResponsibility.FINAL_DOCS]: 'Final Docs',
    [ContactResponsibility.INTERNAL_WAREHOUSE]: 'Internal Warehouse',
    [ContactResponsibility.MERS_TRANSFERS]: 'MERS Transfers',
    [ContactResponsibility.OPERATIONS]: 'Operations',
    [ContactResponsibility.PAY_HISTORIES]: 'Pay Histories',
    [ContactResponsibility.POST_CLOSING]: 'Post Closing',
    [ContactResponsibility.PRE_PURCHASE_REVIEW]: 'Pre-Purchase Review',
    [ContactResponsibility.PROCESSING]: 'Processing',
    [ContactResponsibility.PRODUCTION]: 'Production',
    [ContactResponsibility.QUALITY_CONTROL]: 'Quality Control',
    [ContactResponsibility.SECONDARY_MARKET]: 'Secondary Market',
    [ContactResponsibility.SENIOR_MANAGER]: 'Senior Manager',
    [ContactResponsibility.SERVICING]: 'Servicing',
    [ContactResponsibility.SHIPPING]: 'Shipping',
    [ContactResponsibility.TRAILING_DOCS]: 'Trailing Docs',
    [ContactResponsibility.UNDERWRITING]: 'Underwriting'
};
