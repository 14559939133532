import api, { CommonLoanProgram, EligibilityGuidelineSetSummary, LoanProgramStatus } from '@api';
import { Button, Link as MuiLink, Typography } from '@mui/material';
import { RoutedDialogManager } from '@tsp-ui/core/components';
import {
    replaceItemById, tryGetItemById,
    useAsyncEffect, usePageMessage, useParams, useRouteState
} from '@tsp-ui/core/utils';
import { useTryGetInvestor } from '@utils/hooks';
import { AdminSubRouteParams } from '@views/admin/components/AdminPageTemplate';
import StatusActionsCard from '@views/admin/components/StatusActionsCard';
import Page from '@views/components/Page';
import {
    Dispatch, SetStateAction, createContext, useCallback, useContext, useState
} from 'react';
import { Link } from 'react-router-dom';

import { LoanProgramsContext } from '../../InternalInvestorDetailRoutes';

import styles from './InternalLoanProgramDetailPage.module.scss';
import EditEligibilityGuidelinesDialog from './components/EditEligibilityGuidelinesDialog';
import { EditLoanProgramDetailsDialog } from './components/EditLoanProgramDetailsDialog';
import EligibilityGuidelinesCard from './components/EligibilityGuidelinesCard';
import LoanProgramDetailsCard from './components/LoanProgramDetailsCard';
import ViewEligibilityGuidelinesDialog from './components/ViewEligibilityGuidelinesDialog';


const { ACTIVE, INACTIVE } = LoanProgramStatus;

export function useTryGetLoanProgram(): [ CommonLoanProgram | undefined, (
    updatedLoanProgram: CommonLoanProgram
) => void, boolean] {
    const { loanProgramID } = useParams<AdminSubRouteParams<'loanProgram'>>();
    const { loanPrograms, setLoanPrograms } = useContext(LoanProgramsContext);

    const loanProgram = tryGetItemById(loanPrograms || [], loanProgramID);

    function setLoanProgram(updatedLoanProgram: CommonLoanProgram) {
        setLoanPrograms(loanPrograms => replaceItemById(loanPrograms || [], updatedLoanProgram));
    }

    const isLoading = !loanPrograms;

    return [
        loanProgram, setLoanProgram, isLoading
    ];
}

export function useGetLoanProgram() {
    return useTryGetLoanProgram() as [ CommonLoanProgram, (updatedLoanProgram: CommonLoanProgram) => void, boolean ];
}

export const ProgramEligibilityContext = createContext<{
    eligibilityGuidelineSets?: EligibilityGuidelineSetSummary[];
    setEligibilityGuidelineSets: Dispatch<SetStateAction<EligibilityGuidelineSetSummary[] | undefined>>;
    // TODO eligibilityExclusions, setEligibilityExclusions
}>({
    eligibilityGuidelineSets: undefined,
    setEligibilityGuidelineSets: () => {}
});

export function InternalLoanProgramDetailPage() {
    const pageMessage = usePageMessage();
    const [ investor ] = useTryGetInvestor();
    const defaultEligibilityGuidelineSets = useRouteState<{
        eligibilityGuidelineSets?: EligibilityGuidelineSetSummary[]
    }>().eligibilityGuidelineSets;
    const { investorID, loanProgramID } = useParams<AdminSubRouteParams<'loanProgram'>>();

    const [ eligibilityGuidelineSets, setEligibilityGuidelineSets ] = useState<EligibilityGuidelineSetSummary[]>();
    const [
        loanProgram, setLoanProgram, isLoading
    ] = useTryGetLoanProgram();

    useAsyncEffect(useCallback(async () => {
        setEligibilityGuidelineSets(
            defaultEligibilityGuidelineSets || await api.investors.getEligibilityGuidelineSets(
                investorID, loanProgramID
            )
        );
    }, [
        investorID, loanProgramID, defaultEligibilityGuidelineSets
    ]));

    return (
        <ProgramEligibilityContext.Provider
            value={{
                eligibilityGuidelineSets,
                setEligibilityGuidelineSets
            }}
        >
            <Page
                header="Loan Program Details"
                headerActions={(
                    <Button>
                        Show history
                    </Button>
                )}
                loading={isLoading}
                breadcrumbs={[
                    'Investors',
                    investor?.name,
                    loanProgram?.name
                ]}
            >
                {loanProgram
                    ? (
                        <>
                            <RoutedDialogManager routes={eligibilityGuidelinesDialogRoutes} />

                            <div className={styles.root}>
                                <LoanProgramDetailsCard />

                                <div>
                                    <StatusActionsCard
                                        actions={(
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={async () => {
                                                    try {
                                                        setLoanProgram(await api.investors.updateLoanProgramStatus(
                                                            loanProgram.investorId,
                                                            loanProgram.id,
                                                            loanProgram.status === ACTIVE ? INACTIVE : ACTIVE
                                                        ));

                                                        pageMessage.success('Loan program status updated');
                                                    } catch (error) {
                                                        pageMessage.handleApiError('An error occurred while updating loan program status', error);
                                                    }
                                                }}
                                            >
                                                {loanProgram.status === ACTIVE
                                                    ? 'Deactivate'
                                                    : 'Activate'}
                                            </Button>
                                        )}
                                        noBorder={false}
                                        status={{
                                            [ACTIVE]: 'Active',
                                            [INACTIVE]: 'Inactive'
                                        }[loanProgram.status]}
                                        variant={loanProgram.status}
                                    />

                                    <EligibilityGuidelinesCard />
                                </div>
                            </div>
                        </>
                    ) : (
                        <Typography>
                            This loan program does not exist.{' '}

                            <MuiLink
                                component={Link}
                                to="../loan-programs/new"
                            >
                                Click here
                            </MuiLink> to add a new one.
                        </Typography>
                    )}
            </Page>
        </ProgramEligibilityContext.Provider>
    );
}

export const eligibilityGuidelinesDialogRoutes = {
    'guidelines/:guidelineSetID/edit': EditEligibilityGuidelinesDialog,
    'guidelines/new': EditEligibilityGuidelinesDialog,
    'guidelines/:guidelineSetID': ViewEligibilityGuidelinesDialog,
    edit: EditLoanProgramDetailsDialog
};
