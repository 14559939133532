import { CustomerDetails as Customer } from '@api';
import { Button, Typography } from '@mui/material';
import { AddressTypography, LabeledValue } from '@tsp-ui/core/components';
import { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';

import EditableSectionCard from '../../../components/EditableSectionCard';
import CustomerDetails from '../../components/CustomerDetails';
import { CustomerDetailContext } from '../CustomerDetailPage';

import styles from './CustomerDetailsSummaryCard.module.scss';


interface CustomerDetailsSummaryCardProps {
    customer?: Customer;
    showAdditionalDBAs?: boolean;
    readOnly?: boolean;
}

export default function CustomerDetailsSummaryCard({
    customer, showAdditionalDBAs, readOnly
}: CustomerDetailsSummaryCardProps) {
    const mainCustomer = useContext(CustomerDetailContext).customer!;
    if (!customer) {
        customer = mainCustomer;
    }

    return (
        <EditableSectionCard
            header={customer.name}
            editTo="edit"
            readOnly={readOnly}
        >
            <div className={styles.content}>
                <CustomerDetails
                    dba={customer.name !== customer?.dbaNames?.[0]
                        ? customer?.dbaNames?.[0]
                        : undefined}
                    nmlsID={customer.nmlsId}
                    taxID={customer.taxID}
                    variant="body1"
                />

                <div>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        component="p"
                    >
                        Address
                    </Typography>

                    <AddressTypography address={customer.address} />
                </div>

            </div>

            {!readOnly && (
                <div className={styles.buttons}>
                    <Button
                        component={Link}
                        to="branches"
                    >
                        View branches ({customer.branches?.length || '0'})
                    </Button>

                    <Button
                        component={Link}
                        to="branches/add"
                    >
                        Add branch
                    </Button>
                </div>
            )}

            {showAdditionalDBAs && (
                <LabeledValue
                    label="Other DBA names:"
                    value={customer.dbaNames?.map((dba, index) => (
                        <Fragment key={dba}>
                            {dba}

                            {index !== (customer?.dbaNames?.length || 0) - 1 && <br />}
                        </Fragment>
                    )) || 'None'}
                    variants={{ value: 'body1' }}
                />
            )}
        </EditableSectionCard>
    );
}
