import { Edit } from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import styles from './EditableSectionCard.module.scss';


interface EditableSectionCardProps {
    children: ReactNode;
    header: ReactNode;
    editTo: LinkProps['to'];
    readOnly?: boolean;
    className?: string;
}

export default function EditableSectionCard({
    children, header, editTo, readOnly, className
}: EditableSectionCardProps) {
    return (
        <Paper
            className={clsx(styles.root, className)}
            variant="outlined"
        >
            <Typography
                variant="h6"
                className={styles.title}
            >
                {header}

                <Tooltip title="Edit">
                    <IconButton
                        component={Link}
                        to={editTo}
                        edge="end"
                        className={clsx({
                            [styles.readOnlyButton]: readOnly
                        })}
                    >
                        <Edit color="secondary" />
                    </IconButton>
                </Tooltip>
            </Typography>

            {children}
        </Paper>
    );
}
