import { State } from '@api';
import {
    Button, Paper, TextField, Typography
} from '@mui/material';
import { AddressTypography } from '@tsp-ui/core/components';
import { useState } from 'react';

import { SalesContractUnderwritingTabs } from '../../SalesContractUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';

import styles from './SalesContractSection.module.scss';


interface SalesContractSectionProps {
    setTab: (tab: SalesContractUnderwritingTabs) => void;
    onTabComplete: (tab: SalesContractUnderwritingTabs, nextTab: SalesContractUnderwritingTabs) => void;
}

export default function SalesContractSection({ onTabComplete }: SalesContractSectionProps) {
    const [ contractInFile, setContractInFile ] = useState<boolean>();
    const [ addressMatches, setAddressMatches ] = useState<boolean>();

    return (
        <UnderwritingStepSection title="Sales contract">
            <UnderwritingYesNoQuestionCard
                question="Is the sales contract on file?"
                onAnswered={setContractInFile}
                answer={contractInFile}
            />

            {contractInFile && (
                <UnderwritingYesNoQuestionCard
                    question={(
                        <>
                            <Typography className={styles.addressTypography}>{/* eslint-disable-next-line max-len */}
                                Does the property address on the sales contract match the following address from the file?
                            </Typography>

                            <AddressTypography
                                align="center"
                                address={{
                                    street: '120 Lake Dr',
                                    city: 'West Greenwich',
                                    state: State.RI,
                                    zip: '02817'
                                }}
                            />
                        </>
                    )}
                    onAnswered={setAddressMatches}
                    answer={addressMatches}
                />
            )}

            {addressMatches !== undefined && ( // TODO make a component for this?
                <Paper
                    variant="outlined"
                    className={styles.paper}
                >
                    <Typography>
                        Enter the following information from the sales contract:
                    </Typography>

                    <div className={styles.textFields}>
                        <TextField label="Purchase price" />

                        <TextField
                            label="Seller concessions"
                            helperText="Maximum concessions: $4,000"
                        />

                        <TextField label="Earnest money deposit" />

                        <TextField label="Contract execution date" />
                    </div>

                    <Button
                        variant="contained"
                        className={styles.button}
                        onClick={() => onTabComplete('contract', 'seller-details')}
                    >
                        Next
                    </Button>
                </Paper>
            )}
        </UnderwritingStepSection>
    );
}
