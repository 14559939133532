import api, { Investor, InvestorStatus, ManagedEligibilityStatus } from '@api';
import { CheckCircleOutline, CorporateFare, PendingActions } from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { SubtleLink } from '@tsp-ui/core/components';
import { useGetInvestor } from '@utils/hooks';
import clsx from 'clsx';

import styles from './InternalInvestorCard.module.scss';


const { PENDING } = ManagedEligibilityStatus;
const { ACTIVE, INACTIVE } = InvestorStatus;

interface InvestorCardProps {
    investor: Investor;
}

export function InternalInvestorCard({
    investor: {
        name, id, status, managedEligibilityStatus, code, legalName, isAgency
    }
}: InvestorCardProps) {
    const [ , setInvestor ] = useGetInvestor();

    return (
        <Paper
            className={clsx(styles.root, {
                [styles.lineThrough]: status === INACTIVE
            })}
            elevation={0}
        >
            <div>
                <SubtleLink to={id}>
                    {name}
                </SubtleLink>

                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={styles.code}
                >
                    ({code})
                </Typography>

                <Tooltip title="Legal name">
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                        {legalName}
                    </Typography>
                </Tooltip>
            </div>

            <div className={styles.rightContainer}>
                {isAgency && (
                    <Tooltip
                        className={styles.icon}
                        title="This investor is an agency"
                        enterDelay={0}
                    >
                        <CorporateFare color="primary" />
                    </Tooltip>
                )}

                {managedEligibilityStatus === PENDING && (
                    <Tooltip
                        className={styles.icon}
                        title="This investor has pending eligibility"
                        enterDelay={0}
                    >
                        <PendingActions color="warning" />
                    </Tooltip>
                )}

                {status === INACTIVE && (
                    <Tooltip title="Activate investor">
                        <IconButton
                            size="small"
                            onClick={async (event) => {
                                event.preventDefault();

                                setInvestor(await api.investors.updateStatus(id, ACTIVE));
                            }}
                        >
                            <CheckCircleOutline color="secondary" />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        </Paper>
    );
}
