import {
    Button, MenuItem, Paper, TextField, Typography
} from '@mui/material';
import { useState } from 'react';

import { TitleUnderwritingTabs } from '../../TitleUnderwritingPage';
import UnderwritingQuestionCard from '../UnderwritingQuestionCard';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';
import { propertyTypeButtons } from '../appraisal/AppraisalPropertySection';

import styles from './MortgageInsuranceSection.module.scss';


interface MortgageInsuranceSectionProps {
    setTab: (tab: TitleUnderwritingTabs) => void;
    onTabComplete: (tab: TitleUnderwritingTabs, nextTab: TitleUnderwritingTabs) => void;
}

export default function MortgageInsuranceSection({ onTabComplete }: MortgageInsuranceSectionProps) {
    const [ hasCert, setHasCert ] = useState<boolean>();
    const [ loanPurpose, setLoanPurpose ] = useState<string>();
    const [ propertyType, setPropertyType ] = useState<string>();

    return (
        <UnderwritingStepSection title="Mortgage insurance">
            <UnderwritingYesNoQuestionCard
                question="Is the mortgage certificate on file?"
                onAnswered={setHasCert}
                answer={hasCert}
            />

            {hasCert && (
                <UnderwritingQuestionCard
                    question="What is the loan purpose listed on the mortgage insurance certificate?"
                    onAnswered={setLoanPurpose}
                    answer={loanPurpose}
                    buttons={loanPurposeButtons}
                />
            )}

            {loanPurpose && (
                <UnderwritingQuestionCard
                    question="What is the property type listed on the mortgage insurance certificate?"
                    onAnswered={setPropertyType}
                    answer={propertyType}
                    buttons={propertyTypeButtons}
                />
            )}

            {propertyType && (
                <Paper
                    variant="outlined"
                    className={styles.paper}
                >
                    <Typography>
                        Enter the following information from the mortgage insurance certificate:
                    </Typography>

                    <div className={styles.textFields}>
                        <TextField
                            label="Loan amount"
                            className={styles.loanAmount}
                        />

                        <TextField label="LTV" />

                        <TextField label="CLTV" />

                        <TextField label="Monthly premium" />

                        <TextField
                            label="Coverage percentage"
                            className={styles.coveragePercentage}
                            select
                        >
                            <MenuItem value="6">6%</MenuItem>

                            <MenuItem value="12">12%</MenuItem>

                            <MenuItem value="18">18%</MenuItem>

                            <MenuItem value="25">25%</MenuItem>

                            <MenuItem value="30">30%</MenuItem>

                            <MenuItem value="35">35%</MenuItem>
                        </TextField>
                    </div>

                    <Button
                        variant="contained"
                        className={styles.button}
                        onClick={() => onTabComplete('mortgage-insurance', 'summary')}
                    >
                        Next
                    </Button>
                </Paper>
            )}
        </UnderwritingStepSection>
    );
}

const loanPurposeButtons = [
    {
        label: 'Purchase',
        value: 'Purchase'
    },
    {
        label: 'Refinance',
        value: 'Refinance'
    },
    {
        label: 'Cash out refinance',
        value: 'Cash out refinance'
    }
];
