import api, { ReferenceGuideSection } from '@api';
import { Typography } from '@mui/material';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import Page from '@views/components/Page';
import { useCallback, useState } from 'react';

import styles from './CustomerReferenceGuideManagementPage.module.scss';
import { CustomerReferenceGuideCard } from './components/CustomerReferenceGuideCard';


interface CustomerReferenceGuideManagementPageProps {
    className?: string;
}

export default function CustomerReferenceGuideManagementPage({ className }: CustomerReferenceGuideManagementPageProps) {
    const [ loading, setLoading ] = useState(true);
    const [ referenceGuideSections, setReferenceGuideSections ] = useState<ReferenceGuideSection[]>([]);
    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        try {
            setReferenceGuideSections(await api.referenceGuides.getReferenceGuideSections());
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the reference guides', error);
        }

        setLoading(false);
    }, [ pageMessage ]));

    return (
        <Page
            header="Reference guides"
            loading={loading}
            className={className}
        >
            {referenceGuideSections.map(({ id, title, files }) => (
                <div key={id}>
                    <Typography
                        variant="h5"
                        component="h1"
                        className={styles.title}
                    >
                        {title}
                    </Typography>

                    <div className={styles.referenceGuides}>
                        {files.map(file => (
                            <CustomerReferenceGuideCard
                                key={file.id}
                                referenceGuideFile={file}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </Page>
    );
}
