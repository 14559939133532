export enum PermissionLevel {
    ALWAYS = 'ALWAYS',
    NEVER = 'NEVER',
    WHEN_ASSIGNED = 'WHEN_ASSIGNED'
}

export const permissionLevelDisplay = {
    [PermissionLevel.ALWAYS]: 'Always',
    [PermissionLevel.NEVER]: 'Never',
    [PermissionLevel.WHEN_ASSIGNED]: 'When assigned'
};
