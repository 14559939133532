import {
    AvailableProduct, ProductType, commitmentTypeDisplay, servicingTypeDisplay
} from '@api';
import { MenuItem, Paper } from '@mui/material';
import { Switch, TextField, renderEnumOptions } from '@tsp-ui/core/components';
import { useConfirm } from '@tsp-ui/core/utils';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { EligibleProductsFormValues, ProductFormValue } from '../EligibleProductsDialog';

import { LimitFields } from './LimitFields';
import { ProductCard } from './ProductCard';
import styles from './ProductTypeCard.module.scss';
import { GenerateMaxFunction, UnderwritingCardFieldPath, createGenerateMax } from './UnderwritingTypeCard';


export type ProductTypeCardFieldPath = `${UnderwritingCardFieldPath}.productTypes.${ProductType}`;

interface ProductTypeCardProps {
    availableLockPeriods: number[];
    availableProducts: AvailableProduct[];
    baseFieldPath: ProductTypeCardFieldPath;
    label: string;
    generateProductTypeMax: GenerateMaxFunction;
}

export function ProductTypeCard({
    availableLockPeriods,
    availableProducts,
    baseFieldPath,
    label,
    generateProductTypeMax
}: ProductTypeCardProps) {
    const lockPeriodOptions = useMemo(() => availableLockPeriods.map(lockPeriod => (
        <MenuItem
            value={lockPeriod}
            key={lockPeriod}
        >
            {lockPeriod}
        </MenuItem>
    ))
    , [ availableLockPeriods ]);

    const confirm = useConfirm();

    const expanded = useWatch<EligibleProductsFormValues>({ name: `${baseFieldPath}.active` });
    const products = useWatch<EligibleProductsFormValues>({ name: `${baseFieldPath}.products` }) as ProductFormValue[];

    const availableProductsFields = useWatch<EligibleProductsFormValues>({ name: `${baseFieldPath}.products` }) as ProductFormValue[];

    const totalOfProductLimits = availableProductsFields?.reduce(
        (previousValue, currentValue) => previousValue + (
            currentValue.active ? parseInt(currentValue.limit?.limitAmount) : 0
        ), 0
    );

    const productTypeLimit = parseInt(useWatch<EligibleProductsFormValues>({ name: `${baseFieldPath}.limit.limitAmount` }) as string) || 0;

    const generateProductMax = useMemo(() => (
        createGenerateMax('product', productTypeLimit, totalOfProductLimits)
    ), [ productTypeLimit, totalOfProductLimits ]);

    return (
        <Paper
            variant="outlined"
            className={styles.productTypeCard}
        >
            <div className={styles.mainRow}>
                <Switch<EligibleProductsFormValues>
                    name={`${baseFieldPath}.active`}
                    label={label}
                    rules={{
                        validate: (active) => (
                            !!active && products?.filter(product => product.active).length === 0
                                ? 'Deselect or select at least 1 product'
                                : undefined)
                    }}
                    confirm={expanded ? () => confirm(`This action will delete all ${label} products. Are you sure?`) : undefined}
                />

                {expanded && (
                    <LimitFields
                        label={label}
                        baseFieldPath={`${baseFieldPath}.limit`}
                        generateMax={generateProductTypeMax}
                        compact
                    />
                )}
            </div>

            {expanded && (
                <div className={styles.expandedRow}>
                    <div className={styles.productTypeControls}>
                        <TextField<EligibleProductsFormValues>
                            name={`${baseFieldPath}.servicingType`}
                            label="Servicing type"
                            size="small"
                            required
                            select
                        >
                            {renderEnumOptions(servicingTypeDisplay)}
                        </TextField>

                        <TextField<EligibleProductsFormValues>
                            name={`${baseFieldPath}.commitmentTypes`}
                            label="Commitment types"
                            size="small"
                            required
                            select
                            SelectProps={{
                                multiple: true
                            }}
                        >
                            {renderEnumOptions(commitmentTypeDisplay)}
                        </TextField>

                        <TextField<EligibleProductsFormValues>
                            name={`${baseFieldPath}.lockPeriods`}
                            label="Lock periods"
                            size="small"
                            required
                            select
                            SelectProps={{
                                multiple: true
                            }}
                        >
                            {lockPeriodOptions}
                        </TextField>
                    </div>

                    {availableProducts.map((product, index) => (
                        <ProductCard
                            key={product.id}
                            name={product.name}
                            baseFieldPath={`${baseFieldPath}.products.${index}`}
                            generateProductMax={generateProductMax}
                        />
                    ))}
                </div>
            )}
        </Paper>
    );
}
