import { Button, Tooltip } from '@mui/material';
import { EligibilityExclusionsFormValues } from '@views/admin/investors/InvestorDetailPage/components/ExclusionGroupRow';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';

import styles from './ExclusionGroupOperatorButton.module.scss';


interface ExclusionGroupOperatorButtonProps {
    nameBase?: string;
    group: EligibilityExclusionsFormValues;
    isRootGroup: boolean;
}

/**
 * Renders the and/or button that changes the group operator for the given group
 *
 * @param nameBase    - The name base of the group
 * @param group       - The group that this button controls
 * @param isRootGroup - Whether this group is the outermost group
 * @constructor
 */
export default function ExclusionGroupOperatorButton({
    nameBase, group, isRootGroup
}: ExclusionGroupOperatorButtonProps) {
    const numRows = (group.exclusions?.length || 0) + (group.groups?.length || 0);
    const isSingleRule = numRows <= 1;
    const disableButton = !isRootGroup || !!group.groups?.length;

    return isRootGroup ? (
        <Controller
            name={`${nameBase}.operator`}
            render={({ field }) => (
                <Tooltip
                    enterDelay={0}
                    title={disableButton
                        ? 'The grouping operator cannot be changed when groups are present'
                        : `Change to ${
                            field.value === 'and'
                                ? 'OR'
                                : 'AND'
                        }`}
                >
                    <span className={styles.wrapper}/* Needed for tooltip on pseudo-disabled button */>
                        <Button
                            className={clsx(styles.button, {
                                [styles.pseudoDisable]: disableButton
                            })}
                            size="small"
                            variant="outlined"
                            color={field.value === 'and'
                                ? 'primary'
                                : 'secondary'}
                            onClick={disableButton === true ? undefined : () => {
                                field.onChange(field.value === 'and' ? 'or' : 'and');
                            }}
                        >
                            {field.value}
                        </Button>
                    </span>
                </Tooltip>
            )}
        />
    ) : (
        <Tooltip
            enterDelay={0}
            title={isSingleRule
                ? 'Grouping operations have no effect on a single rule'
                : 'AND operator is required for groups'}
        >
            <span className={styles.wrapper}/* Needed for tooltip on pseudo-disabled button */>
                <Button
                    className={clsx(styles.button, styles.pseudoDisable)}
                    size="small"
                    variant="outlined"
                    color="primary"
                    disabled={isSingleRule}
                >
                    {isSingleRule
                        ? '—'
                        : 'and'}
                </Button>
            </span>
        </Tooltip>
    );
}
