import { Product } from '@api';
import { Button, DialogContent } from '@mui/material';
import { DialogActions, RoutedDialog, RoutedDialogProps } from '@tsp-ui/core/components';
import { useState } from 'react';

import { useGetProduct } from '../ProductDetailPage/ProductDetailPage';

import { ProductDetailsForm } from './ProductDetailsForm';


export function ProductDetailsDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const [ product, setProduct ] = useGetProduct();
    const [ loading, setLoading ] = useState(false);

    return (
        <RoutedDialog
            {...props}
            title="Product details"
            maxWidth={false}
        >
            <DialogContent>
                <ProductDetailsForm
                    setLoading={setLoading}
                    onSubmit={(newProduct: Product) => setProduct(newProduct)}
                    entityToEdit={product}
                />
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    type="submit"
                    variant="contained"
                    form={ProductDetailsForm.formID}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
