import clsx from 'clsx';

import { LimitDisplay, LimitDisplayProps } from './LimitDisplay';
import styles from './ProductRow.module.scss';


interface ProductRowProps extends LimitDisplayProps {
    className?: string;
}

export function ProductRow({ className, ...props }: ProductRowProps) {
    return (
        <div className={clsx(styles.productRow, className)}>
            <LimitDisplay {...props} />
        </div>
    );
}
