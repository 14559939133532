import { Typography } from '@mui/material';
import { ReactNode } from 'react';

import styles from './UnderwritingStepSection.module.scss';


interface UnderwritingStepSectionProps {
    title: ReactNode;
    children: ReactNode;
    addButton?: ReactNode;
}

export default function UnderwritingStepSection({
    title, children, addButton
}: UnderwritingStepSectionProps) {
    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <div />

                <Typography variant="h6">
                    {title}
                </Typography>

                <div className={styles.addButton}>
                    {addButton}
                </div>
            </div>

            {children}
        </div>
    );
}
