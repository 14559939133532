import { getCurrentUser } from '@redux/auth';
import { useSelector } from '@redux/store';


export function useGetCurrentUser() {
    const user = useTryGetCurrentUser();

    if (!user) {
        throw new Error('No user is currently logged in. If this is expected, use useTryGetCurrentUser instead.');
    }

    return user;
}

export function useTryGetCurrentUser() {
    return useSelector(getCurrentUser);
}
