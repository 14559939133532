import { useState } from 'react';

import ConditionsSummary from './components/ConditionsSummary';
import UnderwritingPageTemplate from './components/UnderwritingPageTemplate';
import UnderwritingStepCard from './components/UnderwritingStepCard';
import AssetAccountsSection from './components/assets/AssetAccountsSection';
import EarnestMoneySection from './components/assets/EarnestMoneySection';
import GiftFundsSection from './components/assets/GiftFundsSection';
import NetEquitySection from './components/assets/NetEquitySection';

// TODO use react-router for this
export type AssetsUnderwritingTabs = 'summary' | 'gifts' | 'earnest-money' | 'net-equity' | 'assets';

export default function AssetsUnderwritingPage() {
    const [ tab, setTab ] = useState<AssetsUnderwritingTabs>('summary');
    const [ finishedTabs, setFinishedTabs ] = useState<AssetsUnderwritingTabs[]>([]);

    function tabComplete(tab: AssetsUnderwritingTabs, nextTab: AssetsUnderwritingTabs) {
        setFinishedTabs(finishedTabs.concat(tab));
        setTab(nextTab);
    }

    return (
        <UnderwritingPageTemplate
            relevantDocs={[ 'Statement 1.pdf', 'Statement 2.pdf' ]}
            conditionsShown={tab === 'summary'}
            onConditionsClick={() => setTab('summary')}
            stepCards={(
                <>
                    <UnderwritingStepCard
                        active={tab === 'gifts'}
                        conditionIDs={[ '' ]}
                        done={finishedTabs.includes('gifts')}
                        label="Gift funds"
                        onClick={() => setTab('gifts')}
                    />

                    <UnderwritingStepCard
                        active={tab === 'earnest-money'}
                        conditionIDs={[ '' ]}
                        done={finishedTabs.includes('earnest-money')}
                        label="Earnest money"
                        onClick={() => setTab('earnest-money')}
                    />

                    <UnderwritingStepCard
                        active={tab === 'net-equity'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('net-equity')}
                        label="Net equity"
                        onClick={() => setTab('net-equity')}
                    />

                    <UnderwritingStepCard
                        active={tab === 'assets'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('assets')}
                        label="Asset accounts"
                        onClick={() => setTab('assets')}
                    />
                </>
            )}
        >
            {tab === 'summary' ? (
                <ConditionsSummary header="Income & Assets conditions" />
            ) : tab === 'gifts' ? (
                <GiftFundsSection
                    onTabComplete={tabComplete}
                    setTab={setTab}
                />
            ) : tab === 'earnest-money' ? (
                <EarnestMoneySection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : tab === 'net-equity' ? (
                <NetEquitySection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : tab === 'assets' ? (
                <AssetAccountsSection
                    setTab={setTab}
                    onTabComplete={tabComplete}
                />
            ) : null}
        </UnderwritingPageTemplate>
    );
}
