import { apiUtils } from '../api-utils';
import { PendingUpload } from '../pricing/pricing-api';


export function getCommitments(clientId: string): Promise<BulkCommitment[]> {
    return apiUtils.get(`/client/${clientId}/bulk-commitment`);
}

export function getCommitment(clientId: string, commitmentId: string): Promise<BulkCommitmentDetails> {
    return apiUtils.get(`/client/${clientId}/bulk-commitment/${commitmentId}`);
}

export function createCommitment(clientId: string, commitment: Omit<BulkCommitment, 'id'>): Promise<BulkCommitment> {
    return apiUtils.post(`/client/${clientId}/bulk-commitment`, commitment);
}

export function updateCommitment(clientId: string, commitment: BulkCommitment): Promise<BulkCommitment>  {
    return apiUtils.put(`/client/${clientId}/bulk-commitment/${commitment.id}`, commitment);
}

export function uploadToCommitment(
    clientId: string, commitmentId: string, formData: FormData
): Promise<PendingUpload[]> {
    return apiUtils.post(`/client/${clientId}/bulk-commitment/${commitmentId}/upload`, formData, { useAutoContentType: true });
}

export interface BulkCommitment {
    id: string;
    customerId: number;
    lockDate: string;
    lockWindow: number;
    deliveryExpiration: string;
    tradeAmount: number;
    tradeIncentive: number;
    tradeVariance: number;
    pricingTiers: BulkCommitmentPricingTier[];
}

export interface BulkCommitmentDetails extends Omit<BulkCommitment, 'pricingTiers'> {
    pricingTiers: BulkCommitmentPricingTierDetails[];
}

interface BulkCommitmentPricingTier {
    productId?: number;
    noteRate?: number;
    price: number;
    subLimit?: number;
}

export interface BulkCommitmentPricingTierDetails extends BulkCommitmentPricingTier {
    loans: BulkCommitmentLoan[];
}

export interface BulkCommitmentLoan {
    loanNumber: string;
    lockDate: string;
    lockExpiration: string;
    loanAmount: number;
    status: CommitmentLoanStatus;
}

export enum CommitmentLoanStatus {
    SOLD = 'SOLD',
    PURCHASED = 'PURCHASED',
    DELIVERED = 'DELIVERED',
    UNDELIVERED = 'UNDELIVERED'
}

export const commitmentStatusDisplay = {
    [CommitmentLoanStatus.SOLD]: 'Sold',
    [CommitmentLoanStatus.PURCHASED]: 'Purchased',
    [CommitmentLoanStatus.DELIVERED]: 'Delivered',
    [CommitmentLoanStatus.UNDELIVERED]: 'Undelivered'
};
