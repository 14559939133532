import {
    Button, Paper, TextField, Typography
} from '@mui/material';
import { CardTable } from '@tsp-ui/core/components';
import { useEffect, useState } from 'react';

import loanDetailsStyles from '../../../LoanDetailPage.module.scss';
import { CreditUnderwritingTabs } from '../../CreditUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';

import styles from './AKAsSection.module.scss';


interface AKAsSectionProps {
    setTab: (tab: CreditUnderwritingTabs) => void;
    onTabComplete: (tab: CreditUnderwritingTabs, nextTab: CreditUnderwritingTabs) => void;
}

export default function AKAsSection({ onTabComplete }: AKAsSectionProps) {
    const [ hasAKAs, setHasAKAs ] = useState<boolean>();
    const [ showAddresses, setShowAddresses ] = useState<boolean>();
    const [ hasAddresses, setHasAddresses ] = useState<boolean>();

    useEffect(() => {
        if (hasAKAs !== undefined && hasAddresses === false) {
            onTabComplete('akas', 'other-liabilities');
        }
    }, [
        hasAKAs, hasAddresses, onTabComplete
    ]);

    useEffect(() => {
        if (hasAKAs === false) {
            setShowAddresses(true);
        }
    }, [ hasAKAs ]);

    return (
        <UnderwritingStepSection title="AKAs & Addresses">
            <div className={styles.questionContainer}>
                <UnderwritingYesNoQuestionCard
                    question="Are there any unexplained AKAs on the credit report?"
                    onAnswered={setHasAKAs}
                    answer={hasAKAs}
                />

                {hasAKAs && (
                    <Paper
                        variant="outlined"
                        className={loanDetailsStyles.card}
                    >
                        <Typography color="textSecondary">
                            AKAs requiring explanation
                        </Typography>

                        <CardTable
                            className={loanDetailsStyles.editableCardTable}
                            headers={[ 'AKA' ]}
                        >
                            <tr>
                                <td>
                                    <TextField
                                        variant="standard"
                                        size="small"
                                        autoFocus
                                    />
                                </td>
                            </tr>
                        </CardTable>

                        <div className={loanDetailsStyles.buttons}>
                            <Button>
                                Add AKA
                            </Button>

                            {!showAddresses && (
                                <Button
                                    variant="contained"
                                    onClick={() => setShowAddresses(true)}
                                >
                                    Next
                                </Button>
                            )}
                        </div>
                    </Paper>
                )}
            </div>

            {showAddresses && (
                <div className={styles.questionContainer}>
                    <UnderwritingYesNoQuestionCard
                        question="Are there any unexplained address variations on the credit report?"
                        onAnswered={setHasAddresses}
                        answer={hasAddresses}
                    />

                    {hasAddresses && (
                        <Paper
                            variant="outlined"
                            className={loanDetailsStyles.card}
                        >
                            <Typography color="textSecondary">
                                Addresses requiring explanation
                            </Typography>

                            <CardTable
                                className={loanDetailsStyles.editableCardTable}
                                headers={[ 'Address' ]}
                            >
                                <tr>
                                    <td>
                                        <TextField
                                            variant="standard"
                                            size="small"
                                            autoFocus
                                        />
                                    </td>
                                </tr>
                            </CardTable>

                            <div className={loanDetailsStyles.buttons}>
                                <Button>
                                    Add Address
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={() => onTabComplete('akas', 'other-liabilities')}
                                >
                                    Next
                                </Button>
                            </div>
                        </Paper>
                    )}
                </div>
            )}
        </UnderwritingStepSection>
    );
}
