import api, {
    AmortizationType,
    AutomatedUwRecommendation,
    AutomatedUwSystem,
    CommitmentType,
    DocumentationType,
    LoanActivity,
    LoanAlert,
    LoanDetail,
    LoanEvent,
    LoanEventType,
    LoanPurpose,
    LoanRegistrationResponse,
    LoanStatus,
    LoanType,
    NumUnits,
    OccupancyType,
    PipelineGroup,
    PropertyType,
    State,
    loanStatusDisplay
} from '@api';
import {
    createDate,
    randomNum
} from '@tsp-ui/core/utils';
import { addDays, parseISO } from 'date-fns';
import { DefaultBodyType, MockedRequest, rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';
import { internalUsers } from '../user/user-mocks';


let clientLoanNumber = 70000001;
let customerLoanNumber = 50003;
let pipelineGroupID = 300;
let loanAlertID = 500;
let loanActivityID = 600;
let loanTimelineEventID = 700;
let pendingUploadId = 1;

export function createPendingUploadsFromReq(req: MockedRequest<DefaultBodyType>) {
    const reqBody = req.body as any;

    // If it is a single file, reqBody.files is set to the single file, not an array
    const files: File[] = Array.isArray(reqBody.files) ? reqBody.files : [ reqBody.files ];

    return files.map((file) => ({
        fileId: `${pendingUploadId++}`,
        fileName: file.name,
        status: 'pending'
    }));
}

export const mocks = [
    rest.post(getMockUrl('/loan/file-upload'), (req, res, ctx) => {
        const pendingUploads = createPendingUploadsFromReq(req);

        setTimeout(() => (
            pendingUploads.forEach((pendingUpload) => {
                const status = pendingUpload.fileName.includes('Missing Loan Amount')
                    ? 'error'
                    : 'complete';

                if (status === 'complete') {
                    api.webSocket.simulatePricingComplete({
                        loanId: loanToRegister.id,
                        borrowerName: 'William J Clinton',
                        expirationDate: addDays(new Date(), 30).toISOString(),
                        interestRate: 6.5,
                        loanAmount: 166400,
                        loanNumber: 'ABC-50003',
                        pricedDate: new Date().toISOString(),
                        rateSheetId: '1000001'
                    });

                    loanEventsMap[loanToRegister.id].push({
                        id: String(loanTimelineEventID++),
                        loanId: loanToRegister.id,
                        eventType: LoanEventType.CREATED,
                        triggeredBy: internalUsers[0],
                        triggeredAt: new Date().toISOString()
                    }, {
                        id: String(loanTimelineEventID++),
                        loanId: loanToRegister.id,
                        eventType: LoanEventType.PRICED,
                        triggeredBy: internalUsers[0],
                        triggeredAt: new Date().toISOString()
                    });


                    api.webSocket.simulatePricingComplete({
                        loanId: '2',
                        borrowerName: 'George A Washington',
                        expirationDate: addDays(new Date(), 30).toISOString(),
                        interestRate: 6.5,
                        loanAmount: 450000,
                        loanNumber: 'ABC-50004',
                        pricedDate: new Date().toISOString(),
                        rateSheetId: '1000001'
                    });
                }

                api.webSocket.simulateUploadComplete({
                    ...pendingUpload,
                    status
                });
            })
        ), 2000);

        return (res(
            ctx.status(200),
            ctx.json(pendingUploads)
        ));
    }),
    rest.get(getMockUrl('/loan'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(loans)
    ))),
    rest.get(getMockUrl('/loan/alert'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(loanAlerts)
    ))),
    rest.get(getMockUrl('/loan/recent'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(recentlyActiveLoans)
    ))),
    rest.get(getMockUrl('/loan/group'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(pipelineGroups)
    ))),
    rest.post(getMockUrl('/loan/register'), (req, res, ctx) => {
        loans.push(loanToRegister);

        loanEventsMap[loanToRegister.id].push({
            id: String(loanTimelineEventID++),
            loanId: loanToRegister.id,
            eventType: LoanEventType.PRICED,
            triggeredBy: internalUsers[0],
            triggeredAt: new Date().toISOString()
        });

        return res(
            ctx.status(200),
            ctx.delay(randomNum(500, 1000)),
            ctx.json({
                clientLoanNumber: loanToRegister.loanNumber,
                premicorrLoanID: loanToRegister.id
            } as LoanRegistrationResponse)
        );
    }),
    rest.get(getMockUrl('/loan/:loanID'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(100),
        ctx.json(loanDetails[req.params.loanID as string])
    )),
    rest.post(getMockUrl('/loan/:loanID'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(100),
        ctx.json(loanDetails[req.params.loanID as string])
    )),
    rest.get(getMockUrl('/loan/:loanID/events'), (req, res, ctx) => res(
        ctx.status(200),
        ctx.delay(100),
        ctx.json(loanEventsMap[req.params.loanID as string])
    )),
    rest.post(getMockUrl('/loan/:loanID/doc-uploaded'), (req, res, ctx) => {
        const loanID = req.params.loanID as string;

        loanEventsMap[loanID].push({
            id: String(loanTimelineEventID++),
            loanId: loanID,
            eventType: LoanEventType.INITIAL_DOC_PACKAGE_UPLOADED,
            triggeredBy: internalUsers[0],
            triggeredAt: new Date().toISOString()
        });

        loans.find(({ id }) => id === loanID)!.loanStatus = LoanStatus.IN_SETUP;
        loanDetails[loanID].loanStatus = LoanStatus.IN_SETUP;

        return res(ctx.status(200));
    }),
    rest.post(getMockUrl('/loan/:loanID/viewed'), (req, res, ctx) => {
        const loanID = req.params.loanID as string;

        recentlyActiveLoans = recentlyActiveLoans.filter((activity) => activity.loanID !== loanID);
        recentlyActiveLoans.unshift({
            id: String(loanActivityID++),
            loanID,
            loanNumber: loanDetails[loanID].loanNumber,
            lastActiveAt: new Date().toISOString()
        });

        recentlyActiveLoans.sort((a, b) => parseISO(b.lastActiveAt).getTime() - parseISO(a.lastActiveAt).getTime());

        return res(
            ctx.status(200),
            ctx.json(recentlyActiveLoans)
        );
    })
];

// const firstNames = [
//     'Jeff', 'Jim', 'John', 'Jane'
// ];
// const lastNames = [
//     'Burrows', 'Baker', 'Brown', 'Bailey'
// ];

const pipelineGroups: PipelineGroup[] = Object.keys(loanStatusDisplay).filter(
    loanStatus => loanStatus !== LoanStatus.IN_SETUP
).map(loanStatus => ({
    id: String(pipelineGroupID++),
    title: getPipelineGroupTitle(loanStatus as LoanStatus)
}));

function getPipelineGroupTitle(loanStatus: LoanStatus) {
    return (loanStatus === LoanStatus.AWAITING_DOCS || loanStatus === LoanStatus.IN_SETUP)
        ? 'Awaiting Docs & Setup'
        : loanStatusDisplay[loanStatus];
}

// function getLoans(): Loan[] {
//     return [ ...Array(randomNum(10, 18)) ].map(() => {
//         const loanStatus = getRandomEnumValue(LoanStatus);
//
//         return {
//             id: String(loanID++),
//             loanNumber: String(clientLoanNumber++),
//             customerLoanNumber: String(customerLoanNumber++),
//             borrowerName: `${getRandomItemFromArray(firstNames)} ${getRandomItemFromArray(lastNames)}`,
//             loanAmount: randomNum(20, 500) * 1000,
//             loanStatus,
//             expirationDate: createDate(randomNum(-15, 180)).toISOString(),
//             interestRate: randomNum(4, 8, 3),
//             pipelineGroupID: pipelineGroups.find(
//                 pipelineGroup => pipelineGroup.title === getPipelineGroupTitle(loanStatus)
//             )!.id
//         };
//     });
// }

export const loans = [
    {
        id: '11D4DF9D-EC9C-4F45-95A9-02C3099F53A6',
        loanNumber: String(clientLoanNumber++),
        customerLoanNumber: `ABC-${customerLoanNumber++}`,
        borrowerName: 'William J Clinton',
        loanAmount: 166400,
        loanStatus: LoanStatus.CONDITIONED,
        expirationDate: createDate(randomNum(2, 90)).toISOString(),
        interestRate: 6.500,
        pipelineGroupID: pipelineGroups.find(
            pipelineGroup => pipelineGroup.title === getPipelineGroupTitle(LoanStatus.CONDITIONED)
        )!.id
    },
    {
        id: '9FD1A60A-C880-46CC-A9FA-ADE0F9388235',
        loanNumber: String(clientLoanNumber++),
        customerLoanNumber: `ABC-${customerLoanNumber++}`,
        borrowerName: 'Abraham Lincoln',
        loanAmount: 300000,
        loanStatus: LoanStatus.CLOSED,
        expirationDate: createDate(randomNum(2, 90)).toISOString(),
        interestRate: 6.750,
        pipelineGroupID: pipelineGroups.find(
            pipelineGroup => pipelineGroup.title === getPipelineGroupTitle(LoanStatus.CLOSED)
        )!.id
    }
];

const loanToRegister = {
    id: '322F0641-CABE-4E0E-B6A1-B6F48EE533E5',
    loanNumber: String(clientLoanNumber++),
    customerLoanNumber: `ABC-${customerLoanNumber++}`,
    borrowerName: 'William J Clinton',
    loanAmount: 166400,
    loanStatus: LoanStatus.AWAITING_DOCS,
    expirationDate: addDays(new Date(), 30).toISOString(),
    interestRate: 6.500,
    pipelineGroupID: pipelineGroups.find(
        pipelineGroup => pipelineGroup.title === getPipelineGroupTitle(LoanStatus.AWAITING_DOCS)
    )!.id
};

const loanAlerts: LoanAlert[] = [ 1 ].map((value, index) => (
    {
        id: String(loanAlertID++),
        loanID: loans[index].id,
        loanNumber: loans[index].loanNumber,
        alertType: 'One condition left',
        triggeredAt: createDate(randomNum(-2, -1 / 24 / 60, 10)).toISOString() // between 2 days and 1 minute ago
    }
)).sort((a, b) => parseISO(b.triggeredAt).getTime() - parseISO(a.triggeredAt).getTime());

let recentlyActiveLoans: LoanActivity[] = [ 1, 2 ].map((value, index) => ({
    id: String(loanActivityID++),
    loanID: loans[index].id,
    loanNumber: loans[index].loanNumber,
    lastActiveAt: createDate(randomNum(-2, -1 / 24 / 60, 10)).toISOString() // between 2 days and 1 minute ago
})).sort((a, b) => parseISO(b.lastActiveAt).getTime() - parseISO(a.lastActiveAt).getTime());

// const loanRegistrationResponse: LoanRegistrationResponse = {
//     clientLoanNumber: String(clientLoanNumber++),
//     premicorrLoanID: String(loanID++)
// };

// const loanEventsBase: Omit<LoanEvent, 'loanId'>[] = [
//     {
//         id: String(loanTimelineEventID++),
//         eventType: LoanEventType.CREATED,
//         triggeredBy: internalUsers[0],
//         triggeredAt: createDate(-.25).toISOString()
//     },
//     {
//         id: String(loanTimelineEventID++),
//         eventType: LoanEventType.PRICED,
//         triggeredBy: internalUsers[0],
//         triggeredAt: createDate(-.2).toISOString()
//     },
//     {
//         id: String(loanTimelineEventID++),
//         eventType: LoanEventType.REPRICED,
//         triggeredBy: internalUsers[0],
//         triggeredAt: createDate(-.1).toISOString()
//     },
//     {
//         id: String(loanTimelineEventID++),
//         eventType: LoanEventType.LOCKED,
//         triggeredBy: internalUsers[0],
//         triggeredAt: createDate(-.05).toISOString()
//     },
//     {
//         id: String(loanTimelineEventID++),
//         eventType: LoanEventType.INITIAL_DOC_PACKAGE_UPLOADED,
//         triggeredBy: internalUsers[0],
//         triggeredAt: createDate(-.025).toISOString()
//     }
// ];

const loanEventsMap: { [ key: string ]: LoanEvent[] } = {
    [loans[0].id]: [
        {
            id: String(loanTimelineEventID++),
            loanId: loans[0].id,
            eventType: LoanEventType.CREATED,
            triggeredBy: internalUsers[0],
            triggeredAt: createDate(-2).toISOString()
        },
        {
            id: String(loanTimelineEventID++),
            loanId: loans[0].id,
            eventType: LoanEventType.PRICED,
            triggeredBy: internalUsers[0],
            triggeredAt: createDate(-1.995).toISOString()
        },
        {
            id: String(loanTimelineEventID++),
            loanId: loans[0].id,
            eventType: LoanEventType.LOCKED,
            triggeredBy: internalUsers[0],
            triggeredAt: createDate(-1).toISOString()
        },
        {
            id: String(loanTimelineEventID++),
            loanId: loans[0].id,
            eventType: LoanEventType.INITIAL_DOC_PACKAGE_UPLOADED,
            triggeredBy: internalUsers[0],
            triggeredAt: createDate(-0.925).toISOString()
        }
    ],
    [loans[1].id]: [
        {
            id: String(loanTimelineEventID++),
            loanId: loans[1].id,
            eventType: LoanEventType.CREATED,
            triggeredBy: internalUsers[0],
            triggeredAt: createDate(-4).toISOString()
        },
        {
            id: String(loanTimelineEventID++),
            loanId: loans[1].id,
            eventType: LoanEventType.PRICED,
            triggeredBy: internalUsers[0],
            triggeredAt: createDate(-3.995).toISOString()
        },
        {
            id: String(loanTimelineEventID++),
            loanId: loans[1].id,
            eventType: LoanEventType.LOCKED,
            triggeredBy: internalUsers[0],
            triggeredAt: createDate(-3).toISOString()
        },
        {
            id: String(loanTimelineEventID++),
            loanId: loans[1].id,
            eventType: LoanEventType.CLOSED,
            triggeredBy: internalUsers[0],
            triggeredAt: createDate(-2.925).toISOString()
        }
    ],
    [loanToRegister.id]: []
};

// function getLoanEvents(loanID: string): LoanEvent[] {
//     return (getItemById(loans, loanID).loanStatus === LoanStatus.AWAITING_DOCS
//         ? loanEventsBase.slice(0, -1) // if awaiting docs, don't include the INITIAL_DOC_PACKAGE_UPLOADED event
//         : loanEventsBase
//     ).map(event => ({
//         ...event,
//         loanId: loanID
//     }));
// }

const loanDetails: { [key: string]: LoanDetail } = {
    [loans[0].id]: {
        ...loans[0],
        assignee: internalUsers[0],
        loanType: LoanType.CONVENTIONAL,
        amortizationType: AmortizationType.FIXED,
        escrowsFlag: true,
        interestOnlyFlag: false,
        loanTerm: 360,
        lockPeriod: 30,
        borrowers: [
            {
                firstName: 'William',
                middleName: 'J',
                lastName: 'Clinton',
                fico: 712,
                ssn: '111-11-1111',
                email: 'bclinton@email.com',
                primaryWageEarner: true,
                firstTimeHomeBuyer: false
            }
        ],
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        units: NumUnits.ONE,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        address: {
            street: '123 Any Street',
            city: 'Doylestown',
            state: State.PA,
            zip: '19348',
            county: 'Bucks'
        },
        appraisedValue: 208000,
        salePrice: 208000,
        customerId: '1000',
        customerName: 'ABC Mortgage',
        productCode: 'FH30',
        documentationType: DocumentationType.STREAMLINE,
        cashOutAmount: 69124.14,
        commitmentType: CommitmentType.BEST_EFFORT,
        commitmentIdentifier: 'SCO',
        comments: 'Some comments',
        underwriteFlag: false,
        automatedUwSystem: AutomatedUwSystem.LPA,
        automatedUwRecommendation: AutomatedUwRecommendation.APPROVE_ELIGIBLE
    },
    [loans[1].id]: {
        ...loans[1],
        assignee: internalUsers[0],
        loanType: LoanType.CONVENTIONAL,
        amortizationType: AmortizationType.FIXED,
        escrowsFlag: true,
        interestOnlyFlag: false,
        loanTerm: 360,
        lockPeriod: 30,
        borrowers: [
            {
                firstName: 'William',
                middleName: 'J',
                lastName: 'Clinton',
                fico: 712,
                ssn: '111-11-1111',
                email: 'bclinton@email.com',
                primaryWageEarner: true,
                firstTimeHomeBuyer: false
            }
        ],
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        units: NumUnits.ONE,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        address: {
            street: '123 Any Street',
            city: 'Doylestown',
            state: State.PA,
            zip: '19348',
            county: 'Bucks'
        },
        appraisedValue: 208000,
        salePrice: 208000,
        customerId: '1000',
        customerName: 'ABC Mortgage',
        productCode: 'FH30',
        documentationType: DocumentationType.STREAMLINE,
        cashOutAmount: 69124.14,
        commitmentType: CommitmentType.BEST_EFFORT,
        commitmentIdentifier: 'SCO',
        comments: 'Some comments',
        underwriteFlag: false,
        automatedUwSystem: AutomatedUwSystem.LPA,
        automatedUwRecommendation: AutomatedUwRecommendation.APPROVE_ELIGIBLE
    },
    [loanToRegister.id]: {
        ...loanToRegister,
        assignee: internalUsers[0],
        loanType: LoanType.CONVENTIONAL,
        amortizationType: AmortizationType.FIXED,
        escrowsFlag: true,
        interestOnlyFlag: false,
        loanTerm: 360,
        lockPeriod: 30,
        borrowers: [
            {
                firstName: 'William',
                middleName: 'J',
                lastName: 'Clinton',
                fico: 712,
                ssn: '111-11-1111',
                email: 'bclinton@email.com',
                primaryWageEarner: true,
                firstTimeHomeBuyer: false
            }
        ],
        propertyType: PropertyType.SINGLE_FAMILY_RESIDENCE,
        units: NumUnits.ONE,
        occupancy: OccupancyType.OWNER_OCCUPIED,
        purpose: LoanPurpose.REFINANCE_CASH_OUT,
        address: {
            street: '123 Any Street',
            city: 'Doylestown',
            state: State.PA,
            zip: '19348',
            county: 'Bucks'
        },
        appraisedValue: 208000,
        salePrice: 208000,
        customerId: '1000',
        customerName: 'ABC Mortgage',
        productCode: 'FH30',
        documentationType: DocumentationType.STREAMLINE,
        cashOutAmount: 69124.14,
        commitmentType: CommitmentType.BEST_EFFORT,
        commitmentIdentifier: 'SCO',
        comments: 'Some comments',
        underwriteFlag: false,
        automatedUwSystem: AutomatedUwSystem.LPA,
        automatedUwRecommendation: AutomatedUwRecommendation.APPROVE_ELIGIBLE
    }
};

// function getLoanDetail(loanID: string): LoanDetail {
//     const loan = loans.find(loan => loan.id === loanID)!;
//     const amortizationType = getRandomEnumValue(AmortizationType);
//
//     return {
//         id: loanID,
//         assignee: internalUsers[0],
//         loanNumber: loan.loanNumber,
//         loanStatus: loan.loanStatus,
//         loanType: getRandomEnumValue(LoanType),
//         loanAmount: loan.loanAmount,
//         interestRate: loan.interestRate,
//         amortizationType,
//         armMargin: .01,
//         armInitialCap: .02,
//         armSubsequentCap: .02,
//         armLifeCap: .02,
//         escrowsFlag: randomBoolean(),
//         interestOnlyFlag: randomBoolean(),
//         loanTerm: 360,
//         lockPeriod: 30,
//         borrowers: [
//             {
//                 firstName: 'First',
//                 middleName: 'Middle',
//                 lastName: 'Last',
//                 fico: randomNum(500, 800),
//                 ssn: '123-44-2364',
//                 email: 'name@company.com',
//                 primaryWageEarner: randomBoolean(),
//                 firstTimeHomeBuyer: randomBoolean()
//             },
//             {
//                 firstName: 'Some',
//                 middleName: 'Other',
//                 lastName: 'Person',
//                 fico: randomNum(500, 800),
//                 ssn: '485-73-1284',
//                 email: 'name@company.com',
//                 primaryWageEarner: randomBoolean(),
//                 firstTimeHomeBuyer: randomBoolean()
//             }
//         ],
//         propertyType: getRandomEnumValue(PropertyType),
//         units: getRandomEnumValue(NumUnits),
//         occupancy: getRandomEnumValue(OccupancyType),
//         purpose: getRandomEnumValue(LoanPurpose),
//         address: {
//             street: '1234 Some Ln',
//             city: 'Somewhere',
//             state: State.SC,
//             zip: '29414'
//         },
//         appraisedValue: randomNum(25, 30) * 1000,
//         salePrice: randomNum(20, 25) * 1000,
//
//         customerId: '1',
//         customerName: 'Jeff Bowen',
//         customerLoanNumber: '44759925',
//         productCode: 'Some code',
//         specialtyProgram: getRandomEnumValue(SpecialtyProgram),
//         documentationType: getRandomEnumValue(DocumentationType),
//         subordinatedBalance: randomNum(5, 10) * 1000,
//         cashOutAmount: randomNum(5, 10) * 1000,
//         limitedLiabilityCorp: 'Some corp',
//         commitmentType: getRandomEnumValue(CommitmentType),
//         commitmentIdentifier: 'SCO',
//         comments: 'Adding a note',
//         mortgageInsFlag: randomBoolean(),
//         mortgageInsCompany: 'Ins co',
//         mortgageInsCoverage: randomNum(10, 200) * 1000,
//         underwriteFlag: randomBoolean(),
//         automatedUwSystem: getRandomEnumValue(AutomatedUwSystem),
//         automatedUwRecommendation: getRandomEnumValue(AutomatedUwRecommendation)
//     };
// }
