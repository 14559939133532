import { NotificationConfig } from '@api';
import { Button, Paper, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import NotificationCard from './NotificationCard';
import styles from './NotificationsSection.module.scss';


interface NotificationsSectionProps {
    notifications: NotificationConfig[]
}

export default function NotificationsSection({ notifications }: NotificationsSectionProps) {
    const location = useLocation();

    return (
        <Paper
            variant="outlined"
            className={styles.paper}
        >
            <div className={styles.header}>
                <Typography variant="h6">
                    Notifications
                </Typography>

                <Button
                    component={Link}
                    to={`${location.pathname}/notifications/new`}
                >
                    Add notification
                </Button>
            </div>

            {notifications.map(notification => (
                <NotificationCard
                    key={notification.id}
                    notification={notification}
                />
            ))}
        </Paper>
    );
}
