export enum CustomerPendingSteps {
    COMP_FEES = 'COMP_FEES',
    DUE_DILIGENCE = 'DUE_DILIGENCE',
    ELIGIBLE_PRODUCTS = 'ELIGIBLE_PRODUCTS',
    LICENSES = 'LICENSES',
    MANAGERS = 'MANAGERS',
    USERS_CONTACTS = 'USERS_CONTACTS',
    WIRE_INSTRUCTIONS = 'WIRE_INSTRUCTIONS'
}

export const customerPendingStepsDisplay = {
    [CustomerPendingSteps.COMP_FEES]: 'Compensation & fees',
    [CustomerPendingSteps.DUE_DILIGENCE]: 'Due diligence',
    [CustomerPendingSteps.ELIGIBLE_PRODUCTS]: 'Eligible products',
    [CustomerPendingSteps.LICENSES]: 'Licenses',
    [CustomerPendingSteps.MANAGERS]: 'Managers',
    [CustomerPendingSteps.USERS_CONTACTS]: 'Users & contacts',
    [CustomerPendingSteps.WIRE_INSTRUCTIONS]: 'Wire instructions'
};
