import api, { ClientDetail } from '@api';
import {
    Divider, Link as MuiLink, Popover, Typography
} from '@mui/material';
import { useAsyncEffect } from '@tsp-ui/core/utils';
import { ClientCard } from '@views/admin/clients/components/ClientCard';
import { Fragment, useCallback, useState } from 'react';

import styles from './ActiveClients.module.scss';


export function ActiveClients({ clientIDs = [] }: { clientIDs?: string[] }) {
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();
    const [ clients, setClients ] = useState<ClientDetail[]>();

    useAsyncEffect(useCallback(async () => {
        if (!clients) {
            // TODO post-demo handle errors
            setClients((await api.client.getClients()).filter(client => clientIDs.includes(client.id)));
        }
    }, [ clients, clientIDs ]));

    return (
        <>
            <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                className={styles.activeText}
            >
                {clientIDs.length ? (
                    <>
                        Active for{' '}

                        <MuiLink
                            onClick={(event) => {
                                event.preventDefault();
                                setAnchorEl(event.currentTarget);
                            }}
                        >
                            {clientIDs.length} clients
                        </MuiLink>
                    </>
                ) : 'Not active for any clients'}
            </Typography>

            <Popover
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
                PaperProps={{ className: styles.activeClientsPopover }}
            >
                <Typography variant="h6">
                    Active clients
                </Typography>

                {clients?.map((client, index) => (
                    <Fragment key={client.id}>
                        <ClientCard client={client} />

                        {index !== clients.length - 1 && <Divider />}
                    </Fragment>
                ))}
            </Popover>
        </>
    );
}
