import { User } from '@api';
import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { LabeledValue, PhoneTypography } from '@tsp-ui/core/components';
import { getFullName, useConfirm } from '@tsp-ui/core/utils';
import { RolesContext } from '@views/admin/users/UserManagementPage';
import { useContext } from 'react';

import { UserOrContact } from '../UsersAndContactsDialog';

import styles from './UserCard.module.scss';


interface UserCardProps {
    user: User;
    onDelete: (id: string, type: UserOrContact) => void;
    onEditClick: (user: User) => void;
    readOnly?: boolean;
    showRoles?: boolean;
}

export default function UserCard({ // TODO post-demo refactor the global UserCard and delete this file
    user, onDelete, onEditClick, readOnly, showRoles = true
}: UserCardProps) {
    const {
        id, email, roleIds, phone
    } = user;

    const { entities: roles } = useContext(RolesContext);

    const confirm = useConfirm();

    async function handleDelete() {
        if (await confirm('Are you sure you want to delete this user?')) {
            onDelete(id, 'user');
        }
    }

    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            <div>
                <Typography>
                    {getFullName(user)}
                </Typography>

                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {email}
                </Typography>

                <PhoneTypography variant="body2">
                    {phone || ''}
                </PhoneTypography>
            </div>

            {showRoles && (
                <div className={styles.rolesContainer}>
                    <LabeledValue
                        label="Roles"
                        value={(
                            <div>
                                {roleIds.map(roleID => (
                                    <div key={roleID}>{roles.find(role => role.id === roleID)?.name}</div>
                                ))}
                            </div>
                        )}
                        variant="vertical"
                    />
                </div>
            )}

            {!readOnly && (
                <div className={styles.actionsContainer}>
                    <Tooltip title="Edit user">
                        <IconButton onClick={() => onEditClick(user)}>
                            <Edit color="secondary" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete user">
                        <IconButton
                            edge="end"
                            onClick={handleDelete}
                        >
                            <RemoveCircleOutline color="error" />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        </Paper>
    );
}
