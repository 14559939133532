export enum LockPeriod {
    SEVEN = 7,
    TEN = 10,
    FIFTEEN = 15,
    THIRTY = 30,
    FORTY_FIVE = 45,
    SIXTY = 60,
    SEVENTY_FIVE = 75,
    NINETY = 90
}

export const lockPeriodDisplay = {
    [LockPeriod.SEVEN]: '7',
    [LockPeriod.TEN]: '10',
    [LockPeriod.FIFTEEN]: '15',
    [LockPeriod.THIRTY]: '30',
    [LockPeriod.FORTY_FIVE]: '45',
    [LockPeriod.SIXTY]: '60',
    [LockPeriod.SEVENTY_FIVE]: '75',
    [LockPeriod.NINETY]: '90'
};
