import { useEffect, useState } from 'react';

import { FraudReportUnderwritingTabs } from '../../FraudReportUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';


interface FraudReportSectionProps {
    setTab: (tab: FraudReportUnderwritingTabs) => void;
    onTabComplete: (tab: FraudReportUnderwritingTabs, nextTab: FraudReportUnderwritingTabs) => void;
}

export default function FraudReportSection({ onTabComplete }: FraudReportSectionProps) {
    const [ inFile, setInFile ] = useState<boolean>();
    const [ alertsAddressed, setAlertsAddressed ] = useState<boolean>();
    const [ checkedForUndisclosed, setCheckedForUndisclosed ] = useState<boolean>();

    useEffect(() => {
        if (checkedForUndisclosed !== undefined) {
            onTabComplete('report', 'parties');
        }
    }, [ checkedForUndisclosed, onTabComplete ]);

    return (
        <UnderwritingStepSection title="Fraud report">
            <UnderwritingYesNoQuestionCard
                question="Is the fraud report on file?"
                onAnswered={setInFile}
                answer={inFile}
            />

            {inFile && (
                <UnderwritingYesNoQuestionCard
                    question="Have all high and medium alerts been addressed with conditions or notes?"
                    onAnswered={setAlertsAddressed}
                    answer={alertsAddressed}
                />
            )}

            {alertsAddressed !== undefined && (
                <UnderwritingYesNoQuestionCard
                    question="Has the fraud report been checked for any undisclosed REO or other addresses?"
                    onAnswered={setCheckedForUndisclosed}
                    answer={checkedForUndisclosed}
                />
            )}
        </UnderwritingStepSection>
    );
}
