import {
    Contact,
    CustomerAction,
    CustomerChecklistStep,
    CustomerWireInfo,
    ProductType,
    UnderwritingType,
    User
} from '../..';
import { Branch } from '../branches/branches-api';
import { CustomerCompensation, CustomerFee } from '../compensation/compensation-api';
import { CustomerDetails } from '../customer-api';
import { LimitConfig, ProductTypeConfig } from '../eligibleProducts/eligibleProducts-api';
import { License } from '../licenses/licenses-api';


let mockHistoryEvents: CustomerHistoryEvent[] = []; // TODO post-demo remove
export function setMockHistoryEvents(newHistoryEvents: CustomerHistoryEvent[]) {
    mockHistoryEvents = newHistoryEvents;
}

export function getHistory(customerID: string): Promise<CustomerHistoryEvent[]> {
    return new Promise(res => res(mockHistoryEvents)); // TODO post-demo remove this hard coded mock
    // return apiUtils.get(`/customer/${customerID}/eventHistory`);
}

export interface CustomerHistoryEvent {
    id: string;
    action: CustomerAction;
    executedBy: User;
    date: string;
    details: CustomerHistoryEventDetails;
}

interface CustomerHistoryEventDetails<T extends CustomerInfoType = CustomerInfoType> {
    before?: T;
    after: T;
}

export interface EligibleProductsToggleDetails {
    type: ProductType | UnderwritingType;
    products: string[];
}

export function isUnderwritingType(type: ProductType | UnderwritingType): type is UnderwritingType {
    return type === UnderwritingType.DELEGATED || type === UnderwritingType.NON_DELEGATED;
}

export interface EligibleProductsLimitUpdated {
    limit: LimitConfig;
    name: string;
}

export interface EligibleProductsSingleProductToggled {
    product: string;
}

export type CustomerInfoType = Branch
    | Contact
    | CustomerChecklistStep
    | CustomerCompensation
    | CustomerDetails
    | CustomerFee
    | CustomerWireInfo
    | ProductTypeConfig
    | EligibleProductsLimitUpdated
    | EligibleProductsSingleProductToggled
    | EligibleProductsToggleDetails
    | License
    | User;
