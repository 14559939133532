import { Button, ButtonProps, Tooltip } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import styles from './UnderwritingAnswerButtons.module.scss';


interface UnderwritingAnswerButtonsProps<T> {
    buttons: ButtonConfig<T>[];
    size?: ButtonProps['size'];
    value: T;
    setValue: Dispatch<SetStateAction<T>>;
}

export interface ButtonConfig<T> {
    label: string;
    value: T;
    tooltip?: string;
}

export default function UnderwritingAnswerButtons<T>({
    buttons, size, value, setValue
}: UnderwritingAnswerButtonsProps<T>) {
    return (
        <div className={styles.root}>
            {buttons.map(({ value: buttonValue, label, tooltip }) => (
                <Tooltip
                    key={String(buttonValue)}
                    title={tooltip || ''}
                    enterDelay={0}
                >
                    <Button
                        key={String(buttonValue)}
                        variant={buttonValue === value ? 'contained' : 'text'}
                        size={size}
                        disableElevation
                        onClick={() => setValue(buttonValue)}
                    >
                        {label}
                    </Button>
                </Tooltip>
            ))}
        </div>
    );
}
