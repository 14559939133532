import api, {
    NotificationConfig,
    NotificationFrequency,
    NotificationMethod,
    NotificationParam,
    NotificationType,
    conditionEventDisplay,
    conditionExceptionStatusDisplay,
    loanRoleAssignmentDisplay,
    loanStatusDelegatedDisplay,
    loanStatusNonDelegatedDisplay,
    notificationFrequencyDisplay,
    notificationMethodDisplay,
    notificationTypeDisplay
} from '@api';
import { Button, DialogContent } from '@mui/material';
import {
    DialogActions, RoutedDialog, RoutedDialogProps, Switch, TextField, renderEnumOptions
} from '@tsp-ui/core/components';
import { usePageMessage, useParams } from '@tsp-ui/core/utils';
import { useCreateAccountUrl, useGetCurrentAccount } from '@utils/hooks';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AdminRouteParams } from '../../components/AdminPageTemplate';
import { RoleDetailPageContext } from '../RoleDetailPage';

import styles from './NotificationDialog.module.scss';


const {
    LOAN_STATUS_DELEGATED,
    LOAN_STATUS_NON_DELEGATED,
    CONDITION_EVENT,
    LOAN_ROLE_ASSIGNMENT,
    CONDITION_EXCEPTION_STATUS
} = NotificationType;

const NOTIFICATION_DIALOG = 'notification-dialog';

type NotificationDialogProps = Omit<RoutedDialogProps, 'title' | 'children'>;

export default function NotificationDialog(props: NotificationDialogProps) {
    const [ loading, setLoading ] = useState(false);

    const { accountUserType, customerId } = useGetCurrentAccount();
    const createAccountUrl = useCreateAccountUrl();
    const navigate = useNavigate();

    const { roleID, notificationID } = useParams<AdminRouteParams<'role' | 'notification'>>();

    const { notifications, updateNotifications } = useContext(RoleDetailPageContext);
    const defaultValues = notifications.find(({ id }) => id === parseInt(notificationID));

    const formMethods = useForm<NotificationConfig>({ defaultValues });
    const { setValue, watch, reset } = formMethods;
    const pageMessage = usePageMessage();

    const notificationType = watch('type');

    const onEdit = (updatedNotification: NotificationConfig) => {
        updateNotifications(notifications.map(
            notification => (notification.id === updatedNotification.id ? updatedNotification : notification)
        ));
    };

    const onCreate = (newNotification: NotificationConfig) => {
        updateNotifications(notifications.concat([ newNotification ]));
    };

    const onDone = () => {
        reset(baseValues);
        navigate(createAccountUrl(`admin/roles/${roleID}`));
    };

    const handleSubmit = formMethods.handleSubmit(async formValues => {
        setLoading(true);

        try {
            if (defaultValues) {
                onEdit(
                    await api.roles.notifications.editNotification(
                        accountUserType, parseInt(roleID), formValues, customerId
                    )
                );
            } else {
                onCreate(
                    await api.roles.notifications.addNotification(
                        accountUserType, parseInt(roleID), formValues, customerId
                    )
                );
            }

            onDone();
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the notification configuration', error);
        }

        setLoading(false);
    });

    const defaultType = defaultValues?.type;
    useEffect(() => {
        if (notificationType !== defaultType) {
            setValue('parameters', []);
        }
    }, [
        notificationType, setValue, defaultType
    ]);

    useEffect(() => {
        reset(defaultValues);
    }, [ defaultValues, reset ]);

    return (
        <RoutedDialog
            {...props}
            title={`${defaultValues ? 'Edit' : 'Add'} notification`}
        >
            <DialogContent className={styles.container}>
                <form
                    id={NOTIFICATION_DIALOG}
                    className={styles.root}
                    onSubmit={handleSubmit}
                    key={String(defaultValues)}
                    noValidate
                >
                    <FormProvider {...formMethods}>
                        <TextField<NotificationConfig>
                            name="type"
                            label="Type"
                            required
                            hideRequiredIndicator
                            select
                            disabled={!!defaultValues}
                        >
                            {renderEnumOptions(notificationTypeDisplay)}
                        </TextField>

                        <TextField<NotificationConfig>
                            name="frequency"
                            label="Frequency"
                            required
                            hideRequiredIndicator
                            select
                        >
                            {renderEnumOptions(notificationFrequencyDisplay)}
                        </TextField>

                        <TextField<NotificationConfig>
                            name="methods"
                            label="Methods"
                            select
                            SelectProps={{
                                multiple: true
                            }}
                        >
                            {renderEnumOptions(notificationMethodDisplay)}
                        </TextField>

                        <TextField<NotificationConfig>
                            name="parameters"
                            label="Parameters"
                            required
                            hideRequiredIndicator
                            select
                            SelectProps={{
                                multiple: true
                            }}
                            disabled={!notificationType}
                        >
                            {renderParameters(notificationType)}
                        </TextField>

                        <Switch<NotificationConfig>
                            name="includeUnassigned"
                            label="Include unassigned"
                        />
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button onClick={onDone}>
                    Cancel
                </Button>

                <Button
                    form={NOTIFICATION_DIALOG}
                    variant="contained"
                    type="submit"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

function renderParameters(notificationType: NotificationType) {
    switch (notificationType) {
    case LOAN_STATUS_DELEGATED:
        return renderEnumOptions(loanStatusDelegatedDisplay);
    case LOAN_STATUS_NON_DELEGATED:
        return renderEnumOptions(loanStatusNonDelegatedDisplay);
    case CONDITION_EVENT:
        return renderEnumOptions(conditionEventDisplay);
    case LOAN_ROLE_ASSIGNMENT:
        return renderEnumOptions(loanRoleAssignmentDisplay);
    case CONDITION_EXCEPTION_STATUS:
        return renderEnumOptions(conditionExceptionStatusDisplay);
    default:
        return <div />;
    }
}

const baseValues = {
    type: '' as NotificationType,
    parameters: [ '' as NotificationParam ],
    frequency: '' as NotificationFrequency,
    methods: [ '' as NotificationMethod ],
    includeUnassigned: false
};
