import { Button, Link as MuiLink, Typography } from '@mui/material';
import { isFutureDate, isPastDate } from '@tsp-ui/core/utils';
import EditableSectionCard from '@views/admin/components/EditableSectionCard';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { ProgramEligibilityContext } from '../InternalLoanProgramDetailPage';

import styles from './EligibilityGuidelinesCard.module.scss';
import { GuidelineCard } from './GuidelineCard';
import { getActiveGuidelineSet } from './LoanProgramForm';


export default function EligibilityGuidelinesCard() {
    const { eligibilityGuidelineSets } = useContext(ProgramEligibilityContext);

    const activeGuidelineSet = getActiveGuidelineSet(eligibilityGuidelineSets);

    const pendingGuidelines = eligibilityGuidelineSets?.filter(
        ({ effectiveDate }) => isFutureDate(effectiveDate)
    );

    const expiredGuidelines = eligibilityGuidelineSets?.filter(
        ({ expirationDate }) => isPastDate(expirationDate)
    );

    const [ showExpired, setShowExpired ] = useState(false);

    return (
        <EditableSectionCard
            header="Eligibility guidelines"
            editTo="edit"
            readOnly
            className={styles.root}
        >
            <Typography
                variant="caption"
                color="textSecondary"
            >
                Active
            </Typography>

            {activeGuidelineSet ? (
                <GuidelineCard guideline={activeGuidelineSet} />
            ) : (
                <Typography variant="body2">
                    There are no active guidelines.&nbsp;

                    <MuiLink
                        component={Link}
                        to="guidelines/new"
                    >
                        Click here
                    </MuiLink> to create new ones.
                </Typography>
            )}

            <Typography
                variant="caption"
                color="textSecondary"
                component="div"
                className={styles.label}
            >
                Pending
            </Typography>

            {pendingGuidelines?.length ? (
                <div className={styles.guidelineContainer}>
                    {pendingGuidelines.map(pendingGuideline => (
                        <GuidelineCard
                            key={pendingGuideline.id}
                            guideline={pendingGuideline}
                        />
                    ))}
                </div>
            ) : (
                <Typography variant="body2">
                    There are no pending guidelines.
                </Typography>
            )}

            {!!expiredGuidelines?.length && (
                <>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        component="div"
                        className={styles.label}
                    >
                        Expired
                    </Typography>

                    {showExpired ? (
                        <div className={styles.guidelineContainer}>
                            {expiredGuidelines.map(expiredGuideline => (
                                <GuidelineCard
                                    key={expiredGuideline.id}
                                    guideline={expiredGuideline}
                                />
                            ))}
                        </div>
                    ) : (
                        <Button
                            size="small"
                            className={styles.button}
                            onClick={() => setShowExpired(!showExpired)}
                        >
                            View expired guidelines
                        </Button>
                    )}
                </>
            )}
        </EditableSectionCard>
    );
}
