import { TimeString } from '@api';
import {
    BlockOutlined, CheckCircleOutlined, Edit, RemoveCircleOutline
} from '@mui/icons-material';
import {
    Alert, IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { ProgressIndicator } from '@tsp-ui/core/components';
import { formatTime } from '@tsp-ui/core/utils';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';

import styles from './LockAvailabilityCard.module.scss';


interface LockAvailabilityCardProps {
    isAvailable: boolean;
    title: string;
    lockStart: TimeString | null | undefined;
    lockEnd: TimeString | null | undefined;
    date?: string;
    onEdit(): void;
    onToggle(): Promise<void>;
    onDelete?(): Promise<void>;
}

export function LockAvailabilityCard({
    isAvailable, title, lockStart, lockEnd, date, onEdit, onToggle, onDelete
}: LockAvailabilityCardProps) {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDeleting, setIsDeleting ] = useState(false);

    return (
        <Paper
            className={styles.card}
            elevation={0}
        >
            <div>
                <Typography>
                    {title}
                </Typography>

                {isAvailable ? (
                    <Typography variant="body2">
                        {date && (
                            <strong>
                                {format(parseISO(date), 'M/dd/yyyy')},&nbsp;
                            </strong>
                        ) }

                        {`${formatTime(lockStart)} - ${formatTime(lockEnd)}`}
                    </Typography>
                ) : (
                    <Alert
                        severity="warning"
                        icon={false}
                        className={styles.alert}
                    >
                        Lock desk closed on {date ? format(parseISO(date), 'M/dd/yyyy') : `${title}s`}
                    </Alert>
                )}
            </div>

            <div>
                {isAvailable && (
                    <Tooltip
                        title="Edit"
                    >
                        <IconButton onClick={onEdit}>
                            <Edit color="secondary" />
                        </IconButton>
                    </Tooltip>
                )}

                {isLoading ? <ProgressIndicator className={styles.progress} /> : (
                    <Tooltip
                        title={`${isAvailable ? 'Close' : 'Open'} lock desk`}
                    >
                        <IconButton
                            onClick={async () => {
                                setIsLoading(true);

                                await onToggle();

                                setIsLoading(false);
                            }}
                        >
                            {isAvailable ? <BlockOutlined color="action" /> : <CheckCircleOutlined color="success" />}
                        </IconButton>
                    </Tooltip>
                )}

                {isDeleting ? <ProgressIndicator /> : onDelete ? (
                    <Tooltip title="Delete">
                        <IconButton
                            onClick={async () => {
                                setIsDeleting(true);

                                await onDelete();

                                setIsDeleting(false);
                            }}
                        >
                            <RemoveCircleOutline color="error" />
                        </IconButton>
                    </Tooltip>
                ) : undefined}
            </div>
        </Paper>
    );
}
