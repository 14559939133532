import { ExclusionOverlayType } from '@api';
import {
    Button, DialogContent, Step, StepLabel, Stepper
} from '@mui/material';
import { RoutedDialog, RoutedDialogImplProps } from '@tsp-ui/core/components';
import { useParams } from '@tsp-ui/core/utils';
import ExclusionOverlayForm, { useOverlayType } from '@views/admin/investor-eligibility/components/ExclusionOverlayForm';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './ExclusionOverlayDialog.module.scss';


export default function ExclusionOverlayDialog(props: RoutedDialogImplProps) {
    const { overlayID } = useParams();

    const [ saveLoading, setSaveLoading ] = useState(false);
    const [ activeStep, setActiveStep ] = useState(0);

    const type = useOverlayType();

    return (
        <RoutedDialog
            title={overlayID ? 'Edit exclusion overlay' : 'Add exclusion overlay'}
            maxWidth={false}
            saveLoading={saveLoading}
            {...props}
        >
            <DialogContent className={styles.stepperContent}>
                <Stepper
                    activeStep={activeStep}
                    className={styles.stepper}
                >
                    <Step>
                        <StepLabel>
                            Details
                        </StepLabel>
                    </Step>

                    {type !== ExclusionOverlayType.LOAN_PROGRAM && (
                        <Step>
                            <StepLabel>
                                {type === ExclusionOverlayType.CLIENT
                                    ? 'Investors'
                                    : 'Programs'}
                            </StepLabel>
                        </Step>
                    )}

                    <Step>
                        <StepLabel>
                            Exclusions
                        </StepLabel>
                    </Step>
                </Stepper>
            </DialogContent>

            <DialogContent>
                <ExclusionOverlayForm
                    setLoading={setSaveLoading}
                    activeStep={activeStep}
                />

                <div className={styles.buttons}>
                    <Button
                        component={Link}
                        replace
                        to=".."
                    >
                        Cancel
                    </Button>

                    {(type === ExclusionOverlayType.LOAN_PROGRAM && activeStep === 1) || activeStep === 2 ? (
                        <Button
                            key="save"
                            form={ExclusionOverlayForm.formID}
                            variant="contained"
                            type="submit"
                        >
                            Save
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            onClick={() => setActiveStep(activeStep + 1)}
                        >
                            Next
                        </Button>
                    )}
                </div>
            </DialogContent>
        </RoutedDialog>
    );
}
