import { CustomerFee, feeTypeDisplay } from '@api';
import {
    CheckCircleOutline, Edit, PriorityHigh, RemoveCircleOutline, RotateLeftOutlined
} from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { CurrencyField } from '@tsp-ui/core/components';
import { formatCurrency } from '@tsp-ui/core/utils';
import clsx from 'clsx';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FeesCompFormValues } from './CompensationDialogForm';
import styles from './CustomerFeeTableRow.module.scss';


interface CustomerFeeTableRowProps {
    fee: CustomerFee;
    index: number;
    readOnly?: boolean;
}

export default function CustomerFeeTableRow({
    fee, index, readOnly
}: CustomerFeeTableRowProps) {
    const nameBase: `fees.${number}` = `fees.${index}`;

    const { setValue, watch } = useFormContext<FeesCompFormValues>() || {
        setValue: () => {},
        watch: () => {}
    };

    const [ isEditing, setIsEditing ] = useState(false);

    const newAmount = watch(`${nameBase}.adjustedAmount`);
    const isActive = readOnly ? fee.isActive : watch(`${nameBase}.isActive`);
    const amountDisplay = newAmount || fee.defaultAmount;
    const isAdjusted = fee.defaultAmount !== amountDisplay;

    const resetToDefault = () => {
        setValue(`${nameBase}.adjustedAmount`, undefined);
    };

    const toggleActive = () => {
        setValue(`${nameBase}.isActive`, !isActive);

        if (isEditing) {
            setIsEditing(false);
        }
    };

    const saveAdjustment = () => {
        setIsEditing(false);
    };

    const triggerAdjustment = () => {
        setIsEditing(true);
        setValue(`${nameBase}.adjustedAmount`, amountDisplay);
    };

    return (
        <tr
            key={fee.feeType}
            className={clsx(styles.tableRow, {
                [styles.rowInactive]: !isActive
            })}
        >
            <td width="100%">
                <Typography variant="body2">
                    {feeTypeDisplay[fee.feeType]}
                </Typography>
            </td>

            <td>
                {isEditing ? (
                    <CurrencyField<FeesCompFormValues>
                        name={`${nameBase}.adjustedAmount`}
                        className={styles.adjustmentField}
                        variant="standard"
                        autoFocus
                        onKeyDown={event => {
                            if (event.key === 'Enter') {
                                saveAdjustment();
                            }
                        }}
                    />
                ) : (
                    <Typography
                        variant="body2"
                        className={styles.adjusted}
                        align="right"
                    >
                        {isAdjusted && (
                            <Tooltip
                                title={(
                                    <>
                                        Fee adjusted
                                        <br />

                                        Original amount: {
                                            formatCurrency(fee.defaultAmount)
                                        }
                                    </>
                                )}
                            >
                                <PriorityHigh
                                    color="warning"
                                    className={styles.adjustedIcon}
                                />
                            </Tooltip>
                        )}

                        {formatCurrency(amountDisplay)}
                    </Typography>
                )}
            </td>

            <td>
                <div className={styles.actionButtons}>
                    {isAdjusted && !readOnly && (
                        <Tooltip title={isEditing ? '' : 'Reset to default'}>
                            <span>
                                <IconButton
                                    size="small"
                                    disabled={isEditing}
                                    onClick={resetToDefault}
                                >
                                    <RotateLeftOutlined fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    )}

                    {readOnly ? null : isEditing ? (
                        <Tooltip title="Done">
                            <IconButton
                                size="small"
                                onClick={saveAdjustment}
                            >
                                <CheckCircleOutline
                                    color="secondary"
                                    fontSize="small"
                                />
                            </IconButton>
                        </Tooltip>
                    ) : isActive && (
                        <Tooltip title="Adjust fee">
                            <IconButton
                                size="small"
                                onClick={triggerAdjustment}
                            >
                                <Edit
                                    color="secondary"
                                    fontSize="small"
                                />
                            </IconButton>
                        </Tooltip>
                    )}

                    {readOnly ? null : isActive ? (
                        <Tooltip title="Disable fee">
                            <IconButton
                                size="small"
                                onClick={toggleActive}
                            >
                                <RemoveCircleOutline
                                    fontSize="small"
                                    color="error"
                                />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip
                            title="Enable fee"
                            onClick={toggleActive}
                        >
                            <IconButton size="small">
                                <CheckCircleOutline
                                    fontSize="small"
                                    color="success"
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </td>
        </tr>
    );
}
