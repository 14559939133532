import {
    Button, FormControlLabel, Paper, Radio, TextField
} from '@mui/material';
import { useState } from 'react';

import { AppraisalUnderwritingTabs } from '../../AppraisalUnderwritingPage';
import UnderwritingQuestionCard from '../UnderwritingQuestionCard';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';

import styles from './AppraisalDetailsSection.module.scss';


interface AppraisalDetailsSectionProps {
    setTab: (tab: AppraisalUnderwritingTabs) => void;
    onTabComplete: (tab: AppraisalUnderwritingTabs, nextTab: AppraisalUnderwritingTabs) => void;
}

export default function AppraisalDetailsSection({ onTabComplete }: AppraisalDetailsSectionProps) {
    const [ showDecliningMarket, setShowDecliningMarket ] = useState(false);
    const [ decliningMarket, setDecliningMarket ] = useState<boolean>();
    const [ conditionRating, setConditionRating ] = useState<string>();
    const [ asIs, setAsIs ] = useState<boolean>();

    return (
        <UnderwritingStepSection title="Appraisal Details">
            <Paper
                variant="outlined"
                className={styles.questionCard}
            >
                <div className={styles.feesAndTaxesFields}>
                    <TextField label="Annual taxes" />

                    <TextField label="Special assessments" />

                    <div>
                        <TextField label="HOA Fees" />

                        <div>
                            <FormControlLabel
                                control={<Radio name="todo" />}
                                label="per month"
                            />

                            <FormControlLabel
                                control={<Radio name="todo" />}
                                label="per year"
                                className={styles.perYearRadio}
                            />
                        </div>
                    </div>
                </div>

                {!showDecliningMarket && (
                    <Button
                        variant="contained"
                        className={styles.nextButton}
                        onClick={() => setShowDecliningMarket(true)}
                    >
                        Next
                    </Button>
                )}
            </Paper>

            <div className={styles.questionRow}>
                {showDecliningMarket && (
                    <UnderwritingYesNoQuestionCard
                        question="Is the market declining?"
                        onAnswered={setDecliningMarket}
                        answer={decliningMarket}
                    />
                )}

                {decliningMarket !== undefined && (
                    <UnderwritingQuestionCard
                        question="What is the condition rating of the property?"
                        onAnswered={setConditionRating}
                        answer={conditionRating}
                        buttons={conditionRatingButtons}
                    />
                )}
            </div>

            <div className={styles.questionRow}>
                {conditionRating !== undefined && (
                    <UnderwritingQuestionCard
                        question="Appraisal was made"
                        onAnswered={setAsIs}
                        answer={asIs}
                        buttons={asIsButtons}
                    />
                )}

                {asIs !== undefined && (
                    <Paper
                        variant="outlined"
                        className={styles.questionCard}
                    >
                        <div className={styles.dateAndValueFields}>
                            <TextField
                                label="Appraisal date"
                                helperText="expires 04/04/2022"
                            />

                            <TextField label="Appraised value" />
                        </div>

                        <Button
                            variant="contained"
                            className={styles.nextButton}
                            onClick={() => onTabComplete('detail', 'review')}
                        >
                            Next
                        </Button>
                    </Paper>
                )}
            </div>
        </UnderwritingStepSection>
    );
}

const asIsButtons = [
    {
        label: 'as is',
        value: true
    },
    {
        label: 'subject to',
        value: false
    }
];

const conditionRatingButtons = [
    {
        label: 'C1',
        value: 'C1'
    },
    {
        label: 'C2',
        value: 'C2'
    },
    {
        label: 'C3',
        value: 'C3'
    },
    {
        label: 'C4',
        value: 'C4'
    },
    {
        label: 'C5',
        value: 'C5'
    },
    {
        label: 'C6',
        value: 'C6'
    }
];
