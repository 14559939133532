import { LLPAMatrixEntry } from '@api';
import { Dispatch, SetStateAction } from 'react';


export default function updateMatrixEntry<T extends keyof LLPAMatrixEntry = 'adjustment'>(
    entry: LLPAMatrixEntry,
    setEntries: Dispatch<SetStateAction<LLPAMatrixEntry[]>>,
    value: LLPAMatrixEntry[T],
    property: T = 'adjustment' as T
) {
    setEntries((entries) => entries.map((entryToTest) => (entry !== entryToTest ? entryToTest : {
        ...entryToTest,
        [property]: value
    })));
}
