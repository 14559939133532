import tinycolor from 'tinycolor2';


export function getGradient(primaryColor: string | undefined, numColors: number): tinycolor.Instance[] | null {
    if (!primaryColor) {
        return null;
    }

    const color = tinycolor(primaryColor);

    return Array(numColors).fill(null).map(() => {
        const clonedColor = color.clone();
        color.spin(360 / numColors);
        return clonedColor;
    });
}

export function transparentize(colorStr: string, opacity: number): string {
    const color = tinycolor(colorStr);
    color.setAlpha(opacity);
    return color.toHex8String();
}
