import { Engineering } from '@mui/icons-material';
import {
    DialogContent, Pagination, Paper
} from '@mui/material';
import {
    DialogActions, IconTypography, RoutedDialog, RoutedDialogProps
} from '@tsp-ui/core/components';
import clsx from 'clsx';

import styles from './PairOffDialog.module.scss';


export default function PairOffDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    return (
        <RoutedDialog
            {...props}
            title="Pair-offs"
            maxWidth="sm"
            fullWidth
        >
            <DialogContent
                className={clsx(styles.filters, {
                    [styles.scrolledDown]: true
                })}
            />

            <DialogContent
                className={styles.mainContent}
            >
                <Paper
                    variant="outlined"
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <IconTypography
                        icon={(
                            <Engineering
                                color="primary"
                                fontSize="large"
                            />
                        )}
                    >
                        Pair-offs are under construction
                    </IconTypography>
                </Paper>
            </DialogContent>

            <DialogActions
                className={styles.dialogActions}
                loading={false}
            >
                <Pagination
                    count={1}
                    page={1}
                    onChange={() => {}}
                    color="primary"
                />
            </DialogActions>
        </RoutedDialog>
    );
}
