import api, { EligibilityGuidelineSetSummary, ExclusionOverlay } from '@api';
import { Button } from '@mui/material';
import { getItemById, useAsyncEffect, useParams } from '@tsp-ui/core';
import { RoutedDialogManager, usePageMessage } from '@tsp-ui/core/components';
import { overlayDialogRoutes } from '@views/admin/investor-eligibility/overlayDialogRoutes';
import { ProgramEligibilityContext, eligibilityGuidelinesDialogRoutes } from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/InternalLoanProgramDetailPage';
import EligibilityGuidelinesCard from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/EligibilityGuidelinesCard';
import LoanProgramDetailsCard from '@views/admin/investors/InvestorDetailPage/LoanProgramDetailPage/components/LoanProgramDetailsCard';
import Page from '@views/components/Page';
import { useCallback, useContext, useState } from 'react';

import { LoanProgramsContext } from '../investors/InternalInvestorDetailRoutes';

import styles from './ClientLoanProgramDetailPage.module.scss';
import { ExclusionOverlayContext } from './InvestorEligibilityManagementPage';
import { InvestorEligibilityContext } from './InvestorEligibilityRoutes';
import ExclusionOverlaySection from './components/ExclusionOverlaySection';
import ManagedEligibilityGuidelinesCard from './components/ManagedEligibilityGuidelinesCard';


const dialogRoutes = {
    ...overlayDialogRoutes,
    ...eligibilityGuidelinesDialogRoutes
};

export function ClientLoanProgramDetailPage() {
    const [ loading, setLoading ] = useState(true);

    const pageMessage = usePageMessage();

    const {
        loanProgramID: loanProgramId, investorID: investorId
    } = useParams<{ investorID: string, loanProgramID: string }>();

    const [ exclusionOverlays, setExclusionOverlays ] = useState<ExclusionOverlay[]>([]);
    useAsyncEffect(useCallback(async () => {
        try {
            setExclusionOverlays(await api.overlay.getExclusionOverlays({
                loanProgramId,
                investorId
            }));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching exclusion overlays.', error);
        }

        setLoading(false);
    }, [
        loanProgramId, pageMessage, setLoading, investorId
    ]));

    const [ eligibilityGuidelineSets, setEligibilityGuidelineSets ] = useState<EligibilityGuidelineSetSummary[]>();
    useAsyncEffect(useCallback(async () => {
        try {
            setEligibilityGuidelineSets(
                await api.investors.getEligibilityGuidelineSets(investorId, loanProgramId)
            );
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching eligibility guideline sets', error);
        }

        setLoading(false);
    }, [
        loanProgramId, pageMessage, setLoading, investorId
    ]));

    const { loanPrograms } = useContext(LoanProgramsContext);
    const { investors } = useContext(InvestorEligibilityContext);

    const loanProgram = getItemById(loanPrograms || [], loanProgramId);

    return (
        <ProgramEligibilityContext.Provider
            value={{
                eligibilityGuidelineSets,
                setEligibilityGuidelineSets
            }}
        >
            <ExclusionOverlayContext.Provider
                value={{
                    overlays: exclusionOverlays,
                    setOverlays: setExclusionOverlays
                }}
            >
                <Page
                    header="Loan Program Details"
                    loading={loading || !loanPrograms || !investors}
                    headerActions={(
                        <Button>
                            Show history
                        </Button>
                    )}
                >
                    <RoutedDialogManager routes={dialogRoutes} />

                    <div className={styles.root}>
                        <div className={styles.column}>
                            <LoanProgramDetailsCard />

                            {loanProgram?.baseLoanProgramId ? (
                                <ManagedEligibilityGuidelinesCard />
                            ) : (
                                <EligibilityGuidelinesCard />
                            )}
                        </div>

                        <ExclusionOverlaySection overlays={exclusionOverlays} />
                    </div>
                </Page>
            </ExclusionOverlayContext.Provider>
        </ProgramEligibilityContext.Provider>
    );
}
