import { CustomerStatus, CustomerSummary } from '@api';
import { Check, WarningAmber } from '@mui/icons-material';
import { Button, Paper, Tooltip } from '@mui/material';
import { SubtleLink } from '@tsp-ui/core/components';

import BranchTooltip from './BranchTooltip';
import styles from './CustomerCard.module.scss';
import CustomerDetails from './CustomerDetails';
import StatusTooltip from './StatusTooltip';


interface CustomerCardProps {
    customer: CustomerSummary;
}

export function CustomerCard({ customer }: CustomerCardProps) {
    const {
        id, name, status,  dbaNames, nmlsId, branches, pendingSteps
    } = customer;

    return (
        <Paper
            className={styles.card}
            elevation={0}
        >
            <div>
                <SubtleLink to={id}>
                    {name}
                </SubtleLink>

                <CustomerDetails
                    dba={dbaNames?.[0] !== name ? dbaNames?.[0] : undefined}
                    nmlsID={nmlsId}
                />
            </div>

            <div className={styles.cardButtons}>
                {status === CustomerStatus.PENDING ? (
                    (!pendingSteps || pendingSteps.length === 0) ? (
                        <Tooltip
                            title="Click to activate"
                            enterDelay={0}
                        >
                            <Button
                                startIcon={<Check color="success" />}
                                color="inherit"
                            >
                                Complete
                            </Button>
                        </Tooltip>
                    ) : (
                        <StatusTooltip pendingSteps={pendingSteps}>
                            <Button
                                startIcon={<WarningAmber color="warning" />}
                                color="inherit"
                            >
                                {pendingSteps.length} items left
                            </Button>
                        </StatusTooltip>
                    )
                ) : (branches && branches.length > 0) && (
                    <BranchTooltip
                        mainName={name}
                        branches={branches}
                    >
                        <Button>
                            {branches.length}{branches.length > 1 ? ' branches' : ' branch'}
                        </Button>
                    </BranchTooltip>
                )}
            </div>
        </Paper>
    );
}
