import { rest } from 'msw';

import {
    Address, ClientDetail, ClientProductConfig, ClientStatus, ContactResponsibility, ProductType, State
} from '..';
import { getMockUrl } from '../../mocks/getMockUrl';
import { internalUsers } from '../user/user-mocks';

import { mocks as contactsMocks } from './contacts/contacts-mocks';


export const mocks = [
    rest.get(getMockUrl('/client'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(clients)
    ))),
    rest.post(getMockUrl('/client'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as ClientDetail,
            id: `${clientID++}`,
            status: 'PENDING'
        })
    ))),
    rest.put(getMockUrl('/client'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(clients[0])
    ))),
    rest.get(getMockUrl('/client/productConfig'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(productConfig)
    ))),
    rest.get(getMockUrl('/client/:clientID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(clients[0])
    ))),
    rest.put(getMockUrl('/client/:clientID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.delay(500),
        ctx.json(req.body)
    ))),
    rest.get(getMockUrl('/client/:clientID/authorizedUsers'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(internalUsers.slice(0, 2))
    ))),
    rest.post(getMockUrl('/client/:clientID/authorizedUsers'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    rest.delete(getMockUrl('/client/:clientID/authorizedUsers/:userID'), (req, res, ctx) => (res(
        ctx.status(200)
    ))),
    ...contactsMocks
];

const address: Address = {
    street: '1234 Mystery Lane',
    city: 'Somewhere',
    state: State.SC,
    zip: '54321'
};

const contactBase = {
    id: '1',
    isPrimary: true,
    responsibilities: [ ContactResponsibility.SENIOR_MANAGER ],
    title: 'Title'
};

let clientID = 1;

const clients : ClientDetail[] = [
    {
        id: `${clientID++}`,
        nmlsId: '12345',
        name: 'The best client',
        shortName: 'Best client',
        code: 'BECLIE',
        isInternal: false,
        status: ClientStatus.PENDING,
        address,
        primaryContact: {
            ...contactBase,
            name: 'Betsie Best',
            phone: '5555555555',
            email: 'betsie@best.com'
        }
    },
    {
        id: `${clientID++}`,
        nmlsId: '23456',
        name: 'The second best client',
        shortName: 'Second client',
        code: 'SBCLIE',
        isInternal: false,
        status: ClientStatus.ACTIVE,
        address,
        primaryContact: {
            ...contactBase,
            name: 'Stuart Second',
            phone: '5555555555',
            email: 'stuart@second.com'
        }
    },
    {
        id: `${clientID++}`,
        nmlsId: '99999',
        name: 'The worst client',
        shortName: 'Worst client',
        code: 'WOCLIE',
        isInternal: false,
        status: ClientStatus.INACTIVE,
        address,
        primaryContact: {
            ...contactBase,
            name: 'Wallie Worst',
            phone: '5555555555',
            email: 'wallie@worst.com'
        }
    }
];

const productConfig: ClientProductConfig = {
    availableLockPeriods: [
        7, 10, 15, 30, 45, 60, 90
    ],
    availableProducts: [
        {
            id: '1',
            name: '10 year conventional',
            productType: ProductType.CONVENTIONAL
        },
        {
            id: '2',
            name: '20 year conventional',
            productType: ProductType.CONVENTIONAL
        },
        {
            id: '3',
            name: '30 year conventional',
            productType: ProductType.CONVENTIONAL
        },
        {
            id: '4',
            name: '10 year FHA',
            productType: ProductType.FHA
        },
        {
            id: '5',
            name: '20 year FHA',
            productType: ProductType.FHA
        },
        {
            id: '6',
            name: '10 year jumbo',
            productType: ProductType.NON_AGENCY
        },
        {
            id: '7',
            name: '10 year USDA',
            productType: ProductType.USDA
        },
        {
            id: '8',
            name: '10 year VA',
            productType: ProductType.VA
        }
    ]
};
