import { AmortizationType } from '../enums/amorTypeEnum';
import { AutomatedUwSystem } from '../enums/automatedUwSystemEnum';
import { BooleanEnum } from '../enums/booleanEnum';
import { InvestorEnum, LoanProperty } from '../enums/loanPropertyEnum';
import { LoanPurpose } from '../enums/loanPurposeEnum';
import { LoanType } from '../enums/loanTypeEnum';
import { NumUnits } from '../enums/numUnitsEnum';
import { NumericFieldOperator } from '../enums/numericFieldOperatorEnum';
import { OccupancyType } from '../enums/occupancyTypeEnum';
import { PropertyType } from '../enums/propertyTypeEnum';
import { SpecialtyProduct } from '../enums/specialtyProductEnum';
import { State } from '../enums/stateEnum';


export enum NumericMatrixEntryType {
    RANGE = 'RANGE',
    COMPARISON = 'COMPARISON'
}

export type NumericMatrixEntry = {
    type: NumericMatrixEntryType.RANGE;
    min: number;
    max: number;
} | {
    type: NumericMatrixEntryType.COMPARISON;
    operator: NumericFieldOperator;
    value: number;
};

export type LLPAMatrixEntry = Partial<{
    [LoanProperty.LOAN_TYPE]: LoanType[];
    [LoanProperty.OCCUPANCY]: OccupancyType[];
    [LoanProperty.PURPOSE]: LoanPurpose[];
    [LoanProperty.AMORT_TYPE]: AmortizationType[];
    [LoanProperty.PROPERTY_TYPE]: PropertyType[];
    [LoanProperty.UNITS]: NumUnits[];
    [LoanProperty.FICO]: NumericMatrixEntry;
    [LoanProperty.LTV]: NumericMatrixEntry;
    [LoanProperty.CLTV]: NumericMatrixEntry;
    [LoanProperty.DTI]: NumericMatrixEntry;
    [LoanProperty.TERM]: NumericMatrixEntry;
    [LoanProperty.AUS]: AutomatedUwSystem[];
    [LoanProperty.MIN_RESERVES_MONTHS]: NumericMatrixEntry;
    [LoanProperty.HIGH_BALANCE]: BooleanEnum[];
    [LoanProperty.SPECIALTY_PRODUCT]: SpecialtyProduct[];
    [LoanProperty.EFFECTIVE_DATE]: never;
    [LoanProperty.EXPIRATION_DATE]: never;
    [LoanProperty.STATE]: State[];
    [LoanProperty.LOAN_AMOUNT]: NumericMatrixEntry;
    [LoanProperty.INVESTOR]: InvestorEnum;
}> & {
    adjustment: string;
};
