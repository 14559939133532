export enum ProductType {
    CONVENTIONAL = 'CONVENTIONAL',
    FHA = 'FHA',
    VA = 'VA',
    USDA = 'USDA',
    NON_AGENCY = 'NON_AGENCY'
}

export const productTypeDisplay = {
    [ProductType.CONVENTIONAL]: 'Conventional',
    [ProductType.FHA]: 'FHA',
    [ProductType.VA]: 'VA',
    [ProductType.USDA]: 'USDA',
    [ProductType.NON_AGENCY]: 'Non-Agency'
};
