import { useState } from 'react';

import ConditionsSummary from './components/ConditionsSummary';
import UnderwritingPageTemplate from './components/UnderwritingPageTemplate';
import UnderwritingStepCard from './components/UnderwritingStepCard';
import SalesContractSection from './components/sales-contract/SalesContractSection';
import SalesContractSellerDetailsSection from './components/sales-contract/SalesContractSellerDetailsSection';

// TODO use react-router for this
export type SalesContractUnderwritingTabs = 'summary' | 'contract' | 'seller-details';

export default function SalesContractUnderwritingPage() {
    const [ tab, setTab ] = useState<SalesContractUnderwritingTabs>('summary');
    const [ finishedTabs, setFinishedTabs ] = useState<SalesContractUnderwritingTabs[]>([]);

    function tabComplete(tab: SalesContractUnderwritingTabs, nextTab: SalesContractUnderwritingTabs) {
        setFinishedTabs(finishedTabs.concat(tab));
        setTab(nextTab);
    }

    return (
        <UnderwritingPageTemplate
            conditionsShown={tab === 'summary'}
            onConditionsClick={() => setTab('summary')}
            relevantDocs={[ 'Sales contract.pdf' ]}
            stepCards={(
                <>
                    <UnderwritingStepCard
                        active={tab === 'contract'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('contract')}
                        label="Sales contract"
                        onClick={() => setTab('contract')}
                    />

                    <UnderwritingStepCard
                        active={tab === 'seller-details'}
                        conditionIDs={[]}
                        done={finishedTabs.includes('seller-details')}
                        label="Seller details"
                        onClick={() => setTab('seller-details')}
                    />
                </>
            )}
        >
            {tab === 'summary' ? (
                <ConditionsSummary header="Sales contract conditions" />
            ) : tab === 'contract' ? (
                <SalesContractSection
                    onTabComplete={tabComplete}
                    setTab={setTab}
                />
            ) : tab === 'seller-details' ? (
                <SalesContractSellerDetailsSection
                    onTabComplete={tabComplete}
                    setTab={setTab}
                />
            ) : null}
        </UnderwritingPageTemplate>
    );
}
