import { Button, Typography } from '@mui/material';
import { AllowPartialArrayItems } from '@tsp-ui/core/utils';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { ChecklistStepFormValues } from './ChecklistItemCard';
import styles from './SpecialChecklistSection.module.scss';


export interface SpecialChecklistCardProps {
    onRemove: () => void;
    index: number;
}

interface SpecialChecklistSectionProps {
    arrayName: 'agencies' | 'investors' | 'warehouses' | 'insurances';
    title: string;
    addButtonLabel: string;
    SpecialChecklistCard: (props: SpecialChecklistCardProps) => JSX.Element;
}

export function SpecialChecklistSection({
    arrayName, title, addButtonLabel, SpecialChecklistCard
}: SpecialChecklistSectionProps) {
    const { fields, append, remove } = useFieldArray<AllowPartialArrayItems<ChecklistStepFormValues>>(
        { name: arrayName }
    );

    const { reset, getValues } = useFormContext();

    useEffect(() => {
        if (fields.length === 0) {
            append({});
        }
    }, [ fields, append ]);

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <Typography color="textSecondary">
                    {title} *
                </Typography>

                <Button onClick={() => append({})}>{addButtonLabel}</Button>
            </div>

            <div className={styles.cardDisplay}>
                {fields.map((field, index) => (
                    <SpecialChecklistCard
                        key={field.id}
                        index={index}
                        onRemove={() => {
                            remove(index);
                            reset(getValues()); // this resets the default values of the field to undefined
                        }}
                    />
                ))}
            </div>
        </div>
    );
}
