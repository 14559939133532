import { rest } from 'msw';

import { CustomerPendingSteps, CustomerStatus } from '..';
import { getMockUrl } from '../../mocks/getMockUrl';
import { internalUsers } from '../user/user-mocks';

import { branches, mocks as branchesMocks } from './branches/branches-mocks';
import { mocks as checklistMocks } from './checklist/checklist-mocks';
import { mocks as compensationMocks } from './compensation/compensation-mocks';
import { contacts, mocks as contactsMocks } from './contacts/contacts-mocks';
import { CustomerDetails, CustomerPendingStep, CustomerSummary } from './customer-api';
import { productConfiguration, mocks as productsMocks } from './eligibleProducts/eligibleProducts-mocks';
import { mocks as eventHistoryMocks } from './eventHistory/eventHistory-mocks';
import { mocks as licensesMocks } from './licenses/licenses-mocks';
import { mocks as wireInfoMocks } from './wireInfo/wireInfo-mocks';


let newCustomerID = 100;

export const mocks = [
    rest.get(getMockUrl('/customer'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(customerSummaryResults)
    ))),
    rest.post(getMockUrl('/customer'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as CustomerDetails,
            id: `${newCustomerID++}`
        })
    ))),
    rest.get(getMockUrl('/customer/:customerID'), (req, res, ctx) => (res(
        ctx.delay(1000),
        ctx.status(200),
        ctx.json(customersMap[req.params.customerID as string])
    ))),
    rest.put(getMockUrl('/customer/:customerID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(req.body)
    ))),
    ...branchesMocks,
    ...checklistMocks,
    ...compensationMocks,
    ...contactsMocks,
    ...eventHistoryMocks,
    ...licensesMocks,
    ...productsMocks,
    ...wireInfoMocks
];

const somePendingSteps: CustomerPendingStep[] = [
    {
        id: '5',
        name: CustomerPendingSteps.COMP_FEES,
        isRequired: true
    },
    {
        id: '6',
        name: CustomerPendingSteps.LICENSES,
        isRequired: true
    },
    {
        id: '7',
        name: CustomerPendingSteps.DUE_DILIGENCE,
        isRequired: true
    },
    {
        id: '8',
        name: CustomerPendingSteps.WIRE_INSTRUCTIONS,
        isRequired: false
    },
    {
        id: '8',
        name: CustomerPendingSteps.ELIGIBLE_PRODUCTS,
        isRequired: false
    }
];

const customerSummaryResults: CustomerSummary[] = [
    {
        id: '1001',
        name: 'Pending Bank',
        status: CustomerStatus.PENDING,

        dbaNames: [ 'Pending Bank, Inc.' ],
        nmlsId: '555555',
        pendingSteps: somePendingSteps
    },
    {
        id: '1000',
        name: 'ABC Mortgage',
        status: CustomerStatus.ACTIVE,

        dbaNames: [ 'ABC Mortgage, Inc.' ],
        nmlsId: '654321',

        branches
    },
    {
        id: '1002',
        name: 'Inactive Bank',
        status: CustomerStatus.INACTIVE,

        nmlsId: '123456'
    }
];

export const customer: CustomerDetails = {
    id: '1',
    name: 'American Bank',
    nmlsId: '123456',
    taxID: '1234567890',
    address: branches[0].address,
    dbaNames: [ 'AmCap Mortgage, Ltd.', 'American Bank LTD' ],
    productConfiguration,
    primaryContact: contacts[0],
    counterpartyAnalystId: '1',
    primaryAccountExecId: '2',
    secondaryAccountExecId: '3',
    accountManagerId: '4',
    status: CustomerStatus.PENDING
};

const customersMap: { [key: string]: CustomerDetails} = {
    [customerSummaryResults[0].id]: {
        ...customerSummaryResults[0],
        taxID: '1234567890',
        address: branches[0].address,
        productConfiguration,
        primaryContact: contacts[0],
        counterpartyAnalystId: internalUsers[0].id,
        primaryAccountExecId: internalUsers[1].id,
        secondaryAccountExecId: internalUsers[2].id,
        accountManagerId: internalUsers[3].id
    },
    [customerSummaryResults[1].id]: {
        ...customerSummaryResults[1],
        taxID: '1234567890',
        address: branches[0].address,
        productConfiguration,
        primaryContact: contacts[0],
        counterpartyAnalystId: internalUsers[0].id,
        primaryAccountExecId: internalUsers[1].id,
        secondaryAccountExecId: internalUsers[2].id,
        accountManagerId: internalUsers[3].id
    },
    [customerSummaryResults[2].id]: {
        ...customerSummaryResults[2],
        taxID: '1234567890',
        address: branches[0].address,
        productConfiguration,
        primaryContact: contacts[0],
        counterpartyAnalystId: internalUsers[0].id,
        primaryAccountExecId: internalUsers[1].id,
        secondaryAccountExecId: internalUsers[2].id,
        accountManagerId: internalUsers[3].id
    }
};
