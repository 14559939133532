import { LicenseType, State } from '../..';
import { apiUtils } from '../../api-utils';


export function getLicenses(customerID: string): Promise<License[]> {
    return apiUtils.get(`/customer/${customerID}/license`);
}

export function deleteLicense(customerID: string, licenseID: string): Promise<void> {
    return apiUtils.delete(`/customer/${customerID}/license/${licenseID}`);
}

export function renewLicense(customerID: string, licenseID: string, renewedThroughYear: number): Promise<License> {
    return apiUtils.patch(`/customer/${customerID}/license/${licenseID}`, { renewedThroughYear });
}

export function addLicense(customerID: string, license: Omit<License, 'id'>): Promise<License> {
    return apiUtils.post(`/customer/${customerID}/license`, license);
}

export function editLicense(customerID: string, license: License): Promise<License> {
    return apiUtils.put(`/customer/${customerID}/license/${license.id}`, license);
}

export interface License {
    id: string;
    customerId: number;
    state: State;
    type: LicenseType;
    licenseNumber: string;
    renewedThroughYear: number;
}
