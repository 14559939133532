import {
    Button, Paper, TextField, Typography
} from '@mui/material';
import { CardTable } from '@tsp-ui/core/components';
import loanDetailsStyles from '@views/loans/LoanDetailPage.module.scss';
import { useEffect, useState } from 'react';

import { CreditUnderwritingTabs } from '../../CreditUnderwritingPage';
import UnderwritingStepSection from '../UnderwritingStepSection';
import UnderwritingYesNoQuestionCard from '../UnderwritingYesNoQuestionCard';

import styles from './MortgageHistorySection.module.scss';


interface MortgageHistorySectionProps {
    setTab: (tab: CreditUnderwritingTabs) => void;
    onTabComplete: (tab: CreditUnderwritingTabs, nextTab: CreditUnderwritingTabs) => void;
}

export default function MortgageHistorySection({ onTabComplete }: MortgageHistorySectionProps) {
    const [ hasMortgageHistory, setHasMortgageHistory ] = useState<boolean>();
    const [ hasLatePayments, setHasLatePayments ] = useState<boolean>();

    useEffect(() => {
        if (hasLatePayments === false) {
            onTabComplete('history', 'fraud');
        }
    }, [
        hasMortgageHistory, hasLatePayments, onTabComplete
    ]);

    return (
        <UnderwritingStepSection title="Mortgage history">
            <UnderwritingYesNoQuestionCard
                question="Is there satisfactory 12 month mortgage payment history for all properties owned?"
                onAnswered={setHasMortgageHistory}
                answer={hasMortgageHistory}
            />

            {hasMortgageHistory === false && (
                <Paper
                    variant="outlined"
                    className={loanDetailsStyles.card}
                >
                    <Typography color="textSecondary">
                        Properties requiring mortgage history
                    </Typography>

                    <CardTable
                        className={loanDetailsStyles.editableCardTable}
                        headers={[ 'Property address' ]}
                    >
                        <tr>
                            <td width="100%">
                                <TextField
                                    variant="standard"
                                    size="small"
                                    autoFocus
                                    fullWidth
                                />
                            </td>
                        </tr>
                    </CardTable>

                    <div className={loanDetailsStyles.buttons}>
                        <Button>
                            Add property
                        </Button>

                        <Button
                            variant="contained"
                            onClick={() => onTabComplete('history', 'fraud')}
                        >
                            Next
                        </Button>
                    </div>
                </Paper>
            )}

            {hasMortgageHistory && (
                <UnderwritingYesNoQuestionCard
                    question="Does any mortgage have late payments in the last 12 months?"
                    onAnswered={setHasLatePayments}
                    answer={hasLatePayments}
                />
            )}

            {hasLatePayments && (
                <Paper
                    variant="outlined"
                    className={styles.questionCard}
                >
                    <Typography>
                        How many late payments are there in each category?
                    </Typography>

                    <div className={styles.latePaymentFields}>
                        <TextField
                            label="30 days late"
                            size="small"
                        />

                        <TextField
                            label="60 days late"
                            size="small"
                        />

                        <TextField
                            label="90 days late"
                            size="small"
                        />

                        <TextField
                            label="120 days late"
                            size="small"
                        />
                    </div>

                    <div className={styles.buttons}>
                        <Button
                            variant="contained"
                            onClick={() => onTabComplete('history', 'fraud')}
                        >
                            Next
                        </Button>
                    </div>
                </Paper>
            )}
        </UnderwritingStepSection>
    );
}
