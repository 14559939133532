
import { License } from '@api';
import { Button, DialogContent } from '@mui/material';
import { DialogActions, FilledSection } from '@tsp-ui/core/components';

import LicenseCard from './LicenseCard';
import styles from './LicenseListDialogContent.module.scss';


export interface LicenseListDialogContentProps {
    licenses: License[];
    onAddClick: () => void;
    onDelete: (id: string) => void;
    onEditClick: (license: License) => void;
    onRenew: (id: string, renewedThroughYear: number) => void;
    loading: boolean;
}

export default function LicenseListDialogContent({
    licenses, onAddClick, onDelete, onEditClick, onRenew, loading
}: LicenseListDialogContentProps) {
    const currentYear = new Date().getFullYear();

    const active = licenses.filter(license => license.renewedThroughYear >= currentYear);
    const expired = licenses.filter(license => license.renewedThroughYear < currentYear);

    return (
        <>
            <DialogContent>
                <div className={styles.root}>
                    <FilledSection
                        variant="light"
                        header="Active"
                        className={styles.section}
                    >
                        {active.map((license) => (
                            <LicenseCard
                                key={license.id}
                                license={license}
                                onDelete={onDelete}
                                onEditClick={onEditClick}
                                onRenew={onRenew}
                            />
                        ))}
                    </FilledSection>

                    {expired.length > 0 && (
                        <FilledSection
                            header="Expired"
                            variant="light"
                            className={styles.section}
                        >
                            {expired.map((license) => (
                                <LicenseCard
                                    key={license.id}
                                    license={license}
                                    onDelete={onDelete}
                                    onEditClick={onEditClick}
                                    onRenew={onRenew}
                                />
                            ))}
                        </FilledSection>
                    )}
                </div>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    variant="contained"
                    onClick={onAddClick}
                    disabled={loading}
                >
                    Add License
                </Button>
            </DialogActions>
        </>
    );
}
