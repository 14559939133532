import { Contact, ContactTitle, contactTitleDisplay } from '@api';
import { Edit, RemoveCircleOutline, Star } from '@mui/icons-material';
import {
    IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { isEnumValue, useConfirm } from '@tsp-ui/core/utils';

import ContactDisplay from '../ContactDisplay';
import { UserOrContact } from '../UsersAndContactsDialog';

import styles from './ContactCard.module.scss';


interface ContactCardProps {
    contact: Contact;
    onDelete: (id: string, type: UserOrContact) => void;
    onEditClick: (contact: Contact) => void;
}

export default function ContactCard({ contact, onDelete, onEditClick }: ContactCardProps) {
    const { isPrimary, title } = contact;

    const confirm = useConfirm();

    async function handleDelete() {
        if (await confirm('Are you sure you want to delete this contact?')) {
            onDelete(contact.id, 'contact');
        }
    }

    return (
        <Paper
            variant="outlined"
            className={styles.root}
        >
            <Typography
                className={styles.description}
                color="textSecondary"
            >
                {isPrimary && (
                    <Tooltip title="Primary contact">
                        <Star
                            color="primary"
                            fontSize="small"
                            className={styles.primaryContactIcon}
                        />
                    </Tooltip>
                )}

                {isEnumValue(title, ContactTitle) ? contactTitleDisplay[title] : title}
            </Typography>

            <div className={styles.actionsContainer}>
                <Tooltip title="Edit contact">
                    <IconButton onClick={() => onEditClick(contact)}>
                        <Edit color="secondary" />
                    </IconButton>
                </Tooltip>

                <Tooltip title={isPrimary ? 'The primary contact cannot be deleted' : 'Delete contact'}>
                    <span>
                        <IconButton
                            edge="end"
                            disabled={isPrimary}
                            onClick={handleDelete}
                        >
                            <RemoveCircleOutline color={isPrimary ? 'disabled' : 'error'} />
                        </IconButton>
                    </span>
                </Tooltip>
            </div>

            <ContactDisplay
                className={styles.contactInfoContainer}
                contact={contact}
            />
        </Paper>
    );
}
