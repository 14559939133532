export enum ChecklistStepType {
    AGENCY = 'AGENCY',
    INSURANCE = 'INSURANCE',
    WAREHOUSE = 'WAREHOUSE',
    INVESTORS = 'INVESTORS',
    DEFAULT = 'DEFAULT'
}

export const checklistStepTypeDisplay = {
    [ChecklistStepType.AGENCY]: 'Agency',
    [ChecklistStepType.INSURANCE]: 'Insurance',
    [ChecklistStepType.WAREHOUSE]: 'Warehouse',
    [ChecklistStepType.INVESTORS]: 'Investors',
    [ChecklistStepType.DEFAULT]: 'Default'
};
