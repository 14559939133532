import api, {
    CommitmentType,
    LoanPricingResult,
    LoanPricingResultDetail,
    LockPeriod,
    RegistrationType,
    commitmentTypeDisplay,
    registrationTypeDisplay
} from '@api';
import { OpenInNew } from '@mui/icons-material';
import {
    Alert, Button, IconButton, Link as MuiLink, Tooltip, Typography
} from '@mui/material';
import {
    ButtonBar, CurrencyTypography, DateTypography, ExpandableCard, LabelGroup, LabeledValue, Loader
} from '@tsp-ui/core/components';
import { replaceItemByKey, useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { addDays, parseISO } from 'date-fns';
import {
    Dispatch, ReactNode, SetStateAction, useCallback, useRef, useState
} from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import ExpiredButton from './ExpiredButton';
import styles from './LoanPricingResultCard.module.scss';
import PricingResultIneligibleProductsTable from './PricingResultIneligibleProductsTable';
import PricingResultProductsTable from './PricingResultProductsTable';


interface LoanPricingResultCardProps {
    className?: string;
    loanPricingResult: LoanPricingResult;
    updateLoanPricingResults: Dispatch<SetStateAction<LoanPricingResult[] | undefined>>;
    updateFloatedLoans: Dispatch<SetStateAction<LoanPricingResult[] | undefined>>;
    index: number;
}

// TODO post-demo we need a LoanResultCard as a base component for
// LoanPricingResultCard, LoanPipelineResultCard, and LoanDashboardResultCard
export default function LoanPricingResultCard({
    className, loanPricingResult, updateLoanPricingResults, updateFloatedLoans, index, ...transitionProps
}: LoanPricingResultCardProps) {
    const [ showIneligibleProducts, setShowIneligibleProducts ] = useState(false);
    const [ loanPricingResultDetail, setLoanPricingResultDetail ] = useState<LoanPricingResultDetail>();
    const [ commitmentType, setCommitmentType ] = useState(CommitmentType.BEST_EFFORT);
    const [ lockPeriod, setLockPeriod ] = useState<LockPeriod>();
    const [ loading, setLoading ] = useState(false);

    const nodeRef = useRef<HTMLDivElement | null>(null);

    const pageMessage = usePageMessage();

    const {
        expirationDate, loanId, rateSheetId, pricedDate
    } = loanPricingResult;
    const isExpired = parseISO(expirationDate) < new Date();

    const lockExpirationDate = addDays(new Date(), lockPeriod || 0).toISOString();

    const products = loanPricingResultDetail?.products.filter(
        product => product.commitmentType === commitmentType && product.lockPeriod === lockPeriod
    ) || [];

    const lockPeriodOptions = loanPricingResultDetail?.products.reduce((previousValue, currentValue) => {
        if (currentValue.commitmentType === commitmentType) {
            previousValue[`${currentValue.lockPeriod}`] = currentValue.lockPeriod;
        }

        return previousValue;
    }, {} as {[key: string]: LockPeriod}) || {};

    async function registerLoan(productID: string, registrationType: RegistrationType) {
        if (!lockPeriod) {
            return;
        }

        setLoading(true);

        try {
            // TODO make this return the Loan
            await api.loans.registerLoan({
                loanId,
                registrationType,
                lockPeriod,
                commitmentType,
                productId: productID
            });

            setLoading(false); // set loading before component unmounts

            updateLoanPricingResults((loanPricingResults) => (
                loanPricingResults?.filter(pricingResult => pricingResult.loanId !== loanId)
            ));

            if (registrationType === RegistrationType.FLOAT) {
                updateFloatedLoans((floatedLoans) => [
                    loanPricingResult,
                    ...floatedLoans || []
                ]);
            } else {
                updateFloatedLoans((floatedLoans) => (
                    floatedLoans?.filter(floatedLoan => floatedLoan.loanId !== loanId)
                ));
            }

            pageMessage.success(`Loan ${registrationTypeDisplay[registrationType]}`);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while registering the loan', error);

            setLoading(false);
        }
    }

    async function repriceLoan() {
        setLoading(true);

        try {
            const newPricingResult = await api.pricing.repriceLoan(loanId);

            updateLoanPricingResults(
                (loanPricingResults) => loanPricingResults && replaceItemByKey(loanPricingResults, newPricingResult, 'loanId')
            );

            updateFloatedLoans(
                floatedLoans => floatedLoans && replaceItemByKey(floatedLoans, newPricingResult, 'loanId')
            );

            setLoading(false);

            pageMessage.success('Loan repriced');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while repricing the loan', error);

            setLoading(false);
        }
    }

    useAsyncEffect(useCallback(async () => {
        setLoading(true);
        setLoanPricingResultDetail(undefined);

        try {
            const pricingDetail = await api.pricing.getPricingResultDetail(loanId);

            setLoanPricingResultDetail(pricingDetail);
            setLockPeriod(pricingDetail.defaultLockPeriod);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the pricing result details', error);
        }

        setLoading(false);
    }, [ loanId, pageMessage ]));

    const handleButtonBarChange = useCallback((newValue: string) => {
        if (newValue) {
            setLockPeriod(parseInt(newValue));
        }
    }, [ ]);

    return (
        <CSSTransition
            {...transitionProps} // required when CSSTransition is not a direct child of TransitionGroup
            nodeRef={nodeRef}
            exit={false}
            timeout={250 + (index * 50)}
            classNames={{
                enter: styles.pricingResultCardTransition_enter,
                enterActive: styles.pricingResultCardTransition_enter_active
            }}
        >
            <ExpandableCard
                nodeRef={nodeRef}
                className={className}
                expandedContent={loading ? (
                    <Loader
                        className={styles.initialLoader}
                        loading={loading}
                    />
                ) : (loanPricingResultDetail && lockPeriod) ? (
                    <>
                        <div className={styles.pricingControls}>
                            <ButtonBar
                                label="Lock period"
                                options={lockPeriodOptions}
                                onValueChange={handleButtonBarChange}
                                defaultValue={lockPeriod.toString()}
                            />

                            <LabeledValue
                                label="Lock expiration"
                                value={(
                                    <DateTypography
                                        date={lockExpirationDate}
                                        component="span"
                                        variant="body2"
                                    />
                                )}
                                classNames={{ value: styles.expirationDate }}
                                variant="vertical"
                            />

                            <ButtonBar
                                label="Commitment type"
                                options={commitmentTypeDisplay}
                                onValueChange={newValue => setCommitmentType(newValue as CommitmentType)}
                                defaultValue={commitmentType}
                                className={styles.commitmentType}
                            />
                        </div>

                        {products.length ? (
                            <PricingResultProductsTable
                                isExpired={isExpired}
                                products={products}
                                onRegister={registerLoan}
                            />
                        ) : (
                            <Typography
                                className={styles.noProductsFound}
                                variant="body2"
                            >
                                No products found for the given lock period and commitment type.
                            </Typography>
                        )}

                        {loanPricingResultDetail.ineligibleProducts?.length && (
                            <Button
                                size="small"
                                className={styles.ineligibleProductsButton}
                                onClick={() => setShowIneligibleProducts(!showIneligibleProducts)}
                            >
                                {`${showIneligibleProducts ? 'Hide' : 'View'} ineligible products (${loanPricingResultDetail.ineligibleProducts.length})`}
                            </Button>
                        )}

                        {showIneligibleProducts && (
                            <PricingResultIneligibleProductsTable
                                ineligibleProducts={loanPricingResultDetail.ineligibleProducts}
                            />
                        )}
                    </>
                ) : (
                    <Alert
                        severity="warning"
                        className={styles.alert}
                    >
                        An error occurred while fetching product details
                    </Alert>
                )}
                indentContent
            >
                <LoanResultMainRow
                    loanPricingResult={loanPricingResult}
                    onOpenLoanData={() => {}}
                >
                    <div className={styles.pricingDetails}>
                        {isExpired && (
                            <ExpiredButton
                                onReprice={repriceLoan}
                                expirationDate={expirationDate}
                            />
                        )}

                        <LabelGroup>
                            <LabeledValue
                                label="Rate sheet ID:"
                                value={rateSheetId}
                            />

                            <LabeledValue
                                label="Priced at:"
                                value={(
                                    <DateTypography
                                        time
                                        date={pricedDate}
                                        component="span"
                                        variant="body2"
                                    />
                                )}
                            />
                        </LabelGroup>
                    </div>
                </LoanResultMainRow>
            </ExpandableCard>
        </CSSTransition>
    );
}

interface LoanResultMainRowProps {
    children: ReactNode;
    loanPricingResult: LoanPricingResult;
    onOpenLoanData: () => void;
    isLink?: boolean;
}

export function LoanResultMainRow({
    children, loanPricingResult, onOpenLoanData, isLink
}: LoanResultMainRowProps) {
    const {
        loanNumber, loanAmount, borrowerName, interestRate, loanId
    } = loanPricingResult;

    return (
        <div className={styles.mainRow}>
            <Typography
                color="textSecondary"
                className={styles.loanNumber}
            >
                Loan #

                {isLink ? (
                    <MuiLink
                        component={Link}
                        to={`loans/${loanId}`}
                    >
                        {loanNumber}
                    </MuiLink>
                )
                    : <b>{loanNumber}</b>}

                <Tooltip title="View loan data">
                    <IconButton onClick={onOpenLoanData}>
                        <OpenInNew
                            color="secondary"
                            fontSize="small"
                        />
                    </IconButton>
                </Tooltip>
            </Typography>

            <div>
                <CurrencyTypography
                    value={loanAmount}
                    variant="body2"
                />

                <Typography variant="body2">
                    <span className={styles.hidden}>$</span>{interestRate.toFixed(3)}%
                </Typography>
            </div>

            <LabeledValue
                label="Borrower"
                value={borrowerName}
                variant="vertical"
            />

            {children}
        </div>
    );
}
