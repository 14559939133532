import api, { LoanPricingResult, PendingUpload, WebSocketEventType } from '@api';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import {
    CircularProgress, Fade, IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import { usePageMessage } from '@tsp-ui/core/utils';
import clsx from 'clsx';
import {
    Dispatch, ReactNode, SetStateAction, forwardRef, useEffect, useRef, useState
} from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';

import styles from './PendingUploadsCard.module.scss';


interface PendingUploadsCardProps {
    pendingUploads?: PendingUpload[];
    setLoanPricingResults: Dispatch<SetStateAction<LoanPricingResult[] | undefined>>;
}

const FILE_REMOVAL_DELAY = 3000;

export default function PendingUploadsCard({ pendingUploads, setLoanPricingResults }: PendingUploadsCardProps) {
    const pageMessage = usePageMessage();

    const [ files, setFiles ] = useState(pendingUploads || []);
    const uploadsComplete = !!files.length && files.every(({ status }) => status !== 'pending');

    useEffect(() => {
        const unsubFileComplete = api.webSocket.subscribe(
            WebSocketEventType.UPLOAD_COMPLETE,
            (data) => {
                setFiles((files) => files.map((file) => (
                    data.fileId === file.fileId ? data : file
                )));
            }
        );

        const unsubPricingComplete = api.webSocket.subscribe(
            WebSocketEventType.PRICING_COMPLETE,
            (data) => {
                setLoanPricingResults((loanPricingResults) => (loanPricingResults || []).concat(data));
            }
        );

        return () => {
            unsubFileComplete();
            unsubPricingComplete();
        };
    }, [ setLoanPricingResults ]);

    useEffect(() => {
        setFiles((files) => [ ...(pendingUploads || []), ...files ]);
    }, [ pendingUploads ]);

    useEffect(() => {
        if (uploadsComplete) {
            pageMessage.success('All pending uploads have been processed');

            setTimeout(() => {
                setFiles((files) => files.filter(({ status }) => status === 'error'));
            }, FILE_REMOVAL_DELAY);
        }
    }, [ uploadsComplete, pageMessage ]);

    return (
        <TransitionGroup className={styles.root}>
            {!files.length ? (
                <NoUploads />
            ) : files.map((pendingUpload, index) => (
                <PendingUploadCard
                    key={pendingUpload.fileId}
                    pendingUpload={pendingUpload}
                    index={index}
                />
            ))}
        </TransitionGroup>
    );
}

function NoUploads(props: Omit<CSSTransitionProps, 'timeout' | 'classNames'>) {
    const nodeRef = useRef<HTMLSpanElement | null>(null);

    return (
        <CSSTransition
            {...props}
            nodeRef={nodeRef}
            appear
            exit={false}
            key="no_uploads"
            timeout={500}
            classNames={{
                appear: styles.noUploadsTransition_appear,
                appearActive: styles.noUploadsTransition_appear_active
            }}
        >
            <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                ref={nodeRef}
            >
                You don't have any pending uploads
            </Typography>
        </CSSTransition>
    );
}

interface PendingUploadCardProps extends Omit<CSSTransitionProps, 'timout' | 'classNames'> {
    pendingUpload: PendingUpload;
    index: number;
}

export function PendingUploadCard({
    pendingUpload, index, message, ...props
}: PendingUploadCardProps) {
    const { status, fileName } = pendingUpload;
    const nodeRef = useRef<HTMLDivElement | null>(null);

    return (
        <CSSTransition
            {...props}
            nodeRef={nodeRef}
            timeout={{
                enter: 250 + (index * 50),
                exit: 250
            }}
            classNames={{
                enter: styles.fileCardTransition_enter,
                enterActive: styles.fileCardTransition_enter_active,
                exit: styles.fileCardTransition_exit,
                exitActive: styles.fileCardTransition_exit_active
            }}
        >
            <StatusCard
                ref={nodeRef}
                primaryText={fileName}
                secondaryText={secondaryTextByStatus[status]}
                status={status}
                actions={(
                    <Tooltip title="View upload status">
                        <Fade
                            in
                            key={status}
                            className={styles.viewUploadButton}
                        >
                            <IconButton
                                component={Link}
                                to="uploads/1"
                                size="small"
                            >
                                {status === 'complete' ? (
                                    <CheckCircleOutline
                                        fontSize="small"
                                        color="success"
                                    />
                                ) : status === 'error' ? (
                                    <ErrorOutline
                                        fontSize="small"
                                        color="error"
                                    />
                                ) : (
                                    <CircularProgress
                                        color="primary"
                                        size={16}
                                    />
                                )}
                            </IconButton>
                        </Fade>
                    </Tooltip>
                )}
            />
        </CSSTransition>
    );
}

const secondaryTextByStatus = {
    pending: undefined,
    complete: '2 loans processed',
    error: '1 loan errored'
};

interface StatusCardProps {
    className?: string;
    primaryText: ReactNode;
    secondaryText: ReactNode;
    actions: ReactNode;
    status: 'pending' | 'complete' | 'error';
}

// TODO break out to separate component file
export const StatusCard = forwardRef<HTMLDivElement, StatusCardProps>(({
    className, primaryText, secondaryText, actions, status
}, ref) => (
    <Paper
        ref={ref}
        variant="outlined"
        className={clsx(styles.fileCard, className, {
            [styles.done]: status === 'complete',
            [styles.error]: status === 'error'
        })}
    >
        <div className={styles.completedOverlay} />

        <Tooltip title={primaryText}>
            <Typography
                variant="body2"
                className={styles.fileCardPrimaryText}
            >
                {primaryText}
            </Typography>
        </Tooltip>

        <div className={styles.fileIconContainer}>
            <Typography variant="body2">
                {secondaryText}
            </Typography>

            {actions}
        </div>
    </Paper>
));
