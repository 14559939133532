import { Contact, User } from '@api';
import { Button, DialogContent } from '@mui/material';
import { DialogActions, FilledSection } from '@tsp-ui/core/components';

import { UserOrContact } from '../UsersAndContactsDialog';

import ContactCard from './ContactCard';
import UserCard from './UserCard';
import styles from './UsersAndContactsListDialogContent.module.scss';


interface UsersAndContactsListDialogContentProps {
    users: User[];
    contacts: Contact[];
    onDelete: (id: string, type: UserOrContact) => void;
    onAddClick: (type: UserOrContact) => void;
    onEditClick: (item: Contact | User) => void;
    loading: boolean;
}

export function UsersAndContactsListDialogContent({
    users, contacts, onDelete, onAddClick, onEditClick, loading
}: UsersAndContactsListDialogContentProps) {
    return (
        <>
            <DialogContent className={styles.content}>
                <FilledSection
                    header="Users"
                    variant="light"
                    className={styles.section}
                >
                    {users.map(user => (
                        <UserCard
                            key={user.id}
                            user={user}
                            onDelete={onDelete}
                            onEditClick={onEditClick}
                        />
                    ))}
                </FilledSection>

                <FilledSection
                    header="Contacts"
                    variant="light"
                    className={styles.section}
                >
                    {contacts.map(contact => (
                        <ContactCard
                            key={contact.id}
                            contact={contact}
                            onDelete={onDelete}
                            onEditClick={onEditClick}
                        />
                    ))}
                </FilledSection>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    onClick={() => onAddClick('contact')}
                    disabled={loading}
                >
                    Add contact
                </Button>

                <Button
                    variant="contained"
                    onClick={() => onAddClick('user')}
                    disabled={loading}
                >
                    Add user
                </Button>
            </DialogActions>
        </>
    );
}
