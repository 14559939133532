import { Address } from '../..';
import { apiUtils } from '../../api-utils';


export function getBranches(customerID: string): Promise<Branch[]> {
    return apiUtils.get(`/customer/${customerID}/branch`);
}

export function createBranch(customerID: string, branchData: Branch): Promise<Branch> {
    return apiUtils.post(`/customer/${customerID}/branch`, branchData);
}

export function updateBranch(customerID: string, branchData: Branch): Promise<Branch> {
    return apiUtils.put(`/customer/${customerID}/branch/${branchData.id}`, branchData);
}

export interface Branch {
    id: string;
    dba?: string;
    nmlsId: string;
    address: Address;
}
