import api from '@api';
import {
    Button, Menu, MenuItem, TextField
} from '@mui/material';
import { Loader } from '@tsp-ui/core/components';
import { useConfirm, usePageMessage, useParams } from '@tsp-ui/core/utils';
import { useContext, useRef, useState } from 'react';

import styles from './LockAvailabilityButton.module.scss';
import { LockAvailabilityContext } from './LockAvailabilityPage';


export default function LockAvailabilityButton() {
    const [ suspendLoading, setSuspendLoading ] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();
    const commentsRef = useRef<HTMLInputElement>(null);

    const confirm = useConfirm();
    const pageMessage = usePageMessage();
    const { customerID } = useParams();

    const { lockAvailability, setLockAvailability } = useContext(LockAvailabilityContext);

    return (
        <>
            <Button
                variant="contained"
                onClick={event => setAnchorEl(event.currentTarget)}
            >
                Suspend locks
            </Button>

            <Menu
                open={!!anchorEl}
                onClose={() => setAnchorEl(undefined)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
            >
                <MenuItem
                    disabled={lockAvailability?.isSuspended}
                    onClick={async () => {
                        setSuspendLoading(true);

                        if (await confirm(
                            <>
                                Are you sure you want to suspend all locks?

                                <TextField
                                    className={styles.comments}
                                    label="Additional comments"
                                    inputRef={commentsRef}
                                    defaultValue={lockAvailability?.suspendedComments}
                                />
                            </>
                        )) {
                            try {
                                lockAvailability && setLockAvailability(
                                    await api.availability.updateLockAvailability({
                                        ...lockAvailability,
                                        isSuspended: true,
                                        suspendedComments: commentsRef.current?.value || null
                                    }, customerID)
                                );

                                pageMessage.success('Locks supended');
                            } catch (error) {
                                pageMessage.handleApiError('An error occurred while suspending locks', error);
                            }
                        }

                        setSuspendLoading(false);
                        setAnchorEl(undefined);
                    }}
                >
                    Suspend all locks
                </MenuItem>

                <MenuItem
                    disabled={lockAvailability?.isSuspendedUntilReprice}
                    onClick={async () => {
                        setSuspendLoading(true);

                        if (await confirm(
                            <>
                                Are you sure you want to suspend locks until reprice?

                                <TextField
                                    className={styles.comments}
                                    label="Additional comments"
                                    inputRef={commentsRef}
                                    defaultValue={lockAvailability?.suspendedComments}
                                />
                            </>
                        )) {
                            try {
                                lockAvailability && setLockAvailability(
                                    await api.availability.updateLockAvailability({
                                        ...lockAvailability,
                                        isSuspendedUntilReprice: true
                                    }, customerID)
                                );

                                pageMessage.success('Locks suspended until next reprice');
                            } catch (error) {
                                pageMessage.handleApiError(
                                    'An error occurred while suspending locks until next reprice', error
                                );
                            }
                        }

                        setSuspendLoading(false);
                        setAnchorEl(undefined);
                    }}
                >
                    Suspend until reprice
                </MenuItem>
            </Menu>

            <Loader loading={suspendLoading} />
        </>
    );
}
