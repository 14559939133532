import { Investor, InvestorStatus, ManagedEligibilityStatus } from '@api';
import { Button, Link as MuiLink, Typography } from '@mui/material';
import StatusActionsCard from '@views/admin/components/StatusActionsCard';
import StatusChecklistItem from '@views/admin/customers/components/StatusChecklistItem';
import Page from '@views/components/Page';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import styles from './InvestorDetailPageBase.module.scss';
import InvestorDetailSummaryCard from './components/InvestorDetailsSummaryCard';


const { ACTIVE, INACTIVE, PENDING } = InvestorStatus;
const { PENDING: ELIGIBILITY_PENDING } = ManagedEligibilityStatus;

interface InvestorDetailPageBaseProps {
    children: ReactNode;
    investor: Investor | undefined;
    setInvestor(investor: Investor): void;
    loading?: boolean;
    onUpdateStatus: (() => Promise<Investor> | undefined) | undefined;
    statusCardChildren?: ReactNode;
    firstBreadcrumb: ReactNode;
}

export function InvestorDetailPageBase({
    children, investor, setInvestor, loading, onUpdateStatus, statusCardChildren, firstBreadcrumb
}: InvestorDetailPageBaseProps) {
    async function updateStatus() {
        if (investor && onUpdateStatus) {
            setInvestor({
                ...investor,
                ...(await onUpdateStatus())
            });
        }
    }

    return (
        <Page
            header="Investor Details"
            loading={loading}
            headerActions={!investor ? undefined : (
                <Button
                    component={Link}
                    to="history"
                >
                    View history
                </Button>
            )}
            breadcrumbs={[
                firstBreadcrumb,
                investor?.name
            ]}
        >
            {!investor && !loading ? (
                <Typography>
                    The investor you tried to view does not exist.{' '}

                    <MuiLink
                        component={Link}
                        to="./.."
                    >
                        View all investors
                    </MuiLink>.
                </Typography>
            ) : investor && (
                <div className={styles.root}>
                    <InvestorDetailSummaryCard investor={investor} />

                    <StatusActionsCard
                        actions={investor.status === PENDING ? (
                            <Button
                                fullWidth
                                component={Link}
                                to="approve"
                                variant="contained"
                                disabled={investor.managedEligibilityStatus === ELIGIBILITY_PENDING}
                            >
                                Approve
                            </Button>
                        ) : (
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={updateStatus}
                                disabled={investor.managedEligibilityStatus === ELIGIBILITY_PENDING}
                            >
                                {investor.status === ACTIVE ? (
                                    'Deactivate'
                                ) : investor.status === INACTIVE ? (
                                    'Reactivate'
                                ) : 'Activate'}
                            </Button>
                        )}
                        checklistItems={investor.managedEligibilityStatus === ELIGIBILITY_PENDING && (
                            <StatusChecklistItem label="Loan programs" />
                        )}
                        noBorder={false}
                        status={{
                            [ACTIVE]: 'Active',
                            [INACTIVE]: 'Inactive',
                            [PENDING]: 'Pending approval'
                        }[investor.status]}
                        variant={investor.managedEligibilityStatus === ELIGIBILITY_PENDING ? 'PENDING' : investor.status}
                    >
                        {statusCardChildren}
                    </StatusActionsCard>

                    {children}
                </div>
            )}
        </Page>
    );
}
