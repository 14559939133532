import {
    AutomatedUwRecommendation,
    AutomatedUwSystem,
    automatedUwRecommendationDisplay,
    automatedUwSystemDisplay,
    commitmentTypeDisplay,
    documentationTypeDisplay,
    specialtyProgramDisplay
} from '@api';
import { FormControl, FormLabel } from '@mui/material';
import {
    CurrencyField, PercentField, Switch, TextField, isAtLeast, renderEnumOptions
} from '@tsp-ui/core/components';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { ManualLoanEntryFormValues } from '../ManualLoanEntryPage';

import styles from './AdditionalDetailsStep.module.scss';
import ManualLoanEntryStep, { ManualLoanEntryStepProps } from './ManualLoanEntryStep';


const {
    APPROVE_ELIGIBLE, APPROVE_INELIGIBLE, REFER_WITH_CAUTION, OUT_OF_SCOPE, ACCEPT, CAUTION
} = AutomatedUwRecommendation;

const { DU, LPA } = AutomatedUwSystem;

const duAutomatedUwRecommendationDisplay = {
    [APPROVE_ELIGIBLE]: automatedUwRecommendationDisplay[APPROVE_ELIGIBLE],
    [APPROVE_INELIGIBLE]: automatedUwRecommendationDisplay[APPROVE_INELIGIBLE],
    [REFER_WITH_CAUTION]: automatedUwRecommendationDisplay[REFER_WITH_CAUTION],
    [OUT_OF_SCOPE]: automatedUwRecommendationDisplay[OUT_OF_SCOPE]
};

const lpaAutomatedUwRecommendationDisplay = {
    [ACCEPT]: automatedUwRecommendationDisplay[ACCEPT],
    [CAUTION]: automatedUwRecommendationDisplay[CAUTION]
};

export default function AdditionalDetailsStep(props: Partial<ManualLoanEntryStepProps>) {
    const { watch, setValue } = useFormContext<ManualLoanEntryFormValues>();

    const mortgageVisible = watch('mortgageInsFlag');
    const underwriteVisible = watch('underwriteFlag');
    const automatedUwSystem = watch('automatedUwSystem');

    const duAutomatedUwSystem =  automatedUwSystem === DU;
    const showAutomatedUwRecommendation = automatedUwSystem === DU || automatedUwSystem === LPA;

    useEffect(() => {
        setValue('automatedUwRecommendation', '');
    }, [ automatedUwSystem, setValue ]);

    return (
        <ManualLoanEntryStep {...props}>
            <div>
                <FormLabel>
                    Additional details
                </FormLabel>

                <div className={styles.detailsContainer}>
                    <TextField<ManualLoanEntryFormValues>
                        name="productCode"
                        label="Product code"
                        maxLength={100}
                    />

                    <TextField<ManualLoanEntryFormValues>
                        name="specialtyProgram"
                        label="Specialty program"
                        select
                    >
                        {renderEnumOptions(specialtyProgramDisplay)}
                    </TextField>

                    <TextField<ManualLoanEntryFormValues>
                        name="documentationType"
                        label="Documentation"
                        select
                    >
                        {renderEnumOptions(documentationTypeDisplay)}
                    </TextField>

                    <CurrencyField<ManualLoanEntryFormValues>
                        name="subordinatedBalance"
                        label="Subordinated balance"
                        rules={isAtLeast(0)}
                    />

                    <CurrencyField<ManualLoanEntryFormValues>
                        name="cashOutAmount"
                        label="Cash out amount"
                        rules={isAtLeast(0)}
                    />

                    <TextField<ManualLoanEntryFormValues>
                        name="limitedLiabilityCorp"
                        label="Limited liability corp"
                        maxLength={100}
                    />

                    <TextField<ManualLoanEntryFormValues>
                        name="commitmentType"
                        label="Commitment type"
                        select
                    >
                        {renderEnumOptions(commitmentTypeDisplay)}
                    </TextField>

                    <TextField<ManualLoanEntryFormValues>
                        name="commitmentIdentifier"
                        label="Commitment ID"
                        maxLength={50}
                    />

                    <TextField<ManualLoanEntryFormValues>
                        name="comments"
                        label="Comments"
                        className={styles.fullWidth}
                        multiline
                        maxLength={4000}
                    />

                    <Switch<ManualLoanEntryFormValues>
                        className={styles.switch}
                        name="mortgageInsFlag"
                        label="Mortgage insurance"
                    />

                    { mortgageVisible && (
                        <FormControl
                            component="fieldset"
                            className={styles.switchFields}
                        >
                            <TextField<ManualLoanEntryFormValues>
                                name="mortgageInsCompany"
                                label="Insurance Company"
                                maxLength={100}
                            />

                            <PercentField<ManualLoanEntryFormValues>
                                name="mortgageInsCoverage"
                                label="Coverage Percentage"
                                rules={isAtLeast(0)}
                            />
                        </FormControl>
                    )}

                    <Switch<ManualLoanEntryFormValues>
                        className={styles.switch}
                        name="underwriteFlag"
                        label="Underwrite"
                    />

                    {underwriteVisible && (
                        <FormControl
                            component="fieldset"
                            className={styles.switchFields}
                        >
                            <TextField<ManualLoanEntryFormValues>
                                name="automatedUwSystem"
                                label="UW System"
                                select
                            >
                                {renderEnumOptions(automatedUwSystemDisplay)}
                            </TextField>

                            {showAutomatedUwRecommendation ? (
                                <TextField<ManualLoanEntryFormValues>
                                    name="automatedUwRecommendation"
                                    label="UW Recommendation"
                                    select
                                >
                                    {renderEnumOptions(duAutomatedUwSystem
                                        ? duAutomatedUwRecommendationDisplay
                                        : lpaAutomatedUwRecommendationDisplay)}
                                </TextField>
                            ) : null}
                        </FormControl>
                    )}
                </div>
            </div>
        </ManualLoanEntryStep>
    );
}
