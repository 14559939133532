export enum NumUnits {
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4
}

export const numUnitsDisplay = {
    [NumUnits.ONE]: '1',
    [NumUnits.TWO]: '2',
    [NumUnits.THREE]: '3',
    [NumUnits.FOUR]: '4'
};
