import { RoutedDialogProps } from '@tsp-ui/core/components';
import { useContext } from 'react';

import AdminAddEditDialogTemplate from '../../components/AdminAddEditDialogTemplate';
import { RoleDetailPageContext } from '../RoleDetailPage';

import RoleForm from './RoleForm';


export default function EditRoleDetailsDialog(props: Omit<RoutedDialogProps, 'title' | 'children'>) {
    const { role, setRole } = useContext(RoleDetailPageContext);

    return (
        <AdminAddEditDialogTemplate
            entityName="role"
            entityToEdit={role}
            Form={RoleForm}
            onFormSubmit={setRole}
            {...props}
        />
    );
}
