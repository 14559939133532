import {
    Close, Description, Download, ErrorOutline, ExpandMore, Upload
} from '@mui/icons-material';
import {
    Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator
} from '@mui/lab';
import {
    Button, DialogContent, IconButton, Paper, Tooltip, Typography
} from '@mui/material';
import {
    FileInput, IconTypography, RoutedDialog, RoutedDialogImplProps
} from '@tsp-ui/core/components';
import { productAndPricingAcceptedFileTypes } from '@views/product-pricing/ProductAndPricingPage';
import { StatusCard } from '@views/product-pricing/components/PendingUploadsCard';
import { useState } from 'react';

import styles from './LoanUploadResultsDialog.module.scss';


export default function LoanUploadResultsDialog(props: RoutedDialogImplProps) {
    const [ showFileUpload, setShowFileUpload ] = useState(false);

    const actions = (
        <>
            <Tooltip title="Show more">
                <IconButton size="small">
                    <ExpandMore
                        color="secondary"
                        fontSize="small"
                    />
                </IconButton>
            </Tooltip>

            <Tooltip title="Dismiss error">
                <IconButton size="small">
                    <Close
                        color="action"
                        fontSize="small"
                    />
                </IconButton>
            </Tooltip>
        </>
    );

    return (
        <RoutedDialog
            title="Loan upload results"
            maxWidth={false}
            {...props}
        >
            <DialogContent className={styles.dialogContent}>
                <div>
                    <Typography fontWeight={500}>
                        Loans to Price - Missing Loan Amount.xlsx
                    </Typography>

                    <div className={styles.statusContainer}>
                        <Paper variant="outlined">
                            <IconTypography icon={<Description color="primary" />}>
                                0
                            </IconTypography>

                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                loans processed
                            </Typography>
                        </Paper>

                        <Paper variant="outlined">
                            <IconTypography icon={<ErrorOutline color="error" />}>
                                1
                            </IconTypography>

                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                loan errored
                            </Typography>
                        </Paper>
                    </div>

                    <div className={styles.outstandingErrors}>
                        <Typography color="textSecondary">
                            Outstanding errors
                        </Typography>

                        <StatusCard
                            primaryText="Loan #ABC-50004"
                            secondaryText="Loan amount is required"
                            actions={actions}
                            status="error"
                        />
                    </div>
                </div>

                <div>
                    {showFileUpload ? (
                        <>
                            <Typography color="textSecondary">
                                Upload new revision
                            </Typography>

                            <div className={styles.revFileHeader}>
                                <Typography fontWeight={500}>
                                    Loans to Price - Missing Loan Amount_rev2.xlsx
                                </Typography>

                                <IconButton size="small">
                                    <Download color="secondary" />
                                </IconButton>
                            </div>

                            <FileInput
                                compact
                                title="file"
                                className={styles.revisionFileInput}
                                acceptedFileTypes={productAndPricingAcceptedFileTypes}
                                onAddFiles={() => {}}
                            />

                            <Typography
                                variant="caption"
                                color="textSecondary"
                                align="center"
                                className={styles.downloadAndUpdateCaption}
                            >
                                Download and update the revision file to correct the errors and upload it here
                            </Typography>
                        </>
                    ) : (
                        <div>
                            <Typography color="textSecondary">
                                Upload revisions
                            </Typography>

                            <Timeline
                                position="left"
                                className={styles.progressTimeline}
                                nonce={undefined}
                                onResize={undefined}
                                onResizeCapture={undefined}
                            >
                                <TimelineItem>
                                    <TimelineContent className={styles.timelineContent}>
                                        <div>
                                            Original file uploaded

                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                20 seconds ago
                                            </Typography>
                                        </div>
                                    </TimelineContent>

                                    <TimelineSeparator>
                                        <TimelineConnector className={styles.hiddenConnector} />

                                        <TimelineDot color="primary">
                                            <Upload />
                                        </TimelineDot>

                                        <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
                                    </TimelineSeparator>
                                </TimelineItem>

                                <TimelineItem>
                                    <TimelineContent className={styles.timelineContent}>
                                        <div>
                                            <Typography color="error">
                                                1 outstanding error
                                            </Typography>

                                            <Button
                                                size="small"
                                                className={styles.showCreateRevisionButton}
                                                onClick={() => setShowFileUpload(true)}
                                            >
                                                Upload new revision
                                            </Button>
                                        </div>
                                    </TimelineContent>

                                    <TimelineSeparator>
                                        <TimelineConnector sx={{ bgcolor: 'error.main' }} />

                                        <TimelineDot color="error">
                                            <ErrorOutline />
                                        </TimelineDot>

                                        <TimelineConnector className={styles.hiddenConnector} />
                                    </TimelineSeparator>
                                </TimelineItem>
                            </Timeline>
                        </div>
                    )}
                </div>
            </DialogContent>
        </RoutedDialog>
    );
}
