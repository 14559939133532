import {
    EligibilityExclusionGroupFormValues,
    EligibilityExclusionsFormValues
} from '@views/admin/investors/InvestorDetailPage/components/ExclusionGroupRow';
import clsx from 'clsx';
import React, { Fragment } from 'react';

import styles from './ExclusionGroupGraphic.module.scss';


interface ExclusionGroupGraphicProps {
    group: EligibilityExclusionsFormValues;
    indexToHide: number | undefined;
}

const HALF_AND_OR_BUTTON_WIDTH = 20;
const HALF_AND_OR_BUTTON_HEIGHT = 12;

/**
 * Renders the line graphic for an exclusion group
 *
 * @param group       - The exclusion group to render the graphic for
 * @param indexToHide - The index to hide within the group graphic to avoid overlap with delete buttons as
 *                      they are focused/hovered
 * @constructor
 */
export default function ExclusionGroupGraphic({ group, indexToHide }: ExclusionGroupGraphicProps) {
    const yCoordinates = getYCoordinates(group);
    const solidLineHeight = yCoordinates[yCoordinates.length - 1];

    // Adjust the line height so it doesn't overlap the AND/OR button
    const adjustedSolidLineHeight = Math.max(solidLineHeight, HALF_AND_OR_BUTTON_HEIGHT);
    const numRows = (group.exclusions?.length || 0) + (group.groups?.length || 0);

    return (
        <svg
            className={clsx(styles.root, {
                [styles.or]: group.operator === 'or',
                [styles.disabled]: numRows === 1
            })}
            height={computeGroupHeight(group) - HALF_AND_OR_BUTTON_HEIGHT}
        >
            <g transform={`translate(0, ${HALF_AND_OR_BUTTON_HEIGHT})`}>
                <line
                    x1="0"
                    x2="0"
                    y1="12"
                    y2={adjustedSolidLineHeight}
                />

                <line
                    x1="0"
                    x2="0"
                    y1={adjustedSolidLineHeight}
                    y2="100%"
                    strokeDasharray="5"
                />

                {yCoordinates.map((yCoordinate, index) => (
                    <Fragment key={yCoordinate}>
                        <line
                            x1={index === 0
                                ? HALF_AND_OR_BUTTON_WIDTH
                                : 0}
                            x2={index === indexToHide
                                ? index === 0
                                    ? HALF_AND_OR_BUTTON_WIDTH
                                    : 16
                                : 32}
                            y1={yCoordinate}
                            y2={yCoordinate}
                        />

                        {index !== indexToHide && (
                            <circle
                                cx="36"
                                cy={yCoordinate}
                                r="4"
                                stroke="none"
                            />
                        )}
                    </Fragment>
                ))}
            </g>
        </svg>
    );
}

const ROW_HEIGHT = 45;
const GROUP_MARGIN = 8;
const ADD_BUTTON_HEIGHT = 40;
const EXTRA_DASHED_LINE_HEIGHT = 8;

/**
 * Returns a list of y coordinates for the horizontal lines and terminating circles for each row in the group
 *
 * @param group - The group to get y coordinates for
 */
export function getYCoordinates(group: EligibilityExclusionsFormValues | EligibilityExclusionGroupFormValues) {
    let currentYCoord = 0;

    const ruleCoords = group.exclusions?.map((_, index) => {
        if (index > 0) {
            currentYCoord += ROW_HEIGHT;
        }

        return currentYCoord;
    }) || [];

    const groupCoords = !hasSubGroups(group) ? undefined : group.groups?.map((_, index) => {
        const hasRules = group.exclusions && group.exclusions.length > 0;

        if (index > 0 || hasRules) {
            currentYCoord += ROW_HEIGHT + GROUP_MARGIN;
        }

        if (index > 0) {
            currentYCoord += computeGroupHeight(group.groups![index - 1]);

            // The extra dashed line height throws off the group coordinates if we don't have rules, so remove it
            if (!hasRules) {
                currentYCoord -= EXTRA_DASHED_LINE_HEIGHT;
            }
        }

        return currentYCoord;
    });

    return ruleCoords.concat(groupCoords || []);
}

/**
 * Computes the height of a nested exclusion group
 *
 * @param group - The group to compute the height for
 */
function computeGroupHeight(group: EligibilityExclusionsFormValues | EligibilityExclusionGroupFormValues) {
    const groupCoords = getYCoordinates(group);

    let height = groupCoords[groupCoords.length - 1] + ADD_BUTTON_HEIGHT + EXTRA_DASHED_LINE_HEIGHT;

    if (hasSubGroups(group)) {
        const lastItemInGroup = group.groups?.[group.groups.length - 1];
        if (lastItemInGroup) {
            height += computeGroupHeight(lastItemInGroup);
        }
    }

    return height;
}

function hasSubGroups(group: EligibilityExclusionsFormValues | EligibilityExclusionGroupFormValues):
    group is EligibilityExclusionsFormValues {
    return group.hasOwnProperty('groups');
}
