import { Button, Typography } from '@mui/material';
import { logout } from '@redux/auth';
import { useDispatch } from '@redux/store';
import { useNavigate } from 'react-router-dom';

import styles from './GlobalErrorMessage.module.scss';


interface GlobalErrorMessageProps {
    onLogout?: () => void;
    hideLogout?: boolean;
}

export default function GlobalErrorMessage({ onLogout, hideLogout }: GlobalErrorMessageProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <div className={styles.root}>
            <Typography className={styles.errorText}>
                We ran into a problem starting the app, please contact support.
            </Typography>

            {!hideLogout && (
                <Button
                    variant="contained"
                    onClick={async () => {
                        await dispatch(logout());
                        navigate('/login');
                        onLogout?.();
                    }}
                >
                    Logout
                </Button>
            )}
        </div>
    );
}
