import { AppState } from '../store';


export function isAuthenticationInitialized(state: AppState) {
    return !state.auth.initNeeded;
}

export function getCurrentUser(state: AppState) {
    return state.auth.user;
}

export function getAccounts(state: AppState) {
    return state.auth.accounts;
}

export function getAccount(state: AppState, accountID: string | undefined, customerID: string | undefined) {
    return state.auth.accounts?.find(
        (account) => account.id === accountID && (!customerID || account.customerId === customerID)
    );
}
